import { IconButtonProps } from '../../interfaces'
import { Button } from '../Button'

const IconButton = ({
  variant,
  customVariant,
  color = 'white',
  size = 24,
  styles,
  onClick,
  label,
}: IconButtonProps): JSX.Element => {
  return (
    <Button
      variant={'icon'}
      iconProps={{ variant, color, size, customVariant }}
      iconStyles={styles}
      onClick={onClick}
      label={label}
      testId={label}
    />
  )
}

export default IconButton
