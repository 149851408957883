import { AppAction, AppState } from '../Types'

export const addToastMessage = (state: AppState, action: AppAction) => ({
  ...state,
  toastMessages: [...state.toastMessages, action['toastMessage']],
})

export const deleteToastMessage = (state: AppState, action: AppAction) => ({
  ...state,
  toastMessages: [
    ...state.toastMessages.filter(
      (toastMessage) => toastMessage.id !== action['id'],
    ),
  ],
})
