import { CSSProps } from './CSSProps'

export enum InlineMessageType {
  Info = 'Info',
  SuccessInline = 'SuccessInline',
  WarningInline = 'WarningInline',
  ErrorInline = 'ErrorInline',
  BasicInline = 'BasicInline',
}

export enum ToastType {
  SuccessToast = 'SuccessToast',
  ErrorToast = 'ErrorToast',
  Default = 'Default',
  WarningToast = 'WarningToast',
}

export interface MessageProps {
  messageType: InlineMessageType | ToastType
  title?: string
  text?: string | string[] | JSX.Element
  autoDismissAt?: number
  dismissible?: boolean
  style?: CSSProps
  onDismissed?: () => void
}
