/* eslint-disable import/no-unresolved */
import { useState } from 'react'
import { ResponsivePie } from '@nivo/pie'
import { Box, Card, Typography, useTheme } from '@mui/material'

import { DetectionCoverageOverviewData } from '@models/DetectionCoverage'
import Icon from '@common/Icon'
import { Loader } from '@common/Loader'
import { formatLargeNumber } from '@utils/formatLargeNumber'

import MitreTactic from './MitreTactic'

export interface AlertsByMitreTacticProps {
  data?: DetectionCoverageOverviewData
}

const MitreAlertChart = ({ data }: AlertsByMitreTacticProps): JSX.Element => {
  const theme = useTheme()

  const [isHovered, setIsHovered] = useState(false)

  const [activeNode, setActiveNode] = useState('')

  const alertsByMitreTacticMapped = data?.alertsByMitreTactic
    .map((alert) => ({
      id: alert.tactic,
      label: alert.tactic,
      value: alert.incidentCount,
      sequence: data.mitreCoverage.find(
        (technique) => technique.mitreTacticName === alert.tactic,
      )?.mitreTacticSequence,
    }))
    .sort((a, b) => {
      if (!a.sequence || !b.sequence) {
        return 0
      }
      return a.sequence < b.sequence ? -1 : 1
    })

  const chartColors = {
    Reconnaissance: '#7AD9CD',
    'Resource Development': theme.vars.palette.text.secondary,
    'Initial Access': theme.vars.palette.text.primary,
    Execution: theme.vars.palette.common.black,
    Persistence: theme.vars.palette.success.main,
    'Privilege Escalation': theme.vars.palette.success.dark,
    'Defense Evasion': theme.vars.palette.primary.light,
    'Credential Access': theme.vars.palette.primary.main,
    Discovery: theme.vars.palette.important.main,
    'Lateral Movement': theme.vars.palette.important.dark,
    Collection: theme.vars.palette.severity.medium!,
    'Command and Control': '#ffc368',
    Exfiltration: theme.vars.palette.error.light,
    Impact: theme.vars.palette.error.main,
  }

  const filteredColors = alertsByMitreTacticMapped?.map(
    (alert) => chartColors[alert.label],
  )

  const chartTheme = {
    labels: {
      text: {
        fontSize: 16,
      },
    },
    tooltip: {
      container: {
        background: '#333',
      },
    },
  }

  const CenteredMetric = ({ dataWithArc, centerX, centerY }) => {
    let total = 0
    const message = 'Total alerts'
    dataWithArc.forEach((datum) => {
      total += datum.value
    })
    return (
      <g
        tabIndex={0}
        aria-label={`You've had ${formatLargeNumber(total, 'short', 6)} MITRE tactic ${message}`}
      >
        <Box
          component="text"
          x={centerX}
          y={centerY - 12}
          textAnchor="middle"
          dominantBaseline="central"
          sx={(theme) => ({
            fontSize: '28px',
            fontWeight: '600',
            fontFamily: 'Inter',
            fill: theme.palette.text.primary,
            ...theme.applyStyles('dark', {
              fill: theme.palette.text.secondary,
            }),
          })}
        >
          {formatLargeNumber(total, 'short', 6)}
        </Box>
        <Box
          component="text"
          x={centerX}
          y={centerY + 12}
          textAnchor="middle"
          dominantBaseline="central"
          sx={(theme) => ({
            fontSize: '14px',
            fontFamily: 'Inter',
            fontWeight: '400',
            fill: theme.palette.text.primary,
            ...theme.applyStyles('dark', {
              fill: theme.palette.text.secondary,
            }),
          })}
        >
          {message}
        </Box>
      </g>
    )
  }

  const customTooltip = ({ datum: { id, value, color } }) => {
    return (
      <Box
        data-testid="donut-chart-tool-tip"
        sx={(theme) => ({
          backgroundColor: theme.palette.background.paper,
          boxShadow: theme.shadows[1],
          border: `1px solid ${theme.palette.secondary.main}`,
          borderRadius: '5px',
          ...theme.applyStyles('dark', {
            backgroundColor: theme.palette.secondary.darker,
            boxShadow: theme.shadows[1],
            border: `1px solid ${theme.palette.secondary.main}`,
            borderRadius: '5px',
          }),
          padding: '1px',
          width: '178px',
        })}
      >
        <Typography color="textPrimary" variant="caption">
          Alerts by MITRE Tactic
        </Typography>
        <Box
          sx={(theme) => ({
            borderTop: `0.75px solid ${theme.palette.text.primary}`,
            paddingBottom: '2px',
          })}
        ></Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            color,
            paddingTop: '2.5px',
          }}
        >
          <Icon
            size={14}
            variant="square"
            sx={{ color, paddingRight: '3px' }}
          />
          <Typography color="textPrimary" variant="caption">
            {value} {id}
          </Typography>
        </Box>
      </Box>
    )
  }

  const handleMouseEnter = (node) => {
    setActiveNode(node.data.label)
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setActiveNode('')
    setIsHovered(false)
  }

  return (
    <>
      <Box
        id="mitre-alert-chart-container"
        sx={{
          position: 'relative',
          flex: '1 1 auto',
        }}
        aria-labelledby="mitre-alert-chart-label"
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0}
      >
        <Card
          sx={(theme) => ({
            backgroundColor: theme.vars.palette.secondary.light,
            border: `1px solid ${theme.vars.palette.secondary.dark}`,
            borderRadius: '5px',
            boxSizing: 'border-box',
            boxShadow: 'none',
            padding: '16px',
            ...theme.applyStyles('dark', {
              backgroundColor: theme.vars.palette.secondary.dark,
            }),
          })}
        >
          <Typography color="textPrimary" fontWeight={500} variant="caption">
            <span aria-hidden={true}>ALERTS BY MITRE TACTIC</span>
          </Typography>

          {!alertsByMitreTacticMapped ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: 250,
              }}
            >
              <Loader strokeWidth={1} size={50} />
            </Box>
          ) : (
            <>
              <Box
                sx={{
                  width: '220px',
                  height: '220px',
                  margin: '0 auto 14px',
                }}
              >
                <ResponsivePie
                  data={alertsByMitreTacticMapped}
                  margin={{ top: 10, bottom: 10 }}
                  innerRadius={0.91}
                  padAngle={0}
                  cornerRadius={0}
                  sortByValue={true}
                  colors={filteredColors}
                  borderWidth={1}
                  borderColor={{ theme: 'background' }}
                  activeOuterRadiusOffset={9}
                  enableArcLabels={false}
                  enableArcLinkLabels={false}
                  tooltip={customTooltip}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  theme={chartTheme}
                  layers={[
                    'arcs',
                    'arcLabels',
                    'arcLinkLabels',
                    'legends',
                    CenteredMetric,
                  ]}
                />
              </Box>

              <Box
                data-testid="mitre-column-container"
                sx={{
                  display: 'flex',
                  gap: '17px',
                  justifyContent: 'center',
                  ...(isHovered && {
                    '.tactic': {
                      opacity: 0.3,
                      '&.active': {
                        opacity: 1,
                      },
                    },
                  }),
                }}
                aria-hidden={true}
              >
                <Box
                  sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}
                >
                  {alertsByMitreTacticMapped.map((alert, index) => {
                    if (index < alertsByMitreTacticMapped.length / 2) {
                      return (
                        <MitreTactic
                          key={index}
                          active={alert.id === activeNode ? true : false}
                          name={alert.label}
                          color={chartColors[alert.label]}
                          value={alert.value}
                        />
                      )
                    }
                  })}
                </Box>

                <Box
                  sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}
                >
                  {' '}
                  {alertsByMitreTacticMapped.map((alert, index) => {
                    if (index >= alertsByMitreTacticMapped.length / 2) {
                      return (
                        <MitreTactic
                          key={index}
                          active={alert.id === activeNode ? true : false}
                          name={alert.label}
                          color={chartColors[alert.label]}
                          value={alert.value}
                        />
                      )
                    }
                  })}
                </Box>
              </Box>
            </>
          )}
        </Card>
        <p id="mitre-alert-chart-label" hidden>
          Alerts by MITRE Tactic
        </p>
        <Box sx={{ position: 'absolute', top: 0, right: 0, zIndex: -1 }}>
          <>
            {alertsByMitreTacticMapped?.map((arcData) => {
              return (
                // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                <Box key={arcData.label}>
                  MITRE tactic {arcData.label} had{' '}
                  {arcData.value < 2
                    ? `${arcData.value} alert`
                    : `${arcData.value} alerts`}
                </Box>
              )
            })}
          </>
        </Box>
      </Box>
    </>
  )
}

export default MitreAlertChart
