import { createContext, ReactNode, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'

import { Ticket } from '../../../../models'
import { hasValidDraftTicketFields, updateTicketInCache } from '../utils'
import { TicketEditContextModel } from '../Types'
import {
  TicketDataInput,
  UPDATE_TICKET,
  UpdateTicketVariables,
} from '../../../../graphql/mutations/ticket'
import {
  GET_TOP_LEVEL_TICKET_DATA,
  GetTicketDetailsVariables,
} from '../../../../graphql/queries/ticket'

export const TicketEditContext = createContext<TicketEditContextModel | null>(
  null,
)

export const TicketEditContextProvider = ({
  children,
  sysId,
  isEditable,
}: {
  children: ReactNode
  sysId: string
  isEditable: boolean
}) => {
  const [draftTicketFields, setDraftTicketFields] = useState<
    Partial<TicketDataInput>
  >({})

  const resetDraftTicketFields = () => setDraftTicketFields({})

  const { loading: isFetchLoading, error: fetchError } = useQuery<
    { getTicketDetails: Ticket },
    GetTicketDetailsVariables
  >(GET_TOP_LEVEL_TICKET_DATA, {
    fetchPolicy: 'cache-and-network',
    variables: {
      ticketId: sysId,
    },
  })

  const [
    updateTicket,
    { loading: isSubmissionLoading, error: submissionError },
  ] = useMutation<{ updateTicket: boolean }, UpdateTicketVariables>(
    UPDATE_TICKET,
    {
      onCompleted: ({ updateTicket }) => {
        if (!updateTicket) {
          return
        }

        // Update apollo cache to have new values that the user submitted
        updateTicketInCache({
          ticketId: sysId,
          newFields: draftTicketFields,
        })
        resetDraftTicketFields()
      },
    },
  )

  const saveDraftTicketFields = (ticketFields: Partial<TicketDataInput>) => {
    const nextDraftTicketFields = {
      ...draftTicketFields,
      ...ticketFields,
    }
    setDraftTicketFields(nextDraftTicketFields)
  }

  const submitDraftTicketFields = async () => {
    try {
      await updateTicket({
        variables: {
          input: {
            ticketId: sysId,
            ticketData: {
              ...draftTicketFields,
            },
          },
        },
      })
      /**
       * Note: Try/catch block necessary for when Apollo throws errors.
       * Catch block is required with try block, but empty since
       * `useMutation` hook handles error.
       */
      // eslint-disable-next-line no-empty
    } catch {}
  }

  const hasValidChangesToSave =
    !!Object.keys(draftTicketFields).length &&
    hasValidDraftTicketFields(draftTicketFields)

  return (
    <TicketEditContext.Provider
      value={{
        sysId,
        hasValidChangesToSave,
        saveDraftTicketFields,
        isSubmissionLoading,
        submissionError,
        submitDraftTicketFields,
        fetchError,
        isFetchLoading,
        isEditable,
        draftTicketFields,
        resetDraftTicketFields,
      }}
    >
      {children}
    </TicketEditContext.Provider>
  )
}
