import { Box, Theme, useTheme } from '@mui/material'
import { ResponsiveLine } from '@nivo/line'
import { format, parseISO } from 'date-fns'

import { ChartLegend } from '@common/ChartLegend'
import { MetricType } from '@models/CyberAssistant'

import { formatYAxisTime } from '../KnowledgeBase/KnowledgeBase.utils'
import { getFormattedMetric } from '../MTTContainer/MTTContainer'

export const nivoTheme = (theme: Theme) => ({
  axis: {
    ticks: {
      text: {
        fill: theme.vars.palette.text.primary,
      },
      line: {
        strokeWidth: 0,
      },
    },

    domain: {
      line: {
        stroke: theme.vars.palette.secondary.main,
        strokeWidth: 1,
      },
    },
  },
  grid: {
    line: {
      stroke: theme.vars.palette.text.primary,
      strokeWidth: 0.24,
    },
  },
})

export interface MetricChartData {
  month: string
  average: number
}

interface MetricChartProps {
  data: MetricChartData[]
  metricType: MetricType
}

export const MetricChart: React.FC<MetricChartProps> = ({
  data,
  metricType,
}) => {
  const theme = useTheme()

  const getMetricColor = (metric: MetricType) => {
    switch (metric) {
      case MetricType.MTTD:
        return theme.vars.palette.success.main
      case MetricType.MTTR:
        return theme.vars.palette.error.light
      case MetricType.MTTCACK:
        return theme.vars.palette.severity.medium
      default:
        return ''
    }
  }

  const getLegendItems = (metric: MetricType) => [
    {
      color: getMetricColor(metric),
      label: getFormattedMetric(metric),
    },
  ]

  return (
    <Box
      my={'16px'}
      data-testid="metric-line-chart"
      sx={(theme) => ({
        border: `1px solid ${theme.vars.palette.secondary.lighter}`,
        borderRadius: '5px',
        height: '270px',
      })}
    >
      <Box pt={'24px'} px={'24px'}>
        <ChartLegend legendItems={getLegendItems(metricType)} />
      </Box>
      <Box
        id="mttx-line-chart"
        data-testid="mttx-line-chart"
        sx={{ height: '208px', width: '600px' }}
      >
        <ResponsiveLine
          data={[
            {
              id: metricType,
              data: data.map((item) => ({
                x: format(parseISO(item.month), 'MMMM'),
                y: item.average,
              })),
            },
          ]}
          curve="linear"
          theme={nivoTheme(theme)}
          enableGridX={false}
          enableGridY={true}
          lineWidth={1}
          enableSlices={false}
          colors={getMetricColor(metricType)}
          margin={{ top: 4, right: 32, bottom: 40, left: 72 }}
          axisLeft={{
            format: (value) => formatYAxisTime(value),
            tickValues: 5,
          }}
          enablePoints={false}
          gridYValues={5}
        />
      </Box>
    </Box>
  )
}
