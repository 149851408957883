import { CSSProperties } from 'react'
// eslint-disable-next-line import/no-unresolved
import { Property } from 'csstype'

import { Typography } from '../../Typography'
import { colors } from '../../../theme'
import { useTheme } from '../../../hooks'

import './Caption.scss'

export enum TableCaptionPosition {
  TopLeft = 'Top Left',
  TopCenter = 'Top Center',
  TopRight = 'Top Right',
  BottomLeft = 'Bottom Left',
  BottomCenter = 'Bottom Center',
  BottomRight = 'Bottom Right',
}
export interface CaptionProps {
  caption?: string
  captionButton?: JSX.Element
  captionSide?: TableCaptionPosition
}

const Caption = ({
  caption,
  captionButton,
  captionSide = TableCaptionPosition.TopLeft,
}: CaptionProps): JSX.Element => {
  const isDarkTheme = useTheme('dark')

  const captionStyle = (
    captionSide: TableCaptionPosition = TableCaptionPosition.TopLeft,
    isDarkTheme: boolean,
  ): CSSProperties => {
    // position caption
    let tempCaptionHorizontalPosition: Property.TextAlign
    let tempCaptionVerticalPosition: Property.CaptionSide
    if (
      captionSide === TableCaptionPosition.TopLeft ||
      captionSide === TableCaptionPosition.BottomLeft
    ) {
      tempCaptionHorizontalPosition = 'left'
    } else if (
      captionSide === TableCaptionPosition.TopRight ||
      captionSide === TableCaptionPosition.BottomRight
    ) {
      tempCaptionHorizontalPosition = 'right'
    } else {
      tempCaptionHorizontalPosition = 'center'
    }
    switch (captionSide) {
      case TableCaptionPosition.BottomLeft:
      case TableCaptionPosition.BottomRight:
      case TableCaptionPosition.BottomCenter:
        tempCaptionVerticalPosition = 'bottom'
        break
      default:
        tempCaptionVerticalPosition = 'top'
        break
    }
    let result: CSSProperties = {
      captionSide: tempCaptionVerticalPosition,
      color: isDarkTheme ? colors.util.one.light : colors.util.one.main,
      textAlign: tempCaptionHorizontalPosition,
      verticalAlign: 'middle',
    }
    if (tempCaptionVerticalPosition === 'top') {
      result = { ...result, marginBottom: '8px' }
    } else {
      result = { ...result, marginTop: '8px' }
    }
    return result
  }

  return (
    <>
      {(caption || captionButton) && (
        <caption style={captionStyle(captionSide, isDarkTheme)}>
          <div className="table-caption-text-container">
            <Typography size={16} weight={400}>
              {caption}
            </Typography>
            {captionButton}
          </div>
        </caption>
      )}
    </>
  )
}

export default Caption
