import { useState, CSSProperties, useEffect } from 'react'

import { TabProps } from '../../interfaces/Tab'
import { colors } from '../../theme'
import { useTheme } from '../../hooks'
import { Icon } from '../Icon'
import { Typography } from '../Typography'

const Tab = ({
  text,
  active,
  notification,
  variant = 'informational',
  icon,
  styles,
  id,
  onClick,
  onAddTab,
}: TabProps): JSX.Element => {
  const isDarkTheme = useTheme('dark')
  const [activeTab, setActiveTab] = useState(active)
  const [hover, setHover] = useState(false)
  const [indicator, setIndicator] = useState(notification)

  useEffect(() => {
    setActiveTab(active)
    setHover(false)
  }, [active])

  const handleClick = () => {
    if (onClick) {
      onClick(activeTab)
      setActiveTab(true)
      setIndicator(false)
    }
  }

  const handleAddTab = () => {
    if (onAddTab) {
      onAddTab()
    }
  }

  const handleMouseOver = () => {
    !activeTab && setHover(true)
  }

  const handleMouseLeave = () => {
    !activeTab && setHover(false)
  }

  const textColor = () => {
    if (activeTab) {
      return isDarkTheme ? colors.util.navy[50] : colors.util.navy[600]
    }

    if (hover) {
      return isDarkTheme ? colors.util.navy[50] : colors.util.navy[100]
    }
    // inactive and no hover
    return isDarkTheme ? colors.util.navy[100] : colors.util.navy[400]
  }

  const defaultTabStyles: CSSProperties = {
    padding: 8,
  }

  const actionTypographyOverrides: CSSProperties = {
    display: 'inline-block',
    verticalAlign: 'middle',
    marginRight: '12px',
    marginBottom: '8px',
  }

  const informationalStyle: CSSProperties = {
    display: 'inline-block',
    cursor: 'pointer',
    minWidth: '100px',
    textAlign: 'center',
    margin: '0 10px',
  }

  const notificationStyle: CSSProperties = {
    height: 4,
    width: 4,
    borderRadius: 100,
    backgroundColor: colors.util.two.main,
    marginRight: 2,
    display: 'inline-block',
    verticalAlign: 'middle',
  }

  const underlineStyle = (active: boolean): CSSProperties => {
    return {
      height: 2,
      width: '100%',
      backgroundColor: active ? colors.brand.secondary.main : 'transparent',
    }
  }

  const actionStyle: CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    margin: '0 10px',
  }

  const getTabContents = () =>
    activeTab ? (
      <Typography
        component={'span'}
        variant="text11semibold"
        size={14}
        color={textColor()}
      >
        {text}
      </Typography>
    ) : (
      <Typography
        component={'span'}
        variant="text11"
        size={14}
        color={textColor()}
      >
        {text}
      </Typography>
    )

  const getTextColor = (hover: boolean) =>
    hover ? colors.brand.secondary.light : colors.brand.secondary.main

  const getIconColor = (hover: boolean) =>
    hover ? colors.brand.secondary.light : colors.brand.secondary.main

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events, jsx-a11y/mouse-events-have-key-events
    <li
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex={0}
      onClick={handleClick}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      id={`tab-${id}`}
    >
      {variant === 'informational' ? (
        <div style={informationalStyle}>
          <div style={{ ...defaultTabStyles, ...styles }}>
            {!activeTab && indicator && (
              <span style={notificationStyle}>{indicator && indicator}</span>
            )}
            {getTabContents()}
          </div>
          {<div style={underlineStyle(activeTab)}></div>}
        </div>
      ) : (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <div style={actionStyle} onClick={handleAddTab}>
          <Typography
            component={'div'}
            styles={actionTypographyOverrides}
            color={getTextColor(hover)}
          >
            {text}
          </Typography>
          {icon && <Icon variant={icon} color={getIconColor(hover)}></Icon>}
        </div>
      )}
    </li>
  )
}

export default Tab
