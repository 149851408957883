import { TicketMttxResponse } from '@queries/ticket'
import { toTimeUnits } from '@utils/DateTimeUtils'

export type MttxKey = keyof TicketMttxResponse

export const formatMttxValue = (key: MttxKey, value: number | string) => {
  if (typeof value === 'number') {
    return toTimeUnits(value)
  }

  return value
}
