import { Box, Button, List, ListItem, Typography } from '@mui/material'
import { Dispatch } from 'react'

import Icon from '@common/Icon'
import useTicketEditContext from '@hooks/useTicketEditContext'

const TicketAttachmentsContainer = () => {
  const { setIsAttachmentsSideSheetOpen, attachments } = useTicketEditContext()

  return (
    <>
      <TicketAttachmentsTitle numberOfAttachments={attachments.length} />
      <List>
        {attachments.slice(0, 5).map(({ attachmentSysId, name }) => {
          return (
            <ListItem
              key={attachmentSysId}
              sx={(theme) => ({
                fontWeight: 600,
                ...theme.applyStyles('dark', {
                  color: theme.vars.palette.text.secondary,
                }),
              })}
            >
              {name}
            </ListItem>
          )
        })}
      </List>
      <Box sx={{ textAlign: 'center' }}>
        <TicketAttachmentsViewAll
          numberOfAttachments={attachments.length}
          closeSideSheet={setIsAttachmentsSideSheetOpen}
        />
      </Box>
    </>
  )
}

const TicketAttachmentsTitle: React.FC<{ numberOfAttachments: number }> = ({
  numberOfAttachments,
}) => {
  if (numberOfAttachments === 0) {
    return (
      <Typography variant="h5" sx={{ textAlign: 'center' }}>
        No attachments
      </Typography>
    )
  }

  return (
    <Typography
      variant="body1"
      fontWeight={600}
      sx={(theme) => ({
        ...theme.applyStyles('dark', {
          color: theme.vars.palette.text.secondary,
        }),
      })}
      pb={2}
    >
      Attachments{' '}
      <Typography
        component="span"
        variant="body1"
        fontWeight={600}
        sx={(theme) => ({
          color: theme.vars.palette.text.secondary,
        })}
      >
        ({numberOfAttachments > 0 ? numberOfAttachments : 0})
      </Typography>
    </Typography>
  )
}

const TicketAttachmentsViewAll: React.FC<{
  numberOfAttachments: number
  closeSideSheet: Dispatch<React.SetStateAction<boolean>>
}> = ({ numberOfAttachments, closeSideSheet }) => {
  if (numberOfAttachments >= 5) {
    return (
      <Button
        onClick={() => closeSideSheet(true)}
        sx={{
          ':hover': {
            background: 'transparent',
          },
          mt: 1,
        }}
      >
        <Typography
          component="span"
          mr={0.5}
          sx={(theme) => ({
            color: theme.vars.palette.primary.main,
          })}
          fontWeight={600}
        >
          View all attachments
        </Typography>
        <Icon
          variant="arrowForwardCircleOutline"
          sx={{
            height: '20px',
            width: '20px',
          }}
        />
      </Button>
    )
  }
  return null
}

export default TicketAttachmentsContainer
