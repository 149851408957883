import React from 'react'

import { useTheme } from '../../hooks'
import { Icon, Tooltip } from '../'
import { DeltaProps, IconVariant, TickDirection } from '../../interfaces'

import './Delta.scss'

type TickDirectionIconMap = {
  [key in TickDirection]: IconVariant
}
const Delta = ({ delta, tooltipMessage }: DeltaProps): JSX.Element => {
  const tickDirectionIconMappings: TickDirectionIconMap = {
    [TickDirection.UP]: 'arrowUpCircleOutline',
    [TickDirection.DOWN]: 'arrowDownCircleOutline',
    [TickDirection.NEUTRAL]: 'removeCircleOutline',
    [TickDirection.NOT_ENOUGH_DATA]: 'alertCircleOutline',
  }

  const isDarkTheme = useTheme('dark')
  let tickDirection: TickDirection = TickDirection.NEUTRAL

  if (delta !== null && typeof delta === 'string') {
    tickDirection =
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: Object is possibly 'null'.
      delta.match(/-?\d+/g).map(Number) > 0
        ? TickDirection.UP
        : TickDirection.DOWN
  } else if (delta === null) {
    tickDirection = TickDirection.NOT_ENOUGH_DATA
  } else {
    tickDirection = delta > 0 ? TickDirection.UP : TickDirection.DOWN
  }

  const abbreviatePercent = (percent: number): string => {
    if (Math.abs(percent) < 1000) {
      return `${percent}%`
    } else if (Math.abs(percent) < 1000000) {
      return `${(percent / 1000).toFixed(1)}K%`
    } else if (Math.abs(percent) < 1000000000) {
      return `${(percent / 1000000).toFixed(1)}M%`
    } else {
      return `${(percent / 1000000000).toFixed(1)}B%`
    }
  }

  const deltaValue = () => {
    if (!delta) {
      return <span>--</span>
    } else if (typeof delta === 'string') {
      return <span>{delta}</span>
    } else {
      return <span>{abbreviatePercent(delta)}</span>
    }
  }

  const deltaInfo = (
    <div className={isDarkTheme ? 'dw-delta dark' : 'dw-delta'}>
      <Icon variant={tickDirectionIconMappings[`${tickDirection}`]} />
      {deltaValue()}
    </div>
  )

  if (tickDirection === TickDirection.NOT_ENOUGH_DATA) {
    return <Tooltip target={deltaInfo}>{tooltipMessage}</Tooltip>
  }

  return deltaInfo
}

export default Delta
