import { useContext } from 'react'
import { Navigate } from 'react-router'

import { Context } from '../../App'
import ProfileOverview from './ProfileInformation/UserProfileInfo/ProfileOverview'

import './Profile.scss'

const Profile = () => {
  const {
    state: { homePath, user },
  } = useContext(Context)

  if (user.isDWEmployee) {
    return <Navigate to={homePath} />
  }

  return (
    <article
      id="profile-page"
      className="content"
      data-testid="user-profile-page"
    >
      <ProfileOverview />
    </article>
  )
}

export default Profile
