import { useLazyQuery } from '@apollo/client'

import {
  Button,
  CollapsiblePanel,
  Icon,
  InlineMessageType,
  Message,
} from '../../../design-system'
import { Report } from '../../../models'
import { SideSheet } from '../../SideSheet'
import { ReportData } from '../../../models/Report'
import {
  FormatStringDateRange,
  getFormattedDate,
} from '../../../utils/DateTimeUtils'
import { handleDownload } from '../../../utils'
import { DOWNLOAD_REPORT } from '../../../graphql/queries/report'

import './ReportDetails.scss'

interface ReportDetailsProps {
  isOpen: boolean
  closeSideSheet: () => void
  setIsOpen: (boolean) => void
  deleteReportCallback: () => void
  selectedReport?: Report
  selectedCustomer: string | null
}

const ReportDetails = ({
  isOpen = false,
  closeSideSheet,
  setIsOpen,
  deleteReportCallback,
  selectedReport,
  selectedCustomer,
}: ReportDetailsProps) => {
  const [downloadReport] = useLazyQuery(DOWNLOAD_REPORT, {
    onCompleted: ({ downloadReport: path }) => {
      handleDownload(path)
      setIsOpen(false)
    },
  })

  const deleteReport = () => {
    deleteReportCallback()
    setIsOpen(false)
  }

  const getErrorLabel = () => {
    if (selectedReport && selectedReport?.errors.length === 1) {
      return `${selectedReport?.errors.length} Error`
    } else {
      return `${selectedReport?.errors.length} Errors`
    }
  }

  const renderReportDetails = (reportData?: ReportData[]): JSX.Element[] => {
    if (reportData) {
      return reportData.map((data) => {
        let value
        if (data.metric !== undefined) {
          if (data.title === 'Customer Savings') {
            value = new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 0,
            }).format(Number(data.metric))
          } else {
            value = data.metric
          }
        } else {
          value = '- -'
        }

        return (
          <div className="item" key={crypto.randomUUID()}>
            <div>{data.title}</div>
            <div>{value}</div>
          </div>
        )
      })
    } else {
      return []
    }
  }

  return (
    <SideSheet
      open={isOpen}
      icon={<Icon variant="documentTextOutline" />}
      title={'Reports Details'}
      closeSideSheet={closeSideSheet}
      footer={
        <div
          data-testid="report-details-buttons"
          style={{
            display: 'flex',
            alignItems: 'center',
            margin: '16px',
            gap: '16px',
          }}
        >
          <Button
            variant="secondary"
            iconProps={{
              position: 'right',
              variant: 'trashOutline',
            }}
            iconStyles={{ marginLeft: 8 }}
            disabled={selectedReport?.status !== 'Generated'}
            onClick={() => deleteReport()}
          >
            Delete Report
          </Button>
          <Button
            disabled={selectedReport?.status !== 'Generated'}
            onClick={() => {
              downloadReport({
                variables: {
                  input: { reportId: selectedReport?.id },
                  selectedCustomer,
                },
              })
            }}
          >
            Download Report
          </Button>
        </div>
      }
    >
      <div id="reports-sidesheet">
        <div>
          <div className="content-header">{'Highlights'}</div>
        </div>

        {selectedReport?.reportData !== null && (
          <div className="grid-one" data-testid="reportHighlights">
            {renderReportDetails(selectedReport?.reportData)}
          </div>
        )}

        <div className="grid-two">
          <div className="item">
            <div>Report name</div>
            <div>{selectedReport?.name}</div>
          </div>

          <div className="item">
            <div>Date created</div>
            <div>
              {selectedReport &&
                getFormattedDate(selectedReport?.createdAt, 'P')}
            </div>
          </div>

          <div className="item">
            <div>Report date range</div>
            <div>
              {selectedReport &&
                FormatStringDateRange(
                  selectedReport?.startDate,
                  selectedReport?.endDate,
                  'MMM dd, yyyy',
                )}
            </div>
          </div>

          <div className="item">
            <div>User</div>
            <div>{selectedReport?.userName}</div>
          </div>
        </div>

        {selectedReport &&
          selectedReport?.errors !== null &&
          selectedReport?.errors?.length > 0 && (
            <CollapsiblePanel
              label={getErrorLabel()}
              iconType="alertCircle"
              iconColor="rgba(255, 153, 185, 1)"
            >
              {selectedReport.errors.map(({ title, messages }, index) => (
                <Message
                  text={messages}
                  title={`${title} page(s) did not generate`}
                  messageType={InlineMessageType['ErrorInline']}
                  key={index}
                />
              ))}
            </CollapsiblePanel>
          )}
      </div>
    </SideSheet>
  )
}

export default ReportDetails
