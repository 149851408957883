import { ResponsiveLine } from '@nivo/line'

import { AreaLayer } from './MaturityLine.customLayers'
import {
  displayToolTipItem,
  setLineOpacity,
  setStrokeColor,
} from './MaturityBan.utils'
import {
  FormattedMaturityScoreDataInterface,
  MaturityScoreCoordinates,
} from '../../../Home/Dashboard/charts/MaturityScore/MaturityScore.models'
import { Typography, colors } from '../../../../design-system'

const MaturityLine = ({ data, toggles, maxTrend }): JSX.Element => {
  //This function must be in the same file as Nivo only allows the default parameters below and no custom parameters such as state variables or props.
  const StyledMaturityLines = ({
    series,
    lineGenerator,
    xScale,
    yScale,
  }): React.ReactNode => {
    return series.map(({ id, data }, index: number) => (
      <path
        key={id}
        d={lineGenerator(
          data.map((d) => ({
            x: xScale(d.data.x),
            y: yScale(d.data.y),
          })),
        )}
        fill="none"
        stroke={setStrokeColor(index)}
        style={{
          ...setLineOpacity(index, toggles),
          pointerEvents: 'none',
        }}
      />
    ))
  }

  const maxValue =
    Math.max(
      ...data
        .map((item: FormattedMaturityScoreDataInterface) => item.data)
        .flat()
        .map((item: MaturityScoreCoordinates) => item.y),
    ) + 0.25
  const lastCustomerDataPoint = data[0].data.length
  return (
    <>
      <ResponsiveLine
        axisBottom={null}
        axisLeft={null}
        data={data}
        gridXValues={data[0].data.map(({ x }) => x)}
        margin={{ bottom: 0, left: 0, right: 0, top: 0 }}
        markers={
          lastCustomerDataPoint === 0
            ? [
                {
                  axis: 'x',
                  value: data[0].data[0].x,
                  lineStyle: {
                    stroke: '#A2BBC3',
                    strokeWidth: 0,
                  },
                },
              ]
            : [
                {
                  axis: 'x',
                  value: data[0].data[lastCustomerDataPoint - 1].x,
                  lineStyle: {
                    stroke: '#A2BBC3',
                    strokeWidth: 1,
                  },
                },
              ]
        }
        enablePoints={false}
        enablePointLabel={false}
        xScale={{ type: 'point' }}
        yScale={{
          type: 'linear',
          max: maxValue > 10 ? 10 : maxValue,
          min: 0,
        }}
        enableArea={true}
        enableCrosshair={false}
        enableGridX={false}
        enableGridY={false}
        enableSlices="x"
        curve={'linear'}
        isInteractive={true}
        layers={[
          'grid',
          'markers',
          'axes',
          AreaLayer,
          'crosshair',
          'slices',
          'points',
          'mesh',
          'legends',
          StyledMaturityLines,
        ]}
        sliceTooltip={({ slice }) => {
          const reOrderedPoints = [
            slice.points.find((point) => point.serieId === 'customer'),
            slice.points.find((point) => point.serieId === 'industry'),
            slice.points.find((point) => point.serieId === 'deepwatch'),
          ].filter((point) => point !== undefined)
          const initialDate = String(slice.points[0].data.x)
          const [year, month, date] = initialDate.split('-')
          const formattedDate = `${month}/${date}/${year}`
          return (
            <div
              className="flex-container column"
              style={{
                backgroundColor: colors.util.navy[600],
                borderRadius: 3,
                border: '0.75px solid #576569',
              }}
            >
              <Typography component="span" size={12} styles={{ padding: 4 }}>
                {formattedDate}
              </Typography>
              <div
                className="flex-container column gap-8"
                style={{ padding: 4, borderTop: '0.75px solid #576569' }}
              >
                <div className="flex-container align-items-center">
                  <div className="customer-trend" />
                  <Typography component="div" size={12}>
                    {maxTrend.toFixed(2)} - With recommended detections
                  </Typography>
                </div>
                {reOrderedPoints.map((point) =>
                  displayToolTipItem(point, toggles),
                )}
              </div>
            </div>
          )
        }}
      />
    </>
  )
}

export default MaturityLine
