import { gql } from '@apollo/client'

export const ADD_BOOKMARKED_CUSTOMER = gql`
  mutation AddBookmarkedCustomer($customers: [String]) {
    addBookmarkedCustomer(input: { customers: $customers }) {
      customerShortName
      name
      usageLicenseGb
      usageGb
      openCriticalCount
      openWaitingDeepwatchCount
      isBookmarkedCustomer
    }
  }
`
export const REMOVE_BOOKMARKED_CUSTOMER = gql`
  mutation RemoveBookmarkedCustomer($customers: [String]) {
    removeBookmarkedCustomer(input: { customers: $customers }) {
      customerShortName
      name
      usageLicenseGb
      usageGb
      openCriticalCount
      openWaitingDeepwatchCount
      isBookmarkedCustomer
    }
  }
`
