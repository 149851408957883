import { colors } from '../../../design-system'
import {
  DetectionCoverageOverviewData,
  DetectionCoverageOverviewResponse,
  MitreCoverageByTactic,
  MitreCoverageCustomer,
  MitreCoverageTactic,
  SplunkUtilization,
} from '../../../models/DetectionCoverage'
import { FormattedMaturityScoreDataInterface } from '../../Home/Dashboard/charts/MaturityScore/MaturityScore.models'
import {
  calculateAvgScore,
  calculateScoreDiff,
} from '../../Home/Dashboard/charts/MaturityScore/MaturityScore.utils'
import { LineChartData } from '../charts/ChartTypes'
import {
  formatLineChartData,
  getToolTipDates,
} from '../charts/LogSourceVolume/LogSourceUtils'
import { formatMaturityData } from '../charts/MaturityBan/MaturityBan.utils'

export const getCoverageByTacticProperties = (
  id: string,
  data: DetectionCoverageOverviewResponse,
): Pick<MitreCoverageByTactic, 'totalUseCaseCount' | 'version'> => {
  return data.mitreCoverageByTactic.find(
    ({ mitreTacticId, totalUseCaseCount, version }) => {
      if (mitreTacticId === id) {
        return {
          totalUseCaseCount,
          version,
        }
      }
    },
  ) as Pick<MitreCoverageByTactic, 'totalUseCaseCount' | 'version'>
}

/**
 * This method generates the mitreCoverage field by iterating over all of the mitreCoverage values
 * from the API and creating an array of a mitre tactics
 * @param data The detection coverage overview response
 * @returns The data from the detection coverage overview response and
 * the generated mitreCoverage field
 */
export const formatOverviewResponse = (
  data: DetectionCoverageOverviewResponse,
): NonNullable<DetectionCoverageOverviewData> => {
  const detectionCoverageOverviewTactics: MitreCoverageTactic[] = []
  //Group by MITRE Tactic, data from BE comes grouped by Technique
  data.mitreCoverage.forEach((technique) => {
    if (
      !detectionCoverageOverviewTactics.some(
        (tactic) => tactic.mitreTacticId === technique.mitreTacticId,
      )
    ) {
      detectionCoverageOverviewTactics.push({
        mitreTacticId: technique.mitreTacticId,
        mitreTacticName: technique.mitreTacticName,
        mitreTacticSequence: technique.mitreTacticSequence,
        mitreTacticDescription: technique.mitreTacticDescription,
        techniques: data.mitreCoverage.filter(
          (dwTechnique) =>
            dwTechnique.mitreTacticId === technique.mitreTacticId,
        ),
        ...getCoverageByTacticProperties(technique.mitreTacticId, data),
      })
    }
  })
  return {
    ...data,
    mitreCoverage: detectionCoverageOverviewTactics,
  }
}

export interface DetectionCoverageDisplayValues {
  latestSplunkUtilization?: SplunkUtilization
  currentSplunkUsage: number
  coverage?: {
    dw: MitreCoverageTactic[]
    customer: MitreCoverageCustomer[]
  }
  availableUseCases: number
  enabledUseCases: number
  logWeekOverWeekData: 0 | LineChartData[]
  toolTipDates: string[]
  formattedData: 0 | FormattedMaturityScoreDataInterface[]
  custAvg: number
  dwAvg: number
  industryAvg: number
  maxValCustTrend: number
  scoreDiff: string
}

/**
 * This method takes the detection coverage response and returns the render variables needed for the coverage page
 *
 * @param detectionCoverageOverview The detection coverage data returned from the API
 * @returns The generated display values
 */
export const getDetectionCoverageDisplayValues = (
  detectionCoverageOverview?: DetectionCoverageOverviewData,
): DetectionCoverageDisplayValues => {
  const latestSplunkUtilization =
    detectionCoverageOverview?.splunkUtilization.splunkUsage.at(
      detectionCoverageOverview.splunkUtilization.splunkUsage.length - 1,
    )

  const currentSplunkUsage = latestSplunkUtilization
    ? latestSplunkUtilization.totalGb
    : 0

  const coverage = detectionCoverageOverview && {
    dw: detectionCoverageOverview.mitreCoverage,
    customer: detectionCoverageOverview.mitreCoverageCustomer,
  }

  const availableUseCases = !detectionCoverageOverview
    ? 0
    : detectionCoverageOverview.availableDetections

  const enabledUseCases = !detectionCoverageOverview
    ? 0
    : detectionCoverageOverview.enabledDetections

  const logWeekOverWeekData =
    !detectionCoverageOverview?.splunkUtilizationWeekTrend
      ? 0
      : formatLineChartData(
          detectionCoverageOverview.splunkUtilizationWeekTrend,
          colors.brand.secondary.main,
          colors.util.one.lighter,
        )

  const toolTipDates = !detectionCoverageOverview?.splunkUtilizationWeekTrend
    ? []
    : getToolTipDates(
        detectionCoverageOverview?.splunkUtilizationWeekTrend.currentWeek,
      )

  const formattedData: FormattedMaturityScoreDataInterface[] | 0 =
    !detectionCoverageOverview
      ? 0
      : formatMaturityData(detectionCoverageOverview?.maturityTrends)

  const custAvg: number = !detectionCoverageOverview
    ? 0
    : calculateAvgScore(formattedData[0].data)
  const dwAvg: number = !detectionCoverageOverview
    ? 0
    : calculateAvgScore(formattedData[1].data)
  const industryAvg: number = !detectionCoverageOverview
    ? 0
    : calculateAvgScore(formattedData[2].data)

  const maxValCustTrend: number = !formattedData[0]
    ? 0
    : formattedData[0].data.reduce((prev, current) => {
        if (prev.y > current.y) {
          return prev
        }

        return current
      }, 0).y

  const scoreDiffValue = calculateScoreDiff(custAvg, dwAvg)

  const scoreDiff = isNaN(scoreDiffValue)
    ? '0'
    : Number(calculateScoreDiff(custAvg, dwAvg)).toFixed(2)

  return {
    currentSplunkUsage,
    coverage,
    availableUseCases,
    enabledUseCases,
    logWeekOverWeekData,
    toolTipDates,
    formattedData,
    custAvg,
    dwAvg,
    industryAvg,
    maxValCustTrend,
    scoreDiff,
  }
}

export interface LogSource<T extends string | number> {
  label: string
  value: T
  progress: number
}

export interface MitreOverviewValues {
  logSourceByUtilization: LogSource<string>[]
  logSourceByAlert: LogSource<number>[] | undefined
  mitreDeepWatchTechniqueData: number
  mitreCustomerTechniqueData: number
  deepWatchTacticsData: number
  customerTacticsData: number
}

/**
 * This method takes the overview response data and returns the overview values used
 * in the MITRE Overview component
 *
 * @param data The detection coverage overview response
 * @returns The MITRE overview data
 */
export const getMitreOverviewData = (
  data: NonNullable<DetectionCoverageOverviewData>,
): MitreOverviewValues => {
  const logSourceByUtilization = data.logSourceByUtilization.map((log) => {
    return {
      label: log.source,
      value: Math.round(log.totalGb) + ' GB',
      progress: log.utilizationPercentage,
    }
  })

  const logSourceByAlert = data.logSourceByAlert?.map((log) => {
    return {
      label: log.source,
      value:
        log.incidentCount < 1 && log.incidentCount > 0
          ? Math.ceil(log.incidentCount)
          : Math.round(log.incidentCount),
      progress: log.alertsPercentage,
    }
  })

  /**
   * There is overlap between some of the tactics' techniques and
   * "appliedTechniques" is used below for both mitreDeepWatchTechniqueData
   * and mitreCustomerTechniqueData to make sure we are only uniquely aggregating
   * each technique (SON-2019).
   **/
  let appliedTechniques: string[] = []
  const mitreDeepWatchTechniqueData = data.mitreCoverage.reduce(
    (acc, tactic) => {
      return (
        acc +
        tactic.techniques.filter((technique) => {
          if (!appliedTechniques.includes(technique.mitreTechniqueId)) {
            appliedTechniques.push(technique.mitreTechniqueId)
            return technique.totalUseCaseCount > 0
          }
        }).length
      )
    },
    0,
  )
  appliedTechniques = []
  const mitreCustomerTechniqueData = data.mitreCoverageCustomer.reduce(
    (acc, technique) => {
      if (!appliedTechniques.includes(technique.mitreTechniqueId)) {
        appliedTechniques.push(technique.mitreTechniqueId)
        return technique.totalEnabledUseCaseCount > 0 ? acc + 1 : acc
      }
      return acc
    },
    0,
  )

  const deepWatchTacticsData = new Set(
    data?.mitreCoverage.map((tact) => tact.mitreTacticId),
  ).size

  const customerTacticsData = data?.mitreCoverageCustomer.reduce(
    (acc, technique) => {
      if (
        !acc.includes(technique.mitreTacticId) &&
        technique.totalEnabledUseCaseCount > 0
      ) {
        acc.push(technique.mitreTacticId)
      }
      return acc
    },
    [] as string[],
  ).length

  return {
    logSourceByUtilization,
    logSourceByAlert,
    mitreDeepWatchTechniqueData,
    mitreCustomerTechniqueData,
    deepWatchTacticsData,
    customerTacticsData,
  }
}
