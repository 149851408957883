import { useSearchParams } from 'react-router-dom'

import { SecurityIndexTabs } from './components/SecurityIndexTabs'
import SecurityIndexBans from './components/Bans/SecurityIndexBans'
import { SecurityIndexContextProvider } from './context/SecurityIndexContext'

//! MSW breaks e2e tests in the pipelines. Only use while developing
// if (process.env.NODE_ENV === 'development') {
//   const { worker } = require('../../../mocks/browser')
//   worker.start()
// }

const SecurityIndex = () => {
  const [searchParams] = useSearchParams()

  const selectedCustomer = searchParams.get('customer') || ''

  return (
    <SecurityIndexContextProvider selectedCustomer={selectedCustomer}>
      <div
        id="security-index"
        data-testid="security-index"
        className="flex column gap-24"
        style={{ width: 1132 }} // TODO: static width should be removed when dashboard is ready for flex layout
      >
        <SecurityIndexBans />
        <SecurityIndexTabs />
      </div>
    </SecurityIndexContextProvider>
  )
}

export default SecurityIndex
