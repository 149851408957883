import React, { FunctionComponent, useContext, CSSProperties } from 'react'

import { colors } from '../../../theme'
import { useTheme } from '../../../hooks'
import { MobileTableContext } from '../MobileTableContext'
import { MobileTableRowProps } from './MobileTableRow.types'
import { ButtonProps } from '../../../interfaces'
import { Button } from '../../Button'
import { Icon } from '../../Icon'

import './MobileTableRow.scss'

const MobileTableRow: FunctionComponent<MobileTableRowProps> = ({
  rowData,
  tableHeaders,
  handleOnClick,
}: any): JSX.Element => {
  const isDarkTheme = useTheme('dark')
  const mobileTableRowProps = useContext(MobileTableContext)
  const iconColor = rowData.disabled
    ? colors.util.navy[100]
    : colors.brand.secondary.main

  const tableBodyTextColor = (
    bodyTextColor: string,
    isDarkTheme: boolean,
  ): string => {
    if (bodyTextColor) {
      return bodyTextColor
    } else if (isDarkTheme) {
      return colors.util.one.lighter
    } else {
      return colors.util.one.main
    }
  }

  const trClasses = (value: any): string => {
    if (value.disabled || mobileTableRowProps.loading) {
      return 'mobile-disabled-row'
    } else if (
      handleButtonClick === undefined &&
      mobileTableRowProps.buttonElements.length === 0
    ) {
      return `tr-no-click ${isDarkTheme ? 'tr-dark' : 'tr-light'}`
    } else {
      return ''
    }
  }

  const getTableEntryClass = (value: any, keyIndex: number): CSSProperties => {
    let dataStyle: CSSProperties
    const obj = Object.values(value)[keyIndex as number]
    if (obj === 'Error') {
      dataStyle = {
        color: colors.util.orange[100],
      }
    } else {
      dataStyle = {
        color: value.disabled
          ? colors.util.one.light
          : tableBodyTextColor(mobileTableRowProps.bodyTextColor, isDarkTheme),
      }
    }
    return dataStyle
  }

  const handleCardClicked = (
    id: number | string,
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    e.stopPropagation()
    handleOnClick?.(id)
  }

  const handleButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    buttonPropValue: ButtonProps,
  ) => {
    if (
      (rowData.disabled === undefined || rowData.disabled === false) &&
      mobileTableRowProps.loading === false
    ) {
      e.stopPropagation()
      buttonPropValue.onClick?.(e)
      buttonPropValue.customOnClick?.(rowData)
    }
  }

  const handleIconButtonClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    buttonPropValue: ButtonProps,
  ) => {
    if (
      (rowData.disabled === undefined || rowData.disabled === false) &&
      mobileTableRowProps.loading === false
    ) {
      e.stopPropagation()
      buttonPropValue.customOnClick?.(e)
      buttonPropValue.customOnClick?.(rowData)
    }
  }

  const handleKeyPress = (event, buttonPropValue) => {
    if (event.key === 'Enter') {
      handleIconButtonClick(event, buttonPropValue)
    }
  }

  const handleCellKeyPress = (event, rowData) => {
    if (
      (rowData?.disabled === undefined || rowData?.disabled === false) &&
      mobileTableRowProps.loading === false &&
      event.key === 'Enter'
    ) {
      handleCardClicked(rowData?.id, event)
    }
  }

  const buttonRow = (rowData: any) => {
    if (mobileTableRowProps.buttonElements.length === 0) {
      return <></>
    } else if (mobileTableRowProps.buttonElements.length <= 2) {
      return (
        <div className={'mobile-table-button-row ' + trClasses(rowData)}>
          {mobileTableRowProps.buttonElements.map(
            (buttonPropValue: ButtonProps, index) => {
              return (
                <Button
                  disabled={rowData.disabled}
                  key={index}
                  {...buttonPropValue}
                  onClick={(e) => {
                    handleButtonClick(e, buttonPropValue)
                  }}
                />
              )
            },
          )}
        </div>
      )
    } else {
      return (
        <div className="mobile-table-icon-button-row">
          {mobileTableRowProps.buttonElements.map(
            (buttonPropValue: ButtonProps, index) => {
              return (
                <div
                  role="button"
                  tabIndex={index}
                  className={'mobile-table-icon-button ' + trClasses(rowData)}
                  key={index}
                  onKeyDown={(event) => handleKeyPress(event, buttonPropValue)}
                  onClick={(e) => {
                    handleIconButtonClick(e, buttonPropValue)
                  }}
                >
                  <div className="icon-container">
                    {
                      <Icon
                        variant={buttonPropValue.iconProps?.variant}
                        color={iconColor}
                        size={buttonPropValue.iconProps?.size || 24}
                      />
                    }
                  </div>
                </div>
              )
            },
          )}
        </div>
      )
    }
  }

  return (
    <div>
      {Object.keys(rowData).map((key, keyIndex) => {
        return (
          key !== 'disabled' &&
          tableHeaders.at(keyIndex)?.visible && (
            <div
              data-label={tableHeaders.at(keyIndex)?.name?.toUpperCase()}
              key={keyIndex}
              style={getTableEntryClass(rowData, keyIndex)}
            >
              <div
                role="button"
                tabIndex={keyIndex}
                className={'mobile-table-row ' + trClasses(rowData)}
                onKeyDown={(event) => handleCellKeyPress(event, rowData)}
                onClick={(e) => {
                  if (
                    (rowData?.disabled === undefined ||
                      rowData?.disabled === false) &&
                    mobileTableRowProps.loading === false
                  ) {
                    handleCardClicked(rowData?.id, e)
                  }
                }}
              >
                <div className="mobile-table-header">
                  {tableHeaders.at(keyIndex)?.name?.toUpperCase()}
                </div>
                <div className="mobile-table-display-value">
                  {Object.keys(rowData[`${key}`]).includes('displayValue')
                    ? rowData[`${key}`].displayValue
                    : rowData[`${key}`]}
                </div>
              </div>
            </div>
          )
        )
      })}
      {buttonRow(rowData)}
    </div>
  )
}

export default MobileTableRow
