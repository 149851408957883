import { FunctionComponent } from 'react'

import * as Icons from './Icons'

import './NavIcon.scss'

interface SectionTitlePropsT {
  variant: string
  size?: number
  className?: string
}

const SectionTitle: FunctionComponent<SectionTitlePropsT> = ({
  variant,
  size = 0,
  className = '',
}) => {
  const selectIcon = (variant: string) => {
    switch (variant) {
      case 'Collapse':
        return Icons.Collapse
      case 'Coverage':
        return Icons.Coverage
      case 'Dashboard':
        return Icons.Dashboard
      case 'Expand':
        return Icons.Expand
      case 'Reports':
        return Icons.Reports
      default:
        return null
    }
  }

  return (
    <div className={className ? className : 'sidebar-navlink-icon'}>
      <img
        src={selectIcon(variant)}
        alt={variant}
        style={size > 0 ? { height: `${size}px` } : {}}
      />
    </div>
  )
}

export default SectionTitle
