import { colors } from '../../../../design-system/theme'
import { Icon, IconVariant } from '../../../../design-system'

export const formatTicketDetailsPriority = (priority: string): JSX.Element => {
  let priorityIcon: IconVariant
  let priorityColor: string
  switch (priority) {
    case 'Critical':
      priorityIcon = 'skull' as IconVariant
      priorityColor = colors.util.two.main
      break
    case 'High':
      priorityIcon = 'alertCircle' as IconVariant
      priorityColor = colors.util.orange[200]
      break
    case 'Moderate':
      priorityIcon = 'disc' as IconVariant
      priorityColor = colors.util.four.dark
      break
    case 'Low':
      priorityIcon = 'arrowDownCircleSharp' as IconVariant
      priorityColor = colors.brand.secondary.light
      break
    case 'Informational':
      priorityIcon = 'informationCircleSharp' as IconVariant
      priorityColor = colors.brand.secondary.lighter
      break
    default:
      priorityIcon = 'skull' as IconVariant
      priorityColor = colors.util.two.main
      break
  }
  return (
    <>
      <Icon
        style={{ paddingRight: '5px' }}
        variant={priorityIcon}
        size={16}
        color={priorityColor}
      />
      <div>{priority}</div>
    </>
  )
}

export const formatEmptyField = (value: string | null) => {
  if (value === null || value === '') {
    return '--'
  }
  return value
}
