import { useContext, useEffect } from 'react'
import {
  Route,
  Routes,
  Navigate,
  useLocation,
  useNavigate,
  Outlet,
} from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { ErrorBoundary } from 'react-error-boundary'
import { Box, useColorScheme } from '@mui/material'

import Protected from '@components/Routes/Protected'
import { UserManagementSettings, Modules } from '@components/Settings'
import TermsOfService from '@components/TermsOfService/TermsOfService'
import { SessionStatus } from '@config/OktaAuthConfig'
import { navigateUserType } from '@utils/index'
import { AwaitCustomer, useCustomer } from '@hooks/useCustomer'
import { SecurityIndex } from '@components/Home/SecurityIndex'
import CompanyOverview from '@components/Settings/CompanyInfo/Overview/CompanyOverview'
import ProfileOverview from '@components/Settings/Profile/ProfileInformation/UserProfileInfo/ProfileOverview'
import { DeepwatchExpertsHome } from '@components/DeepwatchExperts/DeepwatchExpertsHome'
import { ThreatIntelReportsContextProvider } from '@components/ThreatIntel/ThreatIntelReports/context'
import { ThreatIntelReports } from '@components/ThreatIntel/ThreatIntelReports'
import ThreatIntelFullReport from '@components/ThreatIntel/ThreatIntelReports/components/ThreatIntelFullReport/ThreatIntelFullReport'
import Overview from '@components/EnvironmentHealth/Overview/Overview'
import EDROverview from '@components/EDR/EDROverview/EDROverview'
import EDRAssetLibrary from '@components/EDR/EDRAssetLibrary'
import { FirewallOverview } from '@components/Firewall/FirewallOverview'
import { Forwarders } from '@components/EnvironmentHealth/Forwarders'
import { TopNav } from '@components/Layout/TopNav'
import { ToastMessage } from '@common/ToastMessage'
import { useToast } from '@hooks/useToast'
import SkipLink from '@common/SkipLink'
import { handleDeepLinkFormatting } from '@utils/handleDeepLinking'
import { doesCustomerHaveSupportedEDRProvider } from '@hooks/useEDRContent/useEDRContent'

import { Context } from './Provider'
import {
  Coverage,
  Layout,
  Loading,
  Login,
  Reports,
  Tickets,
  Dashboard,
} from '../'
import { ErrorFallback } from './Errors'
import { Paths } from './Types'
import { zIndex } from './Styles/zIndex'

export const App = () => {
  const {
    state: {
      globalLoading,

      user,
      homePath,
      oktaSession,
      dwExpertsCustomer: { customerShortName },
    },
  } = useContext(Context)

  const { pathname, search } = useLocation()
  const navigate = useNavigate()
  const { setMode } = useColorScheme()

  const { isDWEmployee } = user

  const {
    environmentDebug,
    featureEnvHealth,
    featureNgMedrV1,
    featureNgMdr,
    featureMfw,
    featureLightThemeToggle,
    featureDwToCustomerDeepLink,
  } = useFlags()

  if (!featureLightThemeToggle) {
    setMode('dark')
  }

  const { handleToastClose, toastMessages, open } = useToast()

  const {
    customer: {
      isLimitedMdrExperience,
      ngMedrEnabled,
      ngMfwEnabled,
      edrEnabled,
      serviceNgMedrTechnology,
    },
    isLoading,
  } = useCustomer()

  const isLoadingOrEnabled = (
    moduleStatus: boolean | undefined,
    loading: boolean,
  ) => {
    const statusLoading = moduleStatus === undefined && loading
    return statusLoading || moduleStatus
  }

  const showMdrFeatures = !featureNgMdr || !isLimitedMdrExperience
  const showMedrFeatures =
    featureNgMedrV1 && isLoadingOrEnabled(ngMedrEnabled, isLoading)

  const showEdrFeatures =
    !!featureNgMedrV1 &&
    !!isLoadingOrEnabled(edrEnabled, isLoading) &&
    doesCustomerHaveSupportedEDRProvider({
      serviceNgMedrTechnology,
    })

  const showMfwFeatures =
    featureMfw && isLoadingOrEnabled(ngMfwEnabled, isLoading)

  const oldToNewRoutes = {
    '/reports': 'insights',
  }

  let navigateTicketRoute: string = Paths.TICKET_OVERVIEW

  if (isDWEmployee) {
    navigateTicketRoute = `${navigateTicketRoute}${search}`
  }

  /**
   * this is used for redirecting users for updated URLs
   */
  useEffect(() => {
    // eslint-disable-next-line security/detect-object-injection
    const redirectTo = oldToNewRoutes[pathname]

    if (redirectTo) {
      navigateUserType(isDWEmployee, redirectTo, navigate, customerShortName)
    }

    if (!featureDwToCustomerDeepLink && !localStorage.getItem('isDw')) {
      handleDeepLinkFormatting(search, pathname, navigate)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  if (environmentDebug) {
    // eslint-disable-next-line no-console
    console.log('process.env.ENV: ', process.env.ENV)
  }

  /* The app is initialized with globalLoading set to true. This helps avoid
  flashes of the root page while data is loading. However, it means that we
  must always ignore the loading state on the login page. */
  if (
    (globalLoading && pathname !== Paths.LOGIN) ||
    (isLoading && !isDWEmployee && isDWEmployee !== undefined)
  ) {
    return <Loading />
  }

  return (
    <Box
      data-testid="apptest"
      sx={(theme) => ({
        backgroundColor: theme.vars.palette.common.white,
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        ...theme.applyStyles('dark', {
          backgroundColor: theme.vars.palette.secondary.dark,
        }),
      })}
    >
      <SkipLink text="Skip to main content" />
      <Box
        data-testid="toast-messages-container"
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          gap: '0.5rem',
          position: 'absolute',
          top: '0.5rem',
          right: '0.5rem',
          zIndex: zIndex.MAX,
        }}
      >
        {toastMessages!.map((message) => {
          return (
            <ToastMessage
              key={message.id}
              message={message}
              handleToastClose={handleToastClose}
              open={open}
            />
          )
        })}
      </Box>

      <Routes>
        <Route
          path={Paths.LOGIN}
          element={redirectLogin({ oktaSession, homePath })}
        />
        <Route path={Paths.ERROR} element={<ErrorFallback />} />
        <Route path={Paths.TERMS} element={<TermsOfService />} />
        <Route
          path={Paths.ROOT}
          element={<Protected>{setHomePath(homePath)}</Protected>}
        />

        {isDWEmployee && (
          <Route
            element={
              <>
                <TopNav />
                <Outlet />
              </>
            }
          >
            <Route
              path={Paths.DEEPWATCH_EXPERTS}
              element={
                <Protected>
                  <DeepwatchExpertsHome />
                </Protected>
              }
            />
            {/* Render the profile overview without the side nav if a DW expert has not selected a customer */}
            {!customerShortName ? (
              <Route
                path={Paths.PROFILE}
                element={
                  <ErrorBoundary fallbackRender={() => <ErrorFallback />}>
                    <ProfileOverview />
                  </ErrorBoundary>
                }
              />
            ) : null}
          </Route>
        )}

        <Route element={<Layout />}>
          <Route
            path={Paths.DASHBOARD}
            element={
              <Protected>
                <Navigate to={`${Paths.DASHBOARD_OVERVIEW}${search}`} />
              </Protected>
            }
          />
          <Route
            path={Paths.MDR}
            element={
              <Protected>
                <Navigate to={`${Paths.MDR_OVERVIEW}${search}`} />
              </Protected>
            }
          />
          <Route
            path={Paths.DASHBOARD_OVERVIEW}
            element={
              <Protected>
                <Dashboard />
              </Protected>
            }
          />
          <Route
            path={Paths.MDR_OVERVIEW}
            element={
              <Protected>
                <Dashboard />
              </Protected>
            }
          />

          <Route path={Paths.COMPANY_INFO} element={<CompanyOverview />} />

          {/* Render the profile overview in the normal layout for customers, or DW experts who have selected a customer */}
          {isDWEmployee && !!customerShortName ? (
            <Route
              path={Paths.PROFILE}
              element={
                <ErrorBoundary fallbackRender={() => <ErrorFallback />}>
                  <ProfileOverview />
                </ErrorBoundary>
              }
            />
          ) : null}

          {!isDWEmployee && (
            <>
              <Route
                path={Paths.PROFILE}
                element={
                  <ErrorBoundary fallbackRender={() => <ErrorFallback />}>
                    <ProfileOverview />
                  </ErrorBoundary>
                }
              />
              <Route
                path={Paths.SETTINGS}
                element={<Navigate to={Paths.COMPANY_INFO} />}
              />
              <Route
                path={Paths.USER_MANAGEMENT}
                element={
                  <ErrorBoundary fallbackRender={() => <ErrorFallback />}>
                    <UserManagementSettings />
                  </ErrorBoundary>
                }
              />
            </>
          )}
          <Route
            path={Paths.INSIGHTS}
            element={
              <Protected>
                <Reports />
              </Protected>
            }
          />
          <Route
            path={Paths.TICKETS}
            element={
              <Protected>
                <Navigate to={navigateTicketRoute} />
              </Protected>
            }
          />

          {showMdrFeatures && (
            <>
              <Route
                path={Paths.DETECTION_COVERAGE}
                element={
                  <Protected>
                    <Navigate
                      to={`${Paths.DETECTION_COVERAGE_OVERVIEW}${search}`}
                    />
                  </Protected>
                }
              />
              <Route
                path={Paths.DETECTION_COVERAGE_OVERVIEW}
                element={
                  <Protected>
                    <Coverage activeTab={0} />
                  </Protected>
                }
              />
              <Route
                path={Paths.DETECTION_CATALOG}
                element={
                  <Protected>
                    <Coverage activeTab={1} />
                  </Protected>
                }
              />
            </>
          )}
          <Route
            path={Paths.TICKET_OVERVIEW}
            element={
              <Protected>
                <Tickets component="overview" />
              </Protected>
            }
          />
          <Route
            path={Paths.TICKET_LIBRARY}
            element={
              <Protected>
                <Tickets component="library" />
              </Protected>
            }
          />
          <Route
            path={Paths.TICKET_EDIT}
            element={
              <Protected>
                <Tickets component="edit" />
              </Protected>
            }
          />
          <Route
            path={Paths.THREAT_INTEL_REPORTS}
            element={
              <Protected>
                <ThreatIntelReportsContextProvider>
                  <ThreatIntelReports />
                </ThreatIntelReportsContextProvider>
              </Protected>
            }
          />
          <Route
            path={Paths.THREAT_INTEL_FULL_REPORT}
            element={
              <Protected>
                <ThreatIntelFullReport />
              </Protected>
            }
          />
          {showMdrFeatures && (
            <>
              <Route
                path={`${Paths.MDR_SECURITY_INDEX}${Paths.YOUR_INDEX}`}
                element={
                  <Protected>
                    <SecurityIndex />
                  </Protected>
                }
              />
              <Route
                path={`${Paths.SECURITY_INDEX}${Paths.YOUR_INDEX}`}
                element={
                  <Protected>
                    <SecurityIndex />
                  </Protected>
                }
              />
            </>
          )}
          {showMdrFeatures && (
            <>
              <Route
                path={`${Paths.MDR_SECURITY_INDEX}${Paths.CHANGE_HISTORY}`}
                element={
                  <Protected>
                    <SecurityIndex />
                  </Protected>
                }
              />
              <Route
                path={`${Paths.SECURITY_INDEX}${Paths.CHANGE_HISTORY}`}
                element={
                  <Protected>
                    <SecurityIndex />
                  </Protected>
                }
              />
            </>
          )}
          {featureEnvHealth && showMdrFeatures && (
            <Route
              path={Paths.ENVIRONMENT}
              element={
                <Protected>
                  <Overview />
                </Protected>
              }
            />
          )}
          <Route
            path={Paths.FORWARDERS}
            element={
              <Protected>
                <Forwarders />
              </Protected>
            }
          />

          <Route
            path={Paths.MODULES}
            element={
              <Protected>
                <Modules />
              </Protected>
            }
          />

          {featureNgMedrV1 && (showMedrFeatures || showEdrFeatures) && (
            <>
              <Route
                path={Paths.EDR}
                element={
                  <Protected>
                    <AwaitCustomer>
                      <EDROverview />
                    </AwaitCustomer>
                  </Protected>
                }
              />
              <Route
                path={Paths.EDR_ASSETS}
                element={
                  <Protected>
                    <AwaitCustomer>
                      <EDRAssetLibrary key={1} />
                    </AwaitCustomer>
                  </Protected>
                }
              />
              <Route
                path={Paths.EDR_DEVICES}
                element={
                  <Protected>
                    <AwaitCustomer>
                      <EDRAssetLibrary key={1} />
                    </AwaitCustomer>
                  </Protected>
                }
              />
            </>
          )}
          {featureMfw && showMfwFeatures && (
            <Route
              path={Paths.FIREWALL}
              element={
                <Protected>
                  <FirewallOverview />
                </Protected>
              }
            />
          )}
          <Route
            path="/*"
            element={
              <Protected>
                <Navigate to={homePath} />
              </Protected>
            }
          />
        </Route>
      </Routes>
    </Box>
  )
}

/* Root is determined dynamically. This ensures that the loading screen is
rendered until the network call that informs this determination has been resolved. */
const setHomePath = (path: Paths): JSX.Element => {
  if (path === Paths.REDIRECT) {
    return <Loading />
  } else {
    return <Navigate to={path} />
  }
}

/* Ensures that a user is navigated to the home path in the event that they navigated
'back' in the browser after login. */
const redirectLogin = ({
  oktaSession,
  homePath,
}: {
  oktaSession: SessionStatus
  homePath: Paths
}): JSX.Element => {
  if (oktaSession === SessionStatus.ACTIVE) {
    return <Navigate to={homePath} />
  } else {
    return <Login />
  }
}
