import React, { CSSProperties, Fragment } from 'react'

import { ListItemProps, ListItemType, ListItemVariant } from '../../interfaces'
import { useTheme } from '../../hooks'
import { colors } from '../../theme'
import { Typography } from '..'

const MultiSelectListItem = ({
  item,
  active,
  selected,
  onSelect,
  onHover,
  variant,
  searchInput,
}: ListItemProps): JSX.Element => {
  const isDarkTheme = useTheme('dark')

  const handleItemClick = (): void => {
    if (
      onSelect &&
      item.name !== 'No matches found.' &&
      item.name !== 'No more options.'
    ) {
      onSelect(item)
    }

    if (
      item.onClick &&
      item.name !== 'No matches found.' &&
      item.name !== 'No more options.'
    ) {
      item.onClick()
    }
  }

  const handleItemHover = (item: ListItemType | undefined): void => {
    onHover(item)
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLLIElement>) => {
    if (event.key === 'Enter') {
      handleItemClick
    }
  }

  const boldSearchText = (groupName: string) => {
    if (searchInput.length > 0) {
      const boldTextArray = groupName.match(
        RegExp('(' + searchInput + ')', 'ig'),
      )
      const splitBy = boldTextArray?.find(Boolean)

      const groupPartNames = splitBy ? groupName.split(splitBy) : []

      return (
        <Typography styles={textStyle(variant)}>
          {groupPartNames.map((part, index) => {
            if (index === groupPartNames.length - 1) {
              return <Fragment key={index}>{part}</Fragment>
            } else {
              return (
                <Fragment key={index}>
                  {part}
                  <b>{splitBy}</b>
                </Fragment>
              )
            }
          })}
        </Typography>
      )
    }

    return <Typography styles={textStyle(variant)}>{groupName}</Typography>
  }

  const getBackgroundColor = (
    active: boolean,
    variant: ListItemVariant | undefined,
  ) => {
    let color
    if (isDarkTheme) {
      color = active ? '#455358' : colors.util.one.main
    }
    if (variant === 'popover' && isDarkTheme) {
      color = active ? colors.util.navy[400] : colors.util.navy[600]
    } else {
      color = active ? '#455358' : colors.util.one.main
    }
    return color
  }

  const itemContainer = (
    active: boolean,
    variant: ListItemVariant | undefined,
  ): CSSProperties => ({
    cursor: variant === 'standard' ? 'auto' : 'pointer',
    display: 'inline-flex',
    gap: variant === 'standard' || variant === 'link' ? 0 : 8,
    alignContent: 'center',
    justifyContent: variant === 'popover' ? 'flex-start' : 'space-between',
    overflow: 'hidden',
    height: 24,
    width: '100%',
    padding:
      variant === 'standard' || variant === 'link' ? '8px 0px 8px 8px' : '8px',
    backgroundColor: getBackgroundColor(active, variant),
    alignItems: 'center',
    border:
      variant === 'standard' || variant === 'link'
        ? '1px solid #36474C'
        : 'none',
    borderRadius: variant === 'standard' || variant === 'link' ? 5 : '',
  })

  const textStyle = (variant: ListItemVariant | undefined): CSSProperties => ({
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1.4,
    textAlign: 'end',
    overflow: 'visible',
    whiteSpace: 'pre',
    textOverflow: 'ellipsis',
    minWidth: 0,
    marginBlock: 0,
    marginInline: 0,
    margin: variant === 'popover' ? '0' : 'auto auto auto 0',
    color: colors.util.navy[50],
    display: 'flex',
    alignItems: 'center',
  })

  const rightTextStyle: CSSProperties = {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1.4,
    textAlign: 'start',
    overflow: 'hidden',
    whiteSpace: 'pre',
    textOverflow: 'ellipsis',
    minWidth: 0,
    marginBlock: 0,
    marginInline: 0,
    margin: 'auto 0 auto auto',
    color: colors.util.navy[50],
    display: 'flex',
    alignItems: 'center',
    marginRight: '10px',
  }

  return (
    <li
      role="option"
      aria-selected={selected}
      style={itemContainer(active, variant)}
      onClick={handleItemClick}
      onKeyDown={(event) => handleKeyPress(event)}
      onMouseEnter={() => handleItemHover(item)}
      onMouseLeave={() => handleItemHover(undefined)}
      key={item.id}
    >
      {boldSearchText(item.name)}

      {item.rightText ? (
        <Typography styles={rightTextStyle}>{item.rightText}</Typography>
      ) : null}
    </li>
  )
}

export default MultiSelectListItem
