import { Factory } from 'fishery'
import { faker } from '@faker-js/faker'
import { format, parseISO } from 'date-fns'

import { Ticket } from '../src/models'
import {
  TicketPriorityEnum,
  TicketStateEnum,
  TicketTypeEnum,
  TicketingComment,
} from '../src/models/Tickets'

export const TicketFactory = Factory.define<Ticket>(
  ({ overrideValues }: any) => ({
    sysId: faker.string.numeric(5),
    shortDescription: faker.lorem.sentence(),
    priority: TicketPriorityEnum.Critical,
    state: TicketStateEnum.New, // status
    type: TicketTypeEnum.Engineering,
    openedBy: faker.person.fullName(),
    createdDate: faker.date.recent({ days: 10 }).toISOString(),
    sysCreatedOn: format(
      parseISO(faker.date.recent({ days: 10 }).toISOString()),
      'yyyy-MM-dd',
    ), // age
    sysUpdatedOn: format(
      parseISO(faker.date.recent({ days: 10 }).toISOString()),
      'yyyy-MM-dd',
    ), // last updated
    ticketNumber: `CS${faker.string.numeric(10)}`,
    lastUpdateUser: faker.person.fullName(),
    assignedUser: faker.person.fullName(),
    serviceOffering: faker.hacker.noun(),
    customerValidateState: faker.datatype.boolean().toString(),
    category: faker.lorem.word(),
    subcategory: faker.lorem.word(),
    description: faker.hacker.phrase(),
    useCaseTitle: faker.hacker.noun(), // search name
    useCase: faker.string.alphanumeric(), // search id
    incidentType: faker.hacker.noun(),
    cisControl: faker.string.alphanumeric(),
    mitreTactics: faker.hacker.noun(),
    mitreTechniques: faker.hacker.noun(),
    splunkLink: 'https://example.com',
    splunkSearch: faker.lorem.word(),
    servicenowLink: 'https://example.com',
    comments: null,
    __typename: 'Ticket',
    ...overrideValues,
  }),
)

export const TicketCommentFactory = Factory.define<TicketingComment>(
  ({ overrideValues }: any) => ({
    text: faker.lorem.sentence(),
    time: faker.date.past().toISOString(),
    type: 'Additional comments',
    user: faker.person.fullName(),
    ...overrideValues,
  }),
)
