import { useContext, useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import ReactMarkdown from 'react-markdown'

import { colors } from '../../../../../design-system/theme'
import { Context } from '../../../../App'
import { Button, Loader, Typography } from '../../../../../design-system'
import {
  GET_THREAT_INTEL_REPORT_QUERY,
  GetThreatIntelReportData,
} from '../../../../../graphql/queries/threatIntel'
import TIFRTableOfContents from './TOC/TIFRTableOfContents'
import FullReportHeader from './Header/FullReportHeader'
import tiFullReportFooter from '../../../../../assets/ti-full-report-footer.svg'
import { TagNew } from '../../../../../design-system/components/TagNew'

import './ThreatIntelFullReport.scss'

const ThreatIntelFullReport = () => {
  const {
    state: {
      dwExpertsCustomer: { customerShortName: dwCustomerShortName }, // will only populate if DW user
    },
  } = useContext(Context)
  const [currentActiveSection, setCurrentActiveSection] = useState<
    string | null
  >(null)

  const handleScroll = () => {
    const scrollPosition = window.scrollY

    const sectionElements = document.querySelectorAll('.report-content > div')

    sectionElements.forEach((sectionElement) => {
      const { offsetTop, offsetHeight } = sectionElement as HTMLElement
      if (
        scrollPosition >= offsetTop - 88 &&
        scrollPosition < offsetTop + offsetHeight
      ) {
        setCurrentActiveSection(sectionElement.id)
      }
    })
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const {
    data: { getThreatIntelReport: threatIntelReport } = {
      getThreatIntelReport: undefined,
    },
    loading,
  } = useQuery<GetThreatIntelReportData>(GET_THREAT_INTEL_REPORT_QUERY, {
    variables: {
      fetchPolicy: 'cache-and-network',
      reportId: 'test',
      selectedCustomer: dwCustomerShortName,
    },
  })

  return (
    <div
      className="threat-intel-full-reports"
      data-testid="threat-intel-full-reports"
    >
      <div className="report-header" data-testid="report-header">
        {threatIntelReport && (
          <FullReportHeader
            type={threatIntelReport?.type}
            title={threatIntelReport?.title}
            source={threatIntelReport?.source}
            confidenceLevel={threatIntelReport?.confidenceLevel}
            createdDate={threatIntelReport?.createdDate}
          />
        )}
      </div>

      {loading ? (
        <div
          className="report-loading report-content"
          data-testid="report-content-loader"
        >
          <Loader size={80} strokeWidth={1} />
        </div>
      ) : (
        <>
          <div
            className="report-content fade-up-report-content"
            data-testid="report-content"
          >
            <div className="report-labels-container">
              <div className="report-labels" data-testid="report-labels">
                {threatIntelReport?.labels.map((label) => {
                  return (
                    <TagNew
                      key={label}
                      disabled
                      interactive
                      severity="none"
                      text={label.toUpperCase()}
                    />
                  )
                })}
              </div>

              <div className="report-share-link">
                <Button
                  variant="icon"
                  label="share-link-button"
                  iconProps={{ variant: 'link' }}
                  onClick={undefined}
                  buttonStyles={{
                    color: colors.util.one.light,
                  }}
                />
              </div>
            </div>
            {threatIntelReport?.sections.map((section, index) => {
              return (
                <div
                  key={`${section.heading}-${section.level}`}
                  data-testid={`section-${index}`}
                  id={`section-${section.heading}`}
                  className="fade-up-item"
                >
                  <Typography variant="text10semibold">
                    {section.heading}
                  </Typography>
                  <div className="report-section-markdown">
                    <ReactMarkdown>{section.content}</ReactMarkdown>
                  </div>
                </div>
              )
            })}
          </div>

          <div className="report-table-of-contents fade-in-toc">
            <TIFRTableOfContents
              threatIntelReportSections={threatIntelReport?.sections}
              activeSection={currentActiveSection}
            />
          </div>
          <div
            className="report-feedback fade-in-feedback"
            data-testid="report-feedback"
          >
            FEEDBACK GOES HERE
          </div>
          <div className="report-footer" data-testid="report-footer">
            <img
              data-testid="ti-report-footer"
              src={tiFullReportFooter}
              alt="Deepwatch Cyber Threat Intelligence"
              className="image"
            />
          </div>
        </>
      )}
    </div>
  )
}

export default ThreatIntelFullReport
