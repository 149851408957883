import { useFlags } from 'launchdarkly-react-client-sdk'
import { useState, useEffect, useMemo, useCallback } from 'react'
import { NodeId, flattenTree } from 'react-accessible-treeview'
import { useLocation } from 'react-router-dom'

import { useCustomer } from '@hooks/useCustomer'
import { doesCustomerHaveSupportedEDRProvider } from '@hooks/useEDRContent/useEDRContent'

import { buildNavTree, NavTreeMetadata } from './NavSidebar.utils'

interface NavTreeHookReturn {
  /** Whether navigation tree is still loading */
  isLoading: boolean
  /** Flattened navigation tree structure */
  navTree: ReturnType<typeof flattenTree<NavTreeMetadata>>
  /** Array of IDs for expanded navigation nodes */
  expandedIds: NodeId[]
  /**
   * Updates the expansion state of a navigation node
   * @param id The ID of the node to update
   * @param shouldExpand Whether the node should be expanded (`true`) or collapsed (`false`)
   */
  updateExpandedIds: (id: NodeId, shouldExpand: boolean) => void
}

/**
 * Hook to manage navigation tree state and expansion
 * @returns {NavTreeHookReturn} Object containing navigation tree state and handlers
 */
export const useNavTree = (): NavTreeHookReturn => {
  const [expandedIds, setExpandedIds] = useState<NodeId[]>(['mdr'])
  const {
    featureEnvHealth,
    featureNgMdr,
    featureNgMedrV1,
    featureMfw,
    featureDefenderForEndpoint,
  } = useFlags()
  const location = useLocation()

  const {
    customer: {
      ngMdrEnabled,
      ngMedrEnabled,
      ngMfwEnabled,
      isLimitedMdrExperience,
      edrEnabled,
      serviceNgMedrTechnology,
    },
    isLoading,
    isAdmin,
    isDWEmployee,
  } = useCustomer()

  const isCustomerEdrSupported = doesCustomerHaveSupportedEDRProvider({
    serviceNgMedrTechnology,
  })

  const enabledStatuses = useMemo(
    () => ({
      ngMdrEnabled: ngMdrEnabled && featureNgMdr,
      ngMedrEnabled: ngMedrEnabled && featureNgMedrV1,
      ngMfwEnabled: ngMfwEnabled && featureMfw,
      edrEnabled: edrEnabled && featureNgMedrV1 && isCustomerEdrSupported,
      isLimitedMdrExperience: isLimitedMdrExperience && featureNgMdr, // re-using same feature flag as ngMdr
    }),
    [
      featureMfw,
      featureNgMdr,
      featureNgMedrV1,
      ngMdrEnabled,
      ngMedrEnabled,
      ngMfwEnabled,
      edrEnabled,
      isLimitedMdrExperience,
      isCustomerEdrSupported,
    ],
  )

  const navTree = useMemo(
    () =>
      flattenTree(
        buildNavTree(
          enabledStatuses,
          isDWEmployee,
          isAdmin,
          featureEnvHealth,
          featureDefenderForEndpoint,
        ),
      ),
    [
      enabledStatuses,
      isDWEmployee,
      isAdmin,
      featureEnvHealth,
      featureDefenderForEndpoint,
    ],
  )

  const updateExpandedIds = useCallback(
    (id: NodeId, shouldExpand: boolean) =>
      setExpandedIds((prevIds) => {
        if (shouldExpand) {
          return prevIds.includes(id) ? prevIds : [...prevIds, id]
        } else {
          return prevIds.filter((prevId) => prevId !== id)
        }
      }),
    [],
  )

  /**
   * Expand the tree to the current location whenever the location or navTree changes
   */
  useEffect(() => {
    const nodeForCurrentLocation = navTree.find(
      (node) => node.metadata?.path === location.pathname,
    )
    if (nodeForCurrentLocation) {
      const id = nodeForCurrentLocation.parent ?? nodeForCurrentLocation.id
      updateExpandedIds(id, true)
    }
  }, [location, navTree, updateExpandedIds])

  return {
    isLoading,
    navTree,
    expandedIds,
    updateExpandedIds,
  }
}
