import { Factory } from 'fishery'
import { faker } from '@faker-js/faker'

import {
  ChangeHistoryType,
  ChangeHistoryWeight,
  IndexDetailsMetric,
  SecurityIndexEvent,
  YourIndex,
  Trend,
  SecurityIndexBansMetrics,
  BanKeysEnum,
} from '../src/models/SecurityIndex'

const types = [ChangeHistoryType.DETECTION, ChangeHistoryType.LOG_SOURCE]
const weights = [
  ChangeHistoryWeight.HIGH,
  ChangeHistoryWeight.MEDIUM,
  ChangeHistoryWeight.LOW,
]

export const SecurityIndexEventFactory = Factory.define<SecurityIndexEvent>(
  () => ({
    createdAt: faker.date.past(),
    dataSource: faker.hacker.abbreviation(),
    sourceName: faker.lorem.words(10),
    type: types[Math.floor(Math.random() * types.length)],
    id: Math.random(),
    action: faker.datatype.boolean(),
    weight: weights[Math.floor(Math.random() * weights.length)],
  }),
)
export const YourSecurityIndexFactory = Factory.define<YourIndex>(() => ({
  trends: {
    yourIndex: SecurityIndexTrend.buildList(5),
    avgCustomerTrend: SecurityIndexTrend.buildList(5),
    avgIndustryTrend: SecurityIndexTrend.buildList(5),
    pastPeriodTrend: SecurityIndexTrend.buildList(5),
  },
  details: IndexDetailsMetrics,
  preTransformedIndexValues: {
    detections: Math.floor(Math.random() * 100),
    logSources: Math.floor(Math.random() * 100),
    enterpriseCoverage: Math.floor(Math.random() * 100),
  },
  unclassifiedLogSourceCount: Math.floor(Math.random() * 100),
}))

export const SecurityIndexBansMetricsFactory =
  Factory.define<SecurityIndexBansMetrics>(() => ({
    yourIndex: {
      delta: faker.number.float({ min: 10, max: 100, precision: 0.01 }),
      key: BanKeysEnum.YOUR_INDEX,
      index: faker.number
        .float({ min: 10, max: 100, precision: 0.01 })
        .toString(),
    },
    avgCustomerIndex: {
      delta: faker.number.float({ min: 10, max: 100, precision: 0.01 }),
      key: BanKeysEnum.AVG_CUSTOMER_INDEX,
      index: faker.number.float({ min: 1, max: 10, precision: 0.1 }).toString(),
    },
    avgIndustryIndex: {
      delta: faker.number.float({ min: 10, max: 100, precision: 0.01 }),
      key: BanKeysEnum.AVG_INDUSTRY_INDEX,
      index: faker.number.float({ min: 1, max: 10, precision: 0.1 }).toString(),
    },
  }))

export const IndexDetailsMetrics: IndexDetailsMetric = {
  lifetimeIncrease: `${faker.number.int({ min: 10, max: 100 })}pts`,
  pastPeriod: faker.number.float({ min: 1, max: 10, precision: 0.1 }),
  unclassifiedLogSources: faker.number.int({ min: 10, max: 100 }),
  enabledLogSourcesCount: faker.number.int({ min: 10, max: 100 }),
  enabledDetectionsCount: faker.number.int({ min: 10, max: 100 }),
  missingDataSourcesCount: faker.number.int({ min: 10, max: 100 }),
  range: `${faker.number.float({
    min: 1,
    max: 2,
    precision: 0.1,
  })}-${faker.number.float({ min: 3, max: 5, precision: 0.1 })}`,
}

export const nivoTestData = [
  {
    id: 'Your Index',
    data: [
      {
        x: new Date('2023-01-01T00:00:00'),
        y: null,
      },
      {
        x: new Date('2023-01-02T00:00:00'),
        y: 5.58,
      },
      {
        x: new Date('2023-01-03T00:00:00'),
        y: 5.58,
      },
      {
        x: new Date('2023-01-04T00:00:00'),
        y: 6.3,
      },
      {
        x: new Date('2023-01-05T00:00:00'),
        y: 6.3,
      },
      {
        x: new Date('2023-01-06T00:00:00'),
        y: 6.3,
      },
      {
        x: new Date('2023-01-07T00:00:00'),
        y: 6.3,
      },
      {
        x: new Date('2023-01-08T00:00:00'),
        y: 6.3,
      },
      {
        x: new Date('2023-01-09T00:00:00'),
        y: 6.3,
      },
      {
        x: new Date('2023-01-10T00:00:00'),
        y: 6.3,
      },
    ],
  },
  {
    id: 'Avg. DW customer',
    data: [
      {
        x: new Date('2023-01-01T00:00:00'),
        y: 1,
      },
      {
        x: new Date('2023-01-02T00:00:00'),
        y: 1,
      },
      {
        x: new Date('2023-01-03T00:00:00'),
        y: 1,
      },
      {
        x: new Date('2023-01-04T00:00:00'),
        y: 2,
      },
      {
        x: new Date('2023-01-05T00:00:00'),
        y: 2,
      },
      {
        x: new Date('2023-01-06T00:00:00'),
        y: 2,
      },
      {
        x: new Date('2023-01-07T00:00:00'),
        y: 3,
      },
      {
        x: new Date('2023-01-08T00:00:00'),
        y: 3,
      },
      {
        x: new Date('2023-01-09T00:00:00'),
        y: 3,
      },
      {
        x: new Date('2023-01-10T00:00:00'),
        y: 4,
      },
    ],
  },
  {
    id: 'Avg. Industry',
    data: [
      {
        x: new Date('2023-01-01T00:00:00'),
        y: 3.2,
      },
      {
        x: new Date('2023-01-02T00:00:00'),
        y: 3.2,
      },
      {
        x: new Date('2023-01-03T00:00:00'),
        y: 3.25,
      },
      {
        x: new Date('2023-01-04T00:00:00'),
        y: 3.5,
      },
      {
        x: new Date('2023-01-05T00:00:00'),
        y: 4,
      },
      {
        x: new Date('2023-01-06T00:00:00'),
        y: 4,
      },
      {
        x: new Date('2023-01-07T00:00:00'),
        y: 4,
      },
      {
        x: new Date('2023-01-08T00:00:00'),
        y: 5,
      },
      {
        x: new Date('2023-01-09T00:00:00'),
        y: 5,
      },
      {
        x: new Date('2023-01-10T00:00:00'),
        y: 4.5,
      },
    ],
  },
  {
    id: 'Past period',
    data: [
      {
        x: new Date('2023-01-01T00:00:00'),
        y: 5,
      },
      {
        x: new Date('2023-01-02T00:00:00'),
        y: 5,
      },
      {
        x: new Date('2023-01-03T00:00:00'),
        y: 5.2,
      },
      {
        x: new Date('2023-01-04T00:00:00'),
        y: 5.2,
      },
      {
        x: new Date('2023-01-05T00:00:00'),
        y: 5.2,
      },
      {
        x: new Date('2023-01-06T00:00:00'),
        y: 5.2,
      },
      {
        x: new Date('2023-01-07T00:00:00'),
        y: 5.2,
      },
      {
        x: new Date('2023-01-08T00:00:00'),
        y: 5.2,
      },
      {
        x: new Date('2023-01-09T00:00:00'),
        y: 5.22,
      },
      {
        x: new Date('2023-01-10T00:00:00'),
        y: 5.22,
      },
    ],
  },
]
export const eventMockData: SecurityIndexEvent[] = [
  ...SecurityIndexEventFactory.buildList(4, {
    createdAt: new Date('09/01/2022'),
    sourceName: 'test source',
  }),
  ...SecurityIndexEventFactory.buildList(6, {
    createdAt: new Date('09/04/2022'),
    sourceName: 'a source name',
  }),
  ...SecurityIndexEventFactory.buildList(3, {
    createdAt: new Date('09/05/2022'),
    sourceName: 'test source 2',
  }),
]

export const eventMockData2: SecurityIndexEvent[] = [
  ...SecurityIndexEventFactory.buildList(1, {
    createdAt: new Date('2023-01-02T00:00:00'),
    sourceName: 'test source',
  }),
  ...SecurityIndexEventFactory.buildList(12, {
    createdAt: new Date('2023-01-05T00:00:00'),
    sourceName: 'a source name',
  }),
  ...SecurityIndexEventFactory.buildList(1, {
    createdAt: new Date('2023-01-06T00:00:00'),
    sourceName: 'a source name',
  }),
  ...SecurityIndexEventFactory.buildList(3, {
    createdAt: new Date('2023-01-09T00:00:00'),
    sourceName: 'test source 2',
  }),
]

const SecurityIndexTrend = Factory.define<Trend>(() => ({
  index: Math.floor(Math.random() * 100),
  metricDate: faker.date.past().toString(),
}))

export const SecurityIndexBansMetricsMock =
  SecurityIndexBansMetricsFactory.build()

export const YourSecurityIndexMock = YourSecurityIndexFactory.build()
