import { useParams } from 'react-router-dom'

import { TicketEditContextProvider } from './context/TicketEditContext'
import Body from './components/Body'
import Header from './components/Header'
import PermissionWarning from './components/PermissionWarning'
import { useTicketsContext } from '../../../hooks'

import './TicketEdit.scss'

export default function TicketEdit() {
  const { sysId = '' } = useParams()
  const { isEditable } = useTicketsContext()

  return (
    //NOTE: isEditable will be updated in follow on work once logic is available from backend
    <TicketEditContextProvider sysId={sysId} isEditable={isEditable}>
      <div id="ticket-edit" className="flex column gap-12">
        <PermissionWarning />
        <Header />
        <Body />
      </div>
    </TicketEditContextProvider>
  )
}
