import { useState, MouseEvent, FocusEvent } from 'react'
import { createRoot } from 'react-dom/client'
import { flushSync } from 'react-dom'

import {
  ListItemType,
  Card,
  colors,
  Typography,
  Select,
  Button,
} from '../../../../../design-system'
import { useMitreOverviewContext } from '../../context/MitreOverviewContext'
import MitreTacticsCards from './MitreTacticsCards'

export default function MitreCarousel() {
  const {
    mitreSelection,
    hasUserClosedSideSheet,
    carouselTechnique,
    coverage,
    handleSideSheet,
    resetSelectionState,
    setCarouselTechnique,
    setHasUserClosedSideSheet,
    setMitreSelection,
    handleTooltipEnter,
    handleTooltipLeave,
  } = useMitreOverviewContext()

  /**
   * the carouselTactic state below is used to keep track of the tactic
   * in this component separately from its parent as it doesn't handle an
   * "undefined" tactic possibly passed from its parent and should always
   * be a number
   */
  const [carouselTactic, setCarouselTactic] = useState(
    mitreSelection.tactic || 0,
  )

  /**
   * sets the list view/carousel tactic and opens
   * or closes the sidesheet based off of current
   * mitreSelectionState
   */
  const setAndPropagateTactic = (tactic: number) => {
    setCarouselTactic(tactic)
    // eslint-disable-next-line security/detect-object-injection
    const newTechnique = coverage?.dw[tactic].techniques.findIndex(
      (technique) => technique.mitreTechniqueId === carouselTechnique,
    )

    /**
     * open sidesheet for newly selected tactic that has the same
     * technique if the user hasn't previous closed the sidesheet
     */
    if (hasUserClosedSideSheet) {
      setCarouselTechnique(undefined)
    } else if (newTechnique !== -1) {
      setMitreSelection({
        tactic,
        technique: newTechnique,
        subtechnique: mitreSelection.subtechnique,
      })
      setHasUserClosedSideSheet(false)
      handleSideSheet(tactic, newTechnique, mitreSelection.subtechnique, () => {
        // eslint-disable-next-line security/detect-object-injection
        resetSelectionState(coverage.dw[tactic].techniques[newTechnique])
      })

      return
    }

    /**
     * close tactic/technique when scrolling through carousel where
     * there is no match for a technique
     */
    if (mitreSelection.tactic !== undefined) {
      handleSideSheet(
        mitreSelection.tactic,
        mitreSelection.technique,
        mitreSelection.subtechnique,
      )
    }

    setMitreSelection({
      tactic: undefined,
      technique: undefined,
      subtechnique: mitreSelection.subtechnique,
    })
  }

  const handleDecrement = () => {
    let newIndex = carouselTactic - 1
    if (newIndex < 0) {
      newIndex = coverage.dw.length - 1
    }

    setAndPropagateTactic(newIndex)
  }
  const handleIncrement = () => {
    let newIndex = carouselTactic + 1

    if (newIndex >= coverage.dw.length) {
      newIndex = 0
    }

    setAndPropagateTactic(newIndex)
  }
  const handleSelectChange = (option: ListItemType) => {
    const newTacticIndex = coverage.dw.findIndex(
      (tactic) => tactic.mitreTacticId === option.id,
    )
    if (newTacticIndex && carouselTactic !== newTacticIndex) {
      setAndPropagateTactic(newTacticIndex)
    }
  }
  const calculateCarouselBackgroundColor = (tacticIndex: number) => {
    if (carouselTactic === tacticIndex) {
      return `${colors.util.navy[100]}`
    }
  }
  const handleTacticEnter = (
    event: MouseEvent<HTMLDivElement> | FocusEvent<HTMLDivElement>,
    tactic: string,
  ) => {
    const div = document.createElement('div')
    const root = createRoot(div)

    flushSync(() => {
      root.render(
        <Typography
          component="span"
          color={`${colors.util.navy[50]}`}
          size={12}
        >
          {tactic}
        </Typography>,
      )
    })

    handleTooltipEnter(event, 2, div.innerHTML)
  }

  return (
    <div>
      <div className="mitre-list-carousel">
        {/* Buttons and select list */}
        <div className="mitre-carousel-category-and-icon">
          <Button
            variant="navigational-icon"
            iconProps={{ variant: 'arrowBackCircle' }}
            onClick={handleDecrement}
          />
          <div className="mitre-tactic-and-chevron">
            <Select
              key={carouselTactic}
              label=""
              // eslint-disable-next-line security/detect-object-injection
              placeholder={coverage.dw[carouselTactic].mitreTacticName}
              options={coverage.dw.map((tactic, tacticIndex) => ({
                id: tactic.mitreTacticId,
                name: tactic.mitreTacticName,
                index: tacticIndex,
              }))}
              border={false}
              onChange={handleSelectChange}
            />
          </div>
          <Button
            variant="navigational-icon"
            iconProps={{ variant: 'arrowForwardCircle' }}
            onClick={handleIncrement}
          />
        </div>
        {/* Square indicators */}
        <div className="mitre-carousel-square-container">
          {coverage.dw.map((tactic, tacticIndex) => (
            <div
              key={tactic.mitreTacticId}
              tabIndex={0}
              onMouseOver={(event) =>
                handleTacticEnter(event, tactic.mitreTacticName)
              }
              onFocus={(event) =>
                handleTacticEnter(event, tactic.mitreTacticName)
              }
              onMouseOut={handleTooltipLeave}
              onBlur={handleTooltipLeave}
              onClick={() => setAndPropagateTactic(tacticIndex)}
              onKeyDown={(event) => {
                if (['Enter', 'NumpadEnter'].includes(event.code)) {
                  setAndPropagateTactic(tacticIndex)
                }
              }}
              className="mitre-carousel-square"
              style={{
                background: calculateCarouselBackgroundColor(tacticIndex),
              }}
              role={'button'}
            />
          ))}
        </div>
      </div>
      {/* Tactic Cards */}
      <Card borderRadius="2px" width="100%" backgroundColor="#2E3D42">
        <MitreTacticsCards carouselTactic={carouselTactic} />
      </Card>
    </div>
  )
}
