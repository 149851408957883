import { colors, Typography } from '../../../../../design-system'
import { TicketContactData } from '../../Types'
import { mapSettingToLabel } from './TicketContact.utils'
import TicketDataHandler from '../TicketDataHandler'
import TicketEditCard from '../TicketEditCard'
import { GET_TICKET_CONTACT } from '../../../../../graphql/queries/ticket'

import './TicketContact.scss'

/**
 * Note for ticket edit v2+:
 *
 * When we add the select component for customer contact,
 * `buildSelectOptions` in `TicketContact.utils` can be used.
 *
 * Link to original PR w/ select component:
 * https://bitbucket.org/deepwatch/sonar-app/pull-requests/943
 *
 */

interface Props {
  data: TicketContactData
}

const TicketContactContent: React.FC<Props> = ({ data }) => {
  return (
    <div className="settings flex column gap-24">
      {Object.entries(mapSettingToLabel).map(([key, value]) => (
        <dl key={value}>
          <dt>
            <Typography variant="text11">{value}</Typography>
          </dt>
          <dd>
            <Typography color={colors.util.navy[50]} variant="text11">
              {/* eslint-disable-next-line security/detect-object-injection */}
              {data[key] ?? '--'}
            </Typography>
          </dd>
        </dl>
      ))}
    </div>
  )
}

const TicketContact: React.FC = () => {
  return (
    <TicketEditCard
      variant="transparent"
      className="ticket-contact"
      cardStyles={{ minHeight: '398px' }}
    >
      <TicketDataHandler<TicketContactData>
        graphqlQuery={GET_TICKET_CONTACT}
        renderContent={(data) => <TicketContactContent data={data} />}
      />
    </TicketEditCard>
  )
}

export default TicketContact
