import { gql } from '@apollo/client'

interface GroupDetails {
  groupId: string
  successful: boolean
  failReason: string
}

export interface ActivateUserData {
  activateUser: boolean
}

export interface CreateUserData {
  createUser: {
    id: string
    initialGroupIds: Array<GroupDetails>
  }
}

export interface DeactivateUserData {
  deactivateUser: boolean
}

export interface DeleteUserData {
  deleteUser: boolean
}

export interface ReactivateUserData {
  reactivateUser: boolean
}

export interface ActivateUserVariables {
  input: {
    userId: string
  }
}

export interface CreateUserVariables {
  input: {
    firstName: string
    lastName: string
    email: string
    title: string
    initialGroupIds: Array<string>
  }
}

export interface DeactivateUserVariables {
  input: {
    userId: string
  }
}

export interface DeleteUserVariables {
  input: {
    userId: string
  }
}

export interface ReactivateUserVariables {
  input: {
    userId: string
    sendEmail?: boolean
  }
}

export interface UpdateUserVariables {
  input: {
    userIdToUpdate: string
    firstName: string
    lastName: string
    title: string
    email: string
    removeFromGroupIds: Array<string>
    addToGroupIds: Array<string>
  }
}

export const ACTIVATE_USER = gql`
  mutation ActivateUser($input: UserLifecycleInput!) {
    activateUser(input: $input)
  }
`

export const CREATE_ACCEPTED_TERMS = gql`
  mutation CreateAcceptedTerms($acceptedVersion: String!) {
    createAcceptedTerms(input: { acceptedVersion: $acceptedVersion })
  }
`

export const CREATE_USER = gql`
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      id
      initialGroupIds {
        failReason
        groupId
        successful
      }
    }
  }
`

export const DEACTIVATE_USER = gql`
  mutation DeactivateUser($input: UserLifecycleInput!) {
    deactivateUser(input: $input)
  }
`

export const DELETE_USER = gql`
  mutation DeleteUser($input: UserLifecycleInput!) {
    deleteUser(input: $input)
  }
`

export const REACTIVATE_USER = gql`
  mutation ReactivateUser($input: UserLifecycleInput!) {
    reactivateUser(input: $input)
  }
`

export const UPDATE_USER = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input)
  }
`
