import { useContext, useMemo, useState } from 'react'

import { Button, colors, Typography } from '../../../../../design-system'
import TicketEditCard from '../TicketEditCard'
import { TicketCommentForm } from '../TicketCommentForm'
import TicketActivityItem from './TicketActivityItem'
import { convertTicketCommentToTicketActivity } from './TicketActivity.utils'
import TicketDataHandler from '../TicketDataHandler'
import { Context } from '../../../../App'
import { useTicketEditContext } from '../../../../../hooks'
import usePendingActivitySubmission, {
  PendingActivitySortOrder,
} from '../../../hooks/usePendingActivitySubmission'
import { TicketActivityType, TicketActivityTypeEnum } from '../../Types'
import {
  GET_TICKET_ACTIVITY,
  TicketActivityResponse,
} from '../../../../../graphql/queries/ticket'

import './TicketActivity.scss'

export const mapFieldToLabel = {
  priority: 'Urgency',
  state: 'Status',
  customerValidateState: 'Customer validation state',
  customerContact: 'Customer contact',
}

const TicketActivityContent: React.FC<{ data: TicketActivityResponse }> = ({
  data,
}) => {
  const {
    state: { dwExpertsCustomer, customer },
  } = useContext(Context)
  const allComments = useMemo<TicketActivityType[]>(() => {
    const parsedComments = data.comments
      ? data.comments
          .map(convertTicketCommentToTicketActivity)
          .filter(({ type }) => type === TicketActivityTypeEnum.Comment)
      : []
    const placeholderComments: TicketActivityType[] = []

    // If no comments show created comment
    if (!data.comments?.length && (data.openedBy || data.createdDate)) {
      const [firstName, lastName] =
        data.openedBy.split(' ') || `MDR ${dwExpertsCustomer || customer}`

      placeholderComments.push({
        type: TicketActivityTypeEnum.Placeholder,
        id: '',
        createdDate: data?.createdDate || new Date().toISOString(),
        text: `${data.ticketNumber} Created.`,
        userInfo: {
          id: 'createdBy',
          firstName: firstName,
          lastName: lastName || '', // Account for users with no last name
          type: 'system',
        },
      })
    }

    // Sort in descending order
    const allComments = [parsedComments, placeholderComments]
      .flat()
      .sort(
        ({ createdDate: aCreatedDate }, { createdDate: bCreatedDate }) =>
          new Date(bCreatedDate).getTime() - new Date(aCreatedDate).getTime(),
      )

    return allComments
  }, [data, customer, dwExpertsCustomer])

  const [viewAllActivity, setViewAllActivity] = useState(false)
  const { sysId } = useTicketEditContext()
  const { pendingActivityItems, setItemError, setItemCompleted, addItem } =
    usePendingActivitySubmission({
      pendingCommentOrder: PendingActivitySortOrder.DESCENDING,
      ticketId: sysId,
    })

  const ticketActivityItems = viewAllActivity
    ? [pendingActivityItems, allComments].flat()
    : [pendingActivityItems, allComments.slice(0, 5)].flat()

  return (
    <>
      <TicketCommentForm
        ticketId={sysId}
        onSubmit={addItem}
        onCompleted={setItemCompleted}
        onError={setItemError}
      />

      <Typography color={colors.util.navy[50]} variant="text10semibold">
        Activity
      </Typography>
      <div className="flex column">
        {ticketActivityItems.map((activity, index) => (
          <TicketActivityItem
            activity={activity}
            key={activity.id}
            shouldShowVerticalLine={
              ticketActivityItems.length > 1 &&
              index !== [pendingActivityItems, allComments].flat().length - 1
            }
          />
        ))}
        {!viewAllActivity && allComments.length > 5 && (
          <Button
            onClick={() => setViewAllActivity(true)}
            variant="text"
            customClass="view-all-button"
          >
            View All
          </Button>
        )}
      </div>
    </>
  )
}

const TicketActivity: React.FC = () => {
  return (
    <TicketEditCard title="Notes" className="ticket-activity">
      <TicketDataHandler<TicketActivityResponse>
        graphqlQuery={GET_TICKET_ACTIVITY}
        renderContent={(data) => <TicketActivityContent data={data} />}
      />
    </TicketEditCard>
  )
}

export default TicketActivity
