import { NavigateFunction } from 'react-router-dom'

import { oktaAuthConfig } from '@config/OktaAuthConfig'

export const handleDeepLinkFormatting = (
  searchParams: string,
  pathName: string,
  navigate: NavigateFunction,
) => {
  if (searchParams.includes('customer')) {
    const baseSearchParams = new URLSearchParams(searchParams)
    baseSearchParams.delete('customer')

    // Check if there are remaining params so we have these for later navigation
    const hasOtherParams = Array.from(baseSearchParams.keys()).length > 0

    // Construct the new originalUri if there are other params besides customer
    const newOriginalUri = hasOtherParams
      ? `${pathName}?${baseSearchParams.toString()}`
      : pathName

    oktaAuthConfig.setOriginalUri(newOriginalUri)
    navigate(oktaAuthConfig.getOriginalUri()!)
  }
}
