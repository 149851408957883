import { ReactNode, createContext } from 'react'
import { ApolloError, useQuery } from '@apollo/client'

import { YourIndex, SecurityIndexBansMetrics } from '../../../../models'
import {
  GET_SECURITY_INDEX_BAN_METRICS,
  GET_YOUR_SECURITY_INDEX,
  GetSecurityIndexBanMetrics,
  GetYourSecurityIndex,
} from '../../../../graphql/queries/securityIndex'
import {
  YourSecurityIndexMock,
  SecurityIndexBansMetricsMock,
} from '../../../../../mockData/SecurityIndexFactory'
import { GetCustomerInfoVariables } from '../../../../graphql/queries/customer'

type SecurityIndexContext = {
  yourIndexLoading: boolean
  bansLoading: boolean
  yourIndexError: ApolloError | undefined
  bansError: ApolloError | undefined
  bans: SecurityIndexBansMetrics
  yourIndex: YourIndex
}

export const SecurityIndexContext = createContext<SecurityIndexContext | null>(
  null,
)

export const SecurityIndexContextProvider = ({
  children,
  selectedCustomer,
}: {
  children: ReactNode
  selectedCustomer: string | null
}) => {
  const {
    data: { getSecurityIndexBanMetrics: banMetrics } = {
      getSecurityIndexBanMetrics: SecurityIndexBansMetricsMock,
    },
    loading: bansLoading,
    error: bansError,
  } = useQuery<GetSecurityIndexBanMetrics, GetCustomerInfoVariables>(
    GET_SECURITY_INDEX_BAN_METRICS,
    {
      variables: {
        selectedCustomer,
      },
    },
  )

  const {
    data: { getYourSecurityIndex } = {
      getYourSecurityIndex: YourSecurityIndexMock,
    },
    loading: yourIndexLoading,
    error: yourIndexError,
  } = useQuery<GetYourSecurityIndex, GetCustomerInfoVariables>(
    GET_YOUR_SECURITY_INDEX,
    {
      variables: { selectedCustomer },
    },
  )

  return (
    <SecurityIndexContext.Provider
      value={{
        yourIndexError,
        yourIndexLoading,
        bansLoading,
        bansError,
        bans: banMetrics,
        yourIndex: getYourSecurityIndex,
      }}
    >
      {children}
    </SecurityIndexContext.Provider>
  )
}
