import { useQuery } from '@apollo/client'

import { Typography, colors, Icon } from '../../../../design-system'
import Leadership from './Leadership/Leadership'
import MyDeepwatchExperts from './MyDeepwatchExperts/MyDeepwatchExperts'
import MyTeam from './MyTeam/MyTeam'
import { ErrorFallback } from '../../../App/Errors'
import { SkeletonBlock } from '../../../../design-system/components/SkeletonBlock'
import { GET_CUSTOMER_INFO_WITH_FULL_DETAILS } from '../../../../graphql/queries/customer'

import './CompanyOverview.scss'

const CompanyOverview = () => {
  const { loading, data, error } = useQuery(GET_CUSTOMER_INFO_WITH_FULL_DETAILS)
  const companyInfo = data?.getCustomerInfo

  if (error) {
    return <ErrorFallback />
  }

  return (
    <>
      <div id="company-overview-page" data-testid="company-overview">
        <div className="company-header">
          <div className="company-name-title" data-testid="company-name-title">
            {loading ? (
              <>
                <SkeletonBlock minHeight="34px" width="70%" />
                <SkeletonBlock minHeight="20px" width="30%" />
              </>
            ) : (
              <>
                <Typography
                  component="div"
                  weight={600}
                  size={28}
                  color={colors.util.navy[50]}
                >
                  {companyInfo.customerName}
                </Typography>
                <Typography
                  component="div"
                  weight={400}
                  size={16}
                  color={colors.util.navy[100]}
                >
                  {companyInfo.industry}
                </Typography>
              </>
            )}
          </div>
          <div
            className="company-information"
            data-testid="company-information"
          >
            {loading ? (
              <>
                <Icon variant="people" color={colors.util.navy[100]} />
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <SkeletonBlock minHeight="20px" width="40px" />
                </div>
                <Icon variant="locationOutline" color={colors.util.navy[100]} />
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <SkeletonBlock minHeight="20px" width="120px" />
                </div>
              </>
            ) : (
              <>
                <Icon variant="people" color={colors.util.navy[100]} />
                <Typography
                  size={14}
                  color={colors.util.navy[50]}
                  styles={{ paddingTop: '3px', marginRight: '40px' }}
                >
                  {companyInfo.numOfEmployees}
                </Typography>
                <Icon variant="locationOutline" color={colors.util.navy[100]} />
                <Typography
                  size={14}
                  color={colors.util.navy[50]}
                  styles={{ paddingTop: '3px' }}
                >
                  {`${companyInfo.address.street}`}
                  <br />
                  {`${companyInfo.address.city}, ${companyInfo.address.state} ${companyInfo.address.postalCode}`}
                </Typography>
              </>
            )}
          </div>
        </div>
        <hr className="seperator" />

        <div className="company-info-sections">
          <Leadership />
          <MyDeepwatchExperts />
          <MyTeam />
        </div>
      </div>
    </>
  )
}

export default CompanyOverview
