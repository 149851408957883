import {
  Loader,
  Typography,
  colors,
} from '../../../../../../../../design-system'
import { useSecurityIndexContext } from '../../../../../../../../hooks/useSecurityIndexContext'
import ComponentError from '../../../../../../../common/ComponentError/ComponentError'

import './IndexDetails.scss'

const IndexDetails = () => {
  const {
    yourIndex: { details },
    yourIndexError,
    yourIndexLoading,
  } = useSecurityIndexContext()

  if (yourIndexError) {
    return (
      <div style={{ flex: '1 0 25%', textAlign: 'center' }}>
        <ComponentError />
      </div>
    )
  }

  return (
    <div className="details-container">
      <div style={{ lineHeight: '120%' }}>
        <Typography variant="text11medium">Index details</Typography>
      </div>
      <div className="increase-container">
        <div>
          <Typography variant="text11">Lifetime increase</Typography>
          <Typography
            component="div"
            variant="text9semibold"
            color={colors.util.navy[50]}
          >
            {yourIndexLoading ? (
              <Loader centered size={10} />
            ) : (
              details.lifetimeIncrease
            )}
          </Typography>
        </div>
        <div>
          <Typography variant="text11">Past period</Typography>
          <Typography
            variant="text9semibold"
            color={colors.util.navy[50]}
            component="div"
          >
            {yourIndexLoading ? (
              <Loader centered size={10} />
            ) : (
              details.pastPeriod
            )}
          </Typography>
        </div>
      </div>

      <div className="text-container">
        <Typography variant="text11">Unclassified log sources</Typography>
        <Typography
          variant="text11medium"
          color={colors.util.navy[50]}
          component="div"
        >
          {yourIndexLoading ? (
            <Loader centered size={10} />
          ) : (
            details.unclassifiedLogSources
          )}
        </Typography>
      </div>

      <div className="text-container">
        <Typography variant="text11">Enabled log sources</Typography>
        <Typography
          variant="text11medium"
          color={colors.util.navy[50]}
          component="div"
        >
          {yourIndexLoading ? (
            <Loader centered size={10} />
          ) : (
            details.enabledLogSourcesCount
          )}
        </Typography>
      </div>

      <div className="text-container">
        <Typography variant="text11">Enabled detections</Typography>
        <Typography
          variant="text11medium"
          color={colors.util.navy[50]}
          component="div"
        >
          {yourIndexLoading ? (
            <Loader centered size={10} />
          ) : (
            details.enabledDetectionsCount
          )}
        </Typography>
      </div>

      <div className="text-container">
        <Typography variant="text11">Missing data sources</Typography>
        <Typography
          variant="text11medium"
          color={colors.util.navy[50]}
          component="div"
        >
          {yourIndexLoading ? (
            <Loader centered size={10} />
          ) : (
            details.missingDataSourcesCount
          )}
        </Typography>
      </div>

      <div className="text-container">
        <Typography variant="text11">Range</Typography>
        <Typography
          variant="text11medium"
          color={colors.util.navy[50]}
          component="div"
        >
          {yourIndexLoading ? <Loader centered size={10} /> : details.range}
        </Typography>
      </div>
    </div>
  )
}
export default IndexDetails
