import { Box } from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { useWindowDimensions } from '@hooks/index'
import { mobileWindowWidth } from '@constants/constants'

import { TicketActivity } from './TicketActivity'
import TicketAdditionalDetails from './TicketAdditionalDetails'
import { TicketContact } from './TicketContact'
import TicketDetails from './TicketDetails'
import { TicketStatus } from './TicketStatus'
import TicketResolution from './TicketResolution'
import {
  TicketAttachments,
  TicketAttachmentsSideSheet,
} from './TicketAttachments'

const Body: React.FC = () => {
  const { width } = useWindowDimensions()
  const { featureTicketAttachments } = useFlags()

  const isMobile = width <= mobileWindowWidth

  if (isMobile) {
    return (
      <Box>
        <TicketStatus />
        <TicketResolution />
        <TicketActivity />
        <TicketDetails />
        <TicketContact />
        <TicketAdditionalDetails />
      </Box>
    )
  }

  return (
    <Box
      sx={{
        gridArea: 'main',
        display: 'grid',
        gridTemplateColumns: 'minmax(0, 6fr) minmax(330px, 1fr)', // 6fr to account for larger screens
        gap: '1rem',
        padding: '1rem',
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        <TicketResolution />
        <TicketActivity />
        <TicketDetails />
        <TicketAdditionalDetails />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        {featureTicketAttachments && <TicketAttachments />}
        <TicketStatus />
        <TicketContact />
        {featureTicketAttachments && <TicketAttachmentsSideSheet />}
      </Box>
    </Box>
  )
}

export default Body
