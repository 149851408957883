import { useState } from 'react'

import { IndexTrend } from './IndexTrend'
import { IndexTrendEvents } from './IndexTrendEvents'
import { SecurityIndexMetrics } from './SecurityIndexMetrics'

import './YourIndex.scss'

const YourIndex: React.FC = () => {
  const selectedTrendEventsState = useState<Date>()
  return (
    <div
      data-testid="security-index-your-index"
      className="flex"
      id="your-index"
    >
      <IndexTrend selectedTrendEventState={selectedTrendEventsState} />
      <IndexTrendEvents selectedTrendEventState={selectedTrendEventsState} />
      <SecurityIndexMetrics />
    </div>
  )
}
export default YourIndex
