import React, { CSSProperties, useState } from 'react'

import { CollapsiblePanelProps } from '../../interfaces'
import { colors } from '../../theme'
import { useTheme } from '../../hooks'
import { Icon } from '../Icon'
import { Typography } from '../Typography'

const CollapsiblePanel = ({
  label,
  iconType,
  iconColor,
  children,
}: CollapsiblePanelProps): JSX.Element => {
  const [open, setOpen] = useState(false)
  const isDarkTheme = useTheme('dark')

  const panelContainer = (open: boolean): CSSProperties => ({
    borderTop: `1px solid ${colors.util.navy['200']}`,
    borderBottom: `1px solid ${colors.util.navy['200']}`,
    marginTop: '1rem',
    marginBottom: '1rem',
    padding: open ? '0px 0px 12px 0px' : '0',
    cursor: 'pointer',
  })

  const panelHeaderContainer: CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
    margin: '0 8px',
    padding: '10px 0',
  }

  const iconLeftStyles: CSSProperties = {
    marginRight: 4,
    height: 24,
  }

  const panelHeaderLabel: CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    color: isDarkTheme ? colors.util.navy['50'] : colors.common.black,
  }

  const childrenContainerStyles: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    placeContent: 'center space-between',
    gap: 8,
    margin: '0px 8px 0px 8px',
    color: isDarkTheme ? colors.util.navy[50] : colors.common.black,
    fontFamily: 'Inter',
    cursor: 'default',
  }

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
    <section style={panelContainer(open)} tabIndex={0}>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <header
        style={panelHeaderContainer}
        onClick={() => setOpen(!open)}
        onKeyUp={(e: React.KeyboardEvent<HTMLElement>) =>
          e.key === 'Enter' ? setOpen(!open) : null
        }
      >
        <div style={panelHeaderLabel}>
          {iconType && (
            <div style={iconLeftStyles}>
              <Icon variant={iconType} color={iconColor} />
            </div>
          )}
          <div>
            <Typography
              size={18}
              component="div"
              variant={'text10medium'}
              color={isDarkTheme ? colors.util.navy['50'] : colors.common.black}
            >
              {label}
            </Typography>
          </div>
        </div>
        <div>
          {open ? (
            <Icon variant={'chevronUp'} />
          ) : (
            <Icon variant={'chevronDown'} />
          )}
        </div>
      </header>
      {open && <div style={childrenContainerStyles}>{children}</div>}
    </section>
  )
}

export default CollapsiblePanel
