import { createRef, useState } from 'react'
import { CSSTransition } from 'react-transition-group'

import { colors } from '../../../../design-system/theme'
import {
  Icon,
  Typography,
  Menu,
  useOutsideClick,
} from '../../../../design-system'
import BugForm from './BugForm'
import { useModalContext } from '../../../../hooks'

export interface HelpButtonProps {
  menuIn: boolean
  showMenuText: boolean
  fontSize: number
}

const HelpButton = ({ menuIn, showMenuText, fontSize }) => {
  const [flyoutShown, setFlyoutShown] = useState<boolean>(false)
  const { closeModal, openModal } = useModalContext()
  const handleFlyout = () => {
    setFlyoutShown(!flyoutShown)
  }

  const closeFlyOut = () => {
    setFlyoutShown(false)
  }

  const divRef = createRef<HTMLDivElement>()

  useOutsideClick(closeFlyOut, flyoutShown, divRef)

  const openBugForm = () => {
    openModal({
      component: <BugForm closeModal={closeModal} />,
      title: 'Submit a bug',
    })
  }

  const showFlyout = () => {
    return (
      flyoutShown && (
        <Menu
          handleOutsideClick={() => 0}
          variant={'popover'}
          styles={{
            position: 'fixed',
            top: 'auto',
            bottom: '8vh',
            left: '1vw',
            height: 'auto',
            width: 'min-content',
          }}
          options={[
            {
              id: 'submitBug',
              leftIcon: 'bugOutline',
              name: 'Submit a bug!',
              iconColor: colors.common.white,
              onClick: () => {
                openBugForm()
              },
            },
            {
              id: 'submitIdea',
              leftIcon: 'bulbOutline',
              rightIcon: 'openOutline',
              name: 'Submit an idea!',
              iconColor: colors.common.white,
              onClick: () =>
                window.open(
                  'https://dwportal.ideas.aha.io/portal_session/new',
                  '_blank',
                ),
            },
          ]}
        />
      )
    )
  }

  return (
    <li className="bottomli">
      <div ref={divRef}>
        {showFlyout()}
        <button
          className="navlink"
          onClick={handleFlyout}
          onKeyDown={handleFlyout}
          style={{ background: 'none', cursor: 'pointer', border: 'none' }}
          data-testid="sidebar-help-button"
        >
          <Icon title="Help Icon" variant={'helpCircle'} size={26} />
          <CSSTransition in={menuIn} timeout={300} classNames="draweritem">
            <div className="navlink-text">
              <Typography
                component="span"
                size={fontSize}
                color={colors.util.one.light}
              >
                {showMenuText && <>Resource center</>}
              </Typography>
            </div>
          </CSSTransition>
        </button>
      </div>
    </li>
  )
}

export default HelpButton
