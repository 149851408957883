import { useContext, useEffect } from 'react'
import {
  Route,
  Routes,
  Navigate,
  useLocation,
  useNavigate,
} from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'
import classNames from 'classnames'

import { Context } from './Provider'
import {
  Coverage,
  Layout,
  Loading,
  Login,
  Reports,
  Settings,
  SkipLink,
  DeepwatchExperts,
  Tickets,
} from '../'
import { ErrorFallback } from './Errors'
import TermsOfService from '../TermsOfService/TermsOfService'
import { deleteToastMessage } from './Actions'
import Protected from '../Routes/Protected'
import { Paths } from './Types'
import { SessionStatus } from '../../config/OktaAuthConfig'
import { Message, useThemeContext } from '../../design-system'
import { CompanyInfo, Profile, UserManagementSettings } from '../Settings'
import Home from '../Home/Home'
import ThreatIntel from '../ThreatIntel/ThreatIntel'
import { navigateUserType } from '../../utils'

import './App.scss'

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

export const App = () => {
  const {
    state: {
      globalLoading,
      toastMessages,
      user,
      homePath,
      oktaSession,
      dwExpertsCustomer: { customerShortName },
    },
    dispatch,
  } = useContext(Context)

  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { isDWEmployee } = user

  const {
    environmentDebug,
    featureEditTicketPage,
    featureSettings,
    securityIndex,
  } = useFlags()

  const { theme } = useThemeContext()

  useEffect(() => {
    document.body.classList.toggle('dark-theme', theme === 'dark')
    document.body.classList.toggle('light-theme', theme === 'light')
  }, [theme])

  const oldToNewRoutes = {
    '/reports': 'insights',
  }

  /**
   * this is used for redirecting users for updated URLs
   */
  useEffect(() => {
    // eslint-disable-next-line security/detect-object-injection
    const redirectTo = oldToNewRoutes[pathname]

    if (redirectTo) {
      navigateUserType(isDWEmployee, redirectTo, navigate, customerShortName)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  if (environmentDebug) {
    // eslint-disable-next-line no-console
    console.log('process.env.ENV: ', process.env.ENV)
  }

  /* The app is initialized with globalLoading set to true. This helps avoid
  flashes of the root page while data is loading. However, it means that we
  must always ignore the loading state on the login page. */
  if (globalLoading && pathname !== Paths.LOGIN) {
    return <Loading />
  }

  const getSettingsRoutes = (settingsFlag: boolean): JSX.Element => {
    if (settingsFlag) {
      return (
        <>
          <Route
            path={Paths.SETTINGS}
            element={<Navigate to={Paths.COMPANY_INFO} />}
          />
          <Route path={Paths.COMPANY_INFO} element={<CompanyInfo />} />
          <Route path={Paths.PROFILE} element={<Profile />} />
          <Route
            path={Paths.USER_MANAGEMENT}
            element={<UserManagementSettings />}
          />
        </>
      )
    } else {
      return (
        <Route
          path={Paths.SETTINGS}
          element={
            <Protected>
              <Settings />
            </Protected>
          }
        />
      )
    }
  }

  const containerClass = classNames('container', {
    safari: isSafari,
  })

  return (
    <>
      <div className={containerClass} data-testid="apptest">
        <SkipLink text={'Skip to main content'} />
        <div
          style={{
            position: 'fixed',
            right: 16,
            top: 88,
            zIndex: 10,
            clear: 'both',
          }}
        >
          {toastMessages.map((message) => {
            return (
              <div key={message.id}>
                <Message
                  messageType={message.messageType}
                  text={message.text}
                  title={message.title}
                  autoDismissAt={message.secondsToExpire}
                  dismissible={message.dismissible}
                  onDismissed={() => {
                    dispatch(deleteToastMessage(message.id))
                  }}
                />
              </div>
            )
          })}
        </div>
        <Routes>
          <Route
            path={Paths.LOGIN}
            element={redirectLogin({ oktaSession, homePath })}
          />
          <Route
            path={Paths.ERROR}
            element={<ErrorFallback style={{ left: '50%' }} />}
          />
          <Route path={Paths.TERMS} element={<TermsOfService />} />
          <Route
            path={Paths.ROOT}
            element={<Protected>{setHomePath(homePath)}</Protected>}
          />
          <Route
            element={
              <Layout hideSidebar={pathname === Paths.DEEPWATCH_EXPERTS} />
            }
          >
            <Route
              path={Paths.DASHBOARD}
              element={
                <Protected>
                  <Home activeTab={0} />
                </Protected>
              }
            />
            {getSettingsRoutes(featureSettings)}
            <Route
              path={Paths.INSIGHTS}
              element={
                <Protected>
                  <Reports />
                </Protected>
              }
            />
            {isDWEmployee && (
              <Route
                path={Paths.DEEPWATCH_EXPERTS}
                element={
                  <Protected>
                    <DeepwatchExperts />
                  </Protected>
                }
              />
            )}
            <Route
              path={Paths.DETECTION_COVERAGE}
              element={
                <Protected>
                  <Coverage activeTab={0} />
                </Protected>
              }
            />
            <Route
              path={Paths.DETECTION_CATALOG}
              element={
                <Protected>
                  <Coverage activeTab={1} />
                </Protected>
              }
            />
            <Route
              path={Paths.TICKETS}
              element={
                <Protected>
                  <Tickets />
                </Protected>
              }
            />
            {featureEditTicketPage && (
              <Route
                path={Paths.TICKET_EDIT}
                element={
                  <Protected>
                    <Tickets component="edit" />
                  </Protected>
                }
              />
            )}
            <Route
              path={Paths.THREAT_INTEL_REPORTS}
              element={
                <Protected>
                  <ThreatIntel />
                </Protected>
              }
            />
            <Route
              path={Paths.THREAT_INTEL_FULL_REPORT}
              element={
                <Protected>
                  <ThreatIntel component="fullReport" />
                </Protected>
              }
            />
            {securityIndex && (
              <>
                <Route
                  path={`${Paths.SECURITY_INDEX}${Paths.YOUR_INDEX}`}
                  element={
                    <Protected>
                      <Home activeTab={1} />
                    </Protected>
                  }
                />
                <Route
                  path={`${Paths.SECURITY_INDEX}${Paths.CHANGE_HISTORY}`}
                  element={
                    <Protected>
                      <Home activeTab={1} />
                    </Protected>
                  }
                />
              </>
            )}
            <Route
              path="/*"
              element={
                <Protected>
                  <Navigate to={homePath} />
                </Protected>
              }
            />
          </Route>
        </Routes>
      </div>
    </>
  )
}

/* Root is determined dynamically. This ensures that the loading screen is
rendered until the network call that informs this determination has been resolved. */
const setHomePath = (path: Paths): JSX.Element => {
  if (path === Paths.REDIRECT) {
    return <Loading />
  } else {
    return <Navigate to={path} />
  }
}

/* Ensures that a user is navigated to the home path in the event that they navigated
'back' in the browser after login. */
const redirectLogin = ({
  oktaSession,
  homePath,
}: {
  oktaSession: SessionStatus
  homePath: Paths
}): JSX.Element => {
  if (oktaSession === SessionStatus.ACTIVE) {
    return <Navigate to={homePath} />
  } else {
    return <Login />
  }
}
