import classNames from 'classnames'
import { format, parseISO } from 'date-fns'

import { UserAvatar } from '../../../../common/UserAvatar'
import { colors, Icon, Loader, Typography } from '../../../../../design-system'
import { mapFieldToLabel } from './TicketActivity'
import {
  FieldValues,
  TicketActivityType,
  TicketActivityTypeEnum,
} from '../../Types'

import './TicketActivityItem.scss'

interface Props {
  activity: TicketActivityType
  shouldShowVerticalLine?: boolean
}

const TICKET_ACTIVITY_FOOTER_TEXT: Record<TicketActivityTypeEnum, string> = {
  'Additional comments': 'Additional comments',
  'Field changes': 'Field changes',
  Placeholder: 'Field changes',
}

const displayFieldValues = ({ previousValue, value }: FieldValues) => {
  if (previousValue && previousValue !== value) {
    return (
      <div className="values">
        <Typography color={colors.util.navy[50]} variant="text11">
          {previousValue}
          <Icon variant="arrowForwardSharp" size={21} />
          {value}
        </Typography>
      </div>
    )
  }

  return (
    <Typography color={colors.util.navy[50]} variant="text11">
      {value}
    </Typography>
  )
}

const TicketActivityItem: React.FC<Props> = ({
  activity,
  shouldShowVerticalLine,
}) => {
  const { createdDate, userInfo, type } = activity

  const displayActivity = () => {
    switch (type) {
      case TicketActivityTypeEnum.Placeholder: {
        return (
          <div className="field-change field-change-text">
            <Typography variant="text11">{activity.text}</Typography>
          </div>
        )
      }
      case TicketActivityTypeEnum.FieldChanges: {
        return activity.fieldChanges.map((fieldChange) =>
          Object.entries(fieldChange).map(
            ([key, { previousValue, value }], index) => (
              <div
                className="field-change flex align-center gap-16"
                key={index}
              >
                <Typography variant="text11semibold">
                  {/* eslint-disable-next-line security/detect-object-injection */}
                  {mapFieldToLabel[key]}:
                </Typography>
                {displayFieldValues({ previousValue, value })}
              </div>
            ),
          ),
        )
      }
      case TicketActivityTypeEnum.Comment: {
        const { status = 'created' } = activity
        const commentClassnames = classNames('comment', {
          error: status === 'error',
        })

        return (
          <div className={commentClassnames}>
            <Typography
              color={
                status === 'created'
                  ? colors.util.navy[50]
                  : colors.util.navy[100]
              }
              variant="text11"
            >
              {activity.text}
            </Typography>
          </div>
        )
      }
    }
  }

  const displayFooter = () => {
    const formattedDate = format(parseISO(createdDate), 'MMM d, yyyy h:mm a')

    if (type === TicketActivityTypeEnum.Comment) {
      switch (activity.status) {
        case 'pending':
          return (
            <div className="flex gap-4">
              <div>
                <Loader size={16} strokeWidth={1} />
              </div>
              <Typography variant="text12">Posting&hellip;</Typography>
            </div>
          )
        case 'error':
          return (
            <Typography color={colors.util.two.light} variant="text12">
              {activity.error?.message ?? 'An error occurred'}
            </Typography>
          )
        default:
          return (
            <Typography variant="text12">
              {formattedDate} &bull; {TICKET_ACTIVITY_FOOTER_TEXT[type]}
            </Typography>
          )
      }
    }

    return (
      <Typography variant="text12">
        {formattedDate} &bull; {TICKET_ACTIVITY_FOOTER_TEXT[type]}
      </Typography>
    )
  }

  return (
    <div className="ticket-activity-item" data-testid="ticket-activity-item">
      <div className="name flex align-center gap-8">
        <UserAvatar userInfo={userInfo} />
        <Typography color={colors.common.white} variant="text10semibold">
          {`${userInfo.firstName} ${userInfo.lastName}`}
        </Typography>
      </div>
      <div className="content-container">
        <div className="vertical-line-container">
          {shouldShowVerticalLine && (
            <div
              data-testid="ticket-activity-item-vertical-line"
              className="vertical-line"
            />
          )}
        </div>
        <div className="content">
          <div className="w-full flex row">{displayActivity()}</div>
          <footer className="ticket-activity-item-footer">
            {displayFooter()}
          </footer>
        </div>
      </div>
    </div>
  )
}

export default TicketActivityItem
