import { TicketResolutionData } from '../../Types'
import { useTicketsContext, useTicketEditContext } from '../../../../../hooks'
import TicketEditCard from '../TicketEditCard'
import { TicketResolutionForm } from './TicketResolutionForm'
import TicketDataHandler from '../TicketDataHandler'
import apolloClient from '../../../../../config/apolloClient'
import { Loader } from '../../../../../design-system'
import {
  GET_TICKET_RESOLUTION,
  GetTicketDetailsVariables,
} from '../../../../../graphql/queries/ticket'

export const TicketResolution: React.FC = () => {
  const { resolutionCodeEnum, ticketSettingsDataLoading } = useTicketsContext()
  const { saveDraftTicketFields, sysId, isFetchLoading } =
    useTicketEditContext()

  const data = apolloClient.readQuery<
    { getTicketDetails: { resolutionCode: string; resolutionNotes: string } },
    GetTicketDetailsVariables
  >({
    query: GET_TICKET_RESOLUTION,
    variables: {
      ticketId: sysId,
    },
  })

  const showResolutionDetails =
    !!data?.getTicketDetails.resolutionCode &&
    !!data?.getTicketDetails.resolutionNotes

  if (!data && (isFetchLoading || ticketSettingsDataLoading)) {
    return (
      <TicketEditCard cardStyles={{ minHeight: '464px' }}>
        <div className="flex center align-center w-full h-full">
          <Loader strokeWidth={1} size={50} />
        </div>
      </TicketEditCard>
    )
  }

  return showResolutionDetails ? (
    <TicketEditCard
      title="Resolution Details"
      cardStyles={{ minHeight: '464px' }}
    >
      <TicketDataHandler<TicketResolutionData>
        graphqlQuery={GET_TICKET_RESOLUTION}
        renderContent={(data) => (
          <TicketResolutionForm
            data={data}
            resolutionCodeEnum={resolutionCodeEnum}
            onChange={saveDraftTicketFields}
            sysId={sysId}
            inModal={false}
          />
        )}
      />
    </TicketEditCard>
  ) : null
}
