import { TicketStatusData } from '../../Types'
import TicketEditCard from '../TicketEditCard'
import TicketDataHandler from '../TicketDataHandler'
import TicketStatusForm from './components/TicketStatusForm'
import { useTicketEditContext } from '../../../../../hooks'
import { GET_TICKET_STATUS } from '../../../../../graphql/queries/ticket'

const TicketStatus = () => {
  const { isEditable } = useTicketEditContext()
  return (
    <TicketEditCard
      className="ticket-status"
      cardStyles={isEditable ? { minHeight: '620px' } : { minHeight: '500px' }}
    >
      <TicketDataHandler<TicketStatusData>
        graphqlQuery={GET_TICKET_STATUS}
        renderContent={(data) => <TicketStatusForm data={data} />}
      />
    </TicketEditCard>
  )
}

export default TicketStatus
