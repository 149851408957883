import { Loader } from '../../design-system'

import './Loading.scss'

const Loading = () => {
  return (
    <>
      <div id="loading">
        <Loader strokeWidth={2} size={100}></Loader>
      </div>
    </>
  )
}

export default Loading
