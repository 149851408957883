/* eslint-disable no-console */
import React, { useContext, useEffect } from 'react'

import { Context } from '../App'
import { AppEventEmitter } from '../../utils/EventEmitter'
import { deployToastMessage } from '../../utils/ToastMessage'
import { ToastType } from '../../design-system'

const AppWrapper: React.FC<{
  children?: unknown
}> = ({ children }) => {
  const { dispatch } = useContext(Context)

  useEffect(() => {
    AppEventEmitter.on('appSyncClientError', (e) => {
      deployToastMessage(
        {
          ...e,
          messageType: ToastType.ErrorToast,
        },
        dispatch,
      )
    })

    AppEventEmitter.on('graphQlError', (e) => {
      deployToastMessage(
        {
          ...e,
          messageType: ToastType.ErrorToast,
        },
        dispatch,
      )
    })

    AppEventEmitter.on('oktaError', (e) => {
      deployToastMessage(
        {
          ...e,
          messageType: ToastType.ErrorToast,
          code: 401,
        },
        dispatch,
      )
    })

    return () => {
      AppEventEmitter.removeAllListeners('appSyncClientError')
      AppEventEmitter.removeAllListeners('graphQlError')
      AppEventEmitter.removeAllListeners('oktaError')
    }
  }, [dispatch])

  return <>{children}</>
}

export default AppWrapper
