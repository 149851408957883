import { Form, Formik, useFormik } from 'formik'
import { formatDistanceToNow, parseISO } from 'date-fns'

import {
  colors,
  ListItemType,
  Select,
  Typography,
} from '../../../../../../design-system'
import { TicketStatusData } from '../../../Types'
import { setPTagsAndLabels } from '../../../utils'
import {
  findEnumLabelAndNumber,
  returnUserAvatarOrString,
  sortKeysToMatch,
} from '../TicketStatusUtils'
import { TicketEditEnum, TicketStateEnum } from '../../../../../../models'
import {
  useTicketsContext,
  useModalContext,
  useTicketEditContext,
} from '../../../../../../hooks'
import { noop } from '../../../../../../design-system/utils'
import { TicketResolutionForm } from '../../TicketResolution'

import '../TicketStatus.scss'

const TicketStatusForm = ({
  data,
}: {
  data: TicketStatusData
}): JSX.Element => {
  const {
    priorityEnum,
    stateEnum,
    customerValidationEnum,
    urgencyEnum,
    resolutionCodeEnum,
  } = useTicketsContext()

  const {
    saveDraftTicketFields,
    sysId,
    draftTicketFields,
    resetDraftTicketFields,
    isEditable,
  } = useTicketEditContext()

  const { closeModal, openModal } = useModalContext()

  const initialPriorityEnum = findEnumLabelAndNumber(
    data.priority,
    priorityEnum,
  )

  const initialValues = {
    customerValidation: data.customerValidateState,
    state: data.state,
    priority: initialPriorityEnum
      ? `${initialPriorityEnum?.numberValue} - ${initialPriorityEnum?.label}`
      : data.priority,
  }

  const { setFieldValue, values } = useFormik({
    initialValues,
    onSubmit: noop,
  })

  const updatedData = Object.assign({}, data)

  updatedData['age'] = formatDistanceToNow(parseISO(updatedData.sysCreatedOn))

  const setPlaceHolderForForm = (key: string): string => {
    switch (key) {
      case 'priority':
        return initialValues.priority
      case 'state':
        return initialValues.state
      case 'customerValidateState':
        return initialValues.customerValidation || '--'
      default:
        return '--'
    }
  }

  const StatusFormFields = (): JSX.Element => {
    const selectFieldKeys: string[] = [
      'priority',
      'state',
      'customerValidateState',
    ]

    const statesWithCloseEnabled: string[] = [
      TicketStateEnum.Resolved,
      TicketStateEnum.Cancelled,
      TicketStateEnum.Closed,
    ]

    const availableStateEnums = statesWithCloseEnabled.includes(
      initialValues.state,
    )
      ? stateEnum
      : stateEnum.filter((enumVal) => enumVal.label !== TicketStateEnum.Closed)

    const enumMap = {
      priority: priorityEnum,
      state: availableStateEnums,
      customerValidateState: customerValidationEnum,
    }

    return (
      // the TicketStatus component utilizes the saveDraftTicketFields to manage ticket updates and enable the save button onSubmit logic
      <Formik initialValues={initialValues} onSubmit={noop}>
        {() => (
          <Form style={!isEditable ? { marginBottom: '-24px' } : {}}>
            {Object.entries(updatedData)
              .filter((key) => selectFieldKeys.includes(key[0]!))
              .sort(([a], [b]) => sortKeysToMatch(a, b, selectFieldKeys))
              .map(([key]) => {
                const options = enumMap[key].map(
                  (enumValue: TicketEditEnum) => ({
                    id: enumValue.label,
                    name:
                      key === 'priority'
                        ? `${enumValue.numberValue} - ${enumValue.label}`
                        : enumValue.label,
                  }),
                )

                const handleSelectChange = (option: ListItemType) => {
                  if (key === 'state' && option.name === 'Resolved') {
                    openModal({
                      component: (
                        <TicketResolutionForm
                          closeModal={closeModal}
                          resolutionCodeEnum={resolutionCodeEnum}
                          sysId={sysId}
                          ticketNumber={data.ticketNumber}
                          draftTicketFields={draftTicketFields}
                          resetDraftTicketFields={resetDraftTicketFields}
                        />
                      ),
                      title: 'Resolution details',
                    })
                  } else {
                    setFieldValue(key, option.name)
                    saveDraftTicketFields({
                      [key]: option.id,
                    })
                  }
                }

                const selectedOption = options.find(
                  (selectOption: ListItemType) =>
                    selectOption.name === values[key],
                )

                return (
                  <div
                    key={key}
                    className="assinged-to-container-select"
                    data-testid={key}
                  >
                    <Select
                      key={key}
                      placeholder={setPlaceHolderForForm(key)}
                      labelStyleProps={{
                        paddingBottom: '8px',
                        fontWeight: 500,
                        fontSize: 14,
                      }}
                      label={setPTagsAndLabels(key)}
                      options={options}
                      onChange={handleSelectChange}
                      {...(selectedOption && {
                        selected: selectedOption,
                      })}
                      disabled={!isEditable}
                      isEditable={isEditable}
                    />
                  </div>
                )
              })}
          </Form>
        )}
      </Formik>
    )
  }

  const TicketStatusLabelsAndForm = (): JSX.Element => {
    const firstKeys: string[] = ['assignedUser', 'impact']
    const secondKeys: string[] = ['age', 'lastUpdateUser', 'sysCreatedOn']
    return (
      <>
        {Object.entries(updatedData)
          .filter((key) => firstKeys.includes(key[0]))
          .sort(([a], [b]) => sortKeysToMatch(a, b, firstKeys))
          .map(([key, value]) => {
            const initalUrgencyEnum = findEnumLabelAndNumber(value, urgencyEnum)

            return (
              <div
                data-testid="status-label"
                key={key}
                className={`label-and-data-${key}`}
              >
                <Typography size={14} color={colors.util.navy[100]}>
                  {setPTagsAndLabels(key)}
                </Typography>
                {returnUserAvatarOrString(
                  key,
                  key === 'impact' && initalUrgencyEnum
                    ? `${initalUrgencyEnum?.numberValue} - ${initalUrgencyEnum?.label}`
                    : `${value}`,
                  data.assignedUser && {
                    firstName: data.assignedUser.split(' ')[0],
                    lastName: data.assignedUser.split(' ')[1],
                    type: data.assignedToDeepwatch ? 'dw' : 'cust',
                  },
                )}
              </div>
            )
          })}
        <StatusFormFields />
        {Object.entries(updatedData)
          .filter((key) => secondKeys.includes(key[0]))
          .sort(([a], [b]) => sortKeysToMatch(a, b, secondKeys))
          .map(([key, value]) => {
            return (
              <div
                data-testid="status-label"
                key={key}
                className={`label-and-data-${key}`}
              >
                <Typography size={14} color={colors.util.navy[100]}>
                  {setPTagsAndLabels(key)}
                </Typography>
                {returnUserAvatarOrString(key, `${value}`)}
              </div>
            )
          })}
      </>
    )
  }

  return (
    <div className="assigned-to-container">
      <TicketStatusLabelsAndForm />
    </div>
  )
}

export default TicketStatusForm
