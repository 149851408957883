import { ReactNode, createContext, useState } from 'react'

import { ThreatIntelReportsContextModel } from '../types'

interface ThreatIntelReportsContextProviderProps {
  children: ReactNode
}

export const ThreatIntelReportsContext =
  createContext<ThreatIntelReportsContextModel | null>(null)

export const ThreatIntelReportsContextProvider: React.FC<
  ThreatIntelReportsContextProviderProps
> = ({ children }) => {
  const [activeReportId, setActiveReportId] = useState<string | null>(null)
  const openSideSheet = (id: string) => setActiveReportId(id)
  const closeSideSheet = () => setActiveReportId(null)

  return (
    <ThreatIntelReportsContext.Provider
      value={{
        activeReportId,
        isSideSheetOpen: !!activeReportId,
        openSideSheet,
        closeSideSheet,
      }}
    >
      {children}
    </ThreatIntelReportsContext.Provider>
  )
}
