import { useEffect, useState } from 'react'
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material'

import { SideSheet, sideSheetHeaderCss } from '@common/SideSheet'
import {
  FilterOptionValues,
  FilterOptions,
  TicketFilterInput,
} from '@models/index'
import { FilterChips } from '@common/FilterChips'
import Icon from '@common/Icon'

interface TicketFilterProps {
  isOpen: boolean
  setIsOpen: (boolean: boolean) => void
  filterOptionsResponse: FilterOptions<FilterOptionValues[]>
  selectedFilters: FilterOptions<TicketFilterInput>
  clearAll: () => void
  applyFilter: (vars: FilterOptions<TicketFilterInput>) => void
}

const TicketFilters = ({
  isOpen,
  setIsOpen,
  filterOptionsResponse,
  selectedFilters,
  clearAll,
  applyFilter,
}: TicketFilterProps): JSX.Element => {
  const [selectedFiltersSideSheet, setSelectedFiltersSideSheet] =
    useState<FilterOptions<TicketFilterInput>>(selectedFilters)

  useEffect(() => {
    setSelectedFiltersSideSheet(selectedFilters)
  }, [selectedFilters])

  return (
    <SideSheet
      data-testid="ticket-filters-sidesheet-test-id"
      open={isOpen}
      renderTitle={() => (
        <Box
          sx={sideSheetHeaderCss.header}
          data-testid="ticket-filters-sidesheet-header"
        >
          <Box sx={sideSheetHeaderCss.headerContent}>
            <Box sx={sideSheetHeaderCss.titleContent}>Filtering options</Box>
            <IconButton
              disabled={!isOpen}
              onClick={() => setIsOpen(false)}
              data-testid="ticket-filters-sidesheet-close-button"
            >
              <Icon variant="closeOutline" />
            </IconButton>
          </Box>
        </Box>
      )}
      footer={
        <Box
          data-testid="ticket-filters-buttons"
          id="dw-filters-buttons"
          sx={{ display: 'flex', gap: '0.5rem' }}
        >
          <Button variant="outlined" onClick={() => clearAll()}>
            Clear all
          </Button>
          <Button
            variant="contained"
            onClick={() => applyFilter(selectedFiltersSideSheet)}
          >
            Apply
          </Button>
        </Box>
      }
    >
      <Box id="filters-sidesheet" sx={{ padding: '1rem' }}>
        <TextField
          slotProps={{
            input: {
              startAdornment: (
                <InputAdornment position="start">
                  <Icon variant="searchOutline" />
                </InputAdornment>
              ),
              sx: (theme) => ({
                backgroundColor: `${theme.vars.palette.secondary.light} !important`,
                border: `1px solid ${theme.vars.palette.primary.main}`,
                borderRadius: '5px',
                ...theme.applyStyles('dark', {
                  backgroundColor: `${theme.vars.palette.secondary.main} !important`,
                  border: `1px solid ${theme.vars.palette.secondary.light}`,
                }),
              }),
            },
          }}
          placeholder="Find tickets by ID/Subject"
          type="text"
          name="ticket filter search text"
          onChange={({ target }) => {
            setSelectedFiltersSideSheet({
              ...selectedFiltersSideSheet,
              keywordSearch: target.value,
            })
          }}
          value={selectedFiltersSideSheet.keywordSearch}
        />

        {filterOptionsResponse.filters
          .filter((filter) => {
            return filter.key === 'createdDate' ? false : true
          })
          .filter(({ values }) => values.length > 0)
          .map(({ label, key, values }): JSX.Element => {
            return (
              <Box key={key} sx={{ padding: '1rem 0' }}>
                <Typography
                  color="textPrimary"
                  fontWeight={600}
                  variant="body2"
                  sx={{ paddingBottom: '0.5rem' }}
                >
                  {label}
                </Typography>
                <FilterChips
                  key={key}
                  keyValue={key}
                  filterGroup={label}
                  filterLabel={label}
                  filterValues={values}
                  selectedFilters={selectedFiltersSideSheet}
                  setSelectedFilters={
                    setSelectedFiltersSideSheet as VoidFunction
                  }
                />
              </Box>
            )
          })}
      </Box>
    </SideSheet>
  )
}

export default TicketFilters
