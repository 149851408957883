import { IconButtonProps } from '../../../interfaces'
import { IconButton } from '../../IconButton'

import './RowHoverElements.scss'

export interface RowHoverElementProps {
  elements: IconButtonProps[]
  elementValue: Record<string, any>
  elementClasses: string
}

const RowHoverElement = ({
  elements,
  elementValue,
  elementClasses,
}: RowHoverElementProps): JSX.Element => {
  return (
    <>
      {elements.length > 0 && (
        <div className={`hover-buttons${elementClasses}`}>
          <div className="gradient-clip"></div>
          <div className="hover-buttons-container" data-testid="hover-buttons">
            {elements.map((iconButtonPropValue, index) => {
              return (
                <IconButton
                  key={index}
                  {...iconButtonPropValue}
                  onClick={(e) => {
                    e.stopPropagation()
                    iconButtonPropValue.onClick?.(e)
                    iconButtonPropValue.customOnClick?.(elementValue)
                  }}
                />
              )
            })}
          </div>
        </div>
      )}
    </>
  )
}

export default RowHoverElement
