import { BarCustomLayerProps, BarDatum, ResponsiveBar } from '@nivo/bar'
import { format } from 'date-fns'
import { ComponentProps, useEffect, useState } from 'react'
import { Box, useTheme } from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { useXAxisDateFormatting } from '@hooks/index'
import { themeConfig } from '@common/nivo/utils'
import {
  ChartType,
  useEnvironmentHealthChartDetailsSideSheet,
} from '@components/EnvironmentHealth/Sidesheets/EnvironmentHealthChartDetailsSideSheet/EnvironmentHealthChartDetailsSideSheetContext'
import {
  EnvironmentHealthUtilAlertsNoValue,
  Metric,
  SourceType,
} from '@models/EnvHealth'
import { ComponentError } from '@common/ComponentError'

import {
  barColors,
  chunkSourceTypesByDate,
  compareByDateAscending,
  hoveredBarColors,
  modifyMaxValueScale,
} from '../SourceUtilization.utils'
import SuTooltip, { SuAlertsToolTip } from '../SuToolTip/SuToolTip'

export interface UtilizationByDateNoValueChartProps {
  data: EnvironmentHealthUtilAlertsNoValue | null
}

export type ChartData = {
  date: string
  totalGbForDay: number
  sourceTypes: SourceType[]
  totalAlertsForDay: number
}

/**
 * This chart accounts for:
 * - utilization x date x no value
 * - alerts x date x no value
 * @param {UtilizationByDateNoValueChartProps} param The component props
 * @param {EnvironmentHealthUtilAlertsNoValue | null} param.data The data required for the chart
 * @param {Metric} param.Metric The type of chart to render i.e. Utilization or Alerts
 * @returns {import('react').ReactNode} A chart component for unsegmented data
 */
const NoValueChart: React.FC<
  UtilizationByDateNoValueChartProps & { metric: Metric }
> = ({ data, metric }) => {
  const isUtilizationMetric = metric === Metric.UTILIZATION
  const [selectedBar, setSelectedBar] = useState('')
  const [hoveredBar, setHoveredBar] = useState('')
  const theme = useTheme()
  /**
   * Includes the `sourceTypes` array in the chart data; used for lookup when a bar is clicked or hovered
   */
  const chartData = chunkSourceTypesByDate(data).sort(compareByDateAscending)
  /**
   * We need to remove the `sourceTypes` array from the chart data to satisfy the Nivo `BarDatum[]` type
   */
  const chartDataForNivo: Array<Omit<ChartData, 'sourceTypes'>> = chartData.map(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ({ sourceTypes, ...rest }) => rest,
  )

  const { setSideSheetData, setIsOpen, licenseCapacity, isOpen } =
    useEnvironmentHealthChartDetailsSideSheet()

  useEffect(() => {
    // toggle the side sheet if a bar is selected
    if (selectedBar !== '') {
      setIsOpen(true)
    } else {
      setIsOpen(false)
    }
  }, [selectedBar, setIsOpen])

  useEffect(() => {
    // this is when the user clicks the x button in the side sheet
    // we need to both close the side sheet and deselect the active bar
    if (!isOpen) {
      setSelectedBar('')
    }
  }, [isOpen])

  /**
   * @description Look up the data for a specific date; used when a bar is clicked or hovered
   * @param {string | number} indexValue The date of the data to look up
   * @returns {ChartData} The chart data for the date
   */
  const lookUpDataByDate = (indexValue: string | number): ChartData => {
    const data = chartData.find(({ date }) => date === indexValue)
    if (!data) {
      // This error should never be thrown, but it's here for type safety
      throw new Error('Data not found')
    }
    return data
  }

  const handleClick: ComponentProps<typeof ResponsiveBar>['onClick'] = ({
    indexValue,
  }) => {
    const data = lookUpDataByDate(indexValue)

    const percentageFormat = new Intl.NumberFormat('en-US', {
      style: 'percent',
      maximumFractionDigits: 0,
    })

    setSideSheetData({
      chartType: ChartType.SINGLE_DAY,
      sourceTypes: data.sourceTypes,
      chartTitle: isUtilizationMetric ? 'Utilization (GB)' : 'Alerts',
      chartValue: isUtilizationMetric ? 'gb' : 'alerts',
      details: [
        {
          key: 'Date',
          label: 'Date',
          value: format(data.date, 'MMM d, yyyy'),
        },
        ...(isUtilizationMetric
          ? [
              {
                key: 'Total license usage',
                label: 'Total license usage',
                // eslint-disable-next-line @getify/proper-ternary/nested
                value: licenseCapacity
                  ? `${data.totalGbForDay}/${licenseCapacity}GB`
                  : `${data.totalGbForDay}GB`,
              },
              {
                key: 'Usage Percentage',
                label: 'Usage Percentage',
                // eslint-disable-next-line @getify/proper-ternary/nested
                value: licenseCapacity
                  ? percentageFormat.format(
                      data.totalGbForDay / licenseCapacity,
                    )
                  : 'N/A',
              },
            ]
          : [
              {
                key: 'Total alerts',
                label: 'Total alerts',
                value: data.totalAlertsForDay,
              },
            ]),
      ],
    })

    setSelectedBar((prev) => {
      // if a bar is clicked and then the same bar is clicked again return nothing
      if (prev === data.date) {
        return ''
      } else {
        // if a bar is clicked return the data needed for it
        return data.date
      }
    })
  }

  const largestGbDataPoint = chartData
    .slice()
    .sort((a, b) => b.totalGbForDay - a.totalGbForDay)[0]?.totalGbForDay

  const maxAlerts = chartData.reduce((acc, curr) => {
    acc += curr.totalAlertsForDay
    return acc
  }, 0)

  const formatXAxis = useXAxisDateFormatting(chartData)

  if (chartDataForNivo.length === 0) {
    return <ComponentError errorContainerStyles={{ height: '100%' }} />
  }

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        minHeight: 0,
        minWidth: 0,
        width: '99%',
      }}
      id="su-chart-container"
      data-testid="su-chart-container"
    >
      <ResponsiveBar
        data={chartDataForNivo}
        keys={[isUtilizationMetric ? 'totalGbForDay' : 'totalAlertsForDay']}
        indexBy="date"
        margin={{
          top: 50,
          right: 2,
          bottom: 45,
          left: 36,
        }}
        padding={0.6}
        groupMode="grouped"
        indexScale={{ type: 'band', round: true }}
        borderColor={theme.palette.secondary.light}
        valueScale={{
          type: 'linear',
          max: isUtilizationMetric
            ? modifyMaxValueScale(largestGbDataPoint, licenseCapacity)
            : maxAlerts,
          min: 0,
        }}
        tooltip={({ indexValue }) => {
          const data = lookUpDataByDate(indexValue)
          return isUtilizationMetric ? (
            <SuTooltip
              licenseCapacity={licenseCapacity ?? 0}
              toolTipData={data}
            />
          ) : (
            <SuAlertsToolTip toolTipData={data} />
          )
        }}
        colors={({ indexValue }) => {
          const data = lookUpDataByDate(indexValue)

          if (hoveredBar) {
            return hoveredBarColors(
              indexValue,
              data,
              hoveredBar,
              isUtilizationMetric ? licenseCapacity : 0,
              theme,
            )
          } else {
            return barColors(
              indexValue,
              data,
              selectedBar,
              isUtilizationMetric ? licenseCapacity : 0,
              theme,
            )
          }
        }}
        enableLabel={false}
        theme={themeConfig(theme.palette.mode, theme)}
        axisTop={null}
        axisRight={null}
        gridYValues={6}
        axisLeft={{
          tickPadding: 0,
          tickValues: metric === Metric.ALERTS ? Math.min(5, maxAlerts) : 5,
          format: (value) => `${Math.ceil(value)}`,
        }}
        axisBottom={{ format: formatXAxis }}
        onClick={handleClick}
        onMouseEnter={(d, e) => {
          e.currentTarget.style.cursor = 'pointer'
          setHoveredBar(d.indexValue as string)
        }}
        onMouseLeave={() => setHoveredBar('')}
        isFocusable
        barAriaLabel={(data) => data.indexValue.toString()}
        layers={[
          'grid',
          'axes',
          'bars',
          isUtilizationMetric
            ? LicenseCapacityLineWrapper(licenseCapacity)
            : () => <></>,
          isUtilizationMetric ? LicenseCapacityLegend : () => <></>,
        ]}
      />
    </Box>
  )
}

// eslint-disable-next-line jsdoc/require-param
/**
 * Dashed SVG line element with proper positioning for license capacity across the chart
 * @param props.yScale Nivo method to scale y-axis values
 * @param props.width Nivo property that tells us the chart width
 * @param props.licenseCapacity the license capacity to help determine the line position
 * @returns dashed SVG line element
 */
const LicenseCapacityLine: React.FC<
  Pick<BarCustomLayerProps<BarDatum>, 'yScale' | 'width'> & {
    licenseCapacity: number | undefined
  }
> = ({ yScale, width, licenseCapacity }) => {
  const linePosition = licenseCapacity ? yScale(licenseCapacity) : undefined
  return (
    <Box
      component="line"
      sx={(theme) => ({
        stroke: theme.palette.text.primary,
        ...theme.applyStyles('dark', { stroke: theme.palette.text.secondary }),
      })}
      x1="10"
      y1={linePosition}
      x2={width - 55}
      y2={linePosition}
      strokeDasharray="8"
      data-testid="license-capacity-dashed-line"
    />
  )
}

/**
 * Wrapper component for custom Nivo layer
 * We need this wrapper so we can get both the Nivo props as well as
 * any custom props we want to use
 * @param capacity number
 * @returns LicenseCapacityLine which is a dashed line SVG going across the NoValueChart
 */
const LicenseCapacityLineWrapper = (capacity: number | undefined) => {
  // eslint-disable-next-line react/display-name
  return (props) => (
    <LicenseCapacityLine {...props} licenseCapacity={capacity} />
  )
}

/**
 * Nivo custom layer for the license capacity legend found on NoValueChart
 * @param width.width
 * @param width width of the chart so we can position the legend
 * @returns SVG element
 */
const LicenseCapacityLegend: React.FC<
  Pick<BarCustomLayerProps<BarDatum>, 'width'>
> = ({ width }) => {
  const { featureDwLicense } = useFlags()
  const xTranslation = width - (featureDwLicense ? 170 : 199.31)
  return (
    <Box component="g" transform={`translate(${xTranslation}, -20)`}>
      <Box
        component="line"
        x1="-31"
        x2="-10"
        y1="-4"
        y2="-4"
        sx={(theme) => ({
          stroke: theme.palette.text.primary,
          ...theme.applyStyles('dark', {
            stroke: theme.palette.text.secondary,
          }),
        })}
        strokeDasharray={4}
        strokeWidth={7}
        strokeDashoffset={3.5}
      />
      <Box
        component="text"
        sx={(theme) => ({
          fontSize: theme.typography.body2.fontSize,
          fill: theme.palette.text.primary,
        })}
        fontWeight={400}
      >
        {featureDwLicense ? 'Deepwatch license' : 'Current license capacity'}
      </Box>
    </Box>
  )
}

export default NoValueChart
