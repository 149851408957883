import { format } from 'date-fns'
import { SliceTooltipProps as NivoSliceTooltipProps, Point } from '@nivo/line'

import { trendColors } from '../IndexTrend'
import { Icon, Typography, colors } from '../../../../../../../../design-system'

import './SliceTooltip.scss'

interface SliceTooltipProps {
  slice: NivoSliceTooltipProps['slice']
}

const SliceTooltip: React.FC<SliceTooltipProps> = ({ slice }) => {
  const sortedPoints = [
    slice.points.find((point) => point.serieId === 'Your Index'),
    slice.points.find((point) => point.serieId === 'Avg. DW customer'),
    slice.points.find((point) => point.serieId === 'Avg. Industry'),
    slice.points.find((point) => point.serieId === 'Past period'),
  ].filter(Boolean) as Point[]
  return (
    <div data-testid="slice-tooltip" id="slice-tooltip" className="flex column">
      <Typography
        component="span"
        size={12}
        styles={{ padding: 4 }}
        color={colors.util.navy[50]}
      >
        {format(slice.points[0].data.x as Date, 'MMM do')}
      </Typography>
      <div
        className="flex column gap-1"
        style={{
          padding: 4,
          borderTop: '0.75px solid #576569',
        }}
      >
        {sortedPoints.map((point) => {
          return (
            <div key={point.serieId} className="flex align-center gap-4">
              <Icon
                style={{ borderRadius: '2px' }}
                variant="square"
                color={trendColors[point.serieId]}
                size={12}
              />
              <Typography
                component="span"
                size={12}
                styles={{ color: colors.util.navy[100] }}
              >
                {point.serieId}
              </Typography>
              <Typography
                component="span"
                size={12}
                styles={{ color: colors.util.navy[50] }}
              >
                {point.data.yFormatted}
              </Typography>
            </div>
          )
        })}
      </div>
    </div>
  )
}
export default SliceTooltip
