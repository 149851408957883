import { useState } from 'react'

import { setToggleState } from './MaturityBan.utils'
import MaturityLine from './MaturityLine'
import { MaturityBanChartType } from '../ChartTypes'
import { Typography, colors, Toggle } from '../../../../design-system'

import './MaturityBanChartStyle.scss'
import '../../../Home/Dashboard/charts/MaturityScore/MaturityScore.scss'

const MaturityBanChart = ({
  data,
  dwAvg,
  custAvg,
  industryAvg,
  maxTrendValue,
}: MaturityBanChartType): JSX.Element => {
  const [dwTrendToggled, setDwToggle] = useState<boolean>(false)
  const [industryToggled, setIndustryToggle] = useState<boolean>(false)

  const industryAverageColor = industryToggled
    ? colors.util.navy[50]
    : colors.util.navy[100]

  return (
    <div id="coverage-maturity-score" data-testid="coverage-maturity-score">
      <>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            paddingLeft: '10px',
            gap: 24,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '4px',
            }}
          >
            <Typography component="span" size={12}>
              Your score
            </Typography>
            <Typography component="span" size={18} color={colors.util.navy[50]}>
              {custAvg}
            </Typography>
          </div>
          {data[2].data.length > 0 ? (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '4px',
                }}
              >
                <Typography component="span" size={12}>
                  Industry average
                </Typography>
                <Typography
                  component="span"
                  size={18}
                  color={colors.util.navy[50]}
                >
                  {industryAvg}
                </Typography>
              </div>
            </>
          ) : (
            ''
          )}
          <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            <Typography component="span" size={12}>
              Deepwatch average
            </Typography>
            <Typography component="span" size={18} color={colors.util.navy[50]}>
              {dwAvg}
            </Typography>
          </div>
        </div>
      </>
      <div style={{ height: '180px', minWidth: '200px' }}>
        <MaturityLine
          data={data}
          toggles={{
            dwToggle: dwTrendToggled,
            industryToggle: industryToggled,
          }}
          maxTrend={maxTrendValue}
        />
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginLeft: 1,
        }}
      >
        {data[2].data.length > 0 ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography size={18} color={industryAverageColor}>
              Show industry average
            </Typography>
            <Toggle
              label=""
              check={false}
              onClick={() => setToggleState(industryToggled, setIndustryToggle)}
            />
          </div>
        ) : (
          ''
        )}

        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '14px',
          }}
        >
          <Typography
            size={18}
            color={
              dwTrendToggled ? colors.util.navy[50] : colors.util.navy[100]
            }
          >
            Show Deepwatch average
          </Typography>
          <Toggle
            label=""
            check={false}
            onClick={() => setToggleState(dwTrendToggled, setDwToggle)}
          />
        </div>
      </div>
    </div>
  )
}

export default MaturityBanChart
