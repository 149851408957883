import { ThreatIntelReport } from '../../../../../models/ThreatIntel'

export const combineThreatIntelReports = (
  existing: ThreatIntelReport[],
  incoming: ThreatIntelReport[],
): ThreatIntelReport[] =>
  [...existing, ...incoming].sort(
    (
      { createdDate: aCreatedDate, title: aTitle },
      { createdDate: bCreatedDate, title: bTitle },
    ) => {
      const dateComparison =
        new Date(bCreatedDate).getTime() - new Date(aCreatedDate).getTime()

      if (dateComparison === 0) {
        return aTitle.localeCompare(bTitle)
      }

      return dateComparison
    },
  )
