import { Loader } from '../../../../../design-system'
import MitreCarousel from './MitreCarousel'
import { useMitreOverviewContext } from '../../context/MitreOverviewContext'

export default function MitreCoverageList() {
  const { coverage } = useMitreOverviewContext()

  if (coverage.dw.length < 1) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        <Loader strokeWidth={1} size={25} />
      </div>
    )
  }

  return (
    <div>
      <MitreCarousel />
    </div>
  )
}
