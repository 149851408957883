import {
  Dispatch,
  createContext,
  useState,
  SetStateAction,
  ReactNode,
  useContext,
} from 'react'

export type ThemeUI = 'dark' | 'light'
export type ThemeContextType = {
  theme: ThemeUI | undefined
  setTheme?: Dispatch<SetStateAction<ThemeUI>>
}

const ThemeContext = createContext<ThemeContextType | null>(null)

type ThemeProviderProps = {
  children: ReactNode
  defaultTheme: ThemeUI
}

const ThemeProvider = ({
  children,
  defaultTheme,
}: ThemeProviderProps): JSX.Element => {
  //const defaultTheme: ThemeUI = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'
  const [theme, setTheme] = useState<ThemeUI>(defaultTheme)
  const toggleTheme = (): void => {
    setTheme(theme === 'dark' ? 'light' : 'dark')
  }
  return (
    <ThemeContext.Provider value={{ theme: theme, setTheme: toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  )
}

const useThemeContext = () => {
  const themeContext = useContext(ThemeContext)

  if (!themeContext) {
    throw new Error('ThemeContext must be used within ThemeProvider')
  }

  return themeContext
}

export { ThemeContext, ThemeProvider, useThemeContext }
