import React, { useState, useEffect } from 'react'

import { CSSProps, ToggleProps } from '../../interfaces'
import { colors } from '../../theme'
import { useTheme } from '../../hooks'
import { Typography } from '../'

const Toggle = ({
  label,
  check = false,
  disable = false,
  focus = false,
  name,
  labelTestId,
  inputTestId,
  onFocus,
  onBlur,
  onClick,
  onKeyUp,
  onMouseLeave,
  onMouseEnter,
  labelStyles,
  inputStyles,
}: ToggleProps): JSX.Element => {
  const [checked, setChecked] = useState(check)
  const [disabled, setDisabled] = useState(disable)
  const isDarkTheme = useTheme('dark')

  useEffect(() => {
    setChecked(check)
  }, [check])

  useEffect(() => {
    setDisabled(disable)
  }, [disable])

  const handleClick = () => {
    if (disabled) {
      return
    } else {
      setChecked(!checked)
      onClick?.(!checked)
    }
  }

  const handleMouseEnter = () => {
    if (onMouseEnter) {
      onMouseEnter?.()
    }
  }

  const handleMouseLeave = () => {
    if (onMouseLeave) {
      onMouseLeave?.()
    }
  }

  const handleFocus = () => {
    if (onFocus) {
      onFocus?.()
    }
  }

  const handleBlur = () => {
    if (onBlur) {
      onBlur?.()
    }
  }

  const handleKeyUp = (event: React.KeyboardEvent<HTMLElement>) => {
    if (onKeyUp) {
      if (event.code === 'Enter' || event.code === 'Space') {
        setChecked(!checked)
        onKeyUp?.(event, !checked)
      }
    }
  }

  const toggleControlContainer = (
    check: boolean,
    disable?: boolean,
    focus?: boolean,
  ): CSSProps => {
    const darkModeBorder = () => {
      if (isDarkTheme) {
        if (disable) {
          return 'transparent'
        }
        if (focus) {
          return colors.util.navy[50]
        } else {
          return 'transparent'
        }
      }

      if (disable) {
        return 'transparent'
      }
      if (focus) {
        return colors.util.navy[600]
      } else {
        return '#fff'
      }
    }

    return {
      position: 'relative',
      cursor: `${disable ? 'unset' : 'pointer'}`,
      display: 'flex',
      alignItems: 'center',
      margin: '4px',
      border: `1px solid ${darkModeBorder()}`,
      outlineColor: `${darkModeBorder()}`,
      padding: '12px',
      borderRadius: '5px',
    }
  }

  const toggleControlOutline = (
    check?: boolean,
    disable?: boolean,
  ): CSSProps => {
    const darkModeBackground = () => {
      if (isDarkTheme) {
        if (disable && check) {
          return colors.util.navy[200]
        }
        if (check) {
          return '#fff'
        } else {
          return 'transparent'
        }
      }
      if (disable && check) {
        return colors.neutral[200]
      }
      if (check) {
        return colors.util.navy[600]
      } else {
        return '#fff'
      }
    }

    const darkModeBorder = () => {
      let borderColor = ''
      if (isDarkTheme) {
        if ((disable && check) || (disable && !check)) {
          borderColor = colors.util.navy[200]
        } else if (check) {
          borderColor = colors.util.navy[50]
        } else {
          borderColor = colors.util.navy[100]
        }
      } else {
        if (disable && check) {
          borderColor = colors.neutral[200]
        } else if (disable && !check) {
          borderColor = colors.neutral[100]
        } else if (check) {
          borderColor = colors.util.navy[600]
        } else {
          borderColor = colors.neutral[100]
        }
      }

      return `2px solid ${borderColor}`
    }

    return {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: darkModeBackground(),
      border: darkModeBorder(),
      borderRadius: '3em',
      width: '44px',
      height: '24px',
      marginLeft: '8px',
    }
  }

  const toggleControl = (check?: boolean, disable?: boolean): CSSProps => {
    const darkModeBackground = () => {
      if (isDarkTheme) {
        if (disable) {
          return 'transparent'
        } else if (check) {
          return colors.util.navy[600]
        } else {
          return colors.util.navy[100]
        }
      }

      if (disable && check) {
        return colors.neutral[200]
      } else if (disable && !check) {
        return 'transparent'
      } else if (check) {
        return '#fff'
      } else {
        return colors.neutral[200]
      }
    }

    const darkModeBorder = () => {
      let borderColor = ''
      if (isDarkTheme) {
        if (disable) {
          if (disable && check) {
            borderColor = colors.util.navy[600]
          } else if (disable && !check) {
            borderColor = colors.util.navy[200]
          } else {
            borderColor = 'none'
          }
        } else {
          return 'none'
        }
      } else {
        if (disable) {
          if (disable && check) {
            borderColor = '#fff'
          } else if (disable && !check) {
            borderColor = colors.neutral[100]
          } else if (check) {
            borderColor = colors.util.navy[600]
          } else {
            borderColor = colors.neutral[200]
          }
        } else {
          return 'none'
        }
      }

      return `2px solid ${borderColor}`
    }

    return {
      margin: '3px 2px',
      backgroundColor: darkModeBackground(),
      border: darkModeBorder(),
      borderRadius: '100%',
      width: disable ? '17px' : '18px',
      height: disable ? '17px' : '18px',
      transform: check ? 'translateX(20px)' : 'translateX(0px)',
      transition: 'all .2s',
    }
  }

  const toggleStyles: CSSProps = {
    visibility: 'hidden',
    position: 'absolute',
    top: '0',
    left: '0',
    width: 'auto',
    height: 'auto',
  }

  const darkModeText = () => {
    if (isDarkTheme) {
      if (disabled) {
        return colors.util.navy[100]
      }
      if (checked) {
        return colors.util.navy[50]
      } else {
        return colors.util.navy[100]
      }
    }
    return disabled ? colors.neutral[300] : colors.util.navy[600]
  }

  const labelStyle = !isDarkTheme
    ? {
        ...toggleControlContainer(checked, disable, focus),
        ...{ outlineColor: colors.util.navy[600] },
        ...labelStyles,
      }
    : { ...toggleControlContainer(checked, disable, focus), ...labelStyles }

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <label
      data-testid={labelTestId}
      htmlFor={label}
      style={labelStyle}
      onKeyUp={(e: React.KeyboardEvent<HTMLLabelElement>) => handleKeyUp(e)}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onClick={handleClick}
      tabIndex={disable ? -1 : 0}
    >
      {label && (
        <Typography component={'span'} variant="text10" color={darkModeText()}>
          {label}
        </Typography>
      )}
      <input
        data-testid={inputTestId}
        type="checkbox"
        style={toggleStyles}
        name={name}
        disabled={disable}
        onChange={handleClick}
        checked={checked}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
      />
      <div
        style={{ ...toggleControlOutline(checked, disabled), ...inputStyles }}
      >
        <div style={toggleControl(checked, disabled)}></div>
      </div>
    </label>
  )
}

export default Toggle
