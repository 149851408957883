import { useTheme } from '@mui/material'

import { CrowdStrikeTicketPriorityEnum } from '@models/Tickets'
import { Customer } from '@models/Customer'

import { useCustomer } from '../useCustomer'
import {
  EDRAssetAlertSeverityConfig,
  AssetAlertSeverityItem,
  EDRAssetTableColumnMapping,
  EDRAssetTableStatusMapping,
  EDRBanContent,
  EDRContent,
  EDRAssetTableStatusConfig,
  EDRAssetTableFilterMapping,
} from './useEDRContentTypes'
import { EDRProvider } from './edrConstants'

/**
 * A helper function to determine if a customer has a supported EDR provider
 *
 * If the customer's EDR provider is not supported, the EDR pages and navigation elements should not be accessible
 * @param {Partial<Customer>} customer A customer object with at least the `serviceNgMedrTechnology` field
 * @returns {boolean} `true` if the customer has a supported EDR provider, `false` otherwise
 */
export const doesCustomerHaveSupportedEDRProvider = (
  customer: Pick<Customer, 'serviceNgMedrTechnology'>,
): boolean => {
  return isValidEDRProvider(customer.serviceNgMedrTechnology?.toLowerCase())
}

const isValidEDRProvider = (
  provider: string | undefined,
): provider is EDRProvider =>
  provider !== undefined &&
  Object.values(EDRProvider).includes(provider as EDRProvider)

const edrBanContent: Record<EDRProvider, EDRBanContent> = {
  [EDRProvider.Crowdstrike]: {
    alertsLedToContainment: 'Alerts Led to Containment',
    containedAssets: 'Contained Devices',
    inactiveDevices: 'RFM Sensors',
  },
  [EDRProvider.MicrosoftDefenderForEndpoint]: {
    alertsLedToContainment: 'Alerts Led to Isolation',
    containedAssets: 'Isolated Devices',
    inactiveDevices: 'Inactive Devices',
  },
  // Add additional EDR providers here
}

const edrAssetTableColumnMapping: Record<
  EDRProvider,
  EDRAssetTableColumnMapping
> = {
  [EDRProvider.Crowdstrike]: {
    rfmStatus: 'RFM',
  },
  [EDRProvider.MicrosoftDefenderForEndpoint]: {
    rfmStatus: 'inactive',
  },
  // Add additional EDR providers here
}

const useEDRAssetTableStatusMapping = (
  provider: EDRProvider,
): EDRAssetTableStatusMapping => {
  const theme = useTheme()
  const pendingIconConfig: Readonly<Omit<EDRAssetTableStatusConfig, 'text'>> = {
    color: theme.vars.palette.warning.light,
    icon: 'radioButtonOnSharp',
  }
  const normalIconConfig: Readonly<EDRAssetTableStatusConfig> = {
    color: theme.vars.palette.success.light,
    icon: 'radioButtonOnSharp',
    text: 'Normal',
  }
  const containedIconConfig: Readonly<Omit<EDRAssetTableStatusConfig, 'text'>> =
    {
      color: theme.vars.palette.error.light,
      icon: 'radioButtonOffSharp',
    }
  const statusMapping: Record<EDRProvider, EDRAssetTableStatusMapping> = {
    [EDRProvider.Crowdstrike]: {
      containment_pending: {
        ...pendingIconConfig,
        text: 'Containment pending',
      },
      contained: {
        ...containedIconConfig,
        text: 'Contained',
      },
      lift_containment_pending: {
        ...pendingIconConfig,
        text: 'Lift containment pending',
      },
      normal: normalIconConfig,
    },
    [EDRProvider.MicrosoftDefenderForEndpoint]: {
      containment_pending: {
        ...pendingIconConfig,
        text: 'Isolation pending',
      },
      contained: {
        ...containedIconConfig,
        text: 'Isolated',
      },
      lift_containment_pending: {
        ...pendingIconConfig,
        text: 'Lift isolation pending',
      },
      normal: normalIconConfig,
    },
    // Add additional EDR providers here
  }
  return statusMapping[provider as EDRProvider]
}

const useEDRSeverityConfig = (
  provider: EDRProvider,
): EDRAssetAlertSeverityConfig => {
  const theme = useTheme()
  const baseSeverityConfig: AssetAlertSeverityItem[] = [
    {
      key: 'highCount',
      label: CrowdStrikeTicketPriorityEnum.High,
      color: theme.palette.severity.high!,
    },
    {
      key: 'mediumCount',
      label: CrowdStrikeTicketPriorityEnum.Medium,
      color: theme.palette.severity.medium!,
    },
    {
      key: 'lowCount',
      label: CrowdStrikeTicketPriorityEnum.Low,
      color: theme.palette.severity.low!,
    },
    {
      key: 'informationalCount',
      label: CrowdStrikeTicketPriorityEnum.Informational,
      color: theme.palette.severity.info!,
    },
  ]
  const severityConfig: Record<EDRProvider, AssetAlertSeverityItem[]> = {
    [EDRProvider.Crowdstrike]: [
      {
        key: 'criticalCount',
        label: CrowdStrikeTicketPriorityEnum.Critical,
        color: theme.palette.severity.critical!,
      },
      ...baseSeverityConfig,
    ],
    [EDRProvider.MicrosoftDefenderForEndpoint]: baseSeverityConfig,
    // Add additional EDR providers here
  }
  const items = severityConfig[provider as EDRProvider]
  return {
    keys: items.map((item) => item.key),
    colors: items.map((item) => item.color),
    legendItems: items.map((item) => ({
      label: item.label,
      color: item.color,
    })),
  }
}

const edrAssetTableFilterMapping: Record<
  EDRProvider,
  EDRAssetTableFilterMapping
> = {
  [EDRProvider.Crowdstrike]: {
    status: {
      label: 'Status',
    },
    rfmStatus: {
      label: 'RFM Status',
    },
  },
  [EDRProvider.MicrosoftDefenderForEndpoint]: {
    status: {
      label: 'Status',
      transformValueForDisplay: (value) => {
        switch (value) {
          case 'contained':
            return 'isolated'
          case 'containment_pending':
            return 'isolation_pending'
          case 'lift_containment_pending':
            return 'lift_isolation_pending'
          default:
            return value
        }
      },
    },
    rfmStatus: {
      label: 'Inactive Status',
    },
  },
  // Add additional EDR providers here
}

/**
 * This hook returns custom content for the customer's EDR provider
 * @returns {EDRContent} The custom content for the customer's EDR provider
 */
export const useEDRContent = (): EDRContent => {
  const { customer } = useCustomer()
  const edrProvider = customer.serviceNgMedrTechnology?.toLowerCase()
  const provider = isValidEDRProvider(edrProvider)
    ? edrProvider
    : EDRProvider.Crowdstrike // Default to 'crowdstrike' if an invalid provider is passed
  const assetAlertSeverityConfig = useEDRSeverityConfig(provider)
  const assetTableStatusMapping = useEDRAssetTableStatusMapping(provider)
  const banContent = edrBanContent[provider as EDRProvider]
  const assetTableColumnMapping =
    edrAssetTableColumnMapping[provider as EDRProvider]
  const assetTableFilterMapping =
    edrAssetTableFilterMapping[provider as EDRProvider]
  return {
    banContent,
    assetAlertSeverityConfig,
    assetTableStatusMapping,
    assetTableColumnMapping,
    assetTableFilterMapping,
  }
}
