import React, { CSSProperties } from 'react'

import { CSSProps, TextVariant, TypographyProps } from '../../interfaces'
import useTheme from '../../hooks/useTheme'
import { colors } from '../../theme'

interface Stylesheet {
  [key: string]: CSSProps
}

const getKeyValue = function <
  T extends Record<string, CSSProperties>,
  U extends keyof T,
>(obj: T, key: U): CSSProperties {
  return obj[`${String(key)}`]
}

const sharedStyles: CSSProps = {
  fontFamily: 'Inter',
  fontWeight: 400,
  verticalAlign: 'middle',
}

const mediumStyles: CSSProps = {
  fontWeight: 500,
}

const semiboldStyles: CSSProps = {
  fontWeight: 600,
}

const boldStyles: CSSProps = {
  fontWeight: 700,
}

const uppercaseStyles: CSSProps = {
  textTransform: 'uppercase',
}

const typographyStyles = (color: string): Stylesheet =>
  ({
    text1: {
      ...sharedStyles,
      color,
      fontSize: 144,
      fontWeight: 700,
    },
    text2: {
      ...sharedStyles,
      color,
      fontSize: 96,
      fontWeight: 500,
    },
    text3: {
      ...sharedStyles,
      color,
      fontSize: 60,
    },
    text4: {
      ...sharedStyles,
      color,
      fontSize: 55,
    },
    text5: {
      ...sharedStyles,
      color,
      fontSize: 48,
    },
    text6: {
      ...sharedStyles,
      color,
      fontSize: 34,
    },
    text7: {
      ...sharedStyles,
      color,
      fontSize: 28,
    },
    text8: {
      ...sharedStyles,
      color,
      fontSize: 22,
    },
    text9: {
      ...sharedStyles,
      color,
      fontSize: 18,
    },
    text10: {
      ...sharedStyles,
      color,
      fontSize: 16,
    },
    text11: {
      ...sharedStyles,
      color,
      fontSize: 14,
    },
    text12: {
      ...sharedStyles,
      color,
      fontSize: 12,
    },
    text3medium: {
      ...sharedStyles,
      ...mediumStyles,
      color,
      fontSize: 60,
    },
    text4medium: {
      ...sharedStyles,
      ...mediumStyles,
      color,
      fontSize: 55,
    },
    text4bold: {
      ...sharedStyles,
      ...boldStyles,
      color,
      fontSize: 55,
    },
    text5medium: {
      ...sharedStyles,
      ...mediumStyles,
      color,
      fontSize: 48,
    },
    text6semibold: {
      ...sharedStyles,
      ...semiboldStyles,
      color,
      fontSize: 34,
    },
    text7semibold: {
      ...sharedStyles,
      ...semiboldStyles,
      color,
      fontSize: 28,
    },
    text7medium: {
      ...sharedStyles,
      ...mediumStyles,
      color,
      fontSize: 28,
    },
    text8semibold: {
      ...sharedStyles,
      ...semiboldStyles,
      color,
      fontSize: 22,
    },
    text9semibold: {
      ...sharedStyles,
      ...semiboldStyles,
      color,
      fontSize: 18,
    },
    text10semibold: {
      ...sharedStyles,
      ...semiboldStyles,
      color,
      fontSize: 16,
    },
    text10medium: {
      ...sharedStyles,
      ...mediumStyles,
      color,
      fontSize: 16,
    },
    text11medium: {
      ...sharedStyles,
      ...mediumStyles,
      color,
      fontSize: 14,
    },
    text12medium: {
      ...sharedStyles,
      ...mediumStyles,
      color,
      fontSize: 12,
    },
    text11semibold: {
      ...sharedStyles,
      ...semiboldStyles,
      color,
      fontSize: 14,
    },
    text12uppercase: {
      ...sharedStyles,
      ...uppercaseStyles,
      color,
      fontSize: 12,
    },
    text12semibold: {
      ...sharedStyles,
      ...semiboldStyles,
      color,
      fontSize: 12,
    },
  }) as const

const Typography = ({
  variant,
  component = 'p',
  color,
  size = 16,
  weight = 400,
  uppercase,
  children,
  styles,
  onClick,
  shouldParseNewLines,
  withEllipsis,
}: TypographyProps): JSX.Element => {
  const isDarkTheme = useTheme('dark')
  const textColor = color
    ? color
    : // eslint-disable-next-line @getify/proper-ternary/nested
    isDarkTheme
    ? colors.util.one.light
    : colors.util.one.main
  const variantStyle = {
    text1: typographyStyles(textColor).text1,
    text2: typographyStyles(textColor).text2,
    text3: typographyStyles(textColor).text3,
    text4: typographyStyles(textColor).text4,
    text5: typographyStyles(textColor).text5,
    text6: typographyStyles(textColor).text6,
    text7: typographyStyles(textColor).text7,
    text8: typographyStyles(textColor).text8,
    text9: typographyStyles(textColor).text9,
    text10: typographyStyles(textColor).text10,
    text11: typographyStyles(textColor).text11,
    text12: typographyStyles(textColor).text12,
    text3medium: typographyStyles(textColor).text3medium,
    text4medium: typographyStyles(textColor).text4medium,
    text4bold: typographyStyles(textColor).text4bold,
    text5medium: typographyStyles(textColor).text5medium,
    text6semibold: typographyStyles(textColor).text6semibold,
    text7semibold: typographyStyles(textColor).text7semibold,
    text7medium: typographyStyles(textColor).text7medium,
    text8semibold: typographyStyles(textColor).text8semibold,
    text9semibold: typographyStyles(textColor).text9semibold,
    text10semibold: typographyStyles(textColor).text10semibold,
    text10medium: typographyStyles(textColor).text10medium,
    text11medium: typographyStyles(textColor).text11medium,
    text11semibold: typographyStyles(textColor).text11semibold,
    text12medium: typographyStyles(textColor).text12medium,
    text12uppercase: typographyStyles(textColor).text12uppercase,
    text12semibold: typographyStyles(textColor).text12semibold,
  }

  const elementStyle: CSSProps | unknown =
    typeof variant !== 'undefined'
      ? {
          ...getKeyValue(variantStyle, `${variant}` as TextVariant),
          ...(withEllipsis && {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }),
          ...(styles && styles),
        }
      : ({
          ...sharedStyles,
          ...(textColor && { color: textColor }),
          ...(size && { fontSize: size }),
          ...(weight && { fontWeight: weight }),
          ...(uppercase && { textTransform: 'uppercase' }),
          ...(shouldParseNewLines && {
            whiteSpace: 'pre-line',
            wordBreak: 'normal',
            overflowWrap: 'anywhere',
            width: 0,
            minWidth: '100%',
          }),
          ...(withEllipsis && {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }),
          ...(styles && styles),
        } as CSSProps)

  return React.createElement(
    component,
    {
      style: elementStyle,
      onClick,
    },
    children,
  )
}

export default Typography
