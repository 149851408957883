import { IconVariant } from '../../../../design-system/interfaces'
import { DashboardLinksData, DashboardLink } from './MyLinksWidget'

export const getLinkTitle = (key: string): string => {
  switch (key) {
    case 'splunk':
      return 'Splunk'
    case 'servicenow':
      return 'ServiceNow'
    case 'threatLabs':
      return 'Deepwatch Malware Portal'
    default:
      return ''
  }
}

export const getLinkUrl = (key: string, data: DashboardLinksData): string => {
  switch (key) {
    case 'splunk':
      return data.splunk
    case 'servicenow':
      return data.servicenow
    case 'threatLabs':
      return data.threatLabs
    default:
      return ''
  }
}

export const getLinkIcon = (key: string): IconVariant => {
  switch (key) {
    case 'threatLabs':
      return 'nuclearOutline'
    default:
      return 'logOutOutline'
  }
}

export const shapeMyLinksData = (data: DashboardLinksData): DashboardLink[] => {
  if (!data) {
    return []
  }

  return Object.keys(data)
    .filter((key) => key !== '__typename')
    .map((key) => {
      const title = getLinkTitle(key)
      const url = getLinkUrl(key, data)
      const icon = getLinkIcon(key)

      return {
        title,
        url,
        icon,
      }
    })
    .filter((link) => link.title !== '' && link.url !== '')
}
