import { useMutation } from '@apollo/client'
import { useContext } from 'react'

import { ModalContentProps } from '../../../common/Modal/Modal'
import {
  Button,
  InlineMessageType,
  Typography,
  colors,
} from '../../../../design-system'
import { deployToastMessage } from '../../../../utils'
import { Context } from '../../../App'
import {
  DELETE_USER,
  DeleteUserData,
  DeleteUserVariables,
} from '../../../../graphql/mutations/user'
import { GET_USER_MANAGEMENT_SETTINGS } from '../../../../graphql/queries/user'

import './DeleteUserModal.scss'

export interface DeleteUserModalProps extends ModalContentProps {
  userId: string
  userName: string
}

const DeleteUserModal: React.FC<DeleteUserModalProps> = ({
  closeModal,
  userId,
  userName,
}) => {
  const { dispatch: globalDispatch } = useContext(Context)

  const [deleteUser] = useMutation<DeleteUserData, DeleteUserVariables>(
    DELETE_USER,
    {
      refetchQueries: [GET_USER_MANAGEMENT_SETTINGS],
    },
  )

  const toggleModal = () => {
    closeModal()
  }

  const displaySuccessToast = () => {
    deployToastMessage(
      {
        id: crypto.randomUUID(),
        text: 'User deleted!',
        messageType: InlineMessageType.SuccessInline,
        secondsToExpire: 3000,
        dismissible: false,
      },
      globalDispatch,
    )
  }

  const displayProcessingToast = () => {
    deployToastMessage(
      {
        id: crypto.randomUUID(),
        text: 'Processing request to delete user',
        messageType: InlineMessageType.Info,
        dismissible: false,
        secondsToExpire: 3000,
      },
      globalDispatch,
    )
  }

  const displayErrorToast = () => {
    deployToastMessage(
      {
        id: crypto.randomUUID(),
        text: 'Deleting user has failed. Please try again in a minute.',
        messageType: InlineMessageType.ErrorInline,
        secondsToExpire: 3000,
        dismissible: false,
      },
      globalDispatch,
    )
  }

  const handleDeleteUser = async () => {
    toggleModal()
    displayProcessingToast()

    await deleteUser({
      variables: {
        input: {
          userId,
        },
      },
      onCompleted: displaySuccessToast,
      onError: displayErrorToast,
    })
  }
  return (
    <div id="delete-user-modal" data-testid="delete-user-modal">
      <Typography
        weight={600}
        color={colors.util.one.lighter}
        component="div"
        size={16}
      >
        {`Are you sure you want to delete ${userName}? They will no longer have access once deleted.`}
      </Typography>
      <span
        key={'B'}
        style={{
          display: 'flex',
          marginTop: 25,
        }}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'row',
          gap: 16,
        }}
        data-testid="user-status-modal-buttons"
      >
        <Button
          testId="user-status-cancel-btn"
          variant="secondary"
          type="button"
          onClick={() => toggleModal()}
          buttonStyles={{ width: 160, justifyContent: 'center' }}
        >
          Cancel
        </Button>
        <Button
          variant={'primary'}
          type={'button'}
          buttonStyles={{ width: 160, justifyContent: 'center', fontSize: 16 }}
          testId={'user-status-button'}
          onClick={() => handleDeleteUser()}
          label={'Delete'}
        />
      </div>
    </div>
  )
}

export default DeleteUserModal
