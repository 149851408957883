import {
  MitreCoverageCustomer,
  MitreCoverageTactic,
} from '../../../../models/DetectionCoverage'
import { Typography, colors } from '../../../../design-system'

interface TacticDetailProps {
  customerTacticTechniques: MitreCoverageCustomer[]
  tactic: MitreCoverageTactic
}

const TacticDetail = ({
  customerTacticTechniques,
  tactic,
}: TacticDetailProps) => {
  const customerTechniquesCovered = customerTacticTechniques.reduce(
    (prev, curr) => (curr.totalEnabledUseCaseCount > 0 ? prev + 1 : prev),
    0,
  )

  const customerSubTechniquesCovered = customerTacticTechniques.reduce(
    (prev, curr) =>
      prev +
      curr.subtechniques.reduce(
        (prev, curr) => (curr.totalEnabledUseCaseCount > 0 ? prev + 1 : prev),
        0,
      ),
    0,
  )

  return (
    <>
      <div className="mitre-title-description">
        <Typography
          component="div"
          size={16}
          weight={600}
          color={colors.util.navy[50]}
        >
          {tactic.mitreTacticName}
        </Typography>
        <Typography
          component="div"
          size={14}
          styles={{ wordBreak: 'break-word' }}
        >
          {tactic.mitreTacticDescription}
        </Typography>
      </div>
      <div className="mitre-details">
        <div data-testid="tactic-techniques">
          <Typography component="div" size={14} color={colors.util.navy[100]}>
            Techniques
          </Typography>
          <Typography component="div" size={14} color={colors.util.navy[50]}>
            {tactic.techniques.length}
          </Typography>
        </div>
        <div data-testid="tactic-id">
          <Typography component="div" size={14} color={colors.util.navy[100]}>
            ID
          </Typography>
          <Typography component="div" size={14} color={colors.util.navy[50]}>
            {tactic.mitreTacticId}
          </Typography>
        </div>
        <div data-testid="tactic-techniques-covered">
          <Typography component="div" size={14} color={colors.util.navy[100]}>
            Techniques covered
          </Typography>
          <Typography component="div" size={14} color={colors.util.navy[50]}>
            {customerTechniquesCovered}
          </Typography>
        </div>
        <div data-testid="tactic-available-detections">
          <Typography component="div" size={14} color={colors.util.navy[100]}>
            Available detections
          </Typography>
          <Typography component="div" size={14} color={colors.util.navy[50]}>
            {tactic.totalUseCaseCount}
          </Typography>
        </div>
        <div style={{ flex: '100%' }} data-testid="tactic-sub-techniques">
          <Typography component="div" size={14} color={colors.util.navy[100]}>
            Sub techniques
          </Typography>
          <Typography component="div" size={14} color={colors.util.navy[50]}>
            {tactic.techniques.reduce(
              (prev, curr) => prev + curr.subtechniques.length,
              0,
            )}
          </Typography>
        </div>
        <div
          style={{ flex: '100%' }}
          data-testid="tactic-covered-sub-techniques"
        >
          <Typography component="div" size={14} color={colors.util.navy[100]}>
            Sub techniques covered
          </Typography>
          <Typography component="div" size={14} color={colors.util.navy[50]}>
            {customerSubTechniquesCovered}
          </Typography>
        </div>
      </div>
    </>
  )
}

export default TacticDetail
