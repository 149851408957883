import { CSSProperties, useContext, useState, useEffect, useRef } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { CSSTransition } from 'react-transition-group'
import { debounce } from 'throttle-debounce'

import { NavIcon } from './NavIcon'
import { HelpButton } from './HelpButton'
import { Context } from '../../App'
import { colors } from '../../../design-system/theme'
import { FontWeightType, Icon, Typography } from '../../../design-system'
import {
  AppRouteItem,
  IMenuItem,
  NavMenuItemType,
  NavMenuSet,
} from './SideBarTypes'
import {
  mainMenuSet,
  settingsMenuSet,
  settingsMobileMenuSet,
  mainMobileMenuSet,
} from './SideBarMenuTree'
import { Paths } from '../../App/Types'

import './Sidebar.scss'
import './Sidebar-Animate.scss'

export const getLinkStyles = (isActive: boolean): CSSProperties => {
  return {
    color: isActive ? colors.util.one.lighter : colors.util.one.light,
    fontWeight: isActive ? 'bold' : 'normal',
  } as CSSProperties
}

export const getVariant = (isDesktop: boolean, menuIn: boolean) => {
  if (isDesktop) {
    return menuIn ? 'Expand' : 'Collapse'
  } else {
    return menuIn ? 'Collapse' : 'Expand'
  }
}

export const getNavActiveFromTabRoute = (navItem: string): string => {
  switch (navItem) {
    case 'detection-catalog-active':
      return 'detection-coverage-active'
    case 'security-index-active':
      return 'dashboard-active'
    default:
      return navItem
  }
}

const Sidebar = () => {
  const [menuIn, setMenuIn] = useState(false)
  const [menuText, setMenuText] = useState(false)
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1200)
  const [isMobile, setIsMobile] = useState(window.innerWidth < 560)
  const { navigationHelpButton, featureSettings, featureThreatIntel } =
    useFlags()

  const {
    state: {
      user: { isDWEmployee, isAdmin },
      dwExpertsCustomer: { customerShortName },
    },
  } = useContext(Context)

  const { innerWidth } = window

  const fontSize = innerWidth > 559 ? 16 : 10

  const location = useLocation()

  let url: string | undefined = location.pathname.split('/')[1]
  let currentMenuSet: NavMenuSet = mainMenuSet

  if (isMobile) {
    currentMenuSet = mainMobileMenuSet
  }

  if (!featureThreatIntel) {
    mainMenuSet.menuItems = mainMenuSet.menuItems.filter(
      (item) => item.id !== 'threat-intel-active',
    )
  }

  if (featureSettings) {
    const pathNameParts = location.pathname.split('/')
    url = pathNameParts.at(pathNameParts.length - 1)

    if (location.pathname.startsWith(Paths.SETTINGS)) {
      const menuSet = isMobile ? settingsMobileMenuSet : settingsMenuSet

      if (!isAdmin) {
        menuSet.menuItems = menuSet.menuItems.filter(
          (item) => item.id !== 'user-management-active',
        )
      }

      currentMenuSet = menuSet
    }
  }

  const [navActive, setNavActive] = useState(`${url}-active`)

  useEffect(() => {
    let didMount = false
    const handleResize = debounce(500, () => {
      !didMount && setMenuIn(false)
    })

    window.addEventListener('resize', handleResize)

    return () => {
      didMount = true
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    //? needed for when user is navigated via useNavigate
    switch (url) {
      case 'detection-catalog':
        setNavActive(`detection-coverage-active`)
        break
      case 'your-index':
      case 'change-history':
        setNavActive(`dashboard-active`)
        break
      default:
        setNavActive(`${url}-active`)
        break
    }
  }, [url])

  useEffect(() => {
    if (isMobile) {
      setMenuText(true)
    } else {
      setTimeout(() => setMenuText(isDesktop ? !menuIn : menuIn), 50)
    }
  }, [menuIn, isDesktop, isMobile])

  useEffect(() => {
    const handleWindowResize = () => {
      const newIsDesktop = window.innerWidth >= 1200
      const newIsMobile = window.innerWidth < 560

      if (isDesktop !== newIsDesktop) {
        setIsDesktop(newIsDesktop)
        setMenuIn(false)
      }

      if (isMobile !== newIsMobile) {
        setIsMobile(newIsMobile)
        setMenuText(newIsMobile)
      }

      if (menuIn) {
        setMenuIn(false)
      }
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [isDesktop, isMobile, menuIn])

  const toggleRef = useRef(null)
  const navBarDrawerRef = useRef(null)

  const handleExpandClick = () => {
    setMenuIn((prevState) => !prevState)
  }

  const navLinkRef = useRef(new Array(currentMenuSet.menuItems.length + 1))

  const handleNavItemClick = ({ id }, index: number) => {
    navLinkRef.current[`${index}`] = id
    setNavActive(id)
    if (window.innerWidth >= 560 && window.innerWidth <= 1199) {
      setMenuIn(false)
    }
    document
      .getElementsByTagName('html')
      .item(0)
      ?.scroll({ top: 0, left: 0, behavior: 'smooth' })
  }

  const getTextColor = (currentNav: string, navActive: string): string => {
    if (navActive === currentNav) {
      return colors.util.navy[50]
    } else {
      return colors.util.one.light
    }
  }

  const getTextWeight = (
    currentNav: string,
    navActive: string,
  ): FontWeightType => {
    if (navActive === currentNav) {
      return 600
    } else {
      return 400
    }
  }

  const getMenuTree = (currentMenuSet: NavMenuSet): JSX.Element[] => {
    const resultElement: JSX.Element[] = []
    currentMenuSet.menuItems.forEach((menuItem, index) => {
      if (menuItem.type === NavMenuItemType.APP_ROUTE_CHANGE) {
        resultElement.push(createAppRouteListItem(menuItem, index + 1))
      }
    })
    return resultElement
  }

  const buildNavLink = (dwEmployee, path): string => {
    switch (path) {
      case '/dashboard':
      case '/detection-coverage':
        return dwEmployee
          ? `${path}?customer=${customerShortName}&tab=0`
          : `${path}?tab=0`

      default:
        return dwEmployee ? `${path}?customer=${customerShortName}` : path
    }
  }

  const createAppRouteListItem = (
    menuItem: IMenuItem,
    refIndex: number,
  ): JSX.Element => {
    const tempAppRouteItem = menuItem as AppRouteItem

    return (
      <li className={menuItem.id} key={menuItem.id} data-testid={menuItem.id}>
        <NavLink
          to={buildNavLink(isDWEmployee, tempAppRouteItem.targetPath)}
          className="navlink"
          style={({ isActive }) => getLinkStyles(isActive)}
          onClick={() => handleNavItemClick({ id: tempAppRouteItem.id }, 0)}
        >
          <Icon
            title={`${tempAppRouteItem.label} Icon`}
            size={24}
            variant={
              getNavActiveFromTabRoute(navActive) === tempAppRouteItem.id
                ? tempAppRouteItem.activeIcon
                : tempAppRouteItem.inactiveIcon
            }
            color={
              getNavActiveFromTabRoute(navActive) === tempAppRouteItem.id
                ? colors.util.navy[50]
                : colors.util.one.light
            }
          />
          <CSSTransition
            nodeRef={navLinkRef[`${refIndex}`]}
            in={menuIn}
            timeout={300}
            classNames="draweritem"
          >
            <div ref={navLinkRef[`${refIndex}`]} className="navlink-text">
              <Typography
                component="span"
                size={fontSize}
                color={getTextColor(tempAppRouteItem.id, navActive)}
                weight={getTextWeight(tempAppRouteItem.id, navActive)}
              >
                {menuText && tempAppRouteItem.label}
              </Typography>
            </div>
          </CSSTransition>
        </NavLink>
      </li>
    )
  }

  //? the non-breaking spaces here are needed so the animation functionality doesn't break
  return (
    <CSSTransition
      nodeRef={toggleRef}
      in={menuIn}
      appear={!menuIn}
      timeout={300}
      classNames="drawer"
    >
      <aside
        className="aside left"
        ref={toggleRef}
        data-testid="sidebar-element"
      >
        <button className="toggle-menu" onClick={handleExpandClick}>
          <NavIcon
            variant={getVariant(isDesktop, menuIn)}
            size={32}
            className="toggle-menu-icon"
          />
        </button>
        <CSSTransition
          nodeRef={navBarDrawerRef}
          in={menuIn}
          timeout={300}
          classNames="drawer"
        >
          <ul className="menu" ref={navBarDrawerRef}>
            <li className="section-title">
              <CSSTransition
                nodeRef={navLinkRef[0]}
                in={menuIn}
                timeout={300}
                classNames="draweritem"
              >
                <div
                  ref={navLinkRef[0]}
                  className="navlink-section"
                  data-testid="menu-header"
                >
                  {currentMenuSet.parentSet && (
                    <NavLink
                      to={`/dashboard${
                        isDWEmployee ? '?customer=' + customerShortName : ''
                      }`}
                      className="navbackbutton"
                      style={({ isActive }) => getLinkStyles(isActive)}
                    >
                      <Icon variant="arrowBackCircleOutline" size={20}></Icon>
                    </NavLink>
                  )}

                  {menuText && currentMenuSet.menuHeaderLabel}
                </div>
              </CSSTransition>
            </li>

            {getMenuTree(currentMenuSet)}

            {navigationHelpButton && (
              <HelpButton
                menuIn={menuIn}
                showMenuText={menuText}
                fontSize={fontSize}
              />
            )}
          </ul>
        </CSSTransition>
      </aside>
    </CSSTransition>
  )
}

export default Sidebar
