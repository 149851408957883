import { useEffect, useState } from 'react'

import { Button, Typography, colors } from '../../../../design-system'
import { ModalContentProps } from '../Modal'
import { oktaAuthConfig } from '../../../../config/OktaAuthConfig'
import { setCookie } from '../../../../utils'

import './SessionTimeoutModal.scss'

export interface SessionTimeoutModalProps extends ModalContentProps {
  secondsLeft: number
  resetIdleTimer: () => boolean
}

const SessionTimeoutModal = ({
  closeModal,
  resetIdleTimer,
  secondsLeft,
}: SessionTimeoutModalProps) => {
  const [timeLeft, setTimeLeft] = useState(secondsLeft)

  useEffect(() => {
    if (timeLeft === 0) return

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1)
    }, 1000)

    return () => clearInterval(intervalId)
  }, [timeLeft])

  const signOut = (sessionExpired: boolean) => {
    setCookie('se', sessionExpired ? '1' : '0')
    oktaAuthConfig.signOut()
  }

  const minutes = Math.trunc(timeLeft / 60)
  const seconds = timeLeft % 60
  let formatText = ''
  if (minutes > 0) {
    formatText = `${minutes} Minutes ${seconds} Seconds`
  } else {
    formatText = `${seconds} Seconds`
  }

  return (
    <div id="session-timeout-modal" data-testid="session-timeout-modal">
      <Typography
        weight={400}
        color={colors.util.navy[100]}
        component="div"
        size={16}
      >
        {'Your session will expire in'}
      </Typography>
      <Typography
        weight={500}
        color={colors.common.white}
        component="div"
        size={28}
      >
        {formatText}
      </Typography>
      <Typography
        weight={400}
        color={colors.util.navy[100]}
        component="div"
        size={14}
      >
        {'You will automatically be logged out after session time expires!'}
      </Typography>
      <span
        key={'B'}
        style={{
          display: 'flex',
          marginTop: 25,
        }}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          flexDirection: 'row',
          gap: '1rem',
        }}
        data-testid="session-timeout-modal-buttons"
      >
        <Button
          testId="session-timeout-log-out-btn"
          variant="secondary"
          type="button"
          onClick={() => signOut(false)}
          buttonStyles={{ width: 160, justifyContent: 'center' }}
          label="Log Out"
        />
        <Button
          variant={'primary'}
          type={'button'}
          buttonStyles={{ width: 160, justifyContent: 'center' }}
          testId={'session-timeout-continue-btn'}
          onClick={() => {
            closeModal()
            // reset tokens
            oktaAuthConfig.session.exists().then((data) => {
              if (data) {
                oktaAuthConfig.token.getWithoutPrompt().then((data) => {
                  oktaAuthConfig.tokenManager.setTokens(data.tokens)
                  resetIdleTimer()
                })
              } else {
                signOut(true)
              }
            })
          }}
          label={'Continue'}
        />
      </div>
    </div>
  )
}

export default SessionTimeoutModal
