/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable security/detect-object-injection */
import React from 'react'
import { Field, useFormikContext } from 'formik'
import cloneDeep from 'lodash/cloneDeep'
import { Box, Typography } from '@mui/material'

interface FilterOptionSelectProps {
  label: string
  name: string
  selectedValues: string[]
  values: string[]
  variant?: 'single' | 'multi'
  /**
   * Optionally define a function to modify the text rendered for each option
   *
   * Runs before the capitalization transformation which is run on all values
   */
  transformValueForDisplay?: (value: string) => string
}

const FilterOptionSelect: React.FC<FilterOptionSelectProps> = ({
  label,
  name,
  selectedValues,
  values,
  variant = 'multi',
  transformValueForDisplay,
}) => {
  const { values: formikValues, setValues, handleChange } = useFormikContext()

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked } = e.target

    const newValues = cloneDeep(formikValues) as any
    newValues[name] = checked ? [value] : []

    setValues(newValues)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem',
      }}
    >
      <Typography color="textPrimary" fontWeight={600} variant="body2">
        {label}
      </Typography>

      <Box
        data-testid="filter-option-select"
        sx={{ display: 'flex', flexWrap: 'wrap', gap: '0.5rem' }}
      >
        {values.map((val, i) => {
          const isSelected = selectedValues.includes(val)
          const valueTransformedForDisplay = transformValueForDisplay
            ? transformValueForDisplay(val)
            : val

          return (
            <Box
              component="label"
              key={`${val}-${i}`}
              htmlFor={`${name}-${val}`}
              sx={(theme) => ({
                display: 'flex',
                flexDirection: 'row',
                backgroundColor: isSelected
                  ? theme.vars.palette.secondary.main
                  : theme.vars.palette.common.white,
                border: `1px solid ${theme.vars.palette.text.primary}`,
                borderRadius: '5px',
                padding: '4px 8px',
                ...theme.applyStyles('dark', {
                  backgroundColor: isSelected
                    ? theme.vars.palette.text.primary
                    : theme.vars.palette.secondary.dark,
                }),
              })}
              // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
              tabIndex={0}
              onKeyDown={(e) => {
                e.stopPropagation()

                if (e.key === 'Enter' || e.key === ' ') {
                  const newValues = cloneDeep(formikValues) as any
                  newValues[name] = isSelected
                    ? selectedValues.filter((v) => v !== val)
                    : [...selectedValues, val]

                  setValues(newValues)
                }
              }}
            >
              <Typography
                sx={(theme) => ({
                  ...theme.applyStyles('dark', {
                    color: isSelected
                      ? theme.vars.palette.secondary.dark
                      : theme.vars.palette.text.primary,
                  }),
                })}
              >
                {valueTransformedForDisplay.charAt(0).toUpperCase() +
                  valueTransformedForDisplay.slice(1)}
              </Typography>
              <Field
                type="checkbox"
                onChange={
                  variant === 'single' ? handleRadioChange : handleChange
                }
                name={name}
                id={`${name}-${val}`}
                value={val}
                hidden={true}
              />
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}

export default FilterOptionSelect
