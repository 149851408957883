import { addDays, format } from 'date-fns'

import {
  FormattedMaturityScoreDataInterface,
  MaturityScoreCoordinates,
} from '../components/Home/Dashboard/charts/MaturityScore/MaturityScore.models'
import { DashboardMaturity } from '../models/Dashboard'

export function formatMaturityData(
  data: DashboardMaturity,
): FormattedMaturityScoreDataInterface[] {
  if (data.customer.length === 1) {
    const dayPlaceHolder: Date = addDays(Date.parse(data.customer[0].date), 2)
    const formattedDayPlaceHolder: string = format(dayPlaceHolder, 'yyyy-MM-dd')
    const customerData: MaturityScoreCoordinates[] = [
      {
        x: data.customer[0].date,
        y: data.customer[0].score,
      },
      {
        x: formattedDayPlaceHolder,
        y: data.customer[0].score,
      },
    ]
    const dwData: MaturityScoreCoordinates[] = [
      {
        x: data.deepwatch[0].date,
        y: data.deepwatch[0].score,
      },
      {
        x: formattedDayPlaceHolder,
        y: data.deepwatch[0].score,
      },
    ]
    const maturityNivoChartData: FormattedMaturityScoreDataInterface[] = [
      { id: 'customer', data: customerData },
      { id: 'deepwatch', data: dwData },
      { id: 'customerTrend', data: [] },
      { id: 'deepwatchTrend', data: [] },
    ]

    return maturityNivoChartData
  }
  const customerData: MaturityScoreCoordinates[] = data.customer
    ? data.customer.map((c) => ({
        x: c.date,
        y: c.score,
      }))
    : []
  const dwData: MaturityScoreCoordinates[] = data.deepwatch
    ? data.deepwatch.map((c) => ({
        x: c.date,
        y: c.score,
      }))
    : []
  const industryData: MaturityScoreCoordinates[] = data.industry
    ? data.industry.map((c) => ({ x: c.date, y: c.score }))
    : []

  const maturityNivoChartData: FormattedMaturityScoreDataInterface[] = [
    { id: 'customer', data: customerData },
    { id: 'deepwatch', data: dwData },
    { id: 'industry', data: industryData },
    { id: 'customerTrend', data: [] },
    { id: 'deepwatchTrend', data: [] },
  ]

  return maturityNivoChartData
}
