import { FieldFunctionOptions } from '@apollo/client'

export const mergeUtil = <T>(
  existing: Array<T>,
  incoming: Array<T>,
  opts: FieldFunctionOptions,
): Array<T> => {
  const offset = opts.args ? opts.args.input.pagination.offset : 0

  const mergedValues = existing ? existing.slice(0) : []

  // iterate over list of incoming and replace existing in-cache array of tickets
  // using offest ensures replacement starts after offset point
  for (let i = 0; i < incoming.length; i++) {
    // eslint-disable-next-line security/detect-object-injection
    mergedValues[offset + i] = incoming[i]
  }

  return mergedValues
}
