import { useContext } from 'react'
import { ApolloError, useMutation } from '@apollo/client'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { Context } from '../../../../App'
import { Input } from '../../../../../design-system'
import { TicketActivityTypeEnum, TicketCommentActivity } from '../../Types'
import { useTicketEditContext } from '../../../../../hooks'
import { updateTicketInCache } from '../../utils'
import { convertTicketActivityToTicketComment } from '../TicketActivity/TicketActivity.utils'
import {
  AddTicketCommentVariables,
  UPDATE_TICKET,
} from '../../../../../graphql/mutations/ticket'

type FormValues = {
  comment: string
}

interface TicketCommentData {
  updateTicket: boolean
}

interface Props {
  ticketId: string
  onSubmit: (pendingActivityItem: TicketCommentActivity) => void
  onCompleted: (pendingActivityItemId: string) => void
  onError: (pendingActivityItemId: string, error: ApolloError) => void
}

const ActivityFormSchema = Yup.object().shape({
  comment: Yup.string().trim().required('Comment is required'),
})

const TicketCommentForm: React.FC<Props> = ({
  ticketId,
  onSubmit,
  onCompleted,
  onError,
}) => {
  const {
    state: {
      user: { id: userId, firstName, lastName, isDWEmployee },
    },
  } = useContext(Context)

  const { isEditable } = useTicketEditContext()

  const [addTicketComment, { loading }] = useMutation<
    TicketCommentData,
    AddTicketCommentVariables
  >(UPDATE_TICKET)

  const initialValues: FormValues = {
    comment: '',
  }

  const handleFormSubmit = async ({ comment }: FormValues) => {
    if (loading) return

    const pendingActivityItem: TicketCommentActivity = {
      createdDate: new Date().toISOString(),
      id: crypto.randomUUID(),
      status: 'pending',
      text: comment,
      type: TicketActivityTypeEnum.Comment,
      userInfo: {
        id: userId,
        firstName,
        lastName,
        type: isDWEmployee ? 'dw' : 'cust',
      },
    }

    try {
      onSubmit(pendingActivityItem)

      const response = await addTicketComment({
        variables: {
          input: {
            ticketId,
            ticketData: {
              comment,
            },
          },
        },
      })

      if (response.data?.updateTicket) {
        updateTicketInCache({
          ticketId,
          newFields: {
            comments: [
              convertTicketActivityToTicketComment(pendingActivityItem),
            ],
          },
        })
        onCompleted(pendingActivityItem.id)
      }

      resetForm()
    } catch (error) {
      onError(pendingActivityItem.id, error as ApolloError)
    }
  }

  const { handleChange, handleSubmit, resetForm, values } = useFormik({
    initialValues,
    onSubmit: handleFormSubmit,
    validationSchema: ActivityFormSchema,
  })

  return (
    <form onSubmit={handleSubmit}>
      <Input
        type="text"
        name="comment"
        labelId="comment"
        placeholder="Type your comment here&hellip;"
        rightIcon="send"
        onRightIconClick={handleSubmit}
        onChange={handleChange}
        value={values.comment}
        disabled={loading || !isEditable}
        isEditable={isEditable}
        hideHelperText
      />
    </form>
  )
}

export default TicketCommentForm
