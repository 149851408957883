import { CSSProperties } from 'react'

import { colors } from '../../theme'

export const selectContainer: CSSProperties = {
  display: 'flex',
  gap: '40px',
  width: '100%',
}

export const selectWrapper: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  width: '100%',
  position: 'relative',
}

export const getInputWrapperOutline = (
  disabled?: boolean,
  open?: boolean,
  isDarkTheme?: boolean,
) => {
  if (disabled) {
    return isDarkTheme ? 'none' : `1px solid ${colors.neutral[100]}`
  }
  if (open) {
    return isDarkTheme
      ? `1px solid ${colors.util.navy[50]}`
      : `2px solid ${colors.neutral[400]}`
  }
  return isDarkTheme
    ? `1px solid ${colors.util.navy[300]}`
    : `1px solid ${colors.neutral[300]}`
}

export const inputWrapper = (
  disabled?: boolean,
  open?: boolean,
  isDarkTheme?: boolean,
): CSSProperties => ({
  display: 'flex',
  justifyContent: 'space-between',
  borderRadius: 5,
  outline: getInputWrapperOutline(disabled, open, isDarkTheme),
  padding: 0,
  width: '100%',
  position: 'relative',
  background: isDarkTheme ? colors.util.navy[500] : '',
  cursor: 'default',
})

export const inputContainer = (
  disabled?: boolean,
  open?: boolean,
  isDarkTheme?: boolean,
): CSSProperties => ({
  fontWeight: 400,
  fontFamily: 'Inter',
  fontSize: 16,
  lineHeight: 1.5,
  color: isDarkTheme ? colors.util.navy[50] : colors.neutral[400],
  background: 'none',
  outline: 'none',
  border: 'none',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  opacity: disabled ? 0.4 : 1,
  width: open ? '' : '100%',
  cursor: 'default',
})

export const getLabelColor = (open?: boolean, isDarkTheme?: boolean) => {
  if (open) {
    return isDarkTheme ? colors.util.navy[50] : colors.neutral[400]
  }
  return isDarkTheme ? colors.util.navy[100] : colors.neutral[300]
}

export const labelStyles = (
  open?: boolean,
  isDarkTheme?: boolean,
): CSSProperties => ({
  color: getLabelColor(open, isDarkTheme),
  fontFamily: 'Inter',
  fontWeight: 600,
  lineHeight: 1.4,
  width: '100%',
  marginBottom: 8,
  display: 'block',
  textAlign: 'left',
})

export const tagInputWrapper: CSSProperties = {
  width: '100%',
  padding: '4px 8px',
}

export const tagStyles = {
  marginRight: '4px',
  marginBottom: '3px',
}
