import { InlineMessageType, Message, Tag } from '../../../../design-system'
import { Detections } from '../../../../models'
import { formatEmptyField } from '../../../../utils'
import { SideSheet } from '../../../SideSheet'
import { DetectionCatalogStatus } from '../../Coverage.utils'
import { formatStatus } from '../DetectionCatalog.utils'

import './DetectionCatalogSideSheet.scss'

interface DetectionSideSheetProps {
  isOpen: boolean
  closeSideSheet: () => void
  focusedDetection: Detections
}

const DetectionCatalogSideSheet = ({
  isOpen,
  closeSideSheet,
  focusedDetection,
}: DetectionSideSheetProps) => {
  const detectionTag = formatStatus(focusedDetection.status)

  const releaseVersion = () => {
    if (
      focusedDetection.releaseVersion !== undefined &&
      focusedDetection.releaseVersion !== ''
    ) {
      return `v${focusedDetection.releaseVersion}`
    } else {
      return '--'
    }
  }

  const sideSheetItemView = (result: string | Array<string>): JSX.Element => {
    if (typeof result === 'string' || result.length === 1) {
      return <div>{result}</div>
    } else {
      return (
        <div>
          <ul>
            {result.map((element, index) => (
              <li key={index}>{element}</li>
            ))}
          </ul>
        </div>
      )
    }
  }

  const formatMitreTacticOrTechtype = (arr: Array<string>): JSX.Element => {
    let result: string | Array<string>
    if (
      !focusedDetection.mitreTactics.length &&
      !focusedDetection.dataTypes.length
    ) {
      result = 'No Mapping'
    } else {
      result = formatEmptyField(arr)
    }
    return sideSheetItemView(result)
  }

  const formatMitreTechnique = (arr: Array<string>): JSX.Element => {
    const result = formatEmptyField(arr)
    return sideSheetItemView(result)
  }

  const detectionMappingErrorMessage = (): string => {
    if (
      !focusedDetection.mitreTactics.length &&
      !focusedDetection.dataTypes.length
    ) {
      if (focusedDetection.useCase.toLowerCase().includes('dwa_infa_')) {
        return 'Infrastructure detections do not have tech type or MITRE mappings.'
      } else if (focusedDetection.useCase.toLowerCase().includes('dwa_cora_')) {
        return 'Correlation detections do not have a tech type or MITRE mapping because they correlate information between multiple detections.'
      } else {
        return ''
      }
    }
    return ''
  }

  let messageText = ''
  //? Ensure status is "Unavailable" before determining whether or not to show a sidesheet message as there are some data discrepancies
  if (focusedDetection.status === DetectionCatalogStatus.Unavailable) {
    //? Strict false comparison as customer DWA version below 2.33.0 can cause some records to be null instead of false
    if (focusedDetection.dataAvailable === false) {
      messageText =
        'This detection is not Available because you are not ingesting the required log source. Please talk to your Deepwatch experts about ingesting the needed log source.'
    }
    if (focusedDetection.availableInVersion === false) {
      messageText =
        'This detection is not Available because of your DWA version. Please talk to your Deepwatch experts to upgrade to the latest version.'
    }
  }

  return (
    <SideSheet
      title="Detection Details"
      open={isOpen}
      closeSideSheet={closeSideSheet}
    >
      <div
        id="detections-catalog-sidesheet"
        data-testid="detections-catalog-sidesheet-test-id"
        style={{ padding: '0px' }}
      >
        {messageText && (
          <Message
            messageType={InlineMessageType.Info}
            dismissible={false}
            title={'Detection unavailable'}
            text={messageText}
            style={{ marginBottom: '30px' }}
          />
        )}
        <div className="detection-description-info">
          <div className="content">
            {formatEmptyField(focusedDetection.title)}
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 8,
              paddingBottom: 10,
            }}
          >
            <Tag variant="secondary" status={detectionTag.status} />
          </div>
          <div className="description">
            {formatEmptyField(focusedDetection.description)}
          </div>
        </div>
        <div className="grid-two">
          <div className="item">
            <div>MITRE Tactic</div>
            {formatMitreTacticOrTechtype(focusedDetection.mitreTactics)}
          </div>

          <div className="item">
            <div>Technology type</div>
            {formatMitreTacticOrTechtype(focusedDetection.dataTypes)}
          </div>

          <div className="item">
            <div>MITRE Technique</div>
            {formatMitreTechnique(focusedDetection.mitreTechniques)}
          </div>

          <div className="item">
            <div>Detection</div>
            <div>{formatEmptyField(focusedDetection.useCase)}</div>
          </div>

          <div className="item">
            <div>Min DWA version</div>
            <div>{releaseVersion()}</div>
          </div>
        </div>
        {detectionMappingErrorMessage().length > 0 && (
          <Message
            messageType={InlineMessageType.BasicInline}
            dismissible={false}
            title={'Detection note'}
            text={detectionMappingErrorMessage()}
            style={{ marginBottom: '30px' }}
          />
        )}
      </div>
    </SideSheet>
  )
}

export default DetectionCatalogSideSheet
