import { gql } from '@apollo/client'

export interface DeleteReportData {
  deleteReport: boolean
}

export interface GenerateReportData {
  createReport: Report
}

export interface DeleteReportVariables {
  input: {
    reportId: number
  }
  selectedCustomer: string | null
}

export interface GenerateReportVariables {
  input: {
    reportId: number
    reportName: string
    startDate: string
    endDate: string
  }
  selectedCustomer: string | null
}

export const DELETE_REPORT = gql`
  mutation DeleteReport($input: DeleteReportInput!, $selectedCustomer: String) {
    deleteReport(input: $input, selectedCustomer: $selectedCustomer)
  }
`

export const GENERATE_REPORT = gql`
  mutation GenerateReport($input: ReportInput!, $selectedCustomer: String) {
    createReport(input: $input, selectedCustomer: $selectedCustomer) {
      id
      createdAt
      endDate
      numberDownloadAttempt
      name
      startDate
      status
      userEmail
      userName
      reportData {
        id
        title
        metric
      }
      errors {
        id
        title
        messages
      }
    }
  }
`
