import { Button, Typography, colors } from '../../../design-system'
import errorIcon from '../../../assets/general-error-icon.svg'

import '../App.scss'

interface Props {
  style?: React.CSSProperties
}

export const ErrorFallback = ({ style }: Props) => {
  const refreshClick = () => {
    window.location.reload()
  }

  return (
    <div className="container" data-testid="errorfallback">
      <div id="main-error">
        <div id="error-container" style={style}>
          <img src={errorIcon} alt="Error" id="error-icon" className="image" />
          <div id="error-message">
            <Typography size={22} color={colors.util.one.lighter}>
              Something went wrong.
            </Typography>
            <Typography size={16} color={colors.util.one.light}>
              An error has occurred, check your connection then reload the page.
            </Typography>
            <Typography size={16} color={colors.util.one.light}>
              If the problem persists please let us know
            </Typography>
          </div>
          <Button label="Reload Page" onClick={refreshClick} />
        </div>
      </div>
    </div>
  )
}
