import { useMemo, useEffect, RefObject } from 'react'

export function setRef<T>(
  ref:
    | React.MutableRefObject<T | null>
    | ((instance: T | null) => void)
    | null
    | undefined,
  value: T | null,
): void {
  if (typeof ref === 'function') {
    ref(value)
  } else if (ref) {
    ref.current = value
  }
}

export function useForkRef<InstanceA, InstanceB>(
  refA: React.Ref<InstanceA> | null | undefined,
  refB: React.Ref<InstanceB> | null | undefined,
): React.Ref<InstanceA & InstanceB> | null {
  return useMemo(() => {
    if (refA === null && refB === null) {
      return null
    }
    return (refVal) => {
      setRef(refA, refVal)
      setRef(refB, refVal)
    }
  }, [refA, refB])
}

export const useRefOnScreen = (
  observerTarget: RefObject<HTMLElement | undefined>,
  callback?: VoidFunction,
  options: IntersectionObserverInit = { threshold: 0.5 },
) => {
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        callback?.()
      }
    }, options)

    const target = observerTarget.current
    if (observerTarget.current) {
      observer.observe(observerTarget.current)
    }

    return () => {
      target && observer.unobserve(target)
    }
  }, [callback, observerTarget, options])
}
