import { useContext, useEffect, useRef, useState } from 'react'
import { useMutation } from '@apollo/client'
import classNames from 'classnames'
import { Field, Formik, Form } from 'formik'
import { debounce } from 'throttle-debounce'
import * as Yup from 'yup'

import {
  TicketResolutionFormValues,
  TicketResolutionFormProps,
} from '../../Types'
import { ProgressButtonState } from '../../../../../design-system/interfaces/ProgressButton'
import { deployToastMessage } from '../../../../../utils'
import { Context } from '../../../../App'
import {
  colors,
  Button,
  ProgressButton,
  ListItemType,
  Select,
  Typography,
  Message,
  InlineMessageType,
} from '../../../../../design-system'
import { updateTicketInCache } from '../../utils'
import {
  UPDATE_TICKET,
  UpdateTicketVariables,
} from '../../../../../graphql/mutations/ticket'

import './TicketResolutionForm.scss'

const TicketResolutionFormSchema = Yup.object().shape({
  resolutionCode: Yup.string().required('Please select the resolution code'),
  resolutionNotes: Yup.string()
    .trim()
    .required('Please enter resolution notes'),
})

export const TicketResolutionForm: React.FC<TicketResolutionFormProps> = ({
  resolutionCodeEnum,
  data,
  inModal = true,
  closeModal,
  onChange,
  sysId,
  ticketNumber,
  draftTicketFields,
  resetDraftTicketFields,
}) => {
  const [progressButtonState, setProgressButtonState] =
    useState<ProgressButtonState>(ProgressButtonState.DEFAULT)

  const { dispatch } = useContext(Context)

  const isMounted = useRef<boolean | null>(null)

  const [
    updateTicket,
    { loading: isSubmissionLoading, error: submissionError },
  ] = useMutation<{ updateTicket: boolean }, UpdateTicketVariables>(
    UPDATE_TICKET,
    {
      onCompleted: () => {
        if (isMounted.current) {
          // Only do the following if component is still mounted when mutation completes

          setProgressButtonState(ProgressButtonState.SUCCESS)

          setTimeout(() => {
            closeModal?.()
          }, 2000)
        }

        updateTicketInCache({
          ticketId: sysId,
          newFields: draftTicketFields || {},
        })
        resetDraftTicketFields?.()

        setTimeout(() => {
          deployToastMessage(
            {
              id: crypto.randomUUID(),
              title: 'Ticket resolved',
              text: `${ticketNumber} has been saved successfully!`,
              messageType: InlineMessageType.SuccessInline,
              secondsToExpire: 3000,
              dismissible: false,
            },
            dispatch,
          )
        }, 3000)
      },
    },
  )

  useEffect(() => {
    isMounted.current = true

    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    let ignore = false
    let timeoutId: NodeJS.Timeout

    if (submissionError && !ignore) {
      setProgressButtonState(ProgressButtonState.ERROR)

      timeoutId = setTimeout(() => {
        setProgressButtonState(ProgressButtonState.DEFAULT)
      }, 2000)
    }

    return () => {
      ignore = true
      clearTimeout(timeoutId)
    }
  }, [submissionError])

  const resolutionCodeOptions = resolutionCodeEnum.map(
    ({ label, numberValue }) => ({
      id: numberValue,
      name: label,
    }),
  )

  const selectedOption = resolutionCodeOptions.find(
    (option) => option.name === data?.resolutionCode,
  )

  const initialValues: TicketResolutionFormValues = {
    resolutionCode: data?.resolutionCode ?? '',
    resolutionNotes: data?.resolutionNotes ?? '',
  }

  const debouncedTextareaChange = debounce(
    200,
    (resolutionNotes: string) =>
      onChange?.({
        resolutionNotes,
      }),
  )

  const notesFieldId = `resolutionNotes-${inModal ? 'in-modal' : 'on-page'}`

  const handleSubmit = async (values: TicketResolutionFormValues) => {
    // We don't want to submit the form if it's not in the modal
    if (!inModal) return

    try {
      const ticketData = {
        ...draftTicketFields,
        ...values,
        state: 'Resolved',
      }

      await updateTicket({
        variables: {
          input: {
            ticketId: sysId,
            ticketData,
          },
        },
      })

      if (draftTicketFields) {
        updateTicketInCache({
          ticketId: sysId,
          newFields: ticketData,
        })
      }
      /**
       * Note: Try/catch block necessary for when Apollo throws errors.
       * Catch block is required with try block, but empty since
       * `useMutation` hook handles error.
       */
      // eslint-disable-next-line no-empty
    } catch {}
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={TicketResolutionFormSchema}
    >
      {({
        values,
        errors,
        handleChange,
        setFieldValue,
        touched,
        submitForm,
      }) => (
        <Form
          className={classNames('ticket-resolution-form', {
            'in-modal': inModal,
          })}
        >
          <Select
            label={'Resolution code'}
            placeholder={'--'}
            options={resolutionCodeOptions}
            selected={selectedOption}
            border={true}
            labelStyleProps={{ fontSize: '14px', marginBottom: '6px' }}
            onChange={({ name: resolutionCode }: ListItemType) => {
              setFieldValue('resolutionCode', resolutionCode)
              onChange?.({
                resolutionCode,
              })
            }}
            error={!!errors.resolutionCode}
          />
          {errors.resolutionCode && (
            <Typography variant="text12" color={colors.util.two.light}>
              {errors.resolutionCode}
            </Typography>
          )}
          <label
            htmlFor="resolutionNotes"
            className={classNames('field-label', {
              error: errors.resolutionNotes && touched.resolutionNotes,
            })}
          >
            Resolution notes
          </label>
          <Field
            as="textarea"
            value={values.resolutionNotes}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
              handleChange(e)
              debouncedTextareaChange(e.target.value.trim() || null)
            }}
            name="resolutionNotes"
            id={notesFieldId}
            className={classNames('field-textarea', {
              error: errors.resolutionNotes && touched.resolutionNotes,
            })}
            data-testid="resolutionNotes"
          />
          {errors.resolutionNotes && touched.resolutionNotes && (
            <Typography variant="text12" color={colors.util.two.light}>
              {errors.resolutionNotes}
            </Typography>
          )}
          {inModal && (
            <>
              {submissionError && (
                <div className="submission-error-message">
                  <Message
                    messageType={InlineMessageType.ErrorInline}
                    title="Error saving"
                    text={submissionError.message}
                    dismissible={false}
                  />
                </div>
              )}
              <footer className="footer" data-testid="resolutionFooter">
                <Button
                  onClick={() => {
                    closeModal?.()
                  }}
                  variant="secondary"
                  disabled={isSubmissionLoading}
                >
                  Cancel
                </Button>
                <ProgressButton
                  defaultText="Save & resolve case"
                  successText="Resolved"
                  disabled={!values.resolutionCode || !values.resolutionNotes}
                  state={progressButtonState}
                  onClick={submitForm}
                />
              </footer>
            </>
          )}
        </Form>
      )}
    </Formik>
  )
}
