import { ApolloError } from '@apollo/client'
import { Box, Typography, useTheme } from '@mui/material'

import { handleKeyboardAction } from '@utils/handleKeyboardAction'
import { EnvironmentHealthBan as EnvironmentHealthBanData } from '@queries/environmentHealth'
import { useCustomer } from '@hooks/useCustomer'
import BanLayoutCommon from '@common/Ban/BanLayoutCommon'
import Ban from '@common/Ban'

import { useEnvironmentHealthBanSidesheetContext } from '../Sidesheets/EnvironmentHealthBanSidesheet/EnvironmentHealthBanSidesheetContext'
import { useEnvironmentHealthChartDetailsSideSheet } from '../Sidesheets/EnvironmentHealthChartDetailsSideSheet/EnvironmentHealthChartDetailsSideSheetContext'

interface EnvHealthBansDataProps {
  data: EnvironmentHealthBanData | null
  loading: boolean
  error?: ApolloError | null
}

const formatOverages = (daysOverLicensePeriod?: number): JSX.Element | null => {
  switch (daysOverLicensePeriod) {
    case undefined:
    case 0:
      return null
    case 1:
      return (
        <Typography
          sx={{ alignContent: 'center' }}
          color="warning"
          variant="body2"
        >
          1 overage
        </Typography>
      )
    default:
      return (
        <Typography
          sx={{ alignContent: 'center' }}
          color="warning"
          variant="body2"
        >
          {`${daysOverLicensePeriod} overages`}
        </Typography>
      )
  }
}

const EnvironmentHealthBans = ({
  data,
  loading,
  error,
}: EnvHealthBansDataProps): JSX.Element => {
  const {
    customer: { splunkVersion, splunkLink },
  } = useCustomer()

  const { setIsOpen, isOpen } = useEnvironmentHealthBanSidesheetContext()

  const theme = useTheme()
  /**
   * This context is used within this component (rather than the useEnvironmentHealthBanSidesheetContext)
   * because one context must be a parent of the other. I chose to put the <EnvironmentHealthBanSidesheetContextProvider />
   * as a parent of the <EnvironmentHealthChartDetailsSideSheetContextProvider /> in the Overview component,
   * because this is the only component which needs to open the ban sidesheet (whereas the chart details sidesheet is opened in multiple components).
   */
  const { setIsOpen: setIsChartDetailsSidesheetOpen } =
    useEnvironmentHealthChartDetailsSideSheet()

  const handleToggleSidesheet = () => {
    const newIsOpen = !isOpen
    setIsOpen(newIsOpen)
    if (newIsOpen) {
      // Close the chart details sidesheet when the ban sidesheet is opened
      setIsChartDetailsSidesheetOpen(false)
    }
  }

  const handleSiemVersionBanClick = () => {
    if (splunkVersion && splunkLink) {
      window.open(`${splunkLink}`, '_blank')
    }
  }

  return (
    <Box sx={{ display: 'flex', gap: '1rem' }}>
      <Ban
        data-testid="envHealth-ban-avg-dw-license-usage"
        iconVariant="barcodeOutline"
        onClick={handleToggleSidesheet}
        onKeyDown={(e) => handleKeyboardAction(e, handleToggleSidesheet)}
      >
        <BanLayoutCommon
          error={error != null}
          loading={loading}
          title="AVERAGE LICENSE USAGE"
          value={
            data?.avgLicenseUsage
              ? `${Math.round(data.avgLicenseUsage)} GB`
              : 'Not applicable'
          }
        >
          {formatOverages(data?.daysOverLicensePeriod)}
        </BanLayoutCommon>
      </Ban>
      <Ban
        data-testid="envHealth-ban-siem-version"
        iconVariant="radioButtonOnOutline"
        onClick={handleSiemVersionBanClick}
        onKeyDown={(e) => handleKeyboardAction(e, handleSiemVersionBanClick)}
        sx={
          !splunkVersion
            ? {
                cursor: 'initial',
                '&:hover': { backgroundColor: theme.palette.secondary.main },
              }
            : {}
        }
      >
        <BanLayoutCommon
          caption={
            !splunkVersion
              ? "Since your Splunk deployment model's host naming convention deviates from our naming convention, we are unable to fetch your SIEM version."
              : ''
          }
          error={error != null}
          loading={loading}
          title="SIEM VERSION"
          value={!splunkVersion ? 'N/A' : splunkVersion}
          externalLink={!splunkVersion ? false : true}
        />
      </Ban>
    </Box>
  )
}

export default EnvironmentHealthBans
