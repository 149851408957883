import { Tag, Typography, colors } from '../../../../../design-system'
import {
  MitreCoverageTechnique,
  MitreCoverageCustomer,
} from '../../../../../models/DetectionCoverage'

export const IconForCard = (
  isOpen: boolean,
  selectedTechnique?: string,
  techniqueIndex?: string,
) => {
  if (selectedTechnique === techniqueIndex) {
    return isOpen === true ? 'chevronUp' : 'chevronDown'
  }
  return 'chevronDown'
}

export const IconForSubTechTable = ({ coverage }): JSX.Element => {
  if (coverage === 'covered') {
    return (
      <Tag
        text="COVERED"
        variant="small"
        styles={{ backgroundColor: '#99FFCC' }}
      />
    )
  }
  if (coverage === 'not-covered') {
    return (
      <Tag
        text="AVAILABLE"
        variant="small"
        styles={{ backgroundColor: '#EBEBEB' }}
      />
    )
  }
  return (
    <Tag
      text="UNAVAILABLE"
      variant="small"
      styles={{ backgroundColor: colors.util.navy[100] }}
    />
  )
}

export const getFormattedTableData = (
  technique: MitreCoverageTechnique,
  customerTechnique: MitreCoverageCustomer | undefined,
) => {
  // eslint-disable-next-line security/detect-object-injection
  return technique.subtechniques.map((subtechnique, subtechniqueIndex) => {
    const customerTechniqueSubTechniqueEnabledUseCaseCount =
      customerTechnique?.subtechniques.find(
        (customerSubtechnique) =>
          customerSubtechnique.mitreSubtechniqueId ===
          subtechnique.mitreSubtechniqueId,
      )?.totalEnabledUseCaseCount
    let customerSubtechniqueCoverage = 'not-available'
    if (subtechnique.totalUseCaseCount > 0) {
      customerSubtechniqueCoverage = 'not-covered'
      if (customerTechniqueSubTechniqueEnabledUseCaseCount) {
        customerSubtechniqueCoverage = 'covered'
      }
    }

    return {
      id: subtechnique.mitreSubtechniqueId,
      name: {
        sortValue: subtechnique.mitreSubtechniqueName,
        displayValue: (
          <Typography size={13} component="span">
            {subtechnique.mitreSubtechniqueName}
          </Typography>
        ),
      },
      coverage: {
        sortValue: customerSubtechniqueCoverage,
        displayValue: (
          <IconForSubTechTable coverage={customerSubtechniqueCoverage} />
        ),
      },
      useCases: {
        sortValue: 0,
        displayValue: (
          <Typography size={13} component="span">
            {`${
              customerTechniqueSubTechniqueEnabledUseCaseCount
                ? customerTechniqueSubTechniqueEnabledUseCaseCount
                : 0
            }/${subtechnique.totalUseCaseCount}`}
          </Typography>
        ),
      },
      subtechniqueIndex,
    }
  })
}
