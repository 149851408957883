import { useFormik } from 'formik'
import * as Yup from 'yup'

import {
  Button,
  ChipInput,
  Icon,
  Input,
  ListItemSelectType,
  ListItemType,
  Toggle,
  Typography,
} from '../../../../design-system'
import { ModalTitles } from '../UserManagementSettings'
import { OktaGroup, User } from '../../../../models'
import {
  filterAdminGroup,
  mapToListItems,
} from '../UserManagementSettings.util'

export interface AddEditUserFormValues {
  firstName: string
  lastName: string
  email: string
  title: string
  isAdmin: boolean
  groupIDs: string[]
}

interface AddEditUserFormProps {
  customerGroups: OktaGroup[]
  initialGroups: ListItemSelectType[]
  isEditingSelf: boolean
  isFederatedCustomer: boolean
  isLastAdmin: boolean
  modalState: ModalTitles
  onCancelButtonClick: VoidFunction
  onSubmit: (values: AddEditUserFormValues) => void
  user?: User
}

const AddEditUserForm: React.FC<AddEditUserFormProps> = ({
  customerGroups,
  initialGroups,
  isEditingSelf,
  isFederatedCustomer,
  isLastAdmin,
  modalState,
  onCancelButtonClick,
  onSubmit,
  user,
}) => {
  const handleGroupSelect = (selected: ListItemType[]): void => {
    const selectedGroupIds = selected.map(
      (selectedGroup) =>
        appGroupOptions.find((appGroup) => selectedGroup.name === appGroup.name)
          ?.id,
    ) as string[]

    setFieldValue('groupIDs', selectedGroupIds)
  }

  const AddEditUserSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(1, 'First name must be between 1 and 256 characters.')
      .max(256, 'First name must be between 1 and 256 characters.')
      .required('First name is required.'),
    lastName: Yup.string()
      .min(1, 'Last name must be between 1 and 256 characters.')
      .max(256, 'Last name must be between 1 and 256 characters.')
      .required('Last name is required.'),
    email: Yup.string()
      .email('Enter a valid email address.')
      .required('Email is required.'),
    title: Yup.string()
      .min(1, 'Title must be between 1 and 256 characters.')
      .max(256, 'Title must be between 1 and 256 characters.'),
  })

  const initialValues: AddEditUserFormValues = {
    firstName: user?.firstName ? user.firstName : '',
    lastName: user?.lastName ? user.lastName : '',
    email: user?.email ? user.email : '',
    title: user?.title ? user.title : '',
    isAdmin: user?.isAdmin ? user.isAdmin : false,
    groupIDs: user?.groupIDs ? user.groupIDs : [],
  }

  const {
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    dirty,
    errors,
    isValid,
    touched,
    values,
  } = useFormik({
    initialValues: initialValues,
    onSubmit: (values) => onSubmit(values),
    validationSchema: AddEditUserSchema,
  })

  const appGroupOptions = mapToListItems(customerGroups)

  return (
    <div data-testid="user-form">
      <Input
        addErrorSpacing
        required
        labelId="firstName"
        styles={{ marginBottom: '12px', width: '100%' }}
        error={errors.firstName !== undefined && touched.firstName}
        errorText={errors.firstName}
        label={'First Name'}
        placeholder={'Enter First Name'}
        value={values.firstName}
        name="firstName"
        type="text"
        onBlur={handleBlur}
        onChange={handleChange}
        disabled={isFederatedCustomer}
      />

      <Input
        addErrorSpacing
        required
        labelId="lastName"
        styles={{ marginBottom: '12px' }}
        error={errors.lastName !== undefined && touched.lastName}
        errorText={errors.lastName}
        label={'Last Name'}
        placeholder={'Enter Last Name'}
        value={values.lastName}
        name="lastName"
        type="text"
        onBlur={handleBlur}
        onChange={handleChange}
        disabled={isFederatedCustomer}
      />

      <Input
        addErrorSpacing
        required
        labelId="email"
        styles={{ marginBottom: '12px' }}
        disabled={modalState === ModalTitles.ADD ? false : true}
        error={errors.email !== undefined && touched.email}
        errorText={errors.email}
        label={'Email'}
        placeholder={'Enter Email Address'}
        value={values.email}
        name="email"
        type="text"
        onBlur={handleBlur}
        onChange={handleChange}
      />

      <Input
        addErrorSpacing
        required
        labelId="title"
        styles={{ marginBottom: '12px' }}
        label={'Job Title (optional)'}
        placeholder={'Enter Job Title'}
        error={errors.title !== undefined && touched.title}
        errorText={errors.title}
        value={values.title}
        name="title"
        type="text"
        onBlur={handleBlur}
        onChange={handleChange}
        disabled={isFederatedCustomer}
      />

      <Typography component="div" size={18}>
        Permissions
      </Typography>

      <div className="permssions-container" data-testid="permissions-container">
        <div className="permissions-admin-content">
          <div className="permissions-admin-row">
            <Typography component="span" size={16}>
              Administrator
            </Typography>

            <div
              className="icon-container"
              data-tooltip="Administrators manage user application access and can promote other admins; these permission can change at any time"
            >
              <Icon
                variant="informationCircleOutline"
                style={{ paddingLeft: 4 }}
              />
            </div>
          </div>

          <Toggle
            label=""
            disable={isLastAdmin && isEditingSelf}
            check={values.isAdmin}
            name="User Privilege"
            onClick={(privilege) => setFieldValue('isAdmin', privilege)}
          />
        </div>

        {isEditingSelf && isLastAdmin && (
          <Typography
            component="span"
            size={12}
            styles={{ position: 'relative', bottom: '14px' }}
          >
            You cannot disable this user&apos;s admin permissions because there
            is only one admin
          </Typography>
        )}
      </div>

      <div className="application-groups" data-testid="application-groups">
        <ChipInput
          label="Application Groups"
          options={filterAdminGroup(appGroupOptions)}
          selected={filterAdminGroup(initialGroups)}
          placeholder="Select Groups"
          onSelect={handleGroupSelect}
        />
      </div>

      <div className="button-container">
        <Button variant="secondary" onClick={onCancelButtonClick}>
          Cancel
        </Button>
        <Button
          disabled={!isValid || !dirty}
          buttonStyles={{ marginLeft: 8 }}
          onClick={() => handleSubmit()}
        >
          {modalState === ModalTitles.ADD ? 'Add' : 'Save'}
        </Button>
      </div>
    </div>
  )
}

export default AddEditUserForm
