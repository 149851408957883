import { Button, Typography } from '../../../design-system'

import './ModalHeader.scss'

interface ModalHeaderProps {
  closeButtonVisible?: boolean
  closeModal: VoidFunction
  subtitle?: string
  title: string
}

const ModalHeader: React.FC<ModalHeaderProps> = ({
  closeButtonVisible = true,
  closeModal,
  subtitle,
  title,
}) => {
  const handleCloseButtonClick = () => {
    closeModal()
  }

  return (
    <div className="modal-header" data-testid="modal-header">
      <Typography variant="text9">{title}</Typography>

      <div className="subtitle-and-close">
        {subtitle && <Typography variant="text12">{subtitle}</Typography>}

        {closeButtonVisible && (
          <Button
            variant="navigational-icon"
            iconProps={{ variant: 'close' }}
            onClick={handleCloseButtonClick}
          />
        )}
      </div>
    </div>
  )
}

export default ModalHeader
