/* eslint-disable security/detect-object-injection */
import { alpha, Box, Chip, darken, Tooltip, useTheme } from '@mui/material'

import { getDetectionStatusTooltipContent } from './detectionCatalogStatusTagUtils'

export type DetectionStatus =
  | 'pending review'
  | 'enabled'
  | 'blocked'
  | 'trial mode'
  | 'eligible'
  | 'not applicable'
  | 'available'
  | 'unavailable'
  | 'update required'

export type DeprecatedDetectionStatus = 'enabled' | 'available' | 'unavailable'

interface DetectionCatalogStatusTagProps {
  status: DetectionStatus
}

export const DetectionCatalogStatusTag: React.FC<
  DetectionCatalogStatusTagProps
> = ({ status }) => {
  const theme = useTheme()

  if (!status) {
    // If `status` is not defined due to inaccurate types, we don't want to crash the app when calling `status.toUpperCase()`
    return null
  }
  const text = status.toUpperCase()
  const tooltip = getDetectionStatusTooltipContent(status)

  const getDetectionStatusColor = (status: DetectionStatus) => {
    switch (status) {
      case 'enabled':
        return theme.palette.mode === 'light'
          ? theme.palette.success.light
          : theme.palette.success.main
      case 'eligible':
        return theme.palette.info.main
      case 'available':
        return theme.palette.info.light
      case 'trial mode':
        return darken(theme.palette.severity.medium!, 0.1)
      case 'blocked':
        return theme.palette.warning.main
      default:
        return theme.palette.card.main
    }
  }

  const textColor = getDetectionStatusColor(status)

  const tagContent = (
    <Chip
      label={text}
      variant="outlined"
      sx={{
        '&.MuiChip-outlined': {
          color: textColor,
          borderColor: alpha(textColor, 0.54),
        },
      }}
    />
  )

  return tooltip ? (
    <Tooltip
      data-testid="tooltip-content"
      title={tooltip}
      followCursor
      placement="top-start"
    >
      <Box>{tagContent}</Box>
    </Tooltip>
  ) : (
    tagContent
  )
}
