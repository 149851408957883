import React, { MouseEvent, useEffect, useState } from 'react'
import classNames from 'classnames'

import {
  ProgressButtonProps,
  ProgressButtonState,
} from '../../interfaces/ProgressButton'
import { Loader } from '../Loader'
import { Button } from '../Button'
import { useTheme } from '../../hooks'
import { colors } from '../../theme'
import { Icon } from '../Icon'

import './ProgressButton.scss'

const ProgressButton: React.FC<ProgressButtonProps> = ({
  defaultText,
  state,
  disabled = false,
  progressText = 'Saving',
  errorText = 'Error',
  successText = 'Success',
  onClick,
}) => {
  const [buttonState, setButtonState] = useState<ProgressButtonState>(state)
  const isDarkTheme = useTheme('dark')
  const iconColor = isDarkTheme ? colors.util.navy[600] : colors.common.white

  useEffect(() => {
    setButtonState(state)
  }, [state])

  const hasDefaultState = buttonState === ProgressButtonState.DEFAULT
  const hasInProgressState = buttonState === ProgressButtonState.IN_PROGRESS
  const hasErrorState = buttonState === ProgressButtonState.ERROR
  const hasSuccessState = buttonState === ProgressButtonState.SUCCESS

  const renderButtonContent = () => {
    switch (buttonState) {
      case ProgressButtonState.DEFAULT: {
        return <>{defaultText}</>
      }
      case ProgressButtonState.IN_PROGRESS: {
        return (
          <>
            {progressText}
            <Loader size={24} strokeWidth={1} color={iconColor} />
          </>
        )
      }
      case ProgressButtonState.ERROR: {
        return (
          <>
            {errorText}
            <Icon variant="closeCircle" />
          </>
        )
      }
      case ProgressButtonState.SUCCESS: {
        return (
          <>
            {successText}
            <Icon variant="checkmarkCircle" />
          </>
        )
      }
      default:
        throw new Error('Invalid progress button state')
    }
  }

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    setButtonState(ProgressButtonState.IN_PROGRESS)
    onClick?.(e)
  }

  const buttonClasses = classNames('progress-button', {
    [ProgressButtonState.IN_PROGRESS]: hasInProgressState,
    [ProgressButtonState.ERROR]: hasErrorState,
    [ProgressButtonState.SUCCESS]: hasSuccessState,
  })

  return (
    <Button
      onClick={handleClick}
      customClass={buttonClasses}
      disabled={disabled || !hasDefaultState}
    >
      {renderButtonContent()}
    </Button>
  )
}

export default ProgressButton
