import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { useTheme } from '@mui/material'

import {
  FirewallMetrics,
  FirewallVariables,
  FIREWALL_METRICS,
  OPEN_TICKETS_BAN,
  TicketServiceOffering,
} from '@queries/firewall'
import { Paths } from '@components/App/Types'
import Ban from '@common/Ban'
import BanLayoutCommon from '@common/Ban/BanLayoutCommon'
import LicenseTotal from '@common/LicenseTotal'
import { formatNumberWithCommas } from '@utils/Common'

const FirewallBans: React.FC = () => {
  const [searchParams] = useSearchParams()
  const selectedCustomer = searchParams.get('customer')
  const navigate = useNavigate()
  const { search: currentCustomer } = useLocation()
  const theme = useTheme()

  const {
    data: {
      firewallMetrics: {
        totalAssets,
        totalMonitoredAssets,
        totalAssetRevisions,
      },
    } = {
      firewallMetrics: {
        totalAssets: undefined,
        totalMonitoredAssets: undefined,
        totalAssetRevisions: undefined,
      },
    },
    error,
    loading,
  } = useQuery<FirewallMetrics, Pick<FirewallVariables, 'selectedCustomer'>>(
    FIREWALL_METRICS,
    {
      variables: {
        selectedCustomer: selectedCustomer,
      },
    },
  )

  const {
    data: { openTicketsBan } = {
      openTicketsBan: { module: '', total: undefined },
    },
    error: openTicketsError,
    loading: openTicketsLoading,
  } = useQuery(OPEN_TICKETS_BAN, {
    variables: {
      selectedCustomer,
      input: {
        serviceOffering: TicketServiceOffering.FW,
      },
    },
  })

  const notEnoughMonitoredAssets =
    totalMonitoredAssets && totalAssets && totalMonitoredAssets < totalAssets

  const onCurrentOpenTicketsClick = () => {
    const filters = JSON.stringify({
      module: ['FW'],
    })

    const value = currentCustomer
      ? `${Paths.TICKET_LIBRARY}${currentCustomer}&selectedFilters=${filters}`
      : `${Paths.TICKET_LIBRARY}?selectedFilters=${filters}`

    return navigate(value)
  }

  return (
    <>
      <Ban iconVariant={'peopleOutline'} data-testid="monitored-assets-ban">
        <BanLayoutCommon
          caption='"Represents the number of assets in Firemon vs. the number of devices on your Deepwatch contract."'
          error={error != null}
          loading={loading}
          title="MONITORED FIREWALL DEVICES"
          value={
            <LicenseTotal
              current={totalMonitoredAssets}
              total={totalAssets}
              customColor={
                notEnoughMonitoredAssets
                  ? theme.vars.palette.error.light
                  : theme.vars.palette.common.white
              }
            />
          }
        />
      </Ban>

      <Ban
        iconVariant="ticketOutline"
        data-testid="open-tickets-ban"
        onClick={onCurrentOpenTicketsClick}
      >
        <BanLayoutCommon
          error={openTicketsError != null}
          loading={openTicketsLoading}
          title="CURRENT OPEN TICKETS"
          value={openTicketsBan.total ?? 0}
        />
      </Ban>

      <Ban iconVariant={'listOutline'} data-testid="asset-revisions-ban">
        <BanLayoutCommon
          error={error != null}
          loading={loading}
          title="ASSET REVISIONS"
          value={
            totalAssetRevisions
              ? formatNumberWithCommas(totalAssetRevisions)
              : 'Not applicable'
          }
        />
      </Ban>
    </>
  )
}

export default FirewallBans
