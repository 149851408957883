import { Box, SxProps, Theme, Typography, useTheme } from '@mui/material'

import { IconVariant } from '@common/Icon/Icons'
import Icon from '@common/Icon'

export enum InlineMessageType {
  INFORMATIONAL,
  ERROR,
  SUCCESS,
  WARNING,
}

type InlineMessageTypeDict<T extends string | symbol | number, U> = {
  [K in T]: U
}

interface InlineMessageMappingItem {
  color: string
  variant: IconVariant
}

export interface InlineMessageProps {
  message: string | string[]
  sx?: SxProps<Theme>
  title: string
  variant: InlineMessageType
}

const InlineMessage: React.FC<InlineMessageProps> = ({
  message,
  sx,
  title,
  variant,
}) => {
  const theme = useTheme()
  const TypeToIconMapping: InlineMessageTypeDict<
    InlineMessageType,
    InlineMessageMappingItem
  > = {
    [InlineMessageType.ERROR]: {
      color: theme.vars.palette.error.light,
      variant: 'alertCircle',
    },
    [InlineMessageType.INFORMATIONAL]: {
      color: theme.vars.palette.primary.light,
      variant: 'informationCircle',
    },
    [InlineMessageType.SUCCESS]: {
      color: theme.vars.palette.success.main,
      variant: 'checkmarkCircle',
    },
    [InlineMessageType.WARNING]: {
      color: theme.vars.palette.warning.main,
      variant: 'warning',
    },
  }

  const textColor = {
    color: theme.vars.palette.text.primary,
    ...theme.applyStyles('dark', {
      backgroundColor: theme.vars.palette.secondary.darker,
      color: theme.vars.palette.text.secondary,
      boxShadow: 'initial',
    }),
  }

  return (
    <Box
      sx={[
        (theme) => ({
          backgroundColor: theme.vars.palette.common.white,
          borderStyle: 'solid',
          borderWidth: '1px',
          borderRadius: '5px',
          display: 'flex',
          flexFlow: 'row wrap',
          padding: '0.5rem',
          mb: '1rem',
          borderColor: TypeToIconMapping[variant].color,
          ...textColor,
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '0.5rem',
          paddingBottom: '0.25rem',
          width: '100%',
        }}
        data-testid="inline-message-title"
      >
        <Icon
          size={18}
          sx={{ color: TypeToIconMapping[variant].color }}
          variant={TypeToIconMapping[variant].variant}
        />
        <Typography sx={{ ...textColor }} fontWeight={600} variant="caption">
          {title}
        </Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          paddingLeft: 'calc(18px + 0.5rem)', // icon is 18px, gap is 0.5rem
          width: '100%',
        }}
        data-testid="inline-message-content"
      >
        <Typography sx={{ ...textColor }} variant="caption">
          {message}
        </Typography>
      </Box>
    </Box>
  )
}

export default InlineMessage
