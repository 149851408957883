import {
  ColumnFiltersState,
  createColumnHelper,
  getCoreRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table'
import { useCallback, useMemo, useRef, useState } from 'react'
import { Box, useTheme } from '@mui/material'
import { useQuery } from '@apollo/client'
import { useSearchParams } from 'react-router-dom'
import { formatDistanceToNow } from 'date-fns'

import {
  buildGenericCell,
  buildGenericHeader,
  buildSkeletonRows,
} from '@common/Table/utils/table.utils'
import { PaginationLoadingRow, TableHeader } from '@common/Table'
import { MemoizedTanStackTableRow } from '@common/Table/components/MemoizedTanStackTableRow'
import { NoResults } from '@common/NoResults'
import { ComponentError } from '@common/ComponentError'
import Icon from '@common/Icon'
import { Forwarder, FORWARDER_STATUS, FORWARDER_TYPE } from '@models/Forwarders'
import {
  ENVIRONMENT_HEALTH_FORWARDERS,
  Forwarders,
  ForwarderVariablesInput,
} from '@queries/forwarders'
import {
  mergeRefs,
  useResizableColumns,
} from '@common/Table/ColumnResize/useResizableColumns'
import CommonTableContainer from '@common/Table/components/CommonTableContainer'
import CommonTable from '@common/Table/components/CommonTable'
import StyledTableCell from '@common/Table/styled/StyledTableCell'
import { invertColumnSortDirection } from '@utils/tableUtils'
import { usePageSize } from '@hooks/usePageSize'

import ForwardersHeader from './ForwardersHeader'

export const formatTimeAgo = (dateString: string): string => {
  const utcDate = new Date(`${dateString}Z`)

  // Convert to the user's local time zone
  const localDate = new Date(
    utcDate.toLocaleString('en-US', {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }),
  )

  // Format the distance to now with the converted date
  return formatDistanceToNow(localDate, { addSuffix: true })
}

const Fowarders: React.FC = () => {
  const theme = useTheme()
  const tableRef = useRef<HTMLDivElement>(null)

  const [searchParams] = useSearchParams()
  const selectedCustomer = searchParams.get('customer')
  const selectedFiltersFromSearchParams = searchParams.get('selectedFilters')
  const parsedFilters = selectedFiltersFromSearchParams
    ? JSON.parse(selectedFiltersFromSearchParams)
    : null
  const selectedType = parsedFilters?.type ?? []
  const selectedStatus = parsedFilters?.status ?? []

  const [sorting, setSorting] = useState<SortingState>([
    { id: 'status', desc: false },
  ])

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([
    {
      id: 'status',
      value: selectedStatus,
    },
    {
      id: 'type',
      value: selectedType,
    },
  ])

  const getStatusQueryFilter = (status: string[]): FORWARDER_STATUS[] => {
    return (
      status.map(
        (s) => s.replace(' ', '_').toUpperCase() as FORWARDER_STATUS,
      ) || [FORWARDER_STATUS.ACTIVE, FORWARDER_STATUS.AT_RISK]
    )
  }

  const getTypeQueryFilter = (status: string[]): FORWARDER_TYPE[] => {
    return (
      status.map((s) => s.toUpperCase() as FORWARDER_TYPE) || [
        FORWARDER_TYPE.HEAVY,
        FORWARDER_TYPE.UNIVERSAL,
      ]
    )
  }

  // if there is no query string, then ftch all forwarder data
  const queryFilter = [
    {
      id: 'status',
      value: getStatusQueryFilter(selectedStatus),
    },
    {
      id: 'type',
      value: getTypeQueryFilter(selectedType),
    },
    {
      id: 'allData',
      value: ['false'],
    },
  ]

  const sortingWithInvertedDateColumns = invertColumnSortDirection<Forwarder>(
    sorting,
    ['lastIndexConnection'],
  )

  const pageSize = usePageSize()

  const {
    data: { environmentHealthForwarders: { forwarders, pagination } } = {
      environmentHealthForwarders: {
        forwarders: [],
        pagination: { offset: 0, limit: pageSize, total: 0 },
      },
    },
    loading,
    error,
    fetchMore,
  } = useQuery<Forwarders, ForwarderVariablesInput>(
    ENVIRONMENT_HEALTH_FORWARDERS,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        selectedCustomer,
        filters: queryFilter,
        pagination: {
          limit: pageSize,
          offset: 0,
        },
        sorting: sortingWithInvertedDateColumns[0],
      },
    },
  )

  const handleScrollToTop = useCallback(() => {
    tableRef.current &&
      tableRef.current.scrollTo({
        behavior: 'smooth',
        top: 0,
      })
  }, [])

  const columnHelper = createColumnHelper<Forwarder>()
  const columnDef = useMemo(
    () => [
      columnHelper.accessor('hostname', {
        id: 'hostname',
        cell: (props) => buildGenericCell(props),
        header: () => buildGenericHeader('HOST'),
      }),
      columnHelper.accessor('version', {
        id: 'version',
        cell: (props) => buildGenericCell(props),
        header: () => buildGenericHeader('VERSION'),
      }),
      columnHelper.accessor('os', {
        id: 'os',
        cell: (props) => buildGenericCell(props),
        header: () => buildGenericHeader('OS'),
      }),
      columnHelper.accessor('type', {
        id: 'type',
        cell: (props) => buildGenericCell(props),
        header: () => buildGenericHeader('TYPE'),
      }),
      columnHelper.accessor('status', {
        id: 'status',
        cell: (props) => {
          if (props.getValue() === FORWARDER_STATUS.ACTIVE) {
            return (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Icon
                  variant={'radioButtonOnSharp'}
                  sx={{
                    color: theme.palette.success.main,
                    marginRight: '.5em',
                    fill: theme.palette.success.main,
                  }}
                />
                Active
              </Box>
            )
          }
          return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Icon
                variant={'radioButtonOffSharp'}
                sx={{
                  color: theme.palette.error.light,
                  marginRight: '.5em',
                  fill: theme.palette.error.light,
                }}
              />
              At risk
            </Box>
          )
        },
        header: () => buildGenericHeader('STATUS'),
      }),
      columnHelper.accessor('lastIndexConnection', {
        id: 'lastIndexConnection',
        cell: (props) => {
          return formatTimeAgo(props.getValue())
        },
        header: () => buildGenericHeader('LAST INDEX CONNECTION'),
        sortDescFirst: true,
      }),
    ],
    [],
  )

  const {
    columnSizing,
    onColumnSizingChange,
    tableHeaderRefs,
    tableContainerRef,
    handleMouseUp,
    handleMouseDown,
    resetColumnSize,
  } = useResizableColumns({
    columnDef,
  })

  const table = useReactTable<Forwarder>({
    columns: columnDef,
    data: forwarders,
    getCoreRowModel: getCoreRowModel(),
    enableSortingRemoval: false,
    enableRowSelection: false,
    enableMultiRowSelection: false,
    enableSubRowSelection: false,
    manualSorting: true,
    onSortingChange: (val) => {
      handleScrollToTop()
      setSorting(val)
    },
    columnResizeMode: 'onChange',
    onColumnSizingChange,
    state: {
      sorting,
      columnSizing,
    },
  })

  const handleOnBottomReached = useCallback(() => {
    if (forwarders.length < pagination.total) {
      fetchMore({
        variables: {
          pagination: {
            limit: pageSize,
            offset: forwarders.length,
          },
          filters: queryFilter,
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forwarders.length, fetchMore, pageSize, pagination.total])

  const render = () => {
    const COL_SPAN = 6
    // error state
    if (error) {
      return (
        <Box component="tbody">
          <Box component="tr">
            <StyledTableCell
              align="center"
              colSpan={COL_SPAN}
              style={{ border: 'none', padding: '50px' }}
            >
              <ComponentError
                errorText="No Forwarder Data Available"
                errorSubText="There is no forwarder data currently available at the moment."
              />
            </StyledTableCell>
          </Box>
        </Box>
      )
    }

    // no results found
    if (!loading && forwarders.length === 0) {
      return (
        <Box component="tbody">
          <Box component="tr">
            <StyledTableCell
              align="center"
              colSpan={COL_SPAN}
              sx={{ border: 'none', padding: '50px' }}
            >
              <NoResults />
            </StyledTableCell>
          </Box>
        </Box>
      )
    }

    return (
      <Box component="tbody" data-testid="forwarders-table-body">
        {loading && forwarders.length === 0
          ? buildSkeletonRows(table.getAllColumns())
          : table.getRowModel().rows.map((row) => {
              return (
                <MemoizedTanStackTableRow
                  key={row.id}
                  rowId={row.id}
                  testId="forwarders-table-row"
                  isSelected={false}
                  toggleSelected={row.toggleSelected}
                  visibleCells={row.getVisibleCells()}
                  isDisabled
                />
              )
            })}
        <PaginationLoadingRow
          colSpan={7}
          currentLength={forwarders.length}
          pageSize={pageSize}
          total={pagination.total}
          onScrollToTop={handleScrollToTop}
        />
      </Box>
    )
  }

  return (
    <Box
      sx={{ padding: '1.5rem', width: '100%', height: 'calc(100vh - 104px)' }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <ForwardersHeader
          totalForwarders={pagination.total}
          filters={columnFilters}
          setFilters={setColumnFilters}
        />

        <CommonTableContainer
          data-testid="forwarders-table-container"
          ref={mergeRefs(tableContainerRef, tableRef)}
          onScroll={handleOnBottomReached}
        >
          <CommonTable data-testid="forwarders-table">
            <thead data-testid="forwarders-table-header">
              {table.getHeaderGroups().map((headerGroup) => (
                <Box component="tr" key={headerGroup.id}>
                  {headerGroup.headers.map((header, index) => (
                    <TableHeader<Forwarder>
                      key={header.id}
                      header={header}
                      thRef={tableHeaderRefs[`${index}`]}
                      width={columnSizing[header.id]}
                      table={table}
                      onResizeHandleMouseUp={handleMouseUp}
                      onResizeHandleMouseDown={handleMouseDown}
                      onResetColumnSize={resetColumnSize}
                    />
                  ))}
                </Box>
              ))}
            </thead>
            {render()}
          </CommonTable>
        </CommonTableContainer>
      </Box>
    </Box>
  )
}

export default Fowarders
