import { createLogScale } from '@nivo/scales'

import { DashboardThreatProtection } from '../../../../../models/Dashboard'
import { ScaledChartValuesInterface } from './ThreatProtectionSummary.models'

export const scaleValues = ({
  alertCount,
  loggedEventCount,
  openTicketCount,
}: Pick<
  DashboardThreatProtection,
  'alertCount' | 'loggedEventCount' | 'openTicketCount'
>): ScaledChartValuesInterface[] => {
  const ticketInfo = [
    {
      label: 'Events logged',
      value: loggedEventCount === 0 ? 1 : loggedEventCount,
      originalValueToDisplay: loggedEventCount,
      id: 'logged_events_count',
    },
    {
      label: 'Alerts detected',
      value: alertCount === 0 ? 1 : alertCount,
      originalValueToDisplay: alertCount,
      id: 'alert_count',
    },
    {
      label: 'Tickets opened',
      value: openTicketCount === 0 ? 1 : openTicketCount,
      originalValueToDisplay: openTicketCount,
      id: 'total_ticket_count',
    },
    {
      label: '',
      value: 1,
      id: 'empty_part',
    },
  ]

  const nums = ticketInfo.map((d) => d.value)

  const minNum = nums.reduce((a, b) => (a < b ? a : b))
  const maxNum = nums.reduce((a, b) => (a > b ? a : b))

  const logScale = createLogScale(
    { type: 'log', base: 10, min: 'auto', max: 'auto' },
    { all: nums, min: minNum, max: maxNum },
    10000,
    'x',
  )

  return Object.values(ticketInfo).map(
    ({ label, value, id, originalValueToDisplay }) => {
      return {
        id,
        label,
        value: value === 1 ? 0 : logScale(value),
        originalValueToDisplay,
      }
    },
  )
}

export const threatSummaryHasData = (
  threatSummary: DashboardThreatProtection,
): boolean => {
  const hasUndefData =
    threatSummary.alertCount === undefined ||
    threatSummary.loggedEventCount === undefined ||
    threatSummary.openTicketCount === undefined ||
    threatSummary.alertCount === null ||
    threatSummary.loggedEventCount === null ||
    threatSummary.openTicketCount === null

  const hasZeroData = threatSummary.loggedEventCount === 0

  return !hasZeroData && !hasUndefData
}

export const formatNumber = (
  number: number,
  displayType: 'short' | 'long',
): string => {
  if (number) {
    if (number.toString().length > 8) {
      return new Intl.NumberFormat('en', {
        notation: 'compact',
        compactDisplay: displayType,
      }).format(number)
    } else {
      return new Intl.NumberFormat().format(number)
    }
  }

  return number.toString()
}
