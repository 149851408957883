import { useContext, useEffect, useMemo, useRef } from 'react'

import { Ticket } from '../../../../models'
import { TicketCommentForm } from '../../TicketEdit/components/TicketCommentForm'
import {
  TicketActivityType,
  TicketActivityTypeEnum,
} from '../../TicketEdit/Types'
import TicketActivityItem from '../../TicketEdit/components/TicketActivity/TicketActivityItem'
import { convertTicketCommentToTicketActivity } from '../../TicketEdit/components/TicketActivity/TicketActivity.utils'
import { Context } from '../../../App'
import usePendingActivitySubmission, {
  PendingActivitySortOrder,
} from '../../hooks/usePendingActivitySubmission'
import { TicketEditContextProvider } from '../../TicketEdit/context/TicketEditContext'
import { useTicketsContext } from '../../../../hooks'

import './ActivityTab.scss'

interface TicketDetailsProps {
  focusedTicket: Ticket
}

const ActivityTab = ({ focusedTicket }: TicketDetailsProps): JSX.Element => {
  const {
    state: { dwExpertsCustomer, customer },
  } = useContext(Context)
  const { isEditable } = useTicketsContext()
  const bottomOfCommentsList = useRef<HTMLDivElement>(null)
  const { pendingActivityItems, setItemError, setItemCompleted, addItem } =
    usePendingActivitySubmission({
      pendingCommentOrder: PendingActivitySortOrder.ASCENDING,
      ticketId: focusedTicket.sysId,
    })

  const ticketComments = useMemo<TicketActivityType[]>(() => {
    const parsedComments = focusedTicket.comments
      ? focusedTicket.comments.map(convertTicketCommentToTicketActivity)
      : []
    const placeholderComments: TicketActivityType[] = []

    // If no comments show created comment
    if (
      !focusedTicket.comments?.length &&
      (focusedTicket.openedBy || focusedTicket.createdDate)
    ) {
      const [firstName, lastName] =
        focusedTicket.openedBy.split(' ') ||
        `MDR ${dwExpertsCustomer || customer}`

      placeholderComments.push({
        type: TicketActivityTypeEnum.Placeholder,
        id: '',
        createdDate: focusedTicket?.createdDate || new Date().toISOString(),
        text: `${focusedTicket.ticketNumber} Created.`,
        userInfo: {
          id: 'createdBy',
          firstName: firstName,
          lastName: lastName || '', // Account for users with no last name
          type: 'system',
        },
      })
    }

    // Sort in descending order
    const result = [...parsedComments, ...placeholderComments].sort(
      ({ createdDate: aCreatedDate }, { createdDate: bCreatedDate }) =>
        new Date(aCreatedDate).getTime() - new Date(bCreatedDate).getTime(),
    )

    return result
  }, [focusedTicket, customer, dwExpertsCustomer])

  // Scroll to the bottom once a user adds an activity item
  useEffect(() => {
    if (pendingActivityItems.length > 0) {
      bottomOfCommentsList.current?.scrollIntoView({
        behavior: 'smooth',
      })
    }
  }, [pendingActivityItems, bottomOfCommentsList])

  const allComments = [...ticketComments, ...pendingActivityItems]

  return (
    <div id="new-activity-tab-details">
      <div className="content-scroll-container">
        {allComments?.map((comment, index) => {
          return (
            <TicketActivityItem
              key={index}
              activity={comment}
              shouldShowVerticalLine={
                allComments.length > 1 && index !== allComments.length - 1
              }
            />
          )
        })}
        <div className="bottom-of-list" ref={bottomOfCommentsList} />
      </div>
      <div className="comment-form-container">
        <TicketEditContextProvider
          sysId={focusedTicket.sysId}
          isEditable={isEditable}
        >
          <TicketCommentForm
            ticketId={focusedTicket.sysId}
            onCompleted={setItemCompleted}
            onError={setItemError}
            onSubmit={addItem}
          />
        </TicketEditContextProvider>
      </div>
    </div>
  )
}

export default ActivityTab
