import { Checkbox, colors } from '../../../design-system'
import {
  FilterOptionValue,
  FilterOptionValues,
  FilterOptions,
  FilterOptionsGenericObject,
} from '../../../models'

import './Filters.scss'

export interface FilterChipsProps<T> {
  keyValue: string
  filterGroup: string
  filterLabel: string
  filterValues: FilterOptionValue[]
  selectedFilters: FilterOptions<T>
  setSelectedFilters: (FiltersProps: FilterOptions<T>) => void
}
export interface ToggleSelectedFiltersProps
  extends Omit<FilterChipsProps<any>, 'filterValues'> {
  checked: boolean
  value: string
}

const FilterChips = ({
  keyValue,
  filterGroup,
  filterLabel,
  filterValues,
  selectedFilters,
  setSelectedFilters,
}: FilterChipsProps<
  FilterOptionValues | FilterOptionsGenericObject<any>
>): JSX.Element => {
  const handleToggleSelectedFilterCheckboxes = ({
    checked,
    selectedFilters,
    value,
    setSelectedFilters,
  }: ToggleSelectedFiltersProps) => {
    if (checked) {
      setSelectedFilters({
        ...selectedFilters,
        showOpenTicketOnly: false,
        filters: {
          ...selectedFilters.filters,
          [keyValue]: [...selectedFilters.filters[`${keyValue}`], value],
        },
      })
    } else {
      setSelectedFilters({
        ...selectedFilters,
        showOpenTicketOnly: false,
        filters: {
          ...selectedFilters.filters,
          [keyValue]: selectedFilters.filters[`${keyValue}`].filter(
            (filterValue: string) => filterValue !== value,
          ),
        },
      })
    }
  }

  return (
    <>
      {filterValues.map(({ label, value }) => {
        const filterChecked =
          keyValue === ''
            ? false
            : selectedFilters.filters[`${keyValue}`].includes(value)
        return (
          <Checkbox
            key={label}
            label={label}
            name={label}
            checked={filterChecked}
            hideCheckbox
            textColor={
              filterChecked ? colors.util.navy[600] : colors.util.navy[100]
            }
            onChange={(checked: boolean) =>
              handleToggleSelectedFilterCheckboxes({
                keyValue,
                checked,
                selectedFilters,
                filterGroup,
                value,
                filterLabel,
                setSelectedFilters,
              })
            }
          />
        )
      })}
    </>
  )
}

export default FilterChips
