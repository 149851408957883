import React, { CSSProperties, useState } from 'react'

import { ListItemProps, ListItemType, ListItemVariant } from '../../interfaces'
import { colors } from '../../theme'
import { useTheme } from '../../hooks'
import { Icon, Loader, Typography } from '..'

// TODO: after building out toggle component, add prop toggle: ReactElement<Toggle>

const ListItem = ({
  item,
  active,
  selected,
  onSelect,
  onHover,
  variant,
}: ListItemProps): JSX.Element => {
  const isDarkTheme = useTheme('dark')
  const [hover, setHover] = useState(false)
  const getBackgroundColor = (active: boolean) => {
    if (!active) {
      return colors.util.one.main
    } else if (isDarkTheme) {
      colors.util.navy[400]
    } else {
      colors.util.navy[300]
    }
  }

  const getCursorStyle = (
    cursor: ListItemType['cursor'] | undefined,
    variant: ListItemVariant | undefined,
  ) => {
    if (variant === 'standard') {
      return 'default'
    } else if (cursor !== undefined) {
      return cursor
    } else {
      return 'pointer'
    }
  }

  const getTextColor = (
    loading: boolean | undefined,
    textColor: ListItemType['textColor'] | undefined,
  ) => {
    if (loading === true) {
      return colors.util.navy[100]
    } else if (textColor !== undefined) {
      return textColor
    } else if (hover) {
      return colors.util.navy[50]
    } else {
      return colors.util.navy[100]
    }
  }

  const getBorder = (variant: ListItemVariant | undefined) => {
    if (variant === 'standard' || variant === 'link' || variant === 'popover') {
      return `1px solid ${colors.util.navy[400]}`
    } else if (variant === 'select' && hover) {
      return '1px solid rgba(69, 83, 88, 0.8)'
    } else if (variant === 'select' && !hover) {
      return '1px solid transparent'
    } else {
      return 'none'
    }
  }

  const itemContainer = (
    active: boolean,
    variant: ListItemVariant | undefined,
    cursorType: ListItemType['cursor'] | undefined,
  ): CSSProperties => ({
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: hover ? colors.util.navy[400] : getBackgroundColor(active),
    border: getBorder(variant),
    borderRadius: ['standard', 'link', 'select'].includes(variant ?? '')
      ? 5
      : '',
    boxShadow:
      variant === 'select' && hover
        ? '0px 1px 2px rgba(28, 38, 40, 0.1), inset 0px 1px 0px rgba(81, 100, 106, 0.7), inset 0px -1px 0px #36474C'
        : 'none',
    cursor: getCursorStyle(cursorType, variant),
    display: 'inline-flex',
    gap:
      variant === 'standard' || variant === 'link' || variant === 'popover'
        ? 0
        : 8,
    justifyContent: variant === 'popover' ? 'flex-start' : 'space-between',
    margin: variant === 'select' ? 2 : 0,
    overflow: 'hidden',
    padding: variant === 'popover' ? 14 : 8,
    width: '100%',
  })

  const textStyle = (
    variant: ListItemVariant | undefined,
    loading: boolean | undefined,
    textColor: ListItemType['textColor'] | undefined,
  ): CSSProperties => ({
    fontSize: 14,
    fontWeight: variant === 'popover' ? 500 : 400,
    lineHeight: 1.4,
    textAlign: 'start',
    overflow: item.rightText ? 'hidden' : 'visible',
    whiteSpace: 'pre',
    textOverflow: 'ellipsis',
    minWidth: 0,
    marginBlock: 0,
    marginInline: 0,
    margin: variant === 'popover' ? '0' : 'auto auto auto 0',
    color: getTextColor(loading, textColor),
    alignItems: 'center',
  })

  const rightTextStyle: CSSProperties = {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1.4,
    textAlign: 'start',
    whiteSpace: 'pre',
    textOverflow: 'ellipsis',
    minWidth: 0,
    marginBlock: 0,
    marginInline: 0,
    margin: 'auto 10px auto auto',
    color: colors.util.navy[50],
    display: 'flex',
    alignItems: 'center',
  }

  const handleItemClick = (): void => {
    // TODO: Handle logic of not running onSelect if the role of the list item
    // TODO: doesn't pertain to a state, (i.e.) ListItem used for site
    // TODO: navigation
    if (onSelect) {
      onSelect(item)
    }

    if (item.onClick) {
      item.onClick()
    }
  }

  const handleItemHover = (item: ListItemType | undefined): void => {
    if (!item?.disableHover) {
      setHover(!hover)
      onHover(item)
    }
  }

  const displaySelectedIcon: boolean = selected && !item.rightIcon

  const rightText = (loading: boolean) => {
    if (loading === true) {
      return (
        <div style={{ marginRight: 12 }}>
          <Loader size={12} strokeWidth={1} />
        </div>
      )
    } else {
      return <Typography styles={rightTextStyle}>{item.rightText}</Typography>
    }
  }

  const rightIcon = (loading: boolean) => {
    if (loading === true) {
      return (
        <div style={{ marginRight: 12 }}>
          <Loader size={12} strokeWidth={1} />
        </div>
      )
    } else {
      return <Icon variant={item.rightIcon} color={item.iconColor} size={20} />
    }
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLLIElement>) => {
    if (event.key === 'Enter') {
      handleItemClick()
    }
  }

  return (
    <li
      tabIndex={0}
      role="option"
      aria-selected={selected}
      style={itemContainer(
        item?.onClick !== undefined && active,
        variant,
        item.cursor,
      )}
      onClick={handleItemClick}
      onKeyDown={(event) => handleKeyPress(event)}
      className={`list-item--${item.id}`}
      id={`list-item--${item.id}`}
      {...(!item.disableHover && {
        onMouseEnter: () => handleItemHover(item),
        onMouseLeave: () => handleItemHover(undefined),
      })}
    >
      {item.leftIcon && (
        <Icon
          variant={item.leftIcon}
          color={item.iconColor}
          size={item.leftIconSize || 20}
        />
      )}

      {item.leftAlternateImage && item.leftAlternateImage}

      <Typography
        styles={{
          ...textStyle(variant, item.loading, item.textColor),
          marginRight: item.rightIcon || item.rightText ? 8 : 'auto',
          marginLeft: item.leftIcon || item.leftAlternateImage ? 8 : 0,
        }}
      >
        {item.name}
      </Typography>

      {displaySelectedIcon && (
        <Icon variant={'checkmarkCircle'} color={colors.neutral[100]} />
      )}

      {!displaySelectedIcon &&
        item.rightIcon &&
        rightIcon(item.loading ? true : false)}

      {item.rightText && rightText(item.loading ? true : false)}
    </li>
  )
}

export default ListItem
