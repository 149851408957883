import { useFragment, useQuery } from '@apollo/client'
import { useContext } from 'react'

import { useThreatIntelReportsContext } from './context'
import ThreatIntelReportsHeader from './components/ThreatIntelReportsHeader'
import ThreatIntelReportsList from './components/ThreatIntelReportsList/ThreatIntelReportsList'
import { Loader } from '../../../design-system'
import { Context } from '../../App'
import { useThreatIntelReportsInput } from './hooks'
import ThreatIntelSideSheet from './components/ThreatIntelSideSheet/ThreatIntelSideSheet'
import {
  GET_THREAT_INTEL_REPORTS_LIST_QUERY,
  GetThreatIntelReportsData,
  GetThreatIntelReportsVariables,
  ThreatIntelReportsData,
} from '../../../graphql/queries/threatIntel'
import { THREAT_INTEL_REPORT_DATA_FRAGMENT } from '../../../graphql/fragments/threatIntel'

import './ThreatIntelReports.scss'

const ThreatIntelReports: React.FC = () => {
  const threatIntelReportsInput = useThreatIntelReportsInput()

  const {
    state: {
      dwExpertsCustomer: { customerShortName: dwExpertCustomerShortName },
    },
  } = useContext(Context)

  const { isSideSheetOpen, activeReportId, closeSideSheet } =
    useThreatIntelReportsContext()

  const initialAdvisories: ThreatIntelReportsData = {
    pagination: {
      limit: 3,
      offset: 0,
      total: 3,
    },
    threatIntelReports: [],
  }

  const {
    data: { getThreatIntelReports: advisoryReportData } = {
      getThreatIntelReports: initialAdvisories,
    },
    loading: advisoriesLoading,
  } = useQuery<GetThreatIntelReportsData, GetThreatIntelReportsVariables>(
    GET_THREAT_INTEL_REPORTS_LIST_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        selectedCustomer: dwExpertCustomerShortName,
        input: {
          pagination: {
            offset: 0,
            limit: 3,
          },
          ...threatIntelReportsInput,
        },
      },
    },
  )

  const { data: activeReport } = useFragment({
    fragment: THREAT_INTEL_REPORT_DATA_FRAGMENT,
    fragmentName: 'ThreatIntelReportData',
    from: {
      __typename: 'ThreatIntelReport',
      id: activeReportId,
    },
  })

  return (
    <div className="threat-intel-reports">
      {advisoriesLoading ? (
        <div className="flex center align-center w-full h-full">
          <Loader strokeWidth={1} size={120} />
        </div>
      ) : (
        <ThreatIntelReportsHeader
          advisories={advisoryReportData.threatIntelReports}
        />
      )}
      <ThreatIntelReportsList />
      <ThreatIntelSideSheet
        isOpen={isSideSheetOpen}
        closeSideSheet={closeSideSheet}
        threatIntelReport={
          Object.keys(activeReport).length ? activeReport : null
        }
      />
    </div>
  )
}

export default ThreatIntelReports
