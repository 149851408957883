export const scrollToTop = (
  tableRef:
    | React.MutableRefObject<HTMLDivElement>
    | React.RefObject<HTMLDivElement>
    | null,
): void => {
  if (tableRef?.current?.firstElementChild?.scrollTo) {
    tableRef.current.firstElementChild.scrollTo(0, 0)
  }
}
