import classNames from 'classnames'

import { Typography } from '../Typography'
import { IconVariant, TagSeverity } from '../../interfaces'
import { Icon } from '../Icon'
import { colors } from '../../theme'

import './TagNew.scss'

interface TagNewProps {
  interactive: boolean
  severity: TagSeverity
  text: string
  disabled?: boolean
  highlighted?: boolean
  leftIcon?: IconVariant
  onClickCallback?: VoidFunction
  rightIcon?: IconVariant
}

const TagNew = ({
  disabled = false,
  highlighted = false,
  interactive,
  leftIcon,
  rightIcon,
  severity = 'none',
  text,
  onClickCallback,
}: TagNewProps): JSX.Element => {
  const handleClick = (
    e:
      | React.MouseEvent<HTMLSpanElement, MouseEvent>
      | React.KeyboardEvent<HTMLSpanElement>,
  ) => {
    e.stopPropagation()

    onClickCallback?.()
  }

  return (
    <span
      className={classNames('tag-new', 'small', {
        interactive: interactive,
        'non-interactive': interactive === false,
        [`${severity}`]: interactive === false && severity !== 'none',
        'no-hover': disabled,
      })}
      data-testid={`tag-new-${text.trim().toLowerCase().replace(' ', '-')}`}
      onClick={onClickCallback && handleClick}
      onKeyDown={onClickCallback && handleClick}
      role={onClickCallback ? 'button' : 'none'}
    >
      {leftIcon && (
        <Icon
          variant={leftIcon}
          color={disabled ? colors.common.white : colors.util.one.main}
          size={12}
        />
      )}
      <Typography
        color={getTextColor(severity)}
        size={12}
        weight={highlighted ? 700 : 500}
      >
        {text}
      </Typography>
      {rightIcon && (
        <Icon
          variant={rightIcon}
          color={disabled ? colors.common.white : colors.util.one.main}
          size={12}
        />
      )}
    </span>
  )
}

const getTextColor = (severity: TagSeverity): string | undefined => {
  let result = ''
  switch (severity) {
    case 'critical':
      result = colors.util.two.lighter
      break
    case 'high':
      result = colors.util.three.lighter
      break
    case 'informational':
      result = colors.brand.secondary.light
      break
    case 'low':
      result = colors.brand.primary.lighter
      break
    case 'medium':
      result = colors.brand.secondary.lighter
      break
    case 'none':
    default:
      result = colors.util.one.light
      break
  }

  return result
}

export default TagNew
