import React, { useCallback, useMemo, useRef } from 'react'
import classNames from 'classnames'

import { handleKeyboardAction } from '@utils/handleKeyboardAction'

import type { Header, Table } from '@tanstack/react-table'
import type { UseResizableColumnsReturn } from './useResizableColumns/useResizableColumns'

import './ColumnResizeHandle.scss'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface ColumnResizeHandleProps<T = any> {
  /**
   * A TanStack `Header`
   * @see https://tanstack.com/table/latest/docs/guide/headers#headergroup-headers
   */
  header: Header<T, unknown>
  /**
   * A TanStack `Table` from `useReactTable`
   * @see https://tanstack.com/table/latest/docs/framework/react/react-table#usereacttable
   */
  table: Table<T>
  /**
   * A function to handle mousedown events on the column resizing handle, passed from the `useResizableColumns` hook
   */
  onMouseUp: UseResizableColumnsReturn<T>['handleMouseUp']
  /**
   * A function to handle mouseup events on the column resizing handle, passed from the `useResizableColumns` hook
   */
  onMouseDown: UseResizableColumnsReturn<T>['handleMouseDown']
  /**
   * A function to reset the column size, passed from the `useResizableColumns` hook
   */
  onResetColumnSize: UseResizableColumnsReturn<T>['resetColumnSize']
}

/**
 * TODO - This component needs to be migrated to use Material SX styling instead of SCSS
 * @param {object} props - The component props.
 * @param {import('@tanstack/react-table').Header<any, any>} props.header - A TanStack `Header` object
 * @param {import('@tanstack/react-table').Table<any>} props.table - A TanStack `Table` object from `useReactTable`
 * @param {UseResizableColumnsReturn<unknown>['handleMouseUp']} [props.onMouseUp] - A function to handle the mouse up event, passed from the `useResizableColumns` hook
 * @param {UseResizableColumnsReturn<unknown>['handleMouseDown']} props.onMouseDown - A function to handle column resizing, passed from the `useResizableColumns` hook
 * @param {UseResizableColumnsReturn<unknown>['resetColumnSize']} props.onResetColumnSize - A function to reset the column size, passed from the `useResizableColumns` hook
 * @returns {React.FC<ColumnResizeHandleProps>} The ColumnResizeHandle component
 */
export const ColumnResizeHandle: React.FC<ColumnResizeHandleProps> = ({
  header,
  table,
  onMouseUp,
  onMouseDown,
  onResetColumnSize,
}) => {
  const handleRef = useRef<HTMLDivElement>(null)
  const lastColumn = useMemo(() => table.getAllLeafColumns().at(-1), [table])
  const isLastColumn = lastColumn?.id === header.column.id

  /**
   * @description Translate a keyboard event into a mouse events, and dispatch them to simulate dragging the column
   * @param {React.KeyboardEvent<import('@types/node').HTMLElement>} event A keyboard event
   */
  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLElement>) => {
      const handle = handleRef.current
      if (
        handle &&
        !isLastColumn &&
        (event.key === 'ArrowRight' || event.key === 'ArrowLeft')
      ) {
        const initialX = handle.getBoundingClientRect().left
        const initialY = handle.getBoundingClientRect().top
        const commonEventProperties = {
          bubbles: true,
          cancelable: true,
          view: window,
          clientY: initialY,
        }
        const moveDistance = event.key === 'ArrowRight' ? 5 : -5

        const mouseDownEvent = new MouseEvent('mousedown', {
          ...commonEventProperties,
          clientX: initialX,
        })
        const mousemoveEvent = new MouseEvent('mousemove', {
          ...commonEventProperties,
          clientX: initialX + moveDistance,
        })
        const mouseupEvent = new MouseEvent('mouseup', {
          ...commonEventProperties,
          clientX: initialX + moveDistance,
        })

        handle.dispatchEvent(mouseDownEvent)
        handle.dispatchEvent(mousemoveEvent)
        requestAnimationFrame(() => {
          handle.dispatchEvent(mouseupEvent)
        })
      } else {
        handleKeyboardAction(event, () => onResetColumnSize(header)) // Reset the column size if the user presses `Enter` or `Space`
      }
    },
    [onResetColumnSize, header, isLastColumn],
  )

  const className = classNames('resizer', table.options.columnResizeDirection, {
    'is-resizing': header.column.getIsResizing(),
  })

  /**
   * @description Handle the mousedown event on the column resizing handle.
   *
   * If the handle is the last column, reset the column size. Otherwise, call the `onMouseDown` function to
   * handle the column resizing.
   * @param {React.MouseEvent<import('@types/node').HTMLDivElement, import('@types/node').MouseEvent>} event The mousedown event
   */
  const handleMouseDown = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (isLastColumn) {
        event.stopPropagation()
        onResetColumnSize(header)
      } else {
        onMouseDown(event, header)
      }
    },
    [header, isLastColumn, onMouseDown, onResetColumnSize],
  )

  return (
    <div
      ref={handleRef}
      data-testid="column-resize-handle"
      role="button"
      tabIndex={0}
      onMouseDown={handleMouseDown}
      onMouseUp={onMouseUp}
      onKeyDown={handleKeyDown}
      className={className}
    />
  )
}
