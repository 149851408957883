import { Fragment } from 'react'
import classNames from 'classnames'

import { colors, Tag } from '../../../../design-system'
import { convertKeywordSearchToArray, formatChipLabel } from '../Filters.utils'
import {
  FilterOptions,
  FilterOptionsGenericObject,
  FilterOptionValues,
} from '../../../../models'
export interface FilterChipsHeaderProps<T> {
  selectedFilters: FilterOptions<T>
  deleteChip: (filterGroup: string, filterValue: string) => void
  hasActiveFilters?: boolean
}

const FilterChipsHeader = ({
  selectedFilters,
  deleteChip,
  hasActiveFilters = false,
}: FilterChipsHeaderProps<
  FilterOptionValues | FilterOptionsGenericObject<any>
>): JSX.Element => {
  const chipsStyles = {
    color: colors.util.navy[600],
    backgroundColor: colors.util.navy[100],
    cursor: 'pointer',
    fontWeight: '600',
  }

  const convertedKeywordSearch = convertKeywordSearchToArray(
    selectedFilters.keywordSearch ?? '',
  )

  // flatten the object so it's easier to work with
  const combineKeywordSearchAndFilters = {
    ...selectedFilters.filters,
    keywordSearch: convertedKeywordSearch,
  }

  const className = classNames('filter-chips', {
    active: hasActiveFilters,
  })

  return (
    <div className={className} data-testid="filter-chips-header">
      {Object.keys(combineKeywordSearchAndFilters).map(
        (filterGroup: string) => {
          return combineKeywordSearchAndFilters[`${filterGroup}`].map(
            (filterValue: string) => {
              return (
                <Fragment key={filterValue}>
                  <Tag
                    variant="chip"
                    text={formatChipLabel(filterGroup, filterValue)}
                    rightIcon={'closeCircle'}
                    onDelete={() => deleteChip(filterGroup, filterValue)}
                    styles={chipsStyles}
                  />
                </Fragment>
              )
            },
          )
        },
      )}
    </div>
  )
}
export default FilterChipsHeader
