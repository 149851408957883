import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { OktaAuth, Tokens } from '@okta/okta-auth-js'
import { useIdleTimer } from 'react-idle-timer'

import { Context, setGlobalLoading, setSession, setUser } from '../App'
import { deleteCookie, getCookie, setCookie } from '../../utils/'
import { SessionStatus, oktaAuthConfig } from '../../config/OktaAuthConfig'
import { useModalContext } from '../../hooks'
import SessionTimeoutModal from '../common/Modal/SessionTimeoutModal/SessionTimeoutModal'

const SecuritySession: React.FC<
  {
    oktaAuth: OktaAuth
    children?: unknown
  } & React.HTMLAttributes<HTMLDivElement>
> = ({ oktaAuth, children }) => {
  const { dispatch } = useContext(Context)
  const navigate = useNavigate()
  const { closeModal, openModal } = useModalContext()

  // 3600000 === 1 hour
  const { getRemainingTime, isPrompted, pause, start } = useIdleTimer({
    promptBeforeIdle: 1200000,
    startManually: true,
    startOnMount: false,
    stopOnIdle: true,
    timeout: 1800000,
    onIdle: () => {
      signUserOut(oktaAuthConfig, true)
    },
    onPrompt: () => {
      const remainingSeconds = Math.trunc(getRemainingTime() / 1000)
      openModal({
        component: (
          <SessionTimeoutModal
            closeModal={closeModal}
            resetIdleTimer={start}
            secondsLeft={remainingSeconds}
          />
        ),
        title: `Session Timeout`,
        escapeKeyToClose: false,
        closeButtonVisible: false,
      })
    },
  })

  useEffect(() => {
    ;(async () => {
      try {
        if (
          new Date(
            oktaAuth.tokenManager.getTokensSync().accessToken?.expiresAt ??
              0 * 1000,
          ) < new Date()
        ) {
          if (await oktaAuth.session.exists()) {
            const { tokens } = await oktaAuth.token.getWithoutPrompt()
            oktaAuth.tokenManager.setTokens(tokens)
            const { email, family_name, given_name, name, sub } =
              await oktaAuth.getUser()
            const oktaGroups = parseGroups(tokens)

            if (isPrompted() === false) {
              start()
            }

            dispatch(setSession(SessionStatus.ACTIVE))
            dispatch(
              setUser({
                id: sub,
                email: email as string,
                username: name as string,
                firstName: given_name as string,
                lastName: family_name as string,
                isDWEmployee: setEmployeeStatus(oktaGroups),
                isAdmin: setAdminStatus(oktaGroups),
              }),
            )
          } else {
            pause()
            if (getCookie('iav')) {
              signUserOut(oktaAuthConfig, false)
            }
          }
        }
      } catch (error) {
        dispatch(
          setUser({
            id: '',
            email: '',
            firstName: '',
            lastName: '',
            username: '',
            isDWEmployee: false,
            isAdmin: false,
          }),
        )
        dispatch(setSession(SessionStatus.INACTIVE))
        oktaAuth.tokenManager.clear()
        /* Global loading is initialized as true. This ensures that the home path is rendered 
        once authentication has been completed. IMO this approach smells and stand to be improved.*/
      } finally {
        dispatch(setGlobalLoading(false))
      }
    })()
  }, [closeModal, dispatch, isPrompted, navigate, oktaAuth, pause, start])
  return <>{children}</>
}

export default SecuritySession

enum OktaGroups {
  EMPLOYEE_GROUP = 'dw-app-portal-users',
  ADMINS_GROUP = '-portal-admins',
}

const parseGroups = (tokens: Tokens): string[] => {
  if (tokens.idToken) {
    return tokens.idToken.claims.groups as string[]
  }
  return []
}

const setEmployeeStatus = (groups: string[]): boolean => {
  return groups.some((item) => {
    return (item as string).includes(OktaGroups.EMPLOYEE_GROUP)
  })
}

const setAdminStatus = (groups: string[]): boolean => {
  return groups.some((group) => {
    return group.includes(OktaGroups.ADMINS_GROUP)
  })
}

const signUserOut = (oktaApi: OktaAuth, isSessionExpired: boolean): void => {
  if (getCookie('iav')) {
    deleteCookie('iav')
  }
  if (isSessionExpired) {
    setCookie('se', '1', new Date(Date.now() + 10000))
  }
  oktaApi.signOut()
}
