import { useContext, useEffect, useRef, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { Navigate, useNavigate } from 'react-router-dom'
import { useMutation } from '@apollo/client'

import { oktaAuthConfig, SessionStatus } from '../../config/OktaAuthConfig'
import { tosVersion } from '../Routes/UserInit'
import { Context, setSession, setTermsAccepted, setUser } from '../App'
import tosContent from '../../assets/termsofservice.md'
import { renderErrorToast } from '../../utils'
import { Loading } from '../Loading'
import { deleteCookie, setCookie } from '../../utils/Cookies'
import { Paths, Terms } from '../App/Types'
import { ErrorFallback } from '../App/Errors/ErrorFallback'
import { Button, Typography, colors } from '../../design-system'
import { CREATE_ACCEPTED_TERMS } from '../../graphql/mutations/user'

import './TermsOfService.scss'

//? To test that the text would render in the component.
type TermsOfServiceProps = {
  bodyContent?: string
}
const title = 'Terms of Service'

const TermsOfService = ({ bodyContent }: TermsOfServiceProps): JSX.Element => {
  const modalRef = useRef<HTMLElement>(null)
  const [userScrolled, setUserScrolled] = useState(false)
  const navigate = useNavigate()
  const {
    state: {
      homePath,
      user: { isDWEmployee },
    },
    dispatch,
  } = useContext(Context)

  const [createAcceptedTerms, { loading, error }] = useMutation(
    CREATE_ACCEPTED_TERMS,
    {
      variables: {
        acceptedVersion: tosVersion,
      },
      onError: (error) => {
        dispatch(setTermsAccepted(Terms.DECLINED))
        renderErrorToast(error, dispatch)
      },
      onCompleted: (data) => {
        if (data) {
          dispatch(setTermsAccepted(Terms.ACCEPTED))
          deleteCookie('ct') // remove cancelled terms cookie in case of sign out and the login shows incorrect inline message about cancelling terms
          navigate(Paths.DASHBOARD)
        }
      },
    },
  )

  useEffect(() => {
    if (modalRef.current && true) {
      modalRef.current.focus()
    }
    document
      .getElementById('modalchildrencontainer')
      ?.addEventListener('scroll', handleScroll, {
        passive: true,
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleScroll = (e: Event) => {
    const targetElement = e.target as HTMLElement
    const buffer = 10
    const bottom =
      Math.ceil(
        targetElement.clientHeight + targetElement.scrollTop + buffer,
      ) >= targetElement.scrollHeight
    if (bottom) {
      setUserScrolled(true)
    }
  }

  const handleCancelTerms = () => {
    dispatch(setTermsAccepted(Terms.DECLINED))
    dispatch(setSession(SessionStatus.INACTIVE))
    dispatch(
      setUser({
        id: '',
        email: '',
        firstName: '',
        lastName: '',
        username: '',
        isDWEmployee: false,
        isAdmin: false,
      }),
    )
    navigate(Paths.LOGIN)
    oktaAuthConfig.closeSession()
    deleteCookie('iav')
    setCookie('ct', '1', new Date(Date.now() + 10 * 1000))
  }

  const RenderPageElement = () => {
    if (loading) {
      return <Loading />
    } else if (error) {
      return <ErrorFallback style={{ left: '50%' }} />
    } else {
      return (
        <article data-testid="tos-modal" id="dw-terms-of-service">
          <div id="modal-backdrop" className="backdrop" role={'dialog'} />
          <div className="terms-of-service-container" tabIndex={-1}>
            <div className="header-container">
              <Typography
                component={'h2'}
                styles={{
                  color: colors.util.navy[100],
                  fontSize: 18,
                  fontWeight: 600,
                  lineHeight: 1.2,
                  marginInline: 0,
                  marginBlock: 0,
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                {title}
              </Typography>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  component="h3"
                  styles={{ marginBlock: 0 }}
                  size={12}
                >
                  {`VERSION ${tosVersion}`}
                </Typography>
              </div>
            </div>
            <div id="modalchildrencontainer" className="content">
              <Typography component="div">
                {bodyContent === undefined && (
                  <ReactMarkdown
                    className="termsofservicemarkdown"
                    linkTarget="_blank"
                  >
                    {tosContent}
                  </ReactMarkdown>
                )}
                {bodyContent && bodyContent}
              </Typography>
            </div>
            <div className="footer-btn">
              <Button
                label="Cancel"
                variant="tertiary"
                onClick={handleCancelTerms}
              />

              <Button
                label="Agree to Terms"
                variant="primary"
                onClick={() => createAcceptedTerms()}
                disabled={userScrolled === false}
              />
            </div>
          </div>
        </article>
      )
    }
  }

  if (isDWEmployee) {
    return <Navigate to={homePath} />
  }

  return RenderPageElement()
}

export default TermsOfService
