import { useEffect, useMemo, useState } from 'react'

import { useMitreOverviewContext } from '../../context/MitreOverviewContext'
import { IconForCard, getFormattedTableData } from './utils'
import {
  MitreCoverageTactic,
  MitreCoverageTechnique,
} from '../../../../../models/DetectionCoverage'
import {
  Icon,
  DesktopTable,
  Typography,
  colors,
} from '../../../../../design-system'

interface MitreTacticsCardsProps {
  carouselTactic: number
}
export default function MitreTacticsCards({
  carouselTactic,
}: MitreTacticsCardsProps) {
  const { coverage } = useMitreOverviewContext()

  /**
   * Auto scroll to the selected technique (if any) when switching from
   * the table view to the list view. No dependencies are given so that
   * this only runs when the component first mounts.
   */
  useEffect(() => {
    document
      .getElementsByClassName('mitre-card-inner active')[0]
      ?.scrollIntoView(false)
  }, [])

  return (
    <div className="mitre-tatic-cards-container">
      {coverage.dw.map((tactic, tacticIndex) => {
        if (carouselTactic !== tacticIndex) {
          return null
        }

        return tactic.techniques.map((technique, techniqueIndex) => (
          <TacticCard
            key={`${tactic.mitreTacticId}-${technique.mitreTechniqueId}`}
            tactic={tactic}
            technique={technique}
            tacticIndex={tacticIndex}
            techniqueIndex={techniqueIndex}
            carouselTactic={carouselTactic}
          />
        ))
      })}
    </div>
  )
}

interface TacticCardProps {
  tactic: MitreCoverageTactic
  technique: MitreCoverageTechnique
  tacticIndex: number
  techniqueIndex: number
  carouselTactic: number
}
function TacticCard({
  tactic,
  technique,
  tacticIndex,
  techniqueIndex,
  carouselTactic,
}: TacticCardProps) {
  const {
    handleSideSheet,
    hasUserClosedSideSheet,
    carouselTechnique,
    resetSelectionState,
    mitreSelection,
    coverage,
    setCarouselTechnique,
    setHasUserClosedSideSheet,
    setMitreSelection,
  } = useMitreOverviewContext()

  const customerTechnique = useMemo(() => {
    const customerTechnique = coverage.customer.find(
      (customerTechnique) =>
        customerTechnique.mitreTechniqueId === technique.mitreTechniqueId,
    )

    return customerTechnique
  }, [coverage, technique])
  const [shouldShowSubtechniques, setShouldShowSubtechniques] = useState(
    /* eslint-disable security/detect-object-injection */
    mitreSelection.technique !== undefined &&
      coverage.dw[carouselTactic].techniques[mitreSelection.technique] &&
      coverage.dw[carouselTactic].techniques[mitreSelection.technique]
        .subtechniques.length > 0,
    /* eslint-enable security/detect-object-injection */
  )

  const getTypographyStyles = () => {
    const fallbackColor = colors.util.navy[100]

    if (!customerTechnique) {
      return fallbackColor
    }

    if (customerTechnique && customerTechnique.totalEnabledUseCaseCount > 0) {
      return colors.util.navy[600]
    } else if (customerTechnique) {
      return colors.util.navy[50]
    }

    return fallbackColor
  }
  const getContainerStyles = () => {
    const addedClasses: string[] = []

    if (technique.totalUseCaseCount === 0) {
      addedClasses.push('not-available')
    } else {
      if (customerTechnique && customerTechnique.totalEnabledUseCaseCount > 0) {
        addedClasses.push('covered')
      } else {
        addedClasses.push('not-covered')
      }
    }

    if (carouselTechnique === technique.mitreTechniqueId) {
      addedClasses.push('active')
    }

    return addedClasses.join(' ')
  }
  const tableData = useMemo(() => {
    if (mitreSelection.technique === undefined) {
      return []
    }

    return getFormattedTableData(technique, customerTechnique)
  }, [mitreSelection, technique, customerTechnique])

  const columns = [
    {
      name: 'id',
      sortable: false,
      visible: false,
    },
    {
      name: 'SUB TECHNIQUE',
      sortable: false,
      visible: true,
    },
    {
      name: 'COVERAGE',
      sortable: false,
      visible: true,
    },
    {
      name: 'DETECTIONS',
      sortable: false,
      visible: true,
    },
    {
      name: 'subtechniqueIndex',
      sortable: false,
      visible: false,
    },
  ]

  /**
   * This method gets called when the technique card header is clicked. When
   * this is called, if the user has not already clicked this card then the side
   * sheet is opened and the subtechnique table is visible. if the user has already
   * clicked the card then we hide the table and close the side sheet
   */
  const handleTechniqueSelect = () => {
    const newVisibleValue = !shouldShowSubtechniques
    setShouldShowSubtechniques(newVisibleValue)
    const hasSubtechniques = technique.subtechniques.length > 0

    if (
      !hasSubtechniques ||
      !hasUserClosedSideSheet ||
      carouselTechnique !== technique.mitreTechniqueId
    ) {
      handleSideSheet(tacticIndex, techniqueIndex, undefined, () => {
        resetSelectionState(technique)
        setShouldShowSubtechniques(false)
        setCarouselTechnique(undefined)
      })
    }

    const shouldOpenWithSubtechniques =
      hasSubtechniques &&
      (!shouldShowSubtechniques ||
        mitreSelection.subtechnique !== undefined ||
        carouselTechnique !== technique.mitreTechniqueId)
    const shouldOpenWithoutSubtechniques =
      mitreSelection.subtechnique !== undefined ||
      carouselTechnique !== technique.mitreTechniqueId
    const shouldOpen =
      shouldOpenWithSubtechniques || shouldOpenWithoutSubtechniques

    setShouldShowSubtechniques(shouldOpenWithSubtechniques)
    setHasUserClosedSideSheet(false)

    setMitreSelection({
      tactic: shouldOpen ? tacticIndex : undefined,
      technique: shouldOpen ? techniqueIndex : undefined,
      subtechnique: undefined,
    })
    setCarouselTechnique(shouldOpen ? technique.mitreTechniqueId : undefined)
  }

  /**
   * Called when a table row is clicked
   *
   * @param ID the ID of the table row
   */
  const handleRowClick = (id: string | number, rowData) => {
    const subtechniqueIndex = rowData.subtechniqueIndex as number
    const sameSubtechnique = mitreSelection.subtechnique === subtechniqueIndex
    if (sameSubtechnique) {
      setMitreSelection({
        tactic: undefined,
        technique: undefined,
        subtechnique: undefined,
      })
    } else {
      setMitreSelection(mitreSelection)
    }

    setHasUserClosedSideSheet(sameSubtechnique)
    handleSideSheet(tacticIndex, techniqueIndex, subtechniqueIndex, () => {
      setMitreSelection({
        ...mitreSelection,
        subtechnique: undefined,
      })
      setHasUserClosedSideSheet(true)
    })
  }

  return (
    <div
      key={`${tactic.mitreTacticId}-${technique.mitreTechniqueId}}`}
      tabIndex={0}
      className={`mitre-card-inner ${getContainerStyles()}`}
      onClick={handleTechniqueSelect}
      onKeyDown={(e) => {
        if (['Enter', 'NumpadEnter'].includes(e.code)) {
          handleTechniqueSelect()
        }
      }}
      role="button"
    >
      {/* Label */}
      <div
        className="flex-container space-between align-items-center"
        style={{ height: '27px' }}
      >
        <Typography
          color={getTypographyStyles()}
          component="div"
          size={12}
          weight={600}
        >
          {`${technique.mitreTechniqueName}${
            technique.subtechniques.length > 0
              ? ` (${technique.subtechniques.length})`
              : ''
          }`}
        </Typography>
        {technique.subtechniques.length > 0 && (
          <Icon
            variant={IconForCard(
              shouldShowSubtechniques,
              carouselTechnique,
              technique.mitreTechniqueId,
            )}
          />
        )}
      </div>
      {/* Table */}
      {shouldShowSubtechniques &&
        carouselTechnique === technique.mitreTechniqueId && (
          <DesktopTable
            data={tableData}
            customHeaders={columns}
            clickableTableRow={true}
            styles={{ width: '100%' }}
            onClick={handleRowClick}
          />
        )}
    </div>
  )
}
