import { useContext, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useLazyQuery, useQuery } from '@apollo/client'

import {
  MobileTable,
  colors,
  Loader,
  Typography,
  DesktopTable,
  Tag,
  Button,
  ButtonProps,
  ToastType,
} from '../../design-system'
import { IconButtonProps } from '../../design-system/interfaces'
import { ReportDetails } from './ReportDetails'
import { DeleteReportModal } from './DeleteReportModal'
import { Message, Report } from '../../models'
import { ErrorFallback } from '../App/Errors'
import {
  FormatStringDateRange,
  getFormattedDate,
} from '../../utils/DateTimeUtils'
import reportsEmptyState from '../../assets/reports-empty-state.png'
import { useWindowDimensions } from '../../hooks'
import { mobileWindowWidth } from '../../constants/constants'
import { deployToastMessage, handleDownload } from '../../utils'
import { Context } from '../App'
import { GenerateReportModal } from './GenerateReportModal'
import useModalContext from '../../hooks/useModalContext'
import {
  DOWNLOAD_REPORT,
  DownloadReportData,
  DownloadReportVariables,
  GET_REPORTS,
  GetReportsData,
  GetReportsVariables,
} from '../../graphql/queries/report'

import './Reports.scss'

type ReportTableData = {
  id: number
  disabled: boolean
  name: { sortValue: string; displayValue: JSX.Element }
  type: { sortValue: string; displayValue: JSX.Element }
  dateRange: { sortValue: string; displayValue: JSX.Element }
  user: { sortValue: string; displayValue: JSX.Element }
  created: JSX.Element | { sortValue: string; displayValue: JSX.Element }
}

const Reports = () => {
  const {
    dispatch,
    state: {
      user: { username },
    },
  } = useContext(Context)

  const { closeModal, openModal: openModalContext } = useModalContext()

  const [isOpen, setIsOpen] = useState(false)
  const [selectedReport, setSelectedReport] = useState<Report>()

  const [searchParams] = useSearchParams()
  const selectedCustomer = searchParams.get('customer')

  const { width } = useWindowDimensions()

  const {
    loading,
    data: { getReports: reports } = { getReports: [] },
    previousData: { getReports: oldReports } = { getReports: [] },
    error,
    startPolling,
    stopPolling,
  } = useQuery<GetReportsData, GetReportsVariables>(GET_REPORTS, {
    variables: {
      selectedCustomer,
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      const result = reports.some((x) => x.status === 'Requested')

      if (result) {
        startPolling(15000)
      } else {
        stopPolling()
      }

      const oldRequestedReports = oldReports.filter(
        (x) => x.status === 'Requested',
      )

      oldRequestedReports.forEach((oldReport) => {
        const match = reports.find(
          (newReport) =>
            oldReport.id === newReport.id &&
            newReport.status !== 'Requested' &&
            newReport.userName === username,
        )

        if (match) {
          const success: Message = {
            id: crypto.randomUUID(),
            text: `"${match.name}" Report has successfully been generated!`,
            messageType: ToastType.SuccessToast,
            secondsToExpire: 5000,
            dismissible: false,
          }

          deployToastMessage(success, dispatch)
        }
      })
    },
  })

  const openGenerateReportModal = () => {
    openModalContext({
      component: <GenerateReportModal closeModal={closeModal} />,
      title: 'Monthly Report',
    })
  }

  const [downloadReport] = useLazyQuery<
    DownloadReportData,
    DownloadReportVariables
  >(DOWNLOAD_REPORT, {
    onCompleted: ({ downloadReport: path }) => {
      handleDownload(path)
    },
  })

  const mapDataForTable = (data: Report[]): ReportTableData[] => {
    if (data.length === 0) {
      return []
    }
    const mappedResult: ReportTableData[] = data
      .filter((report) => report.status !== 'Failed')
      .map((report) => {
        const dateRange = FormatStringDateRange(
          report.startDate,
          report.endDate,
          'MMM dd, yyyy',
        )

        const createdDate = getFormattedDate(report.createdAt, 'P')

        const createdBy = `${report.userName}`

        const isNew: boolean =
          report.numberDownloadAttempt === 0 && report.status === 'Generated'
        const isLoading: boolean = report.status === 'Requested'

        const dateTextColor =
          report.status !== 'Generated'
            ? colors.util.one.light
            : colors.util.one.lighter

        return {
          id: report.id,
          disabled: report.status !== 'Generated',
          name: {
            sortValue: report.name.toLowerCase(),
            displayValue: (
              <div style={{ display: 'flex', alignContent: 'center' }}>
                {
                  <Typography
                    color={
                      report.status !== 'Generated'
                        ? colors.util.one.light
                        : colors.util.one.lighter
                    }
                    styles={{ marginRight: 8 }}
                    size={14}
                    weight={report.status !== 'Generated' ? 400 : 600}
                  >
                    {report.name}
                  </Typography>
                }
                {isNew && (
                  <Tag text="NEW" variant={'small'} active={true}></Tag>
                )}
              </div>
            ),
          },
          type: {
            sortValue: 'EBR',
            displayValue: (
              <Typography
                color={
                  report.status !== 'Generated'
                    ? colors.util.one.light
                    : colors.util.one.lighter
                }
                size={14}
              >
                EBR
              </Typography>
            ),
          },
          dateRange: {
            sortValue: dateRange.toLowerCase(),
            displayValue: (
              <Typography
                color={
                  report.status !== 'Generated'
                    ? colors.util.one.light
                    : colors.util.one.lighter
                }
                size={14}
              >
                {dateRange}
              </Typography>
            ),
          },
          user: {
            sortValue: createdBy.toLowerCase(),
            displayValue: (
              <Typography
                color={
                  report.status !== 'Generated'
                    ? colors.util.one.light
                    : colors.util.one.lighter
                }
                size={14}
              >
                {createdBy}
              </Typography>
            ),
          },
          created: isLoading ? (
            <Loader strokeWidth={2} size={14} color={colors.util.navy[50]} />
          ) : (
            {
              sortValue: report.createdAt,
              displayValue: (
                <Typography color={dateTextColor} size={14}>
                  {createdDate}
                </Typography>
              ),
            }
          ),
        }
      })
    return mappedResult
  }

  const deleteModal = (reportToDelete: Report) => {
    openModalContext({
      component: (
        <DeleteReportModal
          closeModal={closeModal}
          report={reportToDelete}
          downloadReport={downloadReport}
        />
      ),
      title: 'Delete Report',
    })
  }

  const reportsTableButtons: IconButtonProps[] = [
    {
      customOnClick: ({ id }) => {
        const tempReport = reports.find((report) => report.id === id)
        setSelectedReport(tempReport)
        deleteModal(tempReport as Report)
      },
      variant: 'trashOutline',
      label: 'Delete Report',
    },
    {
      customOnClick: (report) => {
        downloadReport({
          variables: {
            input: { reportId: report.id },
            selectedCustomer,
          },
        })
      },
      variant: 'downloadOutline',
      label: 'Download Report',
    },
  ]

  const reportsMobileTableButtons: ButtonProps[] = [
    {
      customOnClick: ({ id }) => {
        const tempReport = reports.find((report) => report.id === id)
        setSelectedReport(tempReport)
        deleteModal(tempReport as Report)
      },
      variant: 'tertiary',
      iconProps: {
        position: 'right',
        variant: 'trashOutline',
      },
      label: 'Delete',
    },
    {
      customOnClick: (report) => {
        downloadReport({
          variables: {
            input: { reportId: report.id },
            selectedCustomer,
          },
        })
      },
      variant: 'tertiary',
      label: 'Download',
      iconProps: {
        position: 'right',
        variant: 'downloadOutline',
      },
    },
  ]

  const TableComponent = (mappedTableData: ReportTableData[]) => {
    if (width <= mobileWindowWidth) {
      return (
        <MobileTable
          captionProps={{
            caption: `${loading ? '--' : mappedTableData.length} ${
              mappedTableData.length > 1 ? 'Reports' : 'Report'
            }`,
            captionButton: (
              <Button
                label="Generate Report"
                onClick={openGenerateReportModal}
              />
            ),
          }}
          customHeaders={[
            { name: 'id', sortable: false, visible: false },
            { name: 'disabled', sortable: false, visible: false },
            { name: 'REPORT NAME', sortable: true, visible: true },
            { name: 'TYPE', sortable: true, visible: true },
            { name: 'DATE RANGE', sortable: true, visible: true },
            { name: 'USER', sortable: true, visible: true },
            { name: 'DATE CREATED', sortable: true, visible: true },
          ]}
          data={mappedTableData}
          sortDirection={'DESC'}
          buttonElements={reportsMobileTableButtons}
          loading={loading && !reports.length}
          onClick={(id) => {
            setSelectedReport(reports.find((report) => report.id === id))
            setIsOpen(true)
          }}
          loadingRows={8}
          sortOptions={{ defaultSortColumn: 6, sortDirection: 'DESC' }}
        />
      )
    } else {
      return (
        <DesktopTable
          clickableTableRow={true}
          captionProps={{
            caption: `${loading ? '--' : mappedTableData.length} ${
              mappedTableData.length > 1 ? 'Reports' : 'Report'
            }`,
            captionButton: (
              <Button
                label="Generate Report"
                onClick={openGenerateReportModal}
              />
            ),
          }}
          customHeaders={[
            { name: 'id', sortable: false, visible: false },
            { name: 'disabled', sortable: false, visible: false },
            { name: 'REPORT NAME', sortable: true, visible: true },
            { name: 'TYPE', sortable: true, visible: false },
            { name: 'DATE RANGE', sortable: true, visible: true },
            { name: 'USER', sortable: true, visible: true },
            { name: 'DATE CREATED', sortable: true, visible: true },
          ]}
          data={mappedTableData}
          sortOptions={{ defaultSortColumn: 6, sortDirection: 'DESC' }}
          rowHoverElements={reportsTableButtons}
          loading={loading && !reports.length}
          onClick={(id) => {
            setSelectedReport(reports.find((report) => report.id === id))
            setIsOpen(true)
          }}
          loadingRows={8}
        />
      )
    }
  }

  const returnReportsTable = () => {
    if (error) {
      return <ErrorFallback />
    } else if (!loading && reports.length === 0) {
      return (
        <div className="empty-state">
          <div>
            <img
              data-testid="report-empty-state"
              src={reportsEmptyState}
              alt="Report Empty State"
              className="image"
            />
          </div>
          <Typography variant="text8" color={colors.util.navy[50]}>
            No generated reports
          </Typography>
          <div
            className="generate-button"
            data-testid="reportsGenerateReportButton"
          >
            <Button
              onClick={() => openGenerateReportModal()}
              label="Generate Report"
            />
          </div>
        </div>
      )
    }

    const mappedTableData = mapDataForTable(reports)

    return (
      <div className="reports">
        <div className="reports-container">
          <div data-testid="reportsTable">
            {TableComponent(mappedTableData)}
          </div>
        </div>
      </div>
    )
  }

  return (
    <article
      id="reports-page"
      className="content"
      data-testid="reports"
      style={
        !loading && reports.length === 0 ? { justifyContent: 'center' } : {}
      }
    >
      {returnReportsTable()}

      <ReportDetails
        data-testid="reportsDetails"
        isOpen={isOpen}
        closeSideSheet={() => setIsOpen(false)}
        setIsOpen={setIsOpen}
        deleteReportCallback={() => deleteModal(selectedReport as Report)}
        selectedReport={selectedReport}
        selectedCustomer={selectedCustomer}
      />
    </article>
  )
}

export default Reports
