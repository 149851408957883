import { useQuery } from '@apollo/client'
import * as yup from 'yup'
import { Box, Skeleton, Typography } from '@mui/material'
import { useSearchParams } from 'react-router-dom'

import {
  GET_CUSTOMER_INFO_WITH_FULL_DETAILS,
  CustomerInfoData,
  CustomerInfoVariables,
} from '@queries/customer'
import { CustomerAddress } from '@models/Customer'
import fetchErrorIcon from '@app-assets/fetch-error.svg'
import { ComponentError } from '@common/ComponentError'
import Icon from '@common/Icon'
import { useCustomer } from '@hooks/useCustomer'

import Leadership from './Leadership/Leadership'
import MyDeepwatchExperts from './MySquad/MySquad'
import MyTeam from './MyTeam/MyTeam'

export const groupEmployeeCount = (totalEmployees: number): string => {
  if (totalEmployees < 0) return '0'
  if (totalEmployees <= 1000) return '0-1,000'
  if (totalEmployees <= 5000) return '1,001-5,000'
  if (totalEmployees <= 10000) return '5,001-10,000'
  return '10,000+'
}

const CompanyOverview = () => {
  const [searchParams] = useSearchParams()
  const selectedCustomer = searchParams.get('customer')
  const { isDWEmployee } = useCustomer()
  const { loading, data, error } = useQuery<
    CustomerInfoData,
    CustomerInfoVariables
  >(GET_CUSTOMER_INFO_WITH_FULL_DETAILS, {
    variables: { selectedCustomer },
  })

  const companyInfo = data?.customerInfo

  if (error) {
    return (
      <ComponentError
        errorSubText="Check your connection and reload the page. If the problem persists, contact our support team for assistance."
        includeReloadButton={true}
        errorIcon={fetchErrorIcon}
      />
    )
  }

  return (
    <Box
      id="company-overview-page"
      data-testid="company-overview"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        padding: '2rem 3rem',
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <Box
          data-testid="company-name-title"
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            gap: '0.25rem',
          }}
        >
          {loading ? (
            <>
              <Skeleton
                sx={{ minHeight: '34px', width: '70%' }}
                data-testid="skeleton-block"
              />
              <Skeleton
                sx={{ minHeight: '20px', width: '30%' }}
                data-testid="skeleton-block"
              />
            </>
          ) : (
            <>
              <Typography
                fontWeight={600}
                variant="h4"
                sx={(theme) => ({
                  color: theme.vars.palette.text.primary,
                  ...theme.applyStyles('dark', {
                    color: theme.vars.palette.text.secondary,
                  }),
                })}
              >
                {companyInfo?.customerName}
              </Typography>
              <Typography color="textPrimary" variant="body1" fontWeight={500}>
                {companyInfo?.industry}
              </Typography>
            </>
          )}
        </Box>
        <Box
          data-testid="company-information"
          sx={{
            display: 'flex',
            gap: '0.5rem',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {loading ? (
            <>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Skeleton
                  sx={{ minHeight: '20px', width: '40px' }}
                  data-testid="skeleton-block"
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Skeleton
                  sx={{ minHeight: '20px', width: '120px' }}
                  data-testid="skeleton-block"
                />
              </Box>
            </>
          ) : (
            <>
              {companyInfo?.numOfEmployees && (
                <>
                  <Icon
                    sx={(theme) => ({
                      color: theme.vars.palette.text.primary,
                      ...theme.applyStyles('dark', {
                        color: theme.vars.palette.text.secondary,
                      }),
                    })}
                    variant="people"
                  />
                  <Typography
                    fontWeight={500}
                    sx={(theme) => ({
                      paddingTop: '3px',
                      marginRight: '40px',
                      color: theme.vars.palette.text.primary,
                      ...theme.applyStyles('dark', {
                        color: theme.vars.palette.text.secondary,
                      }),
                    })}
                    variant="body2"
                  >
                    {groupEmployeeCount(companyInfo?.numOfEmployees)}
                  </Typography>
                </>
              )}
              {companyInfo?.address && isAddressValid(companyInfo?.address) && (
                <>
                  <Icon
                    variant="locationOutline"
                    sx={(theme) => ({
                      color: theme.vars.palette.text.primary,
                      ...theme.applyStyles('dark', {
                        color: theme.vars.palette.text.secondary,
                      }),
                    })}
                  />
                  <Typography
                    fontWeight={500}
                    sx={(theme) => ({
                      paddingTop: '3px',
                      color: theme.vars.palette.text.primary,
                      ...theme.applyStyles('dark', {
                        color: theme.vars.palette.text.secondary,
                      }),
                    })}
                    variant="body2"
                  >
                    {`${companyInfo?.address?.street}`}
                    <br />
                    {`${companyInfo?.address?.city}, ${companyInfo?.address?.state} ${companyInfo?.address?.postalCode}`}
                  </Typography>
                </>
              )}
            </>
          )}
        </Box>
      </Box>
      <Box
        component="hr"
        sx={(theme) => ({
          border: `solid 1px ${theme.vars.palette.secondary.light}`,
          marginTop: '2rem',
          marginBottom: '2rem',
        })}
      />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '3rem',
          width: '100%',
        }}
      >
        <Leadership />
        <MyDeepwatchExperts />
        {isDWEmployee ? null : <MyTeam />}
      </Box>
    </Box>
  )
}

const ADDRESS_SCHEMA = yup.object({
  street: yup.string().required(),
  city: yup.string().required(),
  state: yup.string().required(),
  postalCode: yup.string().required(),
})

export const isAddressValid = (address: CustomerAddress): boolean =>
  ADDRESS_SCHEMA.isValidSync(address)

export default CompanyOverview
