import { gql } from '@apollo/client'

export const GET_DASHBOARD = gql`
  query GetDashboard(
    $selectedCustomer: String
    $startDate: AWSDate
    $endDate: AWSDate
  ) {
    getDashboard(
      selectedCustomer: $selectedCustomer
      input: { startDate: $startDate, endDate: $endDate }
    ) {
      highlightMetrics {
        delta
        key
        metric
      }
      links {
        servicenow
        splunk
        threatLabs
      }
      logSourceUsage {
        alertCount
        source
        usageGb
      }
      maturityTrend {
        customer {
          date
          score
        }
        deepwatch {
          date
          score
        }
      }
      openTicketTrend {
        date
        openCriticalCount
        openHighCount
        openLowCount
        openModerateCount
      }
      threatSummary {
        alertCount
        closedCriticalTicketCount
        closedTicketCount
        openCriticalTicketCount
        openTicketCount
        totalTicketCount
        loggedEventCount
      }
    }
  }
`
