import { Link } from '@mui/material'
import { useState } from 'react'

import { zIndex } from '@components/App/Styles/zIndex'

const SkipLink = ({ text }) => {
  const [linkStyles, setLinkStyles] = useState({
    transform: 'translateY(-100%)',
  })

  return (
    <Link
      href="#main"
      sx={[
        (theme) => ({
          position: 'absolute',
          left: '50%',
          color: theme.vars.palette.text.primary,
          padding: '8px',
          zIndex: zIndex.SKIP_LINK,
        }),
        linkStyles,
      ]}
      tabIndex={0}
      onFocus={() =>
        setLinkStyles({
          transform: 'translateY(0)',
        })
      }
      onBlur={() =>
        setLinkStyles({
          transform: 'translateY(-100%)',
        })
      }
    >
      {text}
    </Link>
  )
}

export default SkipLink
