/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import { CSSProperties } from 'react'

import { useTheme } from '../../../hooks'
import { colors } from '../../../theme'
import { ColumnHeader } from '../DesktopTable.types'
import SkeletonBlock from '../../SkeletonBlock/SkeletonBlock'
import { IconButtonProps } from '../../../interfaces'

import './TableCell.scss'

export interface TableCellProps {
  tableHeaders: ColumnHeader[]
  keyIndex: number
  tableCellValue: any
  bodyTextColor: string
  rowHoverElements?: IconButtonProps[]
  disabledCell: boolean
  loading: boolean
}

const TableCell = ({
  tableHeaders,
  tableCellValue,
  keyIndex,
  bodyTextColor,
  disabledCell,
  loading = true,
}: TableCellProps): JSX.Element => {
  const isDarkTheme = useTheme('dark')

  const tableCellStateStyles = (
    cellValue: any,
    disabled: boolean,
  ): CSSProperties => {
    if (cellValue === 'Error') {
      return {
        color: colors.util.orange[100],
      }
    } else {
      return {
        color: disabled
          ? colors.util.one.light
          : tableBodyTextColor(bodyTextColor, isDarkTheme),
      }
    }
  }

  return (
    <td
      data-label={tableHeaders.at(keyIndex)?.name?.toUpperCase()}
      key={keyIndex}
      style={tableCellStateStyles(tableCellValue, disabledCell)}
      className="table-td"
      headers={tableHeaders.at(keyIndex)?.name}
    >
      {/* actual table value that is rendered */}
      <div className="table-value">
        {loading ? <SkeletonBlock /> : tableCellValue}
      </div>
    </td>
  )
}

export default TableCell

const tableBodyTextColor = (
  bodyTextColor: string,
  isDarkTheme: boolean,
): string => {
  if (bodyTextColor) {
    return bodyTextColor
  } else if (isDarkTheme) {
    return colors.util.one.lighter
  } else {
    return colors.util.one.main
  }
}
