import { useEffect, useRef, useState } from 'react'

import { Caption } from './Caption'
import { TableHead } from './TableHead'
import { TableRow } from './TableRow'
import { TableCell } from './TableCell'
import {
  SortingOrder,
  TableLoadingState,
  TableProps,
} from './DesktopTable.types'
import TableLoadingRow from './TableLoadingRow/TableLoadingRow'
import {
  addEmptyTableRows,
  changeNullValueToStringError,
  createCustomTableHeaders,
  sortData,
  sortWithSecondaryData,
} from './DesktopTable.utils'
import { useRefOnScreen } from '../../utils/RefUtils'

import './DesktopTable.scss'

const Table = ({
  bodyColor = '',
  bodyTextColor = '',
  captionProps,
  clickableTableRow,
  customHeaders = [],
  data = [],
  headerColor = '',
  headerTextColor = '',
  loading = false,
  loadingRows = 1,
  loadingRowState = TableLoadingState.IDLE,
  onBottomReached,
  onClick,
  rowHoverElements = [],
  sortOptions = {
    isSortedExternally: false,
    defaultSortColumn: -1,
    sortDirection: 'DESC',
    secondarySortDirection: 'ASC',
    secondaryDefaultSortColumn: -1,
  },
  stickyHeaderOffset = 0,
  styles,
}: TableProps): JSX.Element => {
  const tableHeaders = createCustomTableHeaders(
    customHeaders,
    data,
    loadingRows,
  )

  const {
    isSortedExternally,
    defaultSortColumn,
    sortDirection,
    secondarySortDirection,
    secondaryDefaultSortColumn,
  } = sortOptions
  const tableData = changeNullValueToStringError(data)

  const [sortedData, setSortData] = useState(tableData)
  const [sortOrder, setSortOrder] = useState<SortingOrder | undefined>(
    sortDirection,
  )
  const [sortIndex, setSortIndex] = useState(defaultSortColumn)
  const [secondarySortIndex, setSecondarySortIndex] = useState(
    secondaryDefaultSortColumn,
  )

  // if 'onBottomReached' was passed, make observable refs to the container and the row
  const tableRowTriggerRef = useRef<HTMLTableRowElement>(null)

  useRefOnScreen(tableRowTriggerRef, onBottomReached)

  const numVisibleColumns = tableHeaders.filter(
    (columnHeader) => columnHeader.visible,
  ).length

  useEffect(() => {
    if (loading) {
      setSortData(addEmptyTableRows(data, loadingRows))
    } else if (data.length === 0 || isSortedExternally) {
      setSortData(tableData)
    } else if (secondarySortDirection && secondaryDefaultSortColumn) {
      setSortData(
        sortWithSecondaryData(
          data,
          sortIndex ?? -1,
          sortOrder ?? 'DESC',
          secondarySortDirection ?? 'ASC',
          secondarySortIndex ?? -1,
        ),
      )
    } else {
      setSortData(sortData(data, sortIndex ?? -1, sortOrder ?? 'DESC'))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading])

  const handleSorting = (
    sortOrder: SortingOrder,
    index: number,
    secondaryIndex?: number,
    secondarySortOrder?: SortingOrder,
  ): void => {
    setSortOrder(sortOrder)
    setSortIndex(index)
    setSecondarySortIndex(secondaryIndex)
    if (
      index !== -1 &&
      (secondaryDefaultSortColumn === -1 || !secondaryDefaultSortColumn)
    ) {
      setSortData(sortData(data, index, sortOrder))
    }
    if (
      secondaryDefaultSortColumn !== -1 &&
      secondaryDefaultSortColumn !== undefined
    ) {
      setSortData(
        sortWithSecondaryData(
          data,
          index ?? -1,
          sortOrder,
          secondarySortOrder ?? 'ASC',
          secondaryDefaultSortColumn ?? -1,
        ),
      )
    } else {
      setSortData(sortData(data, index, sortOrder))
    }
  }

  const handleRowClick = (id: string | number, rowData: any) => {
    onClick?.(id, rowData)
  }

  let tempSortIndex = sortIndex ?? -1

  if (isSortedExternally) {
    tempSortIndex = defaultSortColumn ?? -1
  }

  return (
    <div
      className={`datatable ${
        stickyHeaderOffset > 0 ? 'datatable-sticky' : ''
      }`}
      style={{
        ...styles,
        ...(stickyHeaderOffset > 0 && {
          maxHeight: `calc(100vh - ${stickyHeaderOffset + 'px'})`,
        }),
      }}
    >
      <table className="dw-table">
        <Caption
          caption={captionProps?.caption}
          captionButton={captionProps?.captionButton}
          captionSide={captionProps?.captionSide}
        />
        <TableHead
          tableHeaders={tableHeaders}
          bodyColor={bodyColor}
          headerBodyColor={headerColor}
          headerTextColor={headerTextColor}
          sortTableColumns={handleSorting}
          sortDir={sortDirection ?? 'DESC'}
          sortIndex={tempSortIndex}
          secondarySortIndex={secondarySortIndex}
          secondarySortDirection={secondarySortDirection}
        />
        <tbody>
          {sortedData &&
            sortedData.length > 0 &&
            sortedData.map(
              (tableData: Record<string, any>, index: number): JSX.Element => {
                return (
                  <TableRow
                    key={index}
                    bodyColor={bodyColor}
                    index={index}
                    tableRowData={tableData}
                    loading={loading}
                    clickableTableRow={clickableTableRow}
                    rowClick={handleRowClick}
                    rowHoverElements={rowHoverElements}
                    tableHeaders={tableHeaders}
                    ref={
                      sortedData.length === index + 1
                        ? tableRowTriggerRef
                        : undefined
                    }
                  >
                    {Object.keys(tableData).map((key, keyIndex) => {
                      return (
                        key !== 'disabled' &&
                        tableHeaders.at(keyIndex)?.visible && (
                          <TableCell
                            key={keyIndex}
                            tableHeaders={tableHeaders}
                            tableCellValue={
                              tableData[`${key}`]?.displayValue !== undefined
                                ? tableData[`${key}`]?.displayValue
                                : tableData[`${key}`]
                            }
                            disabledCell={tableData?.disabled}
                            keyIndex={keyIndex}
                            bodyTextColor={bodyTextColor}
                            rowHoverElements={rowHoverElements}
                            loading={loading}
                          />
                        )
                      )
                    })}
                  </TableRow>
                )
              },
            )}
          {loading !== true &&
            onBottomReached &&
            loadingRowState !== TableLoadingState.IDLE && (
              <TableLoadingRow
                columnSpan={numVisibleColumns}
                loadingState={loadingRowState}
              />
            )}
          {sortedData.length === 0 && (
            <tr className="table-row table-row-empty">
              <td className="table-td" colSpan={tableHeaders.length}>
                Error: There is no data for this table.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

export default Table
