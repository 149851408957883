import { IconButtonProps } from '../../../interfaces'
import { IconButton } from '../../IconButton'

import './RowHoverElements.scss'

export interface RowHoverElementProps {
  elements: IconButtonProps[]
  elementValue: Record<string, any>
  elementClasses: string
  updateClasses: () => void
}

const RowHoverElement = ({
  elements,
  elementValue,
  elementClasses,
  updateClasses,
}: RowHoverElementProps): JSX.Element => {
  return (
    <>
      {elements.length > 0 && (
        <td className={`row-hover-buttons${elementClasses}`}>
          <div className="gradient-clip"></div>
          <div
            className="row-hover-buttons-container"
            data-testid="row-hover-buttons"
            onBlur={(event) => {
              // requires label property in elements prop since that is used as value for "name" attribute on button element
              const buttonName = event.target.getAttribute('name')
              if (buttonName) {
                const lastElement = elements[elements.length - 1]
                if (buttonName === lastElement.label) {
                  updateClasses()
                }
              }
            }}
          >
            {elements.map((iconButtonPropValue, index) => {
              return (
                <IconButton
                  key={index}
                  {...iconButtonPropValue}
                  onClick={(e) => {
                    e.stopPropagation()
                    iconButtonPropValue.onClick?.(e)
                    iconButtonPropValue.customOnClick?.(elementValue)
                  }}
                />
              )
            })}
          </div>
        </td>
      )}
    </>
  )
}

export default RowHoverElement
