import { colors, Loader, Tooltip, Typography } from '../../../../design-system'
import { MitreOverviewValues } from '../utils'
import MitreAlertChart from './MitreCoverageChart'
import { DetectionCoverageOverviewData } from '../../../../models'
import TopLogSources from './TopLogSources'

interface MitreOverviewProps {
  mitreOverviewData: MitreOverviewValues | undefined
  detectionCoverageOverviewData: DetectionCoverageOverviewData
}
export default function MitreOverview({
  mitreOverviewData,
  detectionCoverageOverviewData,
}: MitreOverviewProps) {
  return (
    <aside id="mitre-overview">
      <div
        className="flex-container column gap-24"
        data-testid="detection-coverage-overview-sidebar"
      >
        {/* Header */}
        <Typography component="div" variant="text9semibold">
          MITRE overview
        </Typography>
        {/* Data Labels */}
        <MitreOverviewDataLabel
          isLoading={!mitreOverviewData}
          label="MITRE Tactics covered"
          value={mitreOverviewData?.customerTacticsData}
          compareValue={mitreOverviewData?.deepWatchTacticsData}
          tooltipContent={
            <>
              You have coverage on {mitreOverviewData?.customerTacticsData}{' '}
              MITRE tactics
            </>
          }
        />
        <MitreOverviewDataLabel
          isLoading={!mitreOverviewData}
          label="MITRE Techniques covered"
          value={mitreOverviewData?.mitreCustomerTechniqueData}
          compareValue={mitreOverviewData?.mitreDeepWatchTechniqueData}
          tooltipContent={
            <>
              You have coverage on{' '}
              {mitreOverviewData?.mitreCustomerTechniqueData} MITRE techniques
            </>
          }
        />
        {/* Charts */}
        <MitreAlertChart data={detectionCoverageOverviewData} />
        {!mitreOverviewData || !detectionCoverageOverviewData ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
            }}
          >
            <Loader strokeWidth={2} size={50} />
          </div>
        ) : (
          <>
            <TopLogSources
              title="Top 5 log sources by utilization"
              data={mitreOverviewData?.logSourceByUtilization}
            />
            {mitreOverviewData?.logSourceByAlert && (
              <TopLogSources
                title="Top 5 log sources by alerts"
                data={mitreOverviewData.logSourceByAlert}
              />
            )}
          </>
        )}
      </div>
    </aside>
  )
}

interface MitreOverviewDataLabelProps {
  label: string
  value: number | undefined
  compareValue: number | undefined
  isLoading: boolean
  tooltipContent: JSX.Element
}
function MitreOverviewDataLabel({
  label,
  value,
  compareValue,
  isLoading,
  tooltipContent,
}: MitreOverviewDataLabelProps) {
  const component = (
    <div className="flex-container space-between" tabIndex={0} role={'group'}>
      <Typography
        component="div"
        color={colors.util.navy[50]}
        variant="text11"
        size={14}
      >
        {label}
      </Typography>
      <Typography color={colors.util.navy[50]} component="span" size={14}>
        <>
          {isLoading || value === undefined ? '--' : value}/
          <span style={{ color: colors.util.navy[100] }}>
            {isLoading || compareValue === undefined ? '--' : compareValue}
          </span>
        </>
      </Typography>
    </div>
  )

  if (isLoading) {
    return component
  }

  return (
    <Tooltip target={component}>
      <span>{tooltipContent}</span>
    </Tooltip>
  )
}
