import {
  ReactNode,
  RefObject,
  createContext,
  useContext,
  useState,
  MouseEvent,
  FocusEvent,
  createRef,
} from 'react'

import { DetectionCoverageDisplayValues } from '../utils'
import {
  DetectionCoverageOverviewData,
  MitreCoverageTechnique,
} from '../../../../models/DetectionCoverage'
import { SideSheetProps } from '../../../SideSheet'
import MitreSideSheetContent from '../MitreSideSheetContent/MitreSideSheetContent'
import { Icon, colors } from '../../../../design-system'

type OverviewSideSheetStateProps = Omit<SideSheetProps, 'open'> & {
  id: string
}
export interface MitreSelection {
  tactic: number | undefined
  technique: number | undefined
  subtechnique: number | undefined
}
type HandleSideSheetFunction = (
  tacticIndex: number,
  techniqueIndex?: number,
  subtechniqueIndex?: number,
  closeSideSheetCallback?: () => void,
) => void
type UpdateOrCloseSideSheetFunction = (
  newSideSheet: OverviewSideSheetStateProps,
) => void
type ResetSelectionState = (technique: MitreCoverageTechnique) => void
type HandleTooltipEnter = (
  event: MouseEvent<HTMLElement> | FocusEvent<HTMLElement>,
  gap: number,
  innerHTML: string,
) => void
export type Coverage = NonNullable<DetectionCoverageDisplayValues['coverage']>
export interface MitreOverviewContextModel {
  coverage: Coverage
  mitreSelection: MitreSelection
  carouselTechnique: string | undefined
  hasUserClosedSideSheet: boolean
  isSideSheetOpen: boolean
  sideSheetData: OverviewSideSheetStateProps
  resetSelectionState: ResetSelectionState
  handleSideSheet: HandleSideSheetFunction
  updateOrCloseSideSheet: UpdateOrCloseSideSheetFunction
  detectionCoverageOverview: DetectionCoverageOverviewData | undefined
  setCarouselTechnique(value?: string): void
  setHasUserClosedSideSheet(value: boolean): void
  setMitreSelection(mitreSelection: MitreSelection): void
  setCoverage(
    value: NonNullable<DetectionCoverageDisplayValues['coverage']>,
  ): void
  setDetectionCoverageOverview(value: DetectionCoverageOverviewData): void
  setIsSideSheetOpen(value: boolean): void
  tooltipRef: RefObject<HTMLDivElement>
  handleTooltipEnter: HandleTooltipEnter
  handleTooltipLeave(): void
}

export const initialMitreOverviewContext: MitreOverviewContextModel = {
  coverage: {
    customer: [],
    dw: [],
  },
  carouselTechnique: undefined,
  hasUserClosedSideSheet: false,
  mitreSelection: {
    tactic: undefined,
    technique: undefined,
    subtechnique: undefined,
  },
  tooltipRef: {
    current: null,
  },
  detectionCoverageOverview: undefined,
  isSideSheetOpen: false,
  sideSheetData: {
    id: '',
    children: null,
  },
  /* eslint-disable @typescript-eslint/no-empty-function */
  handleSideSheet: () => {},
  setCarouselTechnique: () => {},
  resetSelectionState: () => {},
  setHasUserClosedSideSheet: () => {},
  setMitreSelection: () => {},
  setCoverage: () => {},
  handleTooltipEnter: () => {},
  handleTooltipLeave: () => {},
  setDetectionCoverageOverview: () => {},
  updateOrCloseSideSheet: () => {},
  setIsSideSheetOpen: () => {},
  /* eslint-enable @typescript-eslint/no-empty-function */
}

export const MitreOverviewContext = createContext<MitreOverviewContextModel>(
  initialMitreOverviewContext,
)

export const MitreOverviewContextProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const tooltipRef = createRef<HTMLDivElement>()
  const [coverage, setCoverage] = useState<
    MitreOverviewContextModel['coverage']
  >(initialMitreOverviewContext.coverage)

  /**
   * The carouselTechniqueState is used to determine which MITRE
   * technique has been selected via MITRE technique ID as it is
   * the unique identifier for a given technique; it is used separately
   * from mitreSelectionState
   */
  const [carouselTechnique, setCarouselTechnique] = useState<
    MitreOverviewContextModel['carouselTechnique']
  >(initialMitreOverviewContext.carouselTechnique)

  /**
   * tracks when a user closes a sidesheet;
   * used for application flow logic
   */
  const [hasUserClosedSideSheet, setHasUserClosedSideSheet] = useState<
    MitreOverviewContextModel['hasUserClosedSideSheet']
  >(initialMitreOverviewContext.hasUserClosedSideSheet)

  const [mitreSelection, setMitreSelection] = useState<
    MitreOverviewContextModel['mitreSelection']
  >(initialMitreOverviewContext.mitreSelection)

  const [isSideSheetOpen, setIsSideSheetOpen] = useState(
    initialMitreOverviewContext.isSideSheetOpen,
  )
  const [sideSheetData, setSideSheetData] =
    useState<OverviewSideSheetStateProps>(
      initialMitreOverviewContext.sideSheetData,
    )

  const [detectionCoverageOverview, setDetectionCoverageOverview] =
    useState<MitreOverviewContextModel['detectionCoverageOverview']>()

  /**
   * This methods gets called when the selection state should be reset to only
   * selecting a given technique
   * @param technique
   */
  const resetSelectionState: ResetSelectionState = (technique) => {
    if (technique.subtechniques.length === 0) {
      setCarouselTechnique(undefined)
    }
    setMitreSelection({
      technique: undefined,
      tactic: undefined,
      subtechnique: mitreSelection.subtechnique,
    })
    setHasUserClosedSideSheet(true)
  }

  /**
   * closes side sheet if the new sideSheet object has the same
   * id prop value to implement closing the sidesheet when the
   * user selects the same ban/tactic/technique/subtechnique
   */
  const updateOrCloseSideSheet: UpdateOrCloseSideSheetFunction = (
    newSideSheet,
  ) => {
    let open = true
    if (isSideSheetOpen && newSideSheet.id === sideSheetData.id) {
      open = false
    }

    setSideSheetData(newSideSheet)
    setIsSideSheetOpen(open)
  }

  /**
   * This method will generate the side sheet footer using the given mitre selections
   * @param tacticIndex
   * @param techniqueIndex
   * @param subtechniqueIndex
   * @returns The footer component
   */
  const getSideSheetFooter = (
    tacticIndex: number,
    techniqueIndex?: number,
    subtechniqueIndex?: number,
  ): ReactNode => {
    // eslint-disable-next-line security/detect-object-injection
    const tactic = detectionCoverageOverview?.mitreCoverage[tacticIndex]

    let type = 'tactic'
    let baseUrl = `https://attack.mitre.org/${type}s/`
    let href = `${baseUrl}${tactic?.mitreTacticId}/`

    if (techniqueIndex !== undefined) {
      type = 'technique'
      baseUrl = `https://attack.mitre.org/${type}s/`
      // eslint-disable-next-line security/detect-object-injection
      href = `${baseUrl}${tactic?.techniques[techniqueIndex].mitreTechniqueId}/`
      if (subtechniqueIndex !== undefined) {
        type = 'subtechnique'
        // eslint-disable-next-line security/detect-object-injection
        href = `${baseUrl}${tactic?.techniques[techniqueIndex].subtechniques[
          subtechniqueIndex
        ].mitreSubtechniqueId.replace('.', '/')}/`
      }
    }
    return (
      <a
        className="mitre-link"
        href={href}
        target={'_blank'}
        rel="external noreferrer"
      >
        View {type} details
        <Icon
          variant={'openOutline'}
          style={{ paddingLeft: 8, color: colors.common.trueblack }}
        />
      </a>
    )
  }

  /**
   * This method will construct the side sheet using the given mitre selection. Depending on what values are set
   * it will determine which title to use and call the methods to generate the content for the side sheet
   *
   * @param tactic The selected tactic
   * @param technique The selected technique
   * @param subtechnique The selected subtechnique
   * @param closeSideSheetCallback Callback that gets called when the side sheet is closed
   */
  const handleSideSheet: HandleSideSheetFunction = (
    tacticIndex,
    techniqueIndex,
    subtechniqueIndex,
    closeSideSheetCallback,
  ) => {
    let title = 'Tactic detail'

    if (subtechniqueIndex !== undefined) {
      title = 'Subtechnique detail'
    } else if (techniqueIndex !== undefined) {
      title = 'Technique detail'
    }

    // eslint-disable-next-line security/detect-object-injection
    const tactic = coverage?.dw[tacticIndex]

    const customerTacticTechniques = tactic
      ? coverage?.customer.filter(
          (customerTechnique) =>
            customerTechnique.mitreTacticId === tactic?.mitreTacticId,
        )
      : []

    updateOrCloseSideSheet({
      id: `${tacticIndex}-${techniqueIndex}-${subtechniqueIndex}`,
      title: title,
      children: (
        <MitreSideSheetContent
          tactic={tactic}
          customerTacticTechniques={customerTacticTechniques}
          techniqueIndex={techniqueIndex}
          subTechniqueIndex={subtechniqueIndex}
        />
      ),
      footer: getSideSheetFooter(
        tacticIndex,
        techniqueIndex,
        subtechniqueIndex,
      ),
      closeSideSheet: () => {
        setIsSideSheetOpen(false)
        closeSideSheetCallback?.()
      },
    })
  }

  /**
   * This function can be called when the tooltip should be opened
   * @param event The mouse event that opened the tooltip. This is used to
   * calculate the offsets
   * @param gap The gap between the top of the container the the tooltip. This is used
   * with offsetTop and offsetHeight to calculate where the tooltip should be
   * @param innerHTML The content of the tooltip
   */
  const handleTooltipEnter: HandleTooltipEnter = (event, gap, innerHTML) => {
    if (tooltipRef.current) {
      tooltipRef.current.innerHTML = innerHTML
      tooltipRef.current.style.display = 'flex'
      tooltipRef.current.style.top = `${
        event.currentTarget.offsetTop - tooltipRef.current.offsetHeight - gap
      }px`
      tooltipRef.current.style.left = `${event.currentTarget.offsetLeft}px`
    }
  }

  /**
   * This function can be called when the tooltip should be set to closwed
   */
  const handleTooltipLeave = () => {
    if (tooltipRef.current) {
      tooltipRef.current.style.display = 'none'
    }
  }

  return (
    <MitreOverviewContext.Provider
      value={{
        coverage,
        carouselTechnique,
        hasUserClosedSideSheet,
        mitreSelection,
        handleSideSheet,
        tooltipRef,
        detectionCoverageOverview,
        isSideSheetOpen,
        sideSheetData,
        resetSelectionState,
        setCarouselTechnique,
        setHasUserClosedSideSheet,
        setMitreSelection,
        setCoverage,
        handleTooltipEnter,
        handleTooltipLeave,
        setDetectionCoverageOverview,
        updateOrCloseSideSheet,
        setIsSideSheetOpen,
      }}
    >
      {children}
    </MitreOverviewContext.Provider>
  )
}

export const useMitreOverviewContext = () => useContext(MitreOverviewContext)
