import { useState } from 'react'
import { format as formatDate, parseISO, intervalToDuration } from 'date-fns/fp'

export const getTimezoneRemovedDate = (date: string): Date => {
  const formattedDate = new Date(date)
  const dateOnly = new Date(
    formattedDate.valueOf() + formattedDate.getTimezoneOffset() * 60 * 1000,
  )

  return dateOnly
}

export const toTimeUnits = (seconds: number) => {
  if (seconds <= 0) {
    return '0 seconds'
  }
  const duration = intervalToDuration({ start: 0, end: seconds * 1000 })

  if (!duration) {
    return ''
  }

  const timeUnits = Object.entries(duration).map(([unit, value]) => ({
    unit,
    value,
  }))

  const filteredUnits = timeUnits.filter(({ value }) => value >= 1)

  if (filteredUnits.length === 1) {
    const unit = filteredUnits[0]
    const suffix =
      unit.value === 1 ? unit.unit.slice(0, unit.unit.length - 1) : unit.unit
    return `${unit.value} ${suffix}`
  }

  const largestUnit = filteredUnits[0]
  const secondLargestUnit = filteredUnits[1]

  const firstUnit = `${largestUnit.value}${largestUnit.unit[0]}`
  const secondUnit = `${secondLargestUnit.value}${secondLargestUnit.unit[0]}`

  return `${firstUnit} ${secondUnit}`
}

export interface DateTimeProps {
  date?: Date
  locale?: string
  options: Intl.DateTimeFormatOptions
}

export interface UseLocalTimeZoneProps {
  dateTime: string
  setDateTime: () => void
}

const initialState: DateTimeProps = {
  date: new Date(),
  locale: Intl.DateTimeFormat().resolvedOptions().locale,
  options: {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZoneName: 'short',
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  },
}

export const useLocalTimeZone = (): [
  string,
  (dateTimeOptions: DateTimeProps) => void,
] => {
  const [timeZone, setTimeZone] = useState<DateTimeProps>(initialState)

  const setDateTime = (dateTimeOptions: DateTimeProps) => {
    setTimeZone({
      date: dateTimeOptions?.date,
      locale: dateTimeOptions.locale,
      options: {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        timeZoneName: 'short',
        timeZone: dateTimeOptions.options?.timeZone,
      },
    })
  }

  const newTime = new Intl.DateTimeFormat(
    timeZone?.locale,
    timeZone?.options,
  ).format(timeZone?.date)

  return [newTime, setDateTime]
}

export const FormatStringDateRange = (
  startDate: string,
  endDate: string,
  format: string,
): string => {
  const formattedStartDate = getFormattedDate(startDate, format)
  const formattedEndDate = getFormattedDate(endDate, format)
  return `${formattedStartDate} - ${formattedEndDate}`
}

export const getFormattedDate = (date: string, format: string) => {
  let result = ''
  try {
    result = formatDate(
      format,
      parseISO(date.indexOf('T') > -1 ? date.split('T')[0] : date),
    )
  } catch (error) {
    result = ''
  }
  return result
}
