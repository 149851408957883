import { useEffect } from 'react'

const useKeyDown = (
  key: KeyboardEvent['key'],
  callback?: VoidFunction,
  enabled = true,
) => {
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      e.key === key && callback?.()
    }

    if (enabled) {
      document.addEventListener('keydown', handleKeyDown)

      return () => {
        document.removeEventListener('keydown', handleKeyDown)
      }
    }
  }, [callback, enabled, key])
}

export default useKeyDown
