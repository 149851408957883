/* eslint-disable no-console */
import { GraphQLFormattedError as GQLError } from 'graphql'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { ApolloLink } from '@apollo/client'

import { AppEventEmitter } from '@utils/EventEmitter'
import { getAccessToken, getIdToken } from '@utils/GetTokens'
import { AlertSeverity } from '@hooks/useToast'

interface GraphQLError extends GQLError {
  errorType?: unknown
}

export const awsAuthLink = setContext((_, { headers }) => {
  const token = getIdToken()
  onError(({ graphQLErrors, networkError, operation }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(
        ({ message, locations, path, errorType, extensions }: GraphQLError) => {
          const errorMessage = message.toLowerCase()

          let errorMessageToDisplay
          if (errorMessage.search(/(token).+(expired)/) !== -1) {
            errorMessageToDisplay = 'inactivity'
          } else if (
            errorMessage.search(/(not).+(authorized).+(access)/) !== -1
          ) {
            errorMessageToDisplay = 'unauthorized access'
          } else {
            errorMessageToDisplay = 'an app error'
          }

          if (
            errorType === 'Unauthorized' ||
            errorMessageToDisplay === 'inactivity' ||
            errorMessageToDisplay === 'unauthorized access'
          ) {
            AppEventEmitter.emit('appSyncClientError', {
              message: `[AppSync Error] : ${message} + ${JSON.stringify(
                locations,
              )} + ${path}`,
              code: 401,
              errorType,
              messageType: AlertSeverity.Error,
              extensions,
              text: `You have been logged out due to ${errorMessageToDisplay}`,
            })
          } else {
            AppEventEmitter.emit('appSyncClientError', {
              message: `[AppSync Error] : ${message} + ${JSON.stringify(
                locations,
              )} + ${path}`,
              errorType,
              code: 400,
              messageType: AlertSeverity.Error,
              extensions,
              text: 'Bad request',
            })
          }
        },
      )
      console.log('graphQLErrors: ', graphQLErrors)
    }

    if (networkError) {
      AppEventEmitter.emit('appSyncClientError', {
        message: `[Network Error][${operation.operationName}]: ${networkError}`,
        messageType: AlertSeverity.Error,
        code: 500,
      })
      console.log(networkError + `${JSON.stringify(operation)}`)
    }
  })
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

export const headerMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers }) => ({
    headers: {
      ...headers,
      'x-access-token': getAccessToken(),
      'content-encoding': 'gzip',
    },
  }))

  return forward(operation)
})
