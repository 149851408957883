import { MaturityScoreCoordinates } from './MaturityScore.models'
import { DashboardMaturity } from '../../../../../models/Dashboard'

export const formatFutureDate = (d: string): string => {
  const start = new Date(d)
  const futureDate = start.setDate(start.getDate() + 1)
  const finalDate = new Date(futureDate).toISOString().split('T')[0]
  return finalDate
}

export const createTrendData = (arr: MaturityScoreCoordinates[]) => {
  const maturityArrayLength = arr.length

  const lastScore = arr[arr.length - 1].y
  const lastDateArr = arr[arr.length - 1].x

  const arr2: MaturityScoreCoordinates[] = []

  for (let i = 0; i < maturityArrayLength - maturityArrayLength * 0.7; i++) {
    const curr = arr[`${i}`].y
    const next = arr[i + 1].y
    const diff = next - curr
    if (i === 0) {
      arr2.push({
        x: lastDateArr,
        y: lastScore,
      })
    } else {
      arr2.push({
        x: formatFutureDate(arr2[i - 1].x),
        y: arr[`${i}`].y + diff,
      })
    }
  }

  return arr2
}

export const calculateAvgScore = (arr: MaturityScoreCoordinates[]): number => {
  const avg = arr.reduce((acc, score) => {
    return acc + score.y / arr.length
  }, 0)
  return Number(avg.toFixed(2))
}

export const calculateScoreDiff = (avgOne: number, avgTwo: number): number => {
  const scoreDiff = avgOne / avgTwo
  return Number(scoreDiff.toFixed(2))
}

export const hasCustomerData = (data: DashboardMaturity): boolean => {
  if (data.customer.length === 0) {
    return false
  } else {
    return true
  }
}
