import { ListItemSelectType } from '../../../design-system'
import { OktaGroup } from '../../../models'

export const filterAdminGroup = (
  groupItems: ListItemSelectType[],
): ListItemSelectType[] =>
  groupItems?.filter((item) => !item.name.includes('-portal-admins'))

// A group has been removed if it exists in the initial list, but not in the selected list.
export const findGroupsToRemove = (
  initialGroupOptions: ListItemSelectType[],
  selectedGroupIDs: string[],
): string[] => {
  return initialGroupOptions
    .map((option) => {
      if (!selectedGroupIDs.includes(option.id as string)) {
        return option.id
      }
    })
    .filter(Boolean) as string[]
}

// A group has been added if it exists in the selected list, but not in the initial list.
export const findGroupsToAdd = (
  initialGroupOptions: ListItemSelectType[],
  selectedGroupIDs: string[],
): string[] => {
  return selectedGroupIDs
    .map((id) => {
      if (!initialGroupOptions.map((group) => group.id).includes(id)) {
        return id
      }
    })
    .filter(Boolean) as string[]
}

export const mapToListItems = (
  oktaGroups: OktaGroup[],
): ListItemSelectType[] => {
  return oktaGroups
    .filter(
      (group) =>
        group.groupName.substring(0, 5) === 'cust-' &&
        !group.groupName.includes('-base'),
    )
    .map((group) => ({
      id: group.groupId,
      name: group.groupName,
    }))
}

export const toggleAdminGroup = ({
  isAdmin,
  selectedGroupIDs,
  adminGroupID,
}: {
  isAdmin: boolean
  selectedGroupIDs: string[]
  adminGroupID: string
}): string[] => {
  if (isAdmin) {
    return [...selectedGroupIDs, adminGroupID]
  } else {
    return selectedGroupIDs.filter((id) => id !== adminGroupID)
  }
}
