import { CSSProperties, ReactNode, createContext } from 'react'

export interface ModalContent {
  component: ReactNode
  title?: string
  subtitle?: string
  escapeKeyToClose?: boolean
  closeButtonVisible?: boolean
  modalContentStyles?: CSSProperties
}

interface ModalContextValues {
  content: ModalContent
  isOpen: boolean
  openModal: (content: ModalContent) => void
  closeModal: VoidFunction
}

export const ModalContext = createContext<ModalContextValues | null>(null)
