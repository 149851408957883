import { Typography, Box, Theme } from '@mui/material'
import { SystemStyleObject } from '@mui/system'

import { SecurityIndexEvent } from '@models/index'
import Icon from '@common/Icon'
import { IconVariant } from '@common/Icon/Icons'

export interface CarouselCardProps {
  testId: string
  iconVariant: IconVariant
  added: SecurityIndexEvent['action']
  title: SecurityIndexEvent['sourceName']
  info: string
  isActive: boolean
  onClick: VoidFunction
  link: string
  featureInteractiveIndicators?: boolean
}

const carouselCardStyles = (theme: Theme): SystemStyleObject<Theme> => ({
  border: `1px solid ${theme.vars.palette.secondary.main}`,
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  cursor: 'pointer',
  color: theme.vars.palette.text.primary,
  borderRadius: '0.3125rem',
  padding: '0.75rem 0.5rem',
  width: '276px',
  position: 'relative',
  transition: 'background-color 0.3s',
  backgroundColor: theme.vars.palette.secondary.light,
  '&.same-date': {
    '&::after': {
      content: '""',
      display: 'block',
      height: '1em',
      width: '2px',
      backgroundColor: theme.vars.palette.secondary.lighter,
      position: 'absolute',
      right: '-9px',
      transform: 'translate(0, -25px) rotate(90deg)',
    },
  },

  ...theme.applyStyles('dark', {
    backgroundColor: theme.vars.palette.secondary.darker,
    color: theme.vars.palette.text.primary,
    border: `1px solid ${theme.vars.palette.secondary.lighter}`,
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    borderRadius: '0.3125rem',
    padding: '0.75rem 0.5rem',
    width: '276px',
    position: 'relative',
    cursor: 'pointer',
    transition: 'background-color 0.3s',

    '&.same-date': {
      '&::after': {
        content: '""',
        display: 'block',
        height: '1em',
        width: '2px',
        backgroundColor: theme.vars.palette.secondary.lighter,
        borderColor: theme.vars.palette.text.primary,
        position: 'absolute',
        right: '-9px',
        transform: 'translate(0, -25px) rotate(90deg)',
      },
    },
  }),

  '&.flex': {
    display: 'flex',
    alignItems: 'center',
  },
})

const CarouselCard: React.FC<CarouselCardProps> = ({
  testId,
  iconVariant,
  added,
  title,
  info,
  isActive = false,
  onClick,
  link,
  featureInteractiveIndicators,
}) => {
  if (featureInteractiveIndicators) {
    return (
      <Box
        data-testid={testId}
        tabIndex={0}
        data-cardActive={isActive ? `active` : ''}
        onClick={onClick}
        role="button"
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
          if (e.key === 'Enter') {
            onClick()
          }
        }}
        sx={(theme: Theme): SystemStyleObject<Theme> => ({
          ...carouselCardStyles(theme),
          ...(isActive && {
            backgroundColor: theme.vars.palette.secondary.main,
            color: theme.vars.palette.text.primary,
            borderColor: theme.vars.palette.text.primary,
            ...theme.applyStyles('dark', {
              backgroundColor: theme.vars.palette.secondary.darker,
              color: theme.vars.palette.text.primary,
              borderColor: theme.vars.palette.text.primary,
              position: 'relative',
              '&::after': {
                backgroundColor: theme.vars.palette.text.primary,
                borderColor: theme.vars.palette.text.primary,
              },
            }),
          }),
          ...(!!link && {
            '&::after': {
              content: '""',
              display: 'block',
              height: '1em',
              width: '2px',
              backgroundColor: theme.vars.palette.text.primary,
              position: 'absolute',
              right: '-9px',
              transform: 'translate(0, -25px) rotate(90deg)',
              ...theme.applyStyles('dark', {
                backgroundColor: theme.vars.palette.secondary.lighter,
              }),
            },
          }),
        })}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '90%' }}>
            <Box
              sx={{
                display: 'flex',
                marginBottom: '0.25rem',
              }}
            >
              <Icon
                variant={iconVariant && iconVariant}
                size={18}
                sx={(theme) =>
                  added || iconVariant === 'cogOutline'
                    ? {
                        color: theme.vars.palette.text.primary,
                        ...theme.applyStyles('dark', {
                          color: theme.vars.palette.text.secondary,
                        }),
                      }
                    : { color: theme.vars.palette.error.light }
                }
              />
              <Typography
                color="textPrimary"
                variant="body2"
                sx={{
                  marginLeft: '8px',
                  maxWidth: '200px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {title}
              </Typography>
            </Box>
            <Box
              data-testid="carousel-card-bottom"
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography
                color="textPrimary"
                fontWeight={500}
                sx={{
                  display: 'block',
                  maxWidth: '200px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
                variant="caption"
              >
                {info}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%',
            }}
          >
            <Icon
              size={24}
              sx={(theme) => ({
                display: 'flex',

                color: theme.vars.palette.secondary.darker,
                opacity: 0.8,
                ...theme.applyStyles('dark', {
                  color: theme.vars.palette.text.secondary,
                  opacity: 0.7,
                }),
              })}
              variant="chevronForward"
            />
          </Box>
        </Box>
      </Box>
    )
  }

  return (
    <Box
      data-testid={testId}
      tabIndex={0}
      data-cardActive={isActive ? `active` : ''}
      onClick={onClick}
      role="button"
      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
          onClick()
        }
      }}
      sx={(theme: Theme): SystemStyleObject<Theme> => ({
        ...carouselCardStyles(theme),
        ...(isActive && {
          backgroundColor: theme.vars.palette.secondary.main,
          color: theme.vars.palette.text.primary,
          borderColor: theme.vars.palette.text.primary,
          ...theme.applyStyles('dark', {
            backgroundColor: theme.vars.palette.secondary.darker,
            color: theme.vars.palette.text.primary,
            borderColor: theme.vars.palette.text.primary,
            position: 'relative',
            '&::after': {
              backgroundColor: theme.vars.palette.text.primary,
              borderColor: theme.vars.palette.text.primary,
            },
          }),
        }),
        ...(!!link && {
          '&::after': {
            content: '""',
            display: 'block',
            height: '1em',
            width: '2px',
            backgroundColor: theme.vars.palette.text.primary,
            position: 'absolute',
            right: '-9px',
            transform: 'translate(0, -25px) rotate(90deg)',
            ...theme.applyStyles('dark', {
              backgroundColor: theme.vars.palette.secondary.lighter,
            }),
          },
        }),
      })}
    >
      <Box
        sx={{
          display: 'flex',
          marginBottom: '0.25rem',
          gap: '0.5rem',
        }}
      >
        <Icon
          variant={iconVariant && iconVariant}
          size={18}
          sx={(theme) =>
            added || iconVariant === 'cogOutline'
              ? {
                  color: theme.vars.palette.text.primary,
                  ...theme.applyStyles('dark', {
                    color: theme.vars.palette.text.secondary,
                  }),
                }
              : { color: theme.vars.palette.error.light }
          }
        />
        <Typography
          color="textPrimary"
          variant="body2"
          sx={{
            marginLeft: '8px',
            maxWidth: '200px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {title}
        </Typography>
      </Box>
      <Box
        data-testid="carousel-card-bottom"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          color="textPrimary"
          fontWeight={500}
          noWrap={true}
          sx={{ display: 'block' }}
          variant="caption"
        >
          {info}
        </Typography>
      </Box>
    </Box>
  )
}

export default CarouselCard
