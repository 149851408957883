import { ReactNode, useState } from 'react'

import { ModalContent, ModalContext } from './ModalContext'
import Modal from './Modal'

interface ModalContextProviderProps {
  children: ReactNode
}

const ModalContextProvider: React.FC<ModalContextProviderProps> = ({
  children,
}) => {
  const initialState: ModalContent = {
    component: undefined,
    subtitle: undefined,
    title: undefined,
    escapeKeyToClose: true,
    closeButtonVisible: true,
    modalContentStyles: undefined,
  }

  const [content, setContent] = useState<ModalContent>(initialState)
  const [isOpen, setIsOpen] = useState(false)

  const closeModal = () => {
    setIsOpen(false)
    setContent(initialState)
  }

  const openModal = (newContent: ModalContent) => {
    setContent(newContent)
    setIsOpen(true)
  }

  // handles turning on/off scrolling when modal is on screen
  if (isOpen) {
    document.body.style.overflow = 'hidden'
  } else {
    document.body.style.overflow = 'auto'
  }

  return (
    <ModalContext.Provider
      value={{
        content,
        isOpen,
        closeModal,
        openModal,
      }}
    >
      <Modal />
      {children}
    </ModalContext.Provider>
  )
}

export default ModalContextProvider
