import React, { ForwardedRef, forwardRef, useState } from 'react'

import { useTheme } from '../../../hooks'
import { RowHoverElement } from '../RowHoverElement'
import { ColumnHeader } from '../DesktopTable.types'
import { tableBodyColor } from '../DesktopTable.utils'
import { IconButtonProps } from '../../../interfaces'

import './TableRow.scss'

export interface TableRowProps {
  bodyColor?: string
  children?: any
  classNames?: string
  clickableTableRow?: boolean
  index: number
  loading?: boolean
  rowClick?: (id: string | number, rowData?: any) => void
  rowHoverElements?: IconButtonProps[]
  tableHeaders: ColumnHeader[]
  tableRowData?: any
}

const TableRow = forwardRef(function TableRow(
  props: TableRowProps,
  ref: ForwardedRef<HTMLTableRowElement>,
) {
  const {
    bodyColor = '',
    children,
    classNames = '',
    clickableTableRow = false,
    index,
    loading,
    rowClick,
    rowHoverElements = [],
    tableRowData,
  } = props
  const isDarkTheme = useTheme('dark')
  const [hoverElementClasses, setHoverElementClasses] = useState('')

  const handleRowClick = (
    id: number | string,
    data: any,
    event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
  ) => {
    event.stopPropagation()
    event.currentTarget.focus()
    if (!['ION-ICON', 'BUTTON'].includes(event.currentTarget?.tagName)) {
      rowClick?.(id, data)
    }
  }

  const handleKeyDown = (
    id: number | string,
    data: any,
    event: React.KeyboardEvent<HTMLElement>,
  ): void => {
    event.stopPropagation()
    if ((event.target as HTMLElement).classList.contains('table-row')) {
      if (event.key === 'Enter' || event.key === ' ') {
        rowClick?.(id, data)
      }
    }
  }

  const rowClassNames = `table-row${tableRowStateClasses(
    clickableTableRow,
    tableRowData?.disabled,
    loading ?? false,
  )} ${classNames} ${!isDarkTheme ? 'table-row-light' : ''}`

  let hoverRowElements = rowHoverElements
  if (
    tableRowData?.rowHoverElements !== undefined &&
    tableRowData.rowHoverElements.length > 0
  ) {
    hoverRowElements = tableRowData.rowHoverElements
  }

  const disabledRow = (): boolean => {
    if (
      (tableRowData?.disabled === undefined ||
        tableRowData?.disabled === false) &&
      loading === false
    ) {
      return false
    }
    return true
  }

  return (
    <tr
      ref={ref}
      key={index}
      tabIndex={0}
      style={{
        backgroundColor: hoverElementClasses
          ? '#36474c'
          : tableBodyColor(bodyColor, isDarkTheme),
      }}
      onClick={(event) =>
        !disabledRow() && handleRowClick(tableRowData?.id, tableRowData, event)
      }
      onKeyDown={(event) =>
        !disabledRow() && handleKeyDown(tableRowData?.id, tableRowData, event)
      }
      onFocus={() => {
        !disabledRow() && setHoverElementClasses(' focused')
      }}
      onBlur={() => {
        !disabledRow() && setHoverElementClasses('')
      }}
      onMouseEnter={() => {
        !disabledRow() && setHoverElementClasses(' active')
      }}
      onMouseLeave={() => {
        !disabledRow() && setHoverElementClasses('')
      }}
      className={rowClassNames.trimEnd()}
    >
      {children}
      {hoverRowElements.length > 0 && (
        <RowHoverElement
          elements={hoverRowElements}
          elementValue={tableRowData}
          elementClasses={hoverElementClasses}
          updateClasses={() => setHoverElementClasses('')}
        />
      )}
    </tr>
  )
})

export default TableRow

const tableRowStateClasses = (
  clickableTableRow: boolean,
  disabled: boolean,
  loading: boolean,
): string => {
  if (disabled || loading) {
    return ' disabled-row'
  } else if (!clickableTableRow) {
    return ' table-row-no-click'
  } else {
    return ''
  }
}
