import { ReactNode } from 'react'

import './InlineMessage.scss'

interface InlineMessageProps {
  children: ReactNode
}

const InlineMessage: React.FC<InlineMessageProps> = ({ children }) => {
  return <div id="inline-message">{children}</div>
}

export default InlineMessage
