import React, { useRef, useState } from 'react'
import { CSSTransition } from 'react-transition-group'

import { BanProps } from '../../interfaces/Ban'
import { colors } from '../../theme'
import { useTheme } from '../../hooks'
import { Icon, Delta } from '../'
import SkeletonBlock from '../SkeletonBlock/SkeletonBlock'

import './Ban.scss'

export const Ban = ({
  color,
  delta,
  iconVariant,
  label,
  loading,
  onClick,
  progress,
  deltaTooltipMessage = 'Not enough data to calculate a trend',
  value,
  style,
}: BanProps): JSX.Element => {
  const [showContent, setShowContent] = useState(true)

  const nodeRef = useRef(null)

  const isDarkTheme = useTheme('dark')

  // const defaultColor = color
  //   ? color
  //   : isDarkTheme
  //   ? colors.common.white
  //   : colors.common.black

  const defaultColor = () => {
    if (color) {
      return color
    } else if (isDarkTheme) {
      return colors.common.white
    } else {
      colors.common.black
    }
  }

  let BanClass = isDarkTheme ? 'dw-ban dark' : 'dw-ban'
  BanClass += onClick && !loading ? ' interactive' : ''

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      style={style}
      className={BanClass}
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex={0}
      onClick={loading ? undefined : onClick}
    >
      <div
        className="icon-container"
        style={{ backgroundColor: defaultColor() }}
      >
        <Icon
          variant={iconVariant}
          color={colors.util.navy[600]}
          size={28}
        ></Icon>
      </div>
      <div className={loading ? 'data-container loading' : 'data-container'}>
        <div className={progress === undefined ? 'label' : 'label compact'}>
          {label}
        </div>
        <div className="value-container">
          <CSSTransition
            in={loading}
            appear={showContent}
            nodeRef={nodeRef}
            timeout={150}
            classNames="dw-ban-loading"
            onEnter={() => setShowContent(false)}
            onExited={() => setShowContent(true)}
            unmountOnExit
          >
            <SkeletonBlock ref={nodeRef} />
          </CSSTransition>

          {showContent && (
            <div className="dw-ban-content">
              <span className="value">{value}</span>
              {delta !== undefined && (
                <Delta
                  delta={delta}
                  tooltipMessage={deltaTooltipMessage}
                ></Delta>
              )}
            </div>
          )}
        </div>
        {progress !== undefined && (
          <div className="progress">
            <div
              style={{
                backgroundColor: defaultColor(),
                width: `${progress}%`,
              }}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default Ban
