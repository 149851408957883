import { createPortal } from 'react-dom'
import { useRef } from 'react'

import ModalHeader from './ModalHeader'
import { useKeyDown } from '../../../design-system'
import { useModalContext } from '../../../hooks'

import './Modal.scss'

export interface ModalContentProps {
  closeButtonVisible?: boolean
  closeModal: VoidFunction
}

const Modal = () => {
  const { content, closeModal, isOpen } = useModalContext()

  const ref = useRef<HTMLDivElement>(null)

  useKeyDown('Escape', closeModal, content.escapeKeyToClose)

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const element = document.getElementById('modal')!

  const modalToRender = (
    <div className="overlay" data-testid="modal-overlay">
      <div
        data-testid="modal-content"
        className="modal-content"
        ref={ref}
        style={content.modalContentStyles}
      >
        {content.title && (
          <ModalHeader
            title={content.title}
            subtitle={content.subtitle}
            closeModal={closeModal}
            closeButtonVisible={content.closeButtonVisible}
          />
        )}

        {content.component}
      </div>
    </div>
  )

  return isOpen ? createPortal(modalToRender, element) : <></>
}

export default Modal
