import { useState } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { Button, Tabs } from '../../../../design-system'
import { SideSheet } from '../../../SideSheet'
import { Ticket } from '../../../../models'
import ActivityTab from '../ActivityTab/ActivityTab'
import NewActivityTab from '../NewActivityTab/ActivityTab'
import TicketDetails from '../TicketDetails/TicketDetails'

import './TicketLibrarySideSheet.scss'

interface TicketSideSheetProps {
  isOpen: boolean
  closeSideSheet: () => void
  focusedTicket: Ticket
}

const TicketLibrarySideSheet = ({
  isOpen,
  closeSideSheet,
  focusedTicket,
}: TicketSideSheetProps) => {
  const { featureEditTicketPage } = useFlags()
  const ActivityTabComponent = featureEditTicketPage
    ? NewActivityTab
    : ActivityTab

  const TicketSideSheetTabs = [
    {
      active: true,
      id: 0,
      text: 'Details',
      notification: false,
      headerName: 'Ticket Details',
    },
    {
      active: false,
      id: 1,
      text: 'Activity',
      notification: false,
      headerName: 'Ticket Details',
    },
  ]

  const TicketLibrarySideSheetTabsContent: JSX.Element[] = [
    <TicketDetails
      key={`details-${focusedTicket.sysId}`}
      focusedTicket={focusedTicket}
    />,
    <ActivityTabComponent
      key={`activity-${focusedTicket.sysId}`}
      focusedTicket={focusedTicket}
    />,
  ]

  const [selectedTab, setSelectedTab] = useState(0)

  return (
    <SideSheet
      data-testid="ticketDetailsHeader"
      open={isOpen}
      closeSideSheet={closeSideSheet}
    >
      <div
        id="tickets-library-sidesheet"
        data-testid="tickets-library-sidesheet-test-id"
        style={{ padding: '0px' }}
      >
        <div className="header" data-testid="tickets-library-sidesheet-header">
          <div className="header-content">
            {TicketSideSheetTabs[parseInt(selectedTab.toString())].headerName}
            <Button
              variant="navigational-icon"
              iconProps={{ variant: 'closeOutline' }}
              onClick={closeSideSheet}
            />
          </div>
        </div>
        <Tabs
          data={TicketSideSheetTabs}
          activeTab={selectedTab}
          onTabClick={(id) => {
            setSelectedTab(id)
          }}
        ></Tabs>
        {TicketLibrarySideSheetTabsContent.at(selectedTab)}
      </div>
    </SideSheet>
  )
}

export default TicketLibrarySideSheet
