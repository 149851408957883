import {
  Box,
  FormControlLabel,
  Link,
  Switch,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'

import Icon from '@common/Icon'

import { useMitreOverviewContext } from '../context/MitreOverviewContext'

interface MitreHeaderProps {
  isTableVisible: boolean
  setIsTableVisible(value: boolean): void
}
export default function MitreHeader({
  isTableVisible,
  setIsTableVisible,
}: MitreHeaderProps) {
  const {
    mitreSelection,
    setMitreSelection,
    setCarouselTechnique,
    handleSideSheet,
    setHasUserClosedSideSheet,
    coverage,
    showUnavailable,
    setShowUnavailable,
  } = useMitreOverviewContext()
  const theme = useTheme()
  const { featureDetectionCoverageUpdates } = useFlags()
  const isLightMode = theme.palette.mode === 'light'
  const handleViewToggle = () => {
    /**
     * when switching to the table view from the list view,
     * while having a subtechnique selected, unset the
     * subtechnique and display the sidesheet for its
     * related technique since the table view does not
     * include a state where users can view subtechniques
     */
    const newTableVisible = !isTableVisible

    setIsTableVisible(newTableVisible)

    if (newTableVisible) {
      return
    }

    const { tactic, technique, subtechnique } = mitreSelection

    setMitreSelection({
      tactic,
      technique,
      subtechnique: undefined,
    })

    if (technique === undefined) {
      setCarouselTechnique(undefined)
    }

    if (tactic !== undefined && subtechnique !== undefined) {
      handleSideSheet(tactic, technique, undefined, () => {
        if (
          technique &&
          // eslint-disable-next-line security/detect-object-injection
          coverage[tactic].techniques[technique].subtechniques.length === 0
        ) {
          setCarouselTechnique(undefined)
        }
        setMitreSelection({
          tactic: undefined,
          subtechnique: undefined,
          technique: undefined,
        })

        setHasUserClosedSideSheet(true)
      })
    }
  }
  const handleUnavailableToggle = () => {
    setShowUnavailable((prev) => !prev)
  }

  const keyColor = isLightMode
    ? theme.palette.text.primary
    : theme.palette.text.secondary

  const gradientStart = isLightMode
    ? theme.palette.secondary.lighter
    : theme.palette.text.disabled
  const gradientEnd = theme.palette.primary.main

  return (
    <Box
      sx={{
        display: 'flex',
        flex: '1 1 auto',
        height: 'min-content',
        minWidth: 'min-content',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Typography color="textPrimary" variant="h6">
          {featureDetectionCoverageUpdates
            ? 'MITRE ATT&CK Coverage'
            : 'MITRE Coverage'}
        </Typography>
        {featureDetectionCoverageUpdates && (
          <Tooltip
            title={
              'Visually map current detections (and associated source types) in your environment to the MITRE ATT&CK framework for a clear view of risk-based coverage. Partner with your Deepwatch experts to drive strategic security maturity and resilience based on your priorities.'
            }
            placement="top-start"
            followCursor
            sx={{ cursor: 'default', pl: '5px' }}
            data-testid="MITRE-coverage-tooltip"
          >
            <Box>
              <Icon
                size={18}
                sx={(theme) => ({
                  ...theme.applyStyles('dark', {
                    color: theme.vars.palette.text.primary,
                  }),
                  color: theme.vars.palette.secondary.contrastText,
                })}
                variant="informationCircleOutline"
              />
            </Box>
          </Tooltip>
        )}
        <Link
          data-testid="mitre-overview-toggle"
          component="button"
          onClick={handleViewToggle}
          sx={{ paddingLeft: 1 }}
          variant="caption"
        >
          {`View as ${isTableVisible ? 'list' : 'table'}${featureDetectionCoverageUpdates ? ' »' : ''}`}
        </Link>
      </Box>
      {featureDetectionCoverageUpdates ? (
        <MitreToggleGradientLegend
          handleUnavailableToggle={handleUnavailableToggle}
          showUnavailable={showUnavailable}
          gradientStart={gradientStart}
          gradientEnd={gradientEnd}
        />
      ) : (
        <Box
          sx={{
            display: 'flex',
            flex: '1 1 auto',
            height: 'min-content',
            minWidth: 'min-content',
            flexGrow: 0,
            alignItems: 'center',
            gap: '16px',
          }}
        >
          <MitreCoverageKey
            label="Unavailable"
            borderColor={theme.palette.secondary.lighter}
          />
          <MitreCoverageKey
            label="Available"
            boxColor={theme.palette.secondary.lighter}
          />
          <MitreCoverageKey label="Covered" boxColor={keyColor} />
        </Box>
      )}
    </Box>
  )
}

interface MitreCoverageKeyProps {
  label: string
  borderColor?: string
  boxColor?: string
}
function MitreCoverageKey({
  label,
  borderColor,
  boxColor = 'transparent',
}: MitreCoverageKeyProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        flex: '1 1 auto',
        height: 'min-content',
        minWidth: 'min-content',
        alignItems: 'center',
        gap: '4px',
      }}
    >
      <Box
        data-testid="mitre-coverage-key-color"
        sx={{
          border: borderColor ? `1px solid ${borderColor}` : '',
          backgroundColor: boxColor,
          width: 12,
          height: 12,
        }}
      />
      <Typography color="textPrimary" variant="caption">
        {label}
      </Typography>
    </Box>
  )
}

interface MitreToggleGradientLegendProps {
  handleUnavailableToggle: () => void
  showUnavailable: boolean
  gradientStart: string | undefined
  gradientEnd: string
}
function MitreToggleGradientLegend({
  handleUnavailableToggle,
  showUnavailable,
  gradientStart,
  gradientEnd,
}: MitreToggleGradientLegendProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        flex: '1 1 auto',
        height: 'min-content',
        minWidth: 'min-content',
        flexGrow: 0,
        alignItems: 'center',
        gap: '16px',
      }}
    >
      <FormControlLabel
        className="switch-label"
        tabIndex={0}
        labelPlacement="start"
        onKeyUp={handleUnavailableToggle}
        sx={(theme) => ({
          '.MuiFormControlLabel-label': {
            color: theme.palette.text.primary,
            fontSize: '12px',
          },
          padding: '12px',
        })}
        control={
          <Switch
            onClick={handleUnavailableToggle}
            checked={showUnavailable}
            data-testid="unavailable-toggle"
          />
        }
        label="Show unavailable"
      />
      <Box
        display="flex"
        sx={{ flexDirection: 'column', width: '170px' }}
        data-testid="legend-gradient"
      >
        <Box
          sx={{
            width: '100%',
            height: '11px',
            borderRadius: '99px',
            background: `linear-gradient(to right, ${gradientStart}, ${gradientEnd})`,
          }}
        />
        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          height="10px"
        >
          <Typography variant="caption">0%</Typography>
          <Typography variant="caption">100%</Typography>
        </Box>
      </Box>
    </Box>
  )
}
