import { useEffect, useMemo } from 'react'
import DatePicker from 'react-datepicker'
import { endOfMonth, startOfMonth, startOfYear, subYears } from 'date-fns'

import { MonthPickerProps } from '../../interfaces'
import YearSelection from './YearSelection'
import MonthSelection from './MonthSelection'

import './MonthPicker.scss'

const MonthPicker = ({
  date,
  setDate,
  isMonthDisabled,
  isYearDisabled,
  closePopoverMenu,
  setDateTo = 'startOfMonth',
}: MonthPickerProps) => {
  const now = useMemo(() => new Date(), [])

  const parsedDate = new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0)

  /**
   * Get two years ago, last year, and current year (in that order)
   * Although this isn't computationally heavy I'm still wrapping it
   * in a useMemo since this component is going to update a lot from
   * the date being updated and this really only needs to be calculated
   * on the initial mount
   */
  const years = useMemo<[Date, Date, Date]>(() => {
    const currentYear = startOfYear(new Date())
    const twoYearsAgo = subYears(currentYear, 2)
    const lastYear = subYears(currentYear, 1)

    return [twoYearsAgo, lastYear, currentYear]
  }, [])

  const [twoYearsAgo, lastYear, currentYear] = years

  /**
   * This effect is used to check if we should adjust the date selection based on the
   * month disabled condition. If the current date is disabled then we calculate the
   * soonest valid date. We count backwards to the start of each month
   * in the year until it is not disabled.
   * This logic is for comparing dates within the same year. If the disabled dates are in a different year
   * then this logic will not cover it. However, you should only be using the month disabled prop in a way
   * that only works per year
   */
  useEffect(() => {
    // Check if the date month is invalid and adjust based on that
    if (!isMonthDisabled(date)) {
      return
    }

    // Reverse the array of months so we can find the most recent non-disabled month
    const startOfMonths = new Array(date.getMonth())
      .fill(0)
      .map((_, index) => new Date(date.getFullYear(), index, 1))
      .reverse()

    let firstValidMonth: Date | undefined

    for (const monthDate of startOfMonths) {
      if (!isMonthDisabled(monthDate)) {
        firstValidMonth = monthDate

        break
      }
    }

    if (!firstValidMonth) {
      return
    }

    setDate(firstValidMonth)
  }, [date, setDate, isMonthDisabled])

  const handleDateChange = (newDate) => {
    if (!newDate) {
      closePopoverMenu()

      return
    }

    const parsedDate =
      setDateTo === 'endOfMonth' ? endOfMonth(newDate) : startOfMonth(newDate)

    setDate(parsedDate)

    closePopoverMenu()
  }

  return (
    <DatePicker
      selected={parsedDate}
      onChange={handleDateChange}
      dateFormat="MMM yyyy"
      showMonthYearPicker
      renderCustomHeader={(props) => (
        <YearSelection
          currentYear={currentYear}
          lastYear={lastYear}
          twoYearsAgo={twoYearsAgo}
          updateDate={setDate}
          isYearDisabled={isYearDisabled}
          {...props}
        />
      )}
      // Setting show four column month picker to true with showTwoColumMonthPicker defaulting to false shows three columns
      showFourColumnMonthYearPicker={false}
      showTwoColumnMonthYearPicker={false}
      renderMonthContent={(monthIndex, shortMonthText) => (
        <MonthSelection
          date={parsedDate}
          monthIndex={monthIndex}
          monthLabel={shortMonthText}
          isMonthDisabled={isMonthDisabled}
        />
      )}
      showPopperArrow={false}
      open
      minDate={twoYearsAgo}
      maxDate={now}
      customInput={<></>}
    />
  )
}

export default MonthPicker
