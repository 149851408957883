import { useState } from 'react'

import { SiMetricsCards } from './SiMetricsCards'
import { SiMetricsChart } from './SiMetricsChart'
import { Typography } from '../../../../../../../design-system'

import './SecurityIndexMetrics.scss'
const SecurityIndexMetrics: React.FC = () => {
  const [active, setActive] = useState<string>('')

  const setActivePart = (id: string): void => {
    setActive(id)
  }

  return (
    <div
      data-testid="security-index-metrics"
      id="security-index-metrics"
      className="flex"
    >
      <div>
        <Typography variant="text10semibold">
          Security Index Calculation
        </Typography>
        <Typography size={14} weight={400} styles={{ marginTop: '.5rem' }}>
          Your Security Index™ is broken down into three parts: Enabled log
          sources, enabled detections and enterprise coverage. Each of these
          sections are weighted differently.
        </Typography>
        <div className="si-metrics-chart-cards-container flex wrap">
          <div className="si-metric-chart-container">
            <SiMetricsChart active={active} />
          </div>
          <SiMetricsCards setActivePart={setActivePart} />
        </div>
      </div>
    </div>
  )
}
export default SecurityIndexMetrics
