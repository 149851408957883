// since keywordSearch is just a string, this function will return it as an array of one string
// so that it is easier to work with when returning the JSX
export const convertKeywordSearchToArray = (searchString: string): string[] => {
  if (searchString.trim() === '') {
    return []
  } else {
    return [searchString]
  }
}

export const formatChipLabel = (
  filterGroup: string,
  filterValue: string,
): string => {
  switch (filterGroup) {
    case 'keywordSearch':
      return `"${filterValue}"`
    case 'sysUpdatedOn':
      return `last updated: ${filterValue}`
    case 'createdDate':
      return `age: ${filterValue}`
    case 'state':
      return `status: ${filterValue}`
    case 'mitreTactics':
      return `MITRE: ${filterValue}`
    default:
      return `${filterGroup}: ${filterValue}`
  }
}
