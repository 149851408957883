import { useTheme } from '../../../hooks'
import { colors } from '../../../theme'
import { Button } from '../../Button'
import Link from '../../Link/Link'
import { TableLoadingRowProps, TableLoadingState } from '../Table.types'
import { findAndScrollToTop, tableBodyColor } from '../Table.utils'
import { Typography } from '../../Typography'
import { Loader } from '../../Loader'

import './TableLoadingRow.scss'

function TableLoadingRow({ loadingState }: TableLoadingRowProps): JSX.Element {
  const isDarkTheme = useTheme('dark')

  return (
    <div style={{ backgroundColor: tableBodyColor('', isDarkTheme) }}>
      <div>{getLoadingRowContent(loadingState)}</div>
    </div>
  )
}

function getLoadingRowContent(loadingState: TableLoadingState): JSX.Element {
  let result = <></>
  switch (loadingState) {
    case TableLoadingState.LOADING:
      result = (
        <div className="table-loading-row-cell-loading">
          <div className="table-loading-row-cell-loading-content">
            <Typography variant="text11medium" color={colors.util.navy[100]}>
              Hang tight, we&apos;re fetching data
            </Typography>
            <Loader centered={true} strokeWidth={2} />
          </div>
        </div>
      )
      break
    case TableLoadingState.ALL_LOADED:
      result = (
        <div className="table-loading-row-cell-all-loaded-div">
          <div className="table-loading-row-cell-all-loaded-content">
            <Typography variant="text11medium" color={colors.util.navy[100]}>
              All of your data has been loaded
            </Typography>
            <Link
              variant="button"
              iconProps={{ variant: 'arrowUp' }}
              aProps={{
                onClick: (e) => {
                  findAndScrollToTop(
                    e.currentTarget.parentElement,
                    'datatable datatable-sticky',
                  )
                },
              }}
            >
              Back to the top
            </Link>
          </div>
        </div>
      )
      break
    case TableLoadingState.ERROR:
      result = (
        <div className="table-loading-row-cell-error">
          <Typography variant="text11medium" color={colors.util.navy[100]}>
            We&apos;re having trouble fetching data. Check your connection then
            reload.
          </Typography>
          <Button
            variant="primary"
            label="Reload"
            onClick={() => {
              location.reload()
            }}
          />
        </div>
      )
      break
    default:
      break
  }
  return <div className="table-loading-row-container">{result}</div>
}

export default TableLoadingRow
