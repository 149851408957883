import { useEffect } from 'react'
import { default as OktaSignIn } from '@okta/okta-signin-widget'
import { Box } from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { OktaWidgetConfig } from '@config/index'
import { oktaAuthConfig } from '@config/OktaAuthConfig'
import { getCookie, setCookie } from '@utils/Cookies'
import { DeepWatchLogo } from '@common/SVGs/DeepwatchLogo'
import { InlineMessage, InlineMessageType } from '@common/InlineMessage'
import { InlineMessageProps } from '@common/InlineMessage/InlineMessage'

import '@okta/okta-signin-widget/css/okta-sign-in.min.css'
import './Login.scss'

export const Login = () => {
  const inlineMessages: InlineMessageProps[] = []
  const cancelledTerms = getCookie('ct')
  const sessionExpired = getCookie('se')

  const { featureDwToCustomerDeepLink } = useFlags()

  if (cancelledTerms === '1') {
    inlineMessages.push({
      variant: InlineMessageType.WARNING,
      title: 'Logged out due to declining',
      message:
        'You were logged out due to failing to agree to our terms & conditions',
    })
  }

  if (sessionExpired === '1') {
    inlineMessages.push({
      variant: InlineMessageType.WARNING,
      title: 'Your session has expired',
      message: 'You were logged out due to inactivity.',
    })
  }

  useEffect(() => {
    const widget = new OktaSignIn(OktaWidgetConfig)
    const origionalUri = oktaAuthConfig.getOriginalUri()
    widget.renderEl(
      { el: '#okta-widget' },
      () => {
        if (OktaWidgetConfig.redirectUri !== undefined) {
          // set expiration of cookie to an hour later
          // "iav" abbreviates "is authentication valid"
          setCookie('iav', '1', new Date(Date.now() + 60 * 60 * 1000))
          if (!featureDwToCustomerDeepLink) {
            window.location.replace(
              origionalUri ? origionalUri : OktaWidgetConfig.redirectUri,
            )
          } else {
            window.location.replace(OktaWidgetConfig.redirectUri)
          }
          // remove the origional uri on login so we do not persist this between sessions
          oktaAuthConfig.removeOriginalUri()
        }
      },
      (err) => {
        throw err
      },
    )
    return () => widget.remove()
  }, [])

  return (
    <main id="login-background" data-testid="login">
      <Box component="article" id="login-page" className="login">
        <Box sx={{ justifyContent: 'center', display: 'flex', width: '100%' }}>
          <DeepWatchLogo textColor="white" />
        </Box>
        <div id="okta-widget" />
        {inlineMessages.map((message, index) => (
          <InlineMessage key={index} {...message} sx={{ maxWidth: '400px' }} />
        ))}
      </Box>
      <div id="login-footer-links">
        <ul>
          <li>
            <a
              href={'https://legal.deepwatch.com/'}
              target="_blank"
              rel="noreferrer"
            >
              Terms of Service
            </a>
          </li>
          <li>
            <a
              href={'https://www.iubenda.com/privacy-policy/46757738/legal'}
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
          </li>
          <li>
            <a
              href={'https://www.deepwatch.com/trust/'}
              target="_blank"
              rel="noreferrer"
            >
              Trust
            </a>
          </li>
          <li>
            <a
              href={'https://www.deepwatch.com/contact-us/'}
              target="_blank"
              rel="noreferrer"
            >
              Contact
            </a>
          </li>
        </ul>
      </div>
    </main>
  )
}
