import { Typography } from '../../../../design-system'
import {
  GET_TICKET_DETAILS,
  TicketDetailsResponse,
} from '../../../../graphql/queries/ticket'
import TicketDataHandler from './TicketDataHandler'
import TicketEditCard from './TicketEditCard'

const TicketDetailsContent: React.FC<{ data: TicketDetailsResponse }> = ({
  data,
}) => (
  <div className="flex column gap-16">
    <div className="flex column gap-14">
      <Typography component="div" variant="text11medium">
        Short description
      </Typography>
      <Typography component="div" shouldParseNewLines color="white">
        {data.shortDescription || '--'}
      </Typography>
    </div>
    <div className="flex column gap-14">
      <Typography component="div" variant="text11medium">
        Description
      </Typography>
      <Typography component="div" shouldParseNewLines color="white">
        {data.description || '--'}
      </Typography>
    </div>
  </div>
)

const TicketDetails: React.FC = () => {
  return (
    <TicketEditCard title="Details" cardStyles={{ minHeight: '250px' }}>
      <TicketDataHandler<TicketDetailsResponse>
        graphqlQuery={GET_TICKET_DETAILS}
        renderContent={(data) => <TicketDetailsContent data={data} />}
      />
    </TicketEditCard>
  )
}

export default TicketDetails
