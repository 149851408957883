import React, { CSSProperties } from 'react'

import { Typography } from '../Typography'
import {
  CSSProps,
  IconVariant,
  TagProps,
  TagSeverity,
  TagStatus,
  TagVariant,
} from '../../interfaces'
import { Icon } from '../Icon'
import { colors } from '../../theme'
import { useTheme } from '../../hooks'

interface GetBackgroundProps {
  variant: TagVariant
  active: boolean
  severity: TagSeverity
}

interface GetTextProps {
  variant: TagVariant
  status?: TagStatus
}

const Tag = ({
  text,
  variant = 'small',
  active = false,
  severity = 'informational',
  status = 'available',
  leftIcon,
  rightIcon,
  closeIcon,
  size,
  styles,
  onDelete,
}: TagProps): JSX.Element => {
  const isDarkTheme = useTheme('dark')
  const getBackgroundColor = ({
    variant,
    active,
    severity,
  }: GetBackgroundProps): string | undefined => {
    if (variant === 'large') {
      switch (severity) {
        case 'critical':
          return colors.util.two.lighter
        case 'high':
          return colors.util.three.lighter
        case 'medium':
          return colors.brand.secondary.lighter
        case 'low':
          return colors.brand.primary.lighter
        case 'informational':
          return colors.util.one.lighter
      }
    } else if (variant === 'small') {
      switch (active) {
        case true:
          return colors.brand.secondary.dark
        case false:
          return colors.neutral[100]
      }
    }
  }

  const getTagHeight = (variant, size): number => {
    if (variant === 'large') {
      return 34
    } else {
      if (size) {
        return size + 6
      } else {
        return 18
      }
    }
  }

  const getFontSize = (variant, size): number => {
    if (variant === 'large') {
      return 14
    } else {
      if (size) {
        return size
      } else {
        return 12
      }
    }
  }

  const tagStyle = (
    variant: TagVariant,
    backgroundColor: string | undefined,
    size: number | undefined,
    styles: CSSProps | undefined,
    status: TagStatus,
  ): CSSProperties => ({
    backgroundColor: variant === 'secondary' ? 'transparent' : backgroundColor,
    display: 'inline-flex',
    height: getTagHeight(variant, size),
    borderRadius: variant === 'large' ? 5 : 2,
    border:
      variant === 'secondary'
        ? `solid 1px ${getTextColor({ variant, status })}`
        : '',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    minWidth: variant === 'large' ? 111 : 'unset',
    padding: variant === 'large' ? '7 10.5px' : '2px 4px',
    ...(styles && styles),
  })

  const chipStyle = (
    variant: TagVariant,
    backgroundColor: string | undefined,
    size: number | undefined,
    styles: CSSProps | undefined,
  ): CSSProperties => ({
    backgroundColor: isDarkTheme ? colors.util.navy[50] : backgroundColor,
    display: 'inline-flex',
    height: 34,
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    ...(styles && styles),
  })

  const chipTextStyle = (
    variant: TagVariant,
    hasLeftIcon: boolean,
    hasRightIcon: boolean,
  ): CSSProperties => ({
    width: 'max-content',
    fontWeight: variant === 'chip' ? 400 : 500,
    color: isDarkTheme ? colors.util.navy[600] : colors.util.navy[500],
    fontSize: 16,
    ...(hasLeftIcon && {
      marginLeft: '0.25ch',
    }),
    ...(hasRightIcon && {
      marginRight: '0.25ch',
    }),
    paddingLeft: 8,
  })

  const textStyle = (
    variant: TagVariant,
    color: string | undefined,
    hasLeftIcon: boolean,
    hasRightIcon: boolean,
    size: number | undefined,
  ): CSSProperties => ({
    width: 'max-content',
    fontWeight: variant === 'large' ? 600 : 500,
    color: color,
    fontSize: getFontSize(variant, size),
    ...(hasLeftIcon && {
      marginLeft: '0.25ch',
    }),
    ...(hasRightIcon && {
      marginRight: '0.25ch',
    }),
  })

  const getTextColor = ({
    variant,
    status,
  }: GetTextProps): string | undefined => {
    if (variant === 'large') {
      return colors.util.one.main
    } else if (variant === 'small') {
      return active === true ? colors.common.white : colors.util.one.main
    }

    if (status === 'enabled') {
      return colors.brand.primary.light
    } else if (status === 'available') {
      return colors.brand.secondary.light
    } else {
      return colors.util.navy[100]
    }
  }

  let severityText
  if (variant === 'large') {
    severityText = severity.charAt(0).toUpperCase() + severity.slice(1)
  }

  let statusText
  if (variant === 'secondary') {
    statusText = status.toUpperCase()
  }

  const backgroundColor = getBackgroundColor({ variant, active, severity })
  const textColor =
    styles && styles.color ? styles.color : getTextColor({ variant, status })
  const hasLeftIcon = leftIcon ? true : false
  const hasRightIcon = rightIcon || closeIcon

  let rightTagIcon
  if (hasRightIcon) {
    if (closeIcon) {
      rightTagIcon = 'close' as IconVariant
    } else {
      rightTagIcon = rightIcon
    }
  }

  if (variant === 'chip') {
    return (
      <span style={chipStyle(variant, backgroundColor, size, styles)}>
        {leftIcon && (
          <Icon
            variant={leftIcon}
            color={active === true ? colors.common.white : colors.util.one.main}
            size={size || 12}
          />
        )}
        <Typography
          styles={chipTextStyle(variant, hasLeftIcon, hasLeftIcon)}
          size={size || 12}
        >
          {severityText || text}
        </Typography>
        {rightTagIcon && (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
          <div
            style={{ display: 'flex', paddingLeft: 6, paddingRight: 6 }}
            onClick={onDelete}
          >
            <Icon
              variant={rightTagIcon}
              color={
                active === true ? colors.common.white : colors.util.one.main
              }
              size={20}
            />
          </div>
        )}
      </span>
    )
  } else {
    return (
      <span style={tagStyle(variant, backgroundColor, size, styles, status)}>
        {leftIcon && variant === 'small' && (
          <Icon
            variant={leftIcon}
            color={active === true ? colors.common.white : colors.util.one.main}
            size={size || 12}
          />
        )}
        <Typography
          styles={textStyle(variant, textColor, hasLeftIcon, hasLeftIcon, size)}
          size={size || 12}
        >
          {variant === 'secondary' && statusText}
          {variant === 'large' ? severityText : text}
        </Typography>
        {rightTagIcon && variant === 'small' && (
          <Icon
            variant={rightTagIcon}
            color={active === true ? colors.common.white : colors.util.one.main}
            size={size || 12}
          />
        )}
      </span>
    )
  }
}

export default Tag
