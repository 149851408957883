/* eslint-disable @getify/proper-ternary/nested */
import React, { useContext, useEffect, useState } from 'react'
import { Form, Formik, useFormik } from 'formik'
import { formatDistanceToNow, parseISO } from 'date-fns'
import { SingleValue } from 'react-select'
import { Box, Typography, useTheme } from '@mui/material'

import { TicketStateEnum } from '@models/index'
import { useTicketsContext, useTicketEditContext } from '@hooks/index'
import { Context } from '@components/App'
import { Dropdown, OptionType } from '@common/Dropdown'
import { Dialog } from '@common/Dialog'
import { avatarVariant } from '@components/Tickets/utils'

import { TicketStatusData } from '../../../Types'
import { setPTagsAndLabels } from '../../../utils'
import {
  findEnumLabelAndNumber,
  returnUserAvatarOrString,
  sortKeysToMatch,
  sortSequenceEnums,
} from '../TicketStatusUtils'
import { TicketResolutionForm } from '../../TicketResolution'

const TicketStatusForm = ({
  data,
}: {
  data: TicketStatusData
}): JSX.Element => {
  const {
    state: {
      user: { isDWEmployee },
    },
  } = useContext(Context)

  const theme = useTheme()

  const {
    priorityEnum,
    stateEnum,
    customerValidationEnum,
    urgencyEnum,
    resolutionCodeEnum,
  } = useTicketsContext()

  const {
    saveDraftTicketFields,
    sysId,
    draftTicketFields,
    resetDraftTicketFields,
    isEditable,
    clientSideErrors,
    resetClientSideErrorsField,
  } = useTicketEditContext()

  const [isResolutionModalOpen, setIsResolutionModalOpen] = useState(false)

  // Impact uses same enum as urgency
  const initialImpactEnum = findEnumLabelAndNumber(data.impact, urgencyEnum)

  const initialValues = {
    customerValidateState: data.customerValidateState,
    state: data.state,
    impact: initialImpactEnum
      ? `${initialImpactEnum?.value} - ${initialImpactEnum?.label}`
      : '',
  }

  const { setFieldValue, values } = useFormik({
    initialValues,
    onSubmit: () => {},
  })

  // Ensure when when data passed to component is updated that the values displayed get updated
  useEffect(() => {
    Object.keys(data).forEach((key) => setFieldValue(key, data[key]))
  }, [data, setFieldValue])

  const updatedData = Object.assign({}, data)

  updatedData['age'] = formatDistanceToNow(parseISO(updatedData.sysCreatedOn))

  const setPlaceHolderForForm = (key: string): string => {
    switch (key) {
      case 'impact':
        return initialValues.impact || '--'
      case 'state':
        return initialValues.state || '--'
      case 'customerValidateState':
        return initialValues.customerValidateState || '--'
      default:
        return '--'
    }
  }

  const StatusFormFields = (): JSX.Element => {
    const selectFieldKeys: string[] = [
      'impact',
      'state',
      'customerValidateState',
    ]

    const statesWithCloseEnabled: string[] = [
      TicketStateEnum.Resolved,
      TicketStateEnum.Cancelled,
      TicketStateEnum.Closed,
    ]

    const availableStateEnums = statesWithCloseEnabled.includes(
      initialValues.state,
    )
      ? stateEnum
      : stateEnum.filter((enumVal) => enumVal.label !== TicketStateEnum.Closed)

    const enumMap = {
      impact: urgencyEnum,
      state: availableStateEnums,
      customerValidateState: customerValidationEnum,
    }

    return (
      // the TicketStatus component utilizes the saveDraftTicketFields to manage ticket updates and enable the save button onSubmit logic
      <>
        <Formik initialValues={initialValues} onSubmit={() => {}}>
          {() => (
            <Form
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem',
                padding: '1rem 0',
              }}
            >
              {Object.entries(updatedData)
                .filter((key) => selectFieldKeys.includes(key[0]!))
                .sort(([a], [b]) => sortKeysToMatch(a, b, selectFieldKeys))
                .map(([key]) => {
                  const options = [...enumMap[key]]
                    .sort(sortSequenceEnums)
                    .map((enumValue) => ({
                      value: enumValue.label,
                      label:
                        key === 'impact'
                          ? `${enumValue.value} - ${enumValue.label}`
                          : enumValue.label,
                    }))

                  const handleSelectChange = (
                    option: SingleValue<{ label: any; value: any }>,
                  ) => {
                    if (key === 'state' && option?.label === 'Resolved') {
                      setIsResolutionModalOpen(true)
                    } else {
                      setFieldValue(key, option?.label)
                      saveDraftTicketFields({
                        [key]: option?.value,
                      })
                      if (clientSideErrors[key]) {
                        resetClientSideErrorsField(key)
                      }
                    }
                  }

                  const selectedOption = options.find(
                    (selectOption: OptionType) => {
                      const label = String(selectOption.label).toLowerCase()
                      const name = (selectOption.value as string).toLowerCase()
                      const value = values[`${key}`]?.toLowerCase()

                      // If no value is set then the ID for impact is the correct value, if a value is set then name is the correct value
                      // This is because we use the value in the label
                      if (key === 'impact') {
                        return [label, name].includes(value)
                      }

                      return name === value
                    },
                  )

                  const labelText = setPTagsAndLabels(key)

                  const isTicketClosed = data.state === TicketStateEnum.Closed
                  // Customer users are not allowed to edit the impact field
                  const isFieldEditable =
                    key === 'impact' && !isDWEmployee
                      ? false
                      : isEditable && !isTicketClosed

                  return (
                    <Box data-testid={`status-label-${key}`} key={key}>
                      <Typography fontWeight={600} variant="body2">
                        {labelText}
                      </Typography>

                      {isFieldEditable ? (
                        <>
                          <Dropdown
                            key={key}
                            options={options}
                            defaultValue={{
                              label: selectedOption?.label || '--',
                              value: selectedOption?.value || '--',
                            }}
                            onChange={handleSelectChange}
                            {...(selectedOption && {
                              selected: selectedOption,
                            })}
                            isDisabled={!isFieldEditable}
                            id={key}
                            controlStyles={{
                              backgroundColor:
                                theme.palette.mode === 'light'
                                  ? theme.vars.palette.common.white
                                  : theme.vars.palette.secondary.dark,
                              border:
                                clientSideErrors[key] &&
                                `1px solid ${theme.vars.palette.error.light}`,
                            }}
                          />

                          {clientSideErrors[key] && (
                            <Typography
                              variant="body2"
                              color="error"
                              sx={{ paddingTop: 0.5 }}
                            >
                              {clientSideErrors[key]}
                            </Typography>
                          )}
                        </>
                      ) : (
                        <Typography
                          variant="body2"
                          sx={(theme) => ({
                            color: theme.vars.palette.text.primary,
                            ...theme.applyStyles('dark', {
                              color: theme.vars.palette.text.secondary,
                            }),
                          })}
                        >
                          {setPlaceHolderForForm(key)}
                        </Typography>
                      )}
                    </Box>
                  )
                })}
            </Form>
          )}
        </Formik>
        <Dialog
          title="Resolution details"
          isOpen={isResolutionModalOpen}
          onClose={() => setIsResolutionModalOpen(false)}
        >
          <TicketResolutionForm
            closeModal={() => setIsResolutionModalOpen(false)}
            resolutionCodeEnum={resolutionCodeEnum}
            sysId={sysId}
            ticketNumber={data.ticketNumber}
            draftTicketFields={draftTicketFields}
            resetDraftTicketFields={resetDraftTicketFields}
          />
        </Dialog>
      </>
    )
  }

  const TicketStatusLabelsAndForm = (): JSX.Element => {
    const firstKeys: string[] = ['assignedUser', 'priority']
    const secondKeys: string[] = ['age', 'lastUpdateUser', 'sysCreatedOn']
    const assignedUserType = data.assignedToDeepwatch ? 'dw' : 'cust'

    return (
      <>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          {Object.entries(updatedData)
            .filter((key) => firstKeys.includes(key[0]))
            .sort(([a], [b]) => sortKeysToMatch(a, b, firstKeys))
            .map(([key, value]) => {
              const initialImpactEnum = findEnumLabelAndNumber(
                value,
                priorityEnum,
              )

              return (
                <Box data-testid={`status-label-${key}`} key={key}>
                  <Typography
                    fontWeight={600}
                    variant="body2"
                    color="textPrimary"
                  >
                    {setPTagsAndLabels(key)}
                  </Typography>

                  {returnUserAvatarOrString(
                    key,
                    key === 'priority' && initialImpactEnum
                      ? `${initialImpactEnum?.value} - ${initialImpactEnum?.label}`
                      : '',
                    data.assignedUser && {
                      firstName: data.assignedUser.split(' ')[0],
                      lastName: data.assignedUser.split(' ')[1],
                      type: data.assignedUserEmail
                        ? avatarVariant(data.assignedUserEmail)
                        : assignedUserType,
                    },
                  )}
                </Box>
              )
            })}
        </Box>

        <StatusFormFields />

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          {Object.entries(updatedData)
            .filter((key) => secondKeys.includes(key[0]))
            .sort(([a], [b]) => sortKeysToMatch(a, b, secondKeys))
            .map(([key, value]) => {
              return (
                <Box data-testid={`status-label-${key}`} key={key}>
                  <Typography
                    fontWeight={600}
                    variant="body2"
                    color="textPrimary"
                  >
                    {setPTagsAndLabels(key)}
                  </Typography>
                  {returnUserAvatarOrString(key, `${value}`)}
                </Box>
              )
            })}
        </Box>
      </>
    )
  }

  return <TicketStatusLabelsAndForm />
}

export default TicketStatusForm
