import { useContext, useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import { Loader } from '../../../design-system'
import {
  DetectionCoverageOverviewResponse,
  DetectionCoverageOverviewVariables,
} from '../../../models/DetectionCoverage'
import { renderErrorToast } from '../../../utils'
import { Context } from '../../App'
import Bans from './Bans'
import MitreHeader from './Mitre/MitreHeader'
import MitreCoverageTable from './Mitre/MitreCoverageTable'
import {
  formatOverviewResponse,
  getDetectionCoverageDisplayValues,
  getMitreOverviewData,
} from './utils'
import MitreCoverageList from './Mitre/MitreList/MitreCoverageList'
import { useMitreOverviewContext } from './context/MitreOverviewContext'
import { LogSourceVolumeLineChart } from '../charts/LogSourceVolume/LogSourceVolume'
import MitreOverview from './Mitre/MitreOverview'
import { SideSheet } from '../../SideSheet'
import { GET_DETECTION_COVERAGE_OVERVIEW } from '../../../graphql/queries/detection'

import './Overview.scss'

interface OverViewProps {
  handleDetectionLibNav: (status: string) => void
}

export default function Overview({
  handleDetectionLibNav,
}: OverViewProps): JSX.Element {
  const [searchParams] = useSearchParams()
  const { dispatch } = useContext(Context)
  const {
    setCoverage,
    tooltipRef,
    detectionCoverageOverview,
    setDetectionCoverageOverview,
    sideSheetData,
    isSideSheetOpen,
  } = useMitreOverviewContext()

  const [isTableVisible, setIsTableVisible] = useState(true)

  const selectedCustomer = searchParams.get('customer') as string

  const { loading, data: responseData } = useQuery<
    { getDetectionCoverageOverview: DetectionCoverageOverviewResponse },
    DetectionCoverageOverviewVariables
  >(GET_DETECTION_COVERAGE_OVERVIEW, {
    variables: {
      selectedCustomer,
    },
    onError: (error) => {
      renderErrorToast(error, dispatch)
    },
    onCompleted: ({ getDetectionCoverageOverview }) =>
      setDetectionCoverageOverview(
        formatOverviewResponse(getDetectionCoverageOverview),
      ),
  })

  const displayValues = useMemo(
    () =>
      detectionCoverageOverview
        ? getDetectionCoverageDisplayValues(detectionCoverageOverview)
        : undefined,
    [detectionCoverageOverview],
  )
  const mitreOverviewData = useMemo(
    () =>
      detectionCoverageOverview
        ? getMitreOverviewData(detectionCoverageOverview)
        : undefined,
    [detectionCoverageOverview],
  )

  useEffect(() => {
    if (displayValues?.coverage) {
      setCoverage(displayValues.coverage)
    }
  }, [displayValues, setCoverage])

  let mitreComponentToRender: JSX.Element

  if (!detectionCoverageOverview) {
    mitreComponentToRender = (
      <div
        data-testid="mitreCoverage-loader"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: 1034,
        }}
        id="mitre-coverage-container"
      >
        <Loader strokeWidth={1} size={100} />
      </div>
    )
  } else if (isTableVisible) {
    mitreComponentToRender = <MitreCoverageTable />
  } else {
    mitreComponentToRender = <MitreCoverageList />
  }

  return (
    <div
      id="detection-coverage-overview"
      className="flex-container column gap-24"
    >
      {/* Bans */}
      <div className="flex-container gap-16 wrap" data-testid="overview-bans">
        <Bans
          handleDetectionLibNav={handleDetectionLibNav}
          isLoading={loading}
          detectionCoverageData={
            displayValues && responseData
              ? {
                  ...displayValues,
                  splunkUtilization:
                    responseData.getDetectionCoverageOverview.splunkUtilization,
                }
              : undefined
          }
        />
      </div>
      <div className="flex-container gap-16 wrap">
        <section
          className="flex-container column gap-24"
          style={{ flexGrow: 3 }}
        >
          {/* MITRE header and table/list */}
          <div className="flex-container column gap-24">
            <MitreHeader
              isTableVisible={isTableVisible}
              setIsTableVisible={setIsTableVisible}
            />
            {mitreComponentToRender}
            {/* Log source volume chart */}
            <LogSourceVolumeLineChart
              chartData={displayValues?.logWeekOverWeekData}
              toolTipDates={displayValues?.toolTipDates}
            />
          </div>
        </section>

        {/* Mitre overview */}
        <MitreOverview
          mitreOverviewData={mitreOverviewData}
          detectionCoverageOverviewData={detectionCoverageOverview}
        />
      </div>
      <SideSheet
        title={sideSheetData.title}
        open={isSideSheetOpen}
        footer={sideSheetData.footer}
        closeSideSheet={sideSheetData.closeSideSheet}
      >
        {sideSheetData.children}
      </SideSheet>
      <div id="mitre-tooltip" ref={tooltipRef} />
    </div>
  )
}
