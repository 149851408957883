import { ColorSystemOptions } from '@mui/material'

export default {
  palette: {
    background: {
      default: '#fff',
    },
    primary: {
      light: '#81baf0',
      main: '#075baa',
      dark: '#053d71',
    },
    secondary: {
      lighter: '#e5e5f3',
      light: '#f7f7ff',
      main: '#bfd0d9',
      dark: '#a4a9ad',
      darker: '#455358',
      contrastText: '#51646a',
    },
    text: {
      primary: '#333',
      secondary: '#f7f7ff',
      disabled: '#a0a0a0',
    },
    success: {
      light: '#2abc7f',
      main: '#239c6a',
      dark: '#1c7c54',
    },
    warning: {
      light: '#ffa622',
      main: '#fa9500',
      dark: '#d37e00',
    },
    error: {
      lighter: '#ff9daa',
      light: '#ff2744',
      main: '#ff0022',
      dark: '#c4001a',
      darker: '#9d0015',
    },
    severity: {
      info: '#333',
      low: '#29b6f6',
      medium: '#ffd046',
      high: '#fa9500',
      critical: '#ff4d85',
    },
    important: {
      main: '#c5a2f6',
      dark: '#7829ea',
    },
    brand: {
      main: '#0c6',
    },
    avatar: {
      cust: 'linear-gradient(rgb(255 153 185 / 100%), rgb(204 0 65 / 100%))',
      dw: 'linear-gradient(rgb(0 204 102 / 100%), rgb(0 102 51 / 100%))',
    },
    card: {
      main: '#a2bbc3',
    },
    info: {
      light: '#4fc3f7',
      main: '#29b6f6',
    },
  },
} as ColorSystemOptions
