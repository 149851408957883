import { differenceInDays } from 'date-fns'

import { mobileWindowWidth } from '../../../../constants/constants'
import {
  Icon,
  IconVariant,
  MobileTable,
  DesktopTable,
  Typography,
  colors,
} from '../../../../design-system'
import { useWindowDimensions } from '../../../../hooks'
import { TicketAdditionalDetailsAttributes } from './TicketAdditionalDetails'
import { TicketPriorityEnum } from '../../../../models'

type RelatedCases = {
  tickets: TicketAdditionalDetailsAttributes[]
}

type RelatedCasesTableColumns =
  | 'ticketSubject'
  | 'priority'
  | 'status'
  | 'type'
  | 'age'
  | 'lastUpdated'

type RelatedCasesTableData = {
  [Property in RelatedCasesTableColumns]: {
    sortValue: string
    displayValue: JSX.Element | string
  }
}

const tableComponents = {
  mobileTable: MobileTable,
  table: DesktopTable,
}

// Should be updated once we know what other icons are possible for the given priority enum
const getPriorityIconVariant = (
  priority: TicketAdditionalDetailsAttributes['priority'],
): IconVariant => {
  switch (priority) {
    case TicketPriorityEnum.Critical:
    default:
      return 'skull'
  }
}

const formatRelatedCasesTableData = (
  tickets: RelatedCases['tickets'],
): RelatedCasesTableData[] => {
  return tickets.map((ticket) => ({
    ticketSubject: {
      sortValue: ticket.ticketNumber,
      displayValue: (
        <div className="flex column">
          <Typography
            component="span"
            variant="text11semibold"
            color={colors.util.navy[50]}
          >
            {ticket.ticketNumber}
          </Typography>
          <span
            style={{
              fontSize: 14,
              color: colors.util.navy[100],
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {ticket.description}
          </span>
        </div>
      ),
    },
    priority: {
      sortValue: ticket.priority,
      displayValue: (
        <div className="flex gap-4">
          <Icon
            variant={getPriorityIconVariant(ticket.priority)}
            size={18}
            color={colors.util.two.main}
          />
          {ticket.priority}
        </div>
      ),
    },
    status: {
      sortValue: ticket.state,
      displayValue: ticket.state,
    },
    type: {
      sortValue: ticket.type,
      displayValue: ticket.type,
    },
    age: {
      sortValue: ticket.sysCreatedOn,
      displayValue: `${differenceInDays(
        new Date(),
        new Date(ticket.sysCreatedOn),
      )} days`,
    },
    lastUpdated: {
      sortValue: ticket.sysUpdatedOn,
      displayValue: `${differenceInDays(
        new Date(),
        new Date(ticket.sysUpdatedOn),
      )} days ago`,
    },
  }))
}

const RelatedCases: React.FC<RelatedCases> = ({ tickets }) => {
  const { width } = useWindowDimensions()
  const TableComponent =
    width > mobileWindowWidth
      ? tableComponents['table']
      : tableComponents['mobileTable']
  return (
    <TableComponent
      customHeaders={[
        {
          name: 'TICKET SUBJECT',
          sortable: true,
          visible: true,
          columnWidth: '30%',
        },
        {
          name: 'PRIORITY',
          sortable: true,
          visible: true,
          columnWidth: '14%',
        },
        { name: 'STATUS', sortable: true, visible: true, columnWidth: '14%' },
        { name: 'TYPE', sortable: true, visible: true, columnWidth: '14%' },
        { name: 'AGE', sortable: true, visible: true, columnWidth: '14%' },
        {
          name: 'LAST UPDATED',
          sortable: true,
          visible: true,
          columnWidth: '14%',
        },
      ]}
      data={formatRelatedCasesTableData(tickets)}
    />
  )
}

export default RelatedCases
