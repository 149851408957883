const WindowsLogo = () => (
  <svg
    data-testid="windows-logo"
    viewBox={`0 0 18 18`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="icons / logo-windows">
      <path
        id="Vector"
        d="M16.875 9.31641H8.15625V15.6094L16.875 16.875V9.31641Z"
      />
      <path
        id="Vector_2"
        d="M7.59375 9.31641H1.125V14.5898L7.59375 15.5285V9.31641Z"
      />
      <path
        id="Vector_3"
        d="M16.875 1.125L8.15625 2.36953V8.75391H16.875V1.125Z"
      />
      <path
        id="Vector_4"
        d="M7.59375 2.45093L1.125 3.37554V8.75444H7.59375V2.45093Z"
      />
    </g>
  </svg>
)

export default WindowsLogo
