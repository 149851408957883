/* eslint-disable security/detect-object-injection */
import { CellContext } from '@tanstack/react-table'
import { Box, Typography, useTheme } from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'

import Icon from '@common/Icon'
import { useEDRContent } from '@hooks/useEDRContent'

import { EDRAsset } from '..'

import type { EDRAssetTableStatusConfig } from '@hooks/useEDRContent/useEDRContentTypes'

const StatusCell = (props: CellContext<EDRAsset, string>) => {
  const theme = useTheme()

  const { featureDefenderForEndpoint } = useFlags()
  const { assetTableStatusMapping } = useEDRContent()

  const STATUS_MAPPING = {
    containment_pending: {
      color: theme.vars.palette.warning.light,
      icon: 'radioButtonOnSharp',
      text: 'Containment pending',
    },
    contained: {
      color: theme.vars.palette.error.light,
      icon: 'radioButtonOffSharp',
      text: 'Contained',
    },
    lift_containment_pending: {
      color: theme.vars.palette.warning.light,
      icon: 'radioButtonOnSharp',
      text: 'Lift containment pending',
    },
    normal: {
      color: theme.vars.palette.success.light,
      icon: 'radioButtonOnSharp',
      text: 'Normal',
    },
  }

  const value = props.getValue()

  const status: EDRAssetTableStatusConfig | undefined =
    featureDefenderForEndpoint
      ? assetTableStatusMapping[value]
      : STATUS_MAPPING[value]

  const statusMatch = status ?? {
    color: theme.vars.palette.text.primary,
    icon: 'radioButtonOffSharp',
    text: 'Unknown',
  }

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        gap: '2px',
      }}
    >
      <Icon
        size={24}
        sx={{ color: statusMatch.color, flex: 'none' }}
        variant={statusMatch.icon}
      />
      <Typography
        noWrap
        variant="body2"
        sx={(theme) => ({
          color: theme.vars.palette.text.primary,
          ...theme.applyStyles('dark', {
            color: theme.vars.palette.text.secondary,
          }),
        })}
      >
        {statusMatch.text}
      </Typography>
    </Box>
  )
}

export default StatusCell
