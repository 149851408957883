import { useTooltip } from '@nivo/tooltip'

import { BeforeSeparatorInterface } from './ThreatProtectionSummary.models'
import { formatNumber } from './ThreatProtectionSummary.utils'
import { Typography } from '../../../../../design-system'
import { colors } from '../../../../../design-system/theme'

// TODO: refactor to be used in customParts so that it is part of SVG chart and not stand alone HTML
export const customLegend = (data): JSX.Element => {
  const {
    closedCriticalTicketCount,
    closedTicketCount,
    openCriticalTicketCount,
    openTicketCount,
  } = data

  const labelInfo = [
    {
      label: 'Tickets opened',
      value: openTicketCount,
      id: 'open_ticket_count',
    },
    {
      label: 'Critical opened',
      value: openCriticalTicketCount,
      id: 'open_critical_ticket_count',
    },
    {
      label: 'Tickets closed',
      value: closedTicketCount,
      id: 'closed_ticket_count',
    },
    {
      label: 'Critical closed',
      value: closedCriticalTicketCount,
      id: 'closed_critical_ticket_count',
    },
  ]

  return (
    <>
      {labelInfo.map(({ label, value, id }) => {
        return (
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          <div key={id} tabIndex={0}>
            <Typography
              styles={{ marginTop: 0, marginBottom: 4 }}
              color={colors.util.navy[100]}
              size={16}
            >
              {label}
            </Typography>

            <Typography
              styles={{ marginTop: 0, marginBottom: 24 }}
              color={colors.util.navy[50]}
              size={16}
            >
              {value}
            </Typography>
          </div>
        )
      })}
    </>
  )
}

// custom layer function
export const customSeparator = ({ beforeSeparators }): JSX.Element => {
  const separators = beforeSeparators
    .slice(1, 4)
    .map((separator: BeforeSeparatorInterface, index: number) => {
      if (index === 2) {
        return (
          <line
            key={separator.partId}
            x1={separator.x0 + 24}
            x2={separator.x1 + 24}
            y1={separator.y0}
            y2={261}
            stroke={colors.util.navy[300]}
            strokeWidth={1}
          />
        )
      }

      return (
        <line
          key={separator.partId}
          x1={separator.x0}
          x2={separator.x1}
          y1={separator.y0}
          y2={261}
          stroke={colors.util.navy[300]}
          strokeWidth={1}
        />
      )
    })
  return separators
}

// custom layer function
export const CustomParts = (props): JSX.Element => {
  const { showTooltipFromEvent, hideTooltip } = useTooltip()

  const handleMouseMove = (e, part) => {
    const tooltipContent = (
      <div
        style={{
          background: '#27373C',
          border: '0.75px solid #455358',
          boxShadow: '0px 1px 4px rgba(25, 41, 46, 0.4)',
          borderRadius: '3px',
          maxWidth: 220,
        }}
      >
        <p
          style={{
            padding: '4px',
            color: colors.util.navy[100],
            fontWeight: '400',
            fontSize: '12px',
            textAlign: 'left',
          }}
        >
          {getTooltipContent(part)}
        </p>
      </div>
    )
    showTooltipFromEvent(tooltipContent, e)
  }

  const handleMouseLeave = () => {
    hideTooltip()
  }

  const getTooltipContent = (part) => {
    if (part.data.id === 'logged_events_count') {
      return 'The total number of logged events with security value.'
    }
    return `The number of ${part.data.label}`
  }

  return props.parts.slice(0, 3).map((part, index: number) => {
    const area = part.areaPoints.map(({ x, x0, x1, y, y0 }) => {
      return {
        x,
        x0,
        x1,
        y,
        y0: isNaN(y0) ? 0 : y0,
        y1: 150,
      }
    })

    if (part.data.value !== undefined) {
      return (
        <g
          key={part.data.id}
          tabIndex={0}
          data-testid={`tps-chart-part-${index}`}
          aria-label={
            part.data.id === 'logged_events_count'
              ? 'The total number of logged events with security value.'
              : `There were ${formatNumber(
                  part.data.originalValueToDisplay,
                  'long',
                )} ${part.data.label}`
          }
        >
          <defs>{linearGradientByIndex(index, part.data.id)}</defs>
          <path
            d={props.areaGenerator(area)}
            fill={`url(#${part.data.id})`}
            onMouseMove={(e) => handleMouseMove(e, part)}
            onMouseLeave={handleMouseLeave}
          />
          <text
            textAnchor="middle"
            x={part.x}
            y={180}
            fill={colors.util.navy[100]}
            className="tps-label"
          >
            {part.data.label}
          </text>
          <text
            textAnchor="middle"
            x={part.x}
            y={204}
            fill={colors.util.navy[50]}
            className="tps-value"
          >
            {formatNumber(part.data.originalValueToDisplay, 'short')}
          </text>
        </g>
      )
    }
  })
}

const linearGradientByIndex = (
  index: number,
  id: number | string,
): JSX.Element => {
  const gradients = [
    ['#0E4658', '#1c6a84'],
    ['#19627b', '#298bac'],
    ['#20738f', '#3AB6E0'],
  ]
  return (
    <linearGradient id={id.toString()} gradientTransform="rotate(0)">
      <stop
        offset="2.66%"
        stopColor={`${gradients[parseInt(index.toString())][0]}`}
      />
      <stop
        offset="100%"
        stopColor={`${gradients[parseInt(index.toString())][1]}`}
      />
    </linearGradient>
  )
}
