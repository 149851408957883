import { ReactElement } from 'react'
import { DocumentNode } from '@apollo/client'

import { Loader } from '../../../../design-system'
import ComponentError from '../../../common/ComponentError/ComponentError'
import apolloClient from '../../../../config/apolloClient'
import { useTicketsContext, useTicketEditContext } from '../../../../hooks'
import { GetTicketDetailsVariables } from '../../../../graphql/queries/ticket'

interface TicketDataHandlerProps<Response> {
  graphqlQuery: DocumentNode
  renderContent: (data: Response) => ReactElement
}

export default function TicketDataHandler<T>({
  graphqlQuery,
  renderContent,
}: TicketDataHandlerProps<T>) {
  const { fetchError: ticketSettingsError, ticketSettingsDataLoading } =
    useTicketsContext()
  const { sysId, isFetchLoading, fetchError } = useTicketEditContext()
  const data = apolloClient.readQuery<
    { getTicketDetails: T },
    GetTicketDetailsVariables
  >({
    query: graphqlQuery,
    variables: {
      ticketId: sysId,
    },
  })

  /**
   * We check if data is falsy before checking loading states to make sure
   * we show a loading state only if the cache is empty and the data is waiting
   * on the backend
   */
  if (!data && (isFetchLoading || ticketSettingsDataLoading)) {
    return (
      <div className="flex center align-center w-full h-full">
        <Loader strokeWidth={1} size={50} />
      </div>
    )
  }

  if (ticketSettingsError || fetchError || !data) {
    return <ComponentError />
  }

  return renderContent(data.getTicketDetails)
}
