export enum ChangeHistoryType {
  LOG_SOURCE = 'Log Source',
  DETECTION = 'Detection',
}
export const CHANGE_HISTORY_LOG_SOURCE_REMOVED = false
export const CHANGE_HISTORY_LOG_SOURCE_ADDED = true
export enum ChangeHistoryWeight {
  HIGH = 'High',
  MEDIUM = 'Medium',
  LOW = 'Low',
}
export interface SecurityIndexEvent {
  id: number
  dataSource: string
  sourceName: string
  type: ChangeHistoryType
  action: boolean
  weight: ChangeHistoryWeight
  createdAt: Date
}

export enum BanKeysEnum {
  YOUR_INDEX = 'your-index',
  AVG_CUSTOMER_INDEX = 'avg-customer-index',
  AVG_INDUSTRY_INDEX = 'avg-industry-index',
}

export interface SecurityIndexBansMetrics {
  yourIndex: {
    key: BanKeysEnum.YOUR_INDEX
    index?: string
    delta?: number
  }
  avgCustomerIndex: {
    key: BanKeysEnum.AVG_CUSTOMER_INDEX
    index?: string
    delta?: number
  }
  avgIndustryIndex: {
    key: BanKeysEnum.AVG_INDUSTRY_INDEX
    index?: string
    delta?: number
  }
}

export interface IndexDetailsMetric {
  lifetimeIncrease: string
  pastPeriod: number
  unclassifiedLogSources: number
  enabledLogSourcesCount: number
  enabledDetectionsCount: number
  missingDataSourcesCount: number
  range: string
}

export interface Trend {
  index: number
  metricDate: string
}

export interface IndexTrends {
  yourIndex: Trend[]
  avgCustomerTrend: Trend[]
  avgIndustryTrend: Trend[]
  pastPeriodTrend: Trend[]
}

export interface PreTransformedIndexValues {
  logSources: number
  detections: number
  enterpriseCoverage: number
}

export interface YourIndex {
  trends: IndexTrends
  details: IndexDetailsMetric
  preTransformedIndexValues: PreTransformedIndexValues
  unclassifiedLogSourceCount: number
}

export interface UnclassifiedLogSource {
  index: string
  logSource: string
  dataModel: string
  dataType: string[]
}
