import {
  ReportType,
  ThreatIntelReport,
} from '../../../../../models/ThreatIntel'
import { Typography, colors } from '../../../../../design-system'
import ThreatIntelReportsAdvisoryCard from '../ThreatIntelReportsAdvisoryCard'
import { useThreatIntelReportsContext } from '../../context'

import './ThreatIntelReportsHeader.scss'

interface ThreatIntelReportsHeaderProps {
  advisories: ThreatIntelReport[]
}

export const ThreatIntelReportsHeader: React.FC<
  ThreatIntelReportsHeaderProps
> = ({ advisories }) => {
  const { activeReportId, openSideSheet } = useThreatIntelReportsContext()

  return (
    <div className="flex column gap-16">
      <Typography color={colors.util.navy[100]} variant="text9semibold">
        Recent significant cyber events and customer advisories
      </Typography>
      {advisories.length ? (
        <div className="flex wrap gap-16">
          {/* TODO: remove CIB mapping when SON-2984 is added */}
          {advisories
            .filter(
              (advisory) => advisory.type !== ReportType.CYBER_INTEL_BRIEF,
            )
            .map((advisory) => (
              <ThreatIntelReportsAdvisoryCard
                key={advisory.id}
                advisory={advisory}
                onClick={openSideSheet}
                isActive={activeReportId === advisory.id}
              />
            ))}
        </div>
      ) : (
        <Typography
          color={colors.util.navy[50]}
          variant="text11semibold"
          styles={{ margin: '40px auto' }}
        >
          No recent Significant cyber event or Customer advisories
        </Typography>
      )}
    </div>
  )
}
