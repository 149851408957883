import { useContext } from 'react'

import { Context } from '../../../../App/Provider'
import { formatCreatedDate, formatDateAndTime } from '../../TicketLibrary.utils'
import { CommentProps } from '../ActivityTab.utils'
import ActivitySvg from '../../../../../../public/activity-empty-state.svg'
import { Icon, Typography } from '../../../../../design-system'
import { colors } from '../../../../../design-system/theme'

import './ActivityTabMessage.scss'

interface ActivityDetailsProps {
  comment: CommentProps
}

const ActivityTabMessage = ({ comment }: ActivityDetailsProps): JSX.Element => {
  const {
    state: {
      user: { isDWEmployee },
    },
  } = useContext(Context)
  if (comment.emptyState) {
    return (
      <div id="activity-tab-message">
        <div className="activity-tab-empty">
          <img src={ActivitySvg} alt="Empty Activity" />
          <Typography variant="text8" size={22} color={colors.common.white}>
            No activity yet
          </Typography>
        </div>
      </div>
    )
  }

  if (comment.createdDate || comment.openedBy) {
    return (
      <div>
        {comment.openedBy ? (
          <div id="activity-tab-message">
            <div className="chat-bubble-header">
              <div className="user-avatar-container">
                <div className="user-avatar system">
                  <Icon variant="ticketOutline" color={colors.common.black} />
                </div>
              </div>
              <div className="user-content">
                <div>
                  <Typography
                    variant="text11semibold"
                    color={colors.common.white}
                  >
                    {comment?.openedBy}
                  </Typography>
                </div>
                <Typography variant="text12">
                  {comment?.createdDate &&
                    formatCreatedDate(comment?.createdDate)}
                </Typography>
              </div>
            </div>
            <div className="chat-bubble-content">
              <div className="chat-bubble-message">
                <Typography variant="text11" color={colors.common.white}>
                  {`${comment.ticketNumber} created`}
                </Typography>
              </div>
            </div>
          </div>
        ) : (
          <div id="activity-tab-message">
            <div className="chat-bubble-header">
              <div className="user-avatar-container">
                <div className="user-avatar system">
                  <Icon variant="ticketOutline" color={colors.common.black} />
                </div>
              </div>
              <div className="user-content">
                <div>
                  <Typography variant="text11" color={colors.common.white}>
                    {`${comment.ticketNumber} created`}
                  </Typography>
                </div>
                <Typography variant="text12">
                  {comment?.createdDate &&
                    formatCreatedDate(comment?.createdDate)}
                </Typography>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  } else {
    return (
      <div id="activity-tab-message">
        <div className="chat-bubble-header">
          <div className="user-avatar-container">
            <div
              className={`user-avatar ${
                isDWEmployee ? 'employee' : 'customer'
              }`}
            >
              <Typography size={11} weight={700} color="rgba(255, 255, 255, 1)">
                {comment.initials}
              </Typography>
            </div>
          </div>
          <div className="user-content">
            <div>
              <Typography variant="text11semibold" color={colors.common.white}>
                {comment.fullName}
              </Typography>
            </div>
            <Typography variant="text12">
              {formatDateAndTime(comment.time)}
            </Typography>
          </div>
        </div>
        <div className="chat-bubble-content">
          <div className="vl" />
          <div className="chat-bubble-message">
            <Typography variant="text11" color={colors.common.white}>
              {comment.text}
            </Typography>
          </div>
        </div>
      </div>
    )
  }
}

export default ActivityTabMessage
