import { CSSProperties } from 'react'

import { useTheme } from '../../../hooks'
import { ColumnHeader } from '../Table.types'
import { colors } from '../../../theme'
import { SkeletonBlock } from '../../SkeletonBlock'
import { IconButtonProps } from '../../../interfaces'
import { RowHoverElement } from '../RowHoverElement'

export interface TableCellProps {
  tableHeaders: ColumnHeader[]
  keyIndex: number
  tableCellValue: string | number | JSX.Element
  bodyTextColor: string
  disabledCell: boolean
  loading: boolean
  hoverRowElements?: IconButtonProps[]
  tableRowData?: any
  hoverElementClasses?: string
}

const TableCell = ({
  tableHeaders,
  tableCellValue,
  keyIndex,
  bodyTextColor,
  disabledCell,
  loading = true,
  hoverRowElements = [],
  tableRowData,
  hoverElementClasses = '',
}: TableCellProps): JSX.Element => {
  const isDarkTheme = useTheme('dark')
  const tableBodyTextColor = (
    bodyTextColor: string,
    isDarkTheme: boolean,
  ): string => {
    if (bodyTextColor) {
      return bodyTextColor
    } else if (isDarkTheme) {
      return colors.util.one.lighter
    } else {
      return colors.util.one.main
    }
  }

  const tableCellStateStyles = (
    cellValue: any,
    disabled: boolean,
  ): CSSProperties => {
    if (cellValue === 'Error') {
      return {
        color: colors.util.orange[100],
      }
    } else {
      return {
        color: disabled
          ? colors.util.one.light
          : tableBodyTextColor(bodyTextColor, isDarkTheme),
      }
    }
  }

  return (
    <div
      role={'cell'}
      key={keyIndex}
      className="table-cell"
      style={tableCellStateStyles(tableCellValue, disabledCell)}
    >
      <>
        <div className="cell-header">
          {tableHeaders.at(keyIndex)?.name?.toUpperCase()}
        </div>
        <div className="cell-value">
          {loading ? (
            <SkeletonBlock />
          ) : (
            <div style={{ display: 'contents' }}>{tableCellValue}</div>
          )}
          {keyIndex === tableHeaders.length - 1 &&
            hoverRowElements.length > 0 && (
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  width: '100%',
                  height: '100%',
                }}
              >
                <RowHoverElement
                  elements={hoverRowElements}
                  elementValue={tableRowData}
                  elementClasses={hoverElementClasses}
                />
              </div>
            )}
        </div>
      </>
    </div>
  )
}

export default TableCell
