import { useContext } from 'react'

import { TicketEditContext } from '../components/Tickets/TicketEdit/context/TicketEditContext'

const useTicketEditContext = () => {
  const ticketEditConext = useContext(TicketEditContext)

  if (!ticketEditConext) {
    throw new Error(
      'TicketEditContext must be used within TicketEditContextProvider',
    )
  }

  return ticketEditConext
}

export default useTicketEditContext
