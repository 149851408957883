import { useMutation } from '@apollo/client'
import { useContext } from 'react'

import { ModalContentProps } from '../../../common/Modal/Modal'
import {
  Button,
  InlineMessageType,
  Typography,
  colors,
} from '../../../../design-system'
import { deployToastMessage } from '../../../../utils'
import { Context } from '../../../App'
import {
  REACTIVATE_USER,
  ReactivateUserData,
  ReactivateUserVariables,
} from '../../../../graphql/mutations/user'
import { GET_USER_MANAGEMENT_SETTINGS } from '../../../../graphql/queries/user'

import './ResendEmailUserModal.scss'

export interface ResendEmailUserModalProps extends ModalContentProps {
  userId: string
}

const ResendEmailUserModal: React.FC<ResendEmailUserModalProps> = ({
  closeModal,
  userId,
}) => {
  const { dispatch: globalDispatch } = useContext(Context)

  const [reactivateUser] = useMutation<
    ReactivateUserData,
    ReactivateUserVariables
  >(REACTIVATE_USER, {
    refetchQueries: [GET_USER_MANAGEMENT_SETTINGS],
  })

  const toggleModal = () => {
    closeModal()
  }

  const displaySuccessToast = () => {
    deployToastMessage(
      {
        id: crypto.randomUUID(),
        text: 'Email has been resent successfully!',
        messageType: InlineMessageType.SuccessInline,
        secondsToExpire: 3000,
        dismissible: false,
      },
      globalDispatch,
    )
  }

  const displayErrorToast = () => {
    deployToastMessage(
      {
        id: crypto.randomUUID(),
        text: 'Resending email to user has failed. Please try again in a minute.',
        messageType: InlineMessageType.ErrorInline,
        secondsToExpire: 3000,
        dismissible: false,
      },
      globalDispatch,
    )
  }

  const handleResendEmailUser = async () => {
    toggleModal()

    await reactivateUser({
      variables: {
        input: {
          sendEmail: true,
          userId,
        },
      },
      onCompleted: displaySuccessToast,
      onError: displayErrorToast,
    })
  }
  return (
    <div id="resend-email-user-modal" data-testid="resend-email-user-modal">
      <Typography
        weight={600}
        color={colors.util.one.lighter}
        component="div"
        size={16}
      >
        {'Are you sure you want to send the invite email again?'}
      </Typography>
      <span
        key={'B'}
        style={{
          display: 'flex',
          marginTop: 25,
        }}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'row',
          gap: 16,
        }}
        data-testid="user-status-modal-buttons"
      >
        <Button
          testId="user-status-cancel-btn"
          variant="secondary"
          type="button"
          onClick={() => toggleModal()}
          buttonStyles={{ width: 160, justifyContent: 'center' }}
        >
          Cancel
        </Button>
        <Button
          variant={'primary'}
          type={'button'}
          buttonStyles={{ width: 160, justifyContent: 'center', fontSize: 16 }}
          testId={'user-status-button'}
          onClick={() => handleResendEmailUser()}
          label={'Send Email'}
        />
      </div>
    </div>
  )
}

export default ResendEmailUserModal
