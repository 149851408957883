import {
  colors,
  Icon,
  MobileTable,
  DesktopTable,
  Tooltip,
  Typography,
} from '../../../../design-system'
import { mobileWindowWidth } from '../../../../constants/constants'
import { useWindowDimensions } from '../../../../hooks'
import ComponentError from '../../../common/ComponentError/ComponentError'

import './TopLogSourcesByUsage.scss'

export interface TopLogSourcesByUsageData {
  source: string
  usageGb: number | null
  alertCount: number
}

export interface TopLogSourcesByUsageProps {
  data: TopLogSourcesByUsageData[]
  loading: boolean
  dateFilterableStyle: boolean
}

const getLogSourceHeaders = () => {
  const headers = [
    { name: 'LOG SOURCE', sortable: false, visible: true },
    { name: 'AVG. LICENSE USAGE', sortable: false, visible: true },
    { name: '# ALERTS', sortable: false, visible: true },
  ]
  return headers
}

const TopLogSourcesByUsage = ({
  data,
  loading,
  dateFilterableStyle,
}: TopLogSourcesByUsageProps): JSX.Element => {
  const { width } = useWindowDimensions()

  const generateTitle = () => {
    if (!loading && data && data.length > 0) {
      return `Top ${data.length} log sources by usage`
    }

    return `Top log sources by usage`
  }

  const TableComponent = () => {
    if (!loading && !data) {
      return <ComponentError />
    }

    const logSourcesData = data.map((log) => {
      return {
        logSource: {
          sortValue: log.source.toLowerCase(),
          displayValue: (
            <Typography variant="text11" size={14} color={colors.util.navy[50]}>
              {log.source}
            </Typography>
          ),
        },
        licenseUsage: {
          sortValue: log.usageGb,
          displayValue: (
            <Typography variant="text11" size={14} color={colors.util.navy[50]}>
              {log.usageGb ? log.usageGb.toFixed(2) + ' GB' : '<1GB'}
            </Typography>
          ),
        },
        alerts: {
          sortValue: log.alertCount,
          displayValue: (
            <Typography variant="text11" size={14} color={colors.util.navy[50]}>
              {log.alertCount}
            </Typography>
          ),
        },
      }
    })

    if (width <= mobileWindowWidth) {
      return (
        <MobileTable
          data={logSourcesData}
          loading={loading}
          loadingRows={5}
          customHeaders={getLogSourceHeaders()}
          sortOptions={{ defaultSortColumn: 1, sortDirection: 'DESC' }}
        />
      )
    }

    return (
      <DesktopTable
        customHeaders={getLogSourceHeaders()}
        data={logSourcesData}
        loading={loading}
        loadingRows={5}
        sortOptions={{ defaultSortColumn: 1, sortDirection: 'DESC' }}
      />
    )
  }

  return (
    <div
      id="top-log-sources-by-usage"
      className="log-sources-table"
      data-testid="top-log-sources-by-usage"
    >
      <div className="content-bar">
        <Tooltip
          target={
            <div className="content-title">
              <Typography
                component="span"
                color={colors.util.navy[100]}
                variant="text9semibold"
              >
                {generateTitle()}
              </Typography>

              {!loading && !data && (
                <Icon
                  variant="warningOutline"
                  size={20}
                  color={colors.util.orange[100]}
                />
              )}
            </div>
          }
        >
          The daily average usage per top log sources.
        </Tooltip>
      </div>
      <div
        className={`${
          !dateFilterableStyle ? 'table-border' : 'table-date-border'
        }`}
      >
        {TableComponent()}
      </div>
    </div>
  )
}

export default TopLogSourcesByUsage
