import { useContext, MouseEvent } from 'react'
import { useSearchParams } from 'react-router-dom'
import { LazyQueryExecFunction, useMutation } from '@apollo/client'

import {
  Button,
  InlineMessageType,
  ToastType,
  Typography,
  colors,
} from '../../../design-system'
import { Message, Report } from '../../../models'
import { deployToastMessage, handleDownload } from '../../../utils'
import { Context } from '../../App/Provider'
import { ModalContentProps } from '../../common/Modal'
import {
  DownloadReportData,
  DownloadReportVariables,
  GET_REPORTS,
} from '../../../graphql/queries/report'
import {
  DELETE_REPORT,
  DeleteReportData,
  DeleteReportVariables,
} from '../../../graphql/mutations/report'

import './DeleteReportModal.scss'

export interface DeleteReportModalProps extends ModalContentProps {
  downloadReport: LazyQueryExecFunction<
    DownloadReportData,
    DownloadReportVariables
  >
  report: Report
}

const DeleteReportModal: React.FC<DeleteReportModalProps> = ({
  closeModal,
  downloadReport,
  report,
}) => {
  const appDispatch = useContext(Context).dispatch

  const [searchParams] = useSearchParams()
  const selectedCustomer = searchParams.get('customer')

  const [deleteReport] = useMutation<DeleteReportData, DeleteReportVariables>(
    DELETE_REPORT,
    {
      refetchQueries: [GET_REPORTS],
      onCompleted: () => {
        const success: Message = {
          id: crypto.randomUUID(),
          text: `"${report.name}" has been deleted successfully!`,
          messageType: ToastType.SuccessToast,
          secondsToExpire: 3000,
          dismissible: false,
        }
        deployToastMessage(success, appDispatch)
      },
      onError: () => {
        const error: Message = {
          id: crypto.randomUUID(),
          text: 'Your report has failed to delete, please try again in a minute.',
          messageType: InlineMessageType.ErrorInline,
          secondsToExpire: 3000,
          dismissible: false,
        }
        deployToastMessage(error, appDispatch)
      },
    },
  )

  const cancelAction = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault()
    closeModal()
  }

  const handleDeleteReport = async () => {
    closeModal()

    await deleteReport({
      variables: {
        input: { reportId: report.id },
        selectedCustomer,
      },
    })
  }

  return (
    <div id="delete-report-modal" data-testid="delete-report-modal">
      <Typography
        weight={600}
        color={colors.util.one.lighter}
        component="div"
        size={16}
      >
        Are you sure you want to permanently delete this report?
      </Typography>
      <span
        key={'B'}
        style={{
          display: 'flex',
          marginTop: 50,
        }}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          padding: '0.5rem',
          gap: '1rem',
        }}
        data-testid="delete-report-modal-buttons"
      >
        <Button
          variant="tertiary"
          type="button"
          onClick={() =>
            downloadReport({
              variables: {
                input: { reportId: report.id },
                selectedCustomer,
              },
              onCompleted: ({ downloadReport: path }) => {
                handleDownload(path)
              },
            })
          }
          iconProps={{ position: 'right', variant: 'downloadOutline' }}
        >
          Download backup
        </Button>
        <Button variant="secondary" onClick={(e) => cancelAction(e)}>
          Cancel
        </Button>
        <Button onClick={handleDeleteReport} data-testid="delete-report-button">
          Delete
        </Button>
      </div>
    </div>
  )
}

export default DeleteReportModal
