import { useContext } from 'react'

import { TicketsContext } from '../components/Tickets/context/TicketsContext'

const useTicketsContext = () => {
  const ticketsConext = useContext(TicketsContext)

  if (!ticketsConext) {
    throw new Error('TicketsContext must be used within TicketsContextProvider')
  }

  return ticketsConext
}

export default useTicketsContext
