export const navigateUserType = (
  dwUser: boolean | undefined,
  pathname: string,
  navigate: (path: string) => void,
  shortName?: string | false | undefined,
) => {
  if (dwUser) {
    navigate(`${pathname}${shortName ? `?customer=${shortName}` : ''}`)
  } else {
    navigate(pathname)
  }
}
