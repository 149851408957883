import { Point } from '@nivo/line'

export const getTooltipTitle = (key: string): string => {
  switch (key) {
    case 'customer':
      return 'My Trend'
    case 'customerTrend':
      return 'My Future Trend'
    case 'deepwatch':
      return 'DW Trend'
    case 'deepwatchTrend':
      return 'DW Future Trend'
    default:
      return ''
  }
}

export const formatDate = (date: string): string => {
  const [year, month, day] = date.split('-')
  return `${month}/${day}/${year}`
}

export const dedupeData = (data: Point[]): Point[] => {
  if (data.length > 2) {
    const dedupe = data.filter(
      (point) =>
        point.serieId === 'customerTrend' || point.serieId === 'deepwatchTrend',
    )
    return dedupe
  }

  return data
}
