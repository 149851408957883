import { useSearchParams } from 'react-router-dom'

import { ThreatIntelReportsInput } from '../types'
import { DEFAULT_THREAT_INTEL_REPORTS_INPUT } from '../../ThreatIntel.utils'
import {
  SortDirection,
  ThreatIntelReportSortOptions,
} from '../../../../graphql/queries/threatIntel'

export const useThreatIntelReportsInput = (): ThreatIntelReportsInput => {
  const [searchParams] = useSearchParams()

  if (!searchParams.size) return DEFAULT_THREAT_INTEL_REPORTS_INPUT

  const labels = searchParams.getAll('label')
  const keywordSearch = searchParams.get('keywordSearch')
  const sortBy = searchParams.get('sortBy')
  const sortDirection = searchParams.get('sortDirection')

  return {
    ...(labels.length ? { filters: { labels } } : {}),
    ...(keywordSearch ? { keywordSearch } : {}),
    ...(sortBy
      ? { sortBy: sortBy as ThreatIntelReportSortOptions }
      : { sortBy: DEFAULT_THREAT_INTEL_REPORTS_INPUT.sortBy }),
    ...(sortDirection
      ? { sortDirection: sortDirection as SortDirection }
      : {
          sortDirection: DEFAULT_THREAT_INTEL_REPORTS_INPUT.sortDirection,
        }),
  }
}
