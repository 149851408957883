import { Options } from 'html-to-image/lib/types'
import { Typography } from '@mui/material'
import { captureException } from '@sentry/react'

import { csvDownload, jsonDownload, svgDownload } from '@utils/downloadUtils'
import PopoverContent from '@common/PopoverContent'
import { zIndex } from '@components/App/Styles/zIndex'
import PopoverListItem from '@common/PopoverListItem'
import Icon from '@common/Icon'
import { AlertSeverity, useToast } from '@hooks/useToast'

export enum DownloadType {
  CSV = 'CSV',
  JSON = 'JSON',
  SVG = 'SVG',
}

const defaultDownloadTypes = {
  CSV: 'CSV',
  JSON: 'JSON',
  SVG: 'SVG',
}

interface ChartDownloadMenuProps<T> {
  chartRef: React.RefObject<HTMLDivElement>
  data: object | object[]
  fields?: string[]
  fileName?: string
  floatingStyles: React.CSSProperties
  getFloatingProps: (
    userProps?: React.HTMLProps<HTMLElement>,
  ) => Record<string, unknown>
  handleOutsideClick: VoidFunction
  setFloating: (node: HTMLElement | null) => void
  svgOptions?: Options
  downloadType?: Record<string, DownloadType>
  triggerAsyncDownload?: () => Promise<T[]>
}

const ChartDownloadMenu: React.FC<
  ChartDownloadMenuProps<Record<string, any>>
> = ({
  chartRef,
  data,
  fields,
  fileName = 'chart',
  floatingStyles,
  svgOptions = {},
  getFloatingProps,
  handleOutsideClick,
  setFloating,
  downloadType = defaultDownloadTypes,
  /**
   * Used for data that needs to be requested when a download list item is clicked
   */
  triggerAsyncDownload,
}) => {
  const { handleShowToast } = useToast()
  const handleCSVDownload = async () => {
    try {
      const asyncData = await triggerAsyncDownload?.()
      csvDownload(asyncData ? asyncData : data, fileName, fields)
    } catch (e) {
      captureException(e)
      handleShowToast(
        AlertSeverity.Error,
        'There was an error exporting the data to CSV. Please try again later.',
        3000,
      )
    } finally {
      handleOutsideClick()
    }
  }

  const handleJSONDownload = async () => {
    try {
      const asyncData = await triggerAsyncDownload?.()
      jsonDownload(asyncData ? asyncData : data, fileName)
    } catch (e) {
      captureException(e)
      handleShowToast(
        AlertSeverity.Error,
        'There was an error exporting the data to JSON. Please try again later.',
        3000,
      )
    } finally {
      handleOutsideClick()
    }
  }

  const handleSVGDownload = async () => {
    try {
      await svgDownload(chartRef, fileName, svgOptions)
    } catch (e) {
      captureException(e)
      handleShowToast(
        AlertSeverity.Error,
        'There was an error exporting the data to SVG. Please try again later.',
        3000,
      )
    } finally {
      handleOutsideClick()
    }
  }

  const listItems = [
    {
      id: DownloadType.CSV,
      name: 'Export as CSV',
      leftIcon: 'documentTextOutline',
      onClick: handleCSVDownload,
    },
    {
      id: DownloadType.JSON,
      name: 'Export as JSON',
      leftIcon: 'codeSlashOutline',
      onClick: handleJSONDownload,
    },
    {
      id: DownloadType.SVG,
      name: 'Export as SVG',
      leftIcon: 'imageOutline',
      onClick: handleSVGDownload,
    },
  ]

  const includeDownloadTypes = listItems.filter(
    ({ id }) => id === downloadType[id],
  )

  return (
    <PopoverContent
      floatingStyles={{
        ...floatingStyles,
        backgroundColor: 'transparent',
        zIndex: zIndex.CHART_DOWNLOAD_MENU,
        width: '200px',
      }}
      getFloatingProps={getFloatingProps}
      setFloating={setFloating}
    >
      {includeDownloadTypes.map(({ id, name, leftIcon, onClick }) => (
        <PopoverListItem
          key={id}
          onClick={onClick}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onClick?.()
            }
          }}
        >
          <Icon variant={leftIcon} />
          <Typography variant="body2">{name}</Typography>
        </PopoverListItem>
      ))}
    </PopoverContent>
  )
}

export default ChartDownloadMenu
