import { useTheme } from '@nivo/core'
import { ResponsiveBar } from '@nivo/bar'
import { AxisTickProps } from '@nivo/axes'
import { format as formatDate, parseISO } from 'date-fns/fp'

import { DashboardOpenTicketTrend } from '../../../../../models/Dashboard'
import ComponentError from '../../../../common/ComponentError/ComponentError'
import { Icon, Loader, Typography, colors } from '../../../../../design-system'

import './CriticalHighOpenTickets.scss'
import '../../Dashboard.scss'

interface CriticalHighOpenTicketsProps {
  data: DashboardOpenTicketTrend[]
  loading: boolean
  dateFilterableStyle: boolean
}

type ValidDateFormats = 'd' | 'M/d' | 'MMM d'

export const getFormattedDate = (date: string, format: string) => {
  return formatDate(
    format,
    parseISO(date.indexOf('T') > -1 ? date.split('T')[0] : date),
  )
}

export const getDateFormat = (dataLength: number): ValidDateFormats => {
  if (dataLength > 20) {
    return 'd'
  } else if (dataLength > 7) {
    return 'M/d'
  }

  return 'MMM d'
}

export const maxChartValue = (data): number =>
  Math.max(
    ...data.flatMap((ticket: Omit<DashboardOpenTicketTrend, 'date'>) => {
      return Object.values(ticket)
    }),
  )

export const CustomTick = (tick: AxisTickProps<string>, dataLength: number) => {
  const theme = useTheme()
  const dateFormat = getDateFormat(dataLength)

  return (
    <g transform={`translate(${tick.x},${tick.y + 22})`}>
      <text
        textAnchor="middle"
        dominantBaseline="middle"
        style={{
          ...theme.axis.ticks.text,
          fontSize: 12,
          fill: '#A2BBC3',
        }}
      >
        {getFormattedDate(tick.value, dateFormat)}
      </text>
    </g>
  )
}

export const formatToolTip = (
  data: DashboardOpenTicketTrend,
  isStacked: boolean,
) => {
  const filteredData: { [key: string]: number } = Object.entries(data)
    .filter(([key]) => key !== '__typename' && key !== 'date')
    .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {})

  const totalTickets = (data: Record<string, number>) =>
    Object.values(data).reduce((a, b) => a + b, 0)

  const entries = isStacked
    ? Object.entries(filteredData).reverse()
    : Object.entries(filteredData)

  return (
    <div className="tool-tip" data-testid="open-tickets-tool-tip">
      <Typography
        styles={{
          paddingLeft: '1px',
          color: '#EEF6F9',
          borderBottom: '0.75px solid #455358',
          paddingBottom: 4,
          marginBottom: 4,
        }}
        size={12}
        weight={400}
      >
        {totalTickets(filteredData)} Total tickets
      </Typography>
      {entries.map((item, index) => {
        let priority = item[0].toLowerCase()
        const value = item[1]
        let color = ''
        if (priority.includes('critical')) {
          priority = 'Critical'
          color = colors.util.two.main
        } else if (priority.includes('high')) {
          priority = 'High'
          color = colors.util.orange[200]
        } else if (priority.includes('moderate')) {
          priority = 'Moderate'
          color = colors.util.four.dark
        } else if (priority.includes('low')) {
          priority = 'Low'
          color = colors.brand.secondary.main
        }
        return (
          <div
            key={index}
            style={{
              color: '#A2BBC3',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'start',
              alignItems: 'center',
              gap: 4,
            }}
          >
            <div
              style={{
                height: '12px',
                width: '12px',
                backgroundColor: color,
                borderRadius: '2px',
              }}
            />
            <Typography size={12}>
              {value} {priority}
            </Typography>
          </div>
        )
      })}
    </div>
  )
}

export const themeConfig = {
  grid: {
    line: {
      stroke: '#455358',
      strokeWidth: 1,
    },
  },
  axis: {
    ticks: {
      line: {
        strokeWidth: 0,
      },
      text: {
        fontSize: 12,
        fill: '#A3A3A3',
      },
    },
  },
}

const CriticalHighOpenTickets = ({
  data,
  loading,
  dateFilterableStyle,
}: CriticalHighOpenTicketsProps): JSX.Element => {
  const renderChart = () => {
    if (loading) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          <Loader strokeWidth={2} size={50} />
        </div>
      )
    } else if (!data || data.length === 0) {
      return <ComponentError />
    }
    const isStacked = data.length > 7
    const keys = [
      'openCriticalCount',
      'openHighCount',
      'openModerateCount',
      'openLowCount',
    ]
    const keyColors = [
      colors.util.two.main,
      colors.util.orange[200],
      colors.util.four.dark,
      colors.brand.secondary.main,
    ]
    const tickets = data.map(
      ({
        openCriticalCount,
        openHighCount,
        openModerateCount,
        openLowCount,
      }) => {
        return {
          openCriticalCount,
          openHighCount,
          openModerateCount,
          openLowCount,
        }
      },
    )
    return (
      <div
        style={{
          display: 'flex',
          height: '100%',
          minHeight: 0,
          minWidth: 0,
          width: '99%',
        }}
      >
        <ResponsiveBar
          data={data}
          keys={isStacked ? keys.reverse() : keys}
          indexBy="date"
          isFocusable={true}
          ariaDescribedBy="chot-chart-description"
          barAriaLabel={({ indexValue, id, formattedValue }) =>
            `on ${indexValue} ${id} was ${formattedValue}`
          }
          margin={{
            top: 50,
            right: 19,
            bottom: 36,
            left: 36,
          }}
          padding={isStacked ? 0.6 : 0.35}
          innerPadding={isStacked ? 0 : 4}
          borderWidth={isStacked ? 1 : 0}
          borderColor={colors.util.navy[400]}
          borderRadius={isStacked ? 0 : 2}
          valueScale={{
            type: 'linear',
            max: isStacked ? 'auto' : Math.ceil(maxChartValue(tickets) / 5) * 5,
            min: 0,
          }}
          indexScale={{ type: 'band', round: true }}
          colors={isStacked ? keyColors.reverse() : keyColors}
          colorBy="id"
          enableLabel={false}
          theme={themeConfig}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            renderTick: (tick) => CustomTick(tick, data.length),
            tickPadding: 8,
          }}
          gridYValues={6}
          axisLeft={{
            format: (tick) => Math.floor(tick) === tick && tick,
            tickValues: 6,
          }}
          tooltip={(point) => formatToolTip(point.data, isStacked)}
          groupMode={isStacked ? 'stacked' : 'grouped'}
        />
      </div>
    )
  }

  return (
    <div
      id="critical-high-open-tickets"
      data-testid="critical-high-open-tickets"
      className="chart-container"
    >
      <div className="content-bar">
        <div className="chart-title">
          <Typography
            component="span"
            color={colors.util.navy[100]}
            variant="text9semibold"
          >
            Open tickets by priority
          </Typography>

          {!loading && !data && (
            <Icon
              variant="warningOutline"
              size={20}
              color={colors.util.orange[100]}
            />
          )}
        </div>

        <div className="chart-info">
          <Icon variant="square" color={colors.util.two.main} />
          <Typography
            component="span"
            size={14}
            styles={{ margin: '0 24px 0 6px' }}
          >
            Critical
          </Typography>
          <Icon variant="square" color={colors.util.orange[200]} />
          <Typography
            component="span"
            size={14}
            styles={{ margin: '0 24px 0 6px' }}
          >
            High
          </Typography>
          <Icon variant="square" color={colors.util.four.dark} />
          <Typography
            component="span"
            size={14}
            styles={{ margin: '0 24px 0 6px' }}
          >
            Moderate
          </Typography>
          <Icon variant="square" color={colors.brand.secondary.main} />
          <Typography component="span" size={14} styles={{ marginLeft: 6 }}>
            Low
          </Typography>
        </div>
      </div>
      <div
        className={`${
          !dateFilterableStyle
            ? 'critical-tickets-card component-border'
            : 'critical-tickets-card date-border'
        }`}
      >
        {renderChart()}
      </div>
    </div>
  )
}

export default CriticalHighOpenTickets
