import apolloClient from '../../../config/apolloClient'
import { Ticket } from '../../../models'
import { TicketDataInput } from '../../../graphql/mutations/ticket'
import { EDITABLE_TICKET_FIELDS_FRAGMENT } from '../../../graphql/fragments/ticket'

export const defineColumnKeys = (data): [string[], string[]] => {
  const columnKeys = Object.keys(data)
  const arrHalfVal = Math.floor(columnKeys.length / 2)
  return [
    columnKeys.slice(0, arrHalfVal),
    columnKeys.slice(arrHalfVal, columnKeys.length),
  ]
}

export const setPTagsAndLabels = (key: string): string => {
  if (!key) {
    return ''
  }

  const pTagsAndLabelsMap = {
    state: 'Status',
    lastupdateuser: 'Last updated by',
    syscreatedon: 'Created at',
    customervalidatestate: 'Customer validation state',
  }

  const lowerCaseString = key
    .split(/(?=[A-Z])/)
    .map((s) => s.toLowerCase())
    .join(' ')

  return (
    pTagsAndLabelsMap[key.toLowerCase()] ||
    lowerCaseString.charAt(0).toUpperCase() + lowerCaseString.slice(1)
  )
}

const customerDateLabelMap = {
  dateCreated: 'Created',
  customerAckTime: 'Customer Acknowledge Time',
  customerAssignTime: 'Customer Assignment Time',
  customerValidationTime: 'Customer Validation Time',
}

export const formatCustomerDateLabels = (label: string): string =>
  // eslint-disable-next-line security/detect-object-injection
  customerDateLabelMap[label] || '--'

export const hasValidDraftTicketFields = (
  draftTicketFields: Partial<TicketDataInput>,
) => Object.values(draftTicketFields).every(Boolean)

export const updateTicketInCache = ({
  newFields,
  ticketId,
}: {
  ticketId: string
  newFields: Partial<Ticket>
}) => {
  const ticketCacheId = apolloClient.cache.identify({
    sysId: ticketId,
    __typename: 'Ticket',
  })

  apolloClient.cache.updateFragment(
    { fragment: EDITABLE_TICKET_FIELDS_FRAGMENT, id: ticketCacheId },
    (data) => ({
      ...data,
      ...newFields,
      sysId: ticketId,
      comments: [data?.comments, newFields?.comments].flat().filter(Boolean),
      sysUpdatedOn: new Date().toISOString(),
    }),
  )
}

export const makeMttxColumnLabels = (label: string): string => {
  switch (label) {
    case 'dateCreated':
      return 'Created'
    case 'customerAssignmentTime':
      return 'Customer Assignment Time'
    case 'customerAcknowledgeTime':
      return 'Customer Acknowledge Time'
    case 'customerValidationTime':
      return 'Customer Validation Time'
    case 'timeToCloseSec':
      return 'MTTC'
    case 'timeToCustomerAcknowledgeSec':
      return 'MTTA'
    case 'timeToCustomerAssignSec':
      return 'MTTca'
    case 'timeToCustomerValidateSec':
      return 'MTTcv'
    case 'timeToDetectionSec':
      return 'MTTD'
    case 'timeToDeclaredIncidentSec':
      return 'MTTdi'
    case 'timeToDetectResolveAlertSec':
      return 'MTTDRv'
    case 'timeToInvestigateSec':
      return 'MTTI'
    case 'timeToNotifySec':
      return 'MTTN'
    case 'timeToResolveTicketSec':
      return 'MTTRv'
    case 'timeToUpdateSec':
      return 'MTTU'
    case 'totalTimeDeepwatchWaitVendorSec':
      return 'MTTRdwV'
    case 'totalTimeDeepwatchWaitCustomerSec':
      return 'MTTcw'
    case 'timeToRespondSec':
      return 'MTTR'
    default:
      return '--'
  }
}
