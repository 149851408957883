import { format, subDays } from 'date-fns'

import {
  createTrendData,
  calculateAvgScore,
  calculateScoreDiff,
  hasCustomerData,
} from './MaturityScore.utils'
import MaturityLine from './MaturityLine'
import { DashboardMaturity } from '../../../../../models/Dashboard'
import { formatMaturityData } from '../../../../../utils/MaturityModelFunctions'
import ComponentError from '../../../../common/ComponentError/ComponentError'
import { Icon, Typography, Loader, colors } from '../../../../../design-system'

import './MaturityScore.scss'
import '../../Dashboard.scss'

export interface MaturityScoreProps {
  loading: boolean
  data: DashboardMaturity
  dateFilterableStyle: boolean
}

const MaturityScore = ({
  data,
  loading,
  dateFilterableStyle,
}: MaturityScoreProps): JSX.Element => {
  const renderMaturityChart = () => {
    if (!data || !data.customer.length) {
      return (
        <div className="score-error">
          <ComponentError />
        </div>
      )
    }

    //get length of customer data array before formatting to handle single day use case
    const maturityTrendLength = data.customer.length

    const formattedData = formatMaturityData(data)
    //dates used to determine if global date filter is beyond the past 1 month limit to show future trend
    const currentDate = new Date()
    const previousMonthDate = format(subDays(currentDate, 31), 'yyyy-MM-dd')
    const startingDate = format(Date.parse(data.customer[0].date), 'yyyy-MM-dd')

    if (formattedData[0].data.length > 2 && startingDate >= previousMonthDate) {
      const customerTrend = createTrendData(formattedData[0].data)
      const deepwatchTrend = createTrendData(formattedData[1].data)

      formattedData[3].data = customerTrend
      formattedData[4].data = deepwatchTrend
    }

    const custAvg = calculateAvgScore(formattedData[0].data)
    const dwAvg = calculateAvgScore(formattedData[1].data)

    const scoreDiff = Number(calculateScoreDiff(custAvg, dwAvg))

    const scoreDiffValue = isNaN(scoreDiff) ? ' ' : scoreDiff

    return (
      <>
        <div aria-hidden={true}>
          <Typography
            styles={{ margin: '16px 0 5px 20px', textAlign: 'left' }}
            component="p"
            size={12}
            color={colors.util.navy[50]}
            weight={500}
          >
            YOUR TREND VS DW AVERAGE
          </Typography>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}
        >
          <Typography
            styles={{
              paddingLeft: '20px',
            }}
            component="span"
            size={24}
            color={'#EEF6F9'}
            weight={600}
          >
            {custAvg}
          </Typography>

          {scoreDiff > 0 && (
            <Icon
              size={18}
              variant="arrowUpCircleOutline"
              style={{ margin: '0 2px 0 8px' }}
            />
          )}

          {scoreDiff < 0 && (
            <Icon
              size={18}
              variant="arrowDownCircleOutline"
              style={{ margin: '0 2px 0 8px' }}
            />
          )}

          {scoreDiff === 0 && '       '}

          {
            <Typography size={12} weight={600} component="span">
              {scoreDiffValue}
            </Typography>
          }
        </div>

        <p id="ms-chart-label" hidden>
          Your maturity score. Your average is {custAvg}. Deepwatch average is{' '}
          {dwAvg}. The difference between your score and the Deepwatch score is{' '}
          {scoreDiff}
        </p>
        {!loading && (
          <MaturityLine
            dataMaturityLength={maturityTrendLength}
            data={formattedData}
          />
        )}
      </>
    )
  }

  return (
    <div id="dashboard-maturity-score">
      <div className="content-bar">
        <Typography variant={'text9semibold'}>Maturity score</Typography>
        {!loading && (!data || !hasCustomerData(data)) && (
          <Icon
            variant="warningOutline"
            size={20}
            color={colors.util.orange[100]}
          />
        )}
      </div>

      <div
        className={`${
          !dateFilterableStyle
            ? 'maturity-score-card component-border'
            : 'maturity-score-card date-border'
        }`}
      >
        <div
          id="dashboard-maturity-score-chart"
          data-testid="maturity-score"
          aria-labelledby="ms-chart-label"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          {loading ? (
            <div
              data-testid="maturityScore-loader"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
              }}
            >
              <Loader strokeWidth={2} size={50} />
            </div>
          ) : (
            renderMaturityChart()
          )}
        </div>
      </div>
    </div>
  )
}

export default MaturityScore
