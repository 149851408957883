import { Icon, Typography, Toggle, colors } from '../../../../design-system'
import { handleKeyBoardAction } from '../../../../utils'

interface DetectionCatalogControls {
  handleOpenFilterSideSheetClick: () => void
  handleIncludeToggle: (toggle: boolean) => void
  toggleState: boolean
  loading: boolean
}

const DetectionCatalogControls = ({
  handleOpenFilterSideSheetClick,
  handleIncludeToggle,
  toggleState,
  loading,
}) => {
  return (
    <div className="detection-catalog-controls">
      <Toggle
        label="Include unavailable detections"
        labelStyles={{ margin: 0, padding: '0 2em 0 0' }}
        check={toggleState}
        inputTestId="include-unavailable-detections"
        onClick={handleIncludeToggle}
        onKeyUp={(event, checked) =>
          handleKeyBoardAction(event, handleIncludeToggle, checked)
        }
        disable={loading}
      />
      <div
        tabIndex={0}
        role={'button'}
        onKeyDown={(event) =>
          handleKeyBoardAction(event, handleOpenFilterSideSheetClick)
        }
        data-testid="detection-catalog-controls-filter-button"
      >
        <Typography
          onClick={handleOpenFilterSideSheetClick}
          weight={600}
          color={colors.brand.secondary.main}
          component="span"
          styles={{
            display: 'flex',
            alignItems: 'center',
            gap: 8,
            cursor: 'pointer',
          }}
        >
          Filter
          <Icon variant={'filter'} color={colors.brand.secondary.main} />
        </Typography>
      </div>
    </div>
  )
}

export default DetectionCatalogControls
