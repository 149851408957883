import { startOfMonth, endOfMonth, add, sub } from 'date-fns'

export const initialStartDate = startOfMonth(sub(new Date(), { months: 2 }))

export const initialEndDate = endOfMonth(add(initialStartDate, { months: 1 }))

export const isBeforeStartDate = (startDate: Date, date: Date): boolean => {
  return date < startDate
}
export const isAfterEndDate = (endDate: Date, date: Date): boolean => {
  return date > endDate
}
