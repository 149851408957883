import { OktaAuth, OktaAuthOptions } from '@okta/okta-auth-js'

import { oktaIssuer } from './OktaEnvs'

export enum SessionStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export const oidcOktaConfig: OktaAuthOptions = {
  clientId: process.env.OKTA_CLIENT_ID,
  issuer: oktaIssuer
    ? process.env.ISSUER
    : 'https://deepwatchdev.oktapreview.com',
  redirectUri: process.env.REDIRECT_URI ? process.env.REDIRECT_URI : '',
  pkce: true,
  scopes: [
    'openid',
    'email',
    'profile',
    'groups',
    'okta.groups.manage',
    'okta.users.manage',
  ],
}

export const oktaAuthConfig = new OktaAuth(oidcOktaConfig)
