import {
  FieldFunctionOptions,
  InMemoryCache,
  InMemoryCacheConfig,
} from '@apollo/client'
import cloneDeep from 'lodash.clonedeep'

import { Ticket, TicketData } from '../../models/Tickets'
import { mergeUtil } from './apolloUtils'
import { ThreatIntelReport } from '../../models/ThreatIntel'
import { ThreatIntelReportsData } from '../../graphql/queries/threatIntel'

const inMemoryCacheConfig: InMemoryCacheConfig = {
  typePolicies: {
    Query: {
      fields: {
        getTickets: {
          keyArgs: [
            '$selectedFilters',
            '$selectedCustomer',
            '$selectedSortings',
          ],
          merge: (
            existing: TicketData,
            incoming: TicketData,
            opts: FieldFunctionOptions,
          ): TicketData => {
            if (!existing) {
              return incoming
            }

            const mergedTickets = mergeUtil<Ticket>(
              existing.tickets,
              incoming.tickets,
              opts,
            )

            const updatedGetTickets: TicketData = cloneDeep(incoming)
            updatedGetTickets.tickets = mergedTickets

            return updatedGetTickets
          },
        },
        // https://www.apollographql.com/docs/react/caching/advanced-topics/#cache-redirects
        getTicketDetails: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'Ticket',
              sysId: args?.input.ticketId,
            })
          },
        },
        getThreatIntelReports: {
          keyArgs: ['$selectedCustomer', '$input'],
          merge: (
            existing: ThreatIntelReportsData,
            incoming: ThreatIntelReportsData,
            opts: FieldFunctionOptions,
          ): ThreatIntelReportsData => {
            if (!existing) {
              return incoming
            }

            const mergedReports = mergeUtil<ThreatIntelReport>(
              existing.threatIntelReports,
              incoming.threatIntelReports,
              opts,
            )

            const updatedGetThreatIntelReports: ThreatIntelReportsData =
              cloneDeep(incoming)
            updatedGetThreatIntelReports.threatIntelReports = mergedReports

            return updatedGetThreatIntelReports
          },
        },
        getThreatIntelReport: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'ThreatIntelReport',
              id: args?.reportId,
            })
          },
        },
      },
    },
    Ticket: {
      keyFields: ['sysId'],
    },
  },
}

const inMemoryCache = new InMemoryCache(inMemoryCacheConfig)

export default inMemoryCache
