import { gql } from '@apollo/client'

import { CustomerHighlight, DeepwatchExpert } from '../../models'

export interface GetDeepwatchExpertsResponse {
  getBookmarkedCustomers: Array<CustomerHighlight>
  getSquadDashboard: Array<CustomerHighlight>
}

export interface GetMyDeepwatchExpertsData {
  getMySquad: Array<DeepwatchExpert>
}

export interface GetMyDeepwtachExpertsVariables {
  selectedCustomer: string | null
}

export const GET_DW_EXPERTS_HOME_DATA = gql`
  query GetDeepwatchExpertsHome {
    getBookmarkedCustomers {
      customerShortName
      name
      usageGb
      usageLicenseGb
      openCriticalCount
      openWaitingDeepwatchCount
      isBookmarkedCustomer
    }
    getSquadDashboard {
      customerShortName
      name
      usageGb
      usageLicenseGb
      openCriticalCount
      openWaitingDeepwatchCount
      isBookmarkedCustomer
    }
  }
`

export const GET_DEEPWATCH_EXPERTS_DASHBOARD = gql`
  query GetDeepwatchExpertsDashboard {
    getSquadDashboard {
      customerShortName
      name
      usageGb
      usageLicenseGb
      openCriticalCount
      openWaitingDeepwatchCount
      isBookmarkedCustomer
    }
  }
`

export const GET_MY_DEEPWATCH_EXPERTS = gql`
  query GetMyDeepwatchExperts {
    getMySquad {
      email
      jobTitle
      name
      squadName
      squadUid
    }
  }
`
