import { useTicketEditContext } from '../../../../../hooks'
import { Banner } from '../../../../../design-system'

export const WARNING_TEXT =
  'You do not have permissions to edit the ticket. Please contact your admin for access.'

export const PermissionWarning: React.FC = () => {
  const { isEditable } = useTicketEditContext()

  if (isEditable) return null

  return (
    <Banner
      text={WARNING_TEXT}
      variant="warning"
      styles={{ margin: '0 -24px' }}
    />
  )
}
