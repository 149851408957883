import {
  SortDirection,
  ThreatIntelReportSortOptions,
} from '../../graphql/queries/threatIntel'

export enum ReportTypeLabel {
  CUSTOMER_ADVISORY_SVE = 'Significant cyber event',
  CUSTOMER_ADVISORY_AWARENESS = 'Customer advisory',
  CYBER_INTEL_BRIEF = 'Cyber intel brief',
}

export enum ReportConfidenceLevelLabel {
  LOW = 'Low confidence',
  MEDIUM = 'Med confidence',
  HIGH = 'High confidence',
}

export const DEFAULT_THREAT_INTEL_REPORTS_INPUT = {
  sortBy: ThreatIntelReportSortOptions.Date,
  sortDirection: SortDirection.Descending,
}
