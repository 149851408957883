import { Dispatch } from 'react'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'

import * as models from '../../models'
import { SessionStatus } from '../../config/OktaAuthConfig'

export enum Terms {
  LOADING = 'loading',
  ACCEPTED = 'accepted',
  DECLINED = 'declined',
}

export enum Paths {
  DASHBOARD = '/dashboard',
  DETECTION_COVERAGE = '/detection-coverage',
  DETECTION_CATALOG = '/detection-catalog',
  ERROR = '/error-page',
  LOGIN = '/login',
  COMPANY_INFO = '/settings/company',
  PRODUCTS = '/products',
  PROFILE = '/settings/profile',
  REDIRECT = '/redirect',
  INSIGHTS = '/insights',
  ROOT = '/',
  SECURITY_INDEX = '/security-index',
  CHANGE_HISTORY = '/change-history',
  YOUR_INDEX = '/your-index',
  SETTINGS = '/settings',
  DEEPWATCH_EXPERTS = '/deepwatch-experts',
  TERMS = '/terms-and-conditions',
  TICKETS = '/tickets',
  TICKET_EDIT = '/tickets/:sysId/edit',
  USER_MANAGEMENT = '/settings/user-management',
  THREAT_INTEL_REPORTS = '/threat-intel',
  THREAT_INTEL_FULL_REPORT = '/threat-intel/:reportId',
}

export type AppState = {
  user: models.OktaUser
  customer: models.Customer
  toastMessages: models.Message[]
  globalLoading: boolean
  customerLoading: boolean
  oktaSession: SessionStatus
  dwExpertsActiveTab: number
  dwExpertsCustomer: models.Customer
  termsAccepted: Terms
  homePath: Paths
  theme: string
}

export enum ActionTypes {
  ADD_REPORT = 'addReport',
  ADD_TOAST_MESSAGE = 'addToastMessage',
  DELETE_REPORT = 'deleteReport',
  DELETE_TOAST_MESSAGE = 'deleteToastMessage',
  SET_CUSTOMER = 'setCustomer',
  SET_GLOBAL_LOADING = 'setGlobalLoading',
  SET_CUSTOMER_LOADING = 'setCustomerLoading',
  SET_HOME_PATH = 'setHomePath',
  SET_REPORTS_LOADING = 'setReportsLoading',
  SET_REPORTS_TIMER_ID = 'setReportsTimerId',
  SET_SESSION = 'setSession',
  SET_DW_EXPERTS_ACTIVE_TAB = 'setDwExpertsActiveTab',
  SET_DW_EXPERTS_CUSTOMER = 'setCustomerNames',
  SET_TERMS_ACCEPTED = 'setTermsAccepted',
  SET_USER = 'setUser',
  UPDATE_OKTA_SESSION = 'updateOktaSession',
  UPDATE_REPORTS = 'updateReports',
}

export type AppAction =
  | {
      type: ActionTypes.SET_USER
      user: models.OktaUser
    }
  | {
      type: ActionTypes.SET_SESSION
      status: SessionStatus
    }
  | {
      type: ActionTypes.SET_GLOBAL_LOADING
      globalLoading: boolean
    }
  | {
      type: ActionTypes.SET_CUSTOMER_LOADING
      customerLoading: boolean
    }
  | {
      type: ActionTypes.ADD_TOAST_MESSAGE
      toastMessage: models.Message
    }
  | {
      type: ActionTypes.DELETE_TOAST_MESSAGE
      id: string
    }
  | {
      type: ActionTypes.SET_DW_EXPERTS_ACTIVE_TAB
      dwExpertsActiveTab: number
    }
  | {
      type: ActionTypes.SET_DW_EXPERTS_CUSTOMER
      dwExpertsCustomer: models.Customer
    }
  | {
      type: ActionTypes.SET_TERMS_ACCEPTED
      termsAccepted: Terms
    }
  | {
      type: ActionTypes.SET_HOME_PATH
      homePath: Paths
    }
  | {
      type: ActionTypes.SET_CUSTOMER
      customer: models.Customer
    }
  | {
      type: ActionTypes.SET_REPORTS_TIMER_ID
      id: string
    }

export type AppContext = {
  state: AppState
  dispatch: Dispatch<AppAction>
  client: ApolloClient<NormalizedCacheObject>
}
