import { ApolloError } from '@apollo/client'

import {
  Ticket,
  TicketPriorityEnum,
  TicketStateEnum,
  TicketEditEnum,
} from '../../../models'
import { UserInfo } from '../../common/UserAvatar'
import { TicketDataInput } from '../../../graphql/mutations/ticket'

export type TicketActivityUserType = {
  firstName: string
  lastName: string
  type: string
}

export type TicketStatusData = Pick<
  Ticket,
  | 'assignedUser'
  | 'impact'
  | 'priority'
  | 'state'
  | 'customerValidateState'
  | 'lastUpdateUser'
  | 'sysCreatedOn'
  | 'assignedToDeepwatch'
  | 'ticketNumber'
>

export type MttxDataType = {
  dateCreated: string
  customerAckTime: string
  customerAssignTime: string
  customerValidationTime: string
  mttn: string
  mttcack: string
  mttca: string
  mttcv: string
  mttdi: string
  mttdrv: string
  mttrv: string
  mttc: string
  mttcw: string
  mttdwc: string
  mttndwv: string
}

export type TicketAdditionalDetailsTypes = {
  threatHuntOutcomes?: string
  ioc?: string
  searchName?: string
  incidentType?: string
  mitreTactics?: string
  mitreTechniques?: string
  splunkSearch?: string
  splunkLink?: string
  documentationLink?: string
  soarLink?: string
  vendorName?: string
  jiraSprint?: string
  searchId?: string
  cisControl?: string
  jiraReferenceNumber?: string
  vendorReferenceNumber?: string
  customerReferenceNumber?: string
  soarIncidentNumber?: string
}

//NOTE: available fields for v1
export type AdditionalDetailsRow = {
  data: {
    cisControl?: string | null
    incidentType?: string | null
    splunkLink?: string | null
    documentationLink?: string | null
    soarLink?: string | null
    threatHuntOutcomes?: string | null
    ioc?: string | null
    __typename?: string
  }
}

export type MttxRow = {
  data: {
    customerAssignmentTime?: number | string | null
    customerAcknowledgeTime?: number | string | null
    customerValidationTime?: number | string | null
    timeToCloseSec?: number | string | null
    timeToCustomerAcknowledgeSec?: number | string | null
    timeToCustomerAssignSec?: number | string | null
    timeToCustomerValidateSec?: number | string | null
    timeToDetectionSec?: number | string | null
    timeToDeclaredIncidentSec?: number | string | null
    timeToDetectResolveAlertSec?: number | string | null
    timeToInvestigateSec?: number | string | null
    timeToNotifySec?: number | string | null
    timeToRespondSec?: number | string | null
    timeToResolveTicketSec?: number | string | null
    timeToUpdateSec?: number | string | null
    totalTimeDeepwatchWaitVendorSec?: number | string | null
    totalTimeDeepwatchWaitCustomerSec?: number | string | null
    __typename?: string
  }
}

export type TicketContactData = Pick<
  Ticket,
  | 'serviceOffering'
  | 'assignmentGroup'
  | 'category'
  | 'subcategory'
  | 'channel'
  | 'caseType'
>

export interface TicketResolutionFormProps {
  resolutionCodeEnum: TicketEditEnum[]
  sysId: string
  closeModal?: VoidFunction
  data?: TicketResolutionData
  draftTicketFields?: Partial<TicketDataInput>
  inModal?: boolean
  onChange?: (ticketFields: Partial<TicketDataInput>) => void
  ticketNumber?: string
  resetDraftTicketFields?: VoidFunction
}

export type TicketResolutionFormValues = {
  resolutionCode: string
  resolutionNotes: string
}

export type TicketResolutionData = Pick<
  Ticket,
  'resolutionCode' | 'resolutionNotes'
>

export enum TicketActivityTypeEnum {
  FieldChanges = 'Field changes',
  Comment = 'Additional comments',
  Placeholder = 'Placeholder',
}

export type FieldValues = {
  previousValue?: string
  value: string
}

export type FieldChange = {
  priority: {
    previousValue?: TicketPriorityEnum
    value: TicketPriorityEnum
  }
  state: {
    previousValue?: TicketStateEnum
    value: TicketStateEnum
  }
  customerValidateState: FieldValues
  customerContact: FieldValues
}

export interface BaseTicketActivity {
  createdDate: string
  id: string
  userInfo: UserInfo
}

export interface TicketFieldChangeActivity extends BaseTicketActivity {
  fieldChanges: Partial<FieldChange>[]
  type: TicketActivityTypeEnum.FieldChanges
}

export interface TicketCommentActivity extends BaseTicketActivity {
  error?: ApolloError
  status?: 'pending' | 'error' | 'created'
  text: string
  type: TicketActivityTypeEnum.Comment
}

export interface TicketPlaceholderActivity extends BaseTicketActivity {
  type: TicketActivityTypeEnum.Placeholder
  text: string
}

export type TicketActivityType =
  | TicketFieldChangeActivity
  | TicketCommentActivity
  | TicketPlaceholderActivity

export type TicketEditContextModel = {
  sysId: string
  hasValidChangesToSave: boolean
  saveDraftTicketFields: (ticketFields: Partial<Ticket>) => void
  isSubmissionLoading: boolean
  submissionError: ApolloError | undefined
  submitDraftTicketFields(): Promise<void>
  isFetchLoading: boolean
  fetchError: ApolloError | undefined
  isEditable: boolean
  draftTicketFields: Partial<TicketDataInput>
  resetDraftTicketFields: VoidFunction
}
