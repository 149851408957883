import { Typography, colors } from '../../../../../design-system'

import './Leadership.scss'

const Leadership = () => {
  const dwLeadership = [
    {
      name: 'Charlie Thomas',
      Title: 'CEO',
    },
    {
      name: 'Bobby Christian',
      Title: 'COO',
    },
    {
      name: 'Clayton Mascarenhas',
      Title: 'SVP',
    },
    {
      name: 'Elizabeth Powell',
      Title: 'Sr. Director, Renewals',
    },
  ]

  return (
    <div className="leadership">
      <div data-testid="leadership">
        <Typography
          component="div"
          weight={500}
          size={16}
          color={colors.util.navy[100]}
          styles={{ lineHeight: '160%' }}
        >
          Deepwatch leadership
        </Typography>
      </div>
      <div className="section">
        {dwLeadership.map((companyData, index) => {
          return (
            <div className="item" key={index}>
              <Typography
                component="div"
                size={14}
                color={colors.util.navy[100]}
              >
                {companyData.Title}
              </Typography>
              <Typography
                component="div"
                size={14}
                color={colors.util.navy[50]}
              >
                {companyData.name}
              </Typography>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Leadership
