import { useMitreOverviewContext } from '../context/MitreOverviewContext'
import { Button, Typography, colors } from '../../../../design-system'

interface MitreHeaderProps {
  isTableVisible: boolean
  setIsTableVisible(value: boolean): void
}
export default function MitreHeader({
  isTableVisible,
  setIsTableVisible,
}: MitreHeaderProps) {
  const {
    mitreSelection,
    setMitreSelection,
    setCarouselTechnique,
    handleSideSheet,
    setHasUserClosedSideSheet,
    coverage,
  } = useMitreOverviewContext()

  const handleViewToggle = () => {
    /**
     * when switching to the table view from the list view,
     * while having a subtechnique selected, unset the
     * subtechnique and display the sidesheet for its
     * related technique since the table view does not
     * include a state where users can view subtechniques
     */
    const newTableVisible = !isTableVisible

    setIsTableVisible(newTableVisible)

    if (newTableVisible) {
      return
    }

    const { tactic, technique, subtechnique } = mitreSelection

    setMitreSelection({
      tactic,
      technique,
      subtechnique: undefined,
    })

    if (technique === undefined) {
      setCarouselTechnique(undefined)
    }

    if (tactic !== undefined && subtechnique !== undefined) {
      handleSideSheet(tactic, technique, undefined, () => {
        if (
          technique &&
          // eslint-disable-next-line security/detect-object-injection
          coverage[tactic].techniques[technique].subtechniques.length === 0
        ) {
          setCarouselTechnique(undefined)
        }
        setMitreSelection({
          tactic: undefined,
          subtechnique: undefined,
          technique: undefined,
        })

        setHasUserClosedSideSheet(true)
      })
    }
  }

  return (
    <div className="flex-container space-between align-items-center">
      <Typography component="div" weight={600} size={18}>
        MITRE Coverage
      </Typography>
      <div className="flex-container align-items-center gap-16 flex-grow-0">
        <MitreCoverageKey
          label="Unavailable"
          borderColor={colors.util.navy[300]}
        />
        <MitreCoverageKey label="Available" boxColor={colors.util.navy[300]} />
        <MitreCoverageKey label="Covered" boxColor={colors.util.navy[50]} />
        <Button
          onClick={handleViewToggle}
          variant="navigational-icon"
          iconProps={{
            variant: isTableVisible ? 'reorderFourOutline' : 'keypad',
          }}
        />
      </div>
    </div>
  )
}

interface MitreCoverageKeyProps {
  label: string
  borderColor?: string
  boxColor?: string
}
function MitreCoverageKey({
  label,
  borderColor,
  boxColor = 'transparent',
}: MitreCoverageKeyProps) {
  return (
    <div className="flex-container align-items-center gap-4">
      <div
        data-testid="mitre-coverage-key-color"
        style={{
          border: borderColor ? `1px solid ${borderColor}` : '',
          backgroundColor: boxColor,
          width: 12,
          height: 12,
        }}
      />
      <Typography component="span" weight={400} size={12}>
        {label}
      </Typography>
    </div>
  )
}
