import { LoaderProps } from '../../interfaces'
import { colors } from '../../theme'

const Loader = ({
  centered = false,
  color,
  strokeWidth = 4,
  size = 14,
}: LoaderProps): JSX.Element => {
  const pulseKeyFrame = `#ripple-loader-${size} {
    position: relative;
    width: ${size}px;
    height: ${size}px;
    ${centered ? 'margin-right:auto;margin-left:auto;' : ''}
  }

  #ripple-loader-${size} div {
    position: absolute;
    border: ${strokeWidth}px solid ${color ? color : colors.util.one.light};
    border-radius: 50%;
    animation: ripple-loader-${size} 1.5s ease-out infinite;
  }

  #ripple-loader-${size} div:nth-child(2) {
    animation-delay: -0.5s;
  }

  @keyframes ripple-loader-${size} {
    0% {
      top: ${size / 2}px;
      left: ${size / 2}px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0;
      left: 0;
      width: ${size}px;
      height: ${size}px;
      opacity: 0;
    }
  }`

  return (
    <>
      <style>{pulseKeyFrame}</style>
      <div id={`ripple-loader-${size}`} data-testid="ripple-loader">
        <div></div>
        <div></div>
      </div>
    </>
  )
}

export default Loader
