import { AppAction, AppState } from '../Types'

export const setDwExpertsActiveTab = (
  state: AppState,
  action: AppAction,
): AppState => {
  return {
    ...state,
    dwExpertsActiveTab: action['dwExpertsActiveTab'],
  }
}

export const setCustomerNames = (
  state: AppState,
  action: AppAction,
): AppState => {
  return {
    ...state,
    dwExpertsCustomer: action['dwExpertsCustomer'],
  }
}

export const setCustomerLoading = (
  state: AppState,
  action: AppAction,
): AppState => {
  return {
    ...state,
    customerLoading: action['customerLoading'],
  }
}
