import { useQuery } from '@apollo/client'
import { useMemo, useCallback, useRef, useState } from 'react'

import apolloClient from '@config/apolloClient'
import {
  TicketData,
  TicketFilterInput,
  TicketSortingInput,
} from '@models/Tickets'

import {
  GET_TICKETS_TABLE_VIEW,
  GET_TICKETS_ADDITIONAL_FIELDS,
  FULL_TICKET_FRAGMENT_WITH_CACHE_FIELDS,
  GetTicketsTableViewData,
  GetTicketsAdditionalFieldsData,
  GetTicketsVariables,
} from './ticketLibraryQueries'
import { TicketLibraryGlobalFilters } from './ticketLibraryTypes'

interface UseProgressiveTicketsProps {
  selectedCustomer: string | null
  globalFilters: TicketLibraryGlobalFilters
  selectedFilters: TicketFilterInput
  showAllTickets: boolean
  formattedSorting: TicketSortingInput
  initialLimit?: number
  debug?: boolean
}

interface UseProgressiveTicketsReturn {
  ticketData: TicketData
  loading: boolean
  /** Initial first-page loading state for table data; not updated after first load */
  tableDataLoading: boolean
  /** Initial first-page loading state for additional data; not updated after first load */
  additionalDataLoading: boolean
  /** True while promises for table and additional data are pending */
  fetchingMore: boolean
  tableDataError: Error | undefined
  additionalDataError: Error | undefined
  fetchMore: () => void
}

const initialState: TicketData = {
  filterOptions: {
    keywordSearch: '',
    filters: [
      {
        label: '',
        key: '',
        values: [
          {
            value: '',
            label: '',
            selected: false,
          },
        ],
      },
    ],
    showOpenTicketOnly: true,
  },
  pagination: {
    limit: 25,
    offset: 0,
    total: 0,
  },
  sortingOptions: [
    {
      key: 'sysUpdatedOn',
      order: 'DESC',
    },
  ],
  tickets: [],
}

export const useProgressiveTickets = ({
  selectedCustomer,
  globalFilters,
  selectedFilters,
  showAllTickets,
  formattedSorting,
  initialLimit = 25,
  debug,
}: UseProgressiveTicketsProps): UseProgressiveTicketsReturn => {
  const fetchingMoreRef = useRef(false)
  const [fetchingMore, setFetchingMore] = useState(false)

  const baseVariables: GetTicketsVariables = useMemo(
    () => ({
      pagination: {
        limit: initialLimit,
        offset: 0,
      },
      selectedCustomer,
      selectedFilters: {
        ...globalFilters,
        filters: selectedFilters,
        showOpenTicketOnly: !showAllTickets,
      },
      selectedSortings: formattedSorting,
    }),
    [
      initialLimit,
      selectedCustomer,
      globalFilters,
      selectedFilters,
      showAllTickets,
      formattedSorting,
    ],
  )

  // Query for table data
  const {
    data: { getTickets: ticketTableData } = { getTickets: initialState },
    loading: tableDataLoading,
    error: tableDataError,
    fetchMore: fetchMoreTable,
  } = useQuery<GetTicketsTableViewData, GetTicketsVariables>(
    GET_TICKETS_TABLE_VIEW,
    {
      fetchPolicy: 'cache-and-network',
      variables: baseVariables,
      onCompleted: (data) => {
        if (debug) {
          // eslint-disable-next-line no-console
          console.log('Table data received:', data)
          // eslint-disable-next-line no-console
          console.log('Cache after table data:', apolloClient.extract())
        }
      },
    },
  )

  // Query for additional fields
  const {
    data: { getTicketsDetails: additionalFieldData } = {
      getTicketsDetails: initialState,
    },
    loading: additionalDataLoading,
    error: additionalDataError,
    fetchMore: fetchMoreAdditional,
  } = useQuery<GetTicketsAdditionalFieldsData, GetTicketsVariables>(
    GET_TICKETS_ADDITIONAL_FIELDS,
    {
      fetchPolicy: 'cache-and-network',
      variables: baseVariables,
      onCompleted: (data) => {
        if (debug) {
          // eslint-disable-next-line no-console
          console.log('Additional data received:', data)
          // eslint-disable-next-line no-console
          console.log('Cache after additional data:', apolloClient.extract())
        }
      },
    },
  )

  // Combine table data with additional fields from cache
  const fullTickets = useMemo(() => {
    // Depending on which query finishes first, the full set of tickets may be in either query
    const queryWithMoreTickets =
      additionalFieldData.tickets.length > ticketTableData.tickets.length
        ? additionalFieldData.tickets
        : ticketTableData.tickets
    return (
      queryWithMoreTickets.map((ticket) => {
        const fragment = apolloClient.readFragment({
          fragment: FULL_TICKET_FRAGMENT_WITH_CACHE_FIELDS,
          fragmentName: 'FullTicketFieldsWithCache',
          id: `Ticket:${JSON.stringify({ sysId: ticket.sysId })}`,
        })
        return fragment ?? ticket
      }) ?? []
    )
  }, [ticketTableData.tickets, additionalFieldData.tickets])

  const ticketData = {
    ...ticketTableData,
    tickets: fullTickets,
  }

  // Combined loading state
  const loading = tableDataLoading && !fullTickets.length

  // Fetch more data handler
  const fetchMore = useCallback(() => {
    if (
      !fetchingMoreRef.current &&
      !tableDataLoading &&
      !additionalDataLoading &&
      ticketData.tickets.length < ticketData.pagination.total
    ) {
      fetchingMoreRef.current = true
      setFetchingMore(true)
      const fetchMoreVariables = {
        pagination: {
          limit: ticketData.pagination.limit,
          offset: ticketData.tickets.length,
        },
      }
      Promise.all([
        fetchMoreTable({
          variables: fetchMoreVariables,
        }),
        fetchMoreAdditional({
          variables: fetchMoreVariables,
        }),
      ]).finally(() => {
        fetchingMoreRef.current = false
        setFetchingMore(false)
      })
    }
  }, [
    fetchMoreTable,
    fetchMoreAdditional,
    tableDataLoading,
    additionalDataLoading,
    ticketData.pagination.limit,
    ticketData.pagination.total,
    ticketData.tickets.length,
  ])

  return {
    ticketData,
    loading,
    tableDataLoading,
    additionalDataLoading,
    fetchingMore,
    tableDataError,
    additionalDataError,
    fetchMore,
  }
}
