import { IconVariant, colors } from '../../../design-system'
import { ChangeHistoryType } from '../../../models/SecurityIndex'

export const getIconVariant = (sourceType: ChangeHistoryType): IconVariant => {
  switch (sourceType) {
    case ChangeHistoryType.LOG_SOURCE:
      return 'serverOutline'
    case ChangeHistoryType.DETECTION:
      return 'pulse'
    default:
      return 'downloadOutline'
  }
}

export const getTagColors = (type: string): string => {
  switch (type.toLowerCase()) {
    case 'critical':
      return colors.util.two.main
    case 'high':
      return colors.util.green[100]
    case 'medium':
      return colors.util.four.light
    default:
      return colors.brand.secondary.lighter
  }
}

export const highlightMetric = (
  activeId: string,
  partId: string,
  colorId: string,
): string => {
  if (activeId !== '' && activeId !== partId) {
    return colors.util.five.main
  } else {
    return colorId
  }
}
