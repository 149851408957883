import { LinkProps } from '../../interfaces'
import { Icon } from '../Icon'

import './Link.scss'

const Link = ({
  variant,
  aProps,
  iconProps,
  children,
}: LinkProps): JSX.Element => {
  let className = 'dw-link'
  if (variant && variant !== 'link') {
    className += variant === 'listItem' ? ' list-item' : ' button'
  }
  if (iconProps) {
    className += ' has-icon'
  }

  return (
    <a className={className} {...aProps}>
      {children}
      {iconProps && <Icon {...iconProps} />}
    </a>
  )
}

export default Link
