import {
  createRef,
  CSSProperties,
  RefObject,
  SetStateAction,
  useEffect,
  useState,
} from 'react'

import { useOutsideClick, useTheme } from '../../hooks'
import noop from '../../utils/noop'
import { colors } from '../../theme'
import {
  ListItemSelectType,
  ListItemType,
  ListItemVariant,
} from '../../interfaces'
import { Typography, ListItem, MultiSelectListItem } from '../'

export type MenuVariant = 'select' | 'popover' | 'list' | 'multiSelect'

export interface MenuProps {
  variant?: MenuVariant
  options: ListItemType[]
  selected?: SetStateAction<ListItemType | undefined>
  onSelect?: (option: ListItemType) => void
  handleOutsideClick: () => void
  noMatchesFound?: boolean
  noMoreOptions?: boolean
  styles?: CSSProperties
  searchInput?: string
  wrapperHeight?: number
  id?: string
  zIndex?: number
  toggleRef?: RefObject<HTMLElement>
}

const getBorder = (open: boolean, isDark: boolean) => {
  if (open) {
    return isDark ? `1px solid ${colors.util.navy[50]}` : 'none'
  }
}

const menuContainer = (
  open: boolean,
  isDark: boolean,
  wrapperHeight: number,
  variant?: MenuVariant,
  styles?: CSSProperties,
): CSSProperties => ({
  width: variant === 'popover' ? 'fit-content' : '100%',
  maxWidth: variant === 'popover' ? 'unset' : '100%',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: variant === 'popover' ? 'flex-start' : '',
  marginBlock: '0',
  marginInline: '0',
  paddingInline: '0',
  boxShadow:
    '0px 6px 15px -3px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px rgba(32, 32, 32, 0.05)',
  borderRadius: 5,
  border: getBorder(open, isDark),
  backgroundColor: colors.util.navy[600],
  paddingTop: variant === 'select' ? 4 : 0,
  paddingBottom: variant === 'select' ? 4 : 0,
  overflow: 'hidden',
  ...(variant === 'popover' && {
    position: 'absolute',
    top: 40,
    left: 0,
    zIndex: 10000,
  }),
  ...(variant === 'multiSelect' && {
    position: 'absolute',
    top: wrapperHeight + 34,
    left: 0,
    right: 0,
  }),
  ...(styles && styles),
})

const getBackgroundColor = (isDark: boolean) => {
  let color
  if (isDark) {
    color = colors.util.one.main
  }
  return color
}

const itemContainer = (
  variant: ListItemVariant | undefined,
  isDark: boolean,
): CSSProperties => ({
  cursor: variant === 'standard' ? 'auto' : 'pointer',
  display: 'inline-flex',
  gap: variant === 'standard' || variant === 'link' ? 0 : 8,
  alignContent: 'center',
  justifyContent: variant === 'popover' ? 'flex-start' : 'space-between',
  overflow: 'hidden',
  height: 24,
  width: '100%',
  minWidth: variant === 'popover' ? 172 : 0,
  padding:
    variant === 'standard' || variant === 'link' ? '8px 0px 8px 8px' : '8px',
  backgroundColor: getBackgroundColor(isDark),
  alignItems: 'center',
  border:
    variant === 'standard' || variant === 'link' ? '1px solid #36474C' : 'none',
  borderRadius: variant === 'standard' || variant === 'link' ? 5 : '',
})

const textStyle = (variant: ListItemVariant | undefined): CSSProperties => ({
  fontSize: 14,
  fontWeight: variant === 'popover' ? 500 : 400,
  lineHeight: 1.4,
  textAlign: 'end',
  overflow: 'visible',
  whiteSpace: 'pre',
  textOverflow: 'ellipsis',
  minWidth: 0,
  marginBlock: 0,
  marginInline: 0,
  margin: variant === 'popover' ? '0' : 'auto auto auto 0',
  display: 'flex',
  alignItems: 'center',
})

const Menu = ({
  variant,
  options,
  selected,
  onSelect,
  handleOutsideClick,
  noMatchesFound,
  noMoreOptions,
  styles,
  searchInput,
  wrapperHeight = 0,
  id,
  zIndex = 1,
  toggleRef,
}: MenuProps): JSX.Element => {
  const open = true

  const isDarkTheme = useTheme('dark')

  const [menuOptions, setMenuOptions] = useState(options)
  useEffect(() => {
    setMenuOptions(options)
  }, [options])

  const [cursorIndex, setCursorIndex] = useState<number>(
    (selected && menuOptions.indexOf(selected as ListItemType)) ?? -1,
  )

  const handleHoveredOption = (option: ListItemType | undefined): void => {
    if (!noMatchesFound && !noMoreOptions) {
      setCursorIndex(option === undefined ? -1 : menuOptions.indexOf(option))
    }
  }

  const menuRef = createRef<HTMLUListElement>()

  useOutsideClick(handleOutsideClick, open, menuRef, true, toggleRef)

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      const selectedOption =
        cursorIndex !== -1 && menuOptions[Number(`${cursorIndex}`)]
          ? menuOptions[Number(`${cursorIndex}`)]
          : menuOptions && menuOptions[0]

      switch (event.key) {
        case 'Enter':
          onSelect ? onSelect(selectedOption as ListItemType) : noop
          break
        case 'ArrowDown':
          setCursorIndex((prevState) =>
            prevState < menuOptions.length - 1 ? prevState + 1 : prevState,
          )
          break
        case 'ArrowUp':
          setCursorIndex((prevState) =>
            prevState > 0 ? prevState - 1 : prevState,
          )
          break
      }
    }
    document.addEventListener('keydown', handleKeyPress)
    return () => document.removeEventListener('keydown', handleKeyPress)
  }, [cursorIndex, menuOptions, onSelect])

  const getMenuItems = (): JSX.Element[] => {
    switch (variant) {
      case 'select':
        return (menuOptions as ListItemSelectType[]).map((item, index) => (
          <ListItem
            key={index}
            active={index === cursorIndex}
            selected={item === selected}
            item={item}
            onSelect={onSelect as (option: ListItemType) => void}
            onHover={handleHoveredOption}
            variant="select"
          />
        ))
      case 'popover':
        return menuOptions.map((item, index) => (
          <ListItem
            key={index}
            active={index === cursorIndex}
            selected={item === selected}
            item={item}
            onSelect={onSelect}
            onHover={handleHoveredOption}
            variant={'popover'}
          />
        ))
      case 'multiSelect':
        return (menuOptions as ListItemSelectType[]).map((item, index) => (
          <MultiSelectListItem
            key={index}
            active={index === cursorIndex}
            selected={item === selected}
            item={item}
            onSelect={onSelect as (option: ListItemType) => void}
            onHover={handleHoveredOption}
            searchInput={searchInput}
          />
        ))
      default:
        return menuOptions.map((item, index) => (
          <ListItem
            key={index}
            active={index === cursorIndex}
            selected={item === selected}
            item={item}
            onSelect={onSelect}
            onHover={handleHoveredOption}
          />
        ))
    }
  }

  return (
    <ul
      tabIndex={0}
      data-testid="popover-menu-id"
      style={{
        ...menuContainer(
          open,
          isDarkTheme,
          wrapperHeight || 0,
          variant,
          styles,
        ),
        zIndex: zIndex ? zIndex : 1,
      }}
      role="listbox"
      className="menu-dropdown"
      ref={menuRef}
      id={id}
    >
      {(noMatchesFound === false || noMatchesFound === undefined) &&
        (noMoreOptions === false || noMoreOptions === undefined) &&
        getMenuItems()}

      {noMatchesFound === true && (
        <li style={itemContainer(undefined, isDarkTheme)}>
          <Typography styles={textStyle(undefined)}>
            {'No matches found.'}
          </Typography>
        </li>
      )}

      {noMoreOptions === true && (
        <li style={itemContainer(undefined, isDarkTheme)}>
          <Typography styles={textStyle(undefined)}>
            {'No more options.'}
          </Typography>
        </li>
      )}
    </ul>
  )
}

export default Menu
