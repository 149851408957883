import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import { Box, Button, Typography, useTheme } from '@mui/material'

import { CustomerHighlight } from '@models/index'
import { ADD_BOOKMARKED_CUSTOMER } from '@mutations/customer'
import { DW_EXPERT_CUSTOMER_FRAGMENT_WITH_MODULES } from '@fragments/dwExperts'
import { Dropdown } from '@common/Dropdown'
import { useToast } from '@hooks/index'
import Icon from '@common/Icon'
import { Loader } from '@common/Loader'
import { AlertSeverity } from '@hooks/useToast'

interface MCEmptyStateProps {
  customerHighlights: CustomerHighlight[]
  loading: boolean
}

interface CustomerDropdown extends CustomerHighlight {
  color: string
  label: string
  value: string
}

const MCEmptyState: React.FC<MCEmptyStateProps> = ({
  customerHighlights,
  loading,
}) => {
  const [selectedCustomers, setSelectedCustomers] = useState<
    Readonly<CustomerDropdown[]>
  >([])

  const { handleShowToast } = useToast()
  const theme = useTheme()

  const [addBookmarkedCustomer] = useMutation(ADD_BOOKMARKED_CUSTOMER, {
    update: (cache, { data: { addBookmarkedCustomer } }) => {
      addBookmarkedCustomer.forEach((cust) => {
        cache.writeFragment({
          id: cache.identify(cust),
          fragment: DW_EXPERT_CUSTOMER_FRAGMENT_WITH_MODULES,
          data: cust,
        })
      })
    },
  })

  const handleAddCustomerButtonClick = async () => {
    if (selectedCustomers.length === 0) {
      return
    }

    const customerShortNames = selectedCustomers.map((customer) => customer.id)
    handleShowToast(
      AlertSeverity.Info,
      `Making request to add selected customers to My Customers`,
    )

    await addBookmarkedCustomer({
      variables: {
        customers: customerShortNames,
      },
      optimisticResponse: {
        addBookmarkedCustomer: customerShortNames.map((cust) => {
          const matchingCust = customerHighlights.find(
            (highlight) => highlight.customerShortName === cust,
          )

          return {
            ...matchingCust,
            isBookmarkedCustomer: true,
          }
        }),
      },
      onCompleted: () => {
        handleShowToast(AlertSeverity.Success, 'Added to My Customers')
      },
      onError: () => {
        handleShowToast(
          AlertSeverity.Error,
          `Could not add selected customers to My Customers`,
        )
      },
    })
  }

  const mappedCustomers = (
    customers: CustomerHighlight[],
  ): CustomerDropdown[] => {
    return customers?.map((customer) => ({
      ...customer,
      id: customer.customerShortName,
      color: theme.vars.palette.text.primary,
      label: String(customer.name),
      value: customer.customerShortName,
    }))
  }

  return (
    <Box
      data-testid="my-customers-empty-state"
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        paddingTop: '4rem',
      }}
    >
      {loading ? (
        <Loader size={80} strokeWidth={2} />
      ) : (
        <>
          <Icon
            size={250}
            sx={(theme) => ({
              color: theme.vars.palette.text.primary,
              fill: theme.vars.palette.secondary.light,
            })}
            variant="myCustomersEmptyState"
          />

          <Typography
            variant="h5"
            sx={(theme) => ({
              color: theme.vars.palette.text.primary,
            })}
          >
            No added customers yet
          </Typography>
          <Typography
            variant="h6"
            fontWeight={400}
            sx={(theme) => ({
              color: theme.vars.palette.text.primary,
            })}
          >
            Customers you have added will appear here.
          </Typography>

          <Box sx={{ textAlign: 'left', maxWidth: '400px', width: '100%' }}>
            <Typography variant="body1" sx={{ paddingBottom: '0.5rem' }}>
              All customers
            </Typography>
            <Dropdown
              placeholder="Pick your customers here"
              options={mappedCustomers(customerHighlights).sort((a, b) =>
                a.name.localeCompare(b.name),
              )}
              isMulti
              isDisabled={loading || !customerHighlights}
              onChange={(customer) => setSelectedCustomers(customer)}
              name="my-customers-dropdown"
              id="my-customers-dropdown"
              isClearable={false}
            />
          </Box>

          <Box data-testid="addCustomersButton">
            <Button
              disabled={selectedCustomers.length === 0}
              variant="contained"
              onClick={handleAddCustomerButtonClick}
            >
              Add to my customers
            </Button>
          </Box>
        </>
      )}
    </Box>
  )
}
export default MCEmptyState
