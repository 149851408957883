import { format } from 'date-fns'
import { useSearchParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import { Icon, Tag, colors } from '../../../../../../design-system'
import { ColumnHeader } from '../../../../../../design-system/components/DesktopTable'
import { getIconVariant, getTagColors } from '../../../SecurityIndex.utils'
import { eventMockData2 } from '../../../../../../../mockData/SecurityIndexFactory'
import { Table, Typography } from '../../../../../../design-system/components'
import ComponentError from '../../../../../common/ComponentError/ComponentError'
import {
  GET_SECURITY_INDEX_CHANGE_HISTORY,
  GetSecurityIndexChangeHistory,
} from '../../../../../../graphql/queries/securityIndex'
import { GetCustomerInfoVariables } from '../../../../../../graphql/queries/customer'

const ChangeHistory: React.FC = (): JSX.Element => {
  const [searchParams] = useSearchParams()

  const selectedCustomer = searchParams.get('customer') || ''

  const {
    data: { getSecurityIndexChangeHistory: events } = {
      getSecurityIndexChangeHistory: eventMockData2,
    },
    loading: eventLoading,
    error: eventError,
  } = useQuery<GetSecurityIndexChangeHistory, GetCustomerInfoVariables>(
    GET_SECURITY_INDEX_CHANGE_HISTORY,
    { variables: { selectedCustomer } },
  )

  const generateTableHeaders = (
    name: string,
    sortable: boolean,
    visible: boolean,
    columnWidth = '35%',
    secondarySortDirection: 'DESC' | 'ASC' | '',
    secondarySortId: string,
  ): ColumnHeader => {
    return {
      name,
      sortable,
      visible,
      columnWidth,
      secondarySortDirection,
      secondarySortId,
    }
  }

  const tableHeaders: ColumnHeader[] = [
    generateTableHeaders('id', false, false, '', '', ''),
    generateTableHeaders('ACTION', true, true, '', '', ''),
    generateTableHeaders('NAME', true, true, '', '', ''),
    generateTableHeaders('DATA SOURCE (DW VALUE)', true, true, '', '', ''),
    generateTableHeaders('DATE', true, true, '', 'ASC', ''),
  ]

  const formatTableData = events.map((event) => {
    const eventAction = event.action
      ? `${event.type} added`
      : `${event.type} removed`

    const action = {
      sortValue: eventAction,
      displayValue: (
        <>
          <Icon
            variant={getIconVariant(event.type)}
            size={18}
            color={
              event.action ? colors.util.one.lighter : colors.util.two.light
            }
            style={{ marginRight: '.5rem', marginBottom: '-3px' }}
          />

          <span>{eventAction}</span>
        </>
      ),
    }

    const type = {
      sortValue: event.type,
      displayValue: (
        <>
          <span style={{ marginRight: '.5rem' }}>{event.type}</span>
          <Tag
            text={event.weight}
            size={12}
            styles={{
              color: getTagColors(event.weight),
              backgroundColor: 'transparent',
              border: `1px solid`,
              textTransform: 'uppercase',
            }}
          />
        </>
      ),
    }

    return {
      id: event.id,
      action,
      sourceName: {
        sortValue: event.sourceName,
        displayValue: event.sourceName,
      },
      type,
      createdAt: {
        sortValue: event.createdAt,
        displayValue: format(new Date(event.createdAt), 'MMM, dd yyyy'),
      },
    }
  })

  const NoChangesMade = (): JSX.Element => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center',
          marginTop: '4em',
        }}
      >
        <Typography
          size={22}
          component="p"
          styles={{ width: '100%', marginBottom: '1em' }}
        >
          No changes have been made
        </Typography>
        <Typography variant="text10" component="p" styles={{ width: '100%' }}>
          There have been no enabled / disabled log sources or detections during
          the selected time period.
        </Typography>
      </div>
    )
  }

  if (eventError) {
    return (
      <div style={{ textAlign: 'center', flex: 1, marginTop: '3em' }}>
        <ComponentError />
      </div>
    )
  }

  return (
    <div style={{ marginTop: '2em' }}>
      <Table
        loading={eventLoading}
        loadingRows={20}
        data={formatTableData}
        sortOptions={{
          defaultSortColumn: 4,
          sortDirection: 'ASC',
          secondaryDefaultSortColumn: 1,
          secondarySortDirection: 'ASC',
        }}
        customHeaders={tableHeaders}
        captionProps={{
          caption: `${events ? events.length : 0} changes`,
        }}
        customErrorComponent={<NoChangesMade />}
      />
    </div>
  )
}

export default ChangeHistory
