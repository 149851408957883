import { startTransition, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { debounce } from 'throttle-debounce'

import {
  DesktopTable,
  Input,
  MobileTable,
  Typography,
} from '../../../design-system'
import NoResults from '../../common/NoResults/NoResults'
import { useWindowDimensions } from '../../../hooks'
import { mobileWindowWidth } from '../../../constants/constants'
import { CustomerHighlight } from '../../../models'
import { boldSearchText } from '../dwExperts.utils'
import {
  Context,
  setCustomerLoading,
  setCustomerNames,
  setDwExpertsActiveTab,
} from '../../App'
import { Paths } from '../../App/Types'
import { renderErrorToast } from '../../../utils'

import './DeepwatchExpertCustomerTable.scss'

interface DeepwatchExpertCustomerTableProps {
  customerHighlights: CustomerHighlight[]
  loading: boolean
  disableRowClick?: boolean
  disableAddedTags?: boolean
}

const DeepwatchExpertCustomerTable: React.FC<
  DeepwatchExpertCustomerTableProps
> = ({
  customerHighlights,
  loading = true,
  disableRowClick = false,
  disableAddedTags = true,
}) => {
  const { dispatch } = useContext(Context)
  const { width } = useWindowDimensions()
  const navigate = useNavigate()

  const [inputValue, setInputValue] = useState('')
  const [searchResults, setSearchResults] =
    useState<CustomerHighlight[]>(customerHighlights)

  useEffect(() => {
    setSearchResults(customerHighlights)
  }, [customerHighlights])

  const handleRightIconClick = () => {
    setInputValue('')
    startTransition(() => {
      setSearchResults(customerHighlights)
    })
  }

  const handleRowClick = (id: number | string) => {
    const customer = customerHighlights.find((val) => val.id === id)

    if (customer === undefined) {
      renderErrorToast({ message: 'Customer not found!' }, dispatch)
      return
    }

    const customerName =
      typeof customer.name === 'string'
        ? customer.name
        : (customer.name.originalValue as string) // Cast to string since we know original value has been set via search input

    dispatch(setDwExpertsActiveTab(1))
    dispatch(
      setCustomerNames({
        customerName,
        customerShortName: customer.customerShortName,
      }),
    )
    dispatch(setCustomerLoading(false))

    navigate(`${Paths.DASHBOARD}?customer=${customer.customerShortName}`)
  }

  const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
    setSearchResults(
      customerHighlights
        .filter((data) => {
          if (typeof data.name === 'string') {
            return (
              (data.name as string)
                .toLowerCase()
                .includes(e.target.value.toLowerCase()) ||
              (data.customerShortName as string)
                .toLowerCase()
                .includes(e.target.value.toLowerCase())
            )
          } else {
            return (
              (data.name.sortValue as string)
                .toLowerCase()
                .includes(e.target.value.toLowerCase()) ||
              (data.customerShortName as string)
                .toLowerCase()
                .includes(e.target.value.toLowerCase())
            )
          }
        })
        .map((data) => {
          let tempName = ''

          if (typeof data.name === 'string') {
            tempName = data.name
          } else {
            tempName = data.name.sortValue as string
          }

          if (tempName.toLowerCase().includes(e.target.value)) {
            const highlight = boldSearchText(
              e.target.value,
              tempName,
              disableAddedTags === false,
              data.isBookmarkedCustomer ?? false,
            )

            return {
              ...data,
              name: {
                sortValue: tempName.toLowerCase(),
                displayValue: highlight,
                originalValue: tempName,
              },
            }
          } else {
            return data
          }
        }),
    )
  }

  const debouncedCallback = debounce(500, (e) => {
    if (e.target.value.length === 0) {
      handleRightIconClick()
    } else {
      handleSearchInput(e)
    }
  })

  const TableComponent = () => {
    const dwExpertCustomerHeaders = [
      { name: 'id', sortable: false, visible: false },
      { name: 'CUSTOMER NAME', sortable: true, visible: true },
      {
        name: 'LICENSE UTIL (GB/day)',
        sortable: true,
        visible: true,
      },
      {
        name: 'LICENSE LIMIT (GB/day)',
        sortable: true,
        visible: true,
      },
      {
        name: 'OPEN CRITICAL TICKETS',
        sortable: true,
        visible: true,
      },
      {
        name: 'TICKETS IN PROGRESS WITH DW',
        sortable: true,
        visible: true,
      },
    ]

    if (width <= mobileWindowWidth) {
      return (
        <MobileTable
          styles={{ paddingBottom: '3em' }}
          sortOptions={{ defaultSortColumn: 1, sortDirection: 'ASC' }}
          loadingRows={15}
          onClick={handleRowClick}
          data={searchResults}
          loading={loading}
          customHeaders={dwExpertCustomerHeaders}
        />
      )
    } else {
      return (
        <DesktopTable
          styles={{ paddingBottom: '3em' }}
          stickyHeaderOffset={284}
          clickableTableRow={true}
          sortOptions={{ defaultSortColumn: 1, sortDirection: 'ASC' }}
          loadingRows={15}
          onClick={disableRowClick ? undefined : handleRowClick}
          data={searchResults}
          loading={loading}
          customHeaders={dwExpertCustomerHeaders}
        />
      )
    }
  }

  return (
    <div className="dw-expert-customer-table">
      <div className="dw-expert-filter-container">
        <Typography variant="text10">
          {searchResults && searchResults.length} Customers
        </Typography>
        <div className="dw-expert-filter" data-testid={'squad-filter'}>
          <Input
            name={'customerName'}
            type={'text'}
            labelId={'customerName'}
            onChange={(e) => debouncedCallback(e)}
            placeholder={'Find customer by name'}
            leftIcon={'searchOutline'}
            value={inputValue}
            rightIcon={'close'}
            onRightIconClick={() => handleRightIconClick()}
            disabled={loading}
          />
        </div>
      </div>
      {!loading && searchResults.length < 1 ? (
        <div className="dw-expert-home-no-results">
          <NoResults />
        </div>
      ) : (
        TableComponent()
      )}
    </div>
  )
}

export default DeepwatchExpertCustomerTable
