import { gql } from '@apollo/client'

import {
  SecurityIndexEvent,
  UnclassifiedLogSource,
  YourIndex,
} from '../../models'
import { SecurityIndexBansMetrics } from '../../models/SecurityIndex'
import { BAN_METRIC_FRAGMENT, TREND_FRAGMENT } from '../fragments/securityIndex'

export interface GetSecurityIndex {
  getSecurityIndex: SecurityIndexEvent[]
}

export interface GetSecurityIndexChangeHistory {
  getSecurityIndexChangeHistory: SecurityIndexEvent[]
}

export interface GetSecurityIndexBanMetrics {
  getSecurityIndexBanMetrics: SecurityIndexBansMetrics
}

export interface GetYourSecurityIndex {
  getYourSecurityIndex: YourIndex
}

export interface GetUnclassifiedLogSources {
  getUnclassifiedLogSources: UnclassifiedLogSource[]
}

export const GET_YOUR_SECURITY_INDEX = gql`
  ${TREND_FRAGMENT}
  query GetYourSecurityIndex {
    getYourSecurityIndex {
      trends {
        yourTrend {
          ...TrendFields
        }
        avgCustomerTrend {
          ...TrendFields
        }
        avgIndustryTrend {
          ...TrendFields
        }
        pastPeriodTrend {
          ...TrendFields
        }
      }
      details {
        lifetimeIncrease
        pastPeriod
        enabledLogSourcesCount
        enabledDetectionsCount
        missingDataSourcesCount
      }
      preTransformedIndexValues {
        logSources
        detections
        enterpriseCoverage
      }
      unclassifiedLogSourceCount
    }
  }
`

export const GET_SECURITY_INDEX_CHANGE_HISTORY = gql`
  query GetSecurityIndexChangeHistory($selectedCustomer: String) {
    getSecurityIndexChangeHistory(selectedCustomer: $selectedCustomer) {
      events {
        id
        createdAt
        sourceName
        dataSource
        type
        action
        weight
      }
    }
  }
`

export const GET_SECURITY_INDEX_BAN_METRICS = gql`
  ${BAN_METRIC_FRAGMENT}
  query GetSecurityIndexBanMetrics($selectedCustomer: String) {
    getSecurityIndexBanMetrics(selectedCustomer: $selectedCustomer) {
      yourIndex {
        ...BanIndexDeltaFields
      }
      avgCustomerIndex {
        ...BanIndexDeltaFields
      }
      avgIndustryIndex {
        ...BanIndexDeltaFields
      }
    }
  }
`

export const GET_UNCLASSIFIED_LOG_SOURCES = gql`
  query GetUnclassifiedLogSources {
    getUnclassifiedLogSources {
      index
      logSource
      dataModel
      dataType
    }
  }
`
