import { Formik } from 'formik'
import * as Yup from 'yup'

import {
  AdditionalDetailsColumn,
  AdditionalDetailsTopRow,
} from './AdditionalDetailsColumns'
import { useTicketEditContext } from '../../../../../hooks'
import { AdditionalDetailsRow } from '../../Types'
import { noop } from '../../../../../design-system/utils'

import './AdditionalDetailsForm.scss'

export const AdditionalDetailsForm = (
  data: AdditionalDetailsRow,
): JSX.Element => {
  const { isEditable } = useTicketEditContext()

  const initialValues = { ...data }
  const schemaValueObject = { ...data }
  const schemaValidationObject = Object.fromEntries(
    Object.entries(schemaValueObject).map(([key]) => [
      `${key}`,
      Yup.string().required(`${key}) is required`),
    ]),
  )

  const AditionalDetailsFormSchema = Yup.object().shape(schemaValidationObject)

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={AditionalDetailsFormSchema}
      //NOTE:Additional Details Fields not in scope for v1 of ticket edit
      onSubmit={noop}
    >
      <div className="additional-details-wrapper">
        <div className="flex column gap-16 additional-details-column top-column additional-details-wrapper">
          {AdditionalDetailsTopRow(data, isEditable)}
        </div>
        <div className="flex gap-24 additional-details-wrapper">
          <div className="flex column gap-24 additional-details-column">
            {AdditionalDetailsColumn(data, 0, isEditable)}
          </div>
          <div className="flex column gap-24 additional-details-column">
            {AdditionalDetailsColumn(data, 1, isEditable)}
          </div>
        </div>
      </div>
    </Formik>
  )
}
