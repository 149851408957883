import { ReactElement, useContext, useState } from 'react'
import { useQuery } from '@apollo/client'
import { useSearchParams } from 'react-router-dom'
import { debounce } from 'throttle-debounce'

import { Context as AppContext } from '../../../../App'
import ThreatIntelReportsTable from '../ThreatIntelReportsTable/ThreatIntelReportsTable'
import { Input, Typography } from '../../../../../design-system'
import { makePluralLabel } from '../../../../../utils'
import { ErrorFallback } from '../../../../App/Errors'
import { useThreatIntelReportsInput } from '../../hooks'
import {
  GET_THREAT_INTEL_REPORTS_LIST_QUERY,
  GetThreatIntelReportsData,
  GetThreatIntelReportsVariables,
} from '../../../../../graphql/queries/threatIntel'
import { combineThreatIntelReports } from './ThreatIntelReportsList.utils'

import './ThreatIntelReportList.scss'

const PAGE_SIZE = 20

const ThreatIntelReportsList = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const { filters, keywordSearch, sortBy, sortDirection } =
    useThreatIntelReportsInput()

  const {
    state: {
      dwExpertsCustomer: { customerShortName },
    },
  } = useContext(AppContext)

  const [searchInput, setSearchInput] = useState(keywordSearch || '')

  const selectedCustomer = searchParams.get('customer')

  const { data, loading, error, fetchMore } = useQuery<
    GetThreatIntelReportsData,
    GetThreatIntelReportsVariables
  >(GET_THREAT_INTEL_REPORTS_LIST_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      selectedCustomer: customerShortName || selectedCustomer,
      input: {
        filters,
        keywordSearch,
        sortBy,
        sortDirection,
        pagination: {
          limit: PAGE_SIZE,
          offset: 0,
        },
      },
    },
    notifyOnNetworkStatusChange: true,
  })

  const fetchNextPage = () => {
    if (
      loading ||
      error ||
      !data?.getThreatIntelReports.threatIntelReports.length ||
      data.getThreatIntelReports.threatIntelReports.length ===
        data.getThreatIntelReports.pagination.total
    ) {
      return
    }

    fetchMore({
      variables: {
        selectedCustomer: customerShortName || selectedCustomer,
        input: {
          filters,
          keywordSearch,
          sortBy,
          sortDirection,
          pagination: {
            limit: PAGE_SIZE,
            offset: data.getThreatIntelReports.threatIntelReports.length,
          },
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => ({
        getThreatIntelReports: {
          pagination: fetchMoreResult.getThreatIntelReports.pagination,
          threatIntelReports: combineThreatIntelReports(
            prev.getThreatIntelReports.threatIntelReports,
            fetchMoreResult.getThreatIntelReports.threatIntelReports,
          ),
        },
      }),
    })
  }

  let component: ReactElement | null = (
    <ThreatIntelReportsTable
      data={data?.getThreatIntelReports.threatIntelReports || []}
      isLoading={loading}
      onBottomReached={fetchNextPage}
    />
  )

  if (error || (!loading && !data)) {
    component = (
      <ErrorFallback style={{ position: 'static', transform: 'unset' }} />
    )
  }

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target

    setSearchInput(value)

    setSearchParams((prevParams) => {
      if (value.trim()) {
        prevParams.set('keywordSearch', value.trim())
      } else {
        prevParams.delete('keywordSearch')
      }

      return prevParams
    })
  }

  const handleSearchInputDelete = () => {
    setSearchInput('')

    setSearchParams((prevParams) => {
      prevParams.delete('keywordSearch')

      return prevParams
    })
  }

  const debounceSearchInputChange = debounce(
    300,
    (e: React.ChangeEvent<HTMLInputElement>) => handleSearchInputChange(e),
  )

  return (
    <div
      className="threat-intel-report-table"
      data-testid="threat-intel-reports-list"
    >
      <div className="threat-intel-report-filter-container">
        <div className="threat-intel-report-table-header">
          <Typography variant="text10">
            {makePluralLabel(
              'Report',
              data?.getThreatIntelReports.pagination.total ?? 0,
            )}
          </Typography>
          <div className="threat-intel-report-filter">
            <Input
              name={'threatIntelReportFilter'}
              type={'text'}
              labelId={'threatIntelReportFilter'}
              onChange={(e) => debounceSearchInputChange(e)}
              placeholder={'Search reports'}
              leftIcon={'searchOutline'}
              value={searchInput}
              rightIcon={'close'}
              onRightIconClick={handleSearchInputDelete}
              disabled={false}
            />
          </div>
        </div>
        {component}
      </div>
    </div>
  )
}

export default ThreatIntelReportsList
