import { CSSProperties, useContext, useState } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useLazyQuery, useQuery } from '@apollo/client'
import { Navigate } from 'react-router'

import {
  Icon,
  MobileTable,
  DesktopTable,
  Typography,
  colors,
  Button,
  ButtonProps,
} from '../../../design-system'
import { Context } from '../../App'
import { OktaGroup, User } from '../../../models'
import { useModalContext, useWindowDimensions } from '../../../hooks'
import { mobileWindowWidth } from '../../../constants/constants'
import { IconButtonProps } from '../../../design-system/interfaces'
import InlineMessage from '../../common/InlineMessage/InlineMessage'
import ResendEmailUserModal from './ResendEmailUserModal/ResendEmailUserModal'
import { DeleteUserModal } from './DeleteUserModal'
import { DeactivateUserModal } from './DeactivateUserModal'
import { ActivateUserModal } from './ActivateUserModal'
import { AddUserModal } from './AddUserModal'
import { EditUserModal } from './EditUserModal'
import {
  GET_USER_GROUPS,
  GET_USER_MANAGEMENT_SETTINGS,
  GetUserGroupsData,
  GetUserGroupsVariables,
} from '../../../graphql/queries/user'

import './UserManagementSettings.scss'

export enum ModalTitles {
  ADD = 'Add User',
  EDIT = 'Edit User',
  ACTIVATE = 'Activate User',
  DEACTIVATE = 'Deactivate User',
  RESEND_EMAIL = 'Resend Email',
  DELETE = 'Delete User',
}

const sharedModalContentStyles: CSSProperties = {
  maxHeight: '90%',
  overflowY: 'auto',
}

const UserManagementSettings = (): React.ReactElement => {
  const {
    state: { customer, homePath, user },
  } = useContext(Context)

  const [selectedUser, setSelectedUser] = useState<User>()
  const [userGroups, setUserGroups] = useState<Array<OktaGroup>>()
  const { width } = useWindowDimensions()
  const { closeModal, openModal: openModalContext } = useModalContext()

  const { addUser, userReactivateDeactivateDelete, featureSettings } =
    useFlags()

  const {
    data: { getUsers: users, getCustomerGroups: customerGroups } = {
      getUsers: [],
      getCustomerGroups: [],
    },
    loading,
  } = useQuery(GET_USER_MANAGEMENT_SETTINGS)

  const [getUserGroups] = useLazyQuery<
    GetUserGroupsData,
    GetUserGroupsVariables
  >(GET_USER_GROUPS)

  if (user.isDWEmployee || !user.isAdmin) {
    return <Navigate to={homePath} />
  }

  const clearUser = () => {
    setSelectedUser(undefined)
    setUserGroups(undefined)
  }

  const onAddUserClick = () => {
    openModalContext({
      component: (
        <AddUserModal
          closeModal={closeModal}
          user={selectedUser as User}
          isLastAdmin={false}
          customerGroups={customerGroups}
          title={ModalTitles.ADD}
          clearUser={clearUser} //? is this needed?
          userGroups={userGroups}
        />
      ),
      title: ModalTitles.ADD,
      modalContentStyles: sharedModalContentStyles,
    })
  }

  // TODO: create type that reflects the user after data table mapping
  const onEditUserClick = async (user: User): Promise<void> => {
    const { data } = await getUserGroups({
      variables: {
        input: {
          userId: user?.id as string,
        },
      },
    })

    openModalContext({
      component: (
        <EditUserModal
          closeModal={closeModal}
          user={users.find((val) => val.id === user.id) as User}
          isLastAdmin={checkForLastAdmin(users)}
          customerGroups={customerGroups}
          title={ModalTitles.EDIT}
          userGroups={data?.getUsersGroupMembership}
        />
      ),
      title: ModalTitles.EDIT,
      modalContentStyles: sharedModalContentStyles,
    })
  }

  const onDeactivateUserClick = async (user: User): Promise<void> => {
    openModalContext({
      component: (
        <DeactivateUserModal
          closeModal={closeModal}
          userId={user.id ?? ''}
          userName={user[`name`][`sortValue`]}
        />
      ),
      title: ModalTitles.DEACTIVATE,
    })
  }

  const onActivateUserClick = async (user: User): Promise<void> => {
    openModalContext({
      component: (
        <ActivateUserModal
          closeModal={closeModal}
          userId={user.id ?? ''}
          userName={user[`name`][`sortValue`]}
        />
      ),
      title: ModalTitles.ACTIVATE,
    })
  }

  const onReactivateUserClick = async (user: User): Promise<void> => {
    openModalContext({
      component: (
        <ResendEmailUserModal closeModal={closeModal} userId={user.id ?? ''} />
      ),
      title: ModalTitles.RESEND_EMAIL,
    })
  }

  const onDeleteUserClick = async (user: User): Promise<void> => {
    openModalContext({
      component: (
        <DeleteUserModal
          closeModal={closeModal}
          userId={user.id ?? ''}
          userName={user[`name`][`sortValue`]}
        />
      ),
      title: ModalTitles.DELETE,
    })
  }

  const usersTableHoverButtons: IconButtonProps[] = []

  usersTableHoverButtons.push({
    variant: 'pencilOutline',
    label: ModalTitles.EDIT,
    customOnClick: (user) => {
      onEditUserClick(user)
    },
  })

  const userMobileTableHoverButtons: ButtonProps[] = [
    {
      variant: 'icon',
      iconProps: {
        variant: 'pencilOutline',
      },
      label: ModalTitles.EDIT,
      customOnClick: (user) => {
        onEditUserClick(user)
      },
    },
  ]

  if (userReactivateDeactivateDelete) {
    userMobileTableHoverButtons.push(
      {
        variant: 'icon',
        iconProps: {
          variant: 'checkmarkCircle',
        },
        label: ModalTitles.RESEND_EMAIL,
        customOnClick: (user) => {
          onReactivateUserClick(user)
        },
      },
      {
        variant: 'icon',
        iconProps: {
          variant: 'banOutline',
        },
        label: ModalTitles.DEACTIVATE,
        customOnClick: (user) => {
          onDeactivateUserClick(user)
        },
      },
    )
  }

  // TODO: This function smells and ought to be refactored. DO NOT reuse this pattern.
  const formatTableData = (users: User[]): Record<string, unknown>[] => {
    return users.map((user) => {
      const lastLogin = new Date(user.lastLogin ? user.lastLogin : '')
        .toLocaleString(undefined, {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: 'numeric',
          minute: '2-digit',
          timeZoneName: 'short',
        })
        .replace(',', '')
      const fullName = user.firstName + ' ' + user.lastName
      const hoverButtons: IconButtonProps[] = []

      if (userReactivateDeactivateDelete) {
        hoverButtons.push({
          variant: 'pencilOutline',
          label: ModalTitles.EDIT,
          customOnClick: (user) => {
            onEditUserClick(user)
          },
        })
        if (user.oktaStatus === 'PROVISIONED') {
          hoverButtons.push({
            customVariant: 'resendEmail',
            label: ModalTitles.RESEND_EMAIL,
            customOnClick: (user) => {
              onReactivateUserClick(user)
            },
          })
        }
        if (
          user.oktaStatus === 'STAGED' ||
          user.oktaStatus === 'DEPROVISIONED'
        ) {
          hoverButtons.push({
            variant: 'checkmarkCircle',
            label: ModalTitles.ACTIVATE,
            customOnClick: (user) => {
              onActivateUserClick(user)
            },
          })
        }
        if (user.oktaStatus === 'PROVISIONED' || user.oktaStatus === 'ACTIVE') {
          hoverButtons.push({
            variant: 'banOutline',
            label: ModalTitles.DEACTIVATE,
            customOnClick: (user) => {
              onDeactivateUserClick(user)
            },
          })
        }
        if (user.oktaStatus === 'DEPROVISIONED') {
          hoverButtons.push({
            variant: 'trashOutline',
            label: ModalTitles.DELETE,
            customOnClick: (user) => {
              onDeleteUserClick(user)
            },
          })
        }
      }

      return {
        id: user.id,
        name: {
          sortValue: fullName.toLowerCase(),
          displayValue: (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'end',
              }}
            >
              <Typography
                variant="text11semibold"
                size={14}
                color={colors.util.navy[50]}
              >
                {fullName}
              </Typography>
              {user && user.isAdmin && (
                <Icon variant="key" size={18} style={{ paddingLeft: 5 }} />
              )}
            </div>
          ),
        },
        email: {
          sortValue: user.email.toLowerCase(),
          displayValue: (
            <Typography variant="text11" size={14} color={colors.util.navy[50]}>
              {user.email}
            </Typography>
          ),
        },
        status: {
          sortValue: user.oktaStatus ? user.oktaStatus.toLowerCase() : '',
          displayValue: getStatus(
            user.oktaStatus ? user.oktaStatus.toLowerCase() : '',
          ),
        },
        lastLoggedIn: {
          sortValue: lastLogin.toLowerCase(),
          displayValue: (
            <Typography variant="text11" size={14} color={colors.util.navy[50]}>
              {lastLogin === 'Invalid Date' ? '--' : lastLogin}
            </Typography>
          ),
        },
        rowHoverElements: hoverButtons,
      }
    })
  }

  const TableComponent = () => {
    const getCaption = () => {
      if (loading) {
        return '--'
      }

      if (users.length > 1) {
        return `${users.length} Users`
      } else {
        return `${users.length} User`
      }
    }

    if (width <= mobileWindowWidth) {
      return (
        <MobileTable
          captionProps={{
            caption: getCaption(),
            ...(addUser &&
              customer.settings !== undefined &&
              !customer.settings?.isOktaFederated && {
                captionButton: (
                  <Button
                    onClick={() => onAddUserClick()}
                    label={ModalTitles.ADD}
                  />
                ),
              }),
          }}
          customHeaders={[
            { name: 'id', visible: false },
            { name: 'Name', sortable: true, visible: true },
            { name: 'Email', sortable: true, visible: true },
            { name: 'Status', sortable: true, visible: true },
            { name: 'Last Logged In', sortable: true, visible: true },
          ]}
          data={formatTableData(users)}
          loading={loading}
          buttonElements={userMobileTableHoverButtons}
          sortOptions={{ defaultSortColumn: 1, sortDirection: 'ASC' }}
        />
      )
    } else {
      return (
        <DesktopTable
          captionProps={{
            caption: getCaption(),
            ...(addUser &&
              customer.settings !== undefined &&
              !customer.settings?.isOktaFederated && {
                captionButton: (
                  <Button
                    onClick={() => onAddUserClick()}
                    label={ModalTitles.ADD}
                  />
                ),
              }),
          }}
          customHeaders={[
            { name: 'id', visible: false },
            { name: 'Name', sortable: true, visible: true },
            { name: 'Email', sortable: true, visible: true },
            { name: 'Status', sortable: true, visible: true },
            { name: 'Last Logged In', sortable: true, visible: true },
          ]}
          data={formatTableData(users)}
          sortOptions={{ defaultSortColumn: 1, sortDirection: 'ASC' }}
          clickableTableRow={true}
          loadingRows={8}
          loading={loading}
          rowHoverElements={usersTableHoverButtons}
        />
      )
    }
  }

  return (
    <>
      <ConditionalArticleWrapper
        condition={featureSettings}
        wrapper={(children) => (
          <article className="content">{children}</article>
        )}
      >
        <div id="user-management" data-testid="usermanagementsettings">
          <div className="federated-cust-message">
            {customer.settings?.isOktaFederated && (
              <InlineMessage>
                <div className="message-icon">
                  <Icon variant="informationCircle" size={18} />
                  <Typography variant="text12medium">
                    Federation message
                  </Typography>
                </div>

                <div className="message-content">
                  <Typography variant="text12">
                    We have detected your organization has federated users from
                    an external identity provider. You may edit and delete the
                    users below, however these changes will only apply to
                    Deepwatch&apos;s Okta organization and any Deepwatch
                    provisioned app (i.e. the IdP the users were federated to).
                  </Typography>
                  <Typography variant="text12">
                    Consider updating the users in your organization&apos;s
                    identity provider to ensure the changes persist. If you have
                    any questions, please reach out to your Customer Success
                    Manager.
                  </Typography>
                </div>
              </InlineMessage>
            )}
          </div>
          {TableComponent()}
        </div>
      </ConditionalArticleWrapper>
    </>
  )
}

export default UserManagementSettings

export const checkForLastAdmin = (users: User[]): boolean => {
  return (
    1 ===
    users.reduce((count: number, user) => (user.isAdmin ? ++count : count), 0)
  )
}

// TODO: This function smells and ought to be refactored. DO NOT reuse this pattern.
const getStatus = (status: string): JSX.Element => {
  // TODO: set flag depending on timestamp comparison from when the invite was
  // TODO: sent and date.now()
  const expiredInvite = false
  switch (status) {
    case 'staged':
    case 'provisioned':
      return (
        <>
          <Icon
            variant={expiredInvite ? 'warning' : 'checkmarkCircle'}
            color={
              expiredInvite ? colors.util.two.light : colors.util.four.main
            }
            size={14}
            style={{ marginRight: 4 }}
          ></Icon>
          <Typography
            variant="text11"
            size={14}
            color={colors.util.navy[50]}
            component="span"
          >
            {expiredInvite ? 'Invite Expired' : 'Invite Pending'}
          </Typography>
        </>
      )
    case 'active':
      return (
        <>
          <Icon
            variant="checkmarkCircle"
            color={colors.brand.primary.light}
            size={14}
            style={{ marginRight: 4 }}
          ></Icon>
          <Typography
            variant="text11"
            size={14}
            color={colors.util.navy[50]}
            component="span"
          >
            Active
          </Typography>
        </>
      )
    case 'deprovisioned':
      return (
        <>
          <Icon
            variant="banOutline"
            color={colors.util.two.main}
            size={14}
            style={{ marginRight: 4 }}
          ></Icon>
          <Typography
            variant="text11"
            size={14}
            color={colors.util.navy[50]}
            component="span"
          >
            Deactivated
          </Typography>
        </>
      )
    // TODO: Why is there no logic in these blocks?
    case 'recovery':
    case 'password expired':
    case 'locked out':
    case 'suspended':
    default:
      return <></>
  }
}

const ConditionalArticleWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children
