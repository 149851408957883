import { Fragment } from 'react'

import { TagNew } from '../../design-system/components/TagNew'
import { CustomerHighlight } from '../../models'

export const boldSearchText = (
  searchInput: string,
  groupName: string,
  includeAddedTag: boolean,
  addedCustomer: boolean,
) => {
  if (searchInput.length > 0) {
    const boldTextArray = groupName.match(RegExp('(' + searchInput + ')', 'ig'))
    const splitBy = boldTextArray?.find(Boolean)

    const groupPartNames = splitBy ? groupName.split(splitBy) : []

    return (
      <div className="customer-name-cell">
        {groupPartNames.map((part, index) => {
          if (index === groupPartNames.length - 1) {
            return <Fragment key={index}>{part}</Fragment>
          } else {
            return (
              <Fragment key={index}>
                {part}
                <b>{splitBy}</b>
              </Fragment>
            )
          }
        })}
        {includeAddedTag && addedCustomer && (
          <TagNew text="ADDED" interactive={false} severity="informational" />
        )}
      </div>
    )
  }

  return groupName
}

export const getCustomerString = (customer: CustomerHighlight) => {
  let customerName = customer.name
  if (typeof customer.name === 'object') {
    if (customer.name.originalValue) {
      customerName = String(customer.name.originalValue)
    } else if (customer.name.sortValue) {
      customerName = String(customer.name.sortValue)
    }
  }
  return customerName
}
