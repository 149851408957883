import { Factory } from 'fishery'
import { faker } from '@faker-js/faker'

import { TicketAttachment } from '../../src/components/Tickets/TicketEdit/Types'

export const TicketAttachmentFactory = Factory.define<TicketAttachment>(() => ({
  attachmentSysId: faker.number.bigInt().toString(),
  name: faker.lorem.word(),
  type: 'img/png',
}))
