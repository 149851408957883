import { useState } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { Tabs } from '../../design-system'
import { ThreatIntelReports } from './ThreatIntelReports'
import ThreatIntelFullReport from './ThreatIntelReports/components/ThreatIntelFullReport/ThreatIntelFullReport'
import { ThreatIntelReportsContextProvider } from './ThreatIntelReports/context'

import './ThreatIntel.scss'

interface ThreatIntelProps {
  component?: 'reports' | 'fullReport'
}

const ThreatIntelTabs = [
  { active: true, id: 0, text: 'Reports', notification: false },
]

const ReportsComponent = () => (
  <ThreatIntelReportsContextProvider>
    <ThreatIntelReports />
  </ThreatIntelReportsContextProvider>
)

const components = {
  reports: ReportsComponent,
  fullReport: ThreatIntelFullReport,
}

const ThreatIntel = ({ component = 'reports' }: ThreatIntelProps) => {
  const [selectedTab, setSelectedTab] = useState(0)
  const { featureThreatIntel } = useFlags()

  // eslint-disable-next-line security/detect-object-injection
  const Component = components[component]

  if (featureThreatIntel) {
    return (
      <article
        id="threat-intel-page"
        className="content"
        data-testid="threat-intel"
      >
        <div className="threat-intel-tabs-container">
          <Tabs
            data={ThreatIntelTabs}
            activeTab={0}
            onTabClick={(id) => {
              setSelectedTab(id)
            }}
          />
        </div>
        {[<Component key={selectedTab} />].at(selectedTab)}
      </article>
    )
  }
}

export default ThreatIntel
