import classNames from 'classnames'
import { format } from 'date-fns'

import {
  Typography,
  colors,
  Icon,
  IconProps,
} from '../../../../../design-system'
import {
  ReportType,
  ThreatIntelReport,
} from '../../../../../models/ThreatIntel'
import { getAdvisoryCardIconVariant } from './ThreatIntelReportsAdvisoryCard.utils'
import { ReportTypeLabel } from '../../../ThreatIntel.utils'

import './ThreatIntelReportsAdvisoryCard.scss'

interface ThreatIntelReportsAdvisoryCardProps {
  advisory: ThreatIntelReport
  onClick: (id: string) => void
  isActive: boolean
}

export const ThreatIntelReportsAdvisoryCard: React.FC<
  ThreatIntelReportsAdvisoryCardProps
> = ({ advisory, onClick, isActive }) => {
  const { createdDate, markAsRead, title, type } = advisory

  const isSCE = type === ReportType.CUSTOMER_ADVISORY_SVE
  const isCA = type === ReportType.CUSTOMER_ADVISORY_AWARENESS
  const isCIB = type === ReportType.CYBER_INTEL_BRIEF

  const advisoryCardClasses = classNames('advisory-card', {
    sce: isSCE,
    ca: isCA,
    cib: isCIB,
    read: markAsRead,
    active: isActive,
  })

  const iconVariantCIB = markAsRead ? 'documentTextOutline' : 'reader'

  const iconProps: IconProps = isCIB
    ? {
        style: {
          width: 18,
          height: 20,
        },
        variant: iconVariantCIB,
      }
    : {
        customVariant: getAdvisoryCardIconVariant(type, markAsRead),
      }

  return (
    <div
      className={advisoryCardClasses}
      data-testid="advisory-card"
      onClick={() => onClick(advisory.id)}
      role="button"
      tabIndex={0}
      onKeyDown={() => onClick(advisory.id)}
    >
      <div className="title">
        <div className="card-icon">
          <Icon {...iconProps} />
        </div>
        <Typography
          variant={markAsRead ? 'text11medium' : 'text11semibold'}
          withEllipsis={true}
        >
          {title}
        </Typography>
      </div>
      <div className="flex space-between">
        <div data-testid="report-meta">
          {createdDate && (
            <Typography component="span" variant="text12">
              {format(new Date(createdDate), 'MMM do')}&nbsp;&bull;&nbsp;
            </Typography>
          )}
          <Typography
            component="span"
            variant="text12"
            color={isSCE ? colors.util.two.light : colors.util.navy[100]}
          >
            {/* eslint-disable-next-line security/detect-object-injection */}
            {ReportTypeLabel[type as ReportType]}
          </Typography>
        </div>
        {/**
         *
         * TODO: Conditionally add icons here later so this component
         * can be reused on the homepage
         *
         */}
      </div>
    </div>
  )
}
