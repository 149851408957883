import { useContext } from 'react'
import { useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'

import { User, OktaGroup } from '../../../../models'
import { InlineMessageType } from '../../../../design-system'
import { deployToastMessage, renderErrorToast } from '../../../../utils'
import AddEditUserForm, {
  AddEditUserFormValues,
} from '../AddEditUserForm/AddEditUserForm'
import { ModalTitles } from '../UserManagementSettings'
import { Context } from '../../../App'
import {
  filterAdminGroup,
  findGroupsToAdd,
  findGroupsToRemove,
  mapToListItems,
} from '../UserManagementSettings.util'
import { ModalContentProps } from '../../../common/Modal'
import { GET_USER_MANAGEMENT_SETTINGS } from '../../../../graphql/queries/user'
import {
  UPDATE_USER,
  UpdateUserVariables,
} from '../../../../graphql/mutations/user'

interface AddEditUserModalProps extends ModalContentProps {
  user?: User
  isLastAdmin: boolean
  customerGroups: OktaGroup[]
  title: ModalTitles
  // clearUser: () => void
  userGroups?: Array<OktaGroup>
}

const EditUserModal = ({
  user,
  isLastAdmin,
  customerGroups,
  title,
  closeModal,
  userGroups,
}: AddEditUserModalProps) => {
  const { dispatch: globalDispatch, state } = useContext(Context)

  const isEditingSelf = state.user.id === user?.id
  const isFederatedCustomer = state.customer.settings?.isOktaFederated

  const adminGroupID = customerGroups.find((group) =>
    group.groupName.includes('admins'),
  )?.groupId as string

  const initialGroups = userGroups ? mapToListItems(userGroups) : []

  const refetchQueries = [GET_USER_MANAGEMENT_SETTINGS]

  const navigate = useNavigate()

  const [updateUser] = useMutation<UpdateUserVariables>(UPDATE_USER, {
    refetchQueries,
  })

  const handleEditUser = async (values: AddEditUserFormValues) => {
    closeModal()
    deployToastMessage(
      {
        id: crypto.randomUUID(),
        text: 'Processing request to edit user',
        messageType: InlineMessageType.Info,
        dismissible: false,
        secondsToExpire: 3000,
      },
      globalDispatch,
    )

    const filteredGroups = filterAdminGroup(initialGroups)

    const removeFromGroupIds = findGroupsToRemove(
      filteredGroups,
      values.groupIDs,
    )
    const addToGroupIds = findGroupsToAdd(filteredGroups, values.groupIDs)

    // check if user changed admin status
    // if not, don't update remove/add from group ids
    if (user?.isAdmin !== values.isAdmin) {
      values.isAdmin
        ? addToGroupIds.push(adminGroupID)
        : removeFromGroupIds.push(adminGroupID)
    }

    await updateUser({
      variables: {
        input: {
          userIdToUpdate: user?.id,
          firstName: values.firstName,
          lastName: values.lastName,
          title: values.title,
          removeFromGroupIds,
          addToGroupIds,
        },
      },
      onCompleted: () => {
        deployToastMessage(
          {
            id: crypto.randomUUID(),
            text: 'User updated',
            messageType: InlineMessageType.SuccessInline,
            dismissible: false,
            secondsToExpire: 6000,
          },
          globalDispatch,
        )

        // check if user demoted self, if so, navigate out of user managment
        // user would no longer have access to page
        if (isEditingSelf && user.isAdmin && !values.isAdmin) {
          navigate('/dashboard')
        }
      },
      onError: (e) => {
        renderErrorToast(e, globalDispatch)
      },
    })
  }
  return (
    <div id="add-edit-user-modal" data-testid="add-edit-user-modal">
      <AddEditUserForm
        customerGroups={customerGroups}
        initialGroups={initialGroups}
        isEditingSelf={isEditingSelf}
        isFederatedCustomer={isFederatedCustomer ? isFederatedCustomer : false}
        isLastAdmin={isLastAdmin}
        modalState={title}
        onCancelButtonClick={() => closeModal()}
        onSubmit={handleEditUser}
        user={user}
      />
    </div>
  )
}

export default EditUserModal
