import { MouseEventHandler } from 'react'

import { BanKeysEnum } from '../../../../../models/SecurityIndex'
import {
  Icon,
  Tooltip,
  colors,
  Ban,
  IconVariant,
} from '../../../../../design-system'
import { useSecurityIndexContext } from '../../../../../hooks/useSecurityIndexContext'

import './SecurityIndexBans.scss'

interface SecurityIndexBanProps {
  key: string
  index?: string
  details: {
    color?: string
    delta?: number | string | null
    iconVariant: IconVariant
    label: string | JSX.Element
    loading?: boolean
    onClick?: MouseEventHandler<HTMLDivElement>
    value: string
  }
}

const SecurityIndexBans = (): JSX.Element => {
  const { bans, bansLoading, bansError } = useSecurityIndexContext()

  const getBanLabel = (key: BanKeysEnum): string => {
    switch (key) {
      case BanKeysEnum.YOUR_INDEX:
        return 'YOUR INDEX'
      case BanKeysEnum.AVG_CUSTOMER_INDEX:
        return 'AVG CUSTOMER INDEX'
      case BanKeysEnum.AVG_INDUSTRY_INDEX:
        return 'AVG INDUSTRY INDEX'
      default:
        return ''
    }
  }

  const getBanIcon = (key: BanKeysEnum): string => {
    switch (key) {
      case BanKeysEnum.YOUR_INDEX:
        return 'flashOutline'
      case BanKeysEnum.AVG_CUSTOMER_INDEX:
        return 'analytics'
      case BanKeysEnum.AVG_INDUSTRY_INDEX:
        return 'businessOutline'
      default:
        return ''
    }
  }

  const getBanColor = (key: BanKeysEnum): string => {
    switch (key) {
      case BanKeysEnum.YOUR_INDEX:
        return colors.util.green[100]
      case BanKeysEnum.AVG_CUSTOMER_INDEX:
        return colors.util.three.light
      case BanKeysEnum.AVG_INDUSTRY_INDEX:
        return colors.util.four.light
      default:
        return ''
    }
  }

  const getBanValues = (key: BanKeysEnum, metric: string) => {
    return metric
  }

  const getTooltipMessage = (key: string): string => {
    switch (key) {
      case 'your-index':
        return 'Tooltip for security index'
      case 'avg-customer-index':
        return 'Tooltip for customer index'
      case 'avg-industry-index':
        return `Tooltip for industry index`
      default:
        return ''
    }
  }

  const securityIndexBanProps: SecurityIndexBanProps[] = Object.keys(bans).map(
    (banData) => {
      // eslint-disable-next-line security/detect-object-injection
      const ban = bans[banData]
      const hasError = ban.index === null || ban.delta === null || bansError
      const delta: number | null | undefined | string = hasError
        ? null
        : `${Math.round((ban.delta as number) * 100) / 100} pts`

      return {
        key: ban.key,
        metric: ban.index,
        details: {
          label: (
            <>
              {getBanLabel(ban.key)}
              {hasError && !bansLoading && (
                <Icon
                  variant="warningOutline"
                  size={15}
                  color={colors.util.orange[100]}
                />
              )}
            </>
          ),
          iconVariant: getBanIcon(ban.key) as IconVariant,
          value: hasError ? 'Error' : getBanValues(ban.key, ban.index),
          delta: delta,
          color: getBanColor(ban.key),
          loading: bansLoading,
        },
      }
    },
  )

  const renderBans = () =>
    securityIndexBanProps.map((ban) => {
      return (
        <Tooltip
          key={ban.key}
          target={
            <Ban
              data-testid={`security-index-ban-${ban.index}`}
              {...ban.details}
            />
          }
        >
          {getTooltipMessage(ban.key)}
        </Tooltip>
      )
    })

  return (
    <div id="security-index-bans" data-testid="security-index-bans">
      {renderBans()}
    </div>
  )
}

export default SecurityIndexBans
