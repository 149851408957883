import { format } from 'date-fns-tz'
import { useMutation } from '@apollo/client'
import { useContext } from 'react'

import {
  useModalContext,
  useTicketsContext,
  useTicketEditContext,
} from '../../../../hooks'
import {
  Button,
  Icon,
  Loader,
  Typography,
  colors,
} from '../../../../design-system'
import TicketEditCard from './TicketEditCard'
import TicketDataHandler from './TicketDataHandler'
import { TicketResolutionForm } from './TicketResolution'
import { Context } from '../../../App'
import { TicketStateEnum } from '../../../../models'
import { updateTicketInCache } from '../utils'
import {
  GET_TICKET_CASE_ACTIONS,
  TicketCaseActionsResponse,
} from '../../../../graphql/queries/ticket'
import {
  UPDATE_TICKET,
  UpdateTicketVariables,
} from '../../../../graphql/mutations/ticket'

const TicketCaseActionsContent: React.FC<{
  data: TicketCaseActionsResponse
}> = ({ data }) => {
  const { closeModal, openModal } = useModalContext()
  const { resolutionCodeEnum, stateEnum } = useTicketsContext()
  const { sysId, draftTicketFields, resetDraftTicketFields, isEditable } =
    useTicketEditContext()
  const {
    state: {
      user: { isDWEmployee },
    },
  } = useContext(Context)

  const showCustomerAcceptRejectOptions =
    data.state === TicketStateEnum.Resolved && !isDWEmployee

  const resolvedStates: string[] = [
    TicketStateEnum.Closed,
    TicketStateEnum.Resolved,
    TicketStateEnum.Cancelled,
  ]

  const showResolveButtonStates = stateEnum
    .filter((stateEnumVal) => !resolvedStates.includes(stateEnumVal.label))
    .map((enumVal) => enumVal.label)

  const [updateTicket, { loading: isUpdateTicketLoading }] = useMutation<
    { updateTicket: boolean },
    UpdateTicketVariables
  >(UPDATE_TICKET, {
    fetchPolicy: 'network-only',
    refetchQueries: [
      { query: GET_TICKET_CASE_ACTIONS, variables: { ticketId: sysId } },
    ],
  })

  const handleResolutionClick = () => {
    openModal({
      component: (
        <TicketResolutionForm
          closeModal={closeModal}
          resolutionCodeEnum={resolutionCodeEnum}
          sysId={sysId}
          ticketNumber={data.ticketNumber}
          draftTicketFields={draftTicketFields}
          resetDraftTicketFields={resetDraftTicketFields}
        />
      ),
      title: 'Resolution details',
    })
  }

  const handleStatusClick = async (state: string) => {
    await updateTicket({
      variables: {
        input: {
          ticketId: sysId,
          ticketData: {
            state,
          },
        },
      },
      onCompleted: () => {
        updateTicketInCache({
          ticketId: sysId,
          newFields: {
            state,
          },
        })
      },
    })
  }

  const handleClick = showResolveButtonStates.includes(data.state)
    ? handleResolutionClick
    : handleStatusClick

  const getButtons = () => {
    let buttonLabel: string | null = null

    if (showResolveButtonStates.includes(data.state)) {
      buttonLabel = 'Resolve case'
    } else if (data.state === TicketStateEnum.Resolved && isDWEmployee) {
      buttonLabel = 'Close case'
    } else if (showCustomerAcceptRejectOptions) {
      buttonLabel = 'Accept resolution & close'
    }

    if (showCustomerAcceptRejectOptions) {
      return (
        <>
          <Button
            disabled={!isEditable}
            onClick={() => handleClick(TicketStateEnum.Closed)}
          >
            {buttonLabel}
          </Button>
          <Button
            disabled={!isEditable}
            onClick={() => handleClick(TicketStateEnum.InProgressDW)}
            customClass="reject-resolution-button"
          >
            Reject resolution
          </Button>
        </>
      )
    } else if (buttonLabel) {
      return (
        <Button
          disabled={!isEditable}
          onClick={() => handleClick(TicketStateEnum.Closed)}
        >
          {buttonLabel}
        </Button>
      )
    }
  }

  if (isUpdateTicketLoading) {
    return (
      <div className="flex center align-center w-full h-full">
        <Loader strokeWidth={1} size={50} />
      </div>
    )
  }

  return (
    <div className="flex column gap-24">
      <div className="flex align-center center gap-12">
        <Icon
          variant="skull"
          style={{
            padding: 5.6,
            borderRadius: 5.6,
            color: colors.common.black,
            background: colors.util.two.main,
          }}
          size={28}
        />
        <Typography
          component="div"
          variant="text7semibold"
          color={colors.util.navy[50]}
        >
          {data.ticketNumber}
        </Typography>
      </div>
      {getButtons()}
      <div
        className="flex center align-center gap-2"
        style={{ fontSize: 12, color: colors.util.navy[100] }}
      >
        Last updated:{' '}
        <Typography component="span" variant="text12semibold">
          {format(new Date(data.sysUpdatedOn), 'MMM dd, yyy h:mm a zz')}
        </Typography>
      </div>
    </div>
  )
}

const TicketCaseActions: React.FC = () => {
  return (
    <TicketEditCard cardStyles={{ padding: '24px 16px', minHeight: '125px' }}>
      <TicketDataHandler<TicketCaseActionsResponse>
        graphqlQuery={GET_TICKET_CASE_ACTIONS}
        renderContent={(data) => <TicketCaseActionsContent data={data} />}
      />
    </TicketEditCard>
  )
}

export default TicketCaseActions
