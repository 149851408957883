import { MaturityTrends } from '../../../../models/DetectionCoverage'
import {
  FormattedMaturityScoreDataInterface,
  MaturityScoreCoordinates,
} from '../../../Home/Dashboard/charts/MaturityScore/MaturityScore.models'
import { SeriesPoint } from '../ChartTypes'
import { Toggles } from './MaturityBan.types'
import { Typography, colors } from '../../../../design-system'

import './MaturityBanChartStyle.scss'

export function formatMaturityData(
  data: MaturityTrends,
): FormattedMaturityScoreDataInterface[] {
  const customerData: MaturityScoreCoordinates[] = data.customer.map((c) => ({
    x: c.date,
    y: c.score,
  }))
  const dwData: MaturityScoreCoordinates[] = data.deepwatch.map((c) => ({
    x: c.date,
    y: c.score,
  }))
  const industryData: MaturityScoreCoordinates[] =
    data.customerIndustriesAverage
      ? data.customerIndustriesAverage.map((c) => ({ x: c.date, y: c.score }))
      : []

  const maturityNivoChartData: FormattedMaturityScoreDataInterface[] = [
    { id: 'customer', data: customerData },
    { id: 'deepwatch', data: dwData },
    { id: 'industry', data: industryData },
    { id: 'customerTrend', data: [] },
  ]

  return maturityNivoChartData
}

export function setToggleState(state: boolean, stateFunction) {
  if (state === false) {
    stateFunction(true)
  }
  if (state === true) {
    stateFunction(false)
  }
}

export function setLineOpacity(index: number, toggles: Toggles) {
  if (index === 3) {
    return { border: '1px solid', strokeDasharray: '8', strokeWidth: 1.2 }
  }

  if (index === 1 && toggles.dwToggle === true) {
    return { border: '1px solid', strokeWidth: 1.2 }
  }

  if (index === 1 && toggles.dwToggle === false) {
    return { strokeWidth: 0 }
  }

  if (index === 2 && toggles.industryToggle === true) {
    return { border: '1px solid', strokeWidth: 1.2 }
  }

  if (index === 2 && toggles.industryToggle === false) {
    return { strokeWidth: 0 }
  }

  return { border: '1px solid', strokeWidth: 1.2 }
}

export function setStrokeColor(index: number) {
  if (index === 0 || index === 3) {
    return '#00CC66'
  }

  if (index === 1) {
    return '#3AB6E0'
  }

  if (index === 2) {
    return '#FACC15'
  }
}

export function displayToolTipItem(
  seriePoint: SeriesPoint | undefined,
  toggles: Toggles,
) {
  if (seriePoint?.serieId === 'deepwatch' && toggles.dwToggle === true) {
    return (
      <div
        key={seriePoint.serieId}
        className="flex-container align-items-center"
      >
        <div className="block deepwatch" />
        <Typography component="div" color={colors.util.navy[100]} size={12}>
          {Number(seriePoint.data.yFormatted).toFixed(2)} - Deepwatch average
        </Typography>
      </div>
    )
  }
  if (seriePoint?.serieId === 'industry' && toggles.industryToggle === true) {
    return (
      <div
        key={seriePoint.serieId}
        className="flex-container align-items-center"
      >
        <div className="block industry" />
        <Typography component="div" color={colors.util.navy[100]} size={12}>
          {Number(seriePoint.data.yFormatted).toFixed(2)} - Industry average
        </Typography>
      </div>
    )
  }
  if (seriePoint?.serieId === 'customer') {
    return (
      <div
        key={seriePoint.serieId}
        className="flex-container align-items-center"
      >
        <div className="block customer" />
        <Typography component="div" color={colors.util.navy[100]} size={12}>
          {Number(seriePoint.data.yFormatted).toFixed(2)} - Your score
        </Typography>
      </div>
    )
  }
}
