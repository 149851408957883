import React, { useContext } from 'react'
import { useMutation } from '@apollo/client'
import UAParser from 'ua-parser-js'
import { Field, Formik } from 'formik'
import * as Yup from 'yup'

import { Button, Input, ToastType } from '../../../../design-system'
import { Context } from '../../../App'
import { deployToastMessage } from '../../../../utils'
import { ModalContentProps } from '../../../common/Modal'
import { CREATE_JIRA_ISSUE } from '../../../../graphql/mutations/bugReporting'

import './BugForm.scss'

const parser = new UAParser()
const metadata = {
  screenResolution: `${window.screen.width}x${window.screen.height}`,
  browser: {
    name: parser.getBrowser().name,
    vendor: parser.getBrowser().vendor,
    version: parser.getBrowser().version,
  },
  viewportSize: `${window.innerWidth}x${window.innerHeight}`,
  operatingSystem: `${parser.getOS().name} ${parser.getOS().version}`,
}

const metadataString = [
  `Screen Resolution: ${metadata.screenResolution}`,
  `Browser Name: ${metadata.browser.name}`,
  `Browser Vendor: ${metadata.browser.vendor}`,
  `Browser Version: ${metadata.browser.version}`,
  `Viewport Size: ${metadata.viewportSize}`,
  `Operating System: ${metadata.operatingSystem}`,
].join('\n')

const locationMax = 32
const summaryMax = 150
const descriptionCharacterMax = 2000 - metadataString.length

const BugFormSchema = Yup.object().shape({
  location: Yup.string()
    .max(
      locationMax,
      `The Location must be no more than ${locationMax} characters`,
    )
    .required('Please enter the location where the bug takes place'),
  summary: Yup.string()
    .max(
      summaryMax,
      `The Summary must be no more than ${summaryMax} characters`,
    )
    .required('Please enter a bug summary'),
  description: Yup.string()
    .max(
      descriptionCharacterMax,
      `The Description must be no more than ${descriptionCharacterMax} characters`,
    )
    .required('Please enter a description of the bug that takes place'),
  severity: Yup.string().required('Please select the severity of the bug'),
})

const BugForm: React.FC<ModalContentProps> = ({ closeModal }) => {
  const { dispatch } = useContext(Context)
  const toggleForm = () => {
    closeModal()
  }
  const [createIssueMutation] = useMutation(CREATE_JIRA_ISSUE)

  const SuccessMessage = {
    id: crypto.randomUUID(),
    messageType: ToastType.SuccessToast,
    text: 'Thank you for your feedback',
    title: 'Bug submitted successfully',
    secondsToExpire: 5000,
    dismissible: true,
  }
  const ErrorMessage = {
    id: crypto.randomUUID(),
    messageType: ToastType.WarningToast,
    text: 'Please try to submit again',
    title: 'Bug failed to submit',
    secondsToExpire: 5000,
    dismissible: true,
  }

  const LoadingMessage = {
    id: crypto.randomUUID(),
    text: `Processing request...`,
    messageType: ToastType.Default,
    secondsToExpire: 5000,
    dismissible: false,
  }
  const handleSubmit = async ({ location, summary, description, severity }) => {
    const finalSummary = `${summary} - ${severity}`
    const finalDescription = `${description}\nLocation: ${location}\nMetadata: ${metadataString}`

    deployToastMessage(LoadingMessage, dispatch)
    toggleForm()

    createIssueMutation({
      variables: { summary: finalSummary, description: finalDescription },
      onCompleted: () => {
        deployToastMessage(SuccessMessage, dispatch)
      },
      onError: (error) => {
        deployToastMessage({ ...ErrorMessage, text: error.message }, dispatch)
      },
    })
  }

  const severityOptions = [
    {
      id: 'severity-low',
      name: 'severity',
      value: 'low',
      label: 'Low',
      subtitle: "Minor issues that don't affect functionality",
    },
    {
      id: 'severity-medium',
      name: 'severity',
      value: 'medium',
      label: 'Medium',
      subtitle: 'Issues that affect some functionality',
    },
    {
      id: 'severity-high',
      name: 'severity',
      value: 'high',
      label: 'High',
      subtitle: 'Critical issues that affect all functionality',
    },
  ]

  return (
    <div id="bug-form-modal" data-testid="bug-form-modal">
      <Formik
        initialValues={{
          location: '',
          summary: '',
          description: '',
          severity: '',
        }}
        onSubmit={handleSubmit}
        validationSchema={BugFormSchema}
      >
        {({
          handleBlur,
          handleChange,
          submitForm,
          errors,
          touched,
          values,
          dirty,
          isValid,
        }) => {
          return (
            <>
              <div className="bug-form-fields">
                <Input
                  disabled={false}
                  error={
                    errors.location !== undefined &&
                    touched.location !== undefined
                  }
                  errorText={
                    errors.location && touched.location ? errors.location : ''
                  }
                  label="Location"
                  labelId="bug-location"
                  name="location"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Enter where the bug takes place"
                  required={true}
                  type="text"
                  value={values.location}
                />

                <Input
                  disabled={false}
                  error={
                    errors.summary !== undefined &&
                    touched.summary !== undefined
                  }
                  errorText={
                    errors.summary && touched.summary ? errors.summary : ''
                  }
                  label="Summary"
                  labelId="bug-summary"
                  name="summary"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Enter the bug summary here"
                  required={true}
                  type="text"
                  value={values.summary}
                />

                <div className="description-input">
                  <label
                    htmlFor="bug-description"
                    className={`field-label ${
                      errors.description && touched.description ? 'invalid' : ''
                    }`}
                  >
                    Description
                  </label>
                  <Field
                    as="textarea"
                    className={`description-textarea ${
                      errors.description && touched.description ? 'invalid' : ''
                    }`}
                    displayname={`description-textarea ${
                      errors.description && touched.description ? 'invalid' : ''
                    }`}
                    disabled={false}
                    id="bug-description"
                    name="description"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Enter the bug description"
                    required={true}
                    value={values.description}
                  />
                  {errors.description && touched.description && (
                    <small className="error-message">
                      {errors.description}
                    </small>
                  )}
                </div>
              </div>

              <div className="bug-form-severity">
                <legend className="field-label">Severity</legend>
                {severityOptions.map((severity) => {
                  return (
                    <div
                      className={`radio-option ${
                        values.severity === severity.value ? 'checked' : ''
                      }`}
                      key={severity.id}
                    >
                      <label>
                        <Field
                          name="severity"
                          key={severity.id}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          required={true}
                          value={severity.value}
                          type="radio"
                        />
                        <span className="label">{severity.label}</span>
                      </label>
                      {severity.subtitle && (
                        <span className="subtitle">{severity.subtitle}</span>
                      )}
                    </div>
                  )
                })}
                {errors.severity && touched.severity && (
                  <small className="error-message">{errors.severity}</small>
                )}
              </div>
              <div
                className="submit-bug-button"
                data-testid="submit-bug-button"
              >
                <Button
                  type="submit"
                  onClick={() => submitForm()}
                  disabled={!isValid || !dirty}
                >
                  Submit
                </Button>
                <Button onClick={toggleForm} variant="secondary">
                  Cancel
                </Button>
              </div>
            </>
          )
        }}
      </Formik>
    </div>
  )
}

export default BugForm
