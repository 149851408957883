import { TicketActivity } from './TicketActivity'
import TicketAdditionalDetails from './TicketAdditionalDetails'
import TicketCaseActions from './TicketCaseActions'
import { TicketContact } from './TicketContact'
import TicketDetails from './TicketDetails'
import { TicketStatus } from './TicketStatus'
import TicketResolution from './TicketResolution'
import { useWindowDimensions } from '../../../../hooks'
import { mobileWindowWidth } from '../../../../constants/constants'

const Body: React.FC = () => {
  const { width } = useWindowDimensions()

  const isMobile = width <= mobileWindowWidth

  if (isMobile) {
    return (
      <div className="flex gap-16 column">
        <TicketCaseActions />
        <TicketStatus />
        <TicketResolution />
        <TicketActivity />
        <TicketDetails />
        <TicketContact />
        <TicketAdditionalDetails />
      </div>
    )
  }

  return (
    <div className="flex gap-16 wrap">
      <div className="flex column gap-16" style={{ flex: 5 }}>
        <TicketResolution />
        <TicketActivity />
        <TicketDetails />
        <TicketAdditionalDetails />
      </div>
      <div className="flex column gap-16" style={{ flex: 1, minWidth: 329 }}>
        <TicketCaseActions />
        <TicketStatus />
        <TicketContact />
      </div>
    </div>
  )
}

export default Body
