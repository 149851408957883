import { TagStatus } from '../../../design-system'
import { Detections } from '../../../models'
import { DetectionCatalogFormatStatus } from '../../../models/Detections'
import { formatEmptyField } from '../../../utils'
import { DetectionCatalogStatus } from '../Coverage.utils'

import './DetectionCatalog.scss'

export const formatData = (detectionsData?: Detections[]) => {
  if (!detectionsData) {
    return []
  }

  return detectionsData.map(
    ({ releaseVersion, useCase, title, mitreTactics, dataTypes, status }) => {
      return {
        id: useCase,
        useCase: formatDetectionTitle(title),
        status: formatStatus(status),
        dataTypes: formatNoMappingDetection(dataTypes),
        mitreTactics: formatNoMappingDetection(mitreTactics),
        releaseVersion: formatEmptyField(releaseVersion),
      }
    },
  )
}

export const formatNoMappingDetection = (arr: Array<string>): string => {
  if (arr.length === 0) {
    return 'No Mapping'
  } else if (arr.length === 1) {
    return `${arr[0]}`
  } else if (arr.length === 2) {
    return `${arr[0]} and ${arr[1]}`
  } else {
    return arr.join(', ').replace(/,([^,]*)$/, ' and$1')
  }
}

export const formatDetectionTitle = (title: string): string => {
  const splitTitle = title.split(/dwa_.+:/i)[1]
  if (splitTitle) {
    return splitTitle.trim()
  } else {
    return title
  }
}

export const formatStatus = (status: string): DetectionCatalogFormatStatus => {
  const lowercaseStatus = status.toLowerCase() as TagStatus
  let sortValue = '1'
  if (lowercaseStatus === 'enabled') {
    sortValue = '2'
  } else if (lowercaseStatus === 'available') {
    sortValue = '3'
  }
  return {
    variant: 'secondary',
    status: lowercaseStatus,
    sortValue: sortValue,
  }
}

export const includeUnavailableDetections = (
  detections: Detections[],
  includeUnavailable: boolean,
): Detections[] => {
  return detections.filter((detection) => {
    if (includeUnavailable) {
      return detection.status === DetectionCatalogStatus.Unavailable
    }
    return (
      detection.status === DetectionCatalogStatus.Enabled ||
      detection.status === DetectionCatalogStatus.Available
    )
  })
}

export const emptyDetection: Detections = {
  useCase: '',
  title: '',
  customerVersion: '',
  releaseVersion: '',
  description: '',
  mitreTactics: [''],
  mitreTechniques: [''],
  dataTypes: [''],
  dataAvailable: false,
  availableInVersion: false,
  status: '',
}

export const matchStringInArray = (
  arrayOfStrings: string[],
  target: string,
): boolean => {
  for (const str of arrayOfStrings) {
    if (str.toLowerCase().includes(target.toLowerCase())) {
      return true
    }
  }
  return false
}

export const keywordSearchFilter = (
  keywords: string,
  dataToFilter: Detections[],
  keywordType: 'title' | 'dataTypes',
): Detections[] => {
  if (keywords !== '') {
    return dataToFilter.filter((detection) => {
      if (keywordType === 'title') {
        if (matchStringInArray([detection.title], keywords)) {
          return detection
        }
      } else if (keywordType === 'dataTypes') {
        if (matchStringInArray(detection.dataTypes, keywords)) {
          return detection
        }
      }
    })
  } else {
    return dataToFilter
  }
}

export const filterByMitreTactic = (
  mitreFilters: string[],
  dataToFilter: Detections[],
): Detections[] => {
  if (mitreFilters.length > 0) {
    return dataToFilter.filter((detection) => {
      const selectedTactics = mitreFilters.filter((selectedTactic) => {
        if (detection.mitreTactics.includes(selectedTactic)) {
          return detection
        }
      })
      return selectedTactics.length > 0 && selectedTactics
    })
  } else {
    return dataToFilter
  }
}

export const filterByStatus = (
  statusFilters: string[],
  dataToFilter: Detections[],
): Detections[] => {
  return statusFilters.length > 0
    ? dataToFilter.filter((detection) =>
        statusFilters.includes(detection.status),
      )
    : dataToFilter
}
