import { Box, List, ListItem, Typography } from '@mui/material'

import { SideSheet } from '@common/SideSheet'
import useTicketEditContext from '@hooks/useTicketEditContext'

const TicketAttachmentsSideSheet = () => {
  const {
    isAttachmentsSideSheetOpen,
    setIsAttachmentsSideSheetOpen,
    attachments,
  } = useTicketEditContext()
  return (
    <SideSheet
      open={isAttachmentsSideSheetOpen}
      title="Attachments"
      closeSideSheet={() => setIsAttachmentsSideSheetOpen(false)}
      data-testid="ticket-attachments-side-sheet"
    >
      <Box p={2}>
        <Typography mb={2}>{attachments.length} Attachments</Typography>
        <List>
          {attachments.map(({ attachmentSysId, name }) => {
            return <ListItem key={attachmentSysId}>{name}</ListItem>
          })}
        </List>
      </Box>
    </SideSheet>
  )
}

export default TicketAttachmentsSideSheet
