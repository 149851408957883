import { MouseEvent, FocusEvent } from 'react'
import { createRoot } from 'react-dom/client'
import { flushSync } from 'react-dom'

import {
  MitreCoverageCustomer,
  MitreCoverageTactic,
  MitreCoverageTechnique,
} from '../../../../models/DetectionCoverage'
import { useMitreOverviewContext } from '../context/MitreOverviewContext'
import { Icon, Typography, colors } from '../../../../design-system'

import './MitreCoverage.scss'

export default function MitreCoverageTable() {
  return (
    <div
      data-testid="mitre-coverage-table"
      className="flex-container column"
      style={{ height: '1035px' }}
    >
      <Tactics />
      <div className="flex-container">
        <Techniques />
      </div>
    </div>
  )
}

function Tactics() {
  const {
    mitreSelection,
    setMitreSelection,
    setCarouselTechnique,
    coverage,
    handleTooltipEnter,
    handleTooltipLeave,
    handleSideSheet,
  } = useMitreOverviewContext()

  const handleTacticEnter = (
    event: MouseEvent<HTMLButtonElement> | FocusEvent<HTMLButtonElement>,
    mitreTacticName: string,
    tacticIndex: number,
  ) => {
    const div = document.createElement('div')
    const root = createRoot(div)

    flushSync(() => {
      root.render(
        <Typography
          component="span"
          size={12}
          color={`${colors.util.navy[100]}`}
          weight={400}
        >
          {mitreTacticName}
        </Typography>,
      )
    })

    handleTooltipEnter(event, 4, div.innerHTML)

    // update the tactic column HTML container class
    /* eslint-disable-next-line security/detect-object-injection */
    document
      .getElementsByClassName('flex-container tactic')
      [tacticIndex].classList.add('highlight')
  }
  const handleTacticLeave = (tacticIndex: number) => {
    handleTooltipLeave()
    if (
      mitreSelection.tactic !== tacticIndex ||
      mitreSelection.technique !== undefined
    ) {
      /* eslint-disable-next-line security/detect-object-injection */
      document
        .getElementsByClassName('flex-container tactic')
        [tacticIndex].classList.remove('highlight')
    }
  }

  const handleTacticSelect = (tacticIndex: number) => {
    if (
      mitreSelection.tactic === tacticIndex &&
      mitreSelection.technique === undefined
    ) {
      setMitreSelection({
        tactic: undefined,
        technique: undefined,
        subtechnique: undefined,
      })

      return
    }
    setMitreSelection({
      tactic: tacticIndex,
      subtechnique: undefined,
      technique: undefined,
    })
    setCarouselTechnique(undefined)
  }

  const handleButtonClick = (tacticIndex: number) => {
    handleSideSheet(tacticIndex, undefined, undefined, () => {
      setMitreSelection({
        tactic: undefined,
        technique: undefined,
        subtechnique: mitreSelection.subtechnique,
      })
    })
    handleTacticSelect(tacticIndex)
  }

  return (
    <div
      className="flex-container gap-4"
      style={{
        paddingBottom: 8,
        borderBottom: `1px solid ${colors.util.navy[400]}`,
        marginBottom: 8,
      }}
    >
      {coverage.dw.map((tactic, tacticIndex) => (
        <button
          key={tactic.mitreTacticId}
          className={`mitre tactic${
            mitreSelection.tactic === tacticIndex &&
            mitreSelection.technique === undefined
              ? ' active'
              : ''
          }`}
          onMouseOver={(event) =>
            handleTacticEnter(event, tactic.mitreTacticName, tacticIndex)
          }
          onFocus={(event) =>
            handleTacticEnter(event, tactic.mitreTacticName, tacticIndex)
          }
          onMouseOut={() => handleTacticLeave(tacticIndex)}
          onBlur={() => handleTacticLeave(tacticIndex)}
          onClick={() => handleButtonClick(tacticIndex)}
        >
          {tactic.mitreTacticName}
        </button>
      ))}
    </div>
  )
}

function Techniques() {
  const {
    setHasUserClosedSideSheet,
    mitreSelection,
    setCarouselTechnique,
    setMitreSelection,
    coverage,
    handleTooltipEnter,
    handleTooltipLeave,
    handleSideSheet,
    resetSelectionState,
  } = useMitreOverviewContext()

  const toolTipEnhanced = (
    technique: MitreCoverageTechnique,
    customer?: MitreCoverageCustomer,
  ) => {
    return (
      <>
        <div
          style={{
            borderTop: '0.75px solid #36474C',
            marginTop: '3px',
            marginBottom: '2px',
          }}
        />
        <div className="flex-container space-between align-items-center">
          <div className="flex-container align-items-center gap-16 flex-grow-0">
            <div className="flex-container align-items-center gap-4">
              <Icon
                variant="pulse"
                size={15}
                color={colors.brand.secondary.light}
              />
              <Typography
                component="span"
                size={12}
                color={`${colors.util.navy[100]}`}
                weight={400}
              >
                {technique.totalUseCaseCount}
              </Typography>
            </div>
            <div className="flex-container align-items-center gap-4">
              <Icon
                variant="cloudDoneOutline"
                size={15}
                color={colors.brand.primary.light}
              />
              <Typography
                component="span"
                size={12}
                color={`${colors.util.navy[100]}`}
                weight={400}
              >
                {customer?.totalEnabledUseCaseCount ?? 0}
              </Typography>
            </div>
            <div className="flex-container align-items-center gap-4">
              <Icon
                variant="returnDownForwardSharp"
                size={15}
                color={colors.util.navy[200]}
              />
              <Typography
                component="span"
                size={12}
                color={`${colors.util.navy[100]}`}
                weight={400}
              >
                {technique?.subtechniques.length ?? 0}
              </Typography>
            </div>
          </div>
        </div>
      </>
    )
  }

  const showTechniqueTooltip = (
    event: MouseEvent<HTMLButtonElement> | FocusEvent<HTMLButtonElement>,
    tactic: MitreCoverageTactic,
    technique: MitreCoverageTechnique,
    customer: MitreCoverageCustomer | undefined,
  ) => {
    const div = document.createElement('div')
    const root = createRoot(div)

    flushSync(() => {
      root.render(
        <>
          <div style={{ display: 'flex', gap: 8 }}>
            <Typography
              component="span"
              size={12}
              color={`${colors.util.navy[100]}`}
              weight={400}
            >
              {tactic.mitreTacticName}
            </Typography>
            <Typography
              component="span"
              size={12}
              color={`${colors.util.navy[100]}`}
              weight={400}
            >
              {tactic.mitreTacticId}
            </Typography>
          </div>
          <Typography
            component="span"
            size={12}
            color={`${colors.util.navy[50]}`}
            weight={600}
          >
            {technique.mitreTechniqueName}
          </Typography>
          {toolTipEnhanced(technique, customer)}
        </>,
      )
    })

    handleTooltipEnter(event, 2, div.innerHTML)
  }

  const handleTechniqueClick = (
    tacticIndex: number,
    techniqueIndex: number,
    technique: MitreCoverageTechnique,
  ) => {
    setHasUserClosedSideSheet(false)

    handleSideSheet(tacticIndex, techniqueIndex, undefined, () => {
      resetSelectionState(technique)
    })

    if (
      mitreSelection.tactic === tacticIndex &&
      mitreSelection.technique === techniqueIndex
    ) {
      setCarouselTechnique(undefined)
      setMitreSelection({
        tactic: undefined,
        technique: undefined,
        subtechnique: mitreSelection.subtechnique,
      })

      return
    }

    setCarouselTechnique(technique.mitreTechniqueId)
    setMitreSelection({
      tactic: tacticIndex,
      technique: techniqueIndex,
      subtechnique: undefined,
    })
  }

  return (
    <>
      {coverage.dw.map((tactic, tacticIndex) => (
        <div
          key={tactic.mitreTacticId}
          className={
            mitreSelection.tactic === tacticIndex &&
            mitreSelection.technique === undefined
              ? 'flex-container column gap-8 tactic highlight active'
              : 'flex-container column gap-8 tactic'
          }
        >
          {tactic.techniques.map((technique, techniqueIndex) => {
            const matchingCoverage = coverage.customer.find(
              (customerTechnique) =>
                customerTechnique.mitreTechniqueId ===
                technique.mitreTechniqueId,
            )

            const coverageClass =
              matchingCoverage !== undefined &&
              matchingCoverage.totalEnabledUseCaseCount
                ? 'covered'
                : 'not-covered'

            const customer = coverage.customer.find(
              (coverageCustomer) =>
                coverageCustomer.mitreTechniqueId ===
                technique.mitreTechniqueId,
            )

            return (
              <button
                key={technique.mitreTechniqueId}
                aria-label={`${technique.mitreTechniqueName}`}
                onMouseOver={(event) =>
                  showTechniqueTooltip(event, tactic, technique, customer)
                }
                onFocus={(event) =>
                  showTechniqueTooltip(event, tactic, technique, customer)
                }
                onMouseOut={handleTooltipLeave}
                onBlur={handleTooltipLeave}
                className={`mitre technique ${
                  technique.isCovered ? coverageClass : 'not-available'
                }${
                  mitreSelection.tactic === tacticIndex &&
                  mitreSelection.technique === techniqueIndex
                    ? ' active'
                    : ''
                }`}
                onClick={() =>
                  handleTechniqueClick(tacticIndex, techniqueIndex, technique)
                }
              />
            )
          })}
        </div>
      ))}
    </>
  )
}
