import { colors, stateColors } from '../../theme'
import { BannerVariant, CSSProps, IconVariant } from '../../interfaces'

const getBackgroundColor = (variant: BannerVariant): string => {
  switch (variant) {
    case 'informational':
      return stateColors.informational.main
    case 'success':
      return colors.brand.primary.lighter
    case 'warning':
      return stateColors.warning.main
    case 'error':
      return colors.util.two.lighter
  }
}

export const commonStyles = (variant: BannerVariant): CSSProps => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '8px 16px',
  color: colors.util.navy[600],
  backgroundColor: getBackgroundColor(variant),
  zIndex: 999,
  width: 'content-width',
  height: 24,
  justifyContent: 'space-between',
  boxSizing: 'content-box',
})

export const getLeftIcon = (
  variant: BannerVariant,
): IconVariant | undefined => {
  switch (variant) {
    case 'informational':
      return 'informationCircle'
    case 'success':
      return 'checkmarkCircle'
    case 'warning':
      return 'alertCircle'
    case 'error':
      return 'warning'
  }
}
