import { useNavigate } from 'react-router-dom'
import { MouseEventHandler, useContext } from 'react'

import { BanKeys, DashboardHighlightMetric } from '../../../../models/Dashboard'
import { toTimeUnits } from '../../../../utils/DateTimeUtils'
import {
  Icon,
  Tooltip,
  colors,
  Ban,
  IconVariant,
} from '../../../../design-system'
import { Paths } from '../../../App/Types'
import { Context } from '../../../App'

import './DashboardBans.scss'

interface DashboardBansDataProps {
  data: DashboardHighlightMetric[]
  loading: boolean
  dateFilterableStyle: boolean
}

interface DashboardBanProps {
  key: string
  metric: string | null
  index: string | number
  details: {
    color?: string
    delta?: number | null
    iconVariant: IconVariant
    label: string | JSX.Element
    loading?: boolean
    onClick?: MouseEventHandler<HTMLDivElement>
    value: string
  }
}

const DashboardBans = ({
  data,
  loading,
  dateFilterableStyle,
}: DashboardBansDataProps): JSX.Element => {
  const {
    state: {
      dwExpertsCustomer: { customerShortName },
    },
  } = useContext(Context)
  const navigate = useNavigate()
  let highlightMetrics: DashboardHighlightMetric[] = [
    {
      key: 'mean-time-to-notify-sec',
      metric: null,
      delta: null,
    },
    {
      key: 'mean-time-to-customer-ack-sec',
      metric: null,
      delta: null,
    },
    {
      key: 'technique-covered',
      metric: null,
      delta: null,
    },
    {
      key: 'positives',
      metric: null,
      delta: null,
    },
  ]

  if (data !== null && data.length > 0) {
    highlightMetrics = data
  }

  const getBanLabel = (key: BanKeys): string => {
    switch (key) {
      case 'mean-time-to-notify-sec':
        return 'MTTN'
      case 'mean-time-to-customer-ack-sec':
        return 'MTT ACK'
      case 'technique-covered':
        return 'TECHNIQUES COVERED'
      case 'positives':
        return 'ESCALATION RATE'
      default:
        return ''
    }
  }

  const getBanIcon = (key: BanKeys): string => {
    switch (key) {
      case 'mean-time-to-notify-sec':
        return 'eyeOutline'
      case 'mean-time-to-customer-ack-sec':
        return 'flashOutline'
      case 'technique-covered':
        return 'keypad'
      case 'positives':
        return 'checkmarkDone'
      default:
        return ''
    }
  }

  const getBanColor = (key: BanKeys): string => {
    switch (key) {
      case 'mean-time-to-notify-sec':
        return colors.util.navy[100]
      case 'mean-time-to-customer-ack-sec':
        return colors.brand.secondary.lighter
      case 'technique-covered':
        return colors.brand.secondary.light
      case 'positives':
        return colors.util.three.light
      default:
        return ''
    }
  }

  const getBanValues = (key: BanKeys, metric: string) => {
    if (
      key === 'mean-time-to-customer-ack-sec' ||
      key === 'mean-time-to-notify-sec'
    ) {
      return toTimeUnits(Math.round(Number(metric)))
    } else if (key === 'positives') {
      return `${metric}%`
    } else {
      return metric
    }
  }

  const getTooltipMessage = (key: string, metric: string | null): string => {
    switch (key) {
      case 'mean-time-to-notify-sec':
        return 'The mean time it takes your Deepwatch experts to notify you about an event in your environment.'
      case 'mean-time-to-customer-ack-sec':
        return 'The mean time for a customer to acknowledge a ticket.'
      case 'technique-covered':
        return `You currently have ${metric} MITRE techniques covered based on your enabled detections.`
      case 'positives':
        return 'The percent of alerts that become tickets you receive from your Deepwatch experts.'
      default:
        return ''
    }
  }

  const handleBanOnClick = (key: string): void => {
    switch (key) {
      case 'mean-time-to-notify-sec':
        break
      case 'mean-time-to-customer-ack-sec':
        break
      case 'technique-covered':
        navigate(`${Paths.DETECTION_COVERAGE}?customer=${customerShortName}`)
        break
      case 'positives':
        break
      default:
        break
    }
  }

  const dashboardBanProps: DashboardBanProps[] = highlightMetrics.map(
    (ban, index) => {
      const hasError =
        ban.metric === null ||
        (ban.delta === null && ban.key !== 'technique-covered')
      let delta: number | null | undefined | string = hasError
        ? null
        : Math.round(ban.delta as number)

      if (ban.key === 'technique-covered') {
        delta = undefined
      }

      return {
        key: ban.key,
        metric: ban.metric,
        index: index,
        details: {
          label: (
            <>
              {getBanLabel(ban.key)}
              {hasError && !loading && (
                <Icon
                  variant="warningOutline"
                  size={15}
                  color={colors.util.orange[100]}
                />
              )}
            </>
          ),
          iconVariant: getBanIcon(ban.key) as IconVariant,
          value: !ban.metric ? 'Error' : getBanValues(ban.key, ban.metric),
          delta: delta,
          color: getBanColor(ban.key),
          loading: loading,
          ...(ban.key === 'technique-covered' && {
            onClick: () => handleBanOnClick(ban.key),
          }),
        },
      }
    },
  )

  const renderBans = () =>
    dashboardBanProps.map((ban) => {
      return (
        <Tooltip
          key={ban.key}
          target={
            <Ban
              data-testid={`dashboard-ban-${ban.index}`}
              {...ban.details}
              style={
                dateFilterableStyle && ban.key !== 'technique-covered'
                  ? {
                      border: '1px solid #3AB6E0',
                      transition: 'ease-out border-color .3s',
                    }
                  : {
                      border: '1px solid #27373C',
                      transition: 'ease-in border-color .3s',
                    }
              }
            />
          }
        >
          {getTooltipMessage(ban.key, ban.metric)}
        </Tooltip>
      )
    })

  return <div id="dashboard-bans">{renderBans()}</div>
}

export default DashboardBans
