import { useContext } from 'react'

import { ThreatIntelReportsContext } from './'

export const useThreatIntelReportsContext = () => {
  const threatIntelReportsContext = useContext(ThreatIntelReportsContext)

  if (!threatIntelReportsContext) {
    throw new Error(
      'ThreatIntelReportsContext must be used within ThreatIntelReportsContextProvider',
    )
  }

  return threatIntelReportsContext
}
