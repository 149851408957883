import { CSSProperties, ReactNode } from 'react'

import { CaptionProps } from './Caption/Caption'
import { IconButtonProps } from '../../interfaces'

export interface ColumnHeader {
  name: string
  onClickOverride?: (sortDirection: SortingOrder, index: number) => void
  sortable?: boolean
  tooltip?: boolean
  tooltipMessage?: ReactNode
  visible?: boolean
  columnWidth?: string
  secondarySortDirection?: SortingOrder | ''
  secondarySortId?: string | number
}

export interface TableSortProps {
  isSortedExternally?: boolean
  sortDirection?: SortingOrder
  defaultSortColumn?: number
  secondarySortDirection?: SortingOrder
  secondaryDefaultSortColumn?: number
}

export interface TableProps {
  bodyColor?: string
  bodyTextColor?: string
  captionProps?: CaptionProps
  clickableTableRow?: boolean
  customHeaders?: ColumnHeader[]
  data: Record<string, any>[]
  headerColor?: string
  headerTextColor?: string
  loading?: boolean
  loadingRows?: number
  loadingRowState?: TableLoadingState
  onBottomReached?: VoidFunction
  onClick?: (id: string | number, rowData?: any) => void
  rowHoverElements?: IconButtonProps[]
  sortOptions?: TableSortProps
  stickyHeaderOffset?: number
  styles?: CSSProperties
}

export enum TableLoadingState {
  IDLE,
  LOADING,
  ALL_LOADED,
  ERROR,
}

export interface TableLoadingRowProps {
  columnSpan: number
  loadingState: TableLoadingState
}

export type SortingOrder = 'ASC' | 'DESC'
