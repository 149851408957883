import { format } from 'date-fns'

import { Typography } from '../../../../../design-system'
import { UserAvatar } from '../../../../common/UserAvatar'
import { TicketEditEnum } from '../../../../../models'

export const setPTagsAndLabels = (key: string): string => {
  if (!key) {
    return ''
  } else if (key.toLowerCase() === 'state') {
    return 'Status'
  } else {
    const lowerCaseString = key
      .split(/(?=[A-Z])/)
      .map((s) => s.toLowerCase())
      .join(' ')

    const formattedPTag =
      lowerCaseString.charAt(0).toUpperCase() + lowerCaseString.slice(1)

    return formattedPTag
  }
}

export const formatCreatedDate = (date: Date): string => {
  return format(date, 'MMM dd, yyyy hh:mm aaa')
}

export const findEnumLabelAndNumber = (
  label: string | boolean | null,
  enumValues: TicketEditEnum[],
): TicketEditEnum | null => {
  return (
    enumValues.find((enumValue: TicketEditEnum) => enumValue.label === label) ||
    null
  )
}

export const returnUserAvatarOrString = (key: string, val: string, user?) => {
  if (key === 'assignedUser') {
    return (
      <div
        style={{
          paddingTop: '8px',
          display: 'flex',
          gap: '10px',
          alignItems: 'center',
        }}
      >
        <UserAvatar userInfo={user} />
        <Typography size={16} color={'#FFF'}>
          {user ? [user.firstName, user.lastName].join(' ') : '--'}
        </Typography>
      </div>
    )
  } else {
    let text = val ?? '--'

    if (key === 'sysCreatedOn') {
      text = formatCreatedDate(new Date(val))
    }

    return <Typography size={14}>{text}</Typography>
  }
}

/**
 * Use this function in `.sort` to match the order of a given array
 * @param aKey The a value from `.sort`
 * @param bKey The b value from `.sort`
 * @param order The order to match
 * @returns The sort order for `.sort`
 */
export const sortKeysToMatch = (
  aKey: string,
  bKey: string,
  order: string[],
): number => {
  const aIndex = order.indexOf(aKey)
  const bIndex = order.indexOf(bKey)

  return aIndex - bIndex
}
