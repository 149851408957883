import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { Icon, Typography, colors } from '../../../design-system'
import {
  IconButtonProps,
  InlineMessageType,
} from '../../../design-system/interfaces'
import { CustomerHighlight } from '../../../models'
import { Paths } from '../../App/Types'
import { Context, setCustomerNames } from '../../App'
import { deployToastMessage } from '../../../utils'
import { DeepwatchExpertCustomerTable } from '../DeepwatchExpertCustomerTable'
import { GET_DW_EXPERTS_HOME_DATA } from '../../../graphql/queries/dwExperts'
import { REMOVE_BOOKMARKED_CUSTOMER } from '../../../graphql/mutations/customer'
import { getCustomerString } from '../dwExperts.utils'

import './MyCustomers.scss'

interface MyCustomersProps {
  bookmarkedCustomers: CustomerHighlight[]
  loading: boolean
}

const MyCustomers: React.FC<MyCustomersProps> = ({
  bookmarkedCustomers,
  loading,
}) => {
  const navigate = useNavigate()
  const { featureAssignedCustomers } = useFlags()
  const { dispatch } = useContext(Context)

  const [removeBookmarkedCustomer] = useMutation(REMOVE_BOOKMARKED_CUSTOMER, {
    refetchQueries: [GET_DW_EXPERTS_HOME_DATA],
  })

  const handleNavigateButtonClick = (customer: CustomerHighlight) => {
    dispatch(
      setCustomerNames({
        customerName: String(customer.name),
        customerShortName: customer.customerShortName,
      }),
    )
    navigate(`${Paths.DASHBOARD}?customer=${customer.customerShortName}`)
  }

  const handleRemoveCustomerButtonClick = async (
    customer: CustomerHighlight,
  ) => {
    deployToastMessage(
      {
        id: crypto.randomUUID(),
        text: `Making request to remove ${getCustomerString(
          customer,
        )} from "My Customers"`,
        messageType: InlineMessageType.Info,
        secondsToExpire: 3000,
        dismissible: false,
      },
      dispatch,
    )

    await removeBookmarkedCustomer({
      variables: {
        customers: [customer.customerShortName],
      },
      onCompleted: () => {
        deployToastMessage(
          {
            id: crypto.randomUUID(),
            text: `${getCustomerString(customer)} removed from "My Customers"`,
            messageType: InlineMessageType.SuccessInline,
            secondsToExpire: 3000,
            dismissible: false,
          },
          dispatch,
        )
      },
      onError: () => {
        deployToastMessage(
          {
            id: crypto.randomUUID(),
            text: `Could not remove ${getCustomerString(
              customer,
            )} from "My Customers"`,
            messageType: InlineMessageType.ErrorInline,
            secondsToExpire: 3000,
            dismissible: false,
          },
          dispatch,
        )
      },
    })
  }

  const shapeHighlightData = (
    rawHighlight: CustomerHighlight[],
  ): CustomerHighlight[] => {
    return rawHighlight.map((highlight, index: number) => {
      if (highlight.usageGb === null) {
        highlight.usageGb = {
          sortValue: -1,
          displayValue: (
            <div style={{ color: colors.util.orange[100] }}>Error</div>
          ),
        }
      }

      if ((highlight.usageGb as number) > highlight.usageLicenseGb) {
        highlight.usageGb = {
          sortValue: highlight.usageGb as number,
          displayValue: (
            <div className="dw-squad-coverage">
              <Typography
                variant="text11semibold"
                color={colors.util.two.light}
              >
                {highlight.usageGb as number}
              </Typography>
              <Icon
                variant="alertCircle"
                color={colors.util.two.light}
                size={18}
              />
            </div>
          ),
        }
      }

      const rowHoverElements: IconButtonProps[] = []

      if (featureAssignedCustomers) {
        if (highlight.isBookmarkedCustomer) {
          rowHoverElements.push({
            variant: 'removeCircleSharp',
            label: 'Remove from "My Customers"',
            customOnClick: handleRemoveCustomerButtonClick,
          })
        }

        rowHoverElements.push({
          variant: 'arrowForwardCircleOutline',
          label: 'Customer Dashboard Page',
          customOnClick: handleNavigateButtonClick,
        })
      }

      return {
        ...highlight,
        id: index,
        rowHoverElements,
      }
    })
  }

  return (
    <div data-testid="my-customers" id="my-customers" className="my-customers">
      <DeepwatchExpertCustomerTable
        customerHighlights={shapeHighlightData(bookmarkedCustomers)}
        disableRowClick={true}
        loading={loading}
      />
    </div>
  )
}

export default MyCustomers
