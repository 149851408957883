import { useEffect, useState } from 'react'

import { Typography, Input, Button } from '../../../../design-system'
import { SideSheet } from '../../../SideSheet'
import {
  FilterOptionValues,
  FilterOptions,
  TicketFilterInput,
} from '../../../../models'
import FilterChips from '../../../common/Filters/FilterChips'

interface TicketFilterProps {
  isOpen: boolean
  setIsOpen: (boolean: boolean) => void
  filterOptionsResponse: FilterOptions<FilterOptionValues[]>
  selectedFilters: FilterOptions<TicketFilterInput>
  clearAll: () => void
  applyFilter: (vars: FilterOptions<TicketFilterInput>) => void
}

const TicketFilters = ({
  isOpen,
  setIsOpen,
  filterOptionsResponse,
  selectedFilters,
  clearAll,
  applyFilter,
}: TicketFilterProps): JSX.Element => {
  const [selectedFiltersSideSheet, setSelectedFiltersSideSheet] =
    useState<FilterOptions<TicketFilterInput>>(selectedFilters)

  useEffect(() => {
    setSelectedFiltersSideSheet(selectedFilters)
  }, [selectedFilters])

  return (
    <SideSheet
      data-testid="ticket-filters-header"
      open={isOpen}
      footer={
        <div
          className="dw-filters-buttons"
          data-testid="ticket-filters-buttons"
          id="dw-filters-buttons"
        >
          <Button variant="secondary" onClick={() => clearAll()}>
            Clear all
          </Button>
          <Button onClick={() => applyFilter(selectedFiltersSideSheet)}>
            Apply
          </Button>
        </div>
      }
    >
      <article
        id="filters-sidesheet"
        className={`content dw-filters-sidesheet ${
          isOpen ? 'side-sheet-active' : ''
        }`} // for testing if the side sheet is open or closed. see comment in test file
        data-testid="ticket-filters-sidesheet-test-id"
        style={{ padding: '0px' }}
      >
        <div className="header" data-testid="ticket-filters-sidesheet-header">
          <div className="header-content">
            <Typography>Filtering options</Typography>
            <Button
              variant="navigational-icon"
              iconProps={{ variant: 'closeOutline' }}
              onClick={() => setIsOpen(false)}
            />
          </div>
        </div>
        <div className="dw-filters-form">
          <Input
            leftIcon={'searchOutline'}
            placeholder="Find tickets by ID/Subject"
            type="text"
            labelId="filterSearchText"
            name="ticket filter search text"
            onChange={({ target }) => {
              setSelectedFiltersSideSheet({
                ...selectedFiltersSideSheet,
                keywordSearch: target.value,
              })
            }}
            value={selectedFiltersSideSheet.keywordSearch}
          />
          <>
            {filterOptionsResponse.filters.map(
              ({ label, key, values }): JSX.Element => {
                return (
                  <fieldset className="dw-filters-group" key={key}>
                    <Typography component={'legend'}>{label}</Typography>
                    <FilterChips
                      key={key}
                      keyValue={key}
                      filterGroup={label}
                      filterLabel={label}
                      filterValues={values}
                      selectedFilters={selectedFiltersSideSheet}
                      setSelectedFilters={
                        setSelectedFiltersSideSheet as VoidFunction
                      }
                    />
                  </fieldset>
                )
              },
            )}
          </>
        </div>
      </article>
    </SideSheet>
  )
}

export default TicketFilters
