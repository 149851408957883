import { gql } from '@apollo/client'

import { Customer } from '@models/index'

export interface CustomerNamesResponse {
  customerInfo: Customer
}

export interface CustomerInfoData {
  customerInfo: Customer
}

export interface CustomerInfoVariables {
  selectedCustomer: string | null
}

export const GET_CUSTOMER_INFO_WITH_TA_VERSIONS = gql`
  query CustomerInfo($selectedCustomer: String) {
    customerInfo(selectedCustomer: $selectedCustomer) {
      customerDwaAppVersion
      deepwatchLatestDwaAppVersion
    }
  }
`

const BASE_CUSTOMER_INFO_FIELDS = gql`
  fragment BaseCustomerInfo on CustomerInfo {
    maturityScoreStartDate
    customerShortName
    customerName
    industry
    settings {
      isOktaFederated
    }
    splunkLink
    splunkLicenseType
    splunkDeploymentModel
    splunkVersion
    ngMdrEnabled
    ngMedrEnabled
    ngMfwEnabled
    mdrEnabled
    fwEnabled
    threatSignalEnabled
    vmEnabled
    edrEnabled
    crowdstrikeUrl
    sentinelEnabled
    isLimitedMdrExperience
    betaAgreementStatus
    serviceNgMedrTechnology
  }
`

const EDR_FIELDS = gql`
  fragment EdrFields on CustomerInfo {
    hasEdrAssets
  }
`

export const GET_CUSTOMER_INFO_FOR_USER_INIT = gql`
  ${BASE_CUSTOMER_INFO_FIELDS}
  query CustomerInfo($selectedCustomer: String) {
    customerInfo(selectedCustomer: $selectedCustomer) {
      ...BaseCustomerInfo
    }
  }
`

export const GET_CUSTOMER_INFO_FOR_USER_INIT_WITH_EDR = gql`
  ${BASE_CUSTOMER_INFO_FIELDS}
  ${EDR_FIELDS}
  query CustomerInfo($selectedCustomer: String) {
    customerInfo(selectedCustomer: $selectedCustomer) {
      ...BaseCustomerInfo
      ...EdrFields
    }
  }
`

export const GET_CUSTOMER_INFO_WITH_FULL_DETAILS = gql`
  query CustomerInfo($selectedCustomer: String) {
    customerInfo(selectedCustomer: $selectedCustomer) {
      customerShortName
      customerName
      maturityScoreStartDate
      settings {
        isOktaFederated
      }
      numOfEmployees
      industry
      address {
        street
        city
        state
        postalCode
      }
      splunkLink
    }
  }
`
