export const formatEmptyField = (
  value: string | Array<any> | null | undefined,
): string | Array<any> => {
  if (value === null || value === undefined || !value.length) {
    return '--'
  }
  return value
}

export const makePluralLabel = (value: string, count: number): string => {
  if (count > 1 || count === 0) {
    return `${count} ${value}s`
  } else {
    return `${count} ${value}`
  }
}
