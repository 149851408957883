import { gql } from '@apollo/client'

import {
  TICKET_ACTIVITY_FRAGMENT,
  TICKET_ADDITIONAL_DETAILS_FRAGMENT,
  TICKET_CASE_ACTIONS_FRAGMENT,
  TICKET_CONTACT_FRAGMENT,
  TICKET_DETAILS_FRAGMENT,
  TICKET_MTTX_FRAGMENT,
  TICKET_RESOLUTION_FRAGMENT,
  TICKET_STATUS_FRAGMENT,
} from '../fragments/ticket'
import {
  FilterOptions,
  PaginationInput,
  Ticket,
  TicketData,
  TicketEditSettingsData,
  TicketFilterInput,
  TicketSortingInput,
} from '../../models'

export interface GetTicketsData {
  getTickets: TicketData
}

export interface GetTicketsVariables {
  pagination: PaginationInput
  selectedCustomer: string | null
  selectedFilters: FilterOptions<TicketFilterInput>
  selectedSortings: TicketSortingInput
}

export interface GetTicketDetailsVariables {
  ticketId: string
}
export interface GetTicketEditSettingsData {
  getTicketEditSettings: TicketEditSettingsData
}
export interface GetTicketEditSettingsVariables {
  selectedCustomer: string | null
}

export type TicketCaseActionsResponse = Pick<
  Ticket,
  'ticketNumber' | 'sysUpdatedOn' | 'state'
>

export type TicketDetailsResponse = Pick<
  Ticket,
  'shortDescription' | 'description'
>

export type TicketStatusResponse = Pick<
  Ticket,
  | 'assignedUser'
  | 'impact'
  | 'priority'
  | 'state'
  | 'customerValidateState'
  | 'lastUpdateUser'
  | 'sysCreatedOn'
>

export type TicketActivityResponse = Pick<
  Ticket,
  'comments' | 'openedBy' | 'createdDate' | 'ticketNumber' | 'sysId'
>

export type TicketAdditionalDetailsResponse = Pick<
  Ticket,
  | 'mitreTactics'
  | 'mitreTechniques'
  | 'searchName'
  | 'splunkLink'
  | 'splunkSearch'
  | 'useCaseTitle'
>

export type TicketMttxResponse = Pick<
  Ticket,
  | 'customerAssignmentTime'
  | 'customerAcknowledgeTime'
  | 'customerValidationTime'
  | 'timeToCloseSec'
  | 'timeToCustomerAcknowledgeSec'
  | 'timeToCustomerAssignSec'
  | 'timeToCustomerValidateSec'
  | 'timeToDetectionSec'
  | 'timeToDeclaredIncidentSec'
  | 'timeToDetectResolveAlertSec'
  | 'timeToInvestigateSec'
  | 'timeToNotifySec'
  | 'timeToRespondSec'
  | 'timeToResolveTicketSec'
  | 'timeToUpdateSec'
  | 'totalTimeDeepwatchWaitVendorSec'
  | 'totalTimeDeepwatchWaitCustomerSec'
>

export const GET_TICKETS = gql`
  query GetTickets(
    $selectedFilters: TicketFilterOptionInput
    $selectedCustomer: String
    $pagination: PaginationInput
    $selectedSortings: [TicketSortingInput]
  ) {
    getTickets(
      selectedCustomer: $selectedCustomer
      input: {
        selectedFilters: $selectedFilters
        pagination: $pagination
        selectedSortings: $selectedSortings
      }
    ) {
      tickets {
        sysId
        priority
        type
        createdDate
        sysUpdatedOn
        ticketNumber
        lastUpdateUser
        shortDescription
        openedBy
        assignedUser
        serviceOffering
        customerValidateState
        category
        subcategory
        description
        useCaseTitle
        useCase
        incidentType
        cisControl
        mitreTactics
        mitreTechniques
        splunkLink
        splunkSearch
        servicenowLink
        comments {
          time
          type
          user
          text
        }
        state
      }
      sortingOptions {
        key
        order
      }
      filterOptions {
        filters {
          label
          key
          values {
            label
            value
            selected
          }
        }
        keywordSearch
        showOpenTicketOnly
      }
      pagination {
        limit
        offset
        total
      }
    }
  }
`

export const GET_TICKET_METRICS = gql`
  query GetTicketMetrics($selectedCustomer: String, $input: GetDashboardInput) {
    getTicketMetrics(selectedCustomer: $selectedCustomer, input: $input) {
      openCriticalCount
      openHighCount
      openWaitingCustomerCount
      openWaitingDeepwatchCount
    }
  }
`

export const GET_TICKET_CASE_ACTIONS = gql`
  ${TICKET_CASE_ACTIONS_FRAGMENT}
  query GetTicketDetails($ticketId: String!) {
    getTicketDetails(input: { ticketId: $ticketId }) {
      ...TicketCaseActionsFields
    }
  }
`

export const GET_TICKET_DETAILS = gql`
  ${TICKET_DETAILS_FRAGMENT}
  query GetTicketDetails($ticketId: String!) {
    getTicketDetails(input: { ticketId: $ticketId }) {
      ...TicketDetailsFields
    }
  }
`

export const GET_TICKET_STATUS = gql`
  ${TICKET_STATUS_FRAGMENT}
  query GetTicketDetails($ticketId: String!) {
    getTicketDetails(input: { ticketId: $ticketId }) {
      ...TicketStatusFields
    }
  }
`

export const GET_TICKET_CONTACT = gql`
  ${TICKET_CONTACT_FRAGMENT}
  query GetTicketDetails($ticketId: String!) {
    getTicketDetails(input: { ticketId: $ticketId }) {
      ...TicketContactFields
    }
  }
`

export const GET_TICKET_RESOLUTION = gql`
  ${TICKET_RESOLUTION_FRAGMENT}
  query GetTicketDetails($ticketId: String!) {
    getTicketDetails(input: { ticketId: $ticketId }) {
      ...TicketResolutionFields
    }
  }
`

export const GET_TICKET_ACTIVITY = gql`
  ${TICKET_ACTIVITY_FRAGMENT}
  query GetTicketDetails($ticketId: String!) {
    getTicketDetails(input: { ticketId: $ticketId }) {
      ...TicketActivityFields
    }
  }
`

export const GET_TICKET_ADDITIONAL_DETAILS = gql`
  ${TICKET_ADDITIONAL_DETAILS_FRAGMENT}
  query GetTicketDetails($ticketId: String!) {
    getTicketDetails(input: { ticketId: $ticketId }) {
      ...TicketAdditionalDetailsFields
    }
  }
`

export const GET_TICKET_MTTX = gql`
  ${TICKET_MTTX_FRAGMENT}
  query GetTicketDetails($ticketId: String!) {
    getTicketDetails(input: { ticketId: $ticketId }) {
      ...TicketMttxFields
    }
  }
`
export const GET_TICKET_EDIT_SETTINGS = gql`
  query GetTicketEditSettings($selectedCustomer: String!) {
    getTicketEditSettings(selectedCustomer: $selectedCustomer) {
      enabled
      enumValues {
        urgency {
          label
          numberValue
        }
        priority {
          label
          numberValue
        }
        state {
          label
          numberValue
        }
        resolutionCode {
          label
          numberValue
        }
        customerValidationState {
          label
          numberValue
        }
      }
    }
  }
`

export const GET_TICKETS_WITH_CACHE_FIELDS = gql`
  ${TICKET_CASE_ACTIONS_FRAGMENT}
  ${TICKET_DETAILS_FRAGMENT}
  ${TICKET_STATUS_FRAGMENT}
  ${TICKET_CONTACT_FRAGMENT}
  ${TICKET_ACTIVITY_FRAGMENT}
  ${TICKET_RESOLUTION_FRAGMENT}
  query GetTickets(
    $selectedFilters: TicketFilterOptionInput
    $selectedCustomer: String
    $pagination: PaginationInput
    $selectedSortings: [TicketSortingInput]
  ) {
    getTickets(
      selectedCustomer: $selectedCustomer
      input: {
        selectedFilters: $selectedFilters
        pagination: $pagination
        selectedSortings: $selectedSortings
      }
    ) {
      tickets {
        ...TicketCaseActionsFields
        ...TicketDetailsFields
        ...TicketStatusFields
        ...TicketContactFields
        ...TicketActivityFields
        ...TicketResolutionFields
        sysId
        type
        createdDate
        lastUpdateUser
        openedBy
        useCaseTitle
        useCase
        incidentType
        cisControl
        mitreTactics
        mitreTechniques
        splunkLink
        splunkSearch
        servicenowLink
        comments {
          time
          type
          user
          text
        }
      }
      sortingOptions {
        key
        order
      }
      filterOptions {
        filters {
          label
          key
          values {
            label
            value
            selected
          }
        }
        keywordSearch
        showOpenTicketOnly
      }
      pagination {
        limit
        offset
        total
      }
    }
  }
`

export const GET_TOP_LEVEL_TICKET_DATA = gql`
  ${TICKET_CASE_ACTIONS_FRAGMENT}
  ${TICKET_DETAILS_FRAGMENT}
  ${TICKET_STATUS_FRAGMENT}
  ${TICKET_CONTACT_FRAGMENT}
  ${TICKET_ACTIVITY_FRAGMENT}
  ${TICKET_RESOLUTION_FRAGMENT}
  ${TICKET_ADDITIONAL_DETAILS_FRAGMENT}
  ${TICKET_MTTX_FRAGMENT}
  query GetTicketDetails($ticketId: String!) {
    getTicketDetails(input: { ticketId: $ticketId }) {
      ...TicketCaseActionsFields
      ...TicketDetailsFields
      ...TicketStatusFields
      ...TicketContactFields
      ...TicketActivityFields
      ...TicketResolutionFields
      ...TicketAdditionalDetailsFields
      ...TicketMttxFields
      sysId
    }
  }
`
