import { Fragment, useState } from 'react'

import { useTheme } from '../../../hooks'
import { colors } from '../../../theme'
import { ColumnHeader, SortingOrder } from '../DesktopTable.types'
import { TableRow } from '../TableRow'
import { findAndScrollToTop } from '../DesktopTable.utils'
import { Icon } from '../../Icon'

import './TableHead.scss'

export interface TableHeadProps {
  tableHeaders: ColumnHeader[]
  headerBodyColor?: string
  headerTextColor?: string
  isDarkTheme?: boolean
  bodyColor?: string
  sortDir: SortingOrder
  sortIndex: number
  sortTableColumns: (direction: SortingOrder, index: number) => void
  secondarySortIndex?: number
  secondarySortDirection?: SortingOrder
}

interface TableHeadOnClickArgs {
  index: number
  onClickOverride?: (
    sortDirection: SortingOrder,
    index: number,
    secondaryIndex?: string | number,
    secondarySortDirection?: SortingOrder | '',
  ) => void
  sortable: boolean
  sortDirection: SortingOrder
  sortIndex: number
}

const TableHead = ({
  tableHeaders,
  headerTextColor = '',
  headerBodyColor = '',
  sortDir,
  sortIndex,
  sortTableColumns,
  secondarySortIndex,
  secondarySortDirection,
}: TableHeadProps): JSX.Element => {
  const [sortOrder, setSortOrder] = useState(sortDir)
  const [showToolTip, setShowTooltip] = useState(false)
  const isDarkTheme = useTheme('dark')

  const handleOnClick = (options: TableHeadOnClickArgs): SortingOrder => {
    const { index, sortable, sortDirection, sortIndex, onClickOverride } =
      options

    //Use to get the proper secondary sort direction
    const secSortDirection =
      tableHeaders[`${index}`].secondarySortDirection ?? sortDirection

    //Use to get the proper secondary sort index
    const secIndex = tableHeaders[`${index}`].secondarySortId
      ? tableHeaders[`${index}`].secondarySortId
      : secondarySortIndex

    let firstClick = false
    let result = sortDirection
    if (sortable) {
      if (index === sortIndex) {
        firstClick = true
      } else {
        firstClick = false
        onClickOverride?.(sortDir, index, secIndex, secondarySortDirection)
        setSortOrder(sortDir)
        result = sortDir
      }

      if (firstClick) {
        if (sortDirection === 'ASC') {
          onClickOverride?.('DESC', index, secIndex, secSortDirection)
          setSortOrder('DESC')
          firstClick = false
          result = 'DESC'
        } else {
          onClickOverride?.('ASC', index, secIndex, secSortDirection)
          setSortOrder('ASC')
          firstClick = false
          result = 'ASC'
        }
      }
    }
    return result
  }

  const triggerEvent = (
    event: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent,
    sortable: boolean,
    index: number,
    onClickOverride:
      | ((sortDirection: SortingOrder, index: number) => void)
      | undefined,
  ): void => {
    handleOnClick({
      sortable,
      sortDirection: sortOrder,
      index,
      sortIndex,
      onClickOverride: onClickOverride ?? sortTableColumns,
    })

    if (sortable) {
      findAndScrollToTop(
        event.currentTarget.parentElement,
        'datatable datatable-sticky',
      )
    }
  }

  return (
    <thead>
      <TableRow
        classNames="table-header-row"
        tableHeaders={tableHeaders}
        index={sortIndex}
      >
        {tableHeaders.map(
          (
            {
              visible = true,
              sortable = false,
              name,
              tooltip = false,
              tooltipMessage,
              onClickOverride,
              columnWidth = '',
            }: ColumnHeader,
            index,
          ): JSX.Element => {
            if (visible) {
              return (
                <th
                  key={index}
                  style={{
                    color:
                      sortIndex === index
                        ? colors.util.navy[50]
                        : tableHeaderTextColor(headerTextColor, isDarkTheme),
                    backgroundColor: tableHeaderBodyColor(
                      headerBodyColor,
                      isDarkTheme,
                    ),
                    cursor: sortable ? 'pointer' : 'default',
                    width: columnWidth,
                  }}
                  className="table-head"
                  scope="col"
                  tabIndex={0}
                  onClick={(e) =>
                    triggerEvent(e, sortable, index, onClickOverride)
                  }
                  onMouseEnter={
                    tooltip ? () => setShowTooltip(true) : undefined
                  }
                  onMouseLeave={
                    tooltip ? () => setShowTooltip(false) : undefined
                  }
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      triggerEvent(e, sortable, index, onClickOverride)
                    }
                  }}
                  id={name}
                >
                  {name}
                  {
                    <Icon
                      size={16}
                      variant={
                        sortOrder === 'DESC' && index === sortIndex
                          ? 'arrowDown'
                          : 'arrowUp'
                      }
                      color={
                        index === sortIndex
                          ? colors.util.navy[50]
                          : 'transparent'
                      }
                      style={{ verticalAlign: 'bottom' }}
                    />
                  }
                  {showToolTip && tooltip ? (
                    <span className="table-head-tooltip">{tooltipMessage}</span>
                  ) : null}
                </th>
              )
            } else {
              return <Fragment key={index}></Fragment>
            }
          },
        )}
      </TableRow>
    </thead>
  )
}

export default TableHead

const tableHeaderBodyColor = (
  headerColor: string,
  isDarkTheme: boolean,
): string => {
  if (headerColor) {
    return headerColor
  } else if (isDarkTheme) {
    return colors.util.navy[500]
  } else {
    return colors.util.one.light
  }
}

const tableHeaderTextColor = (
  headerTextColor: string,
  isDarkTheme: boolean,
): string => {
  if (headerTextColor) {
    return headerTextColor
  } else if (isDarkTheme) {
    return colors.util.one.light
  } else {
    return colors.util.one.main
  }
}
