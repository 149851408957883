import { useEffect } from 'react'
import { default as OktaSignIn } from '@okta/okta-signin-widget'

import { OktaWidgetConfig } from '../../config'
import deepwatchLogo from '../../assets/deepwatch-logo-dark.svg'
import { getCookie, setCookie } from '../../utils/Cookies'
import { InlineMessageType, Message, MessageProps } from '../../design-system'

// eslint-disable-next-line import/no-unresolved
import '@okta/okta-signin-widget/css/okta-sign-in.min.css'
import './Login.scss'

export const Login = () => {
  const inlineMessages: MessageProps[] = []
  const cancelledTerms = getCookie('ct')
  const sessionExpired = getCookie('se')

  if (cancelledTerms === '1') {
    inlineMessages.push({
      messageType: InlineMessageType.WarningInline,
      dismissible: false,
      title: 'Logged out due to declining',
      text: 'You were logged out due to failing to agree to our terms & conditions',
      style: { width: 380, marginLeft: 'auto', marginRight: 'auto' },
    })
  }

  if (sessionExpired === '1') {
    inlineMessages.push({
      messageType: InlineMessageType.WarningInline,
      dismissible: false,
      title: 'Your session has expired',
      text: 'You were logged out due to inactivity.',
      style: { width: 380, marginLeft: 'auto', marginRight: 'auto' },
    })
  }

  useEffect(() => {
    const widget = new OktaSignIn(OktaWidgetConfig)
    widget.renderEl(
      { el: '#okta-widget' },
      () => {
        if (OktaWidgetConfig.redirectUri !== undefined) {
          // set expiration of cookie to an hour later
          // "iav" abbreviates "is authentication valid"
          setCookie('iav', '1', new Date(Date.now() + 60 * 60 * 1000))
          window.location.replace(OktaWidgetConfig.redirectUri)
        }
      },
      (err) => {
        throw err
      },
    )
    return () => widget.remove()
  }, [])

  return (
    <main id="login-background" data-testid="login">
      <article id="login-page" className="login">
        <img
          src={deepwatchLogo}
          className="login-logo-image"
          alt="deepwatch logo"
        />
        <div id="okta-widget" />
        {inlineMessages.map((message, index) => (
          <Message key={index} {...message} />
        ))}
      </article>
      <div id="login-footer-links">
        <ul>
          <li>
            <a
              href={'https://legal.deepwatch.com/'}
              target="_blank"
              rel="noreferrer"
            >
              Terms of Service
            </a>
          </li>
          <li>
            <a
              href={'https://www.iubenda.com/privacy-policy/46757738/legal'}
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
          </li>
          <li>
            <a
              href={'https://www.deepwatch.com/trust/'}
              target="_blank"
              rel="noreferrer"
            >
              Trust
            </a>
          </li>
          <li>
            <a
              href={'https://www.deepwatch.com/contact-us/'}
              target="_blank"
              rel="noreferrer"
            >
              Contact
            </a>
          </li>
        </ul>
      </div>
    </main>
  )
}
