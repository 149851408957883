import { ReactDatePickerCustomHeaderProps } from 'react-datepicker'

import { MonthPickerProps } from '../../interfaces'
import DateButton from './DateButton'

import './YearSelection.scss'

const YearSelection: React.FC<
  ReactDatePickerCustomHeaderProps & {
    updateDate(newDate: Date): void
    twoYearsAgo: Date
    lastYear: Date
    currentYear: Date
  } & Pick<MonthPickerProps, 'isYearDisabled'>
> = ({
  changeYear,
  date,
  updateDate,
  twoYearsAgo,
  lastYear,
  currentYear,
  isYearDisabled,
}) => {
  const activeYear = date.getFullYear()
  const twoYearsAgoNumber = twoYearsAgo.getFullYear()
  const lastYearNumber = lastYear.getFullYear()
  const currentYearNumber = currentYear.getFullYear()

  const handleYearChange = (year: number) => {
    changeYear(year)
    const newDate = new Date(date)
    newDate.setFullYear(year)
    updateDate(newDate)
  }

  return (
    <div className="custom-header-container">
      <p className="title">Year</p>
      <div className="custom-header-years-container">
        <DateButton
          label={twoYearsAgoNumber}
          onClick={() => handleYearChange(twoYearsAgoNumber)}
          isActive={activeYear === twoYearsAgoNumber}
          isDisabled={isYearDisabled?.(twoYearsAgo)}
        />
        <DateButton
          label={lastYearNumber}
          onClick={() => handleYearChange(lastYearNumber)}
          isActive={activeYear === lastYearNumber}
          isDisabled={isYearDisabled?.(lastYear)}
        />
        <DateButton
          label={currentYearNumber}
          onClick={() => handleYearChange(currentYearNumber)}
          isActive={activeYear === currentYearNumber}
          isDisabled={isYearDisabled?.(currentYear)}
        />
      </div>
      {/* Put month label here since there's no where else to position it with react date picker */}
      <p className="title">Month</p>
    </div>
  )
}

export default YearSelection
