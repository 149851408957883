import TicketEditCard from '../TicketEditCard'
import TicketAttachmentsContainer from './components/TicketAttachmentsContainer'

const TicketAttachments = () => {
  return (
    <TicketEditCard data-testid="ticket-attachments">
      <TicketAttachmentsContainer />
    </TicketEditCard>
  )
}

export default TicketAttachments
