import { useQuery } from '@apollo/client'
import { Link } from 'react-router-dom'

import {
  Button,
  Loader,
  Typography,
  colors,
} from '../../../../../design-system'
import { getAccessGroups } from '../../../../../utils'
import { UserAvatar } from '../../../../common/UserAvatar'
import {
  GET_USERS,
  GetUsersData,
  GetUsersVariables,
} from '../../../../../graphql/queries/user'

import './MyTeam.scss'

const MyTeam = () => {
  const { loading, data: { getUsers: users } = { getUsers: [] } } = useQuery<
    GetUsersData,
    GetUsersVariables
  >(GET_USERS, {
    variables: {
      filter: {
        oktaStatus: 'ACTIVE',
      },
    },
  })

  const isAdmin =
    getAccessGroups().filter((item) => {
      return (item as string).includes('-portal-admins')
    }).length > 0

  return (
    <>
      <div className="my-team" data-testid="my-team">
        <div className="team-title">
          <Typography
            variant="text10medium"
            size={16}
            color={colors.util.navy[100]}
          >
            My team
          </Typography>
          {isAdmin && (
            <Link to="/settings/user-management">
              <Button variant="text">Manage</Button>
            </Link>
          )}
        </div>

        <div className="my-team-users">
          {loading ? (
            <>
              {[...Array(3)].map((_, index) => (
                <div
                  key={index}
                  className="active-user"
                  style={{ minHeight: '180px' }}
                >
                  <Loader />
                </div>
              ))}
            </>
          ) : (
            <>
              {users.map((user) => (
                <div key={user.id} className="active-user">
                  <UserAvatar
                    fontSize={28}
                    size={80}
                    userInfo={{
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      id: user.id!,
                      firstName: user.firstName,
                      lastName: user.lastName,
                      type: 'dw',
                    }}
                  />

                  <Typography
                    variant="text9semibold"
                    size={18}
                    color={colors.neutral[100]}
                  >
                    {user.firstName} {user.lastName}
                  </Typography>
                  <Typography size={14} color={colors.neutral[200]}>
                    {user.title}
                  </Typography>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default MyTeam
