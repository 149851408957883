import { ComputedDatum, ResponsiveBar, ResponsiveBarSvgProps } from '@nivo/bar'
import { format } from 'date-fns'
import { Box, useTheme } from '@mui/material'

import { SourceType } from '@models/EnvHealth'
import { ChartLegend } from '@common/ChartLegend'
import {
  createSourceTypeCompareFunction,
  formatSourceTypeName,
} from '@components/EnvironmentHealth/Overview/SourceUtilization/SourceUtilization.utils'

import { SupportedChartValue } from './EnvironmentHealthChartDetailsSideSheetContext'
import { UtilizationDetailsChartTooltip } from './UtilizationDetailsChartTooltip'
import { ChartData } from './UtilizationDetailsChartTypes'

import type { OrdinalColorScaleConfig } from '@nivo/colors'

const dateFormat = 'MMMM do' as const

/**
 * TODO: If useful, we could allow this to be customized by specifying a more generic function for extracting the datum value
 * e.g.
 * ```tsx
 * valueExtractor: (sourceType: SourceType) => number)
 * ```
 * or
 * ```tsx
 * valueKey: keyof SourceType
 * ```
 * (currently, we're assuming that the value is `gb`)
 *
 * Alternatively, we could make it even more generic, remove the assumption that the data is a SourceType, and allow the developer to specify the data and keys
 */
interface UtilizationDetailsChart {
  sourceTypes: SourceType[]
  chartHeight?: number
  chartValue?: SupportedChartValue
}

/**
 * @description This chart is rendered as a single grouped bar segmented by sourcetype, rather than rendering an inBoxidual datum for each sourcetype
 * @param {UtilizationDetailsChart} props The component props
 * @param {SupportedChartValue} props.chartValue Determine the y-axis value of the chart
 * @param {SourceType[]} props.sourceTypes The source types to display in the chart
 * @param {number} [props.chartHeight] The height of the chart, in pixels
 * @returns {import('react').ReactNode} A grouped bar chart showing the utilization of each sourcetype
 */
export const UtilizationDetailsChart: React.FC<UtilizationDetailsChart> = ({
  sourceTypes: unformattedSourceTypes,
  chartHeight = 350,
  chartValue = 'gb',
}) => {
  const theme = useTheme()

  if (unformattedSourceTypes.length === 0) {
    return null
  }

  const nivoTheme: Readonly<ResponsiveBarSvgProps<ChartData>['theme']> = {
    axis: {
      ticks: {
        line: {
          strokeWidth: 0,
        },
        text: {
          fontSize: 12,
          fill: theme.vars.palette.text.primary,
        },
      },
    },
    tooltip: {
      container: {
        color: theme.vars.palette.secondary.dark,
      },
    },
  }

  const barColors: string[] = [
    '#00CC66',
    theme.vars.palette.important.main,
    theme.vars.palette.primary.light,
    theme.vars.palette.text.secondary,
    theme.vars.palette.severity.medium!,
    theme.vars.palette.warning.main,
    theme.vars.palette.important.dark,
    theme.vars.palette.primary.main,
    theme.vars.palette.success.light,
  ]

  const sourceTypes = unformattedSourceTypes
    .slice()
    .sort(createSourceTypeCompareFunction(chartValue, 'desc'))
    .map(formatSourceTypeName)

  const sourceTypeUtilization = sourceTypes.reduce<ChartData>(
    (acc, curr) => {
      acc[curr.name as keyof ChartData] =
        curr[chartValue as SupportedChartValue]
      return acc
    },
    { date: format(sourceTypes[0].date, dateFormat) } as ChartData,
  )

  const data = [sourceTypeUtilization]

  const keys = sourceTypes.map((sourceType) => sourceType.name)

  const legendBreakdown = sourceTypes.map((sourceType, index) => ({
    label: sourceType.name,
    color: barColors[index as number],
  }))

  const maxAlerts = sourceTypes.reduce((acc, curr) => {
    return Math.max(acc, curr.alerts)
  }, 0)

  /**
   * @description Get the color for the bar, using the index of the sourcetype in the sorted `sourceTypes` array
   * to index into the `barColors` array
   * @param {ComputedDatum<ChartData>} bar The bar datum
   * @returns {string} The color for the bar
   */
  const getColor: OrdinalColorScaleConfig<ComputedDatum<ChartData>> = (bar) => {
    const indexOfSourceType = sourceTypes.findIndex(
      (sourceType) => sourceType.name === bar.id,
    )
    return barColors[indexOfSourceType as number]
  }

  return (
    <Box
      data-testid="utilization-details-chart"
      sx={{ flexDirection: 'column', width: '100%' }}
    >
      <ChartLegend legendItems={legendBreakdown} />
      <Box
        sx={(theme) => ({
          height: chartHeight,
          backgroundColor: theme.palette.secondary.light,
          border: `1px solid ${theme.palette.secondary.light}`,
          borderRadius: '5px',
        })}
      >
        <ResponsiveBar
          layout="vertical"
          indexBy="date"
          groupMode="grouped"
          enableGridY={false}
          enableLabel={false}
          innerPadding={20}
          margin={{
            bottom: 50,
            left: 36,
            top: 8,
          }}
          axisLeft={{
            tickPadding: 10,
            tickValues: chartValue === 'alerts' ? Math.min(5, maxAlerts) : 5,
          }}
          axisBottom={{
            tickPadding: 30,
          }}
          colors={getColor}
          theme={nivoTheme}
          data={data}
          keys={keys}
          tooltip={UtilizationDetailsChartTooltip}
        />
      </Box>
    </Box>
  )
}
