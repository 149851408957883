/* eslint-disable security/detect-object-injection */
import { useQuery } from '@apollo/client'
import { useSearchParams } from 'react-router-dom'
import { useCallback, useRef, useState } from 'react'
import { ColumnFiltersState, SortingState } from '@tanstack/react-table'
import cloneDeep from 'lodash/cloneDeep'
import { Box } from '@mui/material'

import { TableToolbar } from '@common/TableToolbar'
import { FilterOptions } from '@models/Filters'
import { FilterChipsHeader, FilterValue } from '@common/FilterChips'
import {
  DETECTIONS,
  DetectionsData,
  DetectionsVariables,
} from '@queries/detection'
import { useSelectedRow } from '@hooks/index'
import { usePageSize } from '@hooks/usePageSize'

import DetectionCatalogTable, {
  DETECTION_CATALOG_TABLE_ROW_ID_PREFIX,
} from './DetectionCatalogTable/DetectionCatalogTable'
import { DetectionCatalogControls } from './DetectionCatalogControls'
import { DetectionCatalogFilters } from './DetectionCatalogFilters'
import DetectionCatalogSideSheet from './DetectionCatalogSideSheet/DetectionCatalogSideSheet'
import { DetectionCatalogFilterOptions } from './DetectionCatalogFilters/DetectionCatalogFiltersValues'
import { emptyDetection } from './DetectionCatalog.utils'

export interface DetectionCatalogGlobalFilters {
  keywordSearch: string
  showNotApplicableDetections: boolean
}

interface DetectionCatalogProps {
  selectedFilters: ColumnFiltersState
}

const DetectionCatalog: React.FC<DetectionCatalogProps> = ({
  selectedFilters,
}) => {
  const filterButtonRef = useRef<HTMLButtonElement>(null)

  const DEFAULT_COLUMN_FILTERS = [
    {
      id: 'status',
      value: [],
    },
    {
      id: 'mitreTactics',
      value: [],
    },
  ]

  const DEFAULT_GLOBAL_FILTERS = {
    showNotApplicableDetections: false,
    keywordSearch: '',
  }

  const [searchParams] = useSearchParams()
  const selectedCustomer = searchParams.get('customer')

  const [columnFilters, setColumnFilters] =
    useState<ColumnFiltersState>(selectedFilters)
  const [globalFilters, setGlobalFilters] =
    useState<DetectionCatalogGlobalFilters>(DEFAULT_GLOBAL_FILTERS)

  const [sorting, setSorting] = useState<SortingState>([
    {
      id: 'releaseVersion',
      desc: true,
    },
  ])
  const [toggleFilterSideSheet, setToggleFilterSideSheet] =
    useState<boolean>(false)

  const tableRef = useRef<HTMLDivElement>(null)

  const buildFilters = () => {
    const filters = cloneDeep(columnFilters)
    const matchIndex = filters.findIndex((val) => val.id === 'status')

    if ((filters[matchIndex].value as string[]).length === 0) {
      if (globalFilters.showNotApplicableDetections) {
        filters[matchIndex].value = [
          'Available',
          'Enabled',
          'Unavailable',
          'Not Applicable',
          'Pending Review',
          'Trial Mode',
          'Eligible',
          'Blocked',
        ]
      } else {
        filters[matchIndex].value = [
          'Available',
          'Enabled',
          'Pending Review',
          'Trial Mode',
          'Blocked',
          'Eligible',
        ]
      }
    }

    return filters
  }

  const pageSize = usePageSize()

  const {
    data: { detections: { detections, pagination }, mitreTactics } = {
      detections: {
        detections: [],
        pagination: { offset: 0, limit: pageSize, total: 0 },
      },
      mitreTactics: [],
    },
    previousData = { mitreTactics: [] },
    fetchMore,
    loading,
    error,
  } = useQuery<DetectionsData, DetectionsVariables>(DETECTIONS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      filters: buildFilters(),
      keywordSearch: globalFilters.keywordSearch,
      pagination: {
        limit: pageSize,
        offset: 0,
      },
      selectedCustomer,
      sorting: sorting[0],
    },
  })

  const {
    rowSelection,
    setRowSelection,
    selectedRow: focusedDetection,
    previouslySelectedRow: previouslyFocusedDetection,
  } = useSelectedRow({ data: detections, rowIdKey: 'useCase' })

  const handleApplyFilter = ({
    filters,
    keywordSearch,
  }: FilterOptions<DetectionCatalogFilterOptions>) => {
    setColumnFilters((old) => {
      const dup = cloneDeep(old)

      Object.entries(filters).forEach(([key, value]) => {
        const index = dup.findIndex((item) => item.id === key)

        dup[index] = {
          id: key,
          value,
        }
      })

      return dup
    })

    setGlobalFilters((old) => ({
      keywordSearch,
      showNotApplicableDetections: old.showNotApplicableDetections,
    }))

    setToggleFilterSideSheet(false)
    handleScrollToTop()
  }

  const handleClearAll = () => {
    setColumnFilters(DEFAULT_COLUMN_FILTERS)
    setGlobalFilters(DEFAULT_GLOBAL_FILTERS)

    setToggleFilterSideSheet(false)
    handleScrollToTop()
  }

  const handleDeleteChip = (filterGroup: string, filterValue: FilterValue) => {
    if (filterGroup === 'mitreTactics' || filterGroup === 'status') {
      setColumnFilters((old) => {
        const dup = cloneDeep(old)
        const matchIndex = dup.findIndex((val) => val.id === filterGroup)
        const values = dup[matchIndex].value as string[]

        values.splice(
          values.findIndex((val) => val === filterValue),
          1,
        )

        dup[matchIndex].value = values

        return dup
      })
    }

    if (filterGroup === 'keywordSearch') {
      setGlobalFilters((old) => ({
        keywordSearch: '',
        showNotApplicableDetections: old.showNotApplicableDetections,
      }))
    }
  }

  const handleIncludeToggle = () => {
    setGlobalFilters((old) => ({
      keywordSearch: old.keywordSearch,
      showNotApplicableDetections: !old.showNotApplicableDetections,
    }))

    handleScrollToTop()
  }

  const handleOnBottomReached = useCallback(() => {
    if (detections.length < pagination.total) {
      fetchMore({
        variables: {
          pagination: {
            limit: pagination.limit,
            offset: detections.length,
          },
        },
      })
    }
  }, [fetchMore, detections.length, pagination.limit, pagination.total])

  const handleScrollToTop = useCallback(() => {
    tableRef.current &&
      tableRef.current.scrollTo({
        behavior: 'smooth',
        top: 0,
      })
  }, [])

  const handleSortingChange = useCallback(
    (newSortingState: React.SetStateAction<SortingState>) => {
      setSorting(newSortingState)
      handleScrollToTop()
    },
    [setSorting, handleScrollToTop],
  )

  const anyFilterHasBeenApplied =
    globalFilters.keywordSearch !== '' ||
    columnFilters.some((filter) => (filter.value as string[]).length)

  const formattedSelectedFilters =
    columnFilters.reduce<DetectionCatalogFilterOptions>(
      (acc, cur) => ({ ...acc, [cur.id]: cur.value }),
      { mitreTactics: [], status: [] },
    )

  /** When closing the sidesheet, re-focus the row which was selected to improve a11y ux */
  const closeSidesheetAndFocusPreviousDetection = useCallback(() => {
    setRowSelection({})
    if (previouslyFocusedDetection?.useCase) {
      document
        .getElementById(
          `${DETECTION_CATALOG_TABLE_ROW_ID_PREFIX}${previouslyFocusedDetection.useCase}`,
        )
        ?.focus()
    }
  }, [setRowSelection, previouslyFocusedDetection])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: '1.5rem 1rem',
        width: '100%',
        height: 'calc(100vh - 104px)',
        overflowY: 'hidden',
      }}
      id="detection-catalog-content"
      data-testid="detection-catalog-content"
    >
      {anyFilterHasBeenApplied && (
        <FilterChipsHeader
          deleteChip={handleDeleteChip}
          selectedFilters={{
            filters: formattedSelectedFilters,
            keywordSearch: globalFilters.keywordSearch,
          }}
          hasActiveFilters={anyFilterHasBeenApplied}
        />
      )}

      <TableToolbar
        loading={loading && !detections.length}
        dataLength={pagination.total}
        singularEntityName={'detection'}
        pluralEntityName={'detections'}
      >
        <DetectionCatalogControls
          toggleState={globalFilters.showNotApplicableDetections}
          handleOpenFilterSideSheetClick={() => setToggleFilterSideSheet(true)}
          handleIncludeToggle={handleIncludeToggle}
          loading={loading && !detections.length}
          filterButtonRef={filterButtonRef}
        />
      </TableToolbar>

      <DetectionCatalogFilters
        isOpen={toggleFilterSideSheet}
        setIsOpen={(toggle) => {
          if (!toggle) {
            filterButtonRef.current?.focus()
          }
          setToggleFilterSideSheet(toggle)
        }}
        filterOptionsResponse={
          loading ? previousData.mitreTactics : mitreTactics
        }
        selectedFilters={{
          filters: formattedSelectedFilters,
          keywordSearch: globalFilters.keywordSearch,
        }}
        clearAll={handleClearAll}
        applyFilter={handleApplyFilter}
      />

      <DetectionCatalogSideSheet
        isOpen={!!focusedDetection}
        closeSideSheet={closeSidesheetAndFocusPreviousDetection}
        focusedDetection={
          focusedDetection ?? previouslyFocusedDetection ?? emptyDetection
        }
      />

      <DetectionCatalogTable
        filtersApplied={anyFilterHasBeenApplied}
        detections={detections}
        handleScrollToTop={handleScrollToTop}
        loading={loading && !detections.length}
        onBottomReached={handleOnBottomReached}
        onColumnFiltersChange={setColumnFilters}
        onRowSelectionChange={setRowSelection}
        onSortingChange={handleSortingChange}
        pagination={pagination}
        ref={tableRef}
        rowSelection={rowSelection}
        sorting={sorting}
        error={error}
      />
    </Box>
  )
}

export default DetectionCatalog
