import {
  Icon,
  IconVariant,
  Typography,
  colors,
} from '../../../../../../design-system'

import './CarouselCard.scss'

export interface CarouselCardProps {
  iconVariant: IconVariant
  added: boolean
  title: string
  info: string
  id: string | number
  isActive: boolean
}

const CarouselCard: React.FC<CarouselCardProps> = ({
  iconVariant = 'skull',
  added,
  title,
  info,
  id = 0,
  isActive = false,
}) => {
  return (
    <div
      id={`card-${id}`}
      className={`carousel-card${isActive ? ' active' : ''}`}
    >
      <div className="carousel-card-top flex">
        <Icon
          variant={iconVariant && iconVariant}
          size={18}
          color={added ? colors.util.one.lighter : colors.util.two.light}
        />
        <Typography
          color={isActive ? colors.util.navy[50] : ''}
          variant="text11medium"
        >
          {title}
        </Typography>
      </div>
      <div className="carousel-card-bottom">
        <Typography variant="text12medium">{info}</Typography>
      </div>
    </div>
  )
}

export default CarouselCard
