import { useQuery } from '@apollo/client'
import { useSearchParams } from 'react-router-dom'

import { Carousel } from '../../../Carousel'
import { eventMockData2 } from '../../../../../../../../mockData/SecurityIndexFactory'
import {
  GET_SECURITY_INDEX_CHANGE_HISTORY,
  GetSecurityIndexChangeHistory,
} from '../../../../../../../graphql/queries/securityIndex'
import ComponentError from '../../../../../../common/ComponentError/ComponentError'
import { GetCustomerInfoVariables } from '../../../../../../../graphql/queries/customer'

import './IndexTrendEvents.scss'

interface IndexTrendEvents {
  selectedTrendEventState: [
    Date | undefined,
    React.Dispatch<React.SetStateAction<Date | undefined>>,
  ]
}

const IndexTrendEvents: React.FC<IndexTrendEvents> = ({
  selectedTrendEventState,
}) => {
  const [searchParams] = useSearchParams()

  const selectedCustomer = searchParams.get('customer') || ''

  const {
    data: { getSecurityIndexChangeHistory: events } = {
      getSecurityIndexChangeHistory: eventMockData2,
    },
    loading: eventLoading,
    error: eventError,
  } = useQuery<GetSecurityIndexChangeHistory, GetCustomerInfoVariables>(
    GET_SECURITY_INDEX_CHANGE_HISTORY,
    { variables: { selectedCustomer } },
  )

  if (eventError) {
    return (
      <div style={{ textAlign: 'center', flex: 1 }}>
        <ComponentError />
      </div>
    )
  }

  return (
    <div
      data-testid="security-index-trend-events"
      className="flex"
      id="security-index-trend-events"
    >
      <Carousel
        loading={eventLoading}
        events={events}
        selectedTrendEventState={selectedTrendEventState}
      />
    </div>
  )
}
export default IndexTrendEvents
