import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { TicketLibrary } from './TicketLibrary'
import { Tabs } from '../../design-system'
import { TicketEdit } from './TicketEdit'
import { TicketsContextProvider } from './context/TicketsContext'

interface TicketsProps {
  component?: 'library' | 'edit'
}

import './Tickets.scss'

const TicketTabs = [
  { active: true, id: 0, text: 'Ticket Library', notification: false },
]

const components = {
  library: TicketLibrary,
  edit: TicketEdit,
}

const Tickets = ({ component = 'library' }: TicketsProps) => {
  const [selectedTab, setSelectedTab] = useState(0)
  const [searchParams] = useSearchParams()

  const selectedCustomer = searchParams.get('customer') || ''

  // eslint-disable-next-line security/detect-object-injection
  const Component = components[component]

  return (
    <TicketsContextProvider selectedCustomer={selectedCustomer}>
      <article id="tickets-page" data-testid="tickets">
        <Tabs
          data={TicketTabs}
          activeTab={selectedTab}
          onTabClick={(id) => {
            setSelectedTab(id)
          }}
        />
        {[<Component key={selectedTab} />].at(selectedTab)}
      </article>
    </TicketsContextProvider>
  )
}

export default Tickets
