import { Icon, Typography } from '../../../../design-system'

interface MitreTacticProps {
  key?: number | string
  name: string
  color: string
  active: boolean
  value: number
}

const MitreTactic = ({ name, color, active, value }: MitreTacticProps) => {
  return (
    <div className={`tactic${active === true ? ' active' : ''}`}>
      <div
        className="flex-container gap-4 align-items-center"
        tabIndex={0}
        role={'contentinfo'}
        aria-label={`${name} has ${value} alerts`}
      >
        <Icon
          style={{ paddingRight: '4px' }}
          size={8}
          variant="ellipse"
          color={color}
        />
        <Typography component="span" size={12}>
          {name}
        </Typography>
      </div>
    </div>
  )
}

export default MitreTactic
