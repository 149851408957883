import { useContext } from 'react'

import { SecurityIndexContext } from '../components/Home/SecurityIndex/context/SecurityIndexContext'

export const useSecurityIndexContext = () => {
  const securityIndexContext = useContext(SecurityIndexContext)

  if (!securityIndexContext) {
    throw new Error(
      'SecurityIndexContext must be used within SecurityIndexContextProvider',
    )
  }

  return securityIndexContext
}
