import { Tokens } from '@okta/okta-auth-js'

export const getTokens = () => {
  return JSON.parse(
    localStorage.getItem('okta-token-storage') ?? '{}',
  ) as Tokens
}

export const getAccessToken = () => {
  return getTokens()?.accessToken?.accessToken
}

export const getIdToken = () => {
  return getTokens().idToken?.idToken
}

export const getAccessGroups = (): string[] => {
  const tempTokenStorage = getTokens()
  if (tempTokenStorage.idToken) {
    if (
      tempTokenStorage.idToken &&
      tempTokenStorage.idToken.claims &&
      tempTokenStorage.idToken.claims.groups
    ) {
      return tempTokenStorage.idToken.claims.groups as string[]
    } else {
      return []
    }
  } else {
    return []
  }
}
