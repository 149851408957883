import { Formik, FormikErrors } from 'formik'
import { format, startOfYear } from 'date-fns/fp'
import { useMutation } from '@apollo/client'
import { useContext } from 'react'

import {
  Button,
  InlineMessageType,
  Input,
  Popover,
  ToastType,
  Typography,
} from '../../../design-system'
import { Context as AppContext } from '../../App'
import { Message } from '../../../models'
import { deployToastMessage } from '../../../utils'
import {
  isBeforeStartDate,
  initialStartDate,
  initialEndDate,
  isAfterEndDate,
} from './GenerateReportModalHelpers'
import { colors } from '../../../design-system/theme'
import { ModalContentProps } from '../../common/Modal/Modal'
import {
  GENERATE_REPORT,
  GenerateReportData,
  GenerateReportVariables,
} from '../../../graphql/mutations/report'
import { GET_REPORTS } from '../../../graphql/queries/report'

import './GenerateReportModal.scss'

const GenerateReportModal: React.FC<ModalContentProps> = ({ closeModal }) => {
  const {
    dispatch,
    state: {
      dwExpertsCustomer: { customerShortName },
    },
  } = useContext(AppContext)

  const [generateReport] = useMutation<
    GenerateReportData,
    GenerateReportVariables
  >(GENERATE_REPORT, {
    onError: () => {
      const error: Message = {
        id: crypto.randomUUID(),
        text: 'Your report has failed to generate, please try again in a minute.',
        messageType: InlineMessageType.ErrorInline,
        secondsToExpire: 3000,
        dismissible: false,
      }
      deployToastMessage(error, dispatch)
    },
    refetchQueries: [GET_REPORTS],
  })

  const toggleModal = () => {
    closeModal()
  }

  const handleSubmit = ({
    reportName,
    startDate,
    endDate,
  }: {
    reportName: string
    startDate: Date
    endDate: Date
  }) => {
    const generating: Message = {
      id: crypto.randomUUID(),
      text: `Generating report...`,
      messageType: ToastType.Default,
      secondsToExpire: 5000,
      dismissible: false,
    }

    generateReport({
      variables: {
        input: {
          reportId: 0,
          reportName: reportName,
          startDate: format('yyyy-MM-dd', startDate),
          endDate: format('yyyy-MM-dd', endDate),
        },
        selectedCustomer: customerShortName,
      },
    })

    toggleModal()
    deployToastMessage(generating, dispatch)
  }

  const now = new Date()

  return (
    <div id="generate-report-modal">
      <Formik
        initialValues={{
          reportName: '',
          startDate: initialStartDate,
          endDate: initialEndDate,
        }}
        onSubmit={handleSubmit}
        validate={({ reportName, startDate, endDate }) => {
          const errors: FormikErrors<{
            reportName: string
            startDate: string
            endDate: string
          }> = {}

          // validate report name
          if (!reportName) {
            errors.reportName = 'The report name is required'
          } else if (reportName.length < 3 || reportName.length > 256) {
            errors.reportName =
              'The report name must be between 3 and 256 characters.'
          }

          // validate start date and end date
          if (startDate > endDate) {
            errors.startDate = 'Start date must come before end date.'
          }

          return errors
        }}
      >
        {({
          handleBlur,
          handleChange,
          setFieldValue,
          submitForm,
          errors,
          values,
          dirty,
          isValid,
        }) => (
          <>
            <Input
              labelId="report-name"
              disabled={false}
              error={errors.reportName !== undefined}
              errorText={errors.reportName}
              label={'Report Name'}
              placeholder={'Enter a report name'}
              required={true}
              value={values.reportName}
              name="reportName"
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              hintText="The report name must be between 3 and 256 characters."
              /* eslint-disable jsx-a11y/no-autofocus */
              autoFocus={true}
            />

            <div className="date-range-selection-container">
              <Popover
                variant="month"
                label="From"
                date={values.startDate}
                inputName="startDate"
                setDate={(date) => setFieldValue('startDate', date)}
                isMonthDisabled={(date: Date) =>
                  date > now || isAfterEndDate(values.endDate, date)
                }
              />
              <Popover
                key={`${format('yyyy-MM-dd', values.startDate)}`}
                variant="month"
                label="To"
                date={values.endDate}
                inputName="endDate"
                setDate={(date) => setFieldValue('endDate', date)}
                isMonthDisabled={(date) =>
                  date > now || isBeforeStartDate(values.startDate, date)
                }
                isYearDisabled={(date) =>
                  isBeforeStartDate(startOfYear(values.startDate), date)
                }
                setDateTo="endOfMonth"
              />
            </div>

            <div className="date-picker-error">
              {errors.startDate !== undefined && (
                <Typography variant="text12" color={colors.util.two.light}>
                  <>{errors.startDate}</>
                </Typography>
              )}
            </div>

            <div className="date-picker-error">
              {errors.endDate !== undefined && (
                <Typography variant="text12" color={colors.util.two.light}>
                  <>{errors.endDate}</>
                </Typography>
              )}
            </div>

            <div className="date-picker-error">
              {errors.endDate !== undefined && (
                <Typography variant="text12" color={colors.util.two.light}>
                  {errors.endDate as string}
                </Typography>
              )}
            </div>

            <div
              className="generate-report-button"
              data-testid="generate-report-modal-button"
            >
              <Button onClick={toggleModal} variant="secondary">
                Cancel
              </Button>
              <Button
                type="submit"
                onClick={() => submitForm()}
                disabled={!isValid || !dirty}
              >
                Generate Report
              </Button>
            </div>
          </>
        )}
      </Formik>
    </div>
  )
}

export default GenerateReportModal
