import React from 'react'

import { Typography, colors, useTheme } from '../../'
import { CheckboxProps, CSSProps } from '../../interfaces'

const hideCheckboxStyles = (checked: boolean) => ({
  padding: '.5em',
  borderRadius: '5px',
  height: 'auto',
  backgroundColor: checked ? colors.util.navy[100] : 'transparent',
  border: `1px solid ${colors.util.navy[100]}`,
})

const Checkbox = ({
  checked = false,
  label,
  focus = false,
  disabled = false,
  styles,
  textColor,
  name,
  hideCheckbox = false,
  onFocus,
  onBlur,
  onChange,
  onKeyUp,
  onMouseLeave,
  onMouseEnter,
}: CheckboxProps): JSX.Element => {
  const isDarkTheme = useTheme('dark')

  const handleClick = () => {
    if (disabled) {
      return
    }

    onChange(!checked)
  }

  const handleMouseEnter = () => {
    if (onMouseEnter) {
      onMouseEnter?.()
    }
  }

  const handleMouseLeave = () => {
    if (onMouseLeave) {
      onMouseLeave?.()
    }
  }

  const handleFocus = () => {
    if (onFocus) {
      onFocus?.()
    }
  }

  const handleBlur = () => {
    if (onBlur) {
      onBlur?.()
    }
  }

  const handleKeyUp = (event: React.KeyboardEvent<HTMLLabelElement>) => {
    if (onKeyUp) {
      if (event.code === 'Enter' || event.code === 'Space') {
        onChange(!checked)
        onKeyUp?.()
      }
    }
  }

  const darkModeText = (color: string) => {
    if (color) {
      return color
    }

    if (isDarkTheme) {
      return disabled ? colors.util.navy[100] : colors.util.navy[50]
    }

    return disabled ? colors.neutral[300] : colors.util.navy[600]
  }

  const checkboxContainerStyles = (
    check: boolean,
    focus: boolean,
    disable: boolean,
    styleOverride?: CSSProps,
  ): CSSProps => {
    const darkModeBackground = () => {
      if (disable) {
        return ''
      }

      if (isDarkTheme) {
        return check ? colors.util.navy[300] : ''
      }

      return check ? colors.util.navy[50] : '#fff'
    }

    const darkModeBorder = () => {
      if (disable) {
        return 'transparent'
      }

      if (isDarkTheme) {
        return focus ? '' + colors.util.navy[50] : 'transparent'
      }

      return focus ? colors.util.navy[600] : '#fff'
    }

    return {
      position: 'relative',
      cursor: `${disable ? 'unset' : 'pointer'}`,
      display: 'inline-block',
      verticalAlign: 'middle',
      margin: '4px',
      border: `1px solid ${darkModeBorder()}`,
      outlineColor: `${darkModeBorder()}`,
      backgroundColor: `${darkModeBackground()}`,
      padding: '12px',
      borderRadius: '5px',
      userSelect: 'none',
      ...(!isDarkTheme ? { outlineColor: colors.util.navy[600] } : {}),
      ...(hideCheckbox ? hideCheckboxStyles(check) : {}),
      ...styleOverride,
    }
  }

  const checkboxIconStyles = (
    check: boolean,
    focus: boolean,
    disable: boolean,
  ): CSSProps => {
    const disabledAndChecked = disable && check
    const disabledAndNotChecked = disable && !check

    const darkModeIcon = () => {
      if (isDarkTheme) {
        if (disabledAndChecked) {
          return colors.util.navy[200]
        } else if (disabledAndNotChecked) {
          return colors.util.navy[600]
        } else if (check) {
          return colors.util.navy[50]
        }

        return colors.util.navy[600]
      }

      if (disabledAndChecked) {
        return colors.neutral[100]
      } else if (disabledAndNotChecked) {
        return '#fff'
      } else if (check) {
        return colors.util.navy[600]
      }

      return '#fff'
    }

    const darkModeIconOutline = () => {
      if (isDarkTheme) {
        if (disabledAndChecked) {
          return 'transparent'
        } else if (disabledAndNotChecked) {
          return colors.util.navy[200]
        } else if (check) {
          return colors.util.navy[50]
        }

        return colors.util.navy[100]
      }

      if (disable) {
        return colors.neutral[100]
      } else if (focus || check) {
        return colors.util.navy[600]
      }

      return colors.neutral[300]
    }

    return {
      width: '20px',
      height: '20px',
      border: '2px solid ' + darkModeIconOutline(),
      background: darkModeIcon(),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: '12px',
      borderRadius: '10%',
    }
  }

  const checkboxIconContainerStyles: CSSProps = {
    display: 'inline-block',
    verticalAlign: 'middle',
  }

  const checkboxStyles: CSSProps = {
    visibility: 'hidden',
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
  }

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <label
      htmlFor={label}
      style={checkboxContainerStyles(checked, focus, disabled, styles)}
      onMouseEnter={handleMouseEnter}
      onKeyUp={(e: React.KeyboardEvent<HTMLLabelElement>) => handleKeyUp(e)}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
      onFocus={handleFocus}
      onBlur={handleBlur}
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex={0}
    >
      <input
        type="checkbox"
        name={name}
        disabled={disabled}
        style={checkboxStyles}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
        checked={checked}
        onChange={handleClick}
        data-testid="checkbox"
      />
      {!hideCheckbox && (
        <div style={checkboxIconContainerStyles} data-testid="checkbox-icon">
          <div style={checkboxIconStyles(checked, focus, disabled)}>
            <svg
              width="17"
              height="13"
              viewBox="0 0 17 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.49996 13L0.666626 7.16672L2.31162 5.52172L6.49996 9.69838L15.355 0.843384L17 2.50005L6.49996 13Z"
                fill={isDarkTheme ? colors.util.navy[600] : '#fff'}
              />
            </svg>
          </div>
        </div>
      )}
      <Typography
        component={'span'}
        variant="text10"
        color={darkModeText(textColor ?? '')}
      >
        {label}
      </Typography>
    </label>
  )
}

export default Checkbox
