import ReactMarkdown from 'react-markdown'

import {
  MitreCoverageCustomer,
  MitreCoverageSubTechnique,
  MitreCoverageTechnique,
} from '../../../../models/DetectionCoverage'
import { Typography, colors } from '../../../../design-system'

interface SubTechniqueDetailProps {
  customerTacticTechniques: MitreCoverageCustomer[]
  subTechnique: MitreCoverageSubTechnique
  tacticName: string
  technique: MitreCoverageTechnique
}

const SubTechniqueDetail = ({
  customerTacticTechniques,
  subTechnique,
  tacticName,
  technique,
}: SubTechniqueDetailProps) => {
  const customerTechnique = customerTacticTechniques.find(
    (x) => x.mitreTechniqueId === technique.mitreTechniqueId,
  )

  const customerSubTechnique = customerTechnique?.subtechniques.find(
    (x) => x.mitreSubtechniqueId === subTechnique.mitreSubtechniqueId,
  )

  const subTechniqueDetails = {
    title: subTechnique.mitreSubtechniqueName,
    description: subTechnique.mitreSubtechniqueDescription,
    totalEnabledUseCount: customerSubTechnique
      ? customerSubTechnique.totalEnabledUseCaseCount
      : 0,
    totalUseCaseCount: subTechnique.totalUseCaseCount,
    id: subTechnique.mitreSubtechniqueId,
  }

  return (
    <>
      <div className="mitre-title-description">
        <Typography
          component="div"
          size={16}
          weight={600}
          color={colors.util.navy[50]}
        >
          {subTechniqueDetails.title}
        </Typography>
        <Typography component="div" size={14}>
          <ReactMarkdown linkTarget="_blank">
            {subTechniqueDetails.description}
          </ReactMarkdown>
        </Typography>
      </div>
      <div className="mitre-details">
        <div data-testid="subtechnique-enabled-detections">
          <Typography component="div" size={14} color={colors.util.navy[100]}>
            Enabled detections
          </Typography>
          <Typography component="div" size={14} color={colors.util.navy[50]}>
            {subTechniqueDetails.totalEnabledUseCount}
          </Typography>
        </div>
        <div data-testid="subtechnique-available-detections">
          <Typography component="div" size={14} color={colors.util.navy[100]}>
            Available detections
          </Typography>
          <Typography component="div" size={14} color={colors.util.navy[50]}>
            {subTechniqueDetails.totalUseCaseCount}
          </Typography>
        </div>
        <div data-testid="subtechnique-tactic">
          <Typography component="div" size={14} color={colors.util.navy[100]}>
            Tactic
          </Typography>
          <Typography component="div" size={14} color={colors.util.navy[50]}>
            {tacticName}
          </Typography>
        </div>
        <div data-testid="subtechnique-id">
          <Typography component="div" size={14} color={colors.util.navy[100]}>
            ID
          </Typography>
          <Typography component="div" size={14} color={colors.util.navy[50]}>
            {subTechniqueDetails.id}
          </Typography>
        </div>

        {technique.platformList?.length > 0 && (
          <div data-testid="subtechnique-platforms">
            <Typography component="div" size={14} color={colors.util.navy[100]}>
              Platforms
            </Typography>
            <ul
              style={{
                margin: 0,
                paddingLeft: 25,
                color: colors.util.navy[50],
              }}
            >
              {technique.platformList.map((platform) => (
                <li key={platform}>
                  <Typography
                    component="span"
                    size={14}
                    color={colors.util.navy[50]}
                  >
                    {platform}
                  </Typography>
                </li>
              ))}
            </ul>
          </div>
        )}

        {technique.permissionList?.length > 0 && (
          <div data-testid="subtechnique-permissions">
            <Typography component="div" size={14} color={colors.util.navy[100]}>
              Required permissions
            </Typography>
            <ul
              style={{
                margin: 0,
                paddingLeft: 25,
                color: colors.util.navy[50],
              }}
            >
              {technique.permissionList.map((permission) => (
                <li key={permission}>
                  <Typography
                    component="span"
                    size={14}
                    color={colors.util.navy[50]}
                  >
                    {permission}
                  </Typography>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </>
  )
}

export default SubTechniqueDetail
