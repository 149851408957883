import { useContext } from 'react'
import { Navigate, useLocation, useSearchParams } from 'react-router-dom'

import { SessionStatus } from '../../config/OktaAuthConfig'
import { Context } from '../App'
import { Paths, Terms } from '../App/Types'

const Protected = ({ children }: { children: JSX.Element }) => {
  const {
    state: {
      termsAccepted,
      oktaSession,
      user: { isDWEmployee },
    },
  } = useContext(Context)
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const customer = searchParams.get('customer')
  const { pathname } = useLocation()

  if (oktaSession === SessionStatus.INACTIVE) {
    return <Navigate to={Paths.LOGIN} state={{ from: location }} replace />
  }

  if (termsAccepted === Terms.DECLINED) {
    return <Navigate to={Paths.TERMS} state={{ from: location }} replace />
  }

  // Internal users *must* have selected a customer in order to navigate away from DW Experts home.
  if (isDWEmployee && pathname !== Paths.DEEPWATCH_EXPERTS && !customer) {
    return (
      <Navigate
        to={Paths.DEEPWATCH_EXPERTS}
        state={{ from: location }}
        replace
      />
    )
  }

  return children
}

export default Protected
