import { useContext } from 'react'

import { Tabs, TabProps } from '../../../../design-system'
import { Context } from '../../../App'
import { MttxForm } from './MTTX/MttxForm'
import TicketEditCard from './TicketEditCard'
import { Ticket } from '../../../../models'
import RelatedCases from './RelatedCases'
import { TicketFactory } from '../../../../../mockData/TicketFactory'
import TicketDataHandler from './TicketDataHandler'
import { AdditionalDetailsForm } from './AdditionalDetails/AdditionalDetailsForm'
import {
  GET_TICKET_ADDITIONAL_DETAILS,
  GET_TICKET_MTTX,
  TicketAdditionalDetailsResponse,
  TicketMttxResponse,
} from '../../../../graphql/queries/ticket'

export type TicketAdditionalDetailsAttributes = Pick<
  Ticket,
  | 'ticketNumber'
  | 'description'
  | 'priority'
  | 'state'
  | 'type'
  | 'sysCreatedOn'
  | 'sysUpdatedOn'
>

// This placeholder data should be replaced with real, related tickets once available
const ticketRelatedCasesPlaceholderData = [
  TicketFactory.build(),
  TicketFactory.build(),
  TicketFactory.build(),
]

const TicketAdditionalDetails: React.FC = () => {
  const {
    state: {
      user: { isDWEmployee },
    },
  } = useContext(Context)
  const tabs: TabProps[] = [
    { active: true, id: 0, text: 'Additional details', notification: false },
    { active: false, id: 2, text: 'MTTx', notification: false },
  ]
  const tabsContent: JSX.Element[] = [
    <div className="flex column gap-24" key={0}>
      <TicketDataHandler<TicketAdditionalDetailsResponse>
        graphqlQuery={GET_TICKET_ADDITIONAL_DETAILS}
        renderContent={(data) => <AdditionalDetailsForm data={data} />}
      />
    </div>,
    <div className="flex gap-24" key={2}>
      <TicketDataHandler<TicketMttxResponse>
        graphqlQuery={GET_TICKET_MTTX}
        renderContent={(data) => <MttxForm data={data} />}
      />
    </div>,
  ]
  if (!isDWEmployee) {
    //? Add related cases tab and its content
    tabs.splice(1, 0, {
      active: false,
      id: 1,
      text: `Related cases (${ticketRelatedCasesPlaceholderData.length})`,
      notification: false,
    })
    tabsContent.splice(
      1,
      0,
      <RelatedCases key={1} tickets={ticketRelatedCasesPlaceholderData} />,
    )
  }
  return (
    <TicketEditCard
      variant="transparent"
      cardStyles={{ padding: 16, gap: 16, minHeight: '1100px' }}
    >
      <Tabs data={tabs} activeTab={0}>
        {tabsContent}
      </Tabs>
    </TicketEditCard>
  )
}

export default TicketAdditionalDetails
