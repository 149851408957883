import { useContext } from 'react'
import { subDays } from 'date-fns'

import { Context } from '../../App'
import {
  Typography,
  DatePicker,
  colors,
  DatePickerProps,
} from '../../../design-system'

import './PageBanner.scss'

type PageBannerProps = Pick<
  Required<DatePickerProps>,
  | 'startDate'
  | 'endDate'
  | 'defaultStartDate'
  | 'defaultEndDate'
  | 'onSubmit'
  | 'iconCallback'
> & {
  userFirstName: string
  maturityScoreStartDate?: Date | null
  dateFilterableStyle: boolean
}

const PageBanner: React.FC<PageBannerProps> = ({
  startDate,
  endDate,
  defaultStartDate,
  defaultEndDate,
  onSubmit,
  iconCallback,
  userFirstName,
  maturityScoreStartDate,
  dateFilterableStyle,
}): JSX.Element => {
  const {
    state: { customerLoading },
  } = useContext(Context)

  return (
    <div className="page-banner">
      <div className="welcome-message">
        <Typography component="span" size={22} color={colors.util.navy[100]}>
          {`Welcome, ${userFirstName}`}
        </Typography>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          flexWrap: 'nowrap',
        }}
      >
        {dateFilterableStyle && (
          <div className="date-filter-message">
            <Typography
              size={12}
              color={'#EEF6F9'}
              styles={{
                marginLeft: '10px',
                paddingTop: '2px',
              }}
            >
              Only highlighted items will be affected by global filter
            </Typography>
          </div>
        )}
        <DatePicker
          popoverStyle={{ right: '0px' }} // Prevent container from overflowing off of the screen
          disabledDate={(date) => {
            // Data pipeline is one day behind so we don't let the user select the current day
            const lastDay = subDays(new Date(), 1)

            // Disable the dates after today or before the first available data
            if (date > lastDay) {
              return true
            }

            // Disable dates before maturity score start date
            if (maturityScoreStartDate && maturityScoreStartDate > date) {
              return true
            }

            return false
          }}
          startDate={startDate}
          endDate={endDate}
          defaultStartDate={defaultStartDate}
          defaultEndDate={defaultEndDate}
          loading={customerLoading}
          onSubmit={onSubmit}
          allowIconChange={true}
          iconCallback={iconCallback}
        />
      </div>
    </div>
  )
}

export default PageBanner
