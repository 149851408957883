import {
  Box,
  IconButton,
  List,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material'

import Icon from '@common/Icon'

import ForwarderCounts from './ForwarderCounts'
import DailySourceTypesDeviations from './DailySourceTypesDeviations'

const toolTipText = (
  <List sx={{ listStyleType: 'disc', pl: 2 }}>
    <ListItemText sx={{ display: 'list-item' }}>
      <Typography
        variant="caption"
        sx={(theme) => ({
          color:
            theme.palette.mode === 'light'
              ? theme.vars.palette.text.primary
              : theme.vars.palette.text.secondary,
        })}
      >
        Active: Forwarders that have been active in a customer&apos;s
        environment within the past 14 days.
      </Typography>
    </ListItemText>
    <ListItemText sx={{ display: 'list-item' }}>
      <Typography
        variant="caption"
        sx={(theme) => ({
          color:
            theme.palette.mode === 'light'
              ? theme.vars.palette.text.primary
              : theme.vars.palette.text.secondary,
        })}
      >
        At Risk: Forwarders that have previously reported, but now aren&apos;t
        responding to the polling.
      </Typography>
    </ListItemText>
  </List>
)
const StatusDetails: React.FC = () => {
  return (
    <Box id="status-details" data-testid="status-details">
      <Typography color="textPrimary" variant="h6">
        Status details
        <Tooltip
          title={toolTipText}
          placement="top-start"
          sx={{
            cursor: 'initial',
            ':hover': {
              backgroundColor: 'transparent',
            },
          }}
          followCursor
        >
          <IconButton>
            <Icon
              size={18}
              sx={(theme) => ({
                ...theme.applyStyles('dark', {
                  color: theme.vars.palette.text.primary,
                }),
                color: theme.vars.palette.secondary.contrastText,
              })}
              variant="informationCircleOutline"
            />
          </IconButton>
        </Tooltip>
      </Typography>

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(280px, 1fr))',
          gridGap: '1.5rem',
          paddingTop: '1rem',
        }}
      >
        <ForwarderCounts />
        <DailySourceTypesDeviations />
      </Box>
    </Box>
  )
}

export default StatusDetails
