import { useState } from 'react'

import { UserManagementSettings } from './UserManagementSettings'
import { getAccessGroups } from '../../utils'
import { Tabs } from '../../design-system'

import './Settings.scss'

const Settings = () => {
  const [activeTab, setActiveTab] = useState(0)

  const onTabClick = (id: any) => {
    setActiveTab(id)
  }

  const isAdmin =
    getAccessGroups().filter((item) => {
      return (item as string).includes('-portal-admins')
    }).length > 0

  const settingsTabs: any[] = []
  const settingsComponents: any[] = []

  if (isAdmin) {
    settingsTabs.push({
      id: 0,
      text: 'User Management',
      active: true,
      notification: false,
    })
    settingsComponents.push(<UserManagementSettings key={0} />)
  }

  return (
    <article id="settings-page" className="content" data-testid="settings">
      <div className="settings-container">
        <div className="content-bar">
          <Tabs data={settingsTabs} onTabClick={onTabClick}></Tabs>
        </div>
        <div className="tab-content">{settingsComponents[`${activeTab}`]}</div>
      </div>
    </article>
  )
}

export default Settings
