import { ProgressBarProps } from '../../interfaces'
import { colors } from '../../theme'

const ProgressBar = ({
  value,
  maxWidth,
  borderRad,
  height,
  padding,
  breakpoints = [],
  enableBackground,
  enabledBackGroundColor,
}: ProgressBarProps): JSX.Element => {
  const color = breakpoints.reduce(
    (prevBreakpoint, breakpoint) => {
      if (
        value >= breakpoint.threshold &&
        breakpoint.threshold >= prevBreakpoint.threshold
      ) {
        return breakpoint
      }
      return prevBreakpoint
    },
    { threshold: 0, color: colors.util.navy[100] },
  ).color
  const paddingValue = (
    val: number,
    pad: ProgressBarProps['padding'],
  ): number | ProgressBarProps['padding'] => {
    if (val <= 0) {
      return 0
    } else {
      return pad
    }
  }

  return enableBackground === true ? (
    <div
      style={{
        height: height,
        padding: padding,
        background: `linear-gradient(to right, ${color} ${value}% ,${enabledBackGroundColor} ${
          value - 1
        }%)`,
        maxWidth: maxWidth,
        borderRadius: borderRad,
      }}
    />
  ) : (
    <div
      style={{
        height: height,
        padding: paddingValue(value, padding),
        background: `linear-gradient(to right, ${color} ${value}% ,  rgba(255,255,255,0) ${
          value - 1
        }%)`,
        maxWidth: maxWidth,
        borderRadius: borderRad,
      }}
    />
  )
}

export default ProgressBar
