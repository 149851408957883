import { Paths } from '../../App/Types'
import { AppRouteItem, MenuSetItem, NavMenuSet } from './SideBarTypes'

const mainMenuSet: NavMenuSet = {
  menuHeaderLabel: 'YOUR ENVIRONMENT',
  menuItems: [],
}
const settingsMenuSet: NavMenuSet = {
  menuHeaderLabel: 'SETTINGS',
  menuItems: [],
  parentSet: mainMenuSet,
}

const settingsMobileMenuSet: NavMenuSet = {
  menuHeaderLabel: 'SETTINGS',
  menuItems: [],
  parentSet: mainMenuSet,
}

const mainMobileMenuSet: NavMenuSet = {
  menuHeaderLabel: 'YOUR ENVIRONMENT',
  menuItems: [],
}

mainMenuSet.menuItems = [
  new AppRouteItem({
    id: 'dashboard-active',
    label: 'Home',
    activeIcon: 'home',
    inactiveIcon: 'homeOutline',
    targetPath: Paths.DASHBOARD,
  }),
  new AppRouteItem({
    id: 'insights-active',
    label: 'Insights',
    activeIcon: 'statsChart',
    inactiveIcon: 'statsChartOutline',
    targetPath: Paths.INSIGHTS,
  }),
  new AppRouteItem({
    id: 'detection-coverage-active',
    label: 'Detection Coverage',
    activeIcon: 'shield',
    inactiveIcon: 'shieldOutline',
    targetPath: Paths.DETECTION_COVERAGE,
  }),
  new AppRouteItem({
    id: 'tickets-active',
    label: 'Tickets',
    activeIcon: 'ticket',
    inactiveIcon: 'ticketOutline',
    targetPath: Paths.TICKETS,
  }),
  new AppRouteItem({
    id: 'threat-intel-active',
    label: 'Threat Intelligence',
    activeIcon: 'earth',
    inactiveIcon: 'earthOutline',
    targetPath: Paths.THREAT_INTEL_REPORTS,
  }),
  new MenuSetItem({
    id: 'settings-active',
    activeIcon: 'cogOutline',
    inactiveIcon: 'cogOutline',
    label: 'Settings',
    targetMenuSet: settingsMenuSet,
  }),
]

settingsMenuSet.menuItems = [
  new AppRouteItem({
    id: 'company-active',
    label: 'My company',
    activeIcon: 'business',
    inactiveIcon: 'businessOutline',
    targetPath: Paths.COMPANY_INFO,
  }),
  new AppRouteItem({
    id: 'profile-active',
    label: 'My profile',
    activeIcon: 'personCircle',
    inactiveIcon: 'personCircleOutline',
    targetPath: Paths.PROFILE,
  }),
  new AppRouteItem({
    id: 'user-management-active',
    label: 'User management',
    activeIcon: 'peopleCircle',
    inactiveIcon: 'peopleCircleOutline',
    targetPath: Paths.USER_MANAGEMENT,
  }),
]

settingsMobileMenuSet.menuItems = [
  new AppRouteItem({
    id: 'dashboard-active',
    label: 'Home',
    activeIcon: 'home',
    inactiveIcon: 'homeOutline',
    targetPath: Paths.DASHBOARD,
  }),
  ...settingsMenuSet.menuItems,
]

mainMobileMenuSet.menuItems = [
  new AppRouteItem({
    id: 'dashboard-active',
    label: 'Home',
    activeIcon: 'home',
    inactiveIcon: 'homeOutline',
    targetPath: Paths.DASHBOARD,
  }),
  new AppRouteItem({
    id: 'insights-active',
    label: 'Insights',
    activeIcon: 'statsChart',
    inactiveIcon: 'statsChartOutline',
    targetPath: Paths.INSIGHTS,
  }),
  new AppRouteItem({
    id: 'detection-coverage-active',
    label: 'Coverage',
    activeIcon: 'shield',
    inactiveIcon: 'shieldOutline',
    targetPath: Paths.DETECTION_COVERAGE,
  }),
  new AppRouteItem({
    id: 'tickets-active',
    label: 'Tickets',
    activeIcon: 'ticket',
    inactiveIcon: 'ticketOutline',
    targetPath: Paths.TICKETS,
  }),
  new AppRouteItem({
    id: 'threat-intel-active',
    label: 'Threat Intel',
    activeIcon: 'earth',
    inactiveIcon: 'earthOutline',
    targetPath: Paths.THREAT_INTEL_REPORTS,
  }),

  new MenuSetItem({
    id: 'settings-active',
    activeIcon: 'cogOutline',
    inactiveIcon: 'cogOutline',
    label: 'Settings',
    targetMenuSet: settingsMenuSet,
  }),
]

export {
  mainMenuSet,
  settingsMenuSet,
  settingsMobileMenuSet,
  mainMobileMenuSet,
}
