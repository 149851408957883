import { Typography, colors } from '../../../design-system'

import './ComponentError.scss'

const ComponentError = () => {
  return (
    <div id="component-error">
      <Typography variant="text8" color={colors.util.navy[50]}>
        We were unable to fetch data.
      </Typography>

      <Typography variant="text10">
        Please wait a few minutes and try reloading the page.
      </Typography>
    </div>
  )
}

export default ComponentError
