import { gql } from '@apollo/client'

export const GET_DETECTION_COVERAGE_OVERVIEW = gql`
  query GetDetectionCoverageOverview($selectedCustomer: String) {
    getDetectionCoverageOverview(selectedCustomer: $selectedCustomer) {
      splunkUtilization {
        license
        largestLogSource
        splunkUsage {
          metricDate
          totalGb
          totalLicenseGb
        }
      }
      splunkUtilizationWeekTrend {
        previousWeek {
          metricDate
          totalGb
          totalLicenseGb
        }
        currentWeek {
          metricDate
          totalGb
          totalLicenseGb
        }
      }
      mitreCoverageCustomer {
        mitreTacticId
        mitreTacticName
        mitreTacticDescription
        mitreTechniqueId
        mitreTechniqueName
        mitreTechniqueDescription
        totalUseCaseCount
        totalEnabledUseCaseCount
        subtechniques {
          mitreSubtechniqueId
          mitreSubtechniqueName
          mitreTechniqueId
          mitreTechniqueName
          totalUseCaseCount
          totalEnabledUseCaseCount
        }
      }
      enabledDetections
      availableDetections
      mitreCoverage {
        mitreTacticId
        mitreTacticName
        mitreTacticDescription
        mitreTacticSequence
        mitreTechniqueId
        mitreTechniqueName
        mitreTechniqueDescription
        platformList
        permissionList
        totalUseCaseCount
        isCovered
        subtechniques {
          mitreSubtechniqueId
          mitreSubtechniqueName
          mitreSubtechniqueDescription
          mitreTechniqueId
          totalUseCaseCount
          isCovered
        }
      }
      mitreCoverageByTactic {
        mitreTacticId
        mitreTacticName
        mitreTacticDescription
        mitreTacticSequence
        totalUseCaseCount
        version
      }
      alertsByMitreTactic {
        tactic
        incidentCount
      }
      logSourceByUtilization {
        source
        totalGb
        utilizationPercentage
      }
      logSourceByAlert {
        source
        incidentCount
        alertsPercentage
      }
      maturityTrends {
        deepwatch {
          date
          score
        }
        customer {
          date
          score
        }
        customerIndustriesAverage {
          date
          score
        }
      }
    }
  }
`

export const GET_DETECTIONS = gql`
  query GetDetections($selectedCustomer: String) {
    getDetections(selectedCustomer: $selectedCustomer) {
      detections {
        useCase
        title
        customerVersion
        releaseVersion
        description
        mitreTactics
        mitreTechniques
        dataTypes
        dataAvailable
        availableInVersion
        status
      }
      filters
    }
  }
`
