/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import { Typography } from '../../../../../../../../design-system'

interface SiMetricsCardProps {
  setActivePart: (id: string) => void
}

const SiMetricsCards = ({ setActivePart }: SiMetricsCardProps) => {
  return (
    <div className="si-cards flex space-evenly" data-testid="si-cards">
      <div
        className="si-card si-card-log-sources"
        role="contentinfo"
        tabIndex={0}
        id="si-log-sources"
        onMouseEnter={(e) => setActivePart(e.currentTarget.id)}
        onMouseLeave={() => setActivePart('')}
        onFocus={(e) => setActivePart(e.target.id)}
        onBlur={() => setActivePart('')}
      >
        <Typography variant="text11medium">Enabled log sources</Typography>
        <Typography variant="text11" styles={{ lineHeight: '1.5' }}>
          We base your Index on the types of data sources you send us. High
          value data sources increase this score most but are also seen as the
          most critical by Deepwatch.
        </Typography>
        <Typography variant="text11">50% weight</Typography>
      </div>
      <div
        className="si-card si-card-detections"
        role="contentinfo"
        tabIndex={0}
        id="si-detections"
        onMouseEnter={(e) => setActivePart(e.currentTarget.id)}
        onMouseLeave={() => setActivePart('')}
        onFocus={(e) => setActivePart(e.target.id)}
        onBlur={() => setActivePart('')}
      >
        <Typography variant="text11medium">Enabled detections</Typography>
        <Typography variant="text11" styles={{ lineHeight: '1.5' }}>
          Each log source provided has associated detections. Working with
          detection engineers can identify the most effective detections to
          enable.
        </Typography>
        <Typography variant="text11">30% weight</Typography>
      </div>
      <div
        className="si-card si-card-enterprise"
        role="contentinfo"
        tabIndex={0}
        id="si-enterprise-coverage"
        onMouseEnter={(e) => setActivePart(e.currentTarget.id)}
        onMouseLeave={() => setActivePart('')}
        onFocus={(e) => setActivePart(e.target.id)}
        onBlur={() => setActivePart('')}
      >
        <Typography variant="text11medium">Enterprise coverage</Typography>
        <Typography variant="text11" styles={{ lineHeight: '1.5' }}>
          The amount of coverage Deepwatch can see. When we see active assets in
          your environment your score will reflect this.
        </Typography>
        <Typography>20% weight</Typography>
      </div>
    </div>
  )
}
export default SiMetricsCards
