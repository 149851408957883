const colors = {
  brand: {
    primary: {
      lighter: '#e5fff2',
      light: '#9fc',
      main: '#0c6',
      dark: '#00994d',
      darker: '#063',
    },
    secondary: {
      lighter: '#e9f7fc',
      light: '#a7dff1',
      main: '#3ab6e0',
      dark: '#1c8bb0',
      darker: '#0e4658',
    },
  },
  util: {
    one: {
      lighter: '#eef6f9',
      light: '#a2bbc3',
      main: '#27373c',
    },
    two: {
      lighter: '#ffe5ee',
      light: '#ff99b9',
      main: '#ff4d85',
      dark: '#cc0041',
      darker: '#990030',
    },
    three: {
      lighter: '#f1e8fd',
      light: '#c5a2f6',
      main: '#7829ea',
      dark: '#5812ba',
      darker: '#2c095d',
    },
    four: {
      lighter: '#fefae6',
      light: '#ffe684',
      main: '#facc15',
      dark: '#ffdd54',
    },
    five: {
      main: '#647980',
    },
    orange: {
      50: '#fff1dd',
      100: '#ffb470',
      200: '#ff9431',
    },
    navy: {
      50: '#eef6f9',
      100: '#a2bbc3',
      200: '#51646a',
      300: '#455358',
      400: '#36474c',
      500: '#2e3d42',
      600: '#27373c',
      700: '#1c2628',
    },
    purple: {
      200: '#AC71FF',
    },
    green: {
      100: '#99FFCC',
    },
  },
  neutral: {
    50: '#f9f9f9',
    100: '#ebebeb',
    200: '#a3a3a3',
    300: '#737373',
    400: '#585858',
    500: '#343434',
  },
  common: {
    white: '#fff',
    black: '#07191f',
    trueblack: '#000',
  },
}

export default colors
