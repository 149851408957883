import { useTooltip } from '@nivo/tooltip'
import { Box, Typography, useTheme } from '@mui/material'

import { DashboardThreatProtection } from '@models/Dashboard'
import { formatLargeNumber } from '@utils/formatLargeNumber'

import { BeforeSeparatorInterface } from './ThreatProtectionSummary.models'
import { useThreatProtectionSummaryContext } from './useThreatProtectionSummaryContext'

// TODO: refactor to be used in customParts so that it is part of SVG chart and not stand alone HTML
export const customLegend = (
  data: DashboardThreatProtection,
  featureNgMdrFlag: boolean,
): JSX.Element => {
  const {
    closedCriticalTicketCount,
    closedTicketCount,
    openCriticalTicketCount,
    truePositivePercentage,
    openTicketCount,
  } = data

  const labelInfo = [
    featureNgMdrFlag
      ? {
          label: 'True positive %',
          value: (truePositivePercentage * 100).toFixed(),
          id: 'true_positive_percentage',
        }
      : {
          label: 'Tickets opened',
          value: openTicketCount,
          id: 'open_ticket_count',
        },
    {
      label: 'Critical opened',
      value: openCriticalTicketCount,
      id: 'open_critical_ticket_count',
    },
    {
      label: 'Tickets closed',
      value: closedTicketCount,
      id: 'closed_ticket_count',
    },
    {
      label: 'Critical closed',
      value: closedCriticalTicketCount,
      id: 'closed_critical_ticket_count',
    },
  ]

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}>
      {labelInfo.map(({ label, value, id }) => {
        return (
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          <Box key={id} tabIndex={0}>
            <Typography>{label}</Typography>
            <Typography
              fontWeight={600}
              sx={(theme) => ({
                color: theme.vars.palette.primary.dark,
                ...theme.applyStyles('dark', {
                  color: theme.vars.palette.text.secondary,
                }),
              })}
            >
              {value}
            </Typography>
          </Box>
        )
      })}
    </Box>
  )
}

export const getTooltipContent = (id: string, label: string): string => {
  switch (id) {
    case 'logged_events_count':
      return 'The total number of logged events with security value.'
    case 'alerts_count':
      return 'The total number of alerts detected'
    case 'validated_ticket_count':
      return 'The total number of customer validated tickets'
    default:
      return `The number of ${label.toLowerCase()}`
  }
}

// custom layer function
export const CustomSeparator = ({ beforeSeparators }): JSX.Element => {
  const {
    threatSummaryData: { isLogEventCountAvailable },
  } = useThreatProtectionSummaryContext()
  const showAdjustedLines = isLogEventCountAvailable ? 0 : 24

  const separators = beforeSeparators
    .slice(1, 4)
    .map((separator: BeforeSeparatorInterface, index: number) => {
      if (index === 2) {
        // this is the final line to the far right of the chart
        return (
          <Box
            component="line"
            key={separator.partId}
            x1={separator.x0 + showAdjustedLines}
            x2={separator.x1 + showAdjustedLines}
            y1={separator.y0}
            y2={261}
            sx={(theme) => ({
              stroke: theme.vars.palette.secondary.main,
              strokeWidth: 1,
              ...theme.applyStyles('dark', {
                stroke: theme.vars.palette.secondary.lighter,
              }),
            })}
          />
        )
      }

      return (
        <Box
          component="line"
          key={separator.partId}
          x1={separator.x0}
          x2={separator.x1}
          y1={separator.y0}
          y2={261}
          sx={(theme) => ({
            stroke: theme.vars.palette.secondary.main,
            strokeWidth: 1,
            ...theme.applyStyles('dark', {
              stroke: theme.vars.palette.secondary.lighter,
            }),
          })}
        />
      )
    })
  return separators
}

// custom layer function
export const CustomParts = (props): JSX.Element => {
  const { showTooltipFromEvent, hideTooltip } = useTooltip()
  const theme = useTheme()
  const {
    threatSummaryData: { isLogEventCountAvailable },
  } = useThreatProtectionSummaryContext()

  const handleMouseMove = (e, part) => {
    const tooltipContent = (
      <Box
        sx={(theme) => ({
          backgroundColor: theme.vars.palette.common.white,
          border: `1px solid ${theme.vars.palette.secondary.main}`,
          boxShadow: '0px 1px 4px rgba(25, 41, 46, 0.4)',
          borderRadius: '3px',
          maxWidth: 220,
          ...theme.applyStyles('dark', {
            backgroundColor: theme.vars.palette.secondary.dark,
            border: `1px solid ${theme.vars.palette.secondary.lighter}`,
          }),
        })}
      >
        <Typography
          component="p"
          sx={(theme) => ({
            padding: '4px',
            color: theme.vars.palette.text.primary,
            fontWeight: '400',
            fontSize: '12px',
            textAlign: 'left',
          })}
        >
          {getTooltipContent(part.data.id, part.data.label)}
        </Typography>
      </Box>
    )
    showTooltipFromEvent(tooltipContent, e)
  }

  const handleMouseLeave = () => {
    hideTooltip()
  }

  const showChartParts = isLogEventCountAvailable ? 4 : 3

  return props.parts.slice(0, showChartParts).map((part, index: number) => {
    const area = part.areaPoints.map(({ x, x0, x1, y, y0 }) => {
      return {
        x,
        x0,
        x1,
        y,
        y0: isNaN(y0) ? 0 : y0,
        y1: 150,
      }
    })

    if (part.data.value !== undefined) {
      return (
        <g
          key={part.data.id}
          tabIndex={0}
          data-testid={`tps-chart-part-${index}`}
          aria-label={
            part.data.id === 'logged_events_count'
              ? 'The total number of logged events with security value.'
              : `There were ${formatLargeNumber(
                  part.data.originalValueToDisplay,
                  'long',
                )} ${part.data.label}`
          }
        >
          <defs>
            {linearGradientByIndex(index, part.data.id, theme.palette.mode)}
          </defs>
          <path
            d={props.areaGenerator(area)}
            fill={`url(#${part.data.id})`}
            onMouseMove={(e) => handleMouseMove(e, part)}
            onMouseLeave={handleMouseLeave}
          />
          <Typography
            component="text"
            textAnchor="middle"
            x={part.x}
            y={180}
            sx={(theme) => ({
              fill: theme.vars.palette.text.primary,
            })}
          >
            {part.data.label}
          </Typography>
          <Typography
            fontWeight={600}
            component="text"
            textAnchor="middle"
            x={part.x}
            y={204}
            sx={(theme) => ({
              fill: theme.vars.palette.primary.dark,
              ...theme.applyStyles('dark', {
                fill: theme.vars.palette.text.secondary,
              }),
            })}
          >
            {part.data.originalValueToDisplay === 'Not available'
              ? 'Not available'
              : formatLargeNumber(part.data.originalValueToDisplay, 'short')}
          </Typography>
        </g>
      )
    }
  })
}

const linearGradientByIndex = (
  index: number,
  id: number | string,
  mode: 'light' | 'dark' | undefined,
): JSX.Element => {
  const darkModeGradients = [
    ['#0E4658', '#1c6a84'],
    ['#19627b', '#298bac'],
    ['#20738f', '#3AB6E0'],
    ['#39b5df', '#39b6e0'],
  ]
  const lightModeGradients = [['#053D71', '#3AB6E0']]

  const gradients =
    mode === 'dark'
      ? darkModeGradients[parseInt(index.toString())]
      : lightModeGradients[0]
  return (
    <linearGradient id={id.toString()} gradientTransform="rotate(0)">
      <stop offset="2.66%" stopColor={`${gradients[0]}`} />
      <stop offset="100%" stopColor={`${gradients[1]}`} />
    </linearGradient>
  )
}
