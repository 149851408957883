import { CSSProperties, ReactNode } from 'react'

import { IconButtonProps } from '../../interfaces'
import { CaptionProps } from './Caption/Caption'

export interface ColumnHeader {
  name: string
  onClickOverride?: (sortDirection: SortingOrder, index: number) => void
  sortable?: boolean
  tooltip?: boolean
  tooltipMessage?: ReactNode
  visible?: boolean
  columnWidth?: string
  secondarySortDirection?: SortingOrder | ''
  secondarySortId?: string | number
}

export interface TableSortProps {
  isSortedExternally?: boolean
  sortDirection?: SortingOrder
  defaultSortColumn?: number
  secondarySortDirection?: SortingOrder
  secondaryDefaultSortColumn?: number
}

export interface ariaProps {
  'aria-label'?: string
  'aria-labelledby'?: string
  'aria-describedBy'?: string
  'aria-hidden'?: boolean
}

export interface TableProps {
  bodyColor?: string
  bodyTextColor?: string
  captionProps?: CaptionProps
  clickableTableRow?: boolean
  customHeaders?: ColumnHeader[]
  data: Record<string, any>[]
  headerColor?: string
  headerTextColor?: string
  loading?: boolean
  loadingRows?: number
  loadingRowState?: TableLoadingState
  onBottomReached?: VoidFunction
  onClick?: (id: string | number, rowData?: any) => void
  rowHoverElements?: IconButtonProps[] | undefined
  sortOptions?: TableSortProps
  stickyHeaderOffset?: number
  styles?: CSSProperties
  ariaProps?: ariaProps
  responsive?: boolean
  customErrorComponent?: JSX.Element
}

export enum TableLoadingState {
  IDLE,
  LOADING,
  ALL_LOADED,
  ERROR,
}

export interface TableLoadingRowProps {
  loadingState: TableLoadingState
}

export type SortingOrder = 'ASC' | 'DESC'
