import { useLocation, useNavigate } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { Paths } from '@components/App/Types'
import { DashboardHighlightMetric } from '@models/Dashboard'
import { DashboardBanKeys } from '@queries/threatIntel'
import { useCustomer } from '@hooks/useCustomer'
import Ban from '@common/Ban'
import BanLayoutCommon from '@common/Ban/BanLayoutCommon'

interface DashboardBansDataProps {
  data: DashboardHighlightMetric[] | null
  loading: boolean
}

const DashboardBans = ({
  data,
  loading,
}: DashboardBansDataProps): JSX.Element => {
  const {
    customer: { isLimitedMdrExperience },
  } = useCustomer()

  const { featureNgMdr } = useFlags()

  const { search: currentCustomer } = useLocation()
  const navigate = useNavigate()

  const techniquesCovered = data?.find(
    (val) => val.key === DashboardBanKeys.TECHNIQUE_COVERED,
  )?.metric

  const escalationRate = data?.find(
    (val) => val.key === DashboardBanKeys.POSITIVES,
  )

  const handleTechnqiuesCoveredBanClick = () => {
    const val = currentCustomer
      ? `${Paths.DETECTION_COVERAGE_OVERVIEW}${currentCustomer}`
      : Paths.DETECTION_COVERAGE_OVERVIEW

    navigate(val)
  }

  return (
    <>
      {featureNgMdr && isLimitedMdrExperience ? null : (
        <Ban
          data-testid="techniques-covered-ban"
          iconVariant="keypad"
          onClick={handleTechnqiuesCoveredBanClick}
          showHighlight={false}
        >
          <BanLayoutCommon
            caption={`You currently have ${techniquesCovered ?? 0} MITRE techniques covered based on your enabled detections.`}
            error={techniquesCovered == null}
            loading={loading}
            title="TECHNIQUES COVERED"
            value={techniquesCovered?.toString() ?? ''}
          />
        </Ban>
      )}

      <Ban data-testid="escalation-rate-ban" iconVariant="checkmarkDone">
        <BanLayoutCommon
          caption="The percent of alerts that become tickets you receive from your Deepwatch experts."
          loading={loading}
          error={escalationRate?.metric == null}
          title="ESCALATION RATE"
          value={`${escalationRate?.metric?.toString() ?? ''}%`}
        />
      </Ban>
    </>
  )
}

export default DashboardBans
