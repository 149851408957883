/* eslint-disable security/detect-object-injection */
import { useQuery } from '@apollo/client'
import { useSearchParams } from 'react-router-dom'
import { useCallback, useMemo, useRef, useState } from 'react'
import {
  createColumnHelper,
  getCoreRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table'
import { format, formatDistanceToNowStrict } from 'date-fns'
import { Box, IconButton, Tooltip, Typography } from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'

import generalErrorIcon from '@app-assets/general-error-icon.svg'
import { EDR_ASSETS } from '@queries/medr'
import {
  buildGenericCell,
  buildGenericHeader,
  buildSkeletonRows,
} from '@common/Table/utils/table.utils'
import { PaginationLoadingRow, TableHeader } from '@common/Table'
import { MemoizedTanStackTableRow } from '@common/Table/components/MemoizedTanStackTableRow'
import { TableToolbar } from '@common/TableToolbar'
import { ComponentError } from '@common/ComponentError'
import { NoResults } from '@common/NoResults'
import Icon from '@common/Icon'
import CommonTableContainer from '@common/Table/components/CommonTableContainer'
import CommonTable from '@common/Table/components/CommonTable'
import StyledTableCell from '@common/Table/styled/StyledTableCell'
import {
  mergeRefs,
  useResizableColumns,
} from '@common/Table/ColumnResize/useResizableColumns'
import { useEDRContent } from '@hooks/useEDRContent'
import { formatFiltersForTanStackTable } from '@hooks/tableHooks/useSearchParamColumnFilters'

import {
  HostnameCell,
  OperatingSystemCell,
  RFMStatusCell,
  StatusCell,
} from './cells'
import { TIME_FILTER_MAPPING } from '../EDRAssetFilterSideSheet'

import type { EDRAssetFiltersForm } from '../../edrTypes'

export interface EDRAsset {
  agentVersion: string
  hostname: string
  online: boolean
  operatingSystem: string
  lastReboot: string
  lastSeen: string
  platform: string
  rfmStatus: string
  status: string
}

/** Keys for filters which should be transformed using `TIME_FILTER_MAPPING` */
const timeFilterKeys: ReadonlyArray<keyof EDRAssetFiltersForm> = [
  'lastReboot',
  'lastSeen',
]

interface EDRAssetTableProps {
  filters: EDRAssetFiltersForm
  showFilterSidesheet: VoidFunction
}

const EDRAssetTable: React.FC<EDRAssetTableProps> = ({
  filters,
  showFilterSidesheet,
}) => {
  const [sorting, setSorting] = useState<SortingState>([
    { id: 'lastSeen', desc: true },
  ])

  const [searchParams] = useSearchParams()
  const selectedCustomer = searchParams.get('customer')

  const { featureDefenderForEndpoint } = useFlags()
  const { assetTableColumnMapping } = useEDRContent()

  const tableRef = useRef<HTMLDivElement>(null)

  const getTransformedFilters = () =>
    formatFiltersForTanStackTable(filters)
      .filter((columnFilter) => columnFilter.value.length > 0)
      .map((columnFilter) => {
        if (timeFilterKeys.includes(columnFilter.id)) {
          return {
            id: columnFilter.id,
            value: TIME_FILTER_MAPPING[columnFilter.value[0]],
          }
        }
        return columnFilter
      })

  const {
    data: { edrAssets: { data: edrAssets, pagination } } = {
      edrAssets: { data: [], pagination: { offset: 0, limit: 25, total: 0 } },
    },
    error,
    fetchMore,
    loading,
  } = useQuery(EDR_ASSETS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      pagination: {
        limit: 25,
        offset: 0,
      },
      selectedCustomer,
      filters: getTransformedFilters(),
      sorting: sorting[0],
    },
  })

  const columnDef = useMemo(() => {
    const columnHelper = createColumnHelper<EDRAsset>()

    return [
      columnHelper.accessor('hostname', {
        id: 'hostname',
        cell: HostnameCell,
        header: () => buildGenericHeader('host name'),
      }),
      columnHelper.accessor('status', {
        id: 'status',
        cell: StatusCell,
        header: () => buildGenericHeader('status'),
      }),
      columnHelper.accessor('rfmStatus', {
        id: 'rfmStatus',
        size: 80,
        cell: RFMStatusCell,
        header: () =>
          buildGenericHeader(
            featureDefenderForEndpoint
              ? assetTableColumnMapping.rfmStatus
              : 'RFM',
          ),
      }),
      columnHelper.accessor('agentVersion', {
        id: 'agentVersion',
        size: 100,
        cell: (props) => buildGenericCell(props),
        header: () => buildGenericHeader('version'),
      }),
      {
        id: 'operatingSystem',
        accessorFn: (row) => ({
          platform: row.platform,
          operatingSystem: row.operatingSystem,
        }),
        cell: OperatingSystemCell,
        header: () => buildGenericHeader('os'),
      },
      columnHelper.accessor('lastSeen', {
        id: 'lastSeen',
        size: 110,
        cell: (props) => {
          return props.getValue() ? (
            <Typography
              noWrap
              variant="body2"
              sx={(theme) => ({
                color: theme.vars.palette.text.primary,
                ...theme.applyStyles('dark', {
                  color: theme.vars.palette.text.secondary,
                }),
              })}
            >
              {formatDistanceToNowStrict(props.getValue())}
            </Typography>
          ) : null
        },

        header: () => buildGenericHeader('last seen'),
      }),
      columnHelper.accessor('lastReboot', {
        id: 'lastReboot',
        size: 150,
        cell: (props) => {
          const value = props.getValue()
          return props.getValue() ? (
            <Typography
              variant="body2"
              noWrap
              sx={(theme) => ({
                color: theme.vars.palette.text.primary,
                ...theme.applyStyles('dark', {
                  color: theme.vars.palette.text.secondary,
                }),
              })}
            >
              {format(new Date(value), 'MMM dd, yyyy HH:mm')}
            </Typography>
          ) : null
        },
        header: () => buildGenericHeader('last rebooted'),
      }),
    ]
  }, [])

  const {
    columnSizing,
    onColumnSizingChange,
    tableHeaderRefs,
    tableContainerRef,
    handleMouseUp,
    handleMouseDown,
    resetColumnSize,
  } = useResizableColumns({
    columnDef,
  })

  const table = useReactTable({
    columns: columnDef,
    data: edrAssets,
    enableRowSelection: false,
    enableMultiRowSelection: false,
    enableSortingRemoval: false,
    getCoreRowModel: getCoreRowModel(),
    manualFiltering: true,
    manualSorting: true,
    onSortingChange: (val) => {
      handleScrollToTop()
      setSorting(val)
    },
    columnResizeMode: 'onChange',
    onColumnSizingChange,
    state: {
      sorting,
      columnSizing,
    },
  })

  const handleOnBottomReached = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement

        if (scrollHeight - scrollTop - clientHeight < 300) {
          fetchMore({
            variables: {
              pagination: {
                limit: pagination.limit,
                offset: edrAssets.length,
              },
            },
          })
        }
      }
    },
    [edrAssets.length, fetchMore, pagination.limit],
  )

  const handleScrollToTop = useCallback(() => {
    tableRef.current &&
      tableRef.current.scrollTo({
        behavior: 'smooth',
        top: 0,
      })
  }, [])

  const renderTableBody = () => {
    if (error) {
      return (
        <tbody>
          <tr>
            <StyledTableCell
              align="center"
              colSpan={7}
              sx={{ border: 'none', padding: '2rem' }}
            >
              <ComponentError
                errorIcon={generalErrorIcon}
                errorText={`No ${featureDefenderForEndpoint ? 'devices' : 'assets'} available`}
                errorSubText={`An error occurred while fetching ${featureDefenderForEndpoint ? 'devices' : 'assets'}. Please try again later.`}
              />
            </StyledTableCell>
          </tr>
        </tbody>
      )
    }

    if (!loading && edrAssets.length === 0) {
      return (
        <tbody>
          <tr>
            <StyledTableCell
              align="center"
              colSpan={7}
              sx={{ border: 'none', padding: '2rem' }}
            >
              <NoResults />
            </StyledTableCell>
          </tr>
        </tbody>
      )
    }

    return (
      <tbody data-testid="edr-asset-table-body">
        {loading && edrAssets.length === 0
          ? buildSkeletonRows(table.getAllColumns())
          : table
              .getRowModel()
              .rows.map((row) => (
                <MemoizedTanStackTableRow
                  key={row.id}
                  isDisabled
                  rowId="edr-asset-table-row"
                  testId="edr-asset-table-row"
                  isSelected={row.getIsSelected()}
                  toggleSelected={row.toggleSelected}
                  visibleCells={row.getVisibleCells()}
                />
              ))}

        <PaginationLoadingRow
          colSpan={7}
          currentLength={edrAssets.length}
          pageSize={pagination.limit}
          total={pagination.total}
          onScrollToTop={handleScrollToTop}
        />
      </tbody>
    )
  }

  return (
    <>
      <TableToolbar
        loading={loading && edrAssets.length === 0}
        dataLength={pagination.total}
        singularEntityName={featureDefenderForEndpoint ? 'device' : 'asset'}
        pluralEntityName={featureDefenderForEndpoint ? 'devices' : 'assets'}
      >
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            gap: '0.5rem',
          }}
        >
          <IconButton onClick={() => showFilterSidesheet()} size="small">
            <Icon variant="filter" />
          </IconButton>

          <Box
            sx={(theme) => ({
              height: '24px',
              borderRight: `1px solid ${theme.vars.palette.secondary.light}`,
            })}
          />
          <Tooltip
            title="Data updated every 24 hours."
            followCursor
            placement="top-start"
          >
            <Box>
              <Icon variant="informationCircleOutline" />
            </Box>
          </Tooltip>
        </Box>
      </TableToolbar>

      <CommonTableContainer
        data-testid="edr-asset-table-container"
        onScroll={(e) => handleOnBottomReached(e.target as HTMLDivElement)}
        ref={mergeRefs(tableContainerRef, tableRef)}
      >
        <CommonTable>
          <thead data-testid="edr-asset-table-header">
            <tr>
              {table.getLeafHeaders().map((header, index) => (
                <TableHeader<EDRAsset>
                  key={header.id}
                  header={header}
                  width={columnSizing[header.id]}
                  table={table}
                  thRef={tableHeaderRefs[index]}
                  onResizeHandleMouseUp={handleMouseUp}
                  onResizeHandleMouseDown={handleMouseDown}
                  onResetColumnSize={resetColumnSize}
                />
              ))}
            </tr>
          </thead>

          {renderTableBody()}
        </CommonTable>
      </CommonTableContainer>
    </>
  )
}

export default EDRAssetTable
