import { useLocation, useNavigate } from 'react-router-dom'

import {
  colors,
  Icon,
  Link,
  List,
  Tooltip,
  Typography,
} from '../../../../design-system'
import dwEye from '../../../../../public/dw-eye.svg'
import { Customer } from '../../../../models'
import { Paths } from '../../../App/Types'
import ComponentError from '../../../common/ComponentError/ComponentError'

import './CurrentTicketsWidget.scss'
import '../Dashboard.scss'

export interface TicketDetailsData {
  openCriticalCount: number
  openHighCount: number
  openWaitingCustomerCount: number
  openWaitingDeepwatchCount: number
}

export interface LinksData {
  splunk?: string
  servicenow: string
}

export interface TicketDetailsProps {
  customer: Customer
  data: TicketDetailsData
  loading: boolean
  links: LinksData
}

const CurrentTicketsWidget = ({
  customer,
  data,
  links,
  loading,
}: TicketDetailsProps): JSX.Element => {
  const navigate = useNavigate()

  const { search: currentCustomer } = useLocation()

  const handleFilterCurrentTickets = (filter: string) => {
    if (currentCustomer) {
      navigate(`${Paths.TICKETS}${currentCustomer}&selectedFilters={${filter}}`)
    } else {
      navigate(`${Paths.TICKETS}?selectedFilters={${filter}}`)
    }
  }

  const renderLinks = () => {
    if (!loading && !data) {
      return (
        <div className="links-error">
          <ComponentError />
        </div>
      )
    }

    return (
      <div id="list-border" className="current-ticket-links">
        <List
          variant={'link'}
          options={[
            {
              id: 0,
              leftIcon: 'skull',
              name: 'Open critical',
              rightText: `${data?.openCriticalCount}`,
              loading: loading,
              iconColor: '#FF4D85',
              onClick: () =>
                handleFilterCurrentTickets('"priority":["Critical"]'),
            },
            {
              id: 1,
              leftIcon: 'alertCircle',
              name: 'Open high',
              rightText: `${data?.openHighCount}`,
              loading: loading,
              iconColor: '#FF9431',
              onClick: () => handleFilterCurrentTickets('"priority":["High"]'),
            },
            {
              id: 2,
              leftIcon: 'personCircle',
              name: `Waiting for ${
                !customer?.customerName ? '...' : customer.customerName
              }`,
              rightText: `${data?.openWaitingCustomerCount}`,
              loading: loading,
              iconColor: '#C5A2F6',
              onClick: () =>
                handleFilterCurrentTickets(
                  '"state":["In Progress - Customer"]',
                ),
            },
            {
              id: 3,
              leftAlternateImage: <img src={dwEye} alt="Deepwatch" />,
              name: 'Waiting on Deepwatch',
              rightText: `${data?.openWaitingDeepwatchCount}`,
              loading: loading,
              iconColor: '#00CC66',
              onClick: () =>
                handleFilterCurrentTickets('"state":["In Progress - dw"]'),
            },
          ]}
        />
      </div>
    )
  }

  return (
    <div id="ticket-details" data-testid="current-tickets-widget">
      <div style={{ marginBottom: '6px' }} className="title">
        <Tooltip
          target={
            <div className="current-tickets-title">
              <Typography
                component="span"
                color={colors.util.one.light}
                size={18}
                weight={600}
              >
                Current tickets
              </Typography>

              {!loading && !data && (
                <Icon
                  variant="warningOutline"
                  size={20}
                  color={colors.util.orange[100]}
                />
              )}
            </div>
          }
        >
          Tickets currently open in your environment.
        </Tooltip>

        {!loading && links && (
          <Link
            variant="button"
            aProps={{
              href: links.servicenow,
              target: '_blank',
              rel: 'noreferrer',
            }}
            iconProps={{ variant: 'logOutOutline' }}
          >
            ServiceNow
          </Link>
        )}
      </div>

      {renderLinks()}
    </div>
  )
}

export default CurrentTicketsWidget
