import { CellContext } from '@tanstack/react-table'
import { Box, Typography } from '@mui/material'

import Icon from '@common/Icon'

import { EDRAsset } from '..'

interface OperatingSystemCellValues {
  platform: EDRAsset['platform']
  operatingSystem: EDRAsset['operatingSystem']
}

const OperatingSystemCell = (
  props: CellContext<EDRAsset, OperatingSystemCellValues>,
) => {
  const { platform, operatingSystem } = props.getValue()

  const getLogo = () => {
    if (platform && operatingSystem) {
      if (platform.toLowerCase().includes('windows')) {
        return 'windowsLogo'
      }

      if (platform.toLowerCase().includes('linux')) {
        return 'linuxLogo'
      }

      if (platform.toLowerCase().includes('mac')) {
        return 'macOSLogo'
      }
    }

    return ''
  }

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        gap: '4px',
      }}
    >
      <Icon
        sx={(theme) => ({
          fill: theme.vars.palette.text.disabled,
        })}
        variant={getLogo()}
      />
      <Typography
        color="textSecondary"
        noWrap={true}
        sx={(theme) => ({
          color: theme.vars.palette.text.primary,
          ...theme.applyStyles('dark', {
            color: theme.vars.palette.text.secondary,
          }),
        })}
        variant="body2"
      >
        {operatingSystem}
      </Typography>
    </Box>
  )
}

export default OperatingSystemCell
