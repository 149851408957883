import classNames from 'classnames'

import { colors } from '../../../../../../../../design-system'

import './Indicator.scss'

type Indicator = {
  x: number
  y: number
  groupClassNames?: {
    pink?: boolean
    active?: boolean
    inactive?: boolean
  }
  innerHTML: string | TrustedHTML
  onClick: () => void
}

const Indicator: React.FC<Indicator> = ({
  x,
  y,
  groupClassNames,
  innerHTML,
  onClick,
}) => {
  return (
    <>
      <polygon
        points={`${x + 7},${y + 22} ${x + 3},${y + 16} ${x + 11},${y + 16}`}
        fill={colors.util.navy[700]}
        fillOpacity={0.54}
      />
      <g onClick={onClick} className={classNames('indicator', groupClassNames)}>
        <rect
          fill={colors.util.navy[700]}
          fillOpacity={0.54}
          width={18}
          height={18}
          x={x - 2} //? center icon SVG horizontally from padding
          y={y - 2} //? center icon SVG veritically from padding
          rx={3}
          ry={3}
        />
        <g dangerouslySetInnerHTML={{ __html: innerHTML }} />
      </g>
    </>
  )
}

export default Indicator
