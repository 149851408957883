import { useCallback, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { AccessToken } from '@okta/okta-auth-js'
import { stringify } from 'qs'
import { captureException } from '@sentry/react'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { Context, setSession, setUser } from '@components/App'
import { addDisablePointerEventsClass } from '@utils/addDisablePointerEventsClass'
import { SessionStatus, oktaAuthConfig } from '@config/OktaAuthConfig'
import { deleteCookie } from '@utils/Cookies'
import { Paths } from '@components/App/Types'

const useSignOut = (): VoidFunction => {
  const { dispatch } = useContext(Context)
  const navigate = useNavigate()

  const { fixSentryBug } = useFlags()

  const signOut = useCallback(async () => {
    addDisablePointerEventsClass(false)

    if (!fixSentryBug) {
      await oktaAuthConfig.signOut({ clearTokensBeforeRedirect: true })
    } else {
      const { tokens } = await oktaAuthConfig.token.getWithoutPrompt()
      await handleOktaLogout(tokens.accessToken)
    }

    dispatch(setSession(SessionStatus.INACTIVE))
    dispatch(
      setUser({
        id: '',
        email: '',
        firstName: '',
        lastName: '',
        username: '',
        isDWEmployee: false,
        isAdmin: false,
      }),
    )
    deleteCookie('iav')
    localStorage.removeItem('isDw')
    navigate(Paths.LOGIN)
  }, [dispatch, navigate])

  return signOut
}

export const handleOktaLogout = async (
  accessToken: AccessToken | undefined,
): Promise<void> => {
  const tokenData = {
    token: accessToken,
    client_id: process.env.OKTA_CLIENT_ID,
    token_type_hint: 'access_token',
  }
  try {
    await axios.post(`${process.env.ISSUER}/oauth2/v1/revoke`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: stringify(tokenData),
    })
  } catch (error) {
    captureException(error)
    //TODO: We need a larger discussion about how we handle errors. The codebase has us basically returning undefined or empty catch blocks so lets discuss in new year.
  } finally {
    await oktaAuthConfig.signOut({ clearTokensBeforeRedirect: true }) //INFO: We need to clear tokens from session storage as best practice as well, hence the flag 'clearTokensBeforeRedirect:true' is passed here.
  }
}

export default useSignOut
