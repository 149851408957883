import classNames from 'classnames'

import { colors, Icon, Typography } from '../../../design-system'

import './UserAvatar.scss'

export type UserInfo = {
  id: string
  firstName: string
  lastName: string
  type: 'cust' | 'dw' | 'system'
}

interface Props {
  fontSize?: number
  size?: number
  userInfo: UserInfo | null
}

const UserAvatar = ({
  fontSize = 11,
  size = 28,
  userInfo,
}: Props): JSX.Element => {
  //NOTE: this will need to updated based on updated from BE on return types of ticket data and if we are including user info in initial queruies etc.
  //We should consider refactoring to hanlde both ticket edit use case as well as the current use in the header component.
  const initials = `${userInfo?.firstName.charAt(0).toUpperCase() ?? ''}${
    userInfo?.lastName.charAt(0).toUpperCase() ?? ''
  }`

  const userAvatarClassnames = classNames('user-avatar', {
    customer: !userInfo || userInfo.type === 'cust',
    dw: userInfo?.type === 'dw',
    system: userInfo?.type === 'system',
  })

  const initialLabel = initials.length > 0 ? initials : '--'

  return (
    <div
      className={userAvatarClassnames}
      data-testid="user-avatar"
      style={{
        width: size,
        height: size,
      }}
    >
      {userInfo?.type === 'system' ? (
        <Icon variant="ticketOutline" color={colors.common.black} size={16} />
      ) : (
        <Typography size={fontSize} weight={700} color={colors.common.white}>
          {initialLabel}
        </Typography>
      )}
    </div>
  )
}

export default UserAvatar
