import { ForwardedRef, forwardRef, useState } from 'react'

import { ColumnHeader } from '../Table.types'
import { TableCell } from '../TableCell'
import { tableBodyColor } from '../Table.utils'
import { useTheme } from '../../../hooks'
import { IconButtonProps } from '../../../interfaces'
import { Button } from '../../Button'
import { Icon } from '../../Icon'
import {
  handleButtonClick,
  handleIconButtonClick,
  handleKeyDown,
  handleRowClick,
} from './handler'
import { colors } from '../../../theme'
import { SkeletonBlock } from '../../SkeletonBlock'

export interface TableRowProps {
  index: number
  tableHeaders: ColumnHeader[]
  tableRowData: Record<string, any>
  bodyTextColor?: string
  loading: boolean
  clickableTableRow?: boolean
  rowClick?: (id: string | number, rowData?: any) => void
  classNames?: string
  rowHoverElements?: IconButtonProps[]
  active: boolean
}

//TODO: Create Table Cell Component for Rows
const TableRow = forwardRef(function TableRow(
  props: TableRowProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const {
    index,
    tableHeaders,
    tableRowData,
    bodyTextColor = '',
    loading = true,
    clickableTableRow = false,
    rowClick,
    classNames = '',
    rowHoverElements = [],
    active,
  } = props
  const isDarkTheme = useTheme('dark')
  const [hoverElementClasses, setHoverElementClasses] = useState('')

  const tableRowStateClasses = (
    clickableTableRow: boolean,
    disabled: boolean,
    loading: boolean,
  ): string => {
    if (disabled || loading) {
      return ' disabled-row'
    } else if (!clickableTableRow) {
      return ' table-row-no-click'
    } else {
      return ''
    }
  }

  const trClasses = (value: any): string => {
    if (value.disabled || tableRowData.loading) {
      return 'mobile-disabled-row'
    } else if (
      handleButtonClick === undefined &&
      tableRowData.buttonElements.length === 0
    ) {
      return `tr-no-click ${isDarkTheme ? 'tr-dark' : 'tr-light'}`
    } else {
      return ''
    }
  }

  const rowClassNames = `table-row${tableRowStateClasses(
    clickableTableRow,
    tableRowData?.disabled,
    loading ?? false,
  )} ${classNames} ${active ? ' row-selected' : ''} ${
    !isDarkTheme ? 'table-row-light' : ''
  }`

  let hoverRowElements = rowHoverElements
  if (
    tableRowData?.rowHoverElements !== undefined &&
    tableRowData.rowHoverElements.length > 0
  ) {
    hoverRowElements = tableRowData.rowHoverElements
  }

  const disabledRow = (): boolean => {
    if (
      (tableRowData?.disabled === undefined ||
        tableRowData?.disabled === false) &&
      loading === false
    ) {
      return false
    }
    return true
  }

  const buttonRow = (rowData: any) => {
    if (hoverRowElements.length === 0 && loading) {
      return <></>
    } else if (hoverRowElements.length >= 0 && loading) {
      return (
        <div className="table-card-buttons">
          <SkeletonBlock />
        </div>
      )
    } else if (hoverRowElements.length <= 2) {
      return (
        <div className="table-card-buttons">
          {hoverRowElements.map((buttonPropValue, index) => {
            return (
              <Button
                label={buttonPropValue.label}
                variant="tertiary"
                disabled={rowData.disabled}
                key={index}
                onClick={(e) => {
                  handleButtonClick(e, buttonPropValue, tableRowData, loading)
                }}
              />
            )
          })}
        </div>
      )
    } else {
      return (
        <div className="table-icon-buttons-row">
          {hoverRowElements.map((buttonPropValue: IconButtonProps, index) => {
            return (
              <div
                role={'none'}
                tabIndex={0}
                className={'table-icon-button ' + trClasses(rowData)}
                key={index}
                onClick={(e) => {
                  handleIconButtonClick(e, buttonPropValue, rowData, loading)
                }}
              >
                <div className="icon-container">
                  {
                    <Icon
                      variant={buttonPropValue.variant}
                      customVariant={buttonPropValue.customVariant}
                      color={
                        buttonPropValue.color || colors.brand.secondary.main
                      }
                      size={buttonPropValue.size || 24}
                    />
                  }
                </div>
              </div>
            )
          })}
        </div>
      )
    }
  }

  return (
    <div
      ref={ref}
      key={index}
      tabIndex={0}
      role="row"
      id={`row-${index}`}
      style={{
        backgroundColor: hoverElementClasses
          ? '#36474c'
          : tableBodyColor(bodyTextColor, isDarkTheme),
      }}
      onFocus={() => {
        !disabledRow() && setHoverElementClasses(' focused')
      }}
      onBlur={() => {
        !disabledRow() && setHoverElementClasses('')
      }}
      onMouseEnter={() => {
        !disabledRow() && setHoverElementClasses(' active')
      }}
      onMouseLeave={() => {
        !disabledRow() && setHoverElementClasses('')
      }}
      onKeyDown={(event) =>
        !disabledRow() &&
        handleKeyDown(tableRowData?.id, tableRowData, event, rowClick)
      }
      className={`${rowClassNames.trimEnd()}`}
      data-testid="table-row"
    >
      <div className="row-content">
        <div
          style={{ display: 'contents' }}
          role="none"
          data-testid="table-row-click-handler"
          onClick={(event) => {
            if (!disabledRow()) {
              event.stopPropagation()
              handleRowClick(tableRowData?.id, tableRowData, event, rowClick)
            }
          }}
        >
          {Object.keys(tableRowData).map((key, keyIndex) => {
            return (
              key !== 'disabled' &&
              tableHeaders.at(keyIndex)?.visible && (
                <TableCell
                  key={keyIndex}
                  tableHeaders={tableHeaders}
                  tableCellValue={
                    tableRowData[`${key}`]?.displayValue !== undefined
                      ? tableRowData[`${key}`]?.displayValue
                      : tableRowData[`${key}`]
                  }
                  disabledCell={tableRowData?.disabled}
                  keyIndex={keyIndex}
                  bodyTextColor={bodyTextColor}
                  loading={loading}
                  hoverRowElements={hoverRowElements as IconButtonProps[]}
                  tableRowData={tableRowData}
                  hoverElementClasses={hoverElementClasses}
                />
              )
            )
          })}
        </div>

        <div className="table-card-button-row">
          {hoverRowElements.length > 0 && buttonRow(tableRowData)}
        </div>
      </div>
    </div>
  )
})

export default TableRow
