import { AppAction, ActionTypes } from '../Types'
import { OktaUser } from '../../../models'
import { SessionStatus } from '../../../config/OktaAuthConfig'

export const setSession = (status: SessionStatus): AppAction => ({
  type: ActionTypes.SET_SESSION,
  status,
})

export const setUser = (user: OktaUser): AppAction => ({
  type: ActionTypes.SET_USER,
  user,
})
