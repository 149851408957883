import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import {
  init as SentryInit,
  browserTracingIntegration,
  captureConsoleIntegration,
  httpClientIntegration,
  replayIntegration,
} from '@sentry/react'
import { ErrorBoundary } from 'react-error-boundary'
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk'
import { createRoot } from 'react-dom/client'
import ThemeProvider from '@mui/material/styles/ThemeProvider'
import { CssBaseline } from '@mui/material'

import { App, Provider } from '@components/App'
import { ErrorFallback } from '@components/App/Errors'
import { Security, UserInit } from '@components/Routes'
import AppWrapper from '@components/Wrappers/AppWrapper'
import { oktaAuthConfig } from '@config/OktaAuthConfig'
import { AnalyticsWrapper } from '@components/Wrappers/AnalyticWrapper'
import { ToastProvider } from '@hooks/useToast'

import { defaultTheme } from './mui'

const sampleRates = {
  production: {
    traceSampleRate: 0.2,
    replaysSessionSampleRate: 0.0,
    replaysOnErrorSampleRate: 1.0,
  },
  //Keeping Staging and Local the same for now until we have a better understanding of the impact
  staging: {
    traceSampleRate: 0.2,
    replaysSessionSampleRate: 0.0,
    replaysOnErrorSampleRate: 0.0,
  },
  local: {
    traceSampleRate: 0.0,
    replaysSessionSampleRate: 0.0,
    replaysOnErrorSampleRate: 0.0,
  },
}

SentryInit({
  dsn: 'https://4972ad37e2ab4dbc9713eb2518d23918@o442593.ingest.sentry.io/6293238',
  environment: process.env.ENV,
  integrations: [
    browserTracingIntegration(),
    captureConsoleIntegration({ levels: ['error'] }),
    httpClientIntegration(),
    replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
      maskAllInputs: true,
    }),
  ],
  ...sampleRates[process.env.ENV || 'local'],
  enabled: process.env.ENV === 'production' || process.env.ENV === 'staging',
})

pendo.initialize({ apiKey: 'd467d24f-dc33-46b6-4232-819e168a2731' })

//? launchdarkly async
;(async () => {
  let launchDarklyKey
  switch (process.env.LAUNCH_DARKLY_ENV) {
    case 'production':
      launchDarklyKey = '632888d7fdf46211c258ad7f'
      break
    case 'staging':
      launchDarklyKey = '636544b1a3ad8811a6022d9e'
      break
    default:
      launchDarklyKey = '632e440d8bfb4b119a46915a'
      break
  }

  const LDProvider = await asyncWithLDProvider({
    clientSideID: launchDarklyKey,
    options: {
      allAttributesPrivate: false,
      privateAttributes: ['email'],
    },
  })

  const root = createRoot(document.getElementById('root'))

  root.render(
    <React.StrictMode>
      <ThemeProvider theme={defaultTheme}>
        <Provider>
          <AnalyticsWrapper>
            <AppWrapper>
              <ErrorBoundary fallbackRender={() => <ErrorFallback />}>
                <LDProvider>
                  <Router>
                    <Security oktaAuth={oktaAuthConfig}>
                      <ToastProvider>
                        <UserInit>
                          <CssBaseline />
                          <App />
                        </UserInit>
                      </ToastProvider>
                    </Security>
                  </Router>
                </LDProvider>
              </ErrorBoundary>
            </AppWrapper>
          </AnalyticsWrapper>
        </Provider>
      </ThemeProvider>
    </React.StrictMode>,
  )
})()
