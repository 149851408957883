export const getCustomVariant = (
  variant: string,
  color: string,
): JSX.Element => {
  let result: JSX.Element = <></>

  switch (variant) {
    case 'resendEmail': {
      result = (
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          data-testid={variant ? `icon-${variant}` : ''}
        >
          <path
            d="M4.5 7.5L11.25 12.75L18 7.5"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.25 6.375C2.25 5.75368 2.75368 5.25 3.375 5.25H19.125C19.7463 5.25 20.25 5.75368 20.25 6.375V10.415C20.7751 10.5108 21.2778 10.6704 21.75 10.8856V6.375C21.75 4.92525 20.5747 3.75 19.125 3.75H3.375C1.92525 3.75 0.75 4.92525 0.75 6.375V17.625C0.75 19.0747 1.92525 20.25 3.375 20.25H13.7151C13.4122 19.788 13.1672 19.2845 12.9906 18.75H3.375C2.75368 18.75 2.25 18.2463 2.25 17.625V6.375Z"
            fill={color}
          />
          <g>
            <path
              d="M15.3664 16.5441C15.0062 16.5022 14.675 16.7613 14.6877 17.1237C14.7135 17.8569 14.9245 18.5755 15.3059 19.2106C15.8005 20.0343 16.554 20.671 17.4486 21.0215C18.3432 21.372 19.3287 21.4164 20.2512 21.1479C21.1737 20.8794 21.9814 20.3131 22.5482 19.5373C23.1151 18.7615 23.4091 17.8198 23.3845 16.8593C23.3598 15.8988 23.0179 14.9735 22.4121 14.2278C21.8063 13.482 20.9706 12.9578 20.0355 12.7369C19.3146 12.5666 18.5659 12.5841 17.8595 12.7824C17.5104 12.8804 17.3642 13.2747 17.5136 13.6051C17.663 13.9356 18.0522 14.0745 18.407 13.9994C18.8423 13.9074 19.2954 13.9115 19.7336 14.015C20.3865 14.1692 20.9698 14.5352 21.3928 15.0558C21.8157 15.5764 22.0544 16.2224 22.0716 16.893C22.0888 17.5635 21.8835 18.2209 21.4878 18.7625C21.0921 19.3042 20.5282 19.6995 19.8842 19.887C19.2402 20.0744 18.5522 20.0434 17.9276 19.7987C17.3031 19.554 16.7771 19.1095 16.4318 18.5344C16.1999 18.1484 16.0582 17.718 16.0136 17.2753C15.9773 16.9145 15.7267 16.586 15.3664 16.5441Z"
              fill={color}
            />
            <path
              d="M19.0515 11.5294L17.1323 13.4243L19.0515 15.3192"
              stroke={color}
              strokeWidth="1.25"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_3553_137069">
              <rect
                width="12"
                height="12"
                fill="white"
                transform="matrix(-1 0 0 1 25 10.125)"
              />
            </clipPath>
          </defs>
        </svg>
      )

      break
    }
    case 'customerAdvisoryUnread': {
      result = (
        <svg
          viewBox="0 0 22 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          data-testid={variant ? `icon-${variant}` : ''}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.63826 1.38916H15.3968C16.136 1.38997 16.8447 1.68398 17.3674 2.20668C17.8902 2.72939 18.1842 3.4381 18.185 4.17732V11.0986C17.2275 10.4925 16.0924 10.1416 14.8754 10.1416C13.2192 10.1416 11.7148 10.7914 10.6032 11.85C10.5749 11.8465 10.5463 11.8448 10.5175 11.8448H7.03234C6.84747 11.8448 6.67017 11.9182 6.53945 12.0489C6.40873 12.1796 6.3353 12.3569 6.3353 12.5418C6.3353 12.7267 6.40873 12.904 6.53945 13.0347C6.67017 13.1654 6.84747 13.2388 7.03234 13.2388H9.50883C8.98139 14.1503 8.6795 15.2087 8.6795 16.3375C8.6795 18.146 9.45434 19.7736 10.6902 20.9063H5.63826C4.89904 20.9055 4.19033 20.6115 3.66762 20.0887C3.14492 19.566 2.8509 18.8573 2.8501 18.1181V4.17732C2.8509 3.4381 3.14492 2.72939 3.66762 2.20668C4.19033 1.68398 4.89904 1.38997 5.63826 1.38916ZM7.03234 9.75364H14.0027C14.1876 9.75364 14.3649 9.6802 14.4956 9.54948C14.6263 9.41876 14.6998 9.24146 14.6998 9.0566C14.6998 8.87173 14.6263 8.69444 14.4956 8.56371C14.3649 8.43299 14.1876 8.35956 14.0027 8.35956H7.03234C6.84747 8.35956 6.67017 8.43299 6.53945 8.56371C6.40873 8.69444 6.3353 8.87173 6.3353 9.0566C6.3353 9.24146 6.40873 9.41876 6.53945 9.54948C6.67017 9.6802 6.84747 9.75364 7.03234 9.75364ZM7.03234 6.26844H14.0027C14.1876 6.26844 14.3649 6.195 14.4956 6.06428C14.6263 5.93356 14.6998 5.75626 14.6998 5.5714C14.6998 5.38653 14.6263 5.20924 14.4956 5.07852C14.3649 4.9478 14.1876 4.87436 14.0027 4.87436H7.03234C6.84747 4.87436 6.67017 4.9478 6.53945 5.07852C6.40873 5.20924 6.3353 5.38653 6.3353 5.5714C6.3353 5.75626 6.40873 5.93356 6.53945 6.06428C6.67017 6.195 6.84747 6.26844 7.03234 6.26844Z"
            fill="currentcolor"
          />
          <path
            d="M19.5224 16.3376C19.5224 13.7721 17.4409 11.6907 14.8754 11.6907C12.31 11.6907 10.2285 13.7721 10.2285 16.3376C10.2285 18.9031 12.31 20.9845 14.8754 20.9845C17.4409 20.9845 19.5224 18.9031 19.5224 16.3376Z"
            stroke="currentcolor"
            strokeMiterlimit="10"
          />
          <path
            d="M14.7365 14.1606L14.8754 17.1121L15.0141 14.1606C15.0149 14.1417 15.0119 14.1229 15.0053 14.1052C14.9986 14.0876 14.9884 14.0714 14.9753 14.0578C14.9621 14.0442 14.9464 14.0335 14.929 14.0262C14.9116 14.0189 14.8928 14.0152 14.8739 14.0154V14.0154C14.8553 14.0156 14.8368 14.0195 14.8197 14.027C14.8026 14.0344 14.7872 14.0452 14.7744 14.0588C14.7615 14.0724 14.7516 14.0884 14.7451 14.1058C14.7385 14.1233 14.7356 14.142 14.7365 14.1606V14.1606Z"
            stroke="currentcolor"
            strokeWidth="0.774489"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14.8757 19.046C14.7799 19.046 14.6863 19.0176 14.6067 18.9644C14.5271 18.9112 14.4651 18.8356 14.4284 18.7472C14.3918 18.6587 14.3822 18.5614 14.4009 18.4675C14.4196 18.3736 14.4657 18.2874 14.5334 18.2197C14.6011 18.152 14.6873 18.1059 14.7812 18.0872C14.8751 18.0685 14.9724 18.0781 15.0609 18.1147C15.1493 18.1514 15.2249 18.2134 15.2781 18.293C15.3313 18.3726 15.3597 18.4662 15.3597 18.5619C15.3597 18.6903 15.3087 18.8134 15.2179 18.9042C15.1272 18.995 15.004 19.046 14.8757 19.046Z"
            fill="currentcolor"
          />
        </svg>
      )

      break
    }
    case 'customerAdvisoryRead': {
      result = (
        <svg
          viewBox="0 0 22 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          data-testid={variant ? `icon-${variant}` : ''}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.34848 2.216C4.9655 2.216 4.59822 2.36814 4.32741 2.63894C4.05661 2.90974 3.90448 3.27703 3.90448 3.66V17.34C3.90448 17.723 4.05661 18.0903 4.32741 18.3611C4.59822 18.6319 4.9655 18.784 5.34848 18.784H9.9731C10.2818 19.237 10.6502 19.6461 11.0668 20H5.34848C4.643 20 3.96642 19.7198 3.46757 19.2209C2.96873 18.7221 2.68848 18.0455 2.68848 17.34V3.66C2.68848 2.95452 2.96873 2.27794 3.46757 1.7791C3.96642 1.28025 4.643 1 5.34848 1H9.57004H9.57013C10.094 1.00008 10.5964 1.2082 10.9669 1.5786L10.967 1.57865L17.0058 7.61751L17.0059 7.61756C17.3763 7.98805 17.5844 8.49046 17.5845 9.01435L17.5845 9.01444V9.85876C17.1995 9.67778 16.7925 9.53598 16.3685 9.43852V9.056H11.5045C10.9804 9.056 10.4778 8.84782 10.1072 8.47724C9.73666 8.10667 9.52848 7.60407 9.52848 7.08V4.63993V4.63993V2.216H5.34848ZM10.5149 11.26H6.71648C6.38069 11.26 6.10848 11.5322 6.10848 11.868C6.10848 12.2038 6.38069 12.476 6.71648 12.476H9.64919C9.88767 12.035 10.1791 11.6268 10.5149 11.26ZM8.95885 14.68H6.71648C6.38069 14.68 6.10848 14.9522 6.10848 15.288C6.10848 15.6238 6.38069 15.896 6.71648 15.896H8.94374C8.92855 15.7207 8.92079 15.5433 8.92079 15.3641C8.92079 15.1328 8.93371 14.9046 8.95885 14.68ZM15.0469 7.84H15.5086L10.7445 3.07584V5.34507V7.08C10.7445 7.28156 10.8245 7.47487 10.9671 7.6174C11.1096 7.75993 11.3029 7.84 11.5045 7.84H15.0469L10.7445 5.34507L15.0469 7.84Z"
            fill="currentcolor"
          />
          <path
            d="M19.7727 15.5448C19.7727 12.9957 17.7045 10.9275 15.1554 10.9275C12.6063 10.9275 10.5381 12.9957 10.5381 15.5448C10.5381 18.0939 12.6063 20.1621 15.1554 20.1621C17.7045 20.1621 19.7727 18.0939 19.7727 15.5448Z"
            stroke="currentcolor"
            strokeMiterlimit="10"
          />
          <path
            d="M15.0172 13.3818L15.1553 16.3146L15.2931 13.3818C15.2939 13.3631 15.2909 13.3444 15.2843 13.3268C15.2777 13.3093 15.2675 13.2932 15.2545 13.2797C15.2415 13.2662 15.2258 13.2555 15.2085 13.2483C15.1912 13.241 15.1726 13.2374 15.1538 13.2376V13.2376C15.1353 13.2377 15.117 13.2416 15.1 13.2491C15.083 13.2565 15.0676 13.2672 15.0549 13.2807C15.0421 13.2942 15.0322 13.3101 15.0258 13.3274C15.0193 13.3448 15.0164 13.3633 15.0172 13.3818V13.3818Z"
            stroke="currentcolor"
            strokeWidth="0.769552"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15.1553 18.2361C15.0602 18.2361 14.9672 18.2079 14.8881 18.1551C14.809 18.1022 14.7473 18.0271 14.7109 17.9392C14.6745 17.8513 14.665 17.7546 14.6836 17.6613C14.7021 17.568 14.7479 17.4823 14.8152 17.415C14.8825 17.3478 14.9682 17.302 15.0615 17.2834C15.1548 17.2649 15.2515 17.2744 15.3393 17.3108C15.4272 17.3472 15.5023 17.4088 15.5552 17.4879C15.608 17.567 15.6363 17.66 15.6363 17.7551C15.6363 17.8827 15.5856 18.005 15.4954 18.0952C15.4052 18.1854 15.2828 18.2361 15.1553 18.2361Z"
            fill="currentcolor"
          />
        </svg>
      )

      break
    }
    case 'significanCyberEventUnread': {
      result = (
        <svg
          viewBox="0 0 22 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          data-testid={variant ? `icon-${variant}` : ''}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.63923 1.38916H15.3978C16.137 1.38997 16.8457 1.68398 17.3684 2.20668C17.8911 2.72939 18.1851 3.4381 18.1859 4.17732V11.3969C17.2348 10.8365 16.126 10.5151 14.9422 10.5151C13.4074 10.5151 11.9989 11.0553 10.8962 11.956C10.7845 11.8839 10.6535 11.8448 10.5185 11.8448H7.03331C6.84845 11.8448 6.67115 11.9182 6.54043 12.0489C6.40971 12.1796 6.33627 12.3569 6.33627 12.5418C6.33627 12.7267 6.40971 12.904 6.54043 13.0347C6.67115 13.1654 6.84845 13.2388 7.03331 13.2388H9.70271C8.97146 14.2791 8.54216 15.547 8.54216 16.9151C8.54216 18.4246 9.06471 19.812 9.93879 20.9063H5.63923C4.90001 20.9055 4.1913 20.6115 3.6686 20.0887C3.14589 19.566 2.85188 18.8573 2.85107 18.1181V4.17732C2.85188 3.4381 3.14589 2.72939 3.6686 2.20668C4.1913 1.68398 4.90001 1.38997 5.63923 1.38916ZM7.03331 9.75364H14.0037C14.1886 9.75364 14.3659 9.6802 14.4966 9.54948C14.6273 9.41876 14.7007 9.24146 14.7007 9.0566C14.7007 8.87173 14.6273 8.69444 14.4966 8.56371C14.3659 8.43299 14.1886 8.35956 14.0037 8.35956H7.03331C6.84845 8.35956 6.67115 8.43299 6.54043 8.56371C6.40971 8.69444 6.33627 8.87173 6.33627 9.0566C6.33627 9.24146 6.40971 9.41876 6.54043 9.54948C6.67115 9.6802 6.84845 9.75364 7.03331 9.75364ZM7.03331 6.26844H14.0037C14.1886 6.26844 14.3659 6.195 14.4966 6.06428C14.6273 5.93356 14.7007 5.75626 14.7007 5.5714C14.7007 5.38653 14.6273 5.20924 14.4966 5.07852C14.3659 4.9478 14.1886 4.87436 14.0037 4.87436H7.03331C6.84845 4.87436 6.67115 4.9478 6.54043 5.07852C6.40971 5.20924 6.33627 5.38653 6.33627 5.5714C6.33627 5.75626 6.40971 5.93356 6.54043 6.06428C6.67115 6.195 6.84845 6.26844 7.03331 6.26844Z"
            fill="currentcolor"
          />
          <g clipPath="url(#clip0_4154_89340)">
            <path
              d="M18.5006 15.2821C18.2016 14.4885 17.7658 13.7535 17.2128 13.1105C16.2559 12.0028 14.9976 11.2874 14.0071 11.2874C13.9482 11.2874 13.8901 11.3009 13.8372 11.3269C13.7844 11.3528 13.7381 11.3905 13.7021 11.4371C13.666 11.4837 13.6411 11.5379 13.6293 11.5956C13.6174 11.6533 13.6189 11.7129 13.6336 11.7699C13.9682 13.0487 13.2745 14.1121 12.5403 15.2377C11.932 16.17 11.3047 17.133 11.3047 18.2364C11.3047 20.365 13.0366 22.097 15.1653 22.097C17.2939 22.097 19.0258 20.365 19.0258 18.2364C19.0258 17.1916 18.8492 16.1978 18.5006 15.2821ZM15.9453 20.6177C15.6961 20.8831 15.3836 20.9388 15.1653 20.9388C14.9469 20.9388 14.6344 20.8831 14.3852 20.6177C14.1359 20.3522 14.0071 19.9312 14.0071 19.3946C14.0071 18.788 14.2199 18.3262 14.4255 17.8798C14.5449 17.6206 14.6668 17.3569 14.7392 17.0746C14.7468 17.0439 14.762 17.0156 14.7832 16.9921C14.8044 16.9686 14.8311 16.9507 14.8609 16.9399C14.8906 16.9292 14.9226 16.9259 14.9539 16.9304C14.9852 16.9349 15.015 16.9471 15.0405 16.9658C15.2612 17.1365 15.4574 17.3367 15.6237 17.5608C16.0621 18.1399 16.3234 18.8256 16.3234 19.3946C16.3234 19.9312 16.1927 20.3542 15.9453 20.6177Z"
              fill="currentcolor"
            />
          </g>
          <defs>
            <clipPath id="clip0_4154_89340">
              <rect
                width="12.3539"
                height="12.3539"
                fill="currentcolor"
                transform="translate(8.98828 10.5151)"
              />
            </clipPath>
          </defs>
        </svg>
      )

      break
    }
    case 'significanCyberEventRead': {
      result = (
        <svg
          width="22"
          height="24"
          viewBox="0 0 22 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          data-testid={variant ? `icon-${variant}` : ''}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.45992 3.46937C5.07658 3.46937 4.70894 3.62165 4.43788 3.89271C4.16681 4.16377 4.01453 4.53141 4.01453 4.91475V18.6079C4.01453 18.9912 4.16681 19.3589 4.43788 19.6299C4.70894 19.901 5.07658 20.0533 5.45992 20.0533H9.7103C9.99324 20.4994 10.3299 20.9082 10.7111 21.2704H5.45992C4.75376 21.2704 4.07653 20.9899 3.57721 20.4906C3.07788 19.9913 2.79736 19.314 2.79736 18.6079V4.91475C2.79736 4.2086 3.07788 3.53137 3.57721 3.03204C4.07653 2.53272 4.75376 2.2522 5.45992 2.2522H9.68553H9.68563C10.21 2.25228 10.7129 2.46059 11.0838 2.83135L11.0838 2.8314L17.1285 8.87607L17.1285 8.87611C17.4993 9.24696 17.7076 9.74985 17.7077 10.2742V10.2743V10.7743C17.3206 10.603 16.9134 10.4689 16.4905 10.3766V10.3159H16.1744C15.8312 10.259 15.4788 10.2294 15.1195 10.2294C14.7602 10.2294 14.4078 10.259 14.0646 10.3159H11.6218C11.0973 10.3159 10.5942 10.1075 10.2232 9.73662C9.85232 9.36569 9.64393 8.86261 9.64393 8.33804V3.46937H5.45992ZM10.2109 12.5221H6.82923C6.49312 12.5221 6.22065 12.7945 6.22065 13.1306C6.22065 13.4667 6.49312 13.7392 6.82923 13.7392H9.40736C9.62893 13.3023 9.89916 12.8942 10.2109 12.5221ZM8.7551 15.9453H6.82923C6.49312 15.9453 6.22065 16.2178 6.22065 16.5539C6.22065 16.89 6.49312 17.1625 6.82923 17.1625H8.74074C8.72628 16.9869 8.7189 16.8093 8.7189 16.63C8.7189 16.3987 8.73117 16.1703 8.7551 15.9453ZM10.8611 4.33003L15.6298 9.09877H11.6218C11.4201 9.09877 11.2266 9.01862 11.0839 8.87595C10.9412 8.73329 10.8611 8.53979 10.8611 8.33804V4.33003Z"
            fill="currentcolor"
          />
          <path
            d="M18.4052 15.2405C18.1107 14.4586 17.6813 13.7344 17.1364 13.101C16.1936 12.0096 14.9539 11.3047 13.978 11.3047C13.92 11.3047 13.8627 11.318 13.8106 11.3436C13.7585 11.3692 13.713 11.4064 13.6775 11.4522C13.642 11.4981 13.6174 11.5515 13.6057 11.6084C13.594 11.6652 13.5955 11.724 13.61 11.7801C13.9397 13.0401 13.2562 14.0878 12.5328 15.1968C11.9335 16.1154 11.3154 17.0641 11.3154 18.1513C11.3154 20.2485 13.0218 21.9549 15.1191 21.9549C17.2163 21.9549 18.9227 20.2485 18.9227 18.1513C18.9227 17.1219 18.7487 16.1427 18.4052 15.2405ZM15.8877 20.4974C15.6421 20.7589 15.3342 20.8138 15.1191 20.8138C14.9039 20.8138 14.5961 20.7589 14.3505 20.4974C14.1049 20.2359 13.978 19.8211 13.978 19.2924C13.978 18.6947 14.1877 18.2397 14.3902 17.7999C14.5079 17.5446 14.6279 17.2847 14.6993 17.0066C14.7068 16.9763 14.7217 16.9484 14.7426 16.9253C14.7635 16.9021 14.7898 16.8845 14.8192 16.8739C14.8485 16.8633 14.88 16.8601 14.9108 16.8645C14.9417 16.869 14.971 16.8809 14.9962 16.8994C15.2136 17.0676 15.4069 17.2649 15.5708 17.4856C16.0027 18.0562 16.2602 18.7318 16.2602 19.2924C16.2602 19.8211 16.1313 20.2378 15.8877 20.4974Z"
            fill="currentcolor"
          />
        </svg>
      )

      break
    }
  }

  return result
}
