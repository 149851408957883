import {
  SelectedFiltersAgeEnum,
  SelectedFiltersLastUpdatedEnum,
} from '@components/Tickets/TicketLibrary/TicketFilters/TicketFilter.types'

import { FilterOptionValues, FilterOptions } from './Filters'

export type TicketRelatedUnion = Ticket | TicketingRelatedCase

export interface TicketingRelatedCase {
  sysId: string
  splunkSearch: string
  lastUpdateUser: string
  assignedUser: string | null
  assignedUserEmail: string | null
  customerValidateState: string | null
  module: string | null
  serviceOffering: string | null
  category: string | null
  subcategory: string | null
  useCaseTitle: string
  useCase: string
  cisControl: string
  mitreTactics: string
  mitreTechniques: string
  splunkLink: string
  shortDescription: string
  comments: TicketingComment[] | null
  openedBy: string | null
  createdDate: string
  ticketNumber: string
  description: string
  priority: string
  state: string
  sysCreatedOn: string
  sysUpdatedOn: string
  type: string
  incidentType: string | null
  agentType: string | null
}
export interface Ticket {
  sysId: string
  type: string
  ticketNumber: string
  assignedUser: string | null
  assignedUserEmail: string | null
  assignedToDeepwatch: boolean
  assignmentGroup: string
  impact: string
  priority: TicketPriorityEnum
  state: string
  urgency: string
  customerValidateState: string | null
  module: string | null
  serviceOffering: string | null
  category: string | null
  subcategory: string | null
  channel: string | null
  caseType: string | null
  shortDescription: string
  description: string
  openedBy: string | null
  searchName: string
  useCase: string // Search ID
  useCaseTitle: string
  incidentType: string | null
  cisControl: string
  mitreTactics: string
  mitreTechniques: string
  splunkLink: string
  splunkSearch: string
  resolutionNotes: string | null
  resolutionBy: string | null
  resolutionAt: string | null
  resolutionCode: string | null
  timeToCloseSec: number | null
  timeToCustomerAcknowledgeSec: number | null
  timeToCustomerAssignSec: number | null
  timeToCustomerValidateSec: number | null
  timeToDetectionSec: number | null
  timeToDeclaredIncidentSec: number | null
  timeToDetectResolveAlertSec: number | null
  timeToInvestigateSec: number | null
  timeToNotifySec: number | null
  timeToRespondSec: number | null
  timeToResolveTicketSec: number | null
  timeToUpdateSec: number | null
  totalTimeDeepwatchWaitCustomerSec: number | null
  totalTimeDeepwatchWaitVendorSec: number | null
  relatedCases: TicketingRelatedCase[]
  sysCreatedOn: string
  sysUpdatedOn: string
  lastUpdateUser: string
  servicenowLink: string
  createdDate: string
  comments: TicketingComment[] | null
  contactUser: string | null
  closedAt: string
  agentType: string | null
  __typename?: string
}

export enum TicketStateEnum {
  InProgressCustomer = 'In Progress - Customer',
  InProgressDW = 'In Progress - dw',
  InProgressVendor = 'In Progress - Vendor',
  New = 'New',
  OnHold = 'On-Hold',
  Resolved = 'Resolved',
  Cancelled = 'Cancelled',
  Closed = 'Closed',
}

export enum TicketPriorityEnum {
  Critical = 'Critical',
  High = 'High',
  Moderate = 'Moderate',
  Low = 'Low',
  Informational = 'Informational',
}

export enum TicketAssingmentGroup {
  Security = 'dw-Squad-Analysts',
}

export enum CrowdStrikeTicketPriorityEnum {
  Critical = 'Critical',
  High = 'High',
  Medium = 'Medium',
  Low = 'Low',
  Informational = 'Informational',
}

export enum TicketTypeEnum {
  Security = 'Security',
  Engineering = 'Engineering',
}

export enum TicketModuleEnum {
  EDR = 'EDR',
  FW = 'FW',
  MDR = 'MDR',
  NG_MEDR = 'NG-MEDR',
  THREAT_SIGNAL = 'Threat Signal',
  VM = 'VM',
}

export enum TicketStatsAggregationOptions {
  INCIDENT_GROUP = 'INCIDENT_GROUP',
  SERVICE = 'SERVICE',
  TICKET_PRIORITY = 'TICKET_PRIORITY',
  TICKET_STATUS = 'TICKET_STATUS',
  ASSIGNMENT_GROUP = 'ASSIGNMENT_GROUP',
}

export enum TicketModuleOffering {
  MDR_ANALYTICS = 'MDR Analytics',
  MDR_ENGINEERING = 'MDR Engineering',
  VM = 'VM',
  FW = 'FW',
  EDR = 'EDR',
  MDR_ESSENTIALS = 'MDR Essentials',
  NG_MEDR = 'NG-MEDR',
  THREAT_SIGNAL = 'Threat Signal',
}

export enum DetailedTicketType {
  SECURITY = 'Security',
  OPS_ENGINEERING = 'Ops Engineering',
  DETECTION_ENGINEERING = 'Detection Engineering',
  THREAT_HUNT = 'Threat Hunt',
}

export const TICKET_STATE_OPEN = [
  TicketStateEnum.New,
  TicketStateEnum.InProgressCustomer,
  TicketStateEnum.InProgressDW,
  TicketStateEnum.InProgressVendor,
  TicketStateEnum.OnHold,
]

export interface TicketingComment {
  text: string
  time: string
  type: string
  user: string
  internalUser: boolean
}

export interface FormatTicketLibraryTableProps
  extends Pick<Ticket, 'state' | 'type'> {
  id: string
  shortDescription: Record<string, string | JSX.Element>
  priority: Record<string, string | JSX.Element>
  sysCreatedOn: Record<string, string | JSX.Element>
  sysUpdatedOn: Record<string, string | JSX.Element>
}

export interface TicketFilterInput {
  priority: TicketPriorityEnum[]
  state: TicketStateEnum[]
  type: TicketTypeEnum[]
  module: TicketModuleEnum[]
  assignmentGroup: string[]
  sysCreatedOn: SelectedFiltersAgeEnum[]
  sysUpdatedOn: SelectedFiltersLastUpdatedEnum[]
  useCase: string[]
}

export interface PaginationInput {
  limit: number
  offset: number
}

export interface Pagination extends PaginationInput {
  total: number
}

export interface TicketSortingInput {
  sysCreatedOn?: string
  priority?: string
  state?: string
  sysUpdatedOn?: string
  assignmentGroup?: string
  ticketNumber?: string
  type?: string
}

export interface SortingOptions {
  key: string
  order: string
}

export interface TicketData {
  tickets: Ticket[]
  pagination: Pagination
  filterOptions: FilterOptions<FilterOptionValues[]>
  sortingOptions: SortingOptions[]
}

export interface TicketEditEnum {
  label: string
  value: number
  sequence: number | null
}

export interface TicketEditSettingsData {
  enabled: boolean
  enumValues: {
    priority: TicketEditEnum[]
    urgency: TicketEditEnum[]
    state: TicketEditEnum[]
    resolutionCode: TicketEditEnum[]
    customerValidationState: TicketEditEnum[]
  }
}
