/* eslint-disable security/detect-object-injection */
import { useState } from 'react'
import { Box, Chip, useTheme } from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { SideSheet } from '@common/SideSheet'
import { formatChipLabel } from '@common/FilterChips'
import Icon from '@common/Icon'
import { useSearchParamColumnFilters } from '@hooks/tableHooks/useSearchParamColumnFilters'
import { useEDRContent } from '@hooks/useEDRContent'
import { useAwaitedCustomer } from '@hooks/useCustomer'

import EDRAssetTable from './EDRAssetTable'
import EDRAssetFilterSideSheet from './EDRAssetFilterSideSheet'
import { EDRDataUnavailable } from '../EDRDataNotAvailable'

import type { EDRAssetFiltersForm } from '../edrTypes'

const EDRAssetLibraryContainer: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <Box
    data-testid="edr-asset-library"
    sx={{ height: 'calc(100% - 118px)', padding: '1rem', width: '100%' }}
  >
    {children}
  </Box>
)

const EDRAssetLibrary = () => {
  const theme = useTheme()
  const customer = useAwaitedCustomer()
  const { featureDefenderForEndpoint } = useFlags()
  const { assetTableFilterMapping } = useEDRContent()
  const {
    columnFilters: filters,
    setColumnFilters,
    removeColumnFilter,
  } = useSearchParamColumnFilters({
    hostname: [],
    lastReboot: [],
    lastSeen: [],
    operatingSystem: [],
    platform: [],
    rfmStatus: [],
    status: [],
  } as EDRAssetFiltersForm)

  const [showFilterSideSheet, setShowFilterSideSheet] = useState(false)

  const toggleFilterSideSheet = () => {
    setShowFilterSideSheet(!showFilterSideSheet)
  }

  const handleFilterChange = (values: EDRAssetFiltersForm) => {
    setColumnFilters(values)
  }

  const deleteChip = (
    id: keyof EDRAssetFiltersForm,
    val: EDRAssetFiltersForm[typeof id][number],
  ) => {
    removeColumnFilter(id, val)
  }

  const renderChips = () => {
    const chips: JSX.Element[] = []

    ;(Object.keys(filters) as Array<keyof typeof filters>).forEach((key) => {
      if (filters[key].length > 0) {
        const filterValues = filters[key]
        const transformValueFunction =
          assetTableFilterMapping[key]?.transformValueForDisplay
        const transformedKey = assetTableFilterMapping[key]?.label ?? key

        filterValues.forEach((val: string) => {
          const transformedValue = transformValueFunction
            ? transformValueFunction(val)
            : val
          chips.push(
            <Chip
              data-testid={`filter-chip-edr-asset`}
              label={formatChipLabel(
                featureDefenderForEndpoint ? transformedKey : key,
                featureDefenderForEndpoint ? transformedValue : val,
              )}
              onDelete={() => deleteChip(key, val)}
              deleteIcon={
                <div>
                  <Icon
                    variant="closeCircle"
                    size={20}
                    sx={{ color: theme.palette.secondary.dark }}
                  />
                </div>
              }
            />,
          )
        })
      }
    })

    return chips
  }

  if (featureDefenderForEndpoint && !customer.hasEdrAssets) {
    return (
      <EDRAssetLibraryContainer>
        <EDRDataUnavailable />
      </EDRAssetLibraryContainer>
    )
  }

  return (
    <EDRAssetLibraryContainer>
      <SideSheet
        open={showFilterSideSheet}
        title="Filtering options"
        closeSideSheet={toggleFilterSideSheet}
      >
        <EDRAssetFilterSideSheet
          selectedFilters={filters}
          handleFilterChange={handleFilterChange}
          toggleSidesheet={toggleFilterSideSheet}
        />
      </SideSheet>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          gap: '0.5rem',
        }}
      >
        {renderChips()}
      </Box>

      <EDRAssetTable
        filters={filters}
        showFilterSidesheet={toggleFilterSideSheet}
      />
    </EDRAssetLibraryContainer>
  )
}

export default EDRAssetLibrary
