import { useContext, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'

import {
  Button,
  ListItemType,
  Popover,
  Tabs,
  Typography,
  colors,
} from '../../../design-system'
import { Context, setSession, setDwExpertsActiveTab, setUser } from '../../App'
import { oktaAuthConfig, SessionStatus } from '../../../config/OktaAuthConfig'
import { getAccessGroups } from '../../../utils/GetTokens'
import { deleteCookie } from '../../../utils/Cookies'
import { Paths } from '../../App/Types'
import deepwatchLogoDark from '../../../assets/deepwatch-logo-dark.svg'

import './Header.scss'

const tabData = [
  {
    active: true,
    id: 0,
    notification: false,
    text: 'My Home',
  },
]

const Header = () => {
  const {
    dispatch,
    state: {
      dwExpertsCustomer: { customerName, customerShortName },
      user: { firstName, lastName, isAdmin, isDWEmployee },
      customer,
      dwExpertsActiveTab,
    },
  } = useContext(Context)
  const [currentTabs, setCurrentTabs] = useState(tabData)
  const [formShown, setFormShown] = useState<boolean>(false)
  const location = useLocation()
  const navigate = useNavigate()

  const { mobileNavigationHelpButton, featureSettings } = useFlags()

  const handleForm = () => {
    setFormShown(!formShown)
  }

  useEffect(() => {
    if (isDWEmployee && customerName !== undefined && customerName.length > 0) {
      setCurrentTabs([
        {
          ...tabData[0],
        },
        {
          id: 1,
          active: true,
          notification: false,
          text: customerName,
        },
      ])
      dispatch(setDwExpertsActiveTab(1))
    } else {
      dispatch(setDwExpertsActiveTab(0))
    }
  }, [customerName, dispatch, isDWEmployee])

  const [windowWidth, setWindowWidth] = useState(0)
  const resizeWindow = () => {
    setWindowWidth(window.innerWidth)
  }
  useEffect(() => {
    resizeWindow()
    window.addEventListener('resize', resizeWindow)
    return () => window.removeEventListener('resize', resizeWindow)
  }, [windowWidth])

  const onTabClick = (id: number) => {
    dispatch(setDwExpertsActiveTab(id))
    if (id === 0) {
      navigate(Paths.DEEPWATCH_EXPERTS)
    } else if (id === 1) {
      navigate(`${Paths.DASHBOARD}?customer=${customerShortName}`)
    }
  }

  const avatarType = () => {
    const group = getAccessGroups()
    const groupAppend = group.at(0) as unknown as string
    const userType =
      groupAppend?.substring(0, groupAppend?.indexOf('-')) || 'dw'
    return userType
  }

  const signOut = () => {
    oktaAuthConfig.closeSession()
    dispatch(setSession(SessionStatus.INACTIVE))
    dispatch(
      setUser({
        id: '',
        email: '',
        firstName: '',
        lastName: '',
        username: '',
        isDWEmployee: false,
        isAdmin: false,
      }),
    )
    deleteCookie('iav')
    navigate(Paths.LOGIN)
  }

  const popoverItems: ListItemType[] = []

  if (isDWEmployee === false) {
    popoverItems.push({
      cursor: 'default',
      id: popoverItems.length,
      name: customer.customerName,
      textColor: colors.util.one.light,
    })

    // provide route if feature flag is on
    // if off, check that user is admin
    if (featureSettings || isAdmin) {
      popoverItems.push({
        id: popoverItems.length,
        leftIcon: 'cogOutline',
        name: 'Account Settings',
        iconColor: colors.common.white,
        onClick: () => navigate(Paths.SETTINGS),
      })
    }
  }

  popoverItems.push({
    id: popoverItems.length,
    leftIcon: 'documentTextOutline',
    name: 'Legal',
    iconColor: colors.common.white,
    rightIcon: 'openOutline',
    onClick: () => window.open('https://legal.deepwatch.com/', '_blank'),
  })

  popoverItems.push({
    id: popoverItems.length,
    leftIcon: 'logOutOutline',
    name: 'Log out',
    iconColor: colors.util.two.light,
    textColor: colors.util.two.light,
    onClick: () => signOut(),
  })

  const initial = `${firstName?.charAt(0)}` + `${lastName?.charAt(0)}`

  const resetActiveDwExpertTab = () => {
    if (!isDWEmployee) return

    dispatch(setDwExpertsActiveTab(0))
  }

  const homePathValue = isDWEmployee ? Paths.DEEPWATCH_EXPERTS : Paths.ROOT

  return (
    <header id="page-header">
      {location.pathname === Paths.DASHBOARD && (
        <Typography
          component="h1"
          color={colors.util.one.light}
          size={24}
          weight={700}
        >
          Dashboard
        </Typography>
      )}
      {location.pathname === Paths.INSIGHTS && (
        <Typography
          component="h1"
          color={colors.util.one.light}
          size={24}
          weight={700}
        >
          Insights
        </Typography>
      )}
      {location.pathname === Paths.DETECTION_COVERAGE && (
        <Typography
          component="h1"
          color={colors.util.one.light}
          size={24}
          weight={700}
        >
          Detection Coverage
        </Typography>
      )}
      {windowWidth <= 559 && mobileNavigationHelpButton ? (
        <div className="helper-popover">
          <Popover
            variant="menu"
            options={[
              {
                id: 'submitBug',
                leftIcon: 'bugOutline',
                rightIcon: 'openOutline',
                name: 'Submit a bug!',
                iconColor: colors.common.white,
                onClick: () => {
                  handleForm()
                },
              },
              {
                id: 'submitIdea',
                leftIcon: 'bulbOutline',
                rightIcon: 'openOutline',
                name: 'Submit an idea!',
                iconColor: colors.common.white,
                onClick: () =>
                  window.open(
                    'https://dwportal.ideas.aha.io/portal_session/new',
                    '_blank',
                  ),
              },
            ]}
          >
            <>
              <Button
                variant="icon"
                iconProps={{
                  variant: 'helpOutline',
                }}
                buttonStyles={{
                  backgroundColor: 'transparent',
                  color: colors.util.navy[600],
                  margin: '0 1.5rem',
                  outline: 'none',
                  userSelect: 'none',
                }}
                iconStyles={{
                  backgroundColor: '#A2BBC3',
                  borderRadius: '50%',
                }}
              >
                help
              </Button>
            </>
          </Popover>
        </div>
      ) : (
        <Link
          to={homePathValue}
          onClick={() => resetActiveDwExpertTab()}
          className="deepwatch-logo-link"
        >
          <img
            src={deepwatchLogoDark}
            alt="Deepwatch logo"
            className="deepwatch-logo"
          />
        </Link>
      )}
      {isDWEmployee && (
        <div className="header-tabs">
          <Tabs
            tabStyles={{ padding: '21px 10px' }}
            onTabClick={onTabClick}
            data={currentTabs}
            activeTab={
              location.pathname === Paths.DEEPWATCH_EXPERTS
                ? 0
                : dwExpertsActiveTab
            }
            border={false}
          />
        </div>
      )}

      <div
        style={{
          marginRight: '1rem',
          display: 'flex',
          gap: 24,
          alignItems: 'center',
        }}
      >
        <Popover
          variant="menu"
          options={popoverItems}
          styles={{ left: 'unset', right: 0 }}
          data-testid="avatar-popover-id"
        >
          <div
            className="user-avatar"
            data-testid="user-avatar-id"
            style={{
              background:
                avatarType() === 'cust'
                  ? 'linear-gradient(rgba(255, 153, 185, 1), rgba(204, 0, 65, 1))'
                  : 'linear-gradient(rgba(0, 204, 102, 1),rgba(0, 102, 51, 1) )',
            }}
          >
            <Typography size={11} weight={700} color="rgba(255, 255, 255, 1)">
              {initial}
            </Typography>
          </div>
        </Popover>
      </div>
    </header>
  )
}

export default Header
