export interface CardProps {
  border?: string
  borderRadius?: string
  boxSizing?: any
  backgroundColor?: string
  children?: any
  height?: string
  name?: string
  width?: string
}

const Card = ({
  backgroundColor,
  border,
  borderRadius,
  boxSizing,
  children,
  height,
  name = '',
  width,
}: CardProps): JSX.Element => {
  return (
    <>
      <div
        data-testid={name ? `card-${name}` : ''}
        style={{
          backgroundColor: backgroundColor,
          border: border,
          borderRadius: borderRadius,
          boxSizing: boxSizing,
          height: height,
          width: width,
        }}
      >
        {children}
      </div>
    </>
  )
}

//TODO: ADD style consts once we determine any changes that can be made to box (Default styles based on a prop?)

export default Card
