import { useContext, useState } from 'react'
import { Navigate } from 'react-router'

import CompanyOverview from './Overview/CompanyOverview'
import { Tabs } from '../../../design-system'
import { Context } from '../../App'

import './CompanyInfo.scss'

const CompanyInfo = () => {
  const {
    state: { homePath, user },
  } = useContext(Context)

  const [selectedTab, setSelectedTab] = useState(0)

  const tabData = [
    {
      id: 0,
      text: 'Overview',
      active: true,
      notification: false,
    },
  ]

  const CompanyInfoTabsContent: JSX.Element[] = [<CompanyOverview key={0} />]

  if (user.isDWEmployee) {
    return <Navigate to={homePath} />
  }

  return (
    <article
      id="company-info-page"
      className="content"
      data-testid="company-info"
    >
      <Tabs
        data={tabData}
        tabStyles={{ paddingLeft: '11px' }}
        activeTab={selectedTab}
        onTabClick={(id) => {
          setSelectedTab(id)
        }}
      ></Tabs>
      {CompanyInfoTabsContent.at(selectedTab)}
    </article>
  )
}

export default CompanyInfo
