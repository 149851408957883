import React, { ReactElement, useEffect, useRef, useState } from 'react'

import { TableHeader } from './TableHeader'
import { TableRow } from './TableRow'
import {
  addEmptyTableRows,
  changeNullValueToStringError,
  createCustomTableHeaders,
  sortData,
  sortWithSecondaryData,
} from './Table.utils'
import { SortingOrder, TableLoadingState, TableProps } from './Table.types'
import TableLoadingRow from './TableLoadingRow/TableLoadingRow'
import { Caption } from './Caption'
import { useRefOnScreen } from '../../utils'
import { sideSheetPosition } from '../../utils/SideSheetUtils'

import './Table.scss'

const Table = ({
  bodyColor = '',
  bodyTextColor = '',
  captionProps,
  data = [],
  headerColor = '',
  headerTextColor = '',
  customHeaders = [],
  loading = false,
  loadingRows = 1,
  loadingRowState = TableLoadingState.IDLE,
  ariaProps,
  sortOptions = {
    isSortedExternally: false,
    defaultSortColumn: -1,
    sortDirection: 'DESC',
    secondarySortDirection: 'ASC',
    secondaryDefaultSortColumn: -1,
  },
  onBottomReached,
  clickableTableRow,
  onClick,
  stickyHeaderOffset = 0,
  styles,
  rowHoverElements = [],
  responsive = true,
  customErrorComponent: customErrorComponent,
}: TableProps): JSX.Element => {
  const tableHeaders = createCustomTableHeaders(
    customHeaders,
    data,
    loadingRows,
  )

  const {
    isSortedExternally,
    defaultSortColumn,
    sortDirection,
    secondarySortDirection,
    secondaryDefaultSortColumn,
  } = sortOptions
  const tableData = changeNullValueToStringError(data)

  const [sortedData, setSortData] = useState(tableData)
  const [sortOrder, setSortOrder] = useState<SortingOrder | undefined>(
    sortDirection,
  )
  const [sortIndex, setSortIndex] = useState(defaultSortColumn)
  const [secondarySortIndex, setSecondarySortIndex] = useState(
    secondaryDefaultSortColumn,
  )

  // if 'onBottomReached' was passed, make observable refs to the container and the row
  const tableRowTriggerRef = useRef<HTMLTableRowElement>(null)
  const [active, setActive] = useState<boolean | string>(false)

  useRefOnScreen(tableRowTriggerRef, onBottomReached)

  useEffect(() => {
    if (loading) {
      setSortData(addEmptyTableRows(data, loadingRows))
    } else if (data.length === 0 || isSortedExternally) {
      setSortData(tableData)
    } else if (secondarySortDirection && secondaryDefaultSortColumn) {
      setSortData(
        sortWithSecondaryData(
          data,
          sortIndex ?? -1,
          sortOrder ?? 'DESC',
          secondarySortDirection ?? 'ASC',
          secondarySortIndex ?? -1,
        ),
      )
    } else {
      setSortData(sortData(data, sortIndex ?? -1, sortOrder ?? 'DESC'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading])

  useEffect(() => {
    if (!sideSheetPosition) {
      setActive(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sideSheetPosition])

  const setActiveRow = (id) => {
    if (sideSheetPosition) {
      setActive(id)
    } else {
      setActive(id)
    }
  }

  const handleSorting = (
    sortOrder: SortingOrder,
    index: number,
    secondaryIndex?: number,
    secondarySortOrder?: SortingOrder,
  ): void => {
    setSortOrder(sortOrder)
    setSortIndex(index)
    setSecondarySortIndex(secondaryIndex)
    if (
      index !== -1 &&
      (secondaryDefaultSortColumn === -1 || !secondaryDefaultSortColumn)
    ) {
      setSortData(sortData(data, index, sortOrder))
    }
    if (
      secondaryDefaultSortColumn !== -1 &&
      secondaryDefaultSortColumn !== undefined
    ) {
      setSortData(
        sortWithSecondaryData(
          data,
          index ?? -1,
          sortOrder,
          secondarySortOrder ?? 'ASC',
          secondaryDefaultSortColumn ?? -1,
        ),
      )
    } else {
      setSortData(sortData(data, index, sortOrder))
    }
  }

  const handleRowClick = (id: string | number, rowData: any) => {
    if (onClick && clickableTableRow) {
      setActiveRow(id)
    }
    onClick?.(id, rowData)
  }

  let tempSortIndex = sortIndex ?? -1

  if (isSortedExternally) {
    tempSortIndex = defaultSortColumn ?? -1
  }

  let tableContent: ReactElement | null = null

  if (!sortedData?.length && !loading) {
    tableContent = (
      <div className="table-caption">
        <div className="table-caption-content">
          {customErrorComponent || (
            <div className="table-empty-cell table-caption-error-label">
              Error: There is no data for this table.
            </div>
          )}
        </div>
      </div>
    )
  } else if (sortedData.length) {
    tableContent = (
      <div className="table-body" role="rowgroup">
        {sortedData.map(
          (tableData: Record<string, any>, index: number): JSX.Element => {
            return (
              <TableRow
                key={index}
                index={index}
                tableHeaders={tableHeaders}
                tableRowData={tableData}
                bodyTextColor={bodyTextColor}
                loading={
                  loading && loadingRowState !== TableLoadingState.LOADING
                }
                clickableTableRow={clickableTableRow}
                rowClick={handleRowClick}
                rowHoverElements={rowHoverElements}
                ref={
                  sortedData.length === index + 1
                    ? tableRowTriggerRef
                    : undefined
                }
                active={active === tableData.id}
              />
            )
          },
        )}
        {loading && loadingRowState === TableLoadingState.LOADING && (
          <TableLoadingRow loadingState={loadingRowState} />
        )}
      </div>
    )
  }

  return (
    <div className={responsive ? 'table-container' : 'not-responsive'}>
      <Caption
        caption={captionProps?.caption}
        captionButton={captionProps?.captionButton}
        captionSide={captionProps?.captionSide}
      />
      <div
        className={`datatable ${
          stickyHeaderOffset > 0 ? 'datatable-sticky' : ''
        }`}
        style={{
          ...styles,
          ...(stickyHeaderOffset > 0 && {
            maxHeight: `calc(100vh - ${stickyHeaderOffset + 'px'})`,
          }),
        }}
      >
        <div id={'table'} className="table" {...ariaProps} role="table">
          <TableHeader
            tableHeaders={tableHeaders}
            bodyColor={bodyColor}
            headerBodyColor={headerColor}
            headerTextColor={headerTextColor}
            sortTableColumns={handleSorting}
            sortDir={sortDirection ?? 'DESC'}
            sortIndex={tempSortIndex}
            secondarySortIndex={secondarySortIndex}
            secondarySortDirection={secondarySortDirection}
            allowSorting={sortedData.length > 0 && loading !== true}
          />
          {tableContent}
        </div>
      </div>
    </div>
  )
}

export default Table
