import { captureException } from '@sentry/react'
import cloneDeep from 'lodash/cloneDeep'
import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

type SecurityCenterFiltersState<T> = {
  [K in keyof T]: T[K] extends Array<infer U> ? Array<U> : never // Ensure that each key in T maps to an array
}

type ToColumnFilters<T> = {
  [K in keyof T]: {
    id: K
    value: T[K]
  }
}[keyof T][]

/**
 * A function to format filters for TanStack Table.
 * @template T
 * @param {T} filters filters in the format of `{ key: value[] }`
 * @returns {ToColumnFilters<T>} filters in the format of `[{ id: key, value: value[] }]`
 */
export const formatFiltersForTanStackTable = <
  T extends SecurityCenterFiltersState<T>,
>(
  filters: T,
): ToColumnFilters<T> => {
  return Object.entries(filters).map(([key, value]) => ({
    id: key,
    value,
  })) as ToColumnFilters<T>
}

/**
 * Given a value from `URLSearchParams.get()`, returns a parsed object if it is valid JSON, and `undefined` otherwise.
 * @param {string|null} incomingValue a value returned from `URLSearchParams.get()`
 * @template T
 * @returns {T|undefined} the parsed JSON object, or `null` if the value is not valid JSON
 */
export const safelyParseJSONSearchParam = <T>(
  incomingValue: string | null,
): T | undefined => {
  if (!incomingValue) {
    return undefined
  }

  try {
    return JSON.parse(incomingValue)
  } catch (error) {
    captureException(error)
    return undefined
  }
}

/**
 * Construct updated filters using values from
 * the `selectedFilters` parameter in the supplied `URLSearchParams`.
 * @template T
 * @param {T} defaultColumnState the default column filters state
 * @param {URLSearchParams} searchParams the search params object from the URL
 * @returns {T} the updated column filters state
 */
export const deriveFiltersStateFromSearchParams = <
  T extends Record<string, unknown[]>,
>(
  defaultColumnState: T,
  searchParams: URLSearchParams,
): T => {
  const selectedFilters =
    safelyParseJSONSearchParam<Record<string, unknown>>(
      searchParams.get('selectedFilters'),
    ) ?? {}

  const validSelectedFilters = Object.fromEntries(
    Object.entries(selectedFilters).filter(
      ([key, value]) => key in defaultColumnState && Array.isArray(value),
    ),
  ) as Partial<T> // This assertion is safe because our filter only includes keys which are present in the default column state and have an array value

  return {
    ...cloneDeep(defaultColumnState),
    ...validSelectedFilters,
  }
}

/**
 * Constructs a new URLSearchParams object that minimally represents the selected filters.
 * Filters with empty arrays are omitted from the search parameters.
 * @template T - A type that maps string keys to arrays of any type
 * @param {URLSearchParams} currentSearchParams - The current URL search parameters
 * @param {T} newSelectedFilters - An object containing filter keys and their selected values as arrays
 * @returns {URLSearchParams} A new URLSearchParams object with the 'selectedFilters' parameter updated or removed
 */
export const constructMinimalSearchParams = <
  T extends Record<string, unknown[]>,
>(
  currentSearchParams: URLSearchParams,
  newSelectedFilters: T,
): URLSearchParams => {
  const newSearchParams = new URLSearchParams(currentSearchParams)
  const nonEmptyFilters = Object.fromEntries(
    Object.entries(newSelectedFilters).filter(([, value]) => value.length > 0),
  )
  if (Object.keys(nonEmptyFilters).length) {
    newSearchParams.set('selectedFilters', JSON.stringify(nonEmptyFilters))
  } else {
    newSearchParams.delete('selectedFilters')
  }
  return newSearchParams
}

interface UseSearchParamColumnFiltersReturn<
  T extends SecurityCenterFiltersState<T>,
> {
  /** The current filter state, derived from URL parameters and default filter state */
  columnFilters: T
  /** The current filter state, formatted for TanStack Table */
  tanStackColumnFilters: ToColumnFilters<T>
  /** Function to update all filters at once */
  setColumnFilters: (selectedFilters: T) => void
  /** Function to remove a single value from a specific filter */
  removeColumnFilter: <K extends keyof T>(key: K, value: T[K][number]) => void
}

/**
 * A hook that manages column filters state through URL search parameters, designed for use with TanStack Table.
 * Persists filter selections in the URL, enabling shareable filtered views and browser navigation.
 * @template T An object type where each key maps to an array of filter values
 * @param {T} defaultColumnState The default filter state object. Each key represents a filterable column,
 * and its value is an empty array that will hold selected filter values.
 *
 * Example: `{ priority: [], status: [], sysUpdatedOn: [] }`
 * @returns {UseSearchParamColumnFiltersReturn<T>} An object containing the current filter state and functions to update it
 */
export const useSearchParamColumnFilters = <
  T extends SecurityCenterFiltersState<T>,
>(
  defaultColumnState: T,
): UseSearchParamColumnFiltersReturn<T> => {
  const [searchParams, setSearchParams] = useSearchParams()

  const columnFilters = useMemo(
    () => deriveFiltersStateFromSearchParams(defaultColumnState, searchParams),
    [searchParams, defaultColumnState],
  )

  const setSelectedFiltersInSearchParams = (newSelectedFilters: T) => {
    const newSearchParams = constructMinimalSearchParams(
      searchParams,
      newSelectedFilters,
    )
    setSearchParams(newSearchParams)
  }

  const removeColumnFilter = (key: keyof T, value: T[typeof key][number]) => {
    const newFilters = {
      ...columnFilters,
      [key]: columnFilters[key as keyof T].filter((item) => item !== value),
    }
    setSelectedFiltersInSearchParams(newFilters)
  }

  return {
    columnFilters,
    tanStackColumnFilters: formatFiltersForTanStackTable(columnFilters),
    setColumnFilters: setSelectedFiltersInSearchParams,
    removeColumnFilter,
  }
}
