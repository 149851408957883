import { ReactElement, useCallback, useContext, useMemo, useRef } from 'react'
import { useQuery } from '@apollo/client'
import { useSearchParams } from 'react-router-dom'
import { Box } from '@mui/material'

import { Context as AppContext } from '@components/App'
import { useThreatIntelReportsInput } from '@threatIntelHooks/index'
import {
  GET_THREAT_INTEL_REPORTS_LIST_QUERY,
  GetThreatIntelReportsData,
  GetThreatIntelReportsVariables,
  SortDirection,
  ThreatIntelReportSortOptions,
} from '@queries/threatIntel'
import { TableToolbar } from '@common/TableToolbar'
import { NoResults } from '@common/NoResults'
import { usePageSize } from '@hooks/usePageSize'

import ThreatIntelFilterLabel from '../ThreatIntelFilterLabel/ThreatIntelFilterLabel'
import ThreatIntelReportSearch from './ThreatIntelReportSearch'
import { useThreatIntelReportsContext } from '../../context'
import ThreatIntelReportsTable from '../ThreatIntelReportsTable/ThreatIntelReportsTable'

const ThreatIntelReportsList = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const selectedCustomer = searchParams.get('customer')
  const tableRef = useRef<HTMLDivElement>(null)

  const { keywordSearch, sortBy, sortDirection, filters } =
    useThreatIntelReportsInput()

  const { setRowSelection, rowSelection } = useThreatIntelReportsContext()

  const {
    state: {
      dwExpertsCustomer: { customerShortName },
    },
  } = useContext(AppContext)

  const pageSize = usePageSize()

  const {
    data: {
      getThreatIntelReports: { threatIntelReports: reportData, pagination },
    } = {
      getThreatIntelReports: {
        threatIntelReports: [],
        pagination: { total: 0 },
      },
    },
    loading,
    error,
    fetchMore,
  } = useQuery<GetThreatIntelReportsData, GetThreatIntelReportsVariables>(
    GET_THREAT_INTEL_REPORTS_LIST_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        selectedCustomer: customerShortName || selectedCustomer,
        input: {
          filters,
          keywordSearch,
          sortBy,
          sortDirection,
          pagination: {
            limit: pageSize,
            offset: 0,
          },
        },
      },
    },
  )

  const fetchNextPage = useCallback(() => {
    if (reportData.length < pagination.total) {
      fetchMore({
        variables: {
          selectedCustomer: customerShortName || selectedCustomer,
          input: {
            filters,
            keywordSearch,
            sortBy,
            sortDirection,
            pagination: {
              limit: pageSize,
              offset: reportData.length,
            },
          },
        },
      })
    }
  }, [
    customerShortName,
    fetchMore,
    filters,
    keywordSearch,
    pagination.total,
    reportData.length,
    selectedCustomer,
    sortBy,
    sortDirection,
    pageSize,
  ])

  const handleScrollToTop = useCallback(() => {
    tableRef.current &&
      tableRef.current.scrollTo({
        behavior: 'smooth',
        top: 0,
      })
  }, [])

  const sorting = useMemo(() => {
    return [
      {
        id: ThreatIntelSortOptionToSortingState(
          sortBy as ThreatIntelReportSortOptions,
        ),
        desc: sortDirection === SortDirection.Descending,
      },
    ]
  }, [sortBy, sortDirection])

  // Needs to be memoized because the filters property is not always defined
  const selectedLabels = useMemo(() => {
    return filters?.labels ?? []
  }, [filters?.labels])

  let component: ReactElement | null = (
    <ThreatIntelReportsTable
      data={reportData}
      isLoading={loading && !reportData.length}
      dataTotal={pagination.total}
      pageSize={pageSize}
      sorting={sorting}
      selectedLabels={selectedLabels}
      ref={tableRef}
      onBottomReached={fetchNextPage}
      onScrollToTop={handleScrollToTop}
      error={error}
      onRowSelectionChange={setRowSelection}
      rowSelection={rowSelection}
    />
  )

  if (!loading && !error && reportData && reportData.length === 0) {
    component = (
      <NoResults description="There are no reports with these labels. Try removing a label." />
    )
  }

  const updateSearchParams = (value: string) => {
    setSearchParams((prevParams) => {
      if (value.trim()) {
        prevParams.set('keywordSearch', value.trim())
      } else {
        prevParams.delete('keywordSearch')
      }
      document
        .getElementsByClassName('datatable-sticky')
        .item(0)
        ?.scrollTo({ top: 0 })
      return prevParams
    })
  }

  const handleSearchInputDelete = () => {
    setSearchParams((prevParams) => {
      prevParams.delete('keywordSearch')

      return prevParams
    })
  }

  return (
    <Box
      data-testid="threat-intel-reports-list"
      sx={{
        display: 'grid',
        gridRow: 2,
        alignContent: 'start',
      }}
    >
      <ThreatIntelFilterLabel />
      <TableToolbar
        dataLength={pagination.total}
        loading={loading && !reportData.length}
        singularEntityName={'Report'}
        pluralEntityName={'Reports'}
      >
        <ThreatIntelReportSearch
          initialValue={keywordSearch}
          onSearchDelete={handleSearchInputDelete}
          updateSearchValue={updateSearchParams}
        />
      </TableToolbar>
      {component}
    </Box>
  )
}

export const ThreatIntelSortOptionToSortingState = (
  sortColumn: ThreatIntelReportSortOptions,
): string => {
  switch (sortColumn) {
    case ThreatIntelReportSortOptions.Title:
      return 'title'
    case ThreatIntelReportSortOptions.Type:
      return 'type'
    case ThreatIntelReportSortOptions.Date:
    default:
      return 'createdDate'
  }
}

export const SortingStateToThreatIntelSortOption = (
  field: string,
): ThreatIntelReportSortOptions => {
  switch (field) {
    case 'type':
      return ThreatIntelReportSortOptions.Type
    case 'title':
      return ThreatIntelReportSortOptions.Title
    case 'createdDate':
    default:
      return ThreatIntelReportSortOptions.Date
  }
}

export default ThreatIntelReportsList
