import { RefObject, useEffect } from 'react'

const useOutsideClick = (
  callback: VoidFunction,
  isOpen: boolean,
  ref: RefObject<HTMLElement>,
  enabled = true,
  toggleRef?: RefObject<HTMLElement>,
): void => {
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        isOpen &&
        ref.current &&
        !ref.current.contains(e.target) &&
        !toggleRef?.current?.contains(e.target)
      ) {
        callback()
      }
    }

    if (enabled) {
      document.addEventListener('mousedown', checkIfClickedOutside)

      return () => {
        document.removeEventListener('mousedown', checkIfClickedOutside)
      }
    }
  }, [callback, enabled, isOpen, ref, toggleRef])
}

export default useOutsideClick
