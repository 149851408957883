import { gql } from '@apollo/client'

export const TICKET_DETAILS_FRAGMENT = gql`
  fragment TicketDetailsFields on Ticket {
    shortDescription
    description
  }
`

export const TICKET_STATUS_FRAGMENT = gql`
  fragment TicketStatusFields on Ticket {
    assignedUser
    impact
    priority
    state
    customerValidateState
    lastUpdateUser
    sysCreatedOn
    assignedToDeepwatch
    ticketNumber
    assignedUserEmail
  }
`

export const TICKET_RELATED_CASES_FRAGMENT = gql`
  fragment TicketRelatedCasesFields on Ticket {
    relatedCases {
      sysId
      splunkSearch
      lastUpdateUser
      assignedUser
      customerValidateState
      serviceOffering
      category
      subcategory
      useCaseTitle
      useCase
      incidentType
      cisControl
      mitreTactics
      mitreTechniques
      splunkLink
      shortDescription
      comments {
        text
        time
        type
        user
        internalUser
      }
      openedBy
      createdDate
      ticketNumber
      description
      priority
      state
      sysCreatedOn
      type
      sysUpdatedOn
    }
  }
`

export const TICKET_CONTACT_FRAGMENT = gql`
  fragment TicketContactFields on Ticket {
    module
    serviceOffering
    assignmentGroup
    category
    subcategory
    channel
    caseType
    contactUser
    agentType
  }
`

export const TICKET_ACTIVITY_FRAGMENT = gql`
  fragment TicketActivityFields on Ticket {
    comments {
      text
      time
      type
      user
      internalUser
    }
    openedBy
    createdDate
    ticketNumber
    state
    sysCreatedOn
  }
`

export const TICKET_ADDITIONAL_DETAILS_FRAGMENT = gql`
  fragment TicketAdditionalDetailsFields on Ticket {
    mitreTactics
    mitreTechniques
    searchName
    splunkLink
    splunkSearch
    useCaseTitle
  }
`

export const TICKET_MTTX_FRAGMENT = gql`
  fragment TicketMttxFields on Ticket {
    timeToCloseSec
    timeToCustomerAcknowledgeSec
    timeToCustomerAssignSec
    timeToCustomerValidateSec
    timeToDetectionSec
    timeToDeclaredIncidentSec
    timeToDetectResolveAlertSec
    timeToInvestigateSec
    timeToNotifySec
    timeToRespondSec
    timeToResolveTicketSec
    timeToUpdateSec
    totalTimeDeepwatchWaitVendorSec
    totalTimeDeepwatchWaitCustomerSec
  }
`

export const TICKET_RESOLUTION_FRAGMENT = gql`
  fragment TicketResolutionFields on Ticket {
    resolutionCode
    resolutionNotes
    state
  }
`

export const UPDATE_TICKET_IN_CACHE = gql`
  fragment UpdateTicket on Ticket {
    customerValidateState
    resolutionNotes
    resolutionCode
    state
    priority
    comments
  }
`

export const EDITABLE_TICKET_FIELDS_FRAGMENT = gql`
  fragment EditableTicketFields on Ticket {
    customerValidateState
    resolutionNotes
    resolutionCode
    state
    priority
    comments
    sysId
    sysUpdatedOn
  }
`

export const TICKET_HEADER_FRAGMENT = gql`
  fragment TicketHeaderFields on Ticket {
    sysUpdatedOn
    state
    ticketNumber
    resolutionCode
    resolutionNotes
  }
`
