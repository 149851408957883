/* eslint-disable security/detect-object-injection */
import { useCallback, useMemo, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { ColumnFiltersState, SortingState } from '@tanstack/react-table'
import { ErrorBoundary } from 'react-error-boundary'
import { Box } from '@mui/material'

import { FilterChipsHeader, FilterValue } from '@common/FilterChips'
import {
  FilterOptions,
  TicketFilterInput,
  TicketRelatedUnion,
  TicketSortingInput,
  TicketStateEnum,
} from '@models/index'
import { useSelectedRow, useTicketsContext } from '@hooks/index'
import { TableToolbar } from '@common/TableToolbar'
import { ComponentError } from '@common/ComponentError'
import { ErrorCard } from '@common/ErrorCard'
import fetchErrorIcon from '@app-assets/fetch-error.svg'
import { useCustomer } from '@hooks/useCustomer'
import { invertColumnSortDirection } from '@utils/tableUtils'
import { safelyParseJSONSearchParam } from '@hooks/tableHooks/useSearchParamColumnFilters'

import TicketLibrarySideSheet from './TicketLibrarySideSheet/TicketLibrarySideSheet'
import {
  deriveColumnFiltersStateFromSearchParams,
  emptyTicket,
  includeEnabledModules,
  removeValueFromSearchParamHash,
  transformFilterOptionLabels,
} from './TicketLibrary.utils'
import { TicketFilters } from './TicketFilters'
import { TicketLibraryControls } from './TicketLibraryControls'
import TicketLibraryTable, {
  TICKET_LIBRARY_TABLE_ID_PREFIX,
} from './TicketLibraryTable/TicketLibraryTable'
import { TicketLibrarySideSheetFallback } from './TicketLibrarySideSheetFallback'
import { useProgressiveTickets } from './useProgressiveTickets'
import { TicketLibraryGlobalFilters } from './ticketLibraryTypes'

export interface TicketLibraryColumnFilter {
  id: string
  value: string[]
}

const TicketLibrary = (): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { featureNgMdr } = useFlags()

  const defaultColumnState: ColumnFiltersState = useMemo(
    () => [
      {
        id: 'priority',
        value: [],
      },
      {
        id: 'state',
        value: [],
      },
      {
        id: 'assignmentGroup',
        value: [],
      },
      {
        id: featureNgMdr ? 'module' : 'type',
        value: [],
      },
      {
        id: 'sysCreatedOn',
        value: [],
      },
      {
        id: 'sysUpdatedOn',
        value: [],
      },
      { id: 'useCase', value: [] },
    ],
    [featureNgMdr],
  )

  /** When search params change due to applying filters or navigation, we need to reconstruct the `columnFilters` */
  const columnFilters = useMemo(
    () =>
      deriveColumnFiltersStateFromSearchParams(
        defaultColumnState,
        searchParams,
      ),
    [searchParams, defaultColumnState],
  )
  const [globalFilters, setGlobalFilters] =
    useState<TicketLibraryGlobalFilters>({
      keywordSearch: '',
    })
  const [sorting, setSorting] = useState<SortingState>([
    {
      id: 'sysUpdatedOn',
      desc: false,
    },
  ])
  const { ticketSettingsDataLoading } = useTicketsContext()
  const [toggleFilterSideSheet, setToggleFilterSideSheet] = useState(false)
  const tableRef = useRef<HTMLDivElement>(null)

  const {
    customer: {
      edrEnabled,
      fwEnabled,
      mdrEnabled,
      ngMfwEnabled,
      ngMdrEnabled,
      ngMedrEnabled,
      vmEnabled,
      threatSignalEnabled,
    },
    isDWEmployee,
  } = useCustomer()

  const modules = {
    EDR: edrEnabled || ngMedrEnabled,
    FW: fwEnabled || ngMfwEnabled,
    MDR: mdrEnabled || ngMdrEnabled, // should show MDR regardless
    NG_MEDR: ngMedrEnabled,
    Threat_Signal: threatSignalEnabled,
    VM: vmEnabled,
  }

  const defaultFilters = {
    priority: [],
    state: [],
    type: [],
    assignmentGroup: [],
    module: [],
    sysCreatedOn: [],
    sysUpdatedOn: [],
    useCase: [],
  }

  const defaultRowSelectionState = {}

  const selectedCustomer = searchParams.get('customer')
  const filterValueLabelMap = safelyParseJSONSearchParam<
    Record<string, string>
  >(searchParams.get('filterValueLabelMap'))

  const showAllTickets = searchParams.get('showOpenTicketOnly') === 'false'

  const formattedSorting = invertColumnSortDirection<TicketRelatedUnion>(
    sorting,
    ['sysCreatedOn', 'sysUpdatedOn'],
  ).reduce<TicketSortingInput>(
    (acc, cur) => ({
      ...acc,
      [cur.id]: cur.desc ? 'DESC' : 'ASC',
    }),
    {},
  )

  const formattedSelectedFilters = columnFilters.reduce<TicketFilterInput>(
    (acc, cur) => ({ ...acc, [cur.id]: cur.value }),
    defaultFilters,
  )
  const [selectedFilters, setSelectedFilters] = useState<TicketFilterInput>(
    formattedSelectedFilters,
  )

  const {
    ticketData: progressiveTicketData,
    loading: progressiveLoading,
    additionalDataLoading,
    fetchingMore,
    tableDataError: progressiveTableDataError,
    additionalDataError: progressiveAdditionalDataError,
    fetchMore: progressiveFetchMore,
  } = useProgressiveTickets({
    selectedCustomer,
    globalFilters,
    selectedFilters,
    showAllTickets,
    formattedSorting,
  })

  const ticketData = progressiveTicketData

  const tableDataError = progressiveTableDataError

  const additionalDataError = progressiveAdditionalDataError

  const {
    rowSelection,
    setRowSelection,
    selectedRow: focusedTicket,
    previouslySelectedRow: previouslyFocusedTicket,
  } = useSelectedRow({ data: ticketData.tickets, rowIdKey: 'sysId' })

  const filterOptions = {
    ...ticketData.filterOptions,
    filters: featureNgMdr
      ? ticketData.filterOptions.filters
      : ticketData.filterOptions.filters.filter(({ key }) => key !== 'module'),
  }

  const handleScrollToTop = useCallback(() => {
    tableRef.current &&
      tableRef.current.scrollTo({
        behavior: 'smooth',
        top: 0,
      })
  }, [])

  if (tableDataError) {
    return <ComponentError includeReloadButton errorIcon={fetchErrorIcon} />
  }

  const formattedFilterOptions = filterValueLabelMap
    ? transformFilterOptionLabels(filterOptions, filterValueLabelMap)
    : filterOptions

  const filtersWithEnabledModules = includeEnabledModules(
    formattedFilterOptions,
    modules,
  )

  const closedCancelledStatuses = [
    TicketStateEnum.Closed,
    TicketStateEnum.Cancelled,
  ]

  const updateSelectedFiltersInSearchParams = (
    selectedFilters: FilterOptions<TicketFilterInput>,
  ) => {
    const newSearchParams = new URLSearchParams(searchParams)
    const newSelectedFilters = Object.fromEntries(
      Object.entries(selectedFilters.filters).filter(
        ([, value]) => value.length > 0, // Only keep filters that have values to avoid unnecessary clutter in the URL
      ),
    )
    newSearchParams.set('selectedFilters', JSON.stringify(newSelectedFilters))
    setSearchParams(newSearchParams)
  }

  const applyFilter = (selectedFilters: FilterOptions<TicketFilterInput>) => {
    updateSelectedFiltersInSearchParams(selectedFilters)

    setSelectedFilters({
      priority: selectedFilters.filters.priority,
      state: selectedFilters.filters.state,
      type: selectedFilters.filters.type,
      assignmentGroup: selectedFilters.filters.assignmentGroup,
      module: selectedFilters.filters.module,
      sysCreatedOn: selectedFilters.filters.sysCreatedOn,
      sysUpdatedOn: selectedFilters.filters.sysUpdatedOn,
      useCase: selectedFilters.filters.useCase,
    })

    if (
      selectedFilters.filters.state.some((status) =>
        closedCancelledStatuses.includes(status),
      )
    ) {
      const newSearchParams = new URLSearchParams(searchParams)
      newSearchParams.set('showOpenTicketOnly', 'false')
      setSearchParams(newSearchParams)
    } else {
      setGlobalFilters(() => ({
        keywordSearch: selectedFilters.keywordSearch,
      }))
    }

    setToggleFilterSideSheet(false)

    handleScrollToTop()
  }

  const clearAll = () => {
    const newSearchParams = new URLSearchParams(searchParams)
    newSearchParams.delete('selectedFilters')
    newSearchParams.delete('showOpenTicketOnly')
    setSearchParams(newSearchParams)

    setGlobalFilters({
      keywordSearch: '',
    })
    setToggleFilterSideSheet(false)
    setSelectedFilters(defaultFilters)

    handleScrollToTop()
  }

  const closeSideSheet = () => {
    setRowSelection(defaultRowSelectionState)
    /** When closing the sidesheet, re-focus the row which was selected to improve a11y ux */
    if (previouslyFocusedTicket?.sysId) {
      document
        .getElementById(
          `${TICKET_LIBRARY_TABLE_ID_PREFIX}${previouslyFocusedTicket.sysId}`,
        )
        ?.focus()
    }
  }

  /**
   * Upon deletion of a filter chip, we want to remove the filter from the search parameters
   * @param filterGroup
   * @param filterValue
   */
  const removeFiltersFromSearchParams = (
    filterGroup: string,
    filterValue: FilterValue,
  ) => {
    const searchParamsWithUpdatedSelectedFilters =
      removeValueFromSearchParamHash(
        searchParams,
        'selectedFilters',
        filterGroup,
        filterValue as string, // Asserting the `string` type; Our type definitions indicate that `filterValue` could be of type `ThreatIntelReportLabel`, but I don't think that is applicable on this page
      )

    const newSearchParams = removeValueFromSearchParamHash(
      searchParamsWithUpdatedSelectedFilters,
      'filterValueLabelMap',
      filterValue as string,
    )

    setSearchParams(newSearchParams)
  }

  const deleteChip = (filterGroup: string, filterValue: FilterValue) => {
    setRowSelection(defaultRowSelectionState)
    setToggleFilterSideSheet(false)

    setGlobalFilters((old) => ({
      keywordSearch: filterGroup === 'keywordSearch' ? '' : old.keywordSearch,
    }))

    setSelectedFilters((prevSelectedFilters) => ({
      ...prevSelectedFilters,
      [filterGroup]: (prevSelectedFilters[filterGroup] || []).filter(
        (value) => value !== filterValue,
      ),
    }))

    removeFiltersFromSearchParams(filterGroup, filterValue)

    if (
      selectedFilters.state.some((status) =>
        closedCancelledStatuses.includes(status),
      )
    ) {
      const newSearchParams = new URLSearchParams(searchParams)
      newSearchParams.set('showOpenTicketOnly', 'false')
      setSearchParams(newSearchParams)
    }

    handleScrollToTop()
  }

  const handleIncludeToggle = () => {
    setSelectedFilters({
      ...selectedFilters,
      state: selectedFilters.state.filter(
        (state) => !closedCancelledStatuses.includes(state),
      ),
    })
    const newSearchParams = new URLSearchParams(searchParams)
    showAllTickets
      ? newSearchParams.delete('showOpenTicketOnly')
      : newSearchParams.set('showOpenTicketOnly', 'false')
    setSearchParams(newSearchParams)
    handleScrollToTop()
  }

  const anyFilterHasBeenApplied =
    globalFilters.keywordSearch.length > 0 ||
    selectedFilters.sysCreatedOn.length > 0 ||
    selectedFilters.priority.length > 0 ||
    selectedFilters.state.length > 0 ||
    selectedFilters.sysUpdatedOn.length > 0 ||
    selectedFilters.type.length > 0 ||
    selectedFilters.module.length > 0 ||
    (selectedFilters.useCase && selectedFilters.useCase?.length > 0)

  return (
    <Box
      data-testid="ticket-library"
      id="ticket-library-content"
      sx={{
        height: isDWEmployee ? 'calc(100% - 88px)' : 'calc(100% - 1rem)',
        padding: '1rem',
      }}
    >
      <Box id="ticket-library-header" data-testid="ticket-library-header">
        <ErrorBoundary fallbackRender={() => null}>
          {filterOptions && (
            <FilterChipsHeader
              deleteChip={deleteChip}
              filterValueLabelMap={filterValueLabelMap}
              selectedFilters={{
                filters: selectedFilters,
                keywordSearch: globalFilters.keywordSearch,
                showOpenTicketOnly: !showAllTickets,
              }}
              hasActiveFilters={anyFilterHasBeenApplied}
            />
          )}
        </ErrorBoundary>

        <ErrorBoundary
          fallbackRender={() => (
            <Box sx={{ marginBottom: '1rem' }}>
              <ErrorCard />
            </Box>
          )}
        >
          <TableToolbar
            dataLength={ticketData.pagination.total}
            loading={progressiveLoading}
            singularEntityName={showAllTickets ? 'ticket' : 'open ticket'}
            pluralEntityName={showAllTickets ? 'tickets' : 'open tickets'}
          >
            <TicketLibraryControls
              toggleState={!showAllTickets}
              handleOpenFilterSideSheetClick={() => {
                setRowSelection(defaultRowSelectionState)
                setToggleFilterSideSheet(true)
              }}
              handleIncludeToggle={handleIncludeToggle}
            />
          </TableToolbar>
        </ErrorBoundary>
      </Box>

      <ErrorBoundary fallbackRender={() => <ErrorCard includeReloadButton />}>
        <TicketLibraryTable
          data={ticketData}
          handleScrollToTop={handleScrollToTop}
          loading={progressiveLoading}
          onBottomReached={progressiveFetchMore}
          onGlobalFilterChange={setGlobalFilters}
          onSortingChange={setSorting}
          ref={tableRef}
          sorting={sorting}
          rowSelection={rowSelection}
          onRowSelectionChange={setRowSelection}
        />
      </ErrorBoundary>

      <ErrorBoundary
        fallbackRender={() => (
          <TicketLibrarySideSheetFallback
            data-testid="ticket-filters-sidesheet-fallback"
            open={toggleFilterSideSheet}
            closeSideSheet={() => setToggleFilterSideSheet(false)}
            title="Filtering options"
          />
        )}
      >
        <TicketFilters
          isOpen={toggleFilterSideSheet}
          setIsOpen={(toggle) => {
            setToggleFilterSideSheet(toggle)
          }}
          filterOptionsResponse={{
            ...formattedFilterOptions,
            filters: filtersWithEnabledModules,
          }} // filterOptions to display inside side sheet
          selectedFilters={{
            filters: selectedFilters,
            keywordSearch: globalFilters.keywordSearch,
            showOpenTicketOnly: !showAllTickets,
          }}
          clearAll={clearAll}
          applyFilter={applyFilter}
        />
      </ErrorBoundary>

      <ErrorBoundary
        fallbackRender={() => (
          <TicketLibrarySideSheetFallback
            data-testid="ticket-details-sidesheet-fallback"
            open={!!focusedTicket}
            closeSideSheet={closeSideSheet}
            title="Ticket Details"
          />
        )}
      >
        <TicketLibrarySideSheet
          data-testid="ticketDetails"
          isOpen={!!focusedTicket}
          closeSideSheet={closeSideSheet}
          focusedTicket={
            focusedTicket ?? previouslyFocusedTicket ?? emptyTicket
          }
          throwError={additionalDataError}
          isLoading={
            ticketSettingsDataLoading ||
            ((fetchingMore || additionalDataLoading) &&
              !!focusedTicket &&
              // TODO: use a better loading state than the full description to determine if additional fields are loading
              !focusedTicket?.description)
          }
        />
      </ErrorBoundary>
    </Box>
  )
}

export default TicketLibrary
