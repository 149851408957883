import { Icon, Typography, Toggle, colors } from '../../../../design-system'
import { handleKeyBoardAction } from '../../../../utils'

interface TicketLibraryControls {
  handleOpenFilterSideSheetClick: () => void
  handleIncludeToggle: () => void
  toggleState: boolean
}

const TicketLibraryControls = ({
  handleOpenFilterSideSheetClick,
  handleIncludeToggle,
  toggleState,
}) => {
  return (
    <div className="ticket-library-controls">
      <Toggle
        label="Include resolved/closed/cancelled tickets"
        labelStyles={{ margin: 0, padding: '0 2em 0 0' }}
        check={!toggleState}
        onClick={handleIncludeToggle}
        onKeyUp={(event) => handleKeyBoardAction(event, handleIncludeToggle)}
      />
      <div
        tabIndex={0}
        role={'button'}
        onKeyDown={(event) =>
          handleKeyBoardAction(event, handleOpenFilterSideSheetClick)
        }
        data-testid="ticket-library-controls-filter-button"
      >
        <Typography
          onClick={handleOpenFilterSideSheetClick}
          weight={600}
          color={colors.brand.secondary.main}
          component="span"
          styles={{
            display: 'flex',
            alignItems: 'center',
            gap: 8,
            cursor: 'pointer',
          }}
        >
          Filter
          <Icon variant={'filter'} color={colors.brand.secondary.main} />
        </Typography>
      </div>
    </div>
  )
}

export default TicketLibraryControls
