import React from 'react'

import { Typography, colors } from '../../../../../../design-system'

import './TIFRTableOfContents.scss'

const TIFRTableOfContents = ({ threatIntelReportSections, activeSection }) => {
  const scrollToSection = (id: string) => {
    const sectionElement = document.getElementById(id)
    if (sectionElement) {
      const offset = sectionElement.offsetTop - 88
      window.scrollTo({
        top: offset,
        behavior: 'smooth',
      })
    }
  }

  const filteredHeadings = threatIntelReportSections?.filter(
    (section) => section.level < 3,
  )

  return (
    <div className="report-toc-desktop" data-testid="report-table-of-contents">
      <Typography variant="text10semibold">On this page</Typography>
      <ol>
        {filteredHeadings.map((section, index) => (
          <li
            key={index}
            className={
              `section-${section.heading}` === activeSection
                ? 'active-link'
                : ''
            }
          >
            <a
              href={`#section-${index}`}
              onClick={(e) => {
                e.preventDefault()
                scrollToSection(`section-${section.heading}`)
              }}
            >
              <Typography
                variant="text11"
                color={
                  `section-${section.heading}` === activeSection
                    ? colors.brand.secondary.main
                    : colors.util.navy[50]
                }
              >
                {section.heading}
              </Typography>
            </a>
          </li>
        ))}
      </ol>
    </div>
  )
}

export default TIFRTableOfContents
