import { Defs, linearGradientDef } from '@nivo/core'
import { area } from 'd3-shape'

export const AreaLayer = ({ series, xScale, yScale }): JSX.Element => {
  const areaGenerator = area()
    .x((d) => xScale(d['data'].x))
    .y0(() => yScale(0))
    .y1((d) => yScale(d['data'].y))

  return (
    <>
      <Defs
        defs={[
          linearGradientDef('customerGradient', [
            { offset: 0, color: '#00CC66', opacity: 0.4 },
            { offset: 20, color: '#00CC66', opacity: 0.2 },
            { offset: 50, color: '#00CC66', opacity: 0.1 },
            { offset: 70, color: '#00CC66', opacity: 0.0 },
          ]),
        ]}
      />
      <path
        className="area-cust-line"
        d={areaGenerator(series[0].data) ?? undefined}
        fill="url(#customerGradient)"
      />
    </>
  )
}
