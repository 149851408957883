export enum TickDirection {
  UP,
  DOWN,
  NEUTRAL,
  NOT_ENOUGH_DATA,
}

export interface DeltaProps {
  delta: number | string | null
  tooltipMessage?: string
}
