import { CSSProperties, createRef, useState } from 'react'
import { format } from 'date-fns'

import { PopoverMenu, MonthPicker, Input } from '../'
import { useOutsideClick } from '../../hooks'
import { PopoverProps, PopoverVariant } from '../../interfaces'

export const Popover = ({ styles, ...props }: PopoverProps): JSX.Element => {
  const [open, setOpen] = useState<boolean>(false)

  const togglePopoverMenu = () => {
    setOpen((old) => !old)
  }
  const openPopoverMenu = () => {
    setOpen(true)
  }

  const closePopoverMenu = () => {
    setOpen(false)
  }

  const popoverRef = createRef<HTMLDivElement>()
  useOutsideClick(closePopoverMenu, open, popoverRef)

  const getTrigger = () => {
    switch (props.variant) {
      case 'menu':
        return (
          <div
            role="button"
            tabIndex={0}
            onKeyDown={togglePopoverMenu}
            style={triggerArea}
            onClick={togglePopoverMenu}
          >
            {props.children}
          </div>
        )
      case 'month':
        return (
          <Input
            name={props.inputName}
            type="text"
            label={props.label}
            labelId={props.label}
            placeholder={props.placeholder}
            hintText={props.hintText}
            onFocus={openPopoverMenu}
            onMouseDown={togglePopoverMenu}
            value={props.date ? format(props.date, 'MM yyyy') : ''}
            displayValue={props.date ? format(props.date, 'MMM yyyy') : ''}
            leftIcon="calendarOutline"
          />
        )
      default:
        return null
    }
  }

  const getContent = () => {
    switch (props.variant) {
      case 'menu':
        return (
          <PopoverMenu
            options={props.options}
            handleOutsideClick={closePopoverMenu}
            onSelect={closePopoverMenu}
            styles={styles}
          />
        )
      case 'month':
        return <MonthPicker {...props} closePopoverMenu={closePopoverMenu} />
      default:
        return null
    }
  }

  const handleBlur = (event) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      closePopoverMenu()
    }
  }

  return (
    <div
      style={popoverContainer(styles, props.variant)}
      ref={popoverRef}
      onBlur={handleBlur}
    >
      {getTrigger()}
      {open && getContent()}
    </div>
  )
}

const popoverContainer = (
  styles: CSSProperties | undefined,
  variant: PopoverVariant,
): CSSProperties => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
  position: 'relative',
  ...(styles && styles),
  ...(variant === 'month' && {
    width: 'min(100%, 343px)',
  }),
})

const triggerArea: CSSProperties = {
  cursor: 'pointer',
}

export default Popover
