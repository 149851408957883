import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import { Context } from '../../../App'
import { Paths } from '../../../App/Types'
import { Button, Icon, colors } from '../../../../design-system'
import { useTicketEditContext } from '../../../../hooks'

const backButtonStyles = {
  color: colors.util.navy[100],
  fontSize: '14px',
  fontWeight: '500',
  gap: '4px',
  padding: 0,
}

const Header: React.FC = () => {
  const {
    state: {
      user: { isDWEmployee },
      dwExpertsCustomer: { customerShortName },
    },
  } = useContext(Context)
  const {
    isSubmissionLoading,
    submitDraftTicketFields,
    hasValidChangesToSave,
    isEditable,
  } = useTicketEditContext()

  const navigate = useNavigate()

  const handleBackClick = () => {
    let url = `${Paths.TICKETS}`
    url += isDWEmployee ? `?customer=${customerShortName}` : ''

    navigate(url)
  }

  const handleSave = async () => {
    try {
      await submitDraftTicketFields()
    } catch (error) {
      // TODO: Figure out how we'll handle errors in the UI
    }
  }
  const handleSaveAndExit = async () => {
    await handleSave()

    handleBackClick()
  }

  const areButtonsDisabled =
    !hasValidChangesToSave || isSubmissionLoading || !isEditable

  return (
    <header className="flex space-between">
      <Button
        onClick={handleBackClick}
        variant="tertiary"
        buttonStyles={backButtonStyles}
      >
        <Icon variant="arrowBackCircleOutline" size={18} />
        Back
      </Button>
      <div className="flex gap-8 max-content">
        <Button
          variant="secondary"
          disabled={areButtonsDisabled}
          onClick={handleSave}
        >
          Save
        </Button>
        <Button
          variant="secondary"
          disabled={areButtonsDisabled}
          onClick={handleSaveAndExit}
        >
          Save and exit
        </Button>
      </div>
    </header>
  )
}

export default Header
