import { useContext } from 'react'
import { Box } from '@mui/material'

import { useSecurityIndexContext } from '@hooks/useSecurityIndexContext'
import { Context } from '@components/App'
import BanLayoutCommon from '@common/Ban/BanLayoutCommon'
import Ban from '@common/Ban'
import { Delta } from '@common/Delta'

interface SecurityIndexBans {
  selectedTrendEvent: Date | undefined
}

const SecurityIndexBans: React.FC<SecurityIndexBans> = ({
  selectedTrendEvent,
}) => {
  const {
    bans,
    bansLoading,
    bansError,
    yourIndex: {
      trends: { yourTrend },
    },
  } = useSecurityIndexContext()

  const {
    state: {
      customer: { industry },
      dwExpertsCustomer: { industry: dwCustomerIndustry },
    },
  } = useContext(Context)

  const foundTrend =
    yourTrend.find(({ metricDate }) => {
      return selectedTrendEvent?.toISOString().split('T')[0] === metricDate
    })?.index ?? bans?.yourIndex.index

  return (
    <Box
      sx={(theme) => ({
        display: 'grid',
        gap: '1rem',
        maxWidth: '100%',
        '& > div': {
          flex: 1,
        },
        //Applies columns based on screen size
        [theme.breakpoints.down(1100)]: {
          gridTemplateColumns: 'repeat(1, 1fr)',
        },
        [theme.breakpoints.up(1100)]: {
          gridTemplateColumns: 'repeat(3, 1fr)',
        },
      })}
      data-testid="security-index-bans"
    >
      <Ban data-testid="your-index-ban" iconVariant="flashOutline">
        <BanLayoutCommon
          error={bansError != null || foundTrend == null}
          loading={bansLoading}
          title="YOUR INDEX"
          value={foundTrend ? foundTrend.toString() : ''}
        >
          {bans?.yourIndex?.delta != null && (
            <Delta
              delta={Math.round(Number(bans.yourIndex.delta) * 100) / 100}
            />
          )}
        </BanLayoutCommon>
      </Ban>
      <Ban data-testid="avg-customer-index-ban" iconVariant="analytics">
        <BanLayoutCommon
          error={bansError != null || bans?.avgCustomerIndex.index == null}
          loading={bansLoading}
          title="AVG CUSTOMER INDEX"
          value={bans?.avgCustomerIndex.index ?? ''}
        >
          {bans?.avgCustomerIndex.delta != null && (
            <Delta
              delta={
                Math.round(Number(bans?.avgCustomerIndex.delta) * 100) / 100
              }
            />
          )}
        </BanLayoutCommon>
      </Ban>
      <Ban data-testid="avg-industry-index-ban" iconVariant="businessOutline">
        <BanLayoutCommon
          caption={`Your industry is ${industry ? industry.toLowerCase() : dwCustomerIndustry?.toLowerCase()}`}
          error={bansError != null || bans?.avgIndustryIndex.index == null}
          loading={bansLoading}
          title="AVG INDUSTRY INDEX"
          value={bans?.avgIndustryIndex.index ?? ''}
        >
          {bans?.avgIndustryIndex.delta != null && (
            <Delta
              delta={
                Math.round(Number(bans?.avgIndustryIndex.delta) * 100) / 100
              }
            />
          )}
        </BanLayoutCommon>
      </Ban>
    </Box>
  )
}

export default SecurityIndexBans
