import { TicketingComment } from '../../../../../models'
import {
  TicketActivityType,
  TicketActivityTypeEnum,
  TicketCommentActivity,
} from '../../Types'

/**
 * This method takes the data we receive from the backend for the comments (text, type, date, user) and converts
 * them to the data format the ticket activity component expects
 */
export const convertTicketCommentToTicketActivity = (
  data: TicketingComment,
): TicketActivityType => {
  const [firstName, lastName] = data.user.split(' ')

  const userInfo = {
    firstName: firstName || '',
    lastName: lastName || '',
    type: 'cust' as const, // TODO: Update to fetch from backend
    id: '',
  }

  if (data.type === TicketActivityTypeEnum.Comment) {
    return {
      id: crypto.randomUUID(), // The backend doesn't have IDs for the comments
      createdDate: data.time,
      type: TicketActivityTypeEnum.Comment,
      text: data.text,
      userInfo,
    }
  }

  return {
    id: '',
    type: TicketActivityTypeEnum.FieldChanges,
    createdDate: data.time,
    fieldChanges: [],
    userInfo,
  }
}

/**
 * This method takes the client side TicketActivityType and converts it to a TicketingComment type (the value we stored
 * in the cache and get from the server). This is primarily used for updating the cache when a user submits a comment
 */
export const convertTicketActivityToTicketComment = (
  data: TicketCommentActivity,
): TicketingComment => {
  return {
    time: data.createdDate,
    text: data.text,
    type: TicketActivityTypeEnum.Comment,
    user: [data.userInfo.firstName, data.userInfo.lastName].join(' '),
  }
}
