import { ReactNode } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'

import {
  Tooltip,
  Ban,
  BanProps,
  IconVariant,
  colors,
} from '../../../design-system'
import { DetectionCoverageOverviewResponse } from '../../../models/DetectionCoverage'
import { DetectionCoverageDisplayValues } from './utils'
import MaturityBanChart from '../charts/MaturityBan/MaturityBanChart'
import { useMitreOverviewContext } from './context/MitreOverviewContext'
import SplunkUtilization from './SplunkUtilization'
import { DetectionCatalogStatus } from '../Coverage.utils'

function BansGroup({ children }: { children: ReactNode }) {
  return (
    <div
      className="flex-container gap-16 wrap"
      style={{ flexBasis: 0, minWidth: 'fit-content' }}
    >
      {children}
    </div>
  )
}

interface BansContainerProps {
  handleDetectionLibNav: (status: string) => void
  isLoading: boolean
  detectionCoverageData?: Pick<
    DetectionCoverageDisplayValues,
    | 'custAvg'
    | 'scoreDiff'
    | 'currentSplunkUsage'
    | 'availableUseCases'
    | 'enabledUseCases'
    | 'dwAvg'
    | 'industryAvg'
    | 'maxValCustTrend'
    | 'formattedData'
  > &
    Partial<Pick<DetectionCoverageOverviewResponse, 'splunkUtilization'>>
}

export default function Bans({
  handleDetectionLibNav,
  isLoading,
  detectionCoverageData,
}: BansContainerProps) {
  const { featureDetectionLibraryPage } = useFlags()
  const {
    detectionCoverageOverview,
    updateOrCloseSideSheet,
    setIsSideSheetOpen,
  } = useMitreOverviewContext()

  const {
    custAvg,
    scoreDiff,
    availableUseCases,
    currentSplunkUsage,
    enabledUseCases,
    splunkUtilization,
    dwAvg,
    industryAvg,
    maxValCustTrend,
    formattedData,
  } = detectionCoverageData || {
    custAvg: 0,
    dwAvg: 0,
    industryAvg: 0,
    maxValCustTrend: 0,
    scoreDiff: '0',
    availableUseCases: 0,
    currentSplunkUsage: 0,
    enabledUseCases: 0,
    splunkUtilization: undefined,
    formattedData: 0,
  }

  const banProps: BanProps[] = [
    {
      label: 'maturity model',
      iconVariant: 'flashOutline',
      value: `${custAvg}`,
      delta: Number(scoreDiff),
      color: colors.brand.primary.light,
      loading: isLoading,
      onClick: !detectionCoverageData
        ? undefined
        : () => {
            updateOrCloseSideSheet({
              id: 'maturity',
              title: 'Maturity Score',
              children: (
                <MaturityBanChart
                  data={formattedData}
                  dwAvg={dwAvg}
                  custAvg={custAvg}
                  industryAvg={industryAvg}
                  maxTrendValue={maxValCustTrend}
                />
              ),
              closeSideSheet: () => {
                setIsSideSheetOpen(false)
              },
            })
          },
    },
    {
      label: 'splunk utilization',
      iconVariant: 'barcodeOutline',
      value: `${currentSplunkUsage} GB`,
      color: colors.util.three.light,
      progress: !splunkUtilization
        ? undefined
        : (currentSplunkUsage / splunkUtilization.license) * 100,
      loading: isLoading,
      onClick: !detectionCoverageOverview
        ? undefined
        : () => {
            updateOrCloseSideSheet({
              id: 'splunk',
              title: 'Splunk ingest licensing details',
              children: (
                <SplunkUtilization
                  detectionCoverageOverview={detectionCoverageOverview}
                />
              ),
              closeSideSheet: () => {
                setIsSideSheetOpen(false)
              },
            })
          },
    },
    {
      label: 'available detections',
      iconVariant: 'pulse' as IconVariant,
      value: `${availableUseCases}`,
      color: colors.brand.secondary.light,
      loading: isLoading,
    },
    {
      label: 'enabled detections',
      iconVariant: 'cloudDoneOutline' as IconVariant,
      value: `${enabledUseCases}`,
      color: '#ACEC8E',
      loading: isLoading,
      onClick: !featureDetectionLibraryPage
        ? undefined
        : () => {
            handleDetectionLibNav(DetectionCatalogStatus.Enabled)
          },
    },
  ]

  return (
    <>
      <BansGroup>
        <Tooltip
          target={<Ban {...banProps[0]} />}
          wrapperStyles={{
            display: 'flex',
            flex: '1 0 0',
            minWidth: 'fit-content',
          }}
        >
          Your Maturity Score. Compared to the previous 30 days
        </Tooltip>
        <Tooltip
          target={<Ban {...banProps[1]} />}
          wrapperStyles={{
            display: 'flex',
            flex: '1 0 0',
            minWidth: 'fit-content',
          }}
        >{`You are currently using ${banProps[1].value} out of ${detectionCoverageData?.splunkUtilization?.license} GB`}</Tooltip>
      </BansGroup>
      <BansGroup>
        <Tooltip
          target={<Ban {...banProps[2]} />}
          wrapperStyles={{
            display: 'flex',
            flex: '1 0 0',
            minWidth: 'fit-content',
          }}
        >
          Detections that can be enabled based on the latest Deepwatch DWA
          version
        </Tooltip>
        <div
          style={{
            display: 'flex',
            flex: '1 0 0',
            minWidth: 'fit-content',
          }}
        >
          <Ban {...banProps[3]} />
        </div>
      </BansGroup>
    </>
  )
}
