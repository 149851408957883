import { addToastMessage } from '../components/App'
import { Message } from '../models'
import { ToastType } from '../design-system'

export const deployToastMessage = (toastMessage: Message, dispatch) => {
  dispatch(addToastMessage(toastMessage))
}

export const renderErrorToast = (error: unknown, dispatch): void => {
  const { message = 'An error occurred' } = error as Error
  const errorMesage: Message = {
    id: crypto.randomUUID(),
    text: message,
    messageType: ToastType.ErrorToast,
    secondsToExpire: 5000,
    dismissible: false,
  }
  dispatch(addToastMessage(errorMesage))
}
