import { Box } from '@mui/material'

import Icon from '@common/Icon'

interface ErrorLabelProps {
  iconSize?: number
  fontSize?: number
}

export const ErrorLabel: React.FC<ErrorLabelProps> = ({
  iconSize = 24,
  fontSize,
}) => (
  <Box
    sx={(theme) => ({
      color: theme.vars.palette.error.light,
      display: 'flex',
      alignItems: 'center',
      gap: '.5rem',
      fontSize,
    })}
  >
    Error
    <Icon
      variant="alertCircleOutline"
      size={iconSize}
      sx={(theme) => ({ color: theme.vars.palette.error.light })}
    />
  </Box>
)
