/* eslint-disable import/no-unresolved */
import { useState } from 'react'
import { ResponsivePie } from '@nivo/pie'

import {
  Loader,
  colors,
  Icon,
  Typography,
  Card,
} from '../../../../design-system'
import MitreTactic from './MitreTactic'
import { DetectionCoverageOverviewData } from '../../../../models/DetectionCoverage'

export interface AlertsByMitreTacticProps {
  data?: DetectionCoverageOverviewData
}

const MitreAlertChart = ({ data }: AlertsByMitreTacticProps): JSX.Element => {
  const [isHovered, setIsHovered] = useState(false)

  const [activeNode, setActiveNode] = useState('')

  const alertsByMitreTacticMapped = data?.alertsByMitreTactic
    .map((alert) => ({
      id: alert.tactic,
      label: alert.tactic,
      value: alert.incidentCount,
      sequence: data.mitreCoverage.find(
        (technique) => technique.mitreTacticName === alert.tactic,
      )?.mitreTacticSequence,
    }))
    .sort((a, b) => {
      if (!a.sequence || !b.sequence) {
        return 0
      }
      return a.sequence < b.sequence ? -1 : 1
    })

  const chartColors = {
    Reconnaissance: '#7AD9CD',
    'Resource Development': colors.util.navy[50],
    'Initial Access': colors.util.navy[100],
    Execution: colors.common.black,
    Persistence: colors.brand.primary.light,
    'Privilege Escalation': colors.brand.primary.main,
    'Defense Evasion': colors.brand.secondary.light,
    'Credential Access': colors.brand.secondary.main,
    Discovery: colors.util.three.light,
    'Lateral Movement': colors.util.three.main,
    Collection: colors.util.four.lighter,
    'Command and Control': '#FFC368',
    Exfiltration: colors.util.two.light,
    Impact: colors.util.two.main,
  }

  const filteredColors = alertsByMitreTacticMapped?.map(
    (alert) => chartColors[alert.label],
  )

  const chartTheme = {
    labels: {
      text: {
        fontSize: 16,
      },
    },
    tooltip: {
      container: {
        background: '#333',
      },
    },
  }

  const formatNumber = (number: number) => {
    if (number) {
      if (number.toString().length > 6) {
        return new Intl.NumberFormat('en', {
          notation: 'compact',
          compactDisplay: 'short',
        }).format(number)
      } else {
        return new Intl.NumberFormat().format(number)
      }
    }
    return number
  }

  const CenteredMetric = ({ dataWithArc, centerX, centerY }) => {
    let total = 0
    const message = 'Total alerts'
    dataWithArc.forEach((datum) => {
      total += datum.value
    })
    return (
      <g
        tabIndex={0}
        aria-label={`You've had ${formatNumber(total)} MITRE tactic ${message}`}
      >
        <text
          x={centerX}
          y={centerY - 12}
          textAnchor="middle"
          dominantBaseline="central"
          style={{
            fontSize: '28px',
            fontWeight: '600',
            fontFamily: 'Inter',
            fill: colors.util.navy[50],
          }}
        >
          {formatNumber(total)}
        </text>
        <text
          x={centerX}
          y={centerY + 12}
          textAnchor="middle"
          dominantBaseline="central"
          style={{
            fontSize: '14px',
            fontFamily: 'Inter',
            fontWeight: '400',
            fill: colors.util.navy[50],
          }}
        >
          {message}
        </text>
      </g>
    )
  }

  const customTooltip = ({ datum: { id, value, color } }) => {
    return (
      <div className="donut-chart-tool-tip">
        <Typography
          styles={{
            paddingLeft: '1px',
            color: colors.util.navy[100],
          }}
          size={12}
          weight={400}
        >
          Alerts by MITRE Tactic
        </Typography>
        <div
          style={{
            borderTop: '0.75px solid #576569',
            paddingBottom: '2px',
          }}
        ></div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            color,
            paddingTop: '2.5px',
          }}
        >
          <Icon
            size={14}
            variant="square"
            color={color}
            style={{ paddingRight: '3px' }}
          />
          <Typography
            component="span"
            color={colors.util.navy[100]}
            size={12}
            weight={400}
          >
            {value} {id}
          </Typography>
        </div>
      </div>
    )
  }

  const handleMouseEnter = (node) => {
    setActiveNode(node.data.label)
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setActiveNode('')
    setIsHovered(false)
  }

  const mitreColumnClassnames = `mitre-column-container${
    isHovered ? ' hovered' : ''
  }`

  return (
    <>
      <div
        id="mitre-alert-chart-container"
        aria-labelledby="mitre-alert-chart-label"
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0}
      >
        <Card
          backgroundColor={colors.util.navy[400]}
          border="1px solid colors.util.navy[300]"
          borderRadius="5px"
          boxSizing="border-box"
        >
          <Typography
            component="span"
            color={colors.util.navy[100]}
            weight={500}
            size={12}
            styles={{ marginBottom: 10, letterSpacing: 1 }}
          >
            <span aria-hidden={true}>ALERTS BY MITRE TACTIC</span>
          </Typography>

          {!alertsByMitreTacticMapped ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: 250,
              }}
            >
              <Loader strokeWidth={1} size={50} />
            </div>
          ) : (
            <>
              <div
                style={{
                  width: '220px',
                  height: '220px',
                  margin: '0 auto 14px',
                }}
              >
                <ResponsivePie
                  data={alertsByMitreTacticMapped}
                  margin={{ top: 10, bottom: 10 }}
                  innerRadius={0.91}
                  padAngle={0}
                  cornerRadius={0}
                  sortByValue={true}
                  colors={filteredColors}
                  borderWidth={1}
                  borderColor={{ theme: 'background' }}
                  activeOuterRadiusOffset={9}
                  enableArcLabels={false}
                  enableArcLinkLabels={false}
                  tooltip={customTooltip}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  theme={chartTheme}
                  layers={[
                    'arcs',
                    'arcLabels',
                    'arcLinkLabels',
                    'legends',
                    CenteredMetric,
                  ]}
                />
              </div>

              <div className={mitreColumnClassnames} aria-hidden={true}>
                <div className="mitre-column">
                  {alertsByMitreTacticMapped.map((alert, index) => {
                    if (index < alertsByMitreTacticMapped.length / 2) {
                      return (
                        <MitreTactic
                          key={index}
                          active={alert.id === activeNode ? true : false}
                          name={alert.label}
                          color={chartColors[alert.label]}
                          value={alert.value}
                        />
                      )
                    }
                  })}
                </div>

                <div className="mitre-column">
                  {alertsByMitreTacticMapped.map((alert, index) => {
                    if (index >= alertsByMitreTacticMapped.length / 2) {
                      return (
                        <MitreTactic
                          key={index}
                          active={alert.id === activeNode ? true : false}
                          name={alert.label}
                          color={chartColors[alert.label]}
                          value={alert.value}
                        />
                      )
                    }
                  })}
                </div>
              </div>
            </>
          )}
        </Card>
        <p id="mitre-alert-chart-label" hidden>
          Alerts by MITRE Tactic
        </p>
        <div className="mitre-alert-chart-data-interactive">
          <>
            {alertsByMitreTacticMapped?.map((arcData) => {
              return (
                // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                <div key={arcData.label}>
                  MITRE tactic {arcData.label} had{' '}
                  {arcData.value < 2
                    ? `${arcData.value} alert`
                    : `${arcData.value} alerts`}
                </div>
              )
            })}
          </>
        </div>
      </div>
    </>
  )
}

export default MitreAlertChart
