import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Slide,
  Slider,
} from 'pure-react-carousel'
import { format } from 'date-fns'
import { useContext, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Button, Icon, Typography } from '../../../../../design-system'
import { SecurityIndexEvent } from '../../../../../models'
import { CarouselCard } from './CarouselCard'
import { handleKeyBoardAction } from '../../../../../utils'
import { getIconVariant } from '../../SecurityIndex.utils'
import { Paths } from '../../../../App/Types'
import { Context } from '../../../../App'
import { SkeletonBlock } from '../../../../../design-system/components/SkeletonBlock'

import 'pure-react-carousel/dist/react-carousel.es.css'
import './Carousel.scss'

type Carousel = {
  loading: boolean
  events: SecurityIndexEvent[]
  selectedTrendEventState: [
    Date | undefined,
    React.Dispatch<React.SetStateAction<Date | undefined>>,
  ]
}

const Carousel: React.FC<Carousel> = ({
  loading,
  events,
  selectedTrendEventState,
}) => {
  const [visibleSlideCount, setVisibleSlideCount] = useState(0)
  const [backOpactiy, setBackOpactiy] = useState(0.4)
  const [nextOpactiy, setNextOpactiy] = useState(1)
  const [activeSlide, setActiveSlide] = useState<number | string | null>(null)
  const [selectedTrendEvent] = selectedTrendEventState
  const sliderRef = useRef<HTMLDivElement>(null)
  const navigate = useNavigate()
  const { search } = useLocation()
  const {
    state: {
      user: { isDWEmployee },
    },
  } = useContext(Context)
  const shortNameFromUrl = isDWEmployee && search.split('=')[1]

  const filteredEvents = events.filter((datum) =>
    // TODO: update new Date() wrapper
    selectedTrendEvent
      ? new Date(datum.createdAt).getTime() === selectedTrendEvent.getTime()
      : true,
  )

  /**
   * this is used to change the opacity of the arrows if the user
   * clicks either the back or next button, or if they drag the slider.
   * the state variable is needed to trigger a re-render which will trigger
   * the useEffect which will then properly update the DOM with the expected
   * behavior
   */
  const toggleArrowOpacity = () => {
    const allHiddenSlides =
      sliderRef.current &&
      Array.from(sliderRef.current.querySelectorAll('.carousel__slide--hidden'))
    if (allHiddenSlides) {
      const firstSlideHidden = allHiddenSlides.filter((slide) =>
        slide.classList.contains('carousel-slide-0'),
      )
      const lastSlideHidden = allHiddenSlides.filter((slide) =>
        slide.classList.contains(`carousel-slide-${filteredEvents.length - 1}`),
      )

      if (firstSlideHidden.length > 0) {
        setBackOpactiy(1)
      } else {
        setBackOpactiy(0.4)
      }

      if (lastSlideHidden.length > 0) {
        setNextOpactiy(1)
      } else {
        setNextOpactiy(0.4)
      }
    }
  }

  useEffect(() => {
    toggleArrowOpacity()

    if (filteredEvents.length <= 0) {
      setBackOpactiy(0.4)
      setNextOpactiy(0.4)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleSlideCount])

  if (loading) {
    return <SkeletonBlock minHeight={'75px'} width={'100px'} />
  }

  const handleCardClick = (id: string | number) => {
    // interaction between card and chart
    setActiveSlide(id)
    return id
  }

  const handleNextClick = () => {
    setVisibleSlideCount(visibleSlideCount + 1)
  }

  const handleBackClick = () => {
    setVisibleSlideCount(visibleSlideCount - 1)
  }

  const handleViewNearestChange = () => {
    // TODO: navigate to change history tab on click
  }

  const adjustGradient = (bOpactity: number, nOpacity: number): string => {
    if (bOpactity === 1 && nOpacity === 1) {
      return ' first-last-gradient'
    }
    if (bOpactity === 0.4 && nOpacity === 1) {
      return ' last-gradient'
    }
    if (bOpactity === 1 && nOpacity === 0.4) {
      return ' first-gradient'
    }
    return ''
  }

  return (
    <section id="dw-carousel">
      <CarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={40}
        totalSlides={filteredEvents.length}
        visibleSlides={4}
      >
        <div className="carousel-header flex align-center space-between">
          <div className="carousel-header-info flex align-center">
            <Typography component="span" variant="text11medium">
              {filteredEvents.length === 0 ? 'No' : events.length} changes this
              period.
            </Typography>
            <Button
              customClass="security-index-see-all"
              variant="text"
              onClick={() => {
                let customerNameParam = ''
                if (isDWEmployee) {
                  customerNameParam = `?customer=${shortNameFromUrl}`
                }
                navigate(
                  `${Paths.SECURITY_INDEX}${Paths.CHANGE_HISTORY}${customerNameParam}`,
                )
              }}
            >
              see all
            </Button>
          </div>
          <div className="carousel-arrows flex justify-flex-end gap-16">
            <ButtonBack
              className="carousel-arrow"
              onClick={handleBackClick}
              style={{
                opacity: backOpactiy,
                cursor: backOpactiy === 0.4 ? 'initial' : 'pointer',
              }}
            >
              <Icon variant="chevronBack" />
            </ButtonBack>
            <ButtonNext
              className="carousel-arrow"
              onClick={handleNextClick}
              style={{
                opacity: nextOpactiy,
                cursor: nextOpactiy === 0.4 ? 'initial' : 'pointer',
              }}
            >
              <Icon variant="chevronForward" />
            </ButtonNext>
          </div>
        </div>
        {filteredEvents.length <= 0 ? (
          <div style={{ textAlign: 'center' }}>
            <Typography variant="text11">
              No changes have been made to your environment during the selected
              date range.
            </Typography>
            <Button
              variant="secondary"
              customClass="carousel-no-slides-button"
              onClick={handleViewNearestChange}
            >
              View nearest change
            </Button>
          </div>
        ) : (
          <div ref={sliderRef}>
            <Slider
              className={`carousel-container${adjustGradient(
                backOpactiy,
                nextOpactiy,
              )}`}
              onClickCapture={() => toggleArrowOpacity()}
            >
              {filteredEvents.map((card, index) => {
                return (
                  <Slide
                    index={index}
                    key={card.id}
                    className={`carousel-slide carousel-slide-${index}`}
                    onClick={() => handleCardClick(card.id)}
                    onKeyDown={(e) =>
                      handleKeyBoardAction(e, () => handleCardClick(card.id))
                    }
                    aria-label={card.sourceName}
                  >
                    <CarouselCard
                      added={card.action}
                      title={card.sourceName}
                      info={`${format(new Date(card.createdAt), 'MMM do')} • ${
                        card.dataSource
                      }`}
                      id={card.id}
                      iconVariant={getIconVariant(card.type)}
                      isActive={activeSlide === card.id || !!selectedTrendEvent}
                    />
                  </Slide>
                )
              })}
            </Slider>
          </div>
        )}
      </CarouselProvider>
    </section>
  )
}

export default Carousel
