import { AwsAppSyncInterface } from '../interfaces'

const graphqlUrl = process.env.AWS_APPSYNC_GRAPHQL_ENDPOINT
  ? process.env.AWS_APPSYNC_GRAPHQL_ENDPOINT
  : ''

const graphqlRegion = process.env.AWS_APPSYNC_REGION
  ? process.env.AWS_APPSYNC_REGION
  : ''

const graphqlAuthType = process.env.AWS_APPSYNC_AUTHENTICATION_TYPE
  ? process.env.AWS_APPSYNC_AUTHENTICATION_TYPE
  : ''

const awsAppSync: AwsAppSyncInterface = {
  aws_appsync_graphqlEndpoint: graphqlUrl,
  aws_appsync_region: graphqlRegion,
  aws_appsync_authenticationType: graphqlAuthType,
}

export default awsAppSync
