import { gql } from '@apollo/client'

import { Customer } from '../../models'

export interface GetCustomerNamesResponse {
  getCustomerInfo: Customer
}

export interface GetCustomerInfoData {
  getCustomerInfo: Customer
}

export interface GetCustomerInfoVariables {
  selectedCustomer: string | null
}

export const GET_CUSTOMER_INFO_WITH_TA_VERSIONS = gql`
  query GetCustomerInfo($selectedCustomer: String) {
    getCustomerInfo(selectedCustomer: $selectedCustomer) {
      customerDwaAppVersion
      deepwatchLatestDwaAppVersion
    }
  }
`

export const GET_CUSTOMER_INFO_WITH_MATURITY_START_DATE = gql`
  query GetCustomerInfo($selectedCustomer: String) {
    getCustomerInfo(selectedCustomer: $selectedCustomer) {
      maturityScoreStartDate
      customerShortName
      customerName
      settings {
        isOktaFederated
      }
    }
  }
`

export const GET_CUSTOMER_INFO_WITH_FULL_DETAILS = gql`
  query GetCustomerInfo($selectedCustomer: String) {
    getCustomerInfo(selectedCustomer: $selectedCustomer) {
      customerShortName
      customerName
      maturityScoreStartDate
      settings {
        isOktaFederated
      }
      numOfEmployees
      industry
      address {
        street
        city
        state
        postalCode
      }
    }
  }
`
