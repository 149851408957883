import { Fragment, useState } from 'react'

import { ColumnHeader, SortingOrder } from '../Table.types'
import { useTheme } from '../../../hooks'
import { colors } from '../../../theme'
import { findAndScrollToTop } from '../Table.utils'
import { Icon } from '../../Icon'

export interface TableHeaderProps {
  tableHeaders: ColumnHeader[]
  headerBodyColor?: string
  headerTextColor?: string
  isDarkTheme?: boolean
  bodyColor?: string
  sortDir: SortingOrder
  sortIndex: number
  sortTableColumns: (direction: SortingOrder, index: number) => void
  secondarySortIndex?: number
  secondarySortDirection?: SortingOrder
  allowSorting?: boolean
}

interface TableHeadOnClickArgs {
  index: number
  onClickOverride?: (
    sortDirection: SortingOrder,
    index: number,
    secondaryIndex?: string | number,
    secondarySortDirection?: SortingOrder | '',
  ) => void
  sortable: boolean
  sortDirection: SortingOrder
  sortIndex: number
}

const TableHeader = ({
  tableHeaders,
  headerTextColor = '',
  headerBodyColor = '',
  sortDir,
  sortIndex,
  sortTableColumns,
  secondarySortIndex,
  secondarySortDirection,
  allowSorting = true,
}: TableHeaderProps): JSX.Element => {
  const [sortOrder, setSortOrder] = useState(sortDir)
  const [showToolTip, setShowTooltip] = useState(false)
  const isDarkTheme = useTheme('dark')

  const tableHeaderBodyColor = (
    headerColor: string,
    isDarkTheme: boolean,
  ): string => {
    if (headerColor) {
      return headerColor
    } else if (isDarkTheme) {
      return colors.util.navy[500]
    } else {
      return colors.util.one.light
    }
  }

  const tableHeaderTextColor = (
    headerTextColor: string,
    isDarkTheme: boolean,
  ): string => {
    if (headerTextColor) {
      return headerTextColor
    } else if (isDarkTheme) {
      return colors.util.one.light
    } else {
      return colors.util.one.main
    }
  }

  const handleOnClick = (options: TableHeadOnClickArgs): SortingOrder => {
    const { index, sortable, sortDirection, sortIndex, onClickOverride } =
      options

    //Use to get the proper secondary sort direction
    const secSortDirection =
      tableHeaders[`${index}`].secondarySortDirection ?? sortDirection

    //Use to get the proper secondary sort index
    const secIndex = tableHeaders[`${index}`].secondarySortId
      ? tableHeaders[`${index}`].secondarySortId
      : secondarySortIndex

    let firstClick = false
    let result = sortDirection
    if (sortable) {
      if (index === sortIndex) {
        firstClick = true
      } else {
        firstClick = false
        onClickOverride?.(sortDir, index, secIndex, secondarySortDirection)
        setSortOrder(sortDir)
        result = sortDir
      }

      if (firstClick) {
        if (sortDirection === 'ASC') {
          onClickOverride?.('DESC', index, secIndex, secSortDirection)
          setSortOrder('DESC')
          firstClick = false
          result = 'DESC'
        } else {
          onClickOverride?.('ASC', index, secIndex, secSortDirection)
          setSortOrder('ASC')
          firstClick = false
          result = 'ASC'
        }
      }
    }
    return result
  }

  const triggerEvent = (
    event: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent,
    sortable: boolean,
    index: number,
    onClickOverride:
      | ((sortDirection: SortingOrder, index: number) => void)
      | undefined,
  ): void => {
    handleOnClick({
      sortable,
      sortDirection: sortOrder,
      index,
      sortIndex,
      onClickOverride: onClickOverride ?? sortTableColumns,
    })

    if (sortable) {
      findAndScrollToTop(
        event.currentTarget.parentElement,
        'datatable datatable-sticky',
      )
    }
  }

  return (
    <div className="header-row" role="row" tabIndex={0}>
      {tableHeaders.map(
        (
          {
            visible = true,
            sortable = false,
            name,
            tooltip = false,
            tooltipMessage,
            onClickOverride,
            columnWidth = '',
          }: ColumnHeader,
          index,
        ): JSX.Element => {
          const isSortingEnabled = sortIndex === index && allowSorting

          if (visible) {
            return (
              <div
                role="columnheader"
                key={`header-${index}-${name}`}
                className="header-cell"
                tabIndex={0}
                onClick={(e) =>
                  triggerEvent(
                    e,
                    sortable && allowSorting,
                    index,
                    onClickOverride,
                  )
                }
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    triggerEvent(
                      e,
                      sortable && allowSorting,
                      index,
                      onClickOverride,
                    )
                  }
                }}
                onMouseEnter={tooltip ? () => setShowTooltip(true) : undefined}
                onMouseLeave={tooltip ? () => setShowTooltip(false) : undefined}
                style={{
                  color: isSortingEnabled
                    ? colors.util.navy[50]
                    : tableHeaderTextColor(headerTextColor, isDarkTheme),
                  backgroundColor: tableHeaderBodyColor(
                    headerBodyColor,
                    isDarkTheme,
                  ),
                  cursor: isSortingEnabled ? 'pointer' : 'default',
                  width: columnWidth,
                }}
                id={name}
                data-testid="table-header-cell"
              >
                {name}
                {
                  <Icon
                    size={16}
                    variant={
                      sortOrder === 'DESC' && index === sortIndex
                        ? 'arrowDown'
                        : 'arrowUp'
                    }
                    color={
                      isSortingEnabled && allowSorting
                        ? colors.util.navy[50]
                        : 'transparent'
                    }
                    style={{ verticalAlign: 'bottom' }}
                  />
                }
                {showToolTip && tooltip ? (
                  <span className="table-head-tooltip">{tooltipMessage}</span>
                ) : null}
              </div>
            )
          } else {
            return <Fragment key={`header-${index}-${name}`} />
          }
        },
      )}
    </div>
  )
}

export default TableHeader
