import { Defs, linearGradientDef } from '@nivo/core'
import { area } from 'd3-shape'

export const styledMaturityLines = ({
  series,
  lineGenerator,
  xScale,
  yScale,
}): React.ReactNode => {
  return series.map(({ id, data }, index: number) => (
    <path
      key={id}
      d={lineGenerator(
        data.map((d) => ({
          x: xScale(d.data.x),
          y: yScale(d.data.y),
        })),
      )}
      fill="none"
      stroke={index === 0 || index === 3 ? '#00CC66' : '#3AB6E0'}
      style={
        index === 3
          ? { border: '1.5px', strokeDasharray: '10', strokeWidth: 2 }
          : { border: '1.5px solid', strokeWidth: 2 }
      }
    />
  ))
}

export const areaLayer = ({ series, xScale, yScale }): JSX.Element => {
  const areaGenerator = area()
    .x((d) => xScale(d['data'].x))
    .y0(() => yScale(0))
    .y1((d) => yScale(d['data'].y))
  return (
    <>
      <Defs
        defs={[
          linearGradientDef('customerGradient', [
            { offset: 0, color: '#00CC66', opacity: 0.4 },
            { offset: 100, color: '#00CC66', opacity: 0.0 },
          ]),
        ]}
      />
      <path
        className="area-cust-line"
        d={areaGenerator(series[0].data) || undefined}
        fill="url(#customerGradient)"
      />
    </>
  )
}
