import { CustomLayerProps, Point, ResponsiveLine, Serie } from '@nivo/line'
import { DotsItem } from '@nivo/core'
import format from 'date-fns/format'
import { pulseOutline, serverOutline } from 'ionicons/icons'

import SliceTooltip from './SliceTooltip/SliceTooltip'
import TrendSelector from './TrendSelector/TrendSelector'
import IndexDetails from './IndexDetails/IndexDetails'
import { Typography, colors } from '../../../../../../../design-system'
import {
  eventMockData2,
  nivoTestData,
} from '../../../../../../../../mockData/SecurityIndexFactory'
import Indicator from './Indicator/Indicator'
import {
  CHANGE_HISTORY_LOG_SOURCE_REMOVED,
  ChangeHistoryType,
} from '../../../../../../../models/SecurityIndex'

import './IndexTrend.scss'

interface IndexTrend {
  selectedTrendEventState: [
    Date | undefined,
    React.Dispatch<React.SetStateAction<Date | undefined>>,
  ]
}

export const trendColors = {
  'Your Index': colors.brand.primary.main,
  'Avg. DW customer': colors.util.three.light,
  'Avg. Industry': colors.util.four.light,
  'Past period': colors.util.navy[50],
}

const mapAndFlattenSerieDatumSortedByDateAsc = (data: Serie[]) =>
  data
    .flatMap((serie) => serie.data.map((datum) => datum.x as Date))
    .sort((a, b) => a.getTime() - b.getTime())

const domParser = new DOMParser()
const xmlSerializer = new XMLSerializer()

const IndexTrend: React.FC<IndexTrend> = ({ selectedTrendEventState }) => {
  const [selectedTrendDate, setSelectedTrendEvent] = selectedTrendEventState
  /**
   * Custom Nivo chart layer that adds dots to the end of each trend line
   */
  const endingPoints = (props: CustomLayerProps) => {
    const endPoints = [
      props.points.findLast((point) => point.serieId === 'Your Index'),
      props.points.findLast((point) => point.serieId === 'Avg. DW customer'),
      props.points.findLast((point) => point.serieId === 'Avg. Industry'),
      props.points.findLast((point) => point.serieId === 'Past period'),
    ].filter(Boolean) as Point[]
    return (
      <g>
        {endPoints.map((point) => (
          <DotsItem
            key={point.id}
            x={point.x}
            y={point.y}
            datum={point.data}
            size={props.pointSize ?? 0}
            color={point.color}
            borderWidth={props.pointBorderWidth ?? 0}
            borderColor={point.borderColor}
            labelYOffset={props.pointLabelYOffset}
          />
        ))}
      </g>
    )
  }
  /**
   * Custom Nivo chart layer which draws the green "Your Index" line on top of the dark gray one
   * highlighting interaction with the event indicators at a given point in time.
   */
  const yourIndexLineOverlay = (props: CustomLayerProps) => {
    const allTrendDatesSortedAsc = mapAndFlattenSerieDatumSortedByDateAsc(
      props.data,
    )
    const latestSortedDate =
      allTrendDatesSortedAsc[allTrendDatesSortedAsc.length - 1]
    const yourIndexPoints = props.points.filter((point) =>
      selectedTrendDate
        ? point.serieId === 'Your Index' && point.data.x <= selectedTrendDate
        : point.serieId === 'Your Index',
    )
    const yourIndexCoordinates = yourIndexPoints.map(({ x, y }) => ({
      x: x,
      y: y,
    }))
    const latestDataPoint = yourIndexPoints[yourIndexPoints.length - 1]
    const latestYourIndexTrendDate = latestDataPoint.data.x as Date
    return (
      <g>
        <path
          d={props.lineGenerator(yourIndexCoordinates) ?? ''}
          fill="none"
          stroke={trendColors['Your Index']}
          style={{ pointerEvents: 'none' }}
        />
        {latestYourIndexTrendDate.getTime() === latestSortedDate.getTime() ? (
          <DotsItem
            x={latestDataPoint.x}
            y={latestDataPoint.y}
            datum={latestDataPoint.data}
            size={props.pointSize ?? 0}
            color={trendColors['Your Index']}
            borderWidth={props.pointBorderWidth ?? 0}
            borderColor={latestDataPoint.borderColor}
            labelYOffset={props.pointLabelYOffset}
          />
        ) : undefined}
      </g>
    )
  }
  /**
   * Custom Nivo chart layer that draws the bottom (x) axis line the length of the innerWidth
   * and the brackets on each side below the axis line
   */
  const bottomAxisWithBrackets = ({
    innerHeight,
    innerWidth,
  }: CustomLayerProps) => {
    return (
      <g stroke={colors.util.navy[300]} strokeWidth={1}>
        <line
          x1={0}
          x2={0}
          y1={innerHeight + 8} // 8px of padding from bottom axis
          y2={innerHeight + 8 + 17} // 8px padding 17px height
        />
        <line x1={0} x2={innerWidth} y1={innerHeight} y2={innerHeight} />
        <line
          x1={innerWidth}
          x2={innerWidth}
          y1={innerHeight + 8} // 8px of padding from bottom axis
          y2={innerHeight + 8 + 17} // 8px padding 17px height
        />
      </g>
    )
  }
  /**
   * Custom Nivo chart layer that adds the bottom (x) axis tick extrema in the desired format
   */
  const xAxisExtrema = (props: CustomLayerProps) => {
    const allTrendDatesSortedAsc = mapAndFlattenSerieDatumSortedByDateAsc(
      props.data,
    )
    return (
      <g fill={colors.util.navy[100]}>
        <text
          x={4}
          y={props.innerHeight + 8 + 17}
          className="text-12"
          fill={colors.util.navy[100]}
        >
          {format(allTrendDatesSortedAsc[0], 'MMM d')}
        </text>
        <text
          x={props.innerWidth - 4}
          y={props.innerHeight + 8 + 17}
          className="text-12 rtl"
          fill={colors.util.navy[100]}
        >
          {format(
            allTrendDatesSortedAsc[allTrendDatesSortedAsc.length - 1],
            'MMM d',
          )}
        </text>
      </g>
    )
  }
  const yourTrendEventIndicators = (props: CustomLayerProps) => {
    return props.points
      .filter((point) => point.serieId === 'Your Index')
      .map((point) => {
        const xDate = point.data.x as Date
        const indicatorOriginX = point.x - 7 //? point x value - half the width of the SVG for horizontalcentering
        const indicatorOriginY = point.y - 14 - 8 - 2 //? 14px SVG height - 8px for centering within <polygon> element within <IndicatorRoot> - 2px for spacing above trend line
        const events = eventMockData2.filter(
          (event) => event.createdAt.getTime() === xDate.getTime(),
        )
        const isIcon = events.length === 1
        if (events.length) {
          /**
           * Calculate the padding needed for the text element
           * to be positioned in the center of the indicator
           */
          let textWidth = 4
          if (events.length > 9) {
            if (events.length > 19) {
              textWidth = 8
            }
            //? less padding required to center if the number contains a '1', probably due to kerning
            if (String(events.length).includes('1')) {
              textWidth = 7
            }
          }
          let innerHTML = `<text class="text-12" x="${
            point.x - textWidth
          }" y="${point.y - 12}">${events.length}</text>`
          if (isIcon) {
            const sVGDataString =
              events[0].type === ChangeHistoryType.LOG_SOURCE
                ? serverOutline
                : pulseOutline
            innerHTML = sVGDataString.split(',')[1] //remove Data URL
            const svgDocument = domParser.parseFromString(
              innerHTML,
              'image/svg+xml',
            )
            const svg = svgDocument.children.item(0)
            svg?.setAttribute('width', '14px')
            svg?.setAttribute('height', '14px')
            svg?.setAttribute('x', String(indicatorOriginX))
            svg?.setAttribute('y', String(indicatorOriginY))
            innerHTML = xmlSerializer.serializeToString(svgDocument)
          }
          return (
            <Indicator
              key={point.id}
              x={indicatorOriginX}
              y={indicatorOriginY}
              groupClassNames={{
                pink:
                  isIcon &&
                  events[0].action === CHANGE_HISTORY_LOG_SOURCE_REMOVED,
                active: xDate.getTime() === selectedTrendDate?.getTime(),
                inactive:
                  selectedTrendDate &&
                  xDate.getTime() !== selectedTrendDate.getTime(),
              }}
              innerHTML={innerHTML}
              onClick={() => {
                xDate.getTime() === selectedTrendDate?.getTime()
                  ? setSelectedTrendEvent(undefined)
                  : setSelectedTrendEvent(xDate)
              }}
            />
          )
        }
      })
  }
  return (
    <div id="index-trend" className="flex" data-testid="index-trend">
      <div className="flex column gap-16" style={{ width: '100%' }}>
        <div className="flex align-center space-between">
          <Typography component="div" weight={600} size={16}>
            Index trend
          </Typography>
          <TrendSelector />
        </div>
        <div className="trend-container">
          <ResponsiveLine
            animate
            curve="step"
            theme={{
              axis: {
                ticks: {
                  text: {
                    fontSize: 12,
                    fill: colors.util.navy[100],
                  },
                },
              },
              crosshair: {
                line: {
                  stroke: colors.util.navy[100],
                  strokeWidth: 0.5,
                  strokeDasharray: '0',
                },
              },
            }}
            data={nivoTestData}
            xScale={{ type: 'time', useUTC: false, precision: 'day' }}
            margin={{ top: 30, right: 30, bottom: 41, left: 30 }}
            lineWidth={1.25}
            colors={[
              colors.util.navy[200], // Different than trendColor value as we'll have to use a custom layer to show the green line due to indicator selection functionality
              trendColors['Avg. DW customer'],
              trendColors['Avg. Industry'],
              trendColors['Past period'],
            ]}
            enableSlices="x"
            enablePoints={false}
            axisBottom={{
              format: '%b %e',
              tickValues: 0,
            }}
            axisLeft={{
              format: '>-0.1f',
              tickSize: 0,
              tickPadding: 5,
              tickRotation: 0,
            }}
            layers={[
              'axes',
              'areas',
              'crosshair',
              'lines',
              'markers',
              'mesh',
              'slices',
              endingPoints,
              yourIndexLineOverlay,
              bottomAxisWithBrackets,
              xAxisExtrema,
              yourTrendEventIndicators,
            ]}
            sliceTooltip={({ slice }) => <SliceTooltip slice={slice} />}
          />
        </div>
      </div>
      <IndexDetails />
    </div>
  )
}

export default IndexTrend
