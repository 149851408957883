import { ResponsiveBar } from '@nivo/bar'
import { Box, useTheme } from '@mui/material'

import { themeConfig } from '@common/nivo/utils'

import { getUtilizationBySourceTypeAverageColors } from '../SourceUtilization.utils'

const UtilizationByIndexAverageChart = ({ data }) => {
  const theme = useTheme()

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        minHeight: 0,
        minWidth: 0,
        width: '99%',
      }}
    >
      <ResponsiveBar
        data={data}
        keys={['value']}
        indexBy="ranking"
        margin={{
          top: 50,
          right: 19,
          bottom: 36,
          left: 36,
        }}
        padding={0.6}
        groupMode="grouped"
        indexScale={{ type: 'band', round: true }}
        borderColor={theme.vars.palette.secondary.light}
        valueScale={{
          type: 'linear',
          max: 'auto',
          min: 0,
        }}
        colors={getUtilizationBySourceTypeAverageColors(theme)}
        colorBy={'indexValue'}
        enableLabel={false}
        theme={themeConfig(theme.palette.mode, theme)}
        axisTop={null}
        axisRight={null}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'food',
          legendPosition: 'middle',
          legendOffset: -40,
          truncateTickAt: 0,
        }}
      />
    </Box>
  )
}

export default UtilizationByIndexAverageChart
