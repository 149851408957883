import classNames from 'classnames'

import { ButtonProps } from '../../interfaces'
import { Icon } from '../Icon'

import './Button.scss'

const Button: React.FC<ButtonProps> = ({
  buttonStyles,
  children,
  customClass,
  disabled = false,
  iconProps,
  iconStyles,
  label = 'Button',
  onClick,
  testId,
  type = 'button',
  variant = 'primary',
}) => {
  const leftIcon = iconProps?.position === 'left'
  const rightIcon = iconProps?.position === 'right'
  const isIconButton = variant === 'icon' || variant === 'navigational-icon'
  const isCommonButton =
    variant === 'primary' || variant === 'secondary' || variant === 'tertiary'

  const buttonClass = classNames(
    'button',
    variant,
    {
      'with-icon': leftIcon || rightIcon,
      common: isCommonButton,
    },
    customClass,
  )

  return (
    <button
      className={buttonClass}
      data-testid={testId}
      disabled={disabled}
      name={label}
      onClick={onClick}
      type={type}
      style={buttonStyles}
    >
      {isIconButton ? (
        <Icon
          {...iconProps}
          style={iconStyles}
          title={label}
          data-testid={`${label}-icon`}
        />
      ) : (
        <>
          {leftIcon && <Icon {...iconProps} style={iconStyles} />}
          {children || label}
          {rightIcon && <Icon {...iconProps} style={iconStyles} />}
        </>
      )}
    </button>
  )
}

export default Button
