import { Input } from '../../../../../design-system'
import { useTicketEditContext } from '../../../../../hooks'
import { MttxRow } from '../../Types'
import { defineColumnKeys, makeMttxColumnLabels } from '../../utils'

import './Mttx.scss'

export const MttxForm = (data: MttxRow): JSX.Element => {
  const mttxData = { ...data.data }
  delete mttxData.__typename

  const columns = defineColumnKeys(mttxData)

  const { isEditable } = useTicketEditContext()

  /* This function accomplishes the following:
  
    1:Iterates through the expected MTTx object from the ticket data to render input fields that are default disabled. 
    2:The defineColumnKeys function is used to create as close to even number of columns. 
    3:The makeMttxColumnLabels makes appropriate label case based on the label type (MTTx or customerTime etc..)
  */
  const MttxColumn = (columnIndex: number): JSX.Element => {
    return (
      <div className="flex column gap-24 mttx-form-column">
        {Object.entries(mttxData)
          .filter((key) => columns[columnIndex].includes(key[0]))
          .map(([key, value]) => {
            return (
              <Input
                key={key}
                labelId={key}
                styles={{
                  marginBottom: '12px',
                  fontSize: '14px',
                }}
                label={makeMttxColumnLabels(key)}
                placeholder={'--'}
                required={true}
                value={!value ? '--' : `${value}`}
                name={key}
                type="text"
                /* eslint-disable jsx-a11y/no-autofocus */
                disabled={true}
                isEditable={isEditable}
              />
            )
          })}
      </div>
    )
  }

  return (
    <div className="flex gap-24 mttx-form-column">
      {MttxColumn(0)}
      {MttxColumn(1)}
    </div>
  )
}
