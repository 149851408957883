import {
  Tooltip,
  Typography,
  colors,
  ProgressBar,
} from '../../../../design-system'

export type topLogSourcesProps = {
  title: string
  data?: {
    label: string
    value: number | string
    progress: number
  }[]
}

export const getTextColor = (progressNum: number): string => {
  if (progressNum >= 90) return colors.util.two.lighter
  else if (progressNum >= 50) return colors.util.four.light
  else return colors.util.navy[50]
}

const TopLogSources = ({ title, data }: topLogSourcesProps): JSX.Element => {
  const topLogSources = data?.map((datum, index) => {
    const getTooltipMessage = () => {
      if (title === 'Top 5 log sources by utilization') {
        return (
          <span>
            {datum.label} accounts for {datum.progress.toFixed(2)}% of your
            Splunk usage.
          </span>
        )
      } else {
        return (
          <span>
            {datum.label} accounts for {datum.progress.toFixed(2)}% of your
            alerts.
          </span>
        )
      }
    }

    return (
      <Tooltip
        key={datum.label}
        target={
          <div
            className="flex-container column gap-8"
            data-testid="detection-coverage-top-log-sources"
            key={index}
          >
            <div
              className="flex-container space-between"
              tabIndex={0}
              role={'group'}
            >
              <Typography
                component="span"
                color={getTextColor(datum.progress)}
                weight={400}
                styles={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  width: 0,
                  flex: 1,
                }}
              >
                {datum.label}
              </Typography>
              <Typography
                component="span"
                color={getTextColor(datum.progress)}
                weight={400}
                styles={{ marginLeft: 50 }}
              >
                {datum.value}
              </Typography>
            </div>
            <ProgressBar
              value={datum.progress}
              maxWidth={undefined}
              padding={2}
              height={6}
              borderRad={2}
              breakpoints={[
                {
                  threshold: 50,
                  color: '#FFC368',
                },
                {
                  threshold: 90,
                  color: colors.util.two.light,
                },
              ]}
              enableBackground={true}
              enabledBackGroundColor={colors.util.navy[400]}
            />
          </div>
        }
      >
        {getTooltipMessage()}
      </Tooltip>
    )
  })

  return (
    <div className="flex-container column gap-16" data-testid="top-log-sources">
      <Typography
        component="span"
        color={colors.util.navy[100]}
        variant="text9semibold"
      >
        {title}
      </Typography>
      {topLogSources}
    </div>
  )
}

export default TopLogSources
