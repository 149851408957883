import React, { useContext } from 'react'
import { useQuery } from '@apollo/client'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { Context } from '../../App'
import { renderErrorToast } from '../../../utils'
import { Typography, colors, Icon, Tabs } from '../../../design-system'
import { AllCustomers } from '../AllCustomers'
import MyCustomers from '../MyCustomers/MyCustomers'
import MCEmptyState from '../MyCustomers/MCEmptyState'
import { CustomerHighlight } from '../../../models'
import {
  GET_DEEPWATCH_EXPERTS_DASHBOARD,
  GET_DW_EXPERTS_HOME_DATA,
  GetDeepwatchExpertsResponse,
} from '../../../graphql/queries/dwExperts'

import './DeepwatchExpertsHome.scss'

const DeepwatchExpertsHome = (): JSX.Element => {
  const {
    state: { user },
    dispatch,
  } = useContext(Context)

  const { featureAssignedCustomers } = useFlags()

  const queryToExecute = featureAssignedCustomers
    ? GET_DW_EXPERTS_HOME_DATA
    : GET_DEEPWATCH_EXPERTS_DASHBOARD

  const {
    data: {
      getBookmarkedCustomers: bookmarkedCustomers,
      getSquadDashboard: allCustomers,
    } = { getBookmarkedCustomers: [], getSquadDashboard: [] },
    loading,
  } = useQuery<GetDeepwatchExpertsResponse>(queryToExecute, {
    onError: (error) => {
      renderErrorToast(error, dispatch)
    },
  })

  let customerHighlights: CustomerHighlight[]

  if (featureAssignedCustomers) {
    // replace customer with bookmarked customer
    // necessary to show proper labels/buttons on AllCustomers
    customerHighlights = allCustomers.map((cust) => {
      const match = bookmarkedCustomers.find(
        (bookCust) => bookCust.customerShortName === cust.customerShortName,
      )

      // have to make a copy otherwise reference is passed, leading to wonky behavior
      return match ? { ...match } : cust
    })
  } else {
    customerHighlights = allCustomers
  }

  const dwExpertsTabs = [
    {
      id: 0,
      text: 'My Customers',
      active: true,
      notification: false,
    },
    {
      id: 1,
      text: 'All',
      active: true,
      notification: false,
    },
  ]

  const tabsContent: JSX.Element[] = [
    bookmarkedCustomers && bookmarkedCustomers.length === 0 ? (
      <MCEmptyState
        key={0}
        customerHighlights={customerHighlights}
        loading={loading}
      />
    ) : (
      <MyCustomers
        key={0}
        bookmarkedCustomers={bookmarkedCustomers}
        loading={loading}
      />
    ),
    <AllCustomers
      key={1}
      customerHighlights={customerHighlights}
      loading={loading}
    />,
  ]

  return (
    <div data-testid="dw-experts-home" id="dw-experts-home">
      <div className="dw-experts-dashboard-content-bar">
        <Typography
          component="h2"
          size={22}
          variant="text8"
          color={colors.util.navy[100]}
          styles={{ margin: 0 }}
        >
          {`Welcome, ${user.firstName}`}
        </Typography>
        <div className="dw-experts-dashboard-info">
          <Icon variant={'informationCircleOutline'} />

          <Typography
            size={80}
            weight={500}
            component="h2"
            variant="text11"
            color={colors.util.navy[100]}
          >
            All data is updated daily
          </Typography>
        </div>
      </div>

      {featureAssignedCustomers ? (
        <>
          <Tabs data={dwExpertsTabs} activeTab={0}>
            {tabsContent}
          </Tabs>
        </>
      ) : (
        <AllCustomers
          customerHighlights={customerHighlights}
          loading={loading}
        />
      )}
    </div>
  )
}

export default DeepwatchExpertsHome
