import { useSearchParams } from 'react-router-dom'
import { Box } from '@mui/material'

import { TicketLibrary } from './TicketLibrary'
import { TicketEdit } from './TicketEdit'
import { TicketsContextProvider } from './context/TicketsContext'
import TicketOverview from './TicketOverview/TicketOverview'

interface TicketsProps {
  component?: 'library' | 'edit' | 'overview'
}

const components = {
  library: TicketLibrary,
}

const Tickets = ({ component = 'library' }: TicketsProps) => {
  const [searchParams] = useSearchParams()

  const selectedCustomer = searchParams.get('customer') || ''

  components['edit'] = TicketEdit

  components['overview'] = TicketOverview

  const ResultComponent = components[`${component}`]

  return (
    <TicketsContextProvider selectedCustomer={selectedCustomer}>
      <Box data-testid="tickets" sx={{ overflowY: 'auto', width: '100%' }}>
        {components[`${component}`] && <ResultComponent />}
      </Box>
    </TicketsContextProvider>
  )
}

export default Tickets
