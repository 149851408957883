import { ChangeHistoryType } from '@models/SecurityIndex'
import { IconVariant } from '@common/Icon/Icons'

export const getIconVariant = (sourceType: ChangeHistoryType): IconVariant => {
  switch (sourceType) {
    case ChangeHistoryType.LOG_SOURCE:
      return 'serverOutline'
    case ChangeHistoryType.DETECTION:
      return 'pulse'
    case ChangeHistoryType.APP_VERSION_CHANGE:
      return 'cogOutline'
    default:
      return 'downloadOutline'
  }
}

export const highlightMetric = (
  activeId: string,
  partId: string,
  colorId: string,
): string => {
  if (activeId !== '' && activeId !== partId) {
    return '#647980'
  } else {
    return colorId
  }
}

export const getEventText = (action: boolean, type: ChangeHistoryType) => {
  if (type === ChangeHistoryType.APP_VERSION_CHANGE) {
    return 'DWA version change'
  } else if (type === ChangeHistoryType.LOG_SOURCE) {
    if (action) {
      return 'source type added'
    } else {
      return 'source type removed'
    }
  } else if (action) {
    return `${type} added`
  } else {
    return `${type} removed`
  }
}
