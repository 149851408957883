import { Paths } from '@components/App/Types'

interface NgStatuses {
  ngMdrEnabled?: boolean
  ngMedrEnabled?: boolean
  ngMfwEnabled?: boolean
  isLimitedMdrExperience?: boolean
}

type ModulesStatuses = {
  edrEnabled?: boolean
} & NgStatuses

type RequiredMetadata = {
  path: string
}

export type NavTreeMetadata = RequiredMetadata &
  Record<string, string | number | boolean | undefined | null>

type NavTreeNode = {
  id: string
  name: string
  children?: NavTreeNode[]
  metadata: NavTreeMetadata
}

type NavTree = {
  id: 'root'
  name: ''
  children: NavTreeNode[]
}

export const buildNavTree = (
  moduleStatuses: ModulesStatuses,
  isDWEmployee: boolean | undefined,
  isAdmin: boolean,
  featureEnvHealth: boolean,
  featureDefenderForEndpoint: boolean,
): NavTree => {
  const children: NavTreeNode[] = [
    {
      id: 'mdr',
      name: 'MDR',
      children: getMDRItems(
        moduleStatuses.isLimitedMdrExperience,
        featureEnvHealth,
      ),
      metadata: {
        path: Paths.MDR_OVERVIEW,
        icon: 'shieldCheckmarkOutline',
      },
    },
  ]

  if (moduleStatuses.ngMedrEnabled || moduleStatuses.edrEnabled) {
    children.push({
      id: 'endpoint',
      name: 'Endpoint',
      children: [
        {
          id: 'endpoint-asset-library',
          name: featureDefenderForEndpoint
            ? 'Device Inventory'
            : 'Asset Library',
          metadata: {
            path: featureDefenderForEndpoint
              ? Paths.EDR_DEVICES
              : Paths.EDR_ASSETS,
            icon: 'bookOutline',
          },
        },
      ],
      metadata: {
        path: Paths.EDR,
        icon: 'logoWebComponent',
      },
    })
  }

  if (moduleStatuses.ngMfwEnabled) {
    children.push({
      id: 'firewall',
      name: 'Firewall',
      metadata: { path: Paths.FIREWALL, icon: 'flameOutline' },
    })
  }

  // add threat intel
  children.push({
    id: 'threat-intel',
    name: 'Threat Intelligence',
    children: [],
    metadata: {
      path: Paths.THREAT_INTEL_REPORTS,
      icon: 'globeOutline',
      hasDivider: true,
    },
  })

  // add ticketing
  children.push({
    id: 'tickets',
    name: 'Tickets',
    children: [
      {
        id: 'ticket-library',
        name: 'Ticket Library',
        metadata: {
          path: Paths.TICKET_LIBRARY,
          icon: 'libraryOutline',
        },
      },
      {
        id: 'create-ticket',
        name: 'Create Ticket',
        metadata: {
          icon: 'addCircleOutline',
          path: Paths.TICKET_OVERVIEW,
        },
      },
    ],
    metadata: {
      path: Paths.TICKET_OVERVIEW,
      icon: 'ticketOutline',
    },
  })

  children.push(
    getCompanySettingsItems({
      isAdmin,
      isDWEmployee: Boolean(isDWEmployee),
    }),
  )

  return {
    name: '',
    id: 'root',
    children: children,
  }
}

const getMDRItems = (
  isLimitedMdrExperience: boolean | undefined,
  featureEnvHealth: boolean,
) => {
  const itemsToHideForLimitedMdrExperience = [
    'security-index',
    'environment',
    'detection-coverage',
    'detection-catalog',
  ]

  const MDRItems = [
    {
      id: 'security-index',
      name: 'Security Index',
      metadata: {
        icon: 'pulseOutline',
        path: `${Paths.MDR_SECURITY_INDEX}${Paths.YOUR_INDEX}`,
      },
    },
    {
      id: 'reports',
      name: 'Reports',
      metadata: { icon: 'statsChartOutline', path: Paths.INSIGHTS },
    },
    ...(featureEnvHealth
      ? [
          {
            id: 'environment',
            name: 'Environment',
            metadata: { icon: 'leafOutline', path: Paths.ENVIRONMENT },
          },
        ]
      : []),
    {
      id: 'detection-coverage',
      name: 'Detection Coverage',
      metadata: {
        icon: 'shieldOutline',
        path: Paths.DETECTION_COVERAGE_OVERVIEW,
      },
    },
    {
      id: 'detection-catalog',
      name: 'Detection Catalog',
      metadata: { icon: 'bookOutline', path: Paths.DETECTION_CATALOG },
    },
  ]

  return isLimitedMdrExperience
    ? MDRItems.filter(
        (item) => !itemsToHideForLimitedMdrExperience.includes(item.id),
      )
    : MDRItems
}

const getCompanySettingsItems = ({
  isAdmin,
  isDWEmployee,
}: Record<'isAdmin' | 'isDWEmployee', boolean>): NavTreeNode => ({
  id: 'company-settings',
  name: 'Company Settings',
  children: [
    ...(isAdmin && !isDWEmployee
      ? [
          {
            id: 'user-management',
            name: 'User Management',
            metadata: {
              icon: 'people',
              path: Paths.USER_MANAGEMENT,
            },
          },
        ]
      : []),
    ...[
      {
        id: 'modules',
        name: 'Modules',
        children: [],
        metadata: {
          path: Paths.MODULES,
          icon: 'serverOutline',
        },
      },
    ],
  ],
  metadata: {
    path: Paths.COMPANY_INFO,
    icon: 'cogOutline',
    hasDivider: true,
  },
})

export const handleSearchParamsNav = (
  path: string,
  searchParams: URLSearchParams,
): string => {
  const newSearchParams = new URLSearchParams(searchParams)
  if (newSearchParams.has('selectedFilters')) {
    // alert('TRUE')
    newSearchParams.delete('selectedFilters')
  }

  const newPath = `${path}?${newSearchParams.toString()}`
  return newPath
}
