import { Box, Card, Typography } from '@mui/material'

import { NoDetectionsEmptyState } from '@common/SVGs'

export const EDRDataUnavailable: React.FC = () => (
  <Card
    data-testid="edr-data-unavailable"
    sx={[
      (theme) => ({
        padding: 20,
        boxShadow: 'none',
        backgroundColor: theme.vars.palette.secondary.light,
        ...theme.applyStyles('dark', {
          backgroundColor: theme.vars.palette.secondary.main,
        }),
      }),
    ]}
  >
    <Box display="flex" gap={3} flexDirection="column" alignItems="center">
      <NoDetectionsEmptyState
        sx={(theme) => ({
          width: 180,
          stroke: theme.vars.palette.secondary.darker,
          fill: theme.vars.palette.secondary.light,
          ...theme.applyStyles('dark', {
            stroke: theme.vars.palette.text.primary,
          }),
        })}
      />
      <Typography variant="h5">Data Unavailable</Typography>
      <Box textAlign="center">
        <Typography variant="body1">
          We have not currently received the appropriate API permissions to
          populate this page.
        </Typography>
        <Typography variant="body1">
          Please engage with your CSM and Assigned EDR Engineer in order to
          provide the additional API token.
        </Typography>
      </Box>
    </Box>
  </Card>
)
