import { useContext } from 'react'
import { useMutation } from '@apollo/client'

import {
  Button,
  InlineMessageType,
  Typography,
  colors,
} from '../../../../../../design-system'
import { deployToastMessage, renderErrorToast } from '../../../../../../utils'
import { Context } from '../../../../../App'
import { ModalContentProps } from '../../../../../common/Modal'
import { GET_USER } from '../../../../../../graphql/queries/user'
import {
  UPDATE_USER,
  UpdateUserVariables,
} from '../../../../../../graphql/mutations/user'

import './UserProfileModal.scss'

export interface UserProfileModalProps extends ModalContentProps {
  userId?: string
  formValues: {
    firstName: string
    lastName: string
    title: string
    email: string
  }
}

const UserProfileModal: React.FC<UserProfileModalProps> = ({
  closeModal,
  userId,
  formValues,
}) => {
  const { dispatch } = useContext(Context)

  const [updateUser] = useMutation<UpdateUserVariables>(UPDATE_USER, {
    refetchQueries: [GET_USER],
  })

  const displaySuccessToast = () => {
    deployToastMessage(
      {
        id: crypto.randomUUID(),
        text: 'User profile has been updated successfully!',
        messageType: InlineMessageType.SuccessInline,
        secondsToExpire: 3000,
        dismissible: false,
      },
      dispatch,
    )
  }

  const handleUpdateUser = async (values) => {
    closeModal()
    await updateUser({
      variables: {
        input: {
          userIdToUpdate: userId,
          firstName: values.firstName,
          lastName: values.lastName,
          title: values.title,
          email: values.email,
        },
      },
      onCompleted: displaySuccessToast,
      onError: (e) => {
        renderErrorToast(e, dispatch)
      },
    })
  }
  return (
    <div id="user-profile-modal" data-testid="user-profile-modal">
      <Typography
        weight={600}
        color={colors.util.one.lighter}
        component="div"
        size={16}
      >
        {`Are you sure you want to update your email address? This will also change your login username.`}
      </Typography>
      <span
        key={'B'}
        style={{
          display: 'flex',
          marginTop: 25,
        }}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'row',
          gap: 16,
        }}
        data-testid="user-status-modal-buttons"
      >
        <Button
          testId="user-status-cancel-btn"
          variant="secondary"
          onClick={() => closeModal()}
          buttonStyles={{ width: 160, justifyContent: 'center' }}
        >
          Cancel
        </Button>
        <Button
          buttonStyles={{ width: 160, justifyContent: 'center', fontSize: 16 }}
          testId="user-status-button"
          onClick={() => handleUpdateUser(formValues)}
        >
          Update email
        </Button>
      </div>
    </div>
  )
}

export default UserProfileModal
