import React, { useRef } from 'react'

import { TooltipProps } from '../../interfaces'

import './Tooltip.scss'

const Tooltip = ({
  target,
  children,
  wrapperStyles,
  childrenStyles,
  debounce = 1000,
}: TooltipProps): JSX.Element => {
  const debounceTimeout = useRef<number>()
  const tooltip = useRef<HTMLDivElement>(null)

  const adjustTooltipPosition = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    if (tooltip.current) {
      const top =
        e.pageY - tooltip.current.offsetHeight - 12 <= 0
          ? e.pageY + 22
          : e.pageY - tooltip.current.offsetHeight - 12
      const left =
        document.documentElement.clientWidth <= //? Check root element clientWidth (viewport width minus width of vertical scroll bars)
        e.pageX + tooltip.current.offsetWidth
          ? e.pageX - tooltip.current.offsetWidth
          : e.pageX
      tooltip.current.style.top = `${top}px`
      tooltip.current.style.left = `${left}px`
    }
  }

  const handleMouseLeave = () => {
    clearTimeout(debounceTimeout.current)
    tooltip.current?.classList.remove('visible')
  }

  const handleMouseEnter = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    adjustTooltipPosition(e)
    debounceTimeout.current = window.setTimeout(() => {
      tooltip.current?.classList.add('visible')
    }, debounce)
  }

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
    adjustTooltipPosition(e)

  return (
    <>
      <div
        style={wrapperStyles}
        onMouseEnter={handleMouseEnter}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
      >
        {target}
      </div>
      <div ref={tooltip} className="dw-tooltip" style={childrenStyles}>
        {children}
      </div>
    </>
  )
}

export default Tooltip
