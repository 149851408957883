import { Box } from '@mui/material'
import React from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'

import ModuleHeader from '@common/ModuleHeader'
import { useAwaitedCustomer } from '@hooks/useCustomer'
import { EDRProvider } from '@hooks/useEDRContent/edrConstants'

import EDRBans from './EDRBans'
import TopAssetsByAlerts from './TopAssetsByAlerts'
import { EDRDataUnavailable } from '../EDRDataNotAvailable'

const EDROverviewContainer: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <Box
      id="edr-overview-page"
      data-testid="edr-overview"
      sx={{ overflowY: 'auto', padding: '1rem', width: '100%' }}
    >
      {children}
    </Box>
  )
}

const EDROverview: React.FC = () => {
  const customer = useAwaitedCustomer()
  const { featureDefenderForEndpoint } = useFlags()

  const linkInfo = () => {
    const isMicrosoftDefender =
      customer.serviceNgMedrTechnology?.toLowerCase() ===
      EDRProvider.MicrosoftDefenderForEndpoint

    return {
      link: isMicrosoftDefender
        ? 'https://security.microsoft.com/'
        : customer.crowdstrikeUrl,
      name: isMicrosoftDefender ? 'Defender' : 'Falcon',
    }
  }

  const moduleLink = linkInfo()

  if (featureDefenderForEndpoint && !customer.hasEdrAssets) {
    return (
      <EDROverviewContainer>
        <EDRDataUnavailable />
      </EDROverviewContainer>
    )
  }

  return (
    <EDROverviewContainer>
      <ModuleHeader
        moduleLink={moduleLink.link}
        moduleLinkText={moduleLink.name}
        moduleType="medr"
        tooltipText="Data updated every 24 hours"
        loading={customer.customerShortName === ''}
      />

      <EDRBans />
      <TopAssetsByAlerts />
    </EDROverviewContainer>
  )
}
export default EDROverview
