import { MutableRefObject, useRef, useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { useSearchParams } from 'react-router-dom'

import {
  MobileTable,
  DesktopTable,
  Tag,
  Typography,
  colors,
} from '../../../design-system'
import emptyState from '../../../assets/no-detections-empty-state.svg'
import { DetectionCatalogControls } from './DetectionCatalogControls'
import NoResults from '../../common/NoResults/NoResults'
import { useWindowDimensions } from '../../../hooks'
import { mobileWindowWidth } from '../../../constants/constants'
import { ColumnHeader } from '../../../design-system/components/DesktopTable'
import {
  emptyDetection,
  filterByMitreTactic,
  filterByStatus,
  formatData,
  includeUnavailableDetections,
  keywordSearchFilter,
} from './DetectionCatalog.utils'
import {
  DetectionDataVariables,
  Detections,
  FilterOptions,
} from '../../../models'
import { DetectionCatalogFilters } from './DetectionCatalogFilters'
import {
  DetectionCatalogFilterOptions,
  detectionCatalogSelectedFiltersState,
} from './DetectionCatalogFilters/DetectionCatalogFiltersValues'
import { removeDuplicatesFromArray, scrollToTop } from '../../../utils'
import FilterChipsHeader from '../../common/Filters/FilterChipsHeader/FilterChipsHeader'
import DetectionCatalogSideSheet from './DetectionCatalogSideSheet/DetectionCatalogSideSheet'
import { DetectionData } from '../../../models/Detections'
import { DetectionCatalogStatus } from '../Coverage.utils'
import { GET_DETECTIONS } from '../../../graphql/queries/detection'

import './DetectionCatalog.scss'

interface DetectionCatalogProps {
  selectedFilters: Array<string>
}

const DetectionCatalog = ({
  selectedFilters,
}: DetectionCatalogProps): JSX.Element => {
  const calculateStickyHeaderOffset = () => {
    /**
     * Relevant sticky header offset elements
     */
    const pageHeader = document.getElementById('page-header')
    const detectionCatalogPage = document.getElementById(
      'detection-catalog-page',
    )
    const tabs = document.querySelector<HTMLUListElement>(
      '#coverage-page-tabs-container ul',
    )
    const detectionCatalogHeader = document.getElementById(
      'detection-catalog-header',
    )
    /**
     * Get relevant offset element height/padding
     */
    const pageHeaderHeight = pageHeader ? pageHeader.offsetHeight : 0
    const detectionCatalogPagePaddingTop = detectionCatalogPage
      ? parseInt(window.getComputedStyle(detectionCatalogPage).paddingTop)
      : 0
    const tabsHeight = tabs ? tabs.offsetHeight : 0
    const ticketLibraryHeaderHeight = detectionCatalogHeader
      ? detectionCatalogHeader.offsetHeight
      : 0
    // stickyHeaderOffset = #page-header height + #detection-catalog-page top padding + tab ul height + #detection-catalog-header height
    return (
      pageHeaderHeight +
      detectionCatalogPagePaddingTop +
      tabsHeight +
      ticketLibraryHeaderHeight
    )
  }
  const [searchParams] = useSearchParams()
  const selectedCustomer = searchParams.get('customer')
  const { width } = useWindowDimensions()
  const tableRef = useRef() as MutableRefObject<HTMLDivElement> | null
  const [unavailableDetectionsToggle, setUnavailableDetectionsToggle] =
    useState(false)
  const [updatedDetectionsData, setUpdatedDetectionsData] = useState<
    Detections[]
  >([])
  const [filters, setFilters] = useState<string[]>([])
  const [toggleFilterSideSheet, setToggleFilterSideSheet] = useState(false)
  const [selectedDetectionsFilters, setSelectedDetectionsFilters] = useState(
    detectionCatalogSelectedFiltersState,
  )
  const [loading, setLoading] = useState(true)
  const [isOpen, setIsOpen] = useState(false)
  const [focusedDetection, setFocusedDetection] = useState<Detections>()
  const [stickyHeaderOffset, setStickyHeaderOffset] = useState<number>(
    calculateStickyHeaderOffset(),
  )

  const {
    data: { getDetections: originalData } = {
      getDetections: {
        detections: [emptyDetection],
        filters: [],
      },
    },
  } = useQuery<DetectionData, DetectionDataVariables>(GET_DETECTIONS, {
    variables: {
      selectedCustomer,
    },
    onCompleted: ({ getDetections }) => {
      //? Include unavailable detections if there are no detections available in the customer's DWA version
      const includeUnavailable = !getDetections.detections.filter(
        (detection) => detection.status !== DetectionCatalogStatus.Unavailable,
      ).length

      setUpdatedDetectionsData(
        includeUnavailableDetections(getDetections.detections, false),
      )
      setFilters(getDetections.filters)
      if (selectedFilters.length > 0) {
        applyFilter({
          filters: {
            mitreTactics: [],
            status: selectedFilters,
          },
          keywordSearch: '',
        })
      }
      if (includeUnavailable) {
        handleIncludeToggle(true)
      }
      setLoading(false)
    },
  })

  /**
   * We need to update the table header offset when the filters are
   * updated as that can change the height of #detection-catalog-header
   * and or when the width of the screen changes as that can also cause
   * the #detection-catalog-header height to grow (contingent on the number
   * of filters).
   */
  useEffect(() => {
    setStickyHeaderOffset(calculateStickyHeaderOffset())
  }, [selectedDetectionsFilters, width])

  const originalFetchedData = originalData.detections

  const anyFilterHasBeenApplied =
    selectedDetectionsFilters.keywordSearch !== '' ||
    Object.values(selectedDetectionsFilters.filters).some(
      (filterOption) => filterOption.length,
    )

  const handleIncludeToggle = (toggle: boolean) => {
    if (toggle) {
      if (anyFilterHasBeenApplied) {
        const filterWithUnavailable = filterDetectionData(
          selectedDetectionsFilters,
          [
            ...updatedDetectionsData,
            ...includeUnavailableDetections(originalFetchedData, true),
          ],
        )
        setUpdatedDetectionsData(filterWithUnavailable)
      } else {
        setUpdatedDetectionsData(originalFetchedData)
      }

      setUnavailableDetectionsToggle(true)
    } else {
      if (anyFilterHasBeenApplied) {
        const filterWithOutUnavailable = filterDetectionData(
          selectedDetectionsFilters,
          includeUnavailableDetections(originalFetchedData, false),
        )
        setUpdatedDetectionsData(filterWithOutUnavailable)
      } else {
        setUpdatedDetectionsData(
          includeUnavailableDetections(originalFetchedData, false),
        )
      }

      setUnavailableDetectionsToggle(false)
    }
  }

  const filterDetectionData = (
    detectionFilters: FilterOptions<DetectionCatalogFilterOptions>,
    detectionData: Detections[],
  ): Detections[] => {
    // since we want to search across two columns, we need to implement two keyword search filters
    // so that we find all possible search outcomes from the two columns
    const techTypeKeywordsFiltered = keywordSearchFilter(
      detectionFilters.keywordSearch,
      detectionData,
      'dataTypes',
    )

    const titleKeywordsFiltered = keywordSearchFilter(
      detectionFilters.keywordSearch,
      detectionData,
      'title',
    )

    const filteredKeywordSearch = removeDuplicatesFromArray([
      ...techTypeKeywordsFiltered,
      ...titleKeywordsFiltered,
    ])

    const mitresFiltered = filterByMitreTactic(
      detectionFilters.filters.mitreTactics,
      filteredKeywordSearch,
    )

    const statusFiltered = filterByStatus(
      detectionFilters.filters.status,
      mitresFiltered,
    )

    return removeDuplicatesFromArray(statusFiltered)
  }

  const applyFilter = (
    selectedFilters: FilterOptions<DetectionCatalogFilterOptions>,
  ) => {
    const includeUnavailable = selectedFilters.filters.status.includes(
      DetectionCatalogStatus.Unavailable,
    )
    setSelectedDetectionsFilters({
      filters: selectedFilters.filters,
      keywordSearch: selectedFilters.keywordSearch,
    })

    const selectedFiltersHaveBeenChosen =
      selectedFilters.keywordSearch !== '' ||
      Object.values(selectedFilters.filters).some(
        (filterOption) => filterOption.length,
      )

    let filteredDetections: Detections[]

    // edge case where user has toggle on and manually de-selects chips from the side sheet
    // we want the unavailable detections to be included in this case
    if (unavailableDetectionsToggle && !selectedFiltersHaveBeenChosen) {
      filteredDetections = filterDetectionData(selectedFilters, [
        ...originalFetchedData,
        ...includeUnavailableDetections(originalFetchedData, true),
      ])
    } else {
      // normal filtering that accounts for already filtered data (updatedDetectionsData)
      // and does not include unavailable detections
      filteredDetections = filterDetectionData(selectedFilters, [
        ...updatedDetectionsData,
        ...includeUnavailableDetections(
          originalFetchedData,
          includeUnavailable,
        ),
      ])
    }
    scrollToTop(tableRef)
    if (includeUnavailable) {
      setUnavailableDetectionsToggle(includeUnavailable)
    }
    setUpdatedDetectionsData(filteredDetections)
    setToggleFilterSideSheet(false)
  }

  const clearAll = () => {
    scrollToTop(tableRef)
    setSelectedDetectionsFilters(detectionCatalogSelectedFiltersState)
    setUpdatedDetectionsData(
      includeUnavailableDetections(originalFetchedData, false),
    )
    setToggleFilterSideSheet(false)
    setUnavailableDetectionsToggle(false)
  }

  const deleteChip = (filterGroup: string, filterValue: string) => {
    setToggleFilterSideSheet(false)

    let allUpdatedFilters: FilterOptions<DetectionCatalogFilterOptions> =
      detectionCatalogSelectedFiltersState

    if (filterGroup === 'keywordSearch') {
      allUpdatedFilters = {
        ...selectedDetectionsFilters,
        keywordSearch: '',
      }
    } else {
      const filtersToUpdate =
        // eslint-disable-next-line security/detect-object-injection
        selectedDetectionsFilters.filters[filterGroup].filter(
          (item: string) => {
            return filterValue !== item
          },
        )

      allUpdatedFilters = {
        keywordSearch: selectedDetectionsFilters.keywordSearch,
        filters: {
          ...selectedDetectionsFilters.filters,
          [filterGroup]: filtersToUpdate,
        },
      }
    }

    let filteredData: Detections[] = []

    // edge case if toggle is on and Unavailable chip is selected
    if (
      unavailableDetectionsToggle &&
      filterValue === DetectionCatalogStatus.Unavailable
    ) {
      filteredData = filterDetectionData(
        allUpdatedFilters,
        includeUnavailableDetections(originalFetchedData, false),
      )
      setUnavailableDetectionsToggle(false)
      // if unavailable detections toggle is on, include in the data to be filtered
    } else if (unavailableDetectionsToggle) {
      filteredData = filterDetectionData(allUpdatedFilters, [
        ...originalFetchedData,
        ...includeUnavailableDetections(originalFetchedData, true),
      ])
    } else {
      filteredData = filterDetectionData(allUpdatedFilters, [
        ...updatedDetectionsData,
        ...includeUnavailableDetections(originalFetchedData, false),
      ])
    }

    setSelectedDetectionsFilters(allUpdatedFilters)
    setUpdatedDetectionsData(
      filteredData.length === 0
        ? includeUnavailableDetections(originalFetchedData, false)
        : filteredData,
    )

    scrollToTop(tableRef)
  }

  const generateTableHeaders = (
    name: string,
    sortable: boolean,
    visible: boolean,
    columnWidth = '35%',
    secondarySortDirection: 'DESC' | 'ASC' | '',
    secondarySortId: string,
  ): ColumnHeader => {
    return {
      name,
      sortable,
      visible,
      columnWidth,
      secondarySortDirection,
      secondarySortId,
    }
  }

  const tableHeaders: ColumnHeader[] = [
    generateTableHeaders('id', false, false, '', '', ''),
    generateTableHeaders('DETECTIONS', true, true, '', '', ''),
    generateTableHeaders('STATUS', true, true, '', '', ''),
    generateTableHeaders('TECH TYPE', true, true, '', '', ''),
    generateTableHeaders('MITRE TACTIC', true, true, '', '', ''),
    generateTableHeaders('DWA', true, true, '', 'ASC', '1'),
  ]

  const GetWidthAppropriateTable = () => {
    const formattedDetectionData = formatData(updatedDetectionsData).map(
      (detection) => {
        const status = {
          sortValue: detection.status.sortValue,
          displayValue: (
            <div className="detection-status">
              <Tag variant="secondary" status={detection.status.status} />
            </div>
          ),
        }

        return {
          ...detection,
          status,
        }
      },
    )

    if (width <= mobileWindowWidth) {
      return (
        <MobileTable
          data={formattedDetectionData}
          customHeaders={tableHeaders}
          loading={loading}
          loadingRows={10}
          onClick={(id) => {
            setFocusedDetection(
              updatedDetectionsData.find(
                (detection) => detection.useCase === id,
              ) || emptyDetection,
            )
            setIsOpen(!isOpen || focusedDetection?.useCase !== id)
          }}
          sortOptions={{
            isSortedExternally: false,
            defaultSortColumn: 5,
            sortDirection: 'DESC',
            secondaryDefaultSortColumn: 1,
            secondarySortDirection: 'ASC',
          }}
        />
      )
    }
    return (
      <div ref={tableRef} tabIndex={0} role={'none'}>
        <DesktopTable
          data={formattedDetectionData}
          stickyHeaderOffset={stickyHeaderOffset}
          customHeaders={tableHeaders}
          loading={loading}
          loadingRows={10}
          clickableTableRow={true}
          onClick={(id) => {
            setFocusedDetection(
              updatedDetectionsData.find(
                (detection) => detection.useCase === id,
              ),
            )
            setIsOpen(!isOpen || focusedDetection?.useCase !== id)
          }}
          sortOptions={{
            isSortedExternally: false,
            defaultSortColumn: 5,
            sortDirection: 'DESC',
            secondaryDefaultSortColumn: 1,
            secondarySortDirection: 'ASC',
          }}
        />
      </div>
    )
  }

  const renderMainContent = (): JSX.Element => {
    if (
      !loading &&
      !anyFilterHasBeenApplied &&
      updatedDetectionsData.length < 1
    ) {
      return (
        <div id="detection-catalog-no-detections">
          <img
            src={emptyState}
            alt="detections outline"
            id="detections-outline-icon"
            data-testid="no-detections-icon"
          />
          <Typography variant="text8" color={colors.util.navy[50]}>
            No Detections Available
          </Typography>
          <Typography variant="text10" color={colors.util.navy[100]}>
            There are no detections currently available for active scanning.
          </Typography>
        </div>
      )
    }
    if (
      !loading &&
      anyFilterHasBeenApplied &&
      updatedDetectionsData.length < 1
    ) {
      return (
        <div className="detection-catalog-no-results">
          <NoResults />
        </div>
      )
    }
    return (
      <div data-testid="detection-catalog-table">
        {GetWidthAppropriateTable()}
      </div>
    )
  }

  return (
    <div
      id="detection-catalog-page"
      data-testid="detection-catalog-page"
      className={width <= mobileWindowWidth ? 'mobile' : undefined}
    >
      <div
        id="detection-catalog-header"
        data-testid="detections-catalog-header"
      >
        {anyFilterHasBeenApplied && (
          <FilterChipsHeader
            deleteChip={deleteChip}
            selectedFilters={selectedDetectionsFilters}
            hasActiveFilters={anyFilterHasBeenApplied}
          />
        )}

        <Typography>
          {updatedDetectionsData
            ? `${updatedDetectionsData.length} Detections`
            : `0 Detections`}
        </Typography>

        <DetectionCatalogControls
          toggleState={unavailableDetectionsToggle}
          handleOpenFilterSideSheetClick={() => setToggleFilterSideSheet(true)}
          handleIncludeToggle={handleIncludeToggle}
          loading={loading}
        />
      </div>

      {renderMainContent()}

      <DetectionCatalogFilters
        isOpen={toggleFilterSideSheet}
        setIsOpen={(toggle) => {
          setToggleFilterSideSheet(toggle)
          tableRef?.current.focus()
        }}
        filterOptionsResponse={filters} // filterOptions to display inside side sheet
        selectedFilters={selectedDetectionsFilters}
        clearAll={clearAll}
        applyFilter={applyFilter}
      />

      <DetectionCatalogSideSheet
        isOpen={isOpen}
        closeSideSheet={() => {
          setIsOpen(false)
          if (tableRef?.current) {
            tableRef?.current.focus()
          }
        }}
        focusedDetection={focusedDetection || emptyDetection}
      />
    </div>
  )
}

export default DetectionCatalog
