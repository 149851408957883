import { useState } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useLocation, useNavigate } from 'react-router-dom'

import { Ticket } from '../../../../models'
import {
  calculateLastUpdatedDate,
  formatCreatedDate,
} from '../TicketLibrary.utils'
import {
  formatEmptyField,
  formatTicketDetailsPriority,
} from './TicketDetails.utils'
import { Button, CollapsiblePanel, Typography } from '../../../../design-system'
import { colors } from '../../../../design-system/theme'
import { useTicketsContext } from '../../../../hooks'
import { findEnumLabelAndNumber } from '../../TicketEdit/components/TicketStatus/TicketStatusUtils'

import './TicketDetails.scss'

interface TicketDetailsProps {
  focusedTicket: Ticket
}

const TicketDetails = ({ focusedTicket }: TicketDetailsProps): JSX.Element => {
  const [clipboardMessage, setClipboardMessage] = useState(false)
  const { featureEditTicketPage } = useFlags()
  const navigate = useNavigate()
  const { search } = useLocation()
  const handleTicketNavigation = () => {
    featureEditTicketPage
      ? navigate(`${focusedTicket.sysId}/edit${search}`)
      : window.open(`${focusedTicket?.servicenowLink}`, '_blank')
  }

  const { urgencyEnum } = useTicketsContext()

  const handleSplunkSearchCopyToClipboard = () => {
    navigator.clipboard.writeText(focusedTicket.splunkSearch)
    displayClipboardMessage()
  }

  const displayClipboardMessage = () => {
    if (!clipboardMessage) {
      setClipboardMessage(true)
      setTimeout(() => {
        setClipboardMessage(false)
      }, 3000)
    }
  }

  const impactEnumVal = findEnumLabelAndNumber(
    focusedTicket.impact,
    urgencyEnum,
  )
  const urgencyEnumVal = findEnumLabelAndNumber(
    focusedTicket.urgency,
    urgencyEnum,
  )

  return (
    <>
      <div id="tickets-details" data-testid="ticket-library-tickets-details">
        <div>
          <div className="item-content">
            <div>ID</div>
            <div>{focusedTicket.ticketNumber}</div>
          </div>
        </div>

        <div>
          <div className="item-content">
            <div>Subject</div>
            <div>{focusedTicket.shortDescription}</div>
          </div>
        </div>

        <div className="grid-one">
          <div className="grid-item">
            <div>Status</div>
            <div>{focusedTicket.state}</div>
          </div>

          <div className="grid-item">
            <div>Priority</div>
            <div className="grid-item-icon">
              {focusedTicket &&
                formatTicketDetailsPriority(focusedTicket.priority)}
            </div>
          </div>

          <div className="grid-item">
            <div>Impact</div>
            <div>
              {impactEnumVal
                ? `${impactEnumVal.numberValue} - ${impactEnumVal.label}`
                : '--'}
            </div>
          </div>

          <div className="grid-item">
            <div>Urgency</div>
            <div>
              {urgencyEnumVal
                ? `${urgencyEnumVal.numberValue} - ${urgencyEnumVal.label}`
                : '--'}
            </div>
          </div>

          <div className="grid-item">
            <div>Last updated</div>
            <div>
              {focusedTicket.sysUpdatedOn &&
                formatEmptyField(
                  calculateLastUpdatedDate(focusedTicket.sysUpdatedOn),
                )}
            </div>
          </div>

          <div className="grid-item">
            <div>Last updated by</div>
            <div>{formatEmptyField(focusedTicket.lastUpdateUser)}</div>
          </div>

          <div className="grid-item">
            <div>Date created</div>
            <div>
              {focusedTicket &&
                formatEmptyField(formatCreatedDate(focusedTicket.createdDate))}
            </div>
          </div>

          <div className="grid-item">
            <div>Assigned to</div>
            <div>{formatEmptyField(focusedTicket.assignedUser)}</div>
          </div>

          <div className="item-content">
            <div>Service offering</div>
            <div>{formatEmptyField(focusedTicket.serviceOffering)}</div>
          </div>

          <div className="grid-item">
            <div>Customer validation state</div>
            <div>{formatEmptyField(focusedTicket.customerValidateState)}</div>
          </div>

          <div className="grid-item">
            <div>Category</div>
            <div>{formatEmptyField(focusedTicket.category)}</div>
          </div>

          <div className="grid-item">
            <div>Subcategory</div>
            <div>{formatEmptyField(focusedTicket.subcategory)}</div>
          </div>
        </div>

        <CollapsiblePanel label="Description">
          <div className="collapsible-panel-description">
            <Typography
              color={colors.common.white}
              size={14}
              styles={{
                lineHeight: 1.5,
              }}
              shouldParseNewLines
            >
              {focusedTicket.description}
            </Typography>
          </div>
        </CollapsiblePanel>

        <CollapsiblePanel label="Additional Information">
          <div>
            <div className="additional-info-item-content">
              <div className="item-header">Search name</div>
              <div className="item-content">
                {formatEmptyField(focusedTicket.useCaseTitle)}
              </div>
            </div>
          </div>

          <div>
            <div className="additional-info-item-content">
              <div className="item-header">Search ID</div>
              <div className="item-content">
                {formatEmptyField(focusedTicket.useCase)}
              </div>
            </div>
          </div>

          <div className="additional-info-grid">
            <div className="grid-item">
              <div>Incident type</div>
              <div>{formatEmptyField(focusedTicket.incidentType)}</div>
            </div>

            <div className="grid-item">
              <div>CIS control</div>
              <div>{formatEmptyField(focusedTicket.cisControl)}</div>
            </div>

            <div className="grid-item">
              <div>{'MITRE tactic(s)'}</div>
              <div>{formatEmptyField(focusedTicket.mitreTactics)}</div>
            </div>

            <div className="grid-item">
              <div>{'MITRE technique(s)'}</div>
              <div>{formatEmptyField(focusedTicket.mitreTechniques)}</div>
            </div>
          </div>

          <div className="additional-info-item-content">
            <div className="splunk-content">
              <div>Splunk link</div>
              {focusedTicket.splunkLink ? (
                <a
                  href={focusedTicket.splunkLink}
                  target={'_blank'}
                  rel="noopener noreferrer"
                >
                  Open in new tab
                </a>
              ) : (
                '--'
              )}
            </div>
          </div>

          <div className="additional-info-item-content">
            <div className="splunk-content">
              <div>Splunk search</div>
              <div className="splunk-link-content">
                {focusedTicket.splunkSearch ? (
                  <button onClick={handleSplunkSearchCopyToClipboard}>
                    Copy to Clipboard
                  </button>
                ) : (
                  '--'
                )}

                {clipboardMessage && (
                  <div className="copy-to-clipboard-container">
                    <Typography color={colors.common.white} variant="text12">
                      Copied to Clipboard!
                    </Typography>
                  </div>
                )}
              </div>
            </div>
          </div>
        </CollapsiblePanel>
      </div>

      <footer className="footer">
        <div className="footer-content" data-testid="openInSnowButton">
          <Button
            onClick={handleTicketNavigation}
            iconProps={{
              position: 'right',
              variant: featureEditTicketPage
                ? 'arrowForwardCircleOutline'
                : 'openOutline',
            }}
          >
            {featureEditTicketPage ? 'Open ticket' : 'Open in SNOW'}
          </Button>
        </div>
      </footer>
    </>
  )
}

export default TicketDetails
