import { ForwardedRef, forwardRef } from 'react'

import { useTheme } from '../../../hooks'
import { Card } from '../../Card'
import { MobileTableRow } from '../MobileTableRow'
import { MobileTableContext } from '../MobileTableContext'
import { MobileTableCardProps } from './MobileTableCard.types'
import { colors } from '../../../theme'

import './MobileTableCard.scss'

const MobileTableCard = forwardRef(function MobileTableCard(
  props: MobileTableCardProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const {
    bodyColor,
    handleOnClick,
    index,
    mobileTableProps,
    rowData,
    tableHeaders,
  } = props
  const isDarkTheme = useTheme('dark')
  const tableBodyColor = (bodyColor: string, isDarkTheme: boolean): string => {
    if (bodyColor) {
      return bodyColor
    } else if (isDarkTheme) {
      return colors.util.one.main
    } else {
      return colors.util.one.lighter
    }
  }

  const tableBackgroundColor = tableBodyColor(bodyColor, isDarkTheme)

  return (
    <div key={index} className="mobile-table-container" ref={ref}>
      <Card
        backgroundColor={tableBackgroundColor}
        border="1px solid #455358"
        borderRadius="5px"
        boxSizing="border-box"
      >
        <MobileTableContext.Provider value={mobileTableProps}>
          <MobileTableRow
            rowData={rowData}
            tableHeaders={tableHeaders}
            handleOnClick={handleOnClick}
          />
        </MobileTableContext.Provider>
      </Card>
    </div>
  )
})

export default MobileTableCard
