/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  useState,
  ChangeEvent,
  useEffect,
  createRef,
  MouseEvent,
  useRef,
  MouseEventHandler,
} from 'react'

import { ListItemSelectType, ListItemType } from '../../interfaces'
import { useTheme } from '../../hooks'
import * as styles from './ChipInputStyles'
import { noop } from '../../utils'
import { Menu, Tag } from '..'

import './ChipInput.scss'

export interface ChipInputProps {
  disabled?: boolean
  label?: string
  placeholder?: string
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  onSelect?: (options: ListItemType[]) => void
  searchInputValue?: string
  displayValue?: string
  autoFocus?: boolean
  options: ListItemSelectType[]
  selected?: ListItemSelectType[]
  id?: string
}

const ChipInput = ({
  disabled,
  label,
  placeholder,
  onChange,
  onSelect,
  searchInputValue = '',
  displayValue,
  autoFocus = false,
  options,
  selected,
  id,
}: ChipInputProps): JSX.Element => {
  const isDarkTheme = useTheme('dark')

  const [open, setOpen] = useState<boolean>(false)

  const [menuPosition, setMenuPosition] = useState(0)

  const [inputValue, setInputValue] = useState<string>(searchInputValue)

  const [selectedOptions, setSelectedOptions] = useState<ListItemType[]>(
    selected || [],
  )

  const [searchResults, setSearchResults] = useState<
    ListItemSelectType[] | never
  >([])

  const [menuOptions, setMenuOptions] = useState<ListItemSelectType[] | never>(
    options
      .sort((a, b) => a.name.localeCompare(b.name))
      .filter(
        (selected) =>
          !selectedOptions.some((item) => item.name === selected.name),
      ),
  )

  const [removedItems, setRemovedItems] = useState<
    ListItemSelectType[] | never
  >([])

  const wrapperRef = useRef<HTMLDivElement>(null)

  const [wrapperHeight, setWrapperHeight] = useState(0)

  const inputRef = createRef<HTMLInputElement>()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setWrapperHeight(wrapperRef?.current?.offsetHeight || 0)
  })

  useEffect(() => {
    setMenuOptions(
      menuOptions.filter(
        (selected) =>
          !selectedOptions.some((item) => item.name === selected.name),
      ),
    )

    if (selectedOptions) {
      onSelect?.(
        selectedOptions.map((option, index) => {
          return option.uuid
            ? {
                id: index,
                name: option.name,
                uuid: option.uuid,
              }
            : {
                id: index,
                name: option.name,
              }
        }),
      )
    }
  }, [selectedOptions])

  useEffect(() => {
    searchInput(inputValue)
  }, [menuOptions])

  let lastHeight = 0
  useEffect(() => {
    const handleListFlip = () => {
      const list = id && document.querySelector(`#${id}`)
      if (list) {
        const inputSibling = list.previousElementSibling
        if (list.getBoundingClientRect().bottom > window.innerHeight) {
          const siblingTop =
            inputSibling &&
            inputSibling?.getBoundingClientRect().height +
              list.getBoundingClientRect().height +
              8
          if (siblingTop) {
            setMenuPosition(-siblingTop)
          }
          lastHeight = window.innerHeight
        }

        if (lastHeight < window.innerHeight) {
          setMenuPosition(0)
        }
      }
    }

    window.addEventListener('resize', handleListFlip)

    return () => {
      window.removeEventListener('resize', handleListFlip)
    }
  }, [window.scrollY])

  const searchInput = (input: string) => {
    const keyword = input
    if (keyword !== '') {
      const results = menuOptions.filter((option) => {
        return option.name.toLowerCase().indexOf(keyword.toLowerCase()) >= 0
      })
      setSearchResults(results)
    } else {
      setSearchResults([])
    }
  }

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!open) {
      inputRef.current?.focus()
      openSelectMenu()
    }
    searchInput(e.target.value)
    setInputValue(e.target.value)
    onChange?.(e)
  }

  const handleSelectedOption = (selectedOption: ListItemType): void => {
    const newSelectedOptions = [
      selectedOption,
      ...selectedOptions.map((opt) => {
        if (selectedOption.name === opt.name) {
          return selectedOption
        } else {
          return opt
        }
      }),
    ]
    setSelectedOptions(newSelectedOptions)

    const newMenuOptions = menuOptions.filter((option, index) => {
      if (option.name.toLowerCase() === selectedOption.name.toLowerCase()) {
        const newOptions = [
          ...removedItems,
          option.uuid
            ? {
                id: index,
                name: option.name,
                uuid: option.uuid,
              }
            : {
                id: index,
                name: option.name,
              },
        ]
        setRemovedItems(newOptions)
      }
      return option.name.toLowerCase() !== selectedOption.name.toLowerCase()
    })
    setMenuOptions(newMenuOptions)

    if (inputValue.length > 0) {
      const newFoundOptions = searchResults.filter(
        (option) => option.name !== selectedOption.name,
      )
      setSearchResults(newFoundOptions)
    }

    setInputValue('')

    inputRef.current?.focus()

    setOpen(false)
  }

  const toggleSelectMenu = (): void => {
    open ? closeSelectMenu() : openSelectMenu()
  }

  const openSelectMenu = (): void => {
    inputRef.current?.focus()
    setOpen(true)
  }

  const closeSelectMenu = (): void => {
    setOpen(false)
  }

  const deleteChip = (e: MouseEvent<HTMLDivElement>, sOption: any): void => {
    e.preventDefault
    setSelectedOptions(
      selectedOptions.filter((option) => {
        return option.name !== sOption.name
      }),
    )
    setMenuOptions(
      [...menuOptions, sOption].sort((a, b) => a.name.localeCompare(b.name)),
    )
    searchInput(inputValue)
    inputRef.current?.focus()
  }

  const disabledOnClick = (
    disabled: boolean | undefined,
  ): MouseEventHandler<HTMLDivElement> | undefined => {
    if (disabled) {
      return noop
    }
  }

  return (
    <div style={styles.selectContainer}>
      <div style={styles.selectWrapper}>
        <label style={styles.labelStyles(open, isDarkTheme)}>{label}</label>
        <div
          style={styles.inputWrapper(disabled, open, isDarkTheme)}
          ref={wrapperRef}
          onClick={disabledOnClick(disabled)}
        >
          <div style={styles.tagInputWrapper}>
            {selectedOptions.map((option) => {
              return (
                <Tag
                  variant="chip"
                  styles={styles.tagStyles}
                  key={option.id}
                  text={option.name}
                  size={16}
                  rightIcon={'closeCircle'}
                  onDelete={(e) => deleteChip(e, option)}
                />
              )
            })}
            <input
              ref={inputRef}
              type="text"
              placeholder={placeholder}
              value={displayValue ? displayValue : inputValue}
              style={styles.inputContainer(disabled, open, isDarkTheme)}
              disabled={disabled ? disabled : false}
              className={isDarkTheme ? 'dw-input-dark' : 'dw-input'}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus={autoFocus}
              onClick={disabled ? noop : toggleSelectMenu}
              onChange={(e) => handleOnChange(e)}
            />
          </div>
        </div>
        {open && (
          <Menu
            options={searchResults.length > 0 ? searchResults : menuOptions}
            searchInput={inputValue}
            onSelect={handleSelectedOption}
            handleOutsideClick={closeSelectMenu}
            noMatchesFound={
              searchResults.length === 0 && inputValue !== '' ? true : false
            }
            noMoreOptions={
              searchResults.length === 0 &&
              menuOptions.length === 0 &&
              inputValue === ''
                ? true
                : false
            }
            wrapperHeight={wrapperHeight}
            styles={{
              maxHeight: 122,
              overflow: 'auto',
              transform: `translate(${0}px, ${menuPosition}px)`,
            }}
            id={id}
          />
        )}
      </div>
    </div>
  )
}

export default ChipInput
