/* eslint-disable security/detect-object-injection */
import { Typography, colors } from '../../../../../design-system'
import { ColumnHeader } from '../../../../../design-system/components/DesktopTable'
import { Ticket } from '../../../../../models'
import { ReportType } from '../../../../../models/ThreatIntel'
import { DetectionCatalogStatus } from '../../../../Coverage/Coverage.utils'
import { ReportTypeLabel } from '../../../ThreatIntel.utils'

export const RELATED_TICKETS_TABLE_HEADERS: ColumnHeader[] = [
  {
    name: 'sysId',
    visible: false,
  },
  {
    name: 'ticket number',
    visible: true,
  },
  {
    name: 'status',
    visible: true,
  },
]

export const getSideSheetHeaderText = (type: ReportType | undefined) => {
  switch (type) {
    case ReportType.CUSTOMER_ADVISORY_SVE:
      return (
        <Typography color={colors.util.two.light} variant="text9">
          {ReportTypeLabel[type]}
        </Typography>
      )
    case ReportType.CUSTOMER_ADVISORY_AWARENESS:
      return (
        <Typography color={colors.util.four.light} variant="text9">
          {ReportTypeLabel[type]}
        </Typography>
      )
    default:
      return <Typography variant="text9">Report Details</Typography>
  }
}

export const getRelatedTicketsTableData = (
  relatedTickets: Pick<Ticket, 'sysId' | 'ticketNumber' | 'state'>[],
  relatedTicketOnClick: (ticketId: string) => void,
  ticketEditFeatureFlag: boolean,
) => {
  return relatedTickets.map((ticket) => {
    return {
      sysId: ticket.sysId,
      ticketNumber: {
        displayValue: (
          <Typography
            variant="text11semibold"
            {...(ticketEditFeatureFlag && {
              color: colors.brand.secondary.main,
              onClick: () => relatedTicketOnClick(ticket.sysId),
              styles: {
                cursor: 'pointer',
              },
            })}
          >
            {ticket.ticketNumber}
          </Typography>
        ),
      },
      status: {
        displayValue: <Typography variant="text11">{ticket.state}</Typography>,
      },
    }
  })
}

export const associatedDetectionStatusIcons = {
  Enabled: {
    label: DetectionCatalogStatus.Enabled,
    iconVariant: 'checkmarkCircle',
    iconColor: colors.util.green[100],
  },
  Available: {
    label: DetectionCatalogStatus.Available,
    iconVariant: 'addCircleSharp',
    iconColor: colors.brand.secondary.light,
  },
  Unavailable: {
    label: DetectionCatalogStatus.Unavailable,
    iconVariant: 'ban',
    iconColor: colors.util.one.light,
  },
}
