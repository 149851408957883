import { AppAction, ActionTypes } from '../Types'
import { Customer } from '../../../models'

export const setDwExpertsActiveTab = (
  dwExpertsActiveTab: number,
): AppAction => {
  return {
    type: ActionTypes.SET_DW_EXPERTS_ACTIVE_TAB,
    dwExpertsActiveTab,
  }
}

export const setCustomerNames = (dwExpertsCustomer: Customer): AppAction => ({
  type: ActionTypes.SET_DW_EXPERTS_CUSTOMER,
  dwExpertsCustomer,
})

export const setCustomerLoading = (customerLoading: boolean): AppAction => ({
  type: ActionTypes.SET_CUSTOMER_LOADING,
  customerLoading,
})
