import { useContext, useEffect, useRef, useState } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import cloneDeep from 'lodash.clonedeep'
import { useFlags } from 'launchdarkly-react-client-sdk'

import {
  colors,
  Icon,
  IconVariant,
  MobileTable,
  DesktopTable,
  Typography,
} from '../../../design-system'
import { IconButtonProps } from '../../../design-system/interfaces'
import { useTicketsContext, useWindowDimensions } from '../../../hooks'
import { mobileWindowWidth } from '../../../constants/constants'
import {
  FilterOptions,
  Ticket,
  TicketData,
  TicketFilterInput,
} from '../../../models'
import TicketLibrarySideSheet from './TicketLibrarySideSheet/TicketLibrarySideSheet'
import {
  emptyTicket,
  formatData,
  getTicketLibraryTableHeaderIndex,
  includesResolvedClosedOrCancelledTickets,
  ticketsLabel,
} from './TicketLibrary.utils'
import emptyState from '../../../assets/ticketing-empty-state.svg'
import { TicketFilters } from './TicketFilters'
import { navigateUserType, renderErrorToast, scrollToTop } from '../../../utils'
import { TicketLibraryControls } from './TicketLibraryControls'
import NoResults from '../../common/NoResults/NoResults'
import { Context } from '../../App'
import {
  ColumnHeader,
  TableLoadingState,
} from '../../../design-system/components/DesktopTable'
import FilterChipsHeader from '../../common/Filters/FilterChipsHeader/FilterChipsHeader'
import {
  GET_TICKETS,
  GET_TICKETS_WITH_CACHE_FIELDS,
  GetTicketsData,
  GetTicketsVariables,
} from '../../../graphql/queries/ticket'

import './TicketLibrary.scss'

const TicketLibrary = (): JSX.Element => {
  const initialState: TicketData = {
    filterOptions: {
      keywordSearch: '',
      filters: [
        {
          label: '',
          key: '',
          values: [
            {
              value: '',
              label: '',
              selected: false,
            },
          ],
        },
      ],
      showOpenTicketOnly: true,
    },
    pagination: {
      limit: 25,
      offset: 0,
      total: 0,
    },
    sortingOptions: [
      {
        key: 'sysUpdatedOn',
        order: 'DESC',
      },
    ],
    tickets: [],
  }

  const {
    dispatch,
    state: {
      user: { isDWEmployee },
      dwExpertsCustomer: { customerShortName },
    },
  } = useContext(Context)

  const [searchParams] = useSearchParams()
  const selectedCustomer = searchParams.get('customer')
  const selectedFilters = searchParams.get('selectedFilters')

  const navigate = useNavigate()

  const { featureEditTicketPage } = useFlags()

  const { ticketSettingsDataLoading } = useTicketsContext()

  const setupFilters = () => {
    const defaultFilters = {
      priority: [],
      state: [],
      type: [],
      createdDate: [],
      sysUpdatedOn: [],
    }

    if (selectedFilters) {
      return {
        ...defaultFilters,
        ...JSON.parse(selectedFilters),
      }
    }

    return defaultFilters
  }

  const calculateStickyHeaderOffset = () => {
    /**
     * Relevant sticky header offset elements
     */
    const pageHeader = document.getElementById('page-header')
    const ticketLibraryContent = document.getElementById(
      'ticket-library-content',
    )
    const tabs = document.querySelector<HTMLUListElement>('#tickets-page ul')
    const ticketLibraryHeader = document.getElementById('ticket-library-header')
    /**
     * Get relevant offset element height/padding calculations
     */
    const pageHeaderHeight = pageHeader ? pageHeader.offsetHeight : 0
    const ticketLibraryContentPaddingTop = ticketLibraryContent
      ? parseInt(window.getComputedStyle(ticketLibraryContent).paddingTop)
      : 0
    const tabsHeight = tabs ? tabs.offsetHeight : 0
    const ticketLibraryHeaderHeight = ticketLibraryHeader
      ? ticketLibraryHeader.offsetHeight
      : 0
    // stickyHeaderOffset = #page-header height + #ticket-library-content top padding + tab ul height + #ticket-library-header height
    return (
      pageHeaderHeight +
      ticketLibraryContentPaddingTop +
      tabsHeight +
      ticketLibraryHeaderHeight
    )
  }

  const [ticketRequest, setTicketRequest] = useState<GetTicketsVariables>({
    pagination: {
      limit: 25,
      offset: 0,
    },
    selectedCustomer,
    selectedFilters: {
      keywordSearch: '',
      showOpenTicketOnly: true,
      filters: setupFilters(),
    },
    selectedSortings: {
      sysUpdatedOn: 'DESC',
    },
  })

  const [toggleFilterSideSheet, setToggleFilterSideSheet] = useState(false)

  const [focusedTicket, setFocusedTicket] = useState<Ticket>(emptyTicket)

  const [loadingRowState, setLoadingRowState] = useState<TableLoadingState>(
    TableLoadingState.IDLE,
  )

  const [stickyHeaderOffset, setStickyHeaderOffset] = useState<number>(
    calculateStickyHeaderOffset(),
  )

  const { width } = useWindowDimensions()

  const tableRef = useRef<HTMLDivElement>(null)

  const {
    data: { getTickets: ticketData } = { getTickets: initialState },
    fetchMore,
    loading,
  } = useQuery<GetTicketsData, GetTicketsVariables>(
    featureEditTicketPage ? GET_TICKETS_WITH_CACHE_FIELDS : GET_TICKETS,
    {
      fetchPolicy: 'cache-and-network',
      onError: (e) => {
        renderErrorToast(e, dispatch)
      },
      variables: ticketRequest,
    },
  )

  useEffect(() => {
    if (ticketData.tickets.length === ticketData.pagination.total) {
      setLoadingRowState(TableLoadingState.ALL_LOADED)
    } else {
      setLoadingRowState(TableLoadingState.LOADING)
    }
  }, [ticketData, setLoadingRowState])

  /**
   * We need to update the table header offset when the filters are
   * updated as that can change the height of #detection-catalog-header,
   * and or when the width of the screen changes as that can also cause
   * the #detection-catalog-header height to grow (contingent on the number
   * of filters).
   */
  useEffect(() => {
    setStickyHeaderOffset(calculateStickyHeaderOffset())
  }, [ticketRequest.selectedFilters, width])

  const loadingTicketsOrSettings = loading || ticketSettingsDataLoading

  const applyFilter = (selectedFilters: FilterOptions<TicketFilterInput>) => {
    const updatedTicketRequest: GetTicketsVariables = cloneDeep(ticketRequest)
    const showOpenTickets = includesResolvedClosedOrCancelledTickets(
      selectedFilters.filters.state,
    )

    updatedTicketRequest.selectedFilters.filters = selectedFilters.filters
    updatedTicketRequest.selectedFilters.keywordSearch =
      selectedFilters.keywordSearch
    updatedTicketRequest.selectedFilters.showOpenTicketOnly = showOpenTickets

    scrollToTop(tableRef)
    setTicketRequest(updatedTicketRequest)
    setToggleFilterSideSheet(false)
  }

  const clearAll = () => {
    const updatedTicketRequest: GetTicketsVariables = cloneDeep(ticketRequest)
    updatedTicketRequest.selectedFilters = {
      keywordSearch: '',
      showOpenTicketOnly: true,
      filters: {
        priority: [],
        state: [],
        type: [],
        createdDate: [],
        sysUpdatedOn: [],
      },
    }

    scrollToTop(tableRef)
    setTicketRequest(updatedTicketRequest)
    setToggleFilterSideSheet(false)
  }

  const closeSideSheet = () => {
    setFocusedTicket(emptyTicket)
  }

  const deleteChip = (filterGroup: string, filterValue: string) => {
    setToggleFilterSideSheet(false)

    const updatedTicketRequest: GetTicketsVariables = cloneDeep(ticketRequest)

    if (filterGroup === 'keywordSearch') {
      updatedTicketRequest.selectedFilters.keywordSearch = ''
    } else {
      const filtersToUpdate =
        // eslint-disable-next-line security/detect-object-injection
        updatedTicketRequest.selectedFilters.filters[filterGroup].filter(
          (item) => {
            return filterValue !== item
          },
        )

      // eslint-disable-next-line security/detect-object-injection
      updatedTicketRequest.selectedFilters.filters[filterGroup] =
        filtersToUpdate
    }

    scrollToTop(tableRef)
    setTicketRequest(updatedTicketRequest)
  }

  const sortTableHeader = (sortDirection: 'DESC' | 'ASC', sortId: string) => {
    const updatedTicketRequest: GetTicketsVariables = cloneDeep(ticketRequest)
    // eslint-disable-next-line security/detect-object-injection
    updatedTicketRequest.selectedSortings = {
      [sortId]: sortDirection,
    }

    setTicketRequest(updatedTicketRequest)
  }

  const generateTableHeaders = (
    name: string,
    sortable: boolean,
    visible: boolean,
    sortId: string,
    columnWidth = '35%',
  ): ColumnHeader => {
    return {
      name,
      sortable,
      visible,
      onClickOverride: (sortDirection) => {
        sortTableHeader(sortDirection, sortId)
      },
      columnWidth,
    }
  }

  const handleIncludeToggle = () => {
    const updatedTicketRequest: GetTicketsVariables = cloneDeep(ticketRequest)
    updatedTicketRequest.selectedFilters.showOpenTicketOnly =
      !ticketRequest.selectedFilters.showOpenTicketOnly

    scrollToTop(tableRef)
    setTicketRequest(updatedTicketRequest)
  }

  const handleOnBottomReached = () => {
    fetchMore({
      variables: {
        pagination: {
          limit: ticketData.pagination.limit,
          offset: ticketData.tickets.length,
        },
      },
    })
  }

  const tableHeaders: ColumnHeader[] = [
    generateTableHeaders('id', false, false, ''),
    generateTableHeaders('TICKET ID', true, true, 'ticketNumber', ''),
    generateTableHeaders('PRIORITY', true, true, 'priority'),
    generateTableHeaders('STATUS', true, true, 'state'),
    generateTableHeaders('TYPE', true, true, 'type'),
    generateTableHeaders('AGE', true, true, 'createdDate'),
    generateTableHeaders('LAST UPDATED', true, true, 'sysUpdatedOn', '40%'),
  ]

  const anyFilterHasBeenApplied =
    ticketRequest.selectedFilters.keywordSearch.length > 0 ||
    Object.values(ticketRequest.selectedFilters.filters).some(
      (filterOption) => filterOption.length,
    )

  const ticketLibraryTableButtons: IconButtonProps[] = featureEditTicketPage
    ? [
        {
          variant: 'arrowForwardCircleOutline',
          label: 'View ticket',
          customOnClick: ({ id }) => {
            navigateUserType(
              isDWEmployee,
              `/tickets/${id}/edit`,
              navigate,
              customerShortName,
            )
          },
        },
      ]
    : []

  const GetWidthAppropriateTable = () => {
    const formattedTicketData = formatData(ticketData.tickets).map((ticket) => {
      const priority = {
        sortValue: ticket.priority.order,
        displayValue: (
          <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
            <span>
              <Icon
                variant={ticket.priority.icon as IconVariant}
                color={ticket.priority.color as string}
              />
            </span>
            <span>{ticket.priority.text}</span>
          </div>
        ),
      }

      const shortDescription = {
        sortValue: (
          ticket.shortDescription.ticketNumber as string
        ).toLowerCase(),
        displayValue: (
          <>
            <Typography weight={600} color={colors.util.navy[50]}>
              {ticket.shortDescription.ticketNumber}
            </Typography>
            <Typography variant={'text12'}>
              {ticket.shortDescription.text}
            </Typography>
          </>
        ),
      }

      return {
        ...ticket,
        shortDescription,
        priority,
      }
    })

    if (width <= mobileWindowWidth) {
      return (
        <MobileTable
          data={formattedTicketData}
          customHeaders={tableHeaders}
          loading={loadingTicketsOrSettings}
          loadingRows={ticketData.pagination.limit}
          onClick={(id) => {
            setFocusedTicket(
              ticketData.tickets.find((ticket) => ticket.sysId === id) ||
                emptyTicket,
            )
          }}
          onBottomReached={() => handleOnBottomReached()}
          sortOptions={{
            isSortedExternally: true,
            defaultSortColumn: getTicketLibraryTableHeaderIndex(
              Object.keys(ticketRequest.selectedSortings)[0],
            ),
            sortDirection:
              ticketRequest.selectedSortings[
                Object.keys(ticketRequest.selectedSortings)[0]
              ].order === 'ASC'
                ? 'ASC'
                : 'DESC',
          }}
        />
      )
    }

    return (
      <div ref={tableRef} tabIndex={0} role={'none'}>
        <DesktopTable
          data={formattedTicketData}
          stickyHeaderOffset={stickyHeaderOffset}
          customHeaders={tableHeaders}
          loading={loadingTicketsOrSettings}
          loadingRows={ticketData.pagination.limit}
          loadingRowState={loadingRowState}
          clickableTableRow={true}
          onClick={(id) => {
            setFocusedTicket(
              ticketData.tickets.find((ticket) => ticket.sysId === id) ||
                emptyTicket,
            )
          }}
          onBottomReached={handleOnBottomReached}
          sortOptions={{
            isSortedExternally: true,
            defaultSortColumn: getTicketLibraryTableHeaderIndex(
              Object.keys(ticketRequest.selectedSortings)[0],
            ),
            sortDirection:
              ticketRequest.selectedSortings[
                Object.keys(ticketRequest.selectedSortings)[0]
              ].order === 'ASC'
                ? 'ASC'
                : 'DESC',
          }}
          rowHoverElements={ticketLibraryTableButtons}
        />
      </div>
    )
  }

  if (
    !loadingTicketsOrSettings &&
    !anyFilterHasBeenApplied &&
    ticketData.tickets.length === 0
  ) {
    return (
      <div id="ticket-library-no-tickets">
        <img
          src={emptyState}
          alt="ticket outline"
          data-testid="no-tickets-icon"
          id="ticket-outline-icon"
        />
        <Typography variant="text8" color={colors.util.navy[50]}>
          No tickets
        </Typography>
        <Typography variant="text10" color={colors.util.navy[100]}>
          You haven&apos;t received any tickets yet! That&apos;s a good thing!
        </Typography>
      </div>
    )
  } else {
    return (
      <div
        id="ticket-library-content"
        className={width <= mobileWindowWidth ? 'mobile' : undefined}
      >
        <div id="ticket-library-header" data-testid="ticket-library-header">
          {ticketData.filterOptions && (
            <FilterChipsHeader
              deleteChip={deleteChip}
              selectedFilters={ticketRequest.selectedFilters}
              hasActiveFilters={anyFilterHasBeenApplied}
            />
          )}
          <Typography>
            {ticketsLabel(
              ticketData.pagination.total,
              !ticketRequest.selectedFilters.showOpenTicketOnly,
            )}
          </Typography>

          <TicketLibraryControls
            toggleState={ticketRequest.selectedFilters.showOpenTicketOnly}
            handleOpenFilterSideSheetClick={() =>
              setToggleFilterSideSheet(true)
            }
            handleIncludeToggle={handleIncludeToggle}
          />
        </div>

        {loadingTicketsOrSettings === false &&
        anyFilterHasBeenApplied &&
        ticketData.tickets.length < 1 ? (
          <div className="ticket-library-no-results">
            <NoResults />
          </div>
        ) : (
          <div
            data-testid="ticket-library-table"
            className="ticket-library-table"
          >
            {GetWidthAppropriateTable()}
          </div>
        )}

        <TicketFilters
          isOpen={toggleFilterSideSheet}
          setIsOpen={(toggle) => {
            setToggleFilterSideSheet(toggle)
            if (tableRef.current) {
              tableRef?.current.focus()
            }
          }}
          filterOptionsResponse={ticketData.filterOptions} // filterOptions to display inside side sheet
          selectedFilters={ticketRequest.selectedFilters}
          clearAll={clearAll}
          applyFilter={applyFilter}
        />
        <TicketLibrarySideSheet
          data-testid="ticketDetails"
          isOpen={focusedTicket.sysId !== ''}
          closeSideSheet={() => {
            closeSideSheet()
            if (tableRef.current) {
              tableRef?.current.focus()
            }
          }}
          focusedTicket={focusedTicket}
        />
      </div>
    )
  }
}

export default TicketLibrary
