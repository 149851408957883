import React, { CSSProperties, useState, ChangeEvent, useEffect } from 'react'
import isEmail from 'validator/lib/isEmail'
import isDate from 'validator/lib/isDate'
import isEmpty from 'validator/lib/isEmpty'
import escape from 'validator/lib/escape'
import unescape from 'validator/lib/unescape'

import { useTheme } from '../../hooks'
import { IconVariant } from '../../interfaces'
import { InputProps, InputTypes } from '../../interfaces/Input'
import { colors, stateColors } from '../../theme'
import { Icon } from '../Icon'
import { Typography } from '../Typography'

import './Input.scss'

export const Input = ({
  disabled,
  error,
  addErrorSpacing = false,
  errorText,
  hintText,
  label,
  labelId,
  leftIcon,
  placeholder,
  required = false,
  rightIcon,
  onRightIconClick,
  onFocus,
  onBlur,
  onMouseEnter,
  onMouseDown,
  onChange,
  onKeyDown,
  type = 'text',
  name,
  value = '',
  displayValue,
  parentRef,
  styles,
  autoFocus = false,
  isEditable = true,
  hideHelperText = false,
}: InputProps): JSX.Element => {
  const [inputValue, setInputValue] = useState<string | undefined>(value)
  const [inputOutline, setInputOutline] = useState<boolean>(false)
  const [inputError, setInputError] = useState<boolean | undefined>(false)
  const [inputErrorText, setInputErrorText] = useState<string | undefined>('')
  const [inputType, setInputType] = useState<InputTypes>(type)
  const [passwordRightIcon, setPasswordRightIcon] =
    useState<IconVariant>('alertCircle')

  useEffect(() => {
    setPasswordRightIcon(
      inputType === 'password' ? 'eyeOutline' : 'eyeOffOutline',
    )
  }, [inputType])

  useEffect(() => {
    setInputValue(value)
  }, [value])

  const isDarkTheme = useTheme('dark')

  const iconColorToggle = (focus: boolean, disabled: boolean | undefined) => {
    if (disabled) {
      return isDarkTheme ? colors.util.navy[100] : colors.neutral[300]
    }
    if (focus) {
      return isDarkTheme ? colors.util.navy[50] : colors.util.navy[600]
    }
    return isDarkTheme ? colors.util.navy[100] : colors.neutral[300]
  }

  const getInputWrapperOutline = (
    disabled: boolean | undefined,
    error: boolean | undefined,
    focus: boolean | undefined,
  ) => {
    if (error) {
      return isDarkTheme
        ? `1.5px solid ${colors.util.two.light}`
        : `1.5px solid ${stateColors.error.main}`
    }
    if (disabled) {
      return isDarkTheme ? 'none' : `1px solid ${colors.neutral[100]}`
    }
    if (focus) {
      return isDarkTheme
        ? `1px solid ${colors.util.navy[50]}`
        : `2px solid ${colors.neutral[400]}`
    }
    return isDarkTheme
      ? `1px solid ${colors.util.navy[300]}`
      : `1px solid ${colors.neutral[300]}`
  }

  const inputWrapper = (
    disabled: boolean | undefined,
    error: boolean | undefined,
    focus: boolean | undefined,
  ): CSSProperties => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 5,
    outline: getInputWrapperOutline(disabled, error, focus),
    padding: 0,
    width: '100%',
    marginBottom: 8,
    background: isDarkTheme && isEditable ? colors.util.navy[500] : '',
    cursor: 'pointer',
  })

  const inputContainer = (disabled: boolean | undefined): CSSProperties => ({
    display: 'flex',
    width: '100%',
    fontWeight: 400,
    fontFamily: 'Inter',
    fontSize: 16,
    lineHeight: 1.5,
    color: isDarkTheme ? colors.util.navy[100] : colors.neutral[400],
    background: 'none',
    outline: 'none',
    border: 'none',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    opacity: disabled ? 0.4 : 1,
    padding: isEditable ? '8px 11px' : '0px',
  })

  const getLabelColor = (
    error: boolean | undefined,
    focus: boolean | undefined,
  ) => {
    if (error) {
      return isDarkTheme ? colors.util.two.light : stateColors.error.main
    } else if (focus) {
      return isDarkTheme ? colors.util.navy[50] : colors.neutral[400]
    }
    return isDarkTheme ? colors.util.navy[100] : colors.neutral[300]
  }

  const getHintLabelColor = (error: boolean | undefined) => {
    if (error) {
      return isDarkTheme ? colors.util.two.light : stateColors.error.main
    }
    return isDarkTheme ? colors.util.navy[100] : colors.neutral[300]
  }

  const labelStyles = (
    error: boolean | undefined,
    focus: boolean | undefined,
  ): CSSProperties => ({
    color: getLabelColor(error, focus),
    fontFamily: 'Inter',
    fontWeight: 600,
    lineHeight: 1.4,
    width: '100%',
    marginBottom: 8,
    display: 'block',
    textAlign: 'left',
  })

  const hintTextStyles = (error: boolean | undefined) => ({
    color: getHintLabelColor(error),
    display: 'flex',
    fontFamily: 'Inter',
    fontSize: 12,
    fontWeight: 400,
    width: '100%',
    minHeight: addErrorSpacing ? '1rem' : '0',
    marginTop: 8,
  })

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
    onChange?.(e)
  }

  const handlePasswordShowHideToggle = () => {
    setInputType(inputType === 'password' ? 'text' : 'password')
  }

  const validateInput = (
    event: React.FocusEvent<HTMLInputElement>,
  ): boolean => {
    let result = true
    const targetValue = event.target.value
    switch (event.target.type) {
      case 'email':
        result = isEmail(targetValue)
        break
      case 'date':
        result = isDate(targetValue)
        break
      default:
        break
    }
    result
      ? setInputErrorText(undefined)
      : setInputErrorText('This is not a valid ' + event.target.type + ' value')
    return result
  }

  const sanitizeInput = (event: React.FocusEvent<HTMLInputElement>): string => {
    let resultValue = event.target.value
    switch (event.target.type) {
      case 'text':
        resultValue = escape(resultValue)
        break
      default:
        break
    }
    return resultValue
  }

  const handleMouseDown = () => {
    onMouseDown?.()
  }

  const handleMouseEnter = () => {
    onMouseEnter?.()
  }

  const handleFocus = () => {
    setInputOutline(true)
    onFocus?.()
  }

  const handleBlur = (e: React.FocusEvent<HTMLElement>) => {
    setInputOutline(false)
    if (e.target.tagName === 'INPUT') {
      const tempInputElement = e as React.FocusEvent<HTMLInputElement>
      setInputError(
        !isEmpty(tempInputElement.target.value) &&
          !validateInput(tempInputElement),
      )
      setInputValue(sanitizeInput(tempInputElement))
    }
    onBlur?.(e)
  }

  const getHintText = (): string | undefined => {
    if (error) {
      if (errorText) {
        return errorText
      } else {
        return hintText
      }
    } else {
      if (inputError) {
        return inputErrorText
      } else {
        return hintText
      }
    }
  }

  const handleRightIconClick = () => {
    if (type === 'password') {
      handlePasswordShowHideToggle()

      return
    }

    if (onRightIconClick) {
      onRightIconClick()

      return
    }

    setInputValue('')
  }

  return (
    <div style={{ ...inputOuterContainer, ...styles }}>
      <div style={inputInnerWrapper(disabled)}>
        <label htmlFor={labelId} aria-label={label || `${type} input`}>
          {label && (
            <Typography
              component="span"
              styles={labelStyles(error ? error : inputError, inputOutline)}
            >
              {label}
            </Typography>
          )}
          <div
            style={inputWrapper(
              disabled,
              error ? error : inputError,
              inputOutline,
            )}
            className={inputOutline ? 'dw-wrapper-active' : 'dw-wrapper'}
          >
            {leftIcon && (
              <span
                role="button"
                onMouseDown={handleMouseDown}
                onFocus={handleFocus}
                onMouseEnter={handleMouseEnter}
                onBlur={handleBlur}
                style={leftIconStyles}
                tabIndex={-1}
              >
                <Icon
                  variant={leftIcon}
                  size={24}
                  color={iconColorToggle(inputOutline, disabled)}
                />
              </span>
            )}
            <input
              type={type === 'password' ? inputType : type}
              name={name}
              placeholder={placeholder}
              style={inputContainer(disabled)}
              value={displayValue ? displayValue : unescape(inputValue ?? '')}
              onChange={(e) => handleOnChange(e)}
              onKeyDown={onKeyDown}
              onMouseDown={handleMouseDown}
              onFocus={handleFocus}
              onMouseEnter={handleMouseEnter}
              onBlur={handleBlur}
              required={required}
              disabled={disabled ? disabled : false}
              className={isDarkTheme ? 'dw-input-dark' : 'dw-input'}
              ref={parentRef}
              /* eslint-disable jsx-a11y/no-autofocus */
              autoFocus={autoFocus}
              id={labelId}
              aria-describedby={errorText && 'error-message'}
            />
            {displayValue && (
              <input type="hidden" name={`${name}_value`} value={inputValue} />
            )}
            {(rightIcon || (type === 'password' && passwordRightIcon)) && (
              <span
                role="button"
                tabIndex={0}
                onClick={handleRightIconClick}
                onKeyUp={(event) => {
                  if (event.key === 'Enter') {
                    handleRightIconClick()
                  }
                }}
                style={{ marginRight: '8px', display: 'flex' }}
                hidden={
                  type === 'text' &&
                  (rightIcon === 'close' && (inputValue?.length ?? 0)) === 0
                }
                data-testid="input-right-icon"
              >
                <Icon
                  variant={type === 'password' ? passwordRightIcon : rightIcon}
                  size={24}
                  color={iconColorToggle(inputOutline, disabled)}
                />
              </span>
            )}
          </div>
          {!hideHelperText && (
            <small
              style={hintTextStyles(error ? error : inputError)}
              id="error-message"
              data-testid="helper-text"
              aria-busy={error ? error : inputError}
            >
              {(hintText || errorText) && getHintText()}
            </small>
          )}
        </label>
      </div>
    </div>
  )
}

const inputOuterContainer: CSSProperties = {
  display: 'flex',
  width: '100%',
  gap: '40px',
}

const inputInnerWrapper = (disabled: boolean | undefined): CSSProperties => ({
  width: '100%',
  cursor: disabled ? 'default' : 'pointer',
})

const leftIconStyles: CSSProperties = {
  marginLeft: 8,
}
