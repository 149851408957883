import { Detections } from './Detections'
import { Ticket } from './Tickets'

export enum ReportConfidenceLevel {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
}

export enum ReportType {
  CUSTOMER_ADVISORY_SVE = 'CUSTOMER_ADVISORY_SVE',
  CUSTOMER_ADVISORY_AWARENESS = 'CUSTOMER_ADVISORY_AWARENESS',
  CYBER_INTEL_BRIEF = 'CYBER_INTEL_BRIEF',
}

export type RelatedTicket = {
  sysId: string
  ticketNumber: string
  state: string
}

export type ThreatIntelReportContent = {
  heading: string
  level: number
  content: string
  originalMarkdown: string
}

export interface ThreatIntelReportDetail {
  id: string
  title: string
  type: ReportType
  labels: string[]
  source: string
  confidenceLevel: ReportConfidenceLevel
  createdDate: string
  overview: string
  associatedDetections: Array<
    Pick<Detections, 'title' | 'mitreTechniques' | 'status'>
  >
  relatedTickets: Array<Pick<Ticket, 'sysId' | 'ticketNumber' | 'state'>>
  externalReferences: string[]
  relatedIntelReports: string[]
  sections: ThreatIntelReportContent[]
  markAsRead: boolean
}

export type ThreatIntelReport = Omit<ThreatIntelReportDetail, 'sections'>
