import {
  Typography,
  colors,
  Loader,
  Link,
  IconVariant,
} from '../../../../design-system'
import ComponentError from '../../../common/ComponentError/ComponentError'

export interface DashboardLinksData {
  splunk: string
  servicenow: string
  threatLabs: string
}

export interface DashboardLink {
  title: string
  url: string
  icon: IconVariant
}

interface MyLinksWidgetProps {
  data: DashboardLink[]
  loading: boolean
}

const MyLinksWidget = ({ data, loading }: MyLinksWidgetProps) => {
  const anchorProps = {
    style: {
      marginBottom: 8,
      pointerEvents: loading ? 'none' : 'auto',
    },
    target: '_blank',
    rel: 'noreferrer',
  } as Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'children'>

  const renderContent = () => {
    if (!data) {
      return (
        <div style={{ textAlign: 'center' }}>
          <ComponentError />
        </div>
      )
    }

    if (loading) {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignSelf: 'center',
            marginLeft: '43%',
          }}
        >
          <Loader strokeWidth={2} size={50} />
        </div>
      )
    }

    if (data) {
      return data.map((link, index) => {
        return (
          <Link
            key={index}
            variant="listItem"
            aProps={{ ...anchorProps, href: link.url }}
            iconProps={{
              variant: link.icon,
              style: {
                display: loading ? 'none' : 'block',
              },
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                marginRight: 8,
              }}
            >
              {link.title}
            </div>
          </Link>
        )
      })
    }
  }

  return (
    <>
      <div id="dashboard-mylinks-widget" data-testid="dashboard-mylinks-widget">
        <div
          className="title"
          style={{
            alignItems: 'center',
            display: 'flex',
            height: 40,
            justifyContent: 'space-between',
            marginTop: 24,
            marginBottom: 6,
            padding: 0,
          }}
        >
          <Typography
            component="span"
            color={colors.util.navy[100]}
            variant="text9semibold"
          >
            My links
          </Typography>
        </div>
        {renderContent()}
      </div>
    </>
  )
}

export default MyLinksWidget
