import {
  SelectedFiltersAgeEnum,
  SelectedFiltersLastUpdatedEnum,
} from '../components/Tickets/TicketLibrary/TicketFilters/TicketFilter.types'
import { FilterOptionValues, FilterOptions } from './Filters'

export interface TicketingRelatedCase {
  ticketNumber: string
  description: string
  priority: TicketPriorityEnum
  state: string
  sysCreatedOn: string
}
export interface Ticket {
  sysId: string
  type: string
  ticketNumber: string
  assignedUser: string | null
  assignedToDeepwatch: boolean
  assignmentGroup: string | null
  impact: string
  priority: TicketPriorityEnum
  state: string
  urgency: string
  customerValidateState: string | null
  serviceOffering: string | null
  category: string | null
  subcategory: string | null
  channel: string | null
  caseType: string | null
  shortDescription: string
  description: string
  openedBy: string
  searchName: string
  customerContact: string
  useCase: string // Search ID
  useCaseTitle: string
  incidentType: string | null
  cisControl: string
  mitreTactics: string
  mitreTechniques: string
  splunkLink: string
  splunkSearch: string
  resolutionNotes: string | null
  resolutionBy: string | null
  resolutionAt: string | null
  resolutionCode: string | null
  customerAssignmentTime: number | null
  customerAcknowledgeTime: number | null
  customerValidationTime: number | null
  timeToCloseSec: number | null
  timeToCustomerAcknowledgeSec: number | null
  timeToCustomerAssignSec: number | null
  timeToCustomerValidateSec: number | null
  timeToDetectionSec: number | null
  timeToDeclaredIncidentSec: number | null
  timeToDetectResolveAlertSec: number | null
  timeToInvestigateSec: number | null
  timeToNotifySec: number | null
  timeToRespondSec: number | null
  timeToResolveTicketSec: number | null
  timeToUpdateSec: number | null
  totalTimeDeepwatchWaitCustomerSec: number | null
  totalTimeDeepwatchWaitVendorSec: number | null
  relatedCases: TicketingRelatedCase[]
  sysCreatedOn: string
  sysUpdatedOn: string
  lastUpdateUser: string
  servicenowLink: string
  createdDate: string
  comments: TicketingComment[] | null
}

export enum TicketStateEnum {
  InProgressCustomer = 'In Progress - Customer',
  InProgressDW = 'In Progress - dw',
  InProgressVendor = 'In Progress - Vendor',
  New = 'New',
  OnHold = 'On-Hold',
  Resolved = 'Resolved',
  Cancelled = 'Cancelled',
  Closed = 'Closed',
}

export enum TicketPriorityEnum {
  Critical = 'Critical',
  High = 'High',
  Moderate = 'Moderate',
  Low = 'Low',
  Informational = 'Informational',
}

export enum TicketTypeEnum {
  Security = 'Security',
  Engineering = 'Engineering',
}

export interface TicketingComment {
  text: string
  time: string
  type: string
  user: string
}

export interface FormatTicketLibraryTableProps
  extends Pick<Ticket, 'state' | 'type'> {
  id: string
  shortDescription: Record<string, string | JSX.Element>
  priority: Record<string, string | JSX.Element>
  createdDate: Record<string, string | JSX.Element>
  sysUpdatedOn: Record<string, string | JSX.Element>
}

export interface TicketFilterInput {
  priority: TicketPriorityEnum[]
  state: TicketStateEnum[]
  type: TicketTypeEnum[]
  createdDate: SelectedFiltersAgeEnum[]
  sysUpdatedOn: SelectedFiltersLastUpdatedEnum[]
}

export interface PaginationInput {
  limit: number
  offset: number
}

export interface Pagination extends PaginationInput {
  total: number
}

export interface TicketSortingInput {
  createdDate?: string
  priority?: string
  state?: string
  sysUpdatedOn?: string
  ticketNumber?: string
  type?: string
}

export interface SortingOptions {
  key: string
  order: string
}

export interface TicketData {
  tickets: Ticket[]
  pagination: Pagination
  filterOptions: FilterOptions<FilterOptionValues[]>
  sortingOptions: SortingOptions[]
}

export interface TicketEditEnum {
  label: string
  numberValue: number
}

export interface TicketEditSettingsData {
  enabled: boolean
  enumValues: {
    priority: TicketEditEnum[]
    urgency: TicketEditEnum[]
    state: TicketEditEnum[]
    resolutionCode: TicketEditEnum[]
    customerValidationState: TicketEditEnum[]
  }
}
