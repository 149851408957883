import { colors } from '../../theme'
import { ColumnHeader, SortingOrder } from './DesktopTable.types'

export const sortData = (
  tableData: Record<string, any>[],
  key: number,
  sortingDirection: SortingOrder,
): Record<string, any>[] => {
  if (tableData.length === 0) {
    return []
  }

  if (key === -1) {
    return tableData
  }

  const sortKey = Object.keys(tableData[0]).at(key)
  if (sortKey === undefined || sortKey === null) {
    return []
  }

  const sortedData = [...tableData].sort((a, b): number => {
    let aSortValue = Object.keys(a[`${sortKey}`]).includes('sortValue')
      ? a[`${sortKey}`].sortValue
      : a[`${sortKey}`]
    if (typeof aSortValue === 'string') {
      aSortValue = aSortValue.toLowerCase()
    }
    let bSortValue = Object.keys(b[`${sortKey}`]).includes('sortValue')
      ? b[`${sortKey}`].sortValue
      : b[`${sortKey}`]
    if (typeof bSortValue === 'string') {
      bSortValue = bSortValue.toLowerCase()
    }

    if (sortingDirection === 'ASC') {
      return aSortValue < bSortValue ? -1 : 1
    } else if (sortingDirection === 'DESC') {
      return aSortValue > bSortValue ? -1 : 1
    } else {
      return 1
    }
  })
  return sortedData
}

export const isSemanticVersion = (value: string) => {
  const regex = new RegExp(/^\d+\.\d+\.\d+$/)
  return regex.test(value)
}

export const compareValues = (aSortValue, bSortValue) => {
  if (typeof aSortValue === 'string') {
    aSortValue = aSortValue.toLowerCase()
  }

  if (typeof bSortValue === 'string') {
    bSortValue = bSortValue.toLowerCase()
  }
  if (
    typeof aSortValue === 'string' &&
    isSemanticVersion(aSortValue) &&
    typeof bSortValue === 'string' &&
    isSemanticVersion(aSortValue)
  ) {
    // Compare semantic versions
    return aSortValue.localeCompare(bSortValue, undefined, {
      numeric: true,
      sensitivity: 'base',
    })
  }

  if (aSortValue > bSortValue) {
    return 1
  } else if (aSortValue < bSortValue) {
    return -1
  } else {
    return 0
  }
}

export const getSortValue = (obj, primaryField, secondaryField) => {
  let primaryValue = Object.keys(obj[`${primaryField}`] || {}).includes(
    'sortValue',
  )
    ? obj[`${primaryField}`].sortValue
    : obj[`${primaryField}`]
  if (typeof obj === 'string') {
    primaryValue = primaryValue.toLowerCase()
  }

  let secondaryValue = Object.keys(obj[`${secondaryField}`] || {}).includes(
    'sortValue',
  )
    ? obj[`${secondaryField}`].sortValue
    : obj[`${secondaryField}`]
  if (typeof obj === 'string') {
    secondaryValue = secondaryValue.toLowerCase()
  }
  return { primaryValue, secondaryValue }
}

export const compareObjects = (
  primaryField: string | number,
  secondaryField: string | undefined,
  order: SortingOrder,
  secondaryOrder: SortingOrder,
) => {
  return function (a, b) {
    const { primaryValue: aSortValue, secondaryValue: aSecondarySortValue } =
      getSortValue(a, primaryField, secondaryField)

    const { primaryValue: bSortValue, secondaryValue: bSecondarySortValue } =
      getSortValue(b, primaryField, secondaryField)

    //Compare the primary sorting field
    const primaryComparison = compareValues(aSortValue, bSortValue)
    if (primaryComparison !== 0) {
      return order === 'DESC' ? -primaryComparison : primaryComparison
    }
    //Compare the secondary sorting field
    const secondaryComparison = compareValues(
      aSecondarySortValue,
      bSecondarySortValue,
    )
    return secondaryOrder === 'DESC'
      ? -secondaryComparison
      : secondaryComparison
  }
}

export const sortWithSecondaryData = (
  tableData: Record<string, any>[],
  key: number,
  sortingDirection: SortingOrder,
  secondarySortDirection: SortingOrder,
  secondaryKey: number,
): Record<string, any>[] => {
  if (tableData.length === 0) {
    return []
  }

  if (key === -1) {
    return tableData
  }

  const sortKey = Object.keys(tableData[0]).at(key)
  if (sortKey === undefined || sortKey === null) {
    return []
  }

  const secondarySortKey = Object.keys(tableData[0]).at(secondaryKey)
  if (secondaryKey === undefined || secondaryKey === null) {
    return []
  }

  return [...tableData].sort(
    compareObjects(
      sortKey,
      secondarySortKey,
      sortingDirection,
      secondarySortDirection,
    ),
  )
}

export const createCustomTableHeaders = (
  customHeaders: ColumnHeader[],
  tableData: Record<string, any>[],
  loadingRows: number,
): ColumnHeader[] => {
  if (customHeaders.length === 0) {
    const tableDataKeys = getDataKeys(tableData)
    if (tableDataKeys.length > 0) {
      return tableDataKeys.map((header: string): ColumnHeader => {
        return {
          name: header,
          sortable: true,
          visible: true,
        }
      })
    } else {
      return replaceEmptyCustomHeaders(customHeaders, loadingRows)
    }
  } else {
    return customHeaders
  }
}

export const changeNullValueToStringError = (
  tableData: Record<string, any>,
): Record<string, any>[] => {
  return tableData.map((data: any) => {
    for (const key in data) {
      if (data[`${key}`] === null) {
        data[`${key}`] = 'Error'
        return data
      }
    }
    return data
  })
}

export const addEmptyTableRows = (
  tableData: Record<string, any>[],
  loadingRows: number,
): Record<string, any>[] => {
  if (tableData.length > 0) {
    return tableData
  } else {
    const defaultTableData: Record<string, any>[] = []
    for (let rows = 0; rows < loadingRows; rows++) {
      const row: Record<string, any> = {}
      for (let column = 0; column < loadingRows; column++) {
        row[`${column}`] = null
      }
      defaultTableData.push(row)
    }
    return defaultTableData
  }
}

export const tableBodyColor = (
  bodyColor: string,
  isDarkTheme: boolean,
): string => {
  if (bodyColor) {
    return bodyColor
  } else if (isDarkTheme) {
    return colors.util.one.main
  } else {
    return colors.util.one.lighter
  }
}

const getDataKeys = (tableData: Record<string, any>[]): Record<string, any> => {
  return tableData.length > 0
    ? Object.keys(tableData[0] as Record<string, any>)
    : []
}

const replaceEmptyCustomHeaders = (
  tableHeaders: ColumnHeader[],
  loadingRows: number,
): ColumnHeader[] => {
  const emptyHeaders: ColumnHeader[] = []
  if (tableHeaders.length === 0) {
    for (let index = 0; index < loadingRows; index++) {
      emptyHeaders.push({ name: '--', sortable: false, visible: true })
    }
  }
  return emptyHeaders
}

export const findAndScrollToTop = (
  element: HTMLElement | null,
  scrollableElementClassName: string,
): void => {
  let currentParent = element
  while (
    currentParent !== null &&
    currentParent?.className !== scrollableElementClassName
  ) {
    currentParent = currentParent?.parentElement ?? null
  }
  if (currentParent) {
    currentParent.scroll({
      behavior: 'smooth',
      left: 0,
      top: 0,
    })
  }
}
