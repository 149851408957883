import { forwardRef } from 'react'

import { SkeletonBlockProps } from '../../interfaces'

import './SkeletonBlock.scss'

const SkeletonBlock = forwardRef<HTMLDivElement, SkeletonBlockProps>(
  ({ minHeight, width }, ref) => {
    const style = { minHeight, width }

    return (
      <div
        className="skeleton-block skeleton-pulsate"
        ref={ref}
        style={style}
        data-testid="skeleton-block"
      />
    )
  },
)

SkeletonBlock.displayName = 'SkeletonBlock'

export default SkeletonBlock
