import { AppAction, AppState } from '../Types'

export const setSession = (state: AppState, action: AppAction) => {
  return {
    ...state,
    oktaSession: action['status'],
  }
}

export const setUser = (state: AppState, action: AppAction) => {
  return {
    ...state,
    user: action['user'],
  }
}
