import { useQuery } from '@apollo/client'

import { Typography, colors } from '../../../../../design-system'
import { SkeletonBlock } from '../../../../../design-system/components/SkeletonBlock'
import { GET_MY_DEEPWATCH_EXPERTS } from '../../../../../graphql/queries/dwExperts'

import './MyDeepwatchExperts.scss'

const MyDeepwatchExperts = () => {
  const { loading, data } = useQuery(GET_MY_DEEPWATCH_EXPERTS)
  const dwExperts = data?.getMySquad

  return (
    <div className="my-deepwatch-experts">
      <div data-testid="my-deepwatch-experts">
        <Typography
          component="div"
          weight={500}
          size={16}
          color={colors.util.navy[100]}
          styles={{ lineHeight: '160%' }}
        >
          My Deepwatch experts
        </Typography>
      </div>
      <div className="section">
        {loading ? (
          <>
            {[...Array(3)].map((_, index) => (
              <div className="item" key={index}>
                <SkeletonBlock minHeight="17px" width="40%" />
                <SkeletonBlock minHeight="17px" width="20%" />
              </div>
            ))}
          </>
        ) : (
          dwExperts.map((companyData, index) => {
            return (
              <div className="item" key={index}>
                <Typography
                  component="div"
                  size={14}
                  color={colors.util.navy[100]}
                >
                  {companyData.jobTitle}
                </Typography>
                <Typography
                  component="div"
                  size={14}
                  color={colors.util.navy[50]}
                >
                  {companyData.name}
                </Typography>
              </div>
            )
          })
        )}
      </div>
    </div>
  )
}

export default MyDeepwatchExperts
