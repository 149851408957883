import { useContext } from 'react'
import { useLazyQuery, useQuery } from '@apollo/client'

import {
  Loader,
  Icon,
  colors,
  IconVariant,
  List,
  Typography,
  ToastType,
  Button,
} from '../../../../design-system'
import { Context } from '../../../App'
import { Message, Report } from '../../../../models'
import { GenerateReportModal } from '../../../Reports/GenerateReportModal'
import reportsEmptyState from '../../../../assets/reports-empty-state.png'
import { deployToastMessage, handleDownload } from '../../../../utils'
import ComponentError from '../../../common/ComponentError/ComponentError'
import { useModalContext } from '../../../../hooks'
import {
  DOWNLOAD_REPORT,
  DownloadReportData,
  DownloadReportVariables,
  GET_REPORTS,
  GetReportsData,
  GetReportsVariables,
} from '../../../../graphql/queries/report'

import './RecentReportsWidget.scss'

interface RecentReportsInterface {
  selectedCustomer: string | null
}

const RecentReports = ({
  selectedCustomer,
}: RecentReportsInterface): JSX.Element => {
  const { state, dispatch } = useContext(Context)
  const { closeModal, openModal } = useModalContext()

  const {
    loading,
    data: { getReports: reports } = { getReports: [] },
    previousData: { getReports: oldReports } = { getReports: [] },
    startPolling,
    stopPolling,
  } = useQuery<GetReportsData, GetReportsVariables>(GET_REPORTS, {
    variables: {
      selectedCustomer,
    },
    onCompleted: (data) => {
      if (data) {
        //Currently using polling as in the Reports component, but would like to upgrade to subscriptions when able
        const result = reports.some((x) => x.status === 'Requested')
        if (result) {
          startPolling(10000)
        } else {
          stopPolling()
        }
        const oldRequestedReports = oldReports.filter(
          (x) => x.status === 'Requested',
        )

        oldRequestedReports.forEach((oldReport) => {
          const match = reports.find(
            (newReport) =>
              oldReport.id === newReport.id &&
              newReport.status !== 'Requested' &&
              newReport.userName === state.user.username,
          )

          if (match) {
            const success: Message = {
              id: crypto.randomUUID(),
              text: `"${match.name}" Report has successfully been generated!`,
              messageType: ToastType.SuccessToast,
              secondsToExpire: 5000,
              dismissible: false,
            }

            deployToastMessage(success, dispatch)
          }
        })
      }
    },
  })

  const [downloadReport] = useLazyQuery<
    DownloadReportData,
    DownloadReportVariables
  >(DOWNLOAD_REPORT, {
    onCompleted: ({ downloadReport: path }) => {
      handleDownload(path)
    },
  })

  const handleDownloadButtonClick = (report: Report) => {
    downloadReport({
      variables: {
        input: { reportId: report.id },
        selectedCustomer,
      },
    })
  }

  //? is this how we want to render JSX? Would we rather want to keep this
  //? inside of the return?
  const renderContent = () => {
    if (loading) {
      return (
        <div className="loading-container">
          <Loader strokeWidth={2} size={50} />
        </div>
      )
    }

    if (!reports) {
      return (
        <div className="reports-error">
          <ComponentError />
        </div>
      )
    }

    if (reports.length > 0) {
      const listOptions = reports
        .filter(
          (reports) =>
            reports.status === 'Generated' || reports.status === 'Requested',
        )
        .sort((a, b) => +new Date(b.createdAt) - +new Date(a.createdAt))

        .map((report, index) => {
          return {
            id: report.id,
            key: index,
            name: report.name,
            rightIcon: 'downloadOutline' as IconVariant,
            loading: report.status === 'Requested' ? true : false,
            onClick: () => handleDownloadButtonClick(report),
          }
        })
        .slice(0, 3)

      return <List options={listOptions} variant="link" />
    } else {
      return (
        <div className="generate-reports-container">
          <div>
            <img
              data-testid="report-empty-state"
              src={reportsEmptyState}
              alt="Report Empty State"
              className="image"
            />
          </div>
          <Typography color={colors.util.navy[100]} variant={'text11medium'}>
            No generated reports
          </Typography>
          <div className="generate-button">
            <Button
              onClick={() =>
                openModal({
                  component: <GenerateReportModal closeModal={closeModal} />,
                  title: 'Monthly Report',
                })
              }
              variant="secondary"
              label="Generate Report"
              buttonStyles={{ display: 'flex' }}
            />
          </div>
        </div>
      )
    }
  }

  return (
    <div id="recent-reports" data-testid="recent-reports">
      <div className="title">
        <Typography
          component="span"
          color={colors.util.navy[100]}
          size={18}
          weight={600}
        >
          Recently generated reports
        </Typography>

        {!loading && !reports && (
          <Icon
            variant="warningOutline"
            size={20}
            color={colors.util.orange[100]}
          />
        )}
      </div>
      {renderContent()}
    </div>
  )
}

export default RecentReports
