import { ResponsiveRadialBar } from '@nivo/radial-bar'

import { Loader, colors } from '../../../../../../../../design-system'
import { useSecurityIndexContext } from '../../../../../../../../hooks/useSecurityIndexContext'
import ComponentError from '../../../../../../../common/ComponentError/ComponentError'
import { highlightMetric } from '../../../../../SecurityIndex.utils'

import './SiMetricChart.scss'

interface SiMetricsChartProps {
  active: string
}

const SiMetricsChart = ({ active }: SiMetricsChartProps) => {
  const {
    bans,
    yourIndex: {
      preTransformedIndexValues: { logSources, detections, enterpriseCoverage },
    },
    yourIndexLoading,
    yourIndexError,
  } = useSecurityIndexContext()

  const chartMetrics = [
    [
      {
        id: 'si-log-sources',
        data: [{ x: 'percentage', y: logSources }],
        color: colors.brand.secondary.main,
      },
    ],
    [
      {
        id: 'si-detections',
        data: [{ x: 'percentage', y: detections }],
        color: colors.util.three.light,
      },
    ],
    [
      {
        id: 'si-enterprise-coverage',
        data: [{ x: 'percentage', y: enterpriseCoverage }],
        color: colors.brand.primary.light,
      },
    ],
  ]

  const [logSourcesMetric, detectionsMetric, enterpriseCoverageMetric] =
    chartMetrics
  const { id: logSourcesId, color: logSourcesColor } = logSourcesMetric[0]
  const { id: detectionsId, color: detectionsColor } = detectionsMetric[0]
  const { id: enterpriseCoverageId, color: enterpriseCoverageColor } =
    enterpriseCoverageMetric[0]

  const metric = ({ center }) => {
    return (
      <g tabIndex={0} data-testid="metric">
        <text
          x={center[0]}
          y={center[1] - 12}
          textAnchor="middle"
          dominantBaseline="central"
          style={{
            fontSize: '28px',
            fontWeight: '600',
            fontFamily: 'Inter',
            lineHeight: '120%',
            fill: colors.util.navy[50],
          }}
        >
          {bans.yourIndex.index}
        </text>
      </g>
    )
  }

  const yourIndexText = ({ center }) => {
    return (
      <g tabIndex={0} data-testid="your-index-text">
        <text
          x={center[0]}
          y={center[1] + 12}
          textAnchor="middle"
          dominantBaseline="central"
          style={{
            fontSize: '10.636px',
            fontFamily: 'Inter',
            fontWeight: '500',
            lineHeight: '120%',
            fill: colors.util.navy[100],
          }}
        >
          YOUR INDEX
        </text>
      </g>
    )
  }

  if (yourIndexLoading) {
    return <Loader size={200} centered />
  }

  if (yourIndexError) {
    return (
      <div style={{ textAlign: 'center', flex: 1 }}>
        <ComponentError />
      </div>
    )
  }

  return (
    <div className="si-chart" data-testid="si-chart">
      <div id="si-metric-simon-chart" data-testid="si-metric-simon-chart">
        <div
          className="enabled-log-sources-container"
          data-testid={`enabled-log-sources-container ${
            active === logSourcesId ? ' active' : ''
          }`}
        >
          <ResponsiveRadialBar
            maxValue={100}
            startAngle={-88}
            endAngle={88}
            cornerRadius={0}
            innerRadius={0.7}
            colors={[highlightMetric(active, logSourcesId, logSourcesColor)]}
            tracksColor={colors.util.navy[400]}
            data={logSourcesMetric}
            isInteractive={false}
            layers={['tracks', 'bars', metric]}
          />
        </div>
        <div
          className="enabled-detections-container"
          data-testid={`enabled-detections-container ${
            active === detectionsId ? ' active' : ''
          }`}
        >
          <ResponsiveRadialBar
            maxValue={100}
            startAngle={92}
            endAngle={198}
            cornerRadius={0}
            innerRadius={0.7}
            colors={[highlightMetric(active, detectionsId, detectionsColor)]}
            tracksColor={colors.util.navy[400]}
            data={detectionsMetric}
            isInteractive={false}
            layers={['tracks', 'bars', yourIndexText]}
          />
        </div>
        <div
          className="enterprise-coverage-container"
          data-testid={`enterprise-coverage-container ${
            active === enterpriseCoverageId ? ' active' : ''
          }`}
        >
          <ResponsiveRadialBar
            maxValue={100}
            startAngle={202}
            endAngle={268}
            cornerRadius={0}
            innerRadius={0.7}
            tracksColor={colors.util.navy[400]}
            isInteractive={false}
            colors={[
              highlightMetric(
                active,
                enterpriseCoverageId,
                enterpriseCoverageColor,
              ),
            ]}
            data={enterpriseCoverageMetric}
            layers={['tracks', 'bars']}
          />
        </div>
      </div>
    </div>
  )
}
export default SiMetricsChart
