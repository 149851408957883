import React from 'react'
import { ResponsiveLine } from '@nivo/line'

import {
  AxisBottomTick,
  AxisLeftTick,
  displayToolTipItem,
} from '../LogSourceVolume/LogSourceUtils'
import { Icon, Typography, colors, Loader } from '../../../../design-system'

export const LogSourceVolumeLineChart = ({
  chartData,
  toolTipDates,
}): JSX.Element => {
  const themeConfig = {
    grid: {
      line: {
        stroke: '#455358',
        strokeWidth: 1,
      },
    },
    axis: {
      ticks: {
        line: {
          strokeWidth: 0,
        },
        text: {
          fontSize: 12,
          fill: '#A3A3A3',
        },
      },
    },
    crosshair: {
      line: {
        stroke: colors.util.navy[100],
        strokeWidth: 1,
        strokeOpacity: 0.75,
        strokeDasharray: '6 6',
      },
    },
  }

  return (
    <div tabIndex={0} role={'group'} className="flex-container column gap-24">
      <div className="flex-container space-between">
        <Typography
          component="div"
          styles={{ marginBottom: 16 }}
          color={colors.util.navy[100]}
          variant="text9semibold"
        >
          Log source volume week over week
        </Typography>

        <div>
          <div className="flex-container gap-24">
            <div className="flex-container gap-6 align-items-center">
              <Icon variant="square" color={'#EEF6F9'} />
              <Typography component="span" size={14}>
                Previous Week
              </Typography>
            </div>
            <div className="flex-container gap-6 align-items-center">
              <Icon variant="square" color={colors.brand.secondary.main} />
              <Typography component="span" size={14}>
                Current Week
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <div className="responsive-nivo-chart-container">
        {!chartData ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              width: '100%',
            }}
          >
            <Loader strokeWidth={2} size={25} />
          </div>
        ) : (
          <ResponsiveLine
            margin={{
              top: 25,
              right: 30,
              bottom: 50,
              left: 65,
            }}
            colors={[colors.brand.secondary.main, colors.util.navy[50]]}
            enableGridX={false}
            enableGridY={true}
            data={chartData}
            gridYValues={6}
            xScale={{ type: 'point' }}
            yScale={{
              type: 'linear',
              min: 'auto',
              max: 'auto',
              reverse: false,
            }}
            enableSlices="x"
            crosshairType="x"
            theme={themeConfig}
            axisBottom={{
              renderTick: AxisBottomTick,
              tickPadding: 1,
            }}
            axisLeft={{
              renderTick: AxisLeftTick,
              tickPadding: 5,
              tickValues: 5,
            }}
            pointSize={3}
            pointLabelYOffset={0}
            lineWidth={1}
            sliceTooltip={({ slice }) => {
              const reOrderedPoints = [
                slice.points.find((point) => point.serieId === 'previousWeek'),
                slice.points.find((point) => point.serieId === 'currentWeek'),
              ].filter((point) => point !== undefined)
              const day = String(reOrderedPoints[0]?.data.xFormatted).substring(
                0,
                3,
              )
              const returnedDate = toolTipDates.filter(
                (d) => d.substring(0, 3) === day,
              )
              return (
                <div
                  data-testid="slice-tooltip"
                  className="flex-container column"
                  style={{
                    backgroundColor: colors.util.navy[600],
                    borderRadius: 3,
                    border: '0.75px solid #576569',
                    minWidth: 110,
                  }}
                >
                  <Typography
                    component="span"
                    size={12}
                    styles={{ padding: 4 }}
                  >
                    {returnedDate[0]}
                  </Typography>
                  <div
                    className="flex-container column gap-1"
                    style={{
                      padding: 4,
                      borderTop: '0.75px solid #576569',
                    }}
                  >
                    <div className="tooltip-items">
                      {reOrderedPoints.map((p) => displayToolTipItem(p))}
                    </div>
                  </div>
                </div>
              )
            }}
          />
        )}
      </div>
    </div>
  )
}
