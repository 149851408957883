import React, { CSSProperties, useState, useEffect } from 'react'

import Tab from './Tab'
import { TabProps, TabsProps } from '../../interfaces/'
import { colors } from '../../theme'

const Tabs = ({
  activeTab = 0,
  data,
  onTabClick,
  tabStyles,
  triggerInactiveTabs,
  children,
  tabsInactive,
  border = true,
}: TabsProps): JSX.Element => {
  const [tabNumber, setTabNumber] = useState(activeTab)
  const [tabs, setTabs] = useState<TabProps[]>(data)
  const [inactiveTabs, setInactiveTabs] = useState(tabsInactive)
  const [triggerInactive, setTriggerInactive] = useState(triggerInactiveTabs)

  useEffect(() => {
    setTabs(data)
  }, [data])

  useEffect(() => {
    setTabNumber(activeTab !== undefined ? activeTab : 0)
  }, [activeTab])

  useEffect(() => {
    if (triggerInactive !== triggerInactiveTabs) {
      setInactiveTabs(true)
      setTriggerInactive(triggerInactiveTabs)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerInactiveTabs])

  const handleClick = (id: number) => {
    setTabNumber(id)
    onTabClick?.(id)
    setInactiveTabs(false)
  }

  const handleAddTab = () => {
    const newTabs = tabs.slice(0, tabs.length - 1)

    setTabs([
      ...newTabs,
      {
        text: 'New Tab',
        active: false,
        notification: false,
        id: tabs.length - 1,
        variant: 'informational',
      },
      {
        text: 'Add Tab',
        active: false,
        icon: 'searchOutline',
        notification: false,
        id: tabs.length,
        variant: 'action',
      },
    ])
  }

  const tabsStyle = (border: boolean): CSSProperties => ({
    display: 'flex',
    alignItems: 'center',
    overflowX: 'auto',
    listStyle: 'none',
    paddingLeft: 0,
    margin: 0,
    width: '100%',
    ...(border === true && {
      borderBottom: '1px solid ' + colors.util.navy[400],
    }),
  })

  return (
    <>
      <ul style={tabsStyle(border)}>
        {tabs &&
          tabs.map(({ text, notification, variant }, id: number) => {
            return (
              <Tab
                text={text}
                active={inactiveTabs ? false : id === tabNumber}
                notification={notification}
                variant={variant}
                id={id}
                key={id}
                styles={tabStyles}
                onAddTab={handleAddTab}
                onClick={() => handleClick(id)}
              />
            )
          })}
      </ul>
      {/* fix generic object sink: https://stackoverflow.com/questions/51715616/how-to-fix-codacy-alert-generic-object-injection-sink */}
      {inactiveTabs
        ? null
        : children && children[parseInt(tabNumber.toString())]}
    </>
  )
}

export default Tabs
