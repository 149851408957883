import React, { useState } from 'react'

import { colors } from '../../theme'
import { BannerProps, CSSProps } from '../../interfaces'
import { commonStyles, getLeftIcon } from './Banner.utils'
import { Icon } from '../Icon'
import { Typography } from '../Typography'
import { Button } from '../Button'

const Banner: React.FC<BannerProps> = ({
  text,
  variant,
  hasActionButton = false,
  buttonText,
  hasCloseIcon = true,
  styles,
}: BannerProps) => {
  const [opened, setOpened] = useState(true)
  const leftIcon = getLeftIcon(variant)

  const close = () => {
    setOpened(false)
  }

  const actionButton =
    hasActionButton && buttonText ? (
      <Button
        variant={'tertiary'}
        buttonStyles={{ padding: '0', color: colors.util.one.main }}
      >
        {buttonText}
      </Button>
    ) : null

  const closeIcon = hasCloseIcon ? (
    <Button
      iconProps={{ variant: 'close' }}
      buttonStyles={{
        backgroundColor: 'transparent',
        color: colors.util.one.main,
      }}
      onClick={close}
      variant="navigational-icon"
    />
  ) : null

  return opened ? (
    <div style={{ ...commonStyles(variant), ...styles }}>
      <div style={divStyle}>
        {leftIcon && (
          <Icon
            title={variant}
            variant={leftIcon}
            color={colors.util.one.main}
            size={19.5}
          />
        )}
        <Typography styles={textStyle}>{text}</Typography>
      </div>
      <div style={divStyle}>
        {actionButton}
        {closeIcon}
      </div>
    </div>
  ) : null
}

const textStyle: CSSProps = {
  fontWeight: 400,
  color: colors.util.navy[600],
  fontSize: 14,
  marginBlock: 0,
  display: 'flex',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
}

const divStyle: CSSProps = {
  display: 'flex',
  flexDirection: 'row',
  gap: 10.62,
  alignItems: 'center',
}

export default Banner
