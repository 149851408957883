export const handleKeyBoardAction = <T>(
  event: React.KeyboardEvent<HTMLElement>,
  triggerFn: (arg?: T) => void,
  triggerItem?: T,
): void => {
  event.stopPropagation()
  if (event.key === 'Enter' || event.key === ' ') {
    triggerFn(triggerItem)
  }
}
