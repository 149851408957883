import { useContext } from 'react'
import { useQuery } from '@apollo/client'

import { colors, Loader, Tooltip, Typography } from '../../../../design-system'
import { Context } from '../../../App'
import {
  GET_CUSTOMER_INFO_WITH_TA_VERSIONS,
  GetCustomerInfoData,
  GetCustomerInfoVariables,
} from '../../../../graphql/queries/customer'

import './TaVersions.scss'

const initialTaVersionState = {
  customerDwaAppVersion: '-',
  deepwatchLatestDwaAppVersion: '-',
}

export const versionColor = (customerVersion?: string, dwVersion?: string) => {
  if (customerVersion && dwVersion) {
    if (customerVersion >= dwVersion) {
      return colors.util.navy[100]
    } else {
      return colors.util.four.light
    }
  }
}

const TaVersions = (): JSX.Element => {
  const {
    state: {
      customer: { customerShortName },
      dwExpertsCustomer: { customerShortName: dwExpertCustomerShortName },
    },
  } = useContext(Context)

  const {
    data: { getCustomerInfo } = { initialTaVersionState },
    error,
    loading,
  } = useQuery<GetCustomerInfoData, GetCustomerInfoVariables>(
    GET_CUSTOMER_INFO_WITH_TA_VERSIONS,
    {
      variables: {
        selectedCustomer:
          customerShortName === '' ? dwExpertCustomerShortName : null,
      },
    },
  )

  if (loading) {
    return (
      <div className="taversions-container">
        <div
          className="taversions-container-inner"
          data-testid="taversions-loading"
        >
          <Loader strokeWidth={1} />
        </div>
      </div>
    )
  }

  if (error) {
    return (
      <div className="taversions-container">
        <div className={'taversions-container-inner'}>
          <Tooltip
            childrenStyles={{ zIndex: 9999999999 }}
            wrapperStyles={{ marginRight: '2px' }}
            target={
              <Typography
                variant={'text12'}
                color={colors.util.orange[100]}
                component="span"
              >
                Error
              </Typography>
            }
          >
            Error fetching DWA versions
          </Tooltip>
        </div>
      </div>
    )
  }

  return (
    <div className="taversions-container">
      <div className="taversions-container-inner">
        <Tooltip
          childrenStyles={{ zIndex: 9999999999 }}
          wrapperStyles={{ marginRight: '2px' }}
          target={
            <Typography
              variant={'text12'}
              color={colors.util.navy[100]}
              component="span"
            >
              DWA:
            </Typography>
          }
        >
          DWA is Deepwatch&apos;s content version. If you&apos;d like to update,
          contact your Deepwatch experts.
        </Tooltip>
        <Tooltip
          childrenStyles={{ zIndex: 9999999999 }}
          wrapperStyles={{ marginRight: '2px' }}
          target={
            <Typography
              variant={'text12'}
              color={versionColor(
                getCustomerInfo?.customerDwaAppVersion,
                getCustomerInfo?.deepwatchLatestDwaAppVersion,
              )}
              component="span"
            >
              {getCustomerInfo && getCustomerInfo.customerDwaAppVersion
                ? `v${getCustomerInfo.customerDwaAppVersion}`
                : 'n/a'}
            </Typography>
          }
        >
          Your DWA version
        </Tooltip>
        <Typography
          variant={'text12'}
          color={colors.util.navy[100]}
          component="span"
          styles={{ padding: '0 2px' }}
        >
          •
        </Typography>
        <Tooltip
          childrenStyles={{ zIndex: 9999999999 }}
          wrapperStyles={{ marginLeft: '2px' }}
          target={
            <Typography
              variant={'text12'}
              color={colors.util.navy[100]}
              component="span"
            >
              {getCustomerInfo &&
                `v${getCustomerInfo.deepwatchLatestDwaAppVersion}`}
            </Typography>
          }
        >
          Latest DWA version
        </Tooltip>
      </div>
    </div>
  )
}

export default TaVersions
