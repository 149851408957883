import { useQuery } from '@apollo/client'
import { getCoreRowModel, useReactTable } from '@tanstack/react-table'

import {
  MTTX_TABLE,
  MttxData,
  MttxTableData,
  MttxTableVariables,
} from '@queries/mttx'
import { buildSkeletonRows } from '@common/Table/utils/table.utils'
import { TableCell, TableHeader } from '@common/Table'
import { MttxMetric } from '@models/MttxDetails'
import CommonTable from '@common/Table/components/CommonTable'
import CommonTableContainer from '@common/Table/components/CommonTableContainer'
import CommonTableRow from '@common/Table/components/CommonTableRow'

import MttxColumnDefFactory from './MTTXColumnDefFactory'

interface MTTXTableProps {
  metric: MttxMetric
  selectedCustomer: string | null
  selectedDimension: string
  selectedPriority: string
  selectedSegment?: string
  startDate: string
  endDate: string
  selectedTicketType?: string
}

const MTTXTable: React.FC<MTTXTableProps> = ({
  metric,
  selectedCustomer,
  selectedDimension,
  selectedPriority,
  selectedSegment,
  startDate,
  endDate,
  selectedTicketType,
}) => {
  const {
    data: { mttxTable } = { mttxTable: { data: [] } },
    loading,
    error,
  } = useQuery<MttxTableData, MttxTableVariables>(MTTX_TABLE, {
    variables: {
      selectedCustomer,
      input: {
        dimension: selectedDimension,
        metric,
        segment: selectedSegment ? selectedSegment : undefined,
        ticketPriority:
          selectedPriority !== 'All' ? [selectedPriority] : undefined,
        startDate,
        endDate,
        ticketType:
          selectedTicketType && selectedTicketType !== 'All'
            ? [selectedTicketType]
            : undefined,
      },
    },
    fetchPolicy: 'cache-and-network',
  })

  const columnDefFactory = new MttxColumnDefFactory(metric, selectedCustomer)

  const table = useReactTable({
    columns: columnDefFactory.build(
      selectedPriority,
      selectedDimension,
      selectedSegment,
    ),
    data: mttxTable.data,
    enableSorting: false,
    getCoreRowModel: getCoreRowModel(),
  })

  if (error || (!loading && !mttxTable.data.length)) {
    return null
  }

  return (
    <CommonTableContainer
      data-testid="mttx-table-container"
      sx={{ paddingTop: '1rem' }}
    >
      <CommonTable>
        <thead data-testid="mttx-table-header">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} className="no-pointer-events">
              {headerGroup.headers.map((header) => (
                <TableHeader<MttxData> key={header.id} header={header} />
              ))}
            </tr>
          ))}
        </thead>

        <tbody data-testid="mttx-table-body">
          {loading && !mttxTable.data.length
            ? buildSkeletonRows(table.getAllColumns(), 1)
            : table.getRowModel().rows.map((row) => (
                <CommonTableRow
                  disableCursor
                  key={row.id}
                  data-testid="mttx-table-row"
                  isSelected={false}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell<MttxData> key={cell.id} cell={cell} />
                  ))}
                </CommonTableRow>
              ))}
        </tbody>
      </CommonTable>
    </CommonTableContainer>
  )
}

export default MTTXTable
