import { IconButtonProps } from '../../../interfaces'

export const handleRowClick = (
  id: number | string,
  data: any,
  event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  rowClick: ((id: string | number, rowData?: any) => void) | undefined,
) => {
  event.stopPropagation()
  event.currentTarget.focus()
  if (!['ION-ICON', 'BUTTON'].includes(event.currentTarget?.tagName)) {
    rowClick?.(id, data)
  }
}

export const handleKeyDown = (
  id: number | string,
  data: any,
  event: React.KeyboardEvent<HTMLElement>,
  rowClick: ((id: string | number, rowData?: any) => void) | undefined,
): void => {
  event.stopPropagation()
  if ((event.target as HTMLElement).classList.contains('table-row')) {
    if (event.key === 'Enter' || event.key === ' ') {
      rowClick?.(id, data)
    }
  }
}

export const handleButtonClick = (
  e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  buttonPropValue: IconButtonProps,
  tableRowData: Record<string, any>,
  loading: boolean,
) => {
  if (
    (tableRowData.disabled === undefined || tableRowData.disabled === false) &&
    loading === false
  ) {
    e.stopPropagation()
    buttonPropValue.onClick?.(e)
    buttonPropValue.customOnClick?.(tableRowData)
  }
}

export const handleIconButtonClick = (
  e: any,
  buttonPropValue: IconButtonProps,
  tableRowData: Record<string, any>,
  loading: boolean,
) => {
  if (
    (tableRowData.disabled === undefined || tableRowData.disabled === false) &&
    loading === false
  ) {
    e.stopPropagation()
    buttonPropValue.onClick?.(e)
    buttonPropValue.customOnClick?.(tableRowData)
  }
}
