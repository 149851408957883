/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useQuery, useMutation } from '@apollo/client'
import { useContext } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'

import {
  Typography,
  colors,
  Input,
  InlineMessageType,
  Button,
} from '../../../../../design-system'
import { Context } from '../../../../App'
import { useModalContext } from '../../../../../hooks'
import { deployToastMessage, renderErrorToast } from '../../../../../utils'
import { UserProfileModal } from './UserProfileModal'
import { UserAvatar } from '../../../../common/UserAvatar'
import { SkeletonBlock } from '../../../../../design-system/components/SkeletonBlock'
import {
  GET_USER,
  GetUserData,
  GetUserVariables,
} from '../../../../../graphql/queries/user'
import {
  UPDATE_USER,
  UpdateUserVariables,
} from '../../../../../graphql/mutations/user'

import './ProfileOverview.scss'

const ProfileOverview = () => {
  const { state, dispatch } = useContext(Context)
  const { closeModal, openModal } = useModalContext()

  const isFederatedCustomer = state.customer.settings?.isOktaFederated

  const { loading, data: { getUser: user } = { getUser: undefined } } =
    useQuery<GetUserData, GetUserVariables>(GET_USER, {
      variables: {
        input: {
          userId: state.user.id,
        },
      },
    })

  const [updateUser] = useMutation<UpdateUserVariables>(UPDATE_USER, {
    refetchQueries: [GET_USER],
  })

  const EditUserProfileSchema = Yup.object().shape({
    firstName: Yup.string()
      .max(256, 'First name must be between 1 and 256 characters.')
      .required('First name is required.'),
    lastName: Yup.string()
      .max(256, 'Last name must be between 1 and 256 characters.')
      .required('Last name is required.'),
    email: Yup.string()
      .email('Enter a valid email address.')
      .required('Email is required.'),
    title: Yup.string().max(256, 'Title must be between 1 and 256 characters.'),
  })

  const onSubmit = async (values) => {
    if (!isFederatedCustomer && user?.email !== values.email) {
      //show modal
      openModal({
        component: (
          <UserProfileModal
            closeModal={closeModal}
            userId={state.user.id}
            formValues={{
              firstName: values.firstName,
              lastName: values.lastName,
              email: values.email,
              title: values.title,
            }}
          />
        ),
        title: 'Update Email',
      })
    } else {
      await updateUser({
        variables: {
          input: {
            userIdToUpdate: state.user.id,
            firstName: values.firstName,
            lastName: values.lastName,
            title: values.title,
            email: values.email,
          },
        },
        onCompleted: () => {
          deployToastMessage(
            {
              id: crypto.randomUUID(),
              text: 'User updated',
              messageType: InlineMessageType.SuccessInline,
              dismissible: false,
              secondsToExpire: 6000,
            },
            dispatch,
          )
        },
        onError: (e) => {
          renderErrorToast(e, dispatch)
        },
      })
    }
  }

  return (
    <>
      <div id="profile-overview-page" data-testid="profile-overview">
        <Formik
          initialValues={{
            firstName: user?.firstName,
            lastName: user?.lastName,
            email: user?.email,
            title: user?.title,
          }}
          onSubmit={onSubmit}
          enableReinitialize={true}
          validationSchema={EditUserProfileSchema}
        >
          {({
            handleBlur,
            handleChange,
            submitForm,
            errors,
            touched,
            values,
            dirty,
            isValid,
          }) => {
            return (
              <>
                <div id="profile-header-info" className="profile-header-info">
                  <div>
                    {loading ? (
                      <SkeletonBlock minHeight="80px" width="80px" />
                    ) : (
                      <UserAvatar
                        fontSize={28}
                        size={80}
                        userInfo={{
                          id: user!.id!,
                          firstName: user!.firstName,
                          lastName: user!.lastName,
                          type: 'dw',
                        }}
                      />
                    )}
                  </div>
                  <div className="user-name-title">
                    {loading ? (
                      <SkeletonBlock minHeight="34px" width="50%" />
                    ) : (
                      <Typography
                        variant="text7semibold"
                        size={28}
                        weight={600}
                        color={colors.util.navy[50]}
                      >
                        {user?.firstName} {user?.lastName}
                      </Typography>
                    )}

                    {loading ? (
                      <SkeletonBlock minHeight="16px" width="30%" />
                    ) : (
                      <Typography
                        variant="text10"
                        size={16}
                        weight={400}
                        color={colors.util.navy[100]}
                      >
                        {user?.title}
                      </Typography>
                    )}
                  </div>
                </div>

                <div>
                  <Input
                    addErrorSpacing
                    required
                    labelId="first name"
                    error={errors.firstName !== undefined && touched.firstName}
                    errorText={errors.firstName}
                    label={'First'}
                    placeholder={'Enter First Name'}
                    value={values.firstName}
                    name="firstName"
                    type="text"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={loading || isFederatedCustomer}
                  />
                </div>

                <div>
                  <Input
                    addErrorSpacing
                    required
                    labelId="last name"
                    error={errors.lastName !== undefined && touched.lastName}
                    errorText={errors.lastName}
                    label={'Last'}
                    placeholder={'Enter Last Name'}
                    value={values.lastName}
                    name="lastName"
                    type="text"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={loading || isFederatedCustomer}
                  />
                </div>

                <div>
                  <Input
                    addErrorSpacing
                    labelId="title"
                    label={'Title'}
                    placeholder={'Enter Job Title'}
                    error={errors.title !== undefined && touched.title}
                    errorText={errors.title}
                    value={values.title}
                    name="title"
                    type="text"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={loading || isFederatedCustomer}
                  />
                </div>

                <div>
                  <Input
                    addErrorSpacing
                    required
                    labelId="email"
                    error={errors.email !== undefined && touched.email}
                    errorText={errors.email}
                    label={'Email'}
                    placeholder={'Enter Email Address'}
                    value={values.email}
                    name="email"
                    type="text"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={loading || isFederatedCustomer}
                  />
                </div>

                <div className="profile-submit-button">
                  <Button
                    type="submit"
                    onClick={() => submitForm()}
                    disabled={!isValid || !dirty || isFederatedCustomer}
                  >
                    Update
                  </Button>
                </div>
              </>
            )
          }}
        </Formik>

        <div id="profile-header-line"></div>
      </div>
    </>
  )
}

export default ProfileOverview
