import React, { CSSProperties, useState } from 'react'

import { ListItemType, ListItemVariant } from '../../interfaces'
import { ListItem } from '../ListItem'
import { Typography } from '../Typography'

export interface ListProps {
  label?: string
  options: ListItemType[]
  selected?: ListItemType
  variant?: ListItemVariant
}

const List = ({
  options,
  selected,
  label,
  variant = 'standard',
}: ListProps): JSX.Element => {
  const [cursorIndex, setCursorIndex] = useState<number>(
    (selected && options.indexOf(selected as ListItemType)) ?? -1,
  )

  const handleHoveredOption = (option: ListItemType | undefined): void => {
    setCursorIndex(option === undefined ? -1 : options.indexOf(option))
  }

  const getListItems = (): JSX.Element[] => {
    return options.map((item, index) => (
      <ListItem
        key={item.id}
        active={index === cursorIndex}
        selected={false}
        item={item}
        onHover={handleHoveredOption}
        variant={variant}
      />
    ))
  }

  return (
    <>
      {label ? <Typography variant="text9semibold">{label}</Typography> : null}
      <ul style={listContainer} role="listbox">
        {getListItems()}
      </ul>
    </>
  )
}

const listContainer: CSSProperties = {
  width: '100%',
  maxWidth: '100%',
  display: 'flex',
  flexDirection: 'row',
  gap: 8,
  flexWrap: 'wrap',
  marginBlock: '0',
  marginInline: '0',
  paddingInline: '0',
  borderRadius: 5,
  backgroundColor: 'none',
  overflow: 'auto',
}

export default List
