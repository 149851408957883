import { CSSProperties } from 'react'

import { ListItemType } from '../../interfaces'
import { Menu } from '../Menu'

export interface PopoverMenuProps {
  options: ListItemType[]
  handleOutsideClick: () => void
  onSelect: () => void
  styles?: CSSProperties
}

export const PopoverMenu = ({
  options,
  handleOutsideClick,
  onSelect,
  styles,
}: PopoverMenuProps): JSX.Element => {
  return (
    <Menu
      variant={'popover'}
      options={options}
      handleOutsideClick={handleOutsideClick}
      onSelect={onSelect}
      styles={styles}
    />
  )
}

export default PopoverMenu
