import { useRef } from 'react'

import {
  Button,
  Icon,
  Toggle,
  Typography,
  colors,
  useOutsideClick,
} from '../../../../../../../../design-system'

import './TrendSelector.scss'

const TrendSelector = () => {
  const indexComparisonContainerRef = useRef<HTMLDivElement>(null)
  const indexComparisonPopoverRef = useRef<HTMLDivElement>(null)
  useOutsideClick(
    () => indexComparisonPopoverRef.current?.classList.remove('visible'),
    true,
    indexComparisonPopoverRef,
    true,
    indexComparisonContainerRef,
  )
  const togglePopover = () => {
    if (indexComparisonPopoverRef.current?.classList.contains('visible')) {
      return indexComparisonPopoverRef.current.classList.remove('visible')
    }
    indexComparisonPopoverRef.current?.classList.add('visible')
  }
  return (
    <div ref={indexComparisonContainerRef} id="trend-selector">
      <Button
        variant="secondary"
        buttonStyles={{
          color: colors.util.navy[100],
          padding: 8,
        }}
        onClick={togglePopover}
      >
        <Icon variant={'ellipsisVertical'} />
      </Button>
      <div
        ref={indexComparisonPopoverRef}
        id="index-comparison-popover"
        className="flex column gap-16"
      >
        <Typography component="span" variant="text10semibold">
          Index comparison
        </Typography>
        <Toggle
          label="Industry average"
          labelStyles={{ margin: 0, padding: 0 }}
          inputStyles={{ marginLeft: 'auto' }}
          check={false}
        ></Toggle>
        <Toggle
          label="Deepwatch average"
          labelStyles={{ margin: 0, padding: 0 }}
          inputStyles={{ marginLeft: 'auto' }}
          check={false}
        ></Toggle>
        <Toggle
          label="Past period"
          labelStyles={{ margin: 0, padding: 0 }}
          inputStyles={{ marginLeft: 'auto' }}
          check={false}
        ></Toggle>
      </div>
    </div>
  )
}
export default TrendSelector
