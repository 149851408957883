import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { init as SentryInit, BrowserTracing } from '@sentry/react'
import { ErrorBoundary } from 'react-error-boundary'
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk'
import { createRoot } from 'react-dom/client'

import { ThemeProvider } from './design-system'
import { App, Provider } from './components'
import { Security, UserInit } from './components/Routes'
import { oktaAuthConfig } from './config/OktaAuthConfig'
import { ErrorFallback } from './components/App/Errors'
import AppWrapper from './components/Wrappers/AppWrapper'
import { ModalContextProvider } from './components/common/Modal'

SentryInit({
  dsn: 'https://4972ad37e2ab4dbc9713eb2518d23918@o442593.ingest.sentry.io/6293238',
  environment: process.env.ENV,
  integrations: [new BrowserTracing()],

  //? Set tracesSampleRate to 1.0 to capture 100%
  //? of transactions for performance monitoring.
  //? We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  enabled: process.env.ENV !== 'development',
})

pendo.initialize({ apiKey: 'd467d24f-dc33-46b6-4232-819e168a2731' })

//? launchdarkly async
;(async () => {
  let launchDarklyKey
  switch (process.env.ENV) {
    case 'production':
      launchDarklyKey = '632888d7fdf46211c258ad7f'
      break
    case 'staging':
      launchDarklyKey = '636544b1a3ad8811a6022d9e'
      break
    case 'development':
      launchDarklyKey = '632e440d8bfb4b119a46915a'
      break
  }

  const LDProvider = await asyncWithLDProvider({
    clientSideID: launchDarklyKey,
    options: {
      allAttributesPrivate: true,
    },
  })

  const root = createRoot(document.getElementById('root'))

  root.render(
    <React.StrictMode>
      <ThemeProvider defaultTheme="dark">
        <Provider>
          <AppWrapper>
            <ErrorBoundary
              fallbackRender={() => <ErrorFallback style={{ left: '50%' }} />}
            >
              <LDProvider>
                <Router>
                  <ModalContextProvider>
                    <Security oktaAuth={oktaAuthConfig}>
                      <UserInit>
                        <App />
                      </UserInit>
                    </Security>
                  </ModalContextProvider>
                </Router>
              </LDProvider>
            </ErrorBoundary>
          </AppWrapper>
        </Provider>
      </ThemeProvider>
    </React.StrictMode>,
  )
})()
