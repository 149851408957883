import { useContext } from 'react'
import { useMutation } from '@apollo/client'

import { User, OktaGroup } from '../../../../models'
import { InlineMessageType } from '../../../../design-system'
import { deployToastMessage, renderErrorToast } from '../../../../utils'
import AddEditUserForm, {
  AddEditUserFormValues,
} from '../AddEditUserForm/AddEditUserForm'
import { ModalTitles } from '../UserManagementSettings'
import { Context } from '../../../App'
import { mapToListItems } from '../UserManagementSettings.util'
import { ModalContentProps } from '../../../common/Modal'
import { GET_USER_MANAGEMENT_SETTINGS } from '../../../../graphql/queries/user'
import {
  CREATE_USER,
  CreateUserVariables,
} from '../../../../graphql/mutations/user'

interface AddEditUserModalProps extends ModalContentProps {
  user?: User
  isLastAdmin: boolean
  customerGroups: OktaGroup[]
  title: ModalTitles
  clearUser: () => void
  userGroups?: Array<OktaGroup>
}

const AddUserModal = ({
  user,
  isLastAdmin,
  customerGroups,
  title,
  clearUser,
  closeModal,
  userGroups,
}: AddEditUserModalProps) => {
  const { dispatch: globalDispatch, state } = useContext(Context)

  const isEditingSelf = state.user.id === user?.id
  const isFederatedCustomer = state.customer.settings?.isOktaFederated

  const adminGroupID = customerGroups.find((group) =>
    group.groupName.includes('admins'),
  )?.groupId as string

  const initialGroups = userGroups ? mapToListItems(userGroups) : []

  const refetchQueries = [GET_USER_MANAGEMENT_SETTINGS]

  const [createUser] = useMutation<CreateUserVariables>(CREATE_USER, {
    refetchQueries,
  })
  const handleAddUser = async (
    values: AddEditUserFormValues,
  ): Promise<void> => {
    closeModal()
    clearUser()
    deployToastMessage(
      {
        id: crypto.randomUUID(),
        text: 'Processing request to create user',
        messageType: InlineMessageType.Info,
        dismissible: false,
        secondsToExpire: 3000,
      },
      globalDispatch,
    )

    if (values.isAdmin) {
      values.groupIDs = [...values.groupIDs, adminGroupID]
    }

    await createUser({
      variables: {
        input: {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          title: values.title,
          initialGroupIds: values.groupIDs,
        },
      },
      onCompleted: () => {
        deployToastMessage(
          {
            id: crypto.randomUUID(),
            text: 'User created',
            messageType: InlineMessageType.SuccessInline,
            dismissible: false,
            secondsToExpire: 6000,
          },
          globalDispatch,
        )
      },
      onError: (e) => {
        renderErrorToast(e, globalDispatch)
      },
    })
  }
  return (
    <div id="add-edit-user-modal" data-testid="add-edit-user-modal">
      <AddEditUserForm
        customerGroups={customerGroups}
        initialGroups={initialGroups}
        isEditingSelf={isEditingSelf}
        isFederatedCustomer={isFederatedCustomer ? isFederatedCustomer : false}
        isLastAdmin={isLastAdmin}
        modalState={title}
        onCancelButtonClick={() => closeModal()}
        onSubmit={handleAddUser}
        user={user}
      />
    </div>
  )
}

export default AddUserModal
