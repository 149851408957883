import { ResponsiveFunnel } from '@nivo/funnel'

import {
  Icon,
  Typography,
  Loader,
  colors,
  CSSProps,
} from '../../../../../design-system'
import {
  customLegend,
  CustomParts,
  customSeparator,
} from './ThreatProtectionSummary.customLayers'
import { ThreatProtectionSummaryInterface } from './ThreatProtectionSummary.models'
import {
  scaleValues,
  threatSummaryHasData,
} from './ThreatProtectionSummary.utils'
import ComponentError from '../../../../common/ComponentError/ComponentError'

import '../../Dashboard.scss'
import './ThreatProtectionSummary.scss'

const ThreatProtectionSummary = ({
  loading,
  data,
  dateFilterableStyle,
}: ThreatProtectionSummaryInterface): JSX.Element => {
  const centeredStyles: CSSProps = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    flexDirection: 'column',
  }
  const renderChart = () => {
    if (loading) {
      return (
        <div style={centeredStyles}>
          <Loader size={50} strokeWidth={2} />
        </div>
      )
    }

    if (!data || !threatSummaryHasData(data)) {
      return <ComponentError />
    }

    const scaleValuesData = scaleValues(data)

    return (
      <div
        style={{
          display: 'flex',
          height: '100%',
          minHeight: 0,
          minWidth: 0,
          width: '99%',
        }}
      >
        <ResponsiveFunnel
          data={scaleValuesData}
          borderOpacity={0}
          labelColor={{
            from: 'color',
            modifiers: [['darker', 3]],
          }}
          enableLabel={false}
          layers={[CustomParts, customSeparator]}
          enableAfterSeparators={false}
          enableBeforeSeparators={false}
          beforeSeparatorLength={100}
          beforeSeparatorOffset={0}
          afterSeparatorLength={100}
          afterSeparatorOffset={0}
          currentPartSizeExtension={10}
          currentBorderWidth={40}
          motionConfig="wobbly"
          direction="horizontal"
          ariaDescribedBy="tps-chart-description"
          isInteractive={true}
        />

        <p id="tps-chart-description" hidden>
          threat protection summary of all logged events filtering down to
          alerts and further filtering to total tickets
        </p>
        <div
          className="threat-protection-summary"
          data-testid="tps-chart-legend"
          aria-hidden
        >
          {customLegend(data)}
        </div>
      </div>
    )
  }

  return (
    <div
      id="threat-protection-summary"
      className="chart-container"
      data-testid="threat-protection-summary"
    >
      <div className="content-bar">
        <div className="chart-title">
          <Typography variant={'text9semibold'} component="span">
            Threat protection summary
          </Typography>

          {!loading && (!data || !threatSummaryHasData(data)) && (
            <Icon
              variant="warningOutline"
              size={20}
              color={colors.util.orange[100]}
            />
          )}
        </div>
      </div>
      <div
        className={
          !dateFilterableStyle
            ? 'threat-protection-summary-chart-container component-border'
            : 'threat-protection-summary-chart-container date-border'
        }
        style={{ background: colors.util.navy[400] }}
      >
        {renderChart()}
      </div>
    </div>
  )
}

export default ThreatProtectionSummary
