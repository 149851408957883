import { ListItemSelectType } from '../../../../../design-system'
import { setPTagsAndLabels } from '../../utils'

//this data is found in the enterprise data dictionary
export const cisControlOptions = [
  {
    id: 1,
    name: 'CIS1',
  },
  {
    id: 1,
    name: 'CIS2',
  },
  {
    id: 1,
    name: 'CIS3',
  },
  {
    id: 1,
    name: 'CIS4',
  },
  {
    id: 1,
    name: 'CIS5',
  },
  {
    id: 1,
    name: 'CIS6',
  },
  {
    id: 1,
    name: 'CIS7',
  },
  {
    id: 1,
    name: 'CIS8',
  },
  {
    id: 1,
    name: 'CIS9',
  },
  {
    id: 1,
    name: 'CIS10',
  },
  {
    id: 1,
    name: 'CIS11',
  },
  {
    id: 1,
    name: 'CIS12',
  },
  {
    id: 1,
    name: 'CIS13',
  },
  {
    id: 1,
    name: 'CIS14',
  },
  {
    id: 1,
    name: 'CIS15',
  },
  {
    id: 1,
    name: 'CIS16',
  },
  {
    id: 1,
    name: 'CIS17',
  },
  {
    id: 1,
    name: 'CIS18',
  },
  {
    id: 1,
    name: 'CIS19',
  },
  {
    id: 1,
    name: 'CIS20',
  },
]

//this data is found in the enterprise data dictionary
export const incidentTypeOptions = [
  { id: 1, name: 'Access' },
  { id: 2, name: 'Authentication' },
  { id: 3, name: 'Demisto' },
  { id: 4, name: 'Observium' },
  { id: 5, name: 'Phishing' },
  { id: 6, name: 'Infrastructure' },
  { id: 7, name: 'Cloud' },
  { id: 8, name: 'Threat Intelligence' },
  { id: 9, name: 'Malware' },
  { id: 10, name: 'Email' },
  { id: 11, name: 'Network' },
  { id: 12, name: 'Policy Violation' },
  { id: 13, name: 'Intrustion Detection' },
  { id: 14, name: 'Web' },
  { id: 15, name: 'Audit' },
  { id: 16, name: 'Data Loss Prevention' },
  { id: 17, name: 'Endpoint' },
  { id: 18, name: 'Interprocess Messaging' },
  { id: 19, name: 'Vulnerability Management' },
]

//this data is found in the enterprise data dictionary
export const threatHuntOptions = [
  { id: 1, name: 'Security Incident' },
  { id: 2, name: 'Misconfiguration' },
  { id: 3, name: 'Insecure Practice Identified' },
  { id: 4, name: 'Logging Gap Identified' },
  { id: 5, name: 'Detection Content Development -Alert' },
  { id: 6, name: 'Detection Content Development -Dashboard' },
  { id: 7, name: 'Policy Violation' },
  { id: 8, name: 'Vulnerability Identification' },
  { id: 9, name: 'Inconclusive' },
  { id: 10, name: 'No Findings' },
  { id: 11, name: 'Data Model Mapping' },
]

export const selectFieldOptions = (key: string): ListItemSelectType[] => {
  switch (key) {
    case 'cisControl':
      return cisControlOptions
    case 'incidentType':
      return incidentTypeOptions
    case 'threatHuntOutcomes':
      return threatHuntOptions
    default:
      return [{ id: 0, name: '---' }]
  }
}

export const additionalFieldsUniqueLabelNames = (key: string): string => {
  switch (key) {
    case 'cisControl':
      return 'CIS control'
    case 'incidentType':
      return setPTagsAndLabels(key)
    default:
      return setPTagsAndLabels(key)
  }
}
