import { CSSProperties, useState } from 'react'

import { colors } from '../../design-system'

const skipLinkStyles: CSSProperties = {
  position: 'absolute',
  left: '50%',
  transform: 'translateY(-100%)',
  color: colors.util.navy[100],
  padding: '8px',
  zIndex: 99999,
}

const SkipLink = ({ text }) => {
  const [linkStyles, setLinkStyles] = useState(skipLinkStyles)

  return (
    <a
      href="#main"
      style={{ ...linkStyles }}
      tabIndex={0}
      onFocus={() =>
        setLinkStyles({
          ...linkStyles,
          transform: 'translateY(0)',
        })
      }
      onBlur={() =>
        setLinkStyles({
          ...linkStyles,
          transform: 'translateY(-100%)',
        })
      }
    >
      {text}
    </a>
  )
}

export default SkipLink
