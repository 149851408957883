import { useContext } from 'react'

import { DeepwatchExpertsHome } from './DeepwatchExpertsHome'
import { Context } from '../App'
import { Dashboard } from '../Home/Dashboard'
import { Customer } from '../../models'
import { Paths } from '../App/Types'

export interface CustomerNamesProps {
  handleSetCurrentCustomer: (cc: Customer) => void
}

const DeepwatchExperts = () => {
  const {
    state: { dwExpertsActiveTab },
  } = useContext(Context)

  const dwExpertsComponents: JSX.Element[] = []
  dwExpertsComponents.push(
    <DeepwatchExpertsHome key={0} />,
    <Dashboard key={1} />,
  )

  return (
    <>
      {
        dwExpertsComponents[
          `${
            location.pathname === Paths.DEEPWATCH_EXPERTS
              ? 0
              : dwExpertsActiveTab
          }`
        ]
      }
    </>
  )
}

export default DeepwatchExperts
