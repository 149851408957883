import { Outlet } from 'react-router-dom'
import classNames from 'classnames'

import Header from './Header/Header'
import { Sidebar } from './Sidebar'
interface Props {
  hideSidebar?: boolean
}

const Layout = ({ hideSidebar }: Props) => {
  return (
    <>
      <Header />
      <main id="main" className={classNames({ 'no-sidebar': hideSidebar })}>
        <Outlet />
        {!hideSidebar && <Sidebar />}
      </main>
    </>
  )
}

export default Layout
