import { Box, Typography } from '@mui/material'
import cloneDeep from 'lodash/cloneDeep'
import { useSearchParams } from 'react-router-dom'
import { ColumnFilter, ColumnFiltersState } from '@tanstack/react-table'
// eslint-disable-next-line lodash/import-scope
import { isArray } from 'lodash'

import { FilterChipsHeader, FilterValue } from '@common/FilterChips'
import { removeValueFromSearchParamHash } from '@components/Tickets/TicketLibrary/TicketLibrary.utils'

import ForwardersDownload from './ForwardersDownload'
export interface ForwardersHeaderProps {
  totalForwarders: number
  filters: ColumnFilter[]
  setFilters: React.Dispatch<React.SetStateAction<ColumnFiltersState>>
}

const ForwardersHeader: React.FC<ForwardersHeaderProps> = ({
  totalForwarders,
  filters,
  setFilters,
}) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const formattedSelectedFilters = filters.reduce(
    (acc, curr) => {
      if (curr.value !== '') {
        const formatChipValue = (curr.value as string[]).map((val) => {
          if (isArray(val)) {
            return val.join(' ')
          }
          return val.replace('_', ' ')
        })
        return { ...acc, [curr.id]: formatChipValue }
      }
    },
    { status: '', type: '' } as any,
  )

  const handleDeleteChip = (filterGroup: string, filterValue: FilterValue) => {
    setFilters((old) => {
      const dup = cloneDeep(old)
      const matchIndex = dup.findIndex((val) => val.id === filterGroup)
      const values = dup[matchIndex].value as string[]

      values.splice(
        values.findIndex((val) => val === filterValue),
        1,
      )

      dup[matchIndex].value = values

      return dup
    })
  }

  return (
    <Box sx={{ padding: '1rem 0', width: '100%' }}>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          marginBottom: '1em',
        }}
      >
        <Typography component={'span'}>{totalForwarders} Forwarders</Typography>

        <ForwardersDownload total={totalForwarders} />
      </Box>
      <FilterChipsHeader
        deleteChip={(filterGroup, filterValue) => {
          handleDeleteChip(filterGroup, filterValue)
          const searchParamsWithUpdatedSelectedFilters =
            removeValueFromSearchParamHash(
              searchParams,
              'selectedFilters',
              filterGroup,
              filterValue as string,
            )

          setSearchParams(searchParamsWithUpdatedSelectedFilters)
        }}
        selectedFilters={{
          filters: formattedSelectedFilters,
        }}
      />
    </Box>
  )
}

export default ForwardersHeader
