import { ReactNode } from 'react'
import classNames from 'classnames'

import { colors, CSSProps, Typography } from '../../../../design-system'

interface Props {
  children: ReactNode
  cardStyles?: CSSProps
  className?: string
  title?: string
  variant?: 'background' | 'transparent'
}

const TicketEditCard: React.FC<Props> = ({
  cardStyles,
  children,
  className,
  title,
  variant = 'background',
}) => {
  const cardClassnames = classNames(
    'ticket-edit-card flex column gap-24',
    className,
    {
      transparent: variant === 'transparent',
    },
  )

  return (
    <section className={cardClassnames} style={cardStyles}>
      {title && (
        <Typography color={colors.util.navy[50]} variant="text10semibold">
          {title}
        </Typography>
      )}
      {children}
    </section>
  )
}

export default TicketEditCard
