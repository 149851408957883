import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { format, parseISO } from 'date-fns'
import classNames from 'classnames'
import { ReactSVG } from 'react-svg'

import atiLogo from '../../../../../../assets/ati_logo.svg'
import {
  Button,
  colors,
  Tooltip,
  Typography,
} from '../../../../../../design-system'
import {
  ReportType,
  ReportConfidenceLevel,
  ThreatIntelReport,
} from '../../../../../../models/ThreatIntel'
import { Paths } from '../../../../../App/Types'
import { Context } from '../../../../../App'

import './FullReportHeader.scss'
type FullReportHeaderProps = Pick<
  ThreatIntelReport,
  'title' | 'type' | 'source' | 'confidenceLevel' | 'createdDate'
>
const FullReportHeader: React.FC<FullReportHeaderProps> = ({
  title,
  type,
  source,
  confidenceLevel,
  createdDate,
}) => {
  const {
    state: {
      user: { isDWEmployee },
      dwExpertsCustomer: { customerShortName },
    },
  } = useContext(Context)
  const navigate = useNavigate()

  const handleBackNavigation = () => {
    if (isDWEmployee) {
      navigate(`${Paths.THREAT_INTEL_REPORTS}?customer=${customerShortName}`)
    } else {
      navigate(`${Paths.THREAT_INTEL_REPORTS}`)
    }
  }

  return (
    <div
      className={classNames('full-report-header', {
        'cyber-intel-header': type === ReportType.CYBER_INTEL_BRIEF,
        'customer-advisory-awareness-header':
          type === ReportType.CUSTOMER_ADVISORY_AWARENESS,
        'customer-advisory-sce-header':
          type === ReportType.CUSTOMER_ADVISORY_SVE,
      })}
      data-testid="full-report-header"
    >
      <div className="back-navigation">
        <Button
          testId="back-navigation"
          variant="icon"
          iconProps={{
            variant: 'arrowBackCircleOutline',
          }}
          buttonStyles={{
            backgroundColor: 'transparent',
            color: colors.util.navy[100],
            borderRadius: '5rem',
            paddingLeft: '1rem',
          }}
          onClick={handleBackNavigation}
        />
      </div>
      <div
        className="full-report-logo-area"
        data-testid="full-report-logo-area"
      >
        <div style={{ alignSelf: 'center' }}>
          <ReactSVG
            src={atiLogo}
            className={classNames('ati-logo', {
              'cyber-intel-brief': type === ReportType.CYBER_INTEL_BRIEF,
              'customer-advisory-awareness':
                type === ReportType.CUSTOMER_ADVISORY_AWARENESS,
              'customer-advisory-sce':
                type === ReportType.CUSTOMER_ADVISORY_SVE,
            })}
          />
        </div>
        <Tooltip
          target={
            <Typography
              variant="text12"
              weight={400}
              color={colors.util.navy[100]}
            >
              Deepwatch Adversary Tactics & Intelligence
            </Typography>
          }
          wrapperStyles={{
            display: 'flex',
            flex: '1 0 0',
            minWidth: 'fit-content',
          }}
        >
          Report Author
        </Tooltip>
        <Typography
          styles={{ textAlign: 'center', maxWidth: '500px' }}
          color={colors.util.navy[50]}
          variant="text7semibold"
        >
          {title}
        </Typography>

        <div
          data-testid="full-report-header-details"
          className="full-report-header-details"
        >
          {createdDate && (
            <>
              <Typography color={colors.util.navy[100]} variant="text12">
                {format(parseISO(createdDate), 'MMM d, yyyy')}
              </Typography>
              <Typography color={colors.util.navy[100]} variant="text12">
                &#x2022;
              </Typography>
            </>
          )}
          <Tooltip
            target={
              <Typography color={colors.util.navy[100]} variant="text12">
                {source}
              </Typography>
            }
            wrapperStyles={{
              display: 'flex',
              flex: '1 0 0',
              minWidth: 'fit-content',
            }}
          >
            Intel Source
          </Tooltip>
          <Typography color={colors.util.navy[100]} variant="text12">
            &#x2022;
          </Typography>
          <Tooltip
            target={
              <Typography
                color={confidenceColor(confidenceLevel)}
                variant="text12"
              >
                {confidenceText(confidenceLevel)}
              </Typography>
            }
            wrapperStyles={{
              display: 'flex',
              flex: '1 0 0',
              minWidth: 'fit-content',
            }}
          >
            This is the level of confidence we have in the quality of the
            information based on the sources reporting and our own analysis
          </Tooltip>
        </div>
      </div>
    </div>
  )
}

export default FullReportHeader

const confidenceColor = (confidenceLevel) => {
  if (confidenceLevel === ReportConfidenceLevel.LOW) {
    return colors.util.navy[100]
  }
  if (confidenceLevel === ReportConfidenceLevel.MEDIUM) {
    return colors.util.navy[100]
  }
  if (confidenceLevel === ReportConfidenceLevel.HIGH) {
    return colors.util.green[100]
  }
  return colors.util.navy[100]
}

const confidenceText = (confidenceLevel) => {
  if (confidenceLevel === ReportConfidenceLevel.LOW) {
    return 'Low confidence'
  }
  if (confidenceLevel === ReportConfidenceLevel.MEDIUM) {
    return 'Medium confidence'
  }
  if (confidenceLevel === ReportConfidenceLevel.HIGH) {
    return 'High confidence'
  }
  return 'Unknown confidence'
}
