import { format, formatDistanceToNowStrict, parseISO } from 'date-fns'

import {
  Ticket,
  FormatTicketLibraryTableProps,
  FilterOptions,
} from '../../../models'
import {
  TicketFilterInput,
  TicketPriorityEnum,
  TicketStateEnum,
  TicketTypeEnum,
} from '../../../models/Tickets'
import { colors } from '../../../design-system/theme'
import { IconVariant } from '../../../design-system'

export const formatUTCToLocalTime = (utcDateString: string) => {
  const formattedUtcString = utcDateString.replace(' ', 'T') + 'Z'
  const utcDate = parseISO(formattedUtcString)
  const localDate = new Date(utcDate.toLocaleString())
  return localDate
}

export const formatData = (
  tableData: Ticket[],
): FormatTicketLibraryTableProps[] => {
  if (!tableData) {
    return []
  }

  return tableData.map(
    ({
      sysId,
      state,
      type,
      shortDescription,
      priority,
      ticketNumber,
      createdDate,
      sysUpdatedOn,
    }) => {
      return {
        id: sysId,
        shortDescription: {
          ticketNumber,
          text: shortDescription,
        },
        priority: formatPriority(priority),
        state: state,
        type: type,
        createdDate: {
          sortValue: format(parseISO(createdDate), 'yyyy-MM-dd'),
          displayValue: formatDistanceToNowStrict(parseISO(createdDate)),
        },
        sysUpdatedOn: {
          sortValue: format(formatUTCToLocalTime(sysUpdatedOn), 'yyyy-MM-dd'),
          displayValue: formatDistanceToNowStrict(
            formatUTCToLocalTime(sysUpdatedOn),
            {
              addSuffix: true,
            },
          ),
        },
      }
    },
  )
}

const formatPriority = (priority: string): Record<string, string> => {
  let priorityIcon: IconVariant
  let priorityColor: string
  let priorityOrder: string
  switch (priority) {
    case 'Critical':
      priorityIcon = 'skull' as IconVariant
      priorityColor = colors.util.two.main
      priorityOrder = '4'
      break
    case 'High':
      priorityIcon = 'alertCircle' as IconVariant
      priorityColor = colors.util.orange[200]
      priorityOrder = '3'
      break
    case 'Moderate':
      priorityIcon = 'disc' as IconVariant
      priorityColor = colors.util.four.dark
      priorityOrder = '2'
      break
    case 'Low':
      priorityIcon = 'arrowDownCircleSharp' as IconVariant
      priorityColor = colors.brand.secondary.light
      priorityOrder = '1'
      break
    case 'Informational':
      priorityIcon = 'informationCircleSharp' as IconVariant
      priorityColor = colors.brand.secondary.lighter
      priorityOrder = '0'
      break
    default:
      priorityIcon = 'skull' as IconVariant
      priorityColor = colors.util.two.main
      priorityOrder = '4'
      break
  }

  return {
    text: priority,
    icon: priorityIcon,
    color: priorityColor,
    order: priorityOrder,
  }
}

export const formatDateAndTime = (date: string) => {
  const newDate = formatUTCToLocalTime(date)
  return (
    date &&
    `${format(newDate, 'MMM')}, ${format(newDate, 'd')} ${format(
      newDate,
      'yyyy',
    )} ${format(newDate, 'p')}`
  )
}

export const calculateLastUpdatedDate = (date: string): string => {
  const newDate = formatUTCToLocalTime(date)
  return (
    date &&
    `${format(newDate, 'MMM')} ${format(newDate, 'd')}, ${format(
      newDate,
      'yyyy',
    )}
      (${formatDistanceToNowStrict(newDate, {
        addSuffix: true,
      })})`
  )
}

export const formatCreatedDate = (date: string): string => {
  return (
    date &&
    `${format(parseISO(date), 'MMM')} ${format(parseISO(date), 'd')}, ${format(
      parseISO(date),
      'yyyy',
    )}`
  )
}

export const ticketsLabel = (
  tickets: number,
  allTicketsIncluded: boolean,
): string => {
  if (allTicketsIncluded) {
    return `${tickets} tickets`
  } else {
    return `${tickets} Open tickets`
  }
}

export const includesResolvedClosedOrCancelledTickets = (
  selectedStateFilters,
) => {
  return !selectedStateFilters.some((ticket) => {
    if (
      ticket === TicketStateEnum['Resolved'] ||
      ticket === TicketStateEnum['Closed'] ||
      ticket === TicketStateEnum['Cancelled']
    ) {
      return true
    }
  })
}

export const initialFilterInputs: FilterOptions<TicketFilterInput> = {
  keywordSearch: '',
  showOpenTicketOnly: true,
  filters: {
    priority: [],
    state: [],
    type: [],
    createdDate: [],
    sysUpdatedOn: [],
  },
}

export const emptyTicket: Ticket = {
  ticketNumber: '',
  sysId: '',
  priority: TicketPriorityEnum.Informational,
  state: TicketStateEnum.Cancelled,
  type: TicketTypeEnum.Engineering,
  sysUpdatedOn: '',
  lastUpdateUser: '',
  createdDate: '',
  shortDescription: '',
  openedBy: '',
  assignedUser: '',
  assignedToDeepwatch: false,
  assignmentGroup: '',
  serviceOffering: '',
  customerValidateState: '',
  category: '',
  subcategory: '',
  useCaseTitle: '',
  useCase: '',
  incidentType: '',
  cisControl: '',
  mitreTactics: '',
  mitreTechniques: '',
  splunkLink: '',
  splunkSearch: '',
  servicenowLink: '',
  comments: [],
  caseType: '',
  channel: '',
  customerAcknowledgeTime: null,
  customerAssignmentTime: null,
  impact: '',
  urgency: '',
  description: '',
  searchName: '',
  customerContact: '',
  resolutionNotes: null,
  resolutionBy: null,
  resolutionAt: null,
  resolutionCode: null,
  customerValidationTime: null,
  timeToCloseSec: null,
  timeToCustomerAcknowledgeSec: null,
  timeToCustomerAssignSec: null,
  timeToCustomerValidateSec: null,
  timeToDetectionSec: null,
  timeToDeclaredIncidentSec: null,
  timeToDetectResolveAlertSec: null,
  timeToInvestigateSec: null,
  timeToNotifySec: null,
  timeToRespondSec: null,
  timeToResolveTicketSec: null,
  timeToUpdateSec: null,
  totalTimeDeepwatchWaitCustomerSec: null,
  totalTimeDeepwatchWaitVendorSec: null,
  relatedCases: [],
  sysCreatedOn: '',
}

const TicketLibraryTableHeaders = [
  'id',
  'ticketNumber',
  'priority',
  'state',
  'type',
  'createdDate',
  'sysUpdatedOn',
]

export const getTicketLibraryTableHeaderIndex = (
  headerField: string,
): number => {
  return TicketLibraryTableHeaders.indexOf(headerField)
}
