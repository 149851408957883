import { gql } from '@apollo/client'

export const CREATE_JIRA_ISSUE = gql`
  mutation CreateJiraIssue($summary: String!, $description: String!) {
    createJiraIssue(
      input: { jiraIssue: { summary: $summary, description: $description } }
    ) {
      id
      key
      self
      transition {
        errorCollection {
          errorMessages
        }
        status
      }
    }
  }
`
