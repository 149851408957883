import { gql } from '@apollo/client'

export const BAN_METRIC_FRAGMENT = gql`
  fragment BanIndexDeltaFields on SecurityIndexBansMetrics {
    index
    delta
  }
`
export const TREND_FRAGMENT = gql`
  fragment TrendFields on Trend {
    index
    metricDate
  }
`
