import { Message } from '../../../models/Messages'
import { ActionTypes, AppAction } from '../Types'

export const addToastMessage = (toastMessage: Message): AppAction => ({
  type: ActionTypes.ADD_TOAST_MESSAGE,
  toastMessage,
})

export const deleteToastMessage = (id: string): AppAction => ({
  type: ActionTypes.DELETE_TOAST_MESSAGE,
  id: id,
})
