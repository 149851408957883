import { MonthPickerProps } from '../../interfaces'
import DateButton from './DateButton'

const MonthSelection: React.FC<
  {
    date: Date
    monthIndex: number
    monthLabel: string
  } & Pick<MonthPickerProps, 'isMonthDisabled'>
> = ({ date, monthIndex, monthLabel, isMonthDisabled }) => {
  const monthDateObj = new Date(date.getFullYear(), monthIndex, 0)
  const isActive = date.getMonth() === monthIndex
  const isDisabled = isMonthDisabled(monthDateObj)

  return (
    <DateButton
      isDisabled={isDisabled}
      isActive={isActive}
      label={monthLabel}
    />
  )
}

export default MonthSelection
