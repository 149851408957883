/* eslint-disable security/detect-object-injection */
import React, { useContext } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { format } from 'date-fns'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { SideSheet } from '../../../../SideSheet'
import {
  Button,
  IconVariant,
  Typography,
  colors,
} from '../../../../../design-system'
import { TagNew } from '../../../../../design-system/components/TagNew'
import {
  ReportConfidenceLevel,
  ThreatIntelReport,
} from '../../../../../models/ThreatIntel'
import { ReportConfidenceLevelLabel } from '../../../ThreatIntel.utils'
import { Icon, List, Table } from '../../../../../design-system/components'
import { navigateUserType } from '../../../../../utils'
import { Context as AppContext } from '../../../../App'
import {
  RELATED_TICKETS_TABLE_HEADERS,
  associatedDetectionStatusIcons,
  getRelatedTicketsTableData,
  getSideSheetHeaderText,
} from './ThreatIntelSideSheet.utils'

import './ThreatIntelSideSheet.scss'

interface ThreatIntelSideSheetProps {
  isOpen: boolean
  closeSideSheet: () => void
  threatIntelReport: ThreatIntelReport | null
}

const ThreatIntelSideSheet = ({
  isOpen,
  closeSideSheet,
  threatIntelReport,
}: ThreatIntelSideSheetProps) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const navigate = useNavigate()

  const {
    state: {
      user: { isDWEmployee },
      dwExpertsCustomer: { customerShortName },
    },
  } = useContext(AppContext)

  const selectedLabels = searchParams.getAll('label')

  const onLabelClick = (label: string) =>
    setSearchParams((prevParams) => {
      if (!prevParams.has('label', label)) {
        prevParams.append('label', label)
      }

      return prevParams
    })

  const { featureEditTicketPage } = useFlags()

  const handleOpenReport = () => {
    if (!threatIntelReport) {
      return
    }

    navigateUserType(
      isDWEmployee,
      `/threat-intel/${threatIntelReport.id}`,
      navigate,
      customerShortName,
    )
  }

  const subHeaders = threatIntelReport
    ? [
        format(new Date(threatIntelReport.createdDate), 'MMM d, yyyy'),
        threatIntelReport.source,
        ReportConfidenceLevelLabel[
          threatIntelReport.confidenceLevel as ReportConfidenceLevel
        ],
      ]
    : []

  const handleRelatedTicketClick = (ticketId: string) =>
    navigateUserType(
      isDWEmployee,
      `/tickets/${ticketId}/edit`,
      navigate,
      customerShortName,
    )

  return (
    <SideSheet
      open={isOpen}
      closeSideSheet={closeSideSheet}
      footer={
        <Button buttonStyles={{ margin: '16px' }} onClick={handleOpenReport}>
          Read full report
        </Button>
      }
    >
      <div
        id="threat-intel-sidesheet"
        data-testid="threat-intel-sidesheet-test-id"
      >
        <div className="header">
          <div className="header-content">
            {getSideSheetHeaderText(threatIntelReport?.type)}
            <Button
              variant="navigational-icon"
              iconProps={{ variant: 'closeOutline' }}
              onClick={closeSideSheet}
            />
          </div>
        </div>
        <div className="sidesheet-content">
          <Typography color={colors.util.one.lighter} variant="text10semibold">
            {threatIntelReport?.title}
          </Typography>
          <div className="subheader-content">
            {subHeaders.map((subHeader, i) => (
              <React.Fragment key={`${subHeader}${i}`}>
                {i !== 0 && (
                  <Typography
                    styles={{
                      margin: '0 10px',
                    }}
                  >
                    &bull;
                  </Typography>
                )}
                <Typography
                  variant="text12"
                  withEllipsis={i === 1}
                  styles={{
                    maxWidth: '125px',
                  }}
                >
                  {subHeader}
                </Typography>
              </React.Fragment>
            ))}
          </div>
          <div className="labels-content">
            {threatIntelReport?.labels?.map((label, i) => (
              <TagNew
                key={`${label}${i}`}
                text={label.toUpperCase()}
                interactive
                severity="none"
                onClickCallback={() => onLabelClick(label)}
                highlighted={selectedLabels.includes(label)}
              />
            ))}
          </div>
          <Typography
            variant="text11"
            styles={{ padding: '24px 0', lineHeight: '21px' }}
          >
            {threatIntelReport?.overview}
          </Typography>
          {/* TODO: adjust this conditional in SON-3071 when BOLO is available */}
          {threatIntelReport?.relatedTickets.length && (
            <>
              <Typography
                variant="text10semibold"
                styles={{ padding: '16px 0', lineHeight: '19.2px' }}
              >{`${threatIntelReport.relatedTickets.length} related tickets`}</Typography>
              <Table
                data={getRelatedTicketsTableData(
                  threatIntelReport.relatedTickets,
                  handleRelatedTicketClick,
                  featureEditTicketPage,
                )}
                customHeaders={RELATED_TICKETS_TABLE_HEADERS}
              />
            </>
          )}
          {threatIntelReport?.associatedDetections.length && (
            <div data-testid="associated-detections-details">
              <Typography
                variant="text10semibold"
                styles={{
                  paddingTop: '24px',
                  paddingBottom: '4px',
                  lineHeight: '19.2px',
                }}
              >
                Associated detections
              </Typography>
              <Typography variant="text11" styles={{ lineHeight: '21px' }}>
                These detections will alert on the threat outlined in this
                report. Each detection shows the number of techniques it helps
                cover.
              </Typography>
              <div className="associated-detections-key">
                {Object.values(associatedDetectionStatusIcons).map(
                  (statusIcon) => (
                    <div
                      className="associated-detction-status"
                      key={statusIcon.label}
                    >
                      <Icon
                        variant={statusIcon.iconVariant as IconVariant}
                        size={14}
                        color={statusIcon.iconColor}
                        style={{ paddingRight: '4px' }}
                      />
                      <Typography variant="text12">
                        {statusIcon.label}
                      </Typography>
                    </div>
                  ),
                )}
              </div>
              <List
                options={threatIntelReport.associatedDetections.map(
                  (detection) => {
                    return {
                      id: detection.title,
                      leftIcon:
                        associatedDetectionStatusIcons[detection.status]
                          .iconVariant,
                      name: detection.title,
                      rightText: detection.mitreTechniques.length,
                      iconColor:
                        associatedDetectionStatusIcons[detection.status]
                          .iconColor,
                      leftIconSize: 24,
                      disableHover: true,
                    }
                  },
                )}
              />
            </div>
          )}
        </div>
      </div>
    </SideSheet>
  )
}

export default ThreatIntelSideSheet
