import { Dispatch, ReactNode, useContext, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useLDClient } from 'launchdarkly-react-client-sdk'
import { useLazyQuery } from '@apollo/client'

import { SessionStatus } from '../../config/OktaAuthConfig'
import { renderErrorToast } from '../../utils'
import {
  Context,
  setCustomer,
  setCustomerLoading,
  setCustomerNames,
  setHomePath,
  setTermsAccepted,
} from '../App'
import { AppAction, AppState, Paths, Terms } from '../App/Types'
import {
  GET_ACCEPTED_TERMS,
  GetAcceptedTermsData,
  GetAcceptedTermsVariables,
} from '../../graphql/queries/user'
import {
  GET_CUSTOMER_INFO_WITH_MATURITY_START_DATE,
  GetCustomerInfoData,
  GetCustomerInfoVariables,
} from '../../graphql/queries/customer'

export const tosVersion = '2022-10-18'

export const handleSession = async ({
  email,
  customerShortName,
  dispatch,
  getAcceptedTerms,
  getCustomerInfo,
  isDWEmployee,
  ldClient,
  shortName,
  termsAccepted,
  oktaSession,
}: {
  email: string
  isDWEmployee: boolean | undefined
  termsAccepted: Terms
  shortName: string | null
  customerShortName: string
  dispatch: Dispatch<AppAction>
  ldClient: import('launchdarkly-js-client-sdk').LDClient | undefined
  getAcceptedTerms(args: { variables: GetAcceptedTermsVariables }): Promise<{
    data: GetAcceptedTermsData | undefined
  }>
  getCustomerInfo(args: { variables: GetCustomerInfoVariables }): Promise<{
    data: GetCustomerInfoData | undefined
  }>
  oktaSession: AppState['oktaSession']
}) => {
  /**
   * I took the approach of wrapping the queries in try/catch instead of using
   * onError and onCompleted so that the promises could be properly awaited since
   * some of the promises depend on others
   */

  // If the user is not logged in then don't do anything
  if (oktaSession === SessionStatus.INACTIVE || isDWEmployee === undefined) {
    return
  }

  if (isDWEmployee) {
    // If user is a DW employee and the customer name is set we fetch the customer data
    const selectedCustomer = customerShortName || shortName

    if (selectedCustomer) {
      dispatch(setCustomerLoading(true))

      try {
        const { data: customerData } = await getCustomerInfo({
          variables: {
            selectedCustomer,
          },
        })

        if (!customerData?.getCustomerInfo) {
          throw new Error('Failed to get customer data')
        }

        dispatch(setCustomerNames(customerData.getCustomerInfo))
      } catch (error) {
        renderErrorToast(error, dispatch)
      }

      dispatch(setTermsAccepted(Terms.ACCEPTED)) // Bypass the acceptance of terms as this is not applicable to internal users
      dispatch(setHomePath(Paths.DEEPWATCH_EXPERTS))
    }
  } else if (!isDWEmployee && termsAccepted === Terms.LOADING) {
    // If the user is not a DW employee and the terms are loading we fetch the terms status and customer data
    dispatch(setCustomerLoading(true))

    try {
      const { data: termsData } = await getAcceptedTerms({
        variables: {
          acceptedVersion: tosVersion,
        },
      })

      if (termsData?.getAcceptedTerms) {
        dispatch(setTermsAccepted(Terms.ACCEPTED))
        dispatch(setHomePath(Paths.DASHBOARD))
      } else {
        dispatch(setTermsAccepted(Terms.DECLINED))
        dispatch(setHomePath(Paths.TERMS))
      }
    } catch (error) {
      dispatch(setHomePath(Paths.TERMS))
      renderErrorToast(error, dispatch)
      dispatch(setCustomerLoading(false))

      return
    }

    try {
      const { data: customerData } = await getCustomerInfo({
        variables: {
          selectedCustomer: null,
        },
      })

      if (!customerData?.getCustomerInfo) {
        throw new Error('Failed to get customer data')
      }

      dispatch(setCustomer(customerData.getCustomerInfo))
    } catch (error) {
      renderErrorToast(error, dispatch)
    }
  }

  dispatch(setCustomerLoading(false))

  ldClient?.identify({ key: email, email })
  pendo.updateOptions({
    visitor: { id: email },
  })
}

const UserInit = ({ children }: { children: ReactNode }) => {
  const {
    dispatch,
    state: {
      termsAccepted,
      oktaSession,
      dwExpertsCustomer: { customerShortName },
      user: { isDWEmployee, email },
    },
  } = useContext(Context)
  const ldClient = useLDClient()
  const [searchParams] = useSearchParams()
  const shortName = searchParams.get('customer')

  const [getAcceptedTerms] = useLazyQuery<
    GetAcceptedTermsData,
    GetAcceptedTermsVariables
  >(GET_ACCEPTED_TERMS)

  const [getCustomerInfo] = useLazyQuery<
    GetCustomerInfoData,
    GetCustomerInfoVariables
  >(GET_CUSTOMER_INFO_WITH_MATURITY_START_DATE)

  useEffect(() => {
    handleSession({
      getCustomerInfo,
      getAcceptedTerms,
      oktaSession,
      termsAccepted,
      customerShortName,
      email,
      ldClient,
      dispatch,
      isDWEmployee,
      shortName,
    })
  }, [
    getCustomerInfo,
    getAcceptedTerms,
    oktaSession,
    isDWEmployee,
    termsAccepted,
    shortName,
    customerShortName,
    dispatch,
    email,
    ldClient,
  ])

  return <>{children}</>
}

export default UserInit
