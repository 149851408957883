import { ProgressBar, Typography, colors } from '../../../design-system'
import {
  DetectionCoverageOverviewData,
  SplunkUtilization as SplunkUtilizationData,
} from '../../../models/DetectionCoverage'

type SplunkUtilizationProps = {
  detectionCoverageOverview: DetectionCoverageOverviewData
}

export const maxSplunkUsage = (splunkUsage: SplunkUtilizationData[]) => {
  return splunkUsage.length
    ? Math.max(...splunkUsage.map((usage) => usage.totalGb))
    : 0
}

const SplunkUtilization = ({
  detectionCoverageOverview,
}: SplunkUtilizationProps) => {
  const latestSplunkUtilization =
    detectionCoverageOverview?.splunkUtilization.splunkUsage.at(
      detectionCoverageOverview.splunkUtilization.splunkUsage.length - 1,
    )

  const currentSplunkUsage = latestSplunkUtilization
    ? latestSplunkUtilization.totalGb
    : 0
  return (
    <div className="flex-container column gap-24" style={{ margin: '8px 0' }}>
      <div className="flex-container space-between">
        <div className="flex-container column gap-24 flex-grow-0">
          <div className="flex-container column gap-4 flex-grow-0">
            <Typography
              component="span"
              variant="text11"
              color={colors.util.navy[100]}
            >
              License
            </Typography>
            <Typography
              component="span"
              variant="text11"
              color={colors.util.navy[50]}
            >
              {`${detectionCoverageOverview?.splunkUtilization.license} GB`}
            </Typography>
          </div>
          <div className="flex-container column gap-4 flex-grow-0">
            <Typography
              component="span"
              variant="text11"
              color={colors.util.navy[100]}
            >
              Peak usage
            </Typography>
            <Typography
              component="span"
              variant="text11"
              color={colors.util.navy[50]}
            >
              {`${
                !detectionCoverageOverview
                  ? 0
                  : maxSplunkUsage(
                      detectionCoverageOverview.splunkUtilization.splunkUsage,
                    )
              } GB`}
            </Typography>
          </div>
          <div className="flex-container column gap-4 flex-grow-0">
            <Typography
              component="span"
              variant="text11"
              color={colors.util.navy[100]}
            >
              Current usage
            </Typography>
            <Typography
              component="span"
              variant="text11"
              color={colors.util.navy[50]}
            >
              {`${currentSplunkUsage} GB`}
            </Typography>
          </div>
        </div>
        <div className="flex-container column gap-24 flex-grow-0">
          <div className="flex-container column gap-4 flex-grow-0">
            <Typography
              component="span"
              variant="text11"
              color={colors.util.navy[100]}
            >
              # of overages
            </Typography>
            <Typography
              component="span"
              variant="text11"
              color={colors.util.navy[50]}
            >
              {detectionCoverageOverview?.splunkUtilization.splunkUsage.reduce(
                (acc, usage) =>
                  usage.totalGb > usage.totalLicenseGb ? acc + 1 : acc,
                0,
              )}
            </Typography>
          </div>
          <div className="flex-container column gap-4 flex-grow-0">
            <Typography
              component="span"
              variant="text11"
              color={colors.util.navy[100]}
            >
              Largest log source
            </Typography>
            <Typography
              component="span"
              variant="text11"
              color={colors.util.navy[50]}
            >
              {detectionCoverageOverview?.splunkUtilization.largestLogSource}
            </Typography>
          </div>
          <div className="flex-container column gap-4 flex-grow-0">
            <Typography
              component="span"
              variant="text11"
              color={colors.util.navy[100]}
            >
              License delta
            </Typography>
            <Typography
              component="span"
              variant="text11"
              color={colors.util.navy[50]}
            >
              {!detectionCoverageOverview
                ? ''
                : `${(
                    detectionCoverageOverview.splunkUtilization.license -
                    currentSplunkUsage
                  ).toFixed(2)} GB`}
            </Typography>
          </div>
        </div>
      </div>
      <div className="flex-container column" style={{ gap: 19 }}>
        <div className="flex-container space-between">
          <Typography
            component="span"
            variant="text10"
            color={colors.util.navy[100]}
          >
            Monthly usage breakdown
          </Typography>
          <Typography
            component="span"
            variant="text10"
            color={colors.util.navy[100]}
          >
            Date
          </Typography>
        </div>
        <div className="flex-container column" style={{ gap: 14 }}>
          {detectionCoverageOverview?.splunkUtilization.splunkUsage.map(
            (utilization, index) => {
              const progress =
                (utilization.totalGb / utilization.totalLicenseGb) * 100
              const date = new Date(`${utilization.metricDate}T00:00:00`)
              const color =
                progress >= 100 ? colors.util.two.light : colors.util.navy[100]
              return (
                <div
                  key={index}
                  className="flex-container space-between align-items-center"
                >
                  <div style={{ width: '80%' }}>
                    <ProgressBar
                      value={progress}
                      maxWidth={'100%'}
                      padding={2}
                      height={8}
                      borderRad={1}
                      breakpoints={[
                        {
                          threshold: 100,
                          color: colors.util.two.light,
                        },
                      ]}
                    />
                  </div>
                  <Typography component="span" variant="text12" color={color}>
                    {`${date.getUTCMonth() + 1}/${date.getUTCDate()}`}
                  </Typography>
                </div>
              )
            },
          )}
        </div>
      </div>
    </div>
  )
}

export default SplunkUtilization
