import {
  MitreCoverageCustomer,
  MitreCoverageTactic,
} from '../../../../models/DetectionCoverage'
import TacticDetail from './TacticDetail'
import TechniqueDetail from './TechniqueDetail'
import SubTechniqueDetail from './SubTechniqueDetail'
import { Typography } from '../../../../design-system'

import './MitreSideSheet.scss'

interface MitreSideSheetContentProps {
  tactic?: MitreCoverageTactic
  customerTacticTechniques: MitreCoverageCustomer[]
  techniqueIndex?: number
  subTechniqueIndex?: number
}

const MitreSideSheetContent = ({
  customerTacticTechniques,
  tactic,
  techniqueIndex,
  subTechniqueIndex,
}: MitreSideSheetContentProps) => {
  if (!tactic) {
    return (
      <div id="mitre-side-sheet">
        <div className="mitre-title-description">
          <Typography component="div" styles={{ wordBreak: 'break-word' }}>
            Unable to fetch content. Please try another technique.
          </Typography>
        </div>
      </div>
    )
  }

  let detailComponent: JSX.Element | null = null

  if (subTechniqueIndex !== undefined && techniqueIndex !== undefined) {
    // eslint-disable-next-line security/detect-object-injection
    const technique = tactic.techniques[techniqueIndex]
    const subTechnique =
      // eslint-disable-next-line security/detect-object-injection
      technique.subtechniques[subTechniqueIndex]

    detailComponent = (
      <SubTechniqueDetail
        customerTacticTechniques={customerTacticTechniques}
        tacticName={tactic.mitreTacticName}
        technique={technique}
        subTechnique={subTechnique}
      />
    )
  } else if (techniqueIndex !== undefined) {
    // eslint-disable-next-line security/detect-object-injection
    const technique = tactic.techniques[techniqueIndex]

    detailComponent = (
      <TechniqueDetail
        tacticName={tactic.mitreTacticName}
        technique={technique}
        customerTacticTechniques={customerTacticTechniques}
      />
    )
  } else {
    detailComponent = (
      <TacticDetail
        customerTacticTechniques={customerTacticTechniques}
        tactic={tactic}
      />
    )
  }

  return <div id="mitre-side-sheet">{detailComponent}</div>
}

export default MitreSideSheetContent
