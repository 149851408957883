/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { createRef, CSSProperties, useState } from 'react'

import { IconVariant, ListItemSelectType, ListItemType } from '../../interfaces'
import { colors, Icon, Menu, useTheme } from '../..'
import { stateColors } from '../../theme'
import { noop } from '../../utils'

export interface SelectProps {
  label?: string
  border?: boolean
  icon?: IconVariant
  placeholder: string
  options: ListItemSelectType[]
  selected?: ListItemSelectType
  onChange?: (option: ListItemType) => void
  disabled?: boolean
  error?: boolean
  zIndex?: number
  labelStyleProps?: CSSProperties
  isEditable?: boolean
}

const Select = ({
  label,
  border = true,
  icon,
  placeholder,
  options,
  selected,
  onChange,
  disabled = false,
  error = false,
  zIndex = 1,
  labelStyleProps,
  isEditable = true,
}: SelectProps): JSX.Element => {
  const [open, setOpen] = useState<boolean>(false)
  const [selectedOption, setSelectedOption] = useState<
    ListItemType | undefined
  >(selected ?? undefined)

  const inputRef = createRef<HTMLDivElement>()

  const isDarkTheme = useTheme('dark')

  const getInputWrapperOutline = (
    open: boolean,
    disabled: boolean | undefined,
    error: boolean | undefined,
  ) => {
    if (error) {
      return isDarkTheme
        ? `1.5px solid ${colors.util.two.light}`
        : `1.5px solid ${stateColors.error.main}`
    }
    if (disabled) {
      return isDarkTheme ? 'none' : `1px solid ${colors.neutral[100]}`
    }
    if (open) {
      return isDarkTheme
        ? `1.5px solid ${colors.util.navy[50]}`
        : `1.5px solid ${colors.neutral[400]}`
    }
    return isDarkTheme
      ? `1.5px solid ${colors.util.navy[100]}`
      : `1px solid ${colors.neutral[300]}`
  }

  const inputWrapper = (
    open: boolean,
    border: boolean,
    disabled: boolean,
    error: boolean,
  ): CSSProperties => ({
    display: 'flex',
    gap: 10.25,
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: disabled ? 'default' : 'pointer',
    borderRadius: 5,
    outline: border ? getInputWrapperOutline(open, disabled, error) : 0,
    padding: isEditable ? '8px 11px' : '0px',
    width: '100%',
  })

  const inputContainer = (): CSSProperties => ({
    display: 'flex',
    width: '100%',
    fontWeight: 400,
    fontFamily: 'Inter',
    fontSize: 16,
    lineHeight: 1.6,
    color: isDarkTheme ? colors.util.navy[100] : colors.neutral[300],
    background: 'none',
    outline: 'none',
    border: 'none',
    caretColor: 'transparent',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  })

  const getLabelColor = (open: boolean, error: boolean) => {
    if (error) {
      return isDarkTheme ? colors.util.two.light : stateColors.error.main
    }
    if (open) {
      return isDarkTheme ? colors.util.navy[50] : colors.neutral[400]
    }
    return isDarkTheme ? colors.util.navy[100] : colors.neutral[300]
  }

  const labelStyles = (open: boolean, error: boolean): React.CSSProperties => ({
    width: '100%',
    fontFamily: 'Inter',
    fontWeight: 600,
    lineHeight: 1.4,
    color: getLabelColor(open, error),
    ...labelStyleProps,
  })

  const handleSelectedOption = (option: ListItemType): void => {
    setSelectedOption(option)
    closeSelectMenu()
    if (typeof onChange !== 'undefined') {
      onChange(option)
    }
  }

  const toggleSelectMenu = (): void => {
    open ? closeSelectMenu() : openSelectMenu()
  }

  const openSelectMenu = (): void => {
    inputRef.current?.focus()
    setOpen(true)
  }

  const closeSelectMenu = (): void => {
    setOpen(false)
  }

  if (selected && !options.includes(selected)) {
    throw Error(
      'If provided, selected must be equal to an element in the options array.',
    )
  }

  return (
    <div ref={inputRef} style={selectContainer}>
      <div className="select-wrapper" style={selectWrapper}>
        {label && <label style={labelStyles(open, error)}>{label}</label>}
        <div
          style={inputWrapper(open, border, disabled, error)}
          onClick={disabled ? noop : toggleSelectMenu}
          role={'list'}
          className="select-list-input-icon"
        >
          {icon && <Icon variant={icon} size={24} />}
          <input
            value={selectedOption ? selectedOption.name : placeholder}
            type="text"
            // eslint-disable-next-line jsx-a11y/role-has-required-aria-props
            role="combobox"
            style={inputContainer()}
            readOnly
          />
          {isEditable && (
            <Icon
              style={{ cursor: 'pointer' }}
              variant={open ? 'chevronUp' : 'chevronDown'}
              size={24}
            />
          )}
        </div>

        {open && (
          <Menu
            variant={'select'}
            options={options}
            selected={selected}
            onSelect={handleSelectedOption}
            handleOutsideClick={closeSelectMenu}
            zIndex={zIndex}
            toggleRef={inputRef}
          />
        )}
      </div>
    </div>
  )
}

const selectContainer: CSSProperties = {
  display: 'flex',
  gap: '40px',
}

const selectWrapper: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  width: '100%',
  paddingBottom: '8px',
}

export default Select
