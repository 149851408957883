import colors from './colors'

const stateColors = {
  informational: {
    main: colors.brand.secondary.lighter,
    dark: colors.brand.secondary.dark,
  },
  success: {
    light: colors.brand.primary.lighter,
    main: colors.brand.primary.dark,
    dark: colors.brand.primary.darker,
  },
  warning: {
    main: colors.util.four.light,
    dark: colors.util.four.main,
  },
  error: {
    light: colors.util.two.lighter,
    main: colors.util.two.dark,
    dark: colors.util.two.darker,
  },
  hovered: {
    main: colors.util.one.lighter,
  },
}

export default stateColors
