import { createContext, ReactNode } from 'react'
import { ApolloError, useQuery } from '@apollo/client'

import { TicketEditEnum } from '@models/index'
import {
  GET_TICKET_EDIT_SETTINGS,
  GetTicketEditSettingsData,
  GetTicketEditSettingsVariables,
} from '@queries/ticket'

/*
User data will be added to TicketsContext in SON-2510 */

type TicketsContextModel = {
  ticketSettingsDataLoading: boolean
  fetchError: ApolloError | undefined
  priorityEnum: TicketEditEnum[]
  urgencyEnum: TicketEditEnum[]
  stateEnum: TicketEditEnum[]
  resolutionCodeEnum: TicketEditEnum[]
  customerValidationEnum: TicketEditEnum[]
  isEditable: boolean | undefined
}

export const TicketsContext = createContext<TicketsContextModel | null>(null)

export const TicketsContextProvider = ({
  children,
  selectedCustomer,
}: {
  children: ReactNode
  selectedCustomer: string | null
}) => {
  const initialState = {
    enabled: undefined,
    enumValues: {
      priority: [],
      urgency: [],
      state: [],
      resolutionCode: [],
      customerValidationState: [],
    },
  }

  const {
    data: { getTicketEditSettings: ticketEditSettingsData } = {
      getTicketEditSettings: initialState,
    },
    loading: ticketSettingsDataLoading,
    error: fetchError,
  } = useQuery<GetTicketEditSettingsData, GetTicketEditSettingsVariables>(
    GET_TICKET_EDIT_SETTINGS,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        selectedCustomer,
      },
    },
  )

  return (
    <TicketsContext.Provider
      value={{
        ticketSettingsDataLoading:
          (ticketSettingsDataLoading && !ticketEditSettingsData.enabled) ||
          false,
        fetchError: fetchError,
        priorityEnum: ticketEditSettingsData.enumValues.priority,
        urgencyEnum: ticketEditSettingsData.enumValues.urgency,
        stateEnum: ticketEditSettingsData.enumValues.state,
        resolutionCodeEnum: ticketEditSettingsData.enumValues.resolutionCode,
        customerValidationEnum:
          ticketEditSettingsData.enumValues.customerValidationState,
        isEditable: ticketEditSettingsData.enabled,
      }}
    >
      {children}
    </TicketsContext.Provider>
  )
}
