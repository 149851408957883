import { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Tabs } from '../../../../../design-system'
import { ChangeHistory } from './ChangeHistory'
import { YourIndex } from './YourIndex'
import { Context } from '../../../../App'
import { Paths } from '../../../../App/Types'
import { navigateUserType } from '../../../../../utils'

import './SecurityIndexTabs.scss'

const SecurityIndexTabs: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState(0)
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const {
    state: {
      user: { isDWEmployee },
      dwExpertsCustomer: { customerShortName },
    },
  } = useContext(Context)

  const tabData = [
    {
      id: 0,
      text: 'Your Index',
      active: true,
      notification: false,
    },
    {
      id: 1,
      text: 'Change history',
      active: false,
      notification: false,
    },
  ]

  const SecurityIndexTabsContent: JSX.Element[] = [
    <YourIndex key={0} />,
    <ChangeHistory key={0} />,
  ]

  useEffect(() => {
    if (pathname.includes('your-index')) {
      setSelectedTab(0)
    } else if (pathname.includes('change-history')) {
      setSelectedTab(1)
    }
  }, [pathname])

  return (
    <div
      data-testid="security-index-tabs"
      className="flex"
      id="security-index-tabs"
    >
      <Tabs
        data={tabData}
        tabStyles={{ paddingLeft: '11px' }}
        onTabClick={(id) => {
          setSelectedTab(id)
          if (id === 0) {
            navigateUserType(
              isDWEmployee,
              `${Paths.SECURITY_INDEX}${Paths.YOUR_INDEX}`,
              navigate,
              customerShortName,
            )
          } else if (id === 1) {
            navigateUserType(
              isDWEmployee,
              `${Paths.SECURITY_INDEX}${Paths.CHANGE_HISTORY}`,
              navigate,
              customerShortName,
            )
          }
        }}
        activeTab={selectedTab}
      />
      {SecurityIndexTabsContent.at(selectedTab)}
    </div>
  )
}
export default SecurityIndexTabs
