import { useContext } from 'react'

import { ModalContext } from '../components/common/Modal'

const useModalContext = () => {
  const modalContext = useContext(ModalContext)

  if (!modalContext) {
    throw new Error(
      'modalContext has to be used within <ModalContext.Provider />',
    )
  }

  return modalContext
}

export default useModalContext
