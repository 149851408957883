import { AppAction, AppState, ActionTypes, Terms, Paths } from './Types'
import * as reducers from './Reducers'
import { SessionStatus } from '../../config/OktaAuthConfig'

// TODO: Move state related to Deepwatch experts home and detection coverage into those components
export const initialState: AppState = {
  user: {
    id: '',
    firstName: '',
    lastName: '',
    username: '',
    email: '',
    isAdmin: false,
  },
  customer: { customerName: '', customerShortName: '' },
  oktaSession: SessionStatus.INACTIVE,
  globalLoading: true,
  customerLoading: true,
  toastMessages: [],
  dwExpertsActiveTab: 0,
  dwExpertsCustomer: {
    customerName: '',
    customerShortName: '',
  },
  termsAccepted: Terms.LOADING,
  homePath: Paths.REDIRECT,
  theme: 'dark',
}

export const reducer = (state: AppState, action: AppAction): AppState => {
  switch (action.type) {
    case ActionTypes.SET_USER:
      return reducers.setUser(state, action)
    case ActionTypes.SET_CUSTOMER:
      return reducers.setCustomer(state, action)
    case ActionTypes.SET_SESSION:
      return reducers.setSession(state, action)
    case ActionTypes.SET_GLOBAL_LOADING:
      return reducers.setGlobalLoading(state, action)
    case ActionTypes.SET_CUSTOMER_LOADING:
      return reducers.setCustomerLoading(state, action)
    case ActionTypes.ADD_TOAST_MESSAGE:
      return reducers.addToastMessage(state, action)
    case ActionTypes.DELETE_TOAST_MESSAGE:
      return reducers.deleteToastMessage(state, action)
    case ActionTypes.SET_DW_EXPERTS_ACTIVE_TAB:
      return reducers.setDwExpertsActiveTab(state, action)
    case ActionTypes.SET_DW_EXPERTS_CUSTOMER:
      return reducers.setCustomerNames(state, action)
    case ActionTypes.SET_TERMS_ACCEPTED:
      return reducers.setTermsAccepted(state, action)
    case ActionTypes.SET_HOME_PATH:
      return reducers.setHomePath(state, action)
    default:
      throw new Error(`Unknown action type ${action['type']}`)
  }
}
