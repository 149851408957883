import { Box, SxProps, Theme } from '@mui/material'

interface DashedLineRectanglesProps {
  /** The width of the entire dashed line */
  width?: number
  /** The height (thickness) of each dash */
  height?: number
  sx?: SxProps<Theme>
}

/**
 *
 * @param {DashedLineRectanglesProps} props The component props
 * @param {CSSProperties['color']} [props.primaryColor] The color of the dashed line
 * @param {number} [props.width] The width of the entire dashed line
 * @param {number} [props.height] The height (thickness) of each dash
 * @returns {import('react').ReactNode} A dashed line SVG
 */
const DashedLineRectangles: React.FC<DashedLineRectanglesProps> = ({
  sx,
  width = 24,
  height = 6,
}) => {
  const maskWidth = width / 6
  const maskWidthOffset = maskWidth / 2

  return (
    <Box
      component="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      sx={Array.isArray(sx) ? sx : [sx]}
    >
      <rect x="0" y="0" width={width} height={height} mask="url(#dash-mask)" />
      <defs>
        <mask id="dash-mask">
          <rect width={width} height={height} />
          <rect
            x={maskWidthOffset}
            y="0"
            width={maskWidth}
            height={height}
            fill="black"
          />
          <rect
            x={maskWidthOffset + maskWidth * 2}
            y="0"
            width={maskWidth}
            height={height}
            fill="black"
          />
          <rect
            x={maskWidthOffset + maskWidth * 4}
            y="0"
            width={maskWidth}
            height={height}
            fill="black"
          />
        </mask>
      </defs>
    </Box>
  )
}

export default DashedLineRectangles
