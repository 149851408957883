import { useContext, useEffect, useMemo, useState } from 'react'
import { subDays, differenceInDays } from 'date-fns'
import { useLocation, useNavigate } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { Tabs } from '../../design-system'
import Dashboard from './Dashboard/Dashboard'
import { SecurityIndex } from './SecurityIndex'
import PageBanner from '../common/PageBanner/PageBanner'
import { Context } from '../App'
import { Paths } from '../App/Types'
import { navigateUserType } from '../../utils'

import './Home.scss'

const DATE_RANGE_AMOUNT_OF_DAYS = 29

const Home = ({ activeTab = 0 }) => {
  const [selectedTab, setSelectedTab] = useState(activeTab)
  const { securityIndex } = useFlags()
  const navigate = useNavigate()
  const { search } = useLocation()
  const tab = search.split('tab=')[1]

  const tabData = [
    {
      id: 0,
      text: 'Overview',
      active: true,
      notification: false,
    },
    {
      id: 1,
      text: 'Security Index',
      active: false,
      notification: false,
    },
  ].filter(Boolean)

  const {
    state: {
      user: { firstName, isDWEmployee },
      dwExpertsCustomer,
      customer,
    },
  } = useContext(Context)
  //? made need custom hook to handle different components
  const { initialStartDate, initialEndDate, maturityScoreStartDate } = useMemo<{
    initialStartDate: Date
    initialEndDate: Date
    maturityScoreStartDate?: Date
  }>(() => {
    const maturityDateToUse =
      customer.maturityScoreStartDate ??
      dwExpertsCustomer.maturityScoreStartDate
    const maturityScoreStartDate =
      maturityDateToUse && new Date(maturityDateToUse)

    // End date should be the previous day since the data pipeline runs one day behind
    const initialEndDate = subDays(new Date(), 1)

    if (!maturityScoreStartDate) {
      return {
        initialStartDate: subDays(initialEndDate, DATE_RANGE_AMOUNT_OF_DAYS),
        initialEndDate,
      }
    }

    const timeBetweenNowAndStart = Math.abs(
      differenceInDays(maturityScoreStartDate, initialEndDate),
    )

    // We check if the maturity start date is less than DATE_RANGE_AMOUNT_OF_DAYS days from now, if so we adjust the initial start date
    if (timeBetweenNowAndStart < DATE_RANGE_AMOUNT_OF_DAYS) {
      return {
        initialStartDate: subDays(initialEndDate, timeBetweenNowAndStart),
        initialEndDate,
        maturityScoreStartDate,
      }
    }

    return {
      initialStartDate: subDays(initialEndDate, DATE_RANGE_AMOUNT_OF_DAYS),
      initialEndDate,
      maturityScoreStartDate,
    }
  }, [customer, dwExpertsCustomer])

  const [startDate, setStartDate] = useState<Date>(initialStartDate)
  const [endDate, setEndDate] = useState<Date>(initialEndDate)
  const [dateFilterableStyle, setDateFilterableStyle] = useState<boolean>(false)

  useEffect(() => {
    setStartDate(initialStartDate)
    setEndDate(initialEndDate)
  }, [initialStartDate, initialEndDate])

  useEffect(() => {
    if (tab) {
      setSelectedTab(parseInt(tab))
    }
  }, [tab])

  const handleDateChange = (newStartDate?: Date, newEndDate?: Date) => {
    setStartDate(newStartDate || initialStartDate)
    setEndDate(newEndDate || initialEndDate)
  }

  const handleCalendarClick = () => {
    if (dateFilterableStyle === false) setDateFilterableStyle(true)
    if (dateFilterableStyle === true) setDateFilterableStyle(false)
  }

  const HomeTabsContent: JSX.Element[] = [
    <Dashboard
      key={0}
      dateFilterableStyle={dateFilterableStyle}
      startDate={startDate}
      endDate={endDate}
    />,
    <SecurityIndex key={1} />,
  ]

  return (
    <article
      id="home-page"
      className="content"
      data-testid="home"
      style={securityIndex ? { paddingTop: 0 } : {}}
    >
      <div
        className={`home-page-tabs-container${
          securityIndex === false ? ' without-security-index' : ''
        }`}
      >
        {securityIndex && (
          <Tabs
            data={tabData}
            tabStyles={{ paddingLeft: '11px' }}
            activeTab={selectedTab}
            onTabClick={(id) => {
              setSelectedTab(id)
              if (id === 0) {
                navigateUserType(
                  isDWEmployee,
                  Paths.DASHBOARD,
                  navigate,
                  dwExpertsCustomer.customerShortName,
                )
              } else if (id === 1) {
                navigateUserType(
                  isDWEmployee,
                  `${Paths.SECURITY_INDEX}${Paths.YOUR_INDEX}`,
                  navigate,
                  dwExpertsCustomer.customerShortName,
                )
              }
            }}
            border={false}
          />
        )}
        <PageBanner
          userFirstName={firstName || ''}
          startDate={startDate}
          endDate={endDate}
          defaultStartDate={initialStartDate}
          defaultEndDate={initialEndDate}
          onSubmit={handleDateChange}
          maturityScoreStartDate={maturityScoreStartDate}
          iconCallback={handleCalendarClick}
          dateFilterableStyle={dateFilterableStyle}
        />
      </div>

      {HomeTabsContent.at(selectedTab)}
    </article>
  )
}

export default Home
