import { useEffect, useRef, useState } from 'react'

import { MobileTableProps } from './MobileTable.types'
import { Caption } from './Caption'
import { MobileTableCard } from './MobileTableCard'
import {
  changeNullValueToStringError,
  createCustomTableHeaders,
  sortData,
  sortWithSecondaryData,
} from './MobileTable.utils'
import { useRefOnScreen } from '../../utils'

import './MobileTable.scss'

const MobileTable = ({
  bodyColor = '',
  bodyTextColor = '',
  buttonElements = [],
  captionProps,
  customHeaders = [],
  data = [],
  loading = false,
  loadingRows = 8,
  onClick,
  onBottomReached,
  sortOptions = {
    defaultSortColumn: -1,
    sortDirection: 'DESC',
    secondarySortDirection: 'ASC',
    secondaryDefaultSortColumn: -1,
  },
}: MobileTableProps): JSX.Element => {
  // Get Table Headers from field names
  const tableHeaders = createCustomTableHeaders(
    customHeaders,
    data,
    loadingRows,
  )
  const mobileTableProps = {
    bodyTextColor,
    loading,
    loadingRows,
    buttonElements,
  }

  const {
    defaultSortColumn,
    sortDirection,
    secondarySortDirection,
    secondaryDefaultSortColumn,
  } = sortOptions
  const tableData = changeNullValueToStringError(data)
  const [sortedData, setSortData] = useState(tableData)

  // if 'onBottomReached' was passed, make observable refs to the container and the row

  const tableRowTriggerRef = useRef<HTMLTableRowElement>(null)
  useRefOnScreen(tableRowTriggerRef, onBottomReached)

  useEffect(() => {
    if (loading) {
      setSortData(skeletonLoaderData)
    } else if (data.length === 0) {
      setSortData(tableData)
    } else if (secondarySortDirection && secondaryDefaultSortColumn) {
      setSortData(
        sortWithSecondaryData(
          data,
          defaultSortColumn ?? -1,
          sortDirection ?? 'DESC',
          secondarySortDirection ?? 'ASC',
          secondaryDefaultSortColumn ?? -1,
        ),
      )
    } else {
      setSortData(
        sortData(data, defaultSortColumn ?? -1, sortDirection ?? 'DESC'),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading])

  const skeletonLoaderData = () => {
    const skeletonArray: any[] = []
    const columnCount =
      tableHeaders.length > 0 ? tableHeaders.length : loadingRows
    for (let index = 0; index < loadingRows; index++) {
      const tempObject: Record<string, any> = {}
      for (let index = 0; index < columnCount; index++) {
        tempObject[`${index}`] = (
          <div className="skeleton-block skeleton-pulsate" />
        )
      }
      skeletonArray.push(tempObject)
    }
    return skeletonArray
  }

  return (
    <>
      <table className="dw-mobile-table">
        <Caption
          caption={captionProps?.caption}
          captionButton={captionProps?.captionButton}
          captionSide={captionProps?.captionSide}
        />
      </table>
      {sortedData &&
        sortedData.length > 0 &&
        sortedData.map((rowData, index) => {
          return (
            <MobileTableCard
              key={index}
              index={index}
              rowData={rowData}
              tableHeaders={tableHeaders}
              bodyColor={bodyColor}
              mobileTableProps={mobileTableProps}
              handleOnClick={onClick}
              ref={
                sortedData.length === index + 1 ? tableRowTriggerRef : undefined
              }
            />
          )
        })}
    </>
  )
}

export default MobileTable
