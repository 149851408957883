import { gql } from '@apollo/client'

import {
  THREAT_INTEL_REPORT_DATA_FRAGMENT,
  THREAT_INTEL_REPORT_SECTION_FRAGMENT,
} from '../fragments/threatIntel'
import {
  ThreatIntelReport,
  ThreatIntelReportDetail,
} from '../../models/ThreatIntel'
import { Pagination, PaginationInput } from '../../models'

export enum ThreatIntelReportSortOptions {
  Title = 'TITLE',
  Type = 'TYPE',
  Date = 'DATE',
}

export enum SortDirection {
  Ascending = 'ASC',
  Descending = 'DESC',
}

export interface ThreatIntelReportsData {
  threatIntelReports: ThreatIntelReport[]
  pagination: Pagination
}

export interface GetThreatIntelReportsData {
  getThreatIntelReports: ThreatIntelReportsData
}

export interface GetThreatIntelReportData {
  getThreatIntelReport: ThreatIntelReportDetail
}

export interface GetThreatIntelReportsInput {
  filters?: {
    labels: string[]
  }
  keywordSearch?: string
  sortBy?: ThreatIntelReportSortOptions
  sortDirection?: SortDirection
  pagination: PaginationInput
}

export interface GetThreatIntelReportsVariables {
  selectedCustomer: string | null
  input: GetThreatIntelReportsInput
}

export interface GetThreatIntelReportVariables {
  id: string
}

export const GET_THREAT_INTEL_REPORTS_LIST_QUERY = gql`
  ${THREAT_INTEL_REPORT_DATA_FRAGMENT}
  query GetThreatIntelReports(
    $selectedCustomer: String
    $input: ThreatIntelReportsInput
  ) {
    getThreatIntelReports(selectedCustomer: $selectedCustomer, input: $input) {
      threatIntelReports {
        ...ThreatIntelReportData
      }
      pagination {
        offset
        limit
        total
      }
    }
  }
`

export const GET_THREAT_INTEL_REPORT_QUERY = gql`
  ${THREAT_INTEL_REPORT_DATA_FRAGMENT}
  ${THREAT_INTEL_REPORT_SECTION_FRAGMENT}
  query GetThreatIntelReport($reportId: String, $selectedCustomer: String) {
    getThreatIntelReport(
      reportId: $reportId
      selectedCustomer: $selectedCustomer
    ) {
      ...ThreatIntelReportData
      sections {
        ...ThreatIntelReportSection
      }
    }
  }
`
