import { Box, Typography } from '@mui/material'
import React from 'react'

import { TicketMttxResponse } from '@queries/ticket'

import { defineColumnKeys, makeMttxColumnLabels } from '../../utils'
import { MttxKey, formatMttxValue } from './MttxTab.utils'

interface MttxTabProps {
  data: TicketMttxResponse
}

const MttxTab: React.FC<MttxTabProps> = ({ data }) => {
  const mttxData = { ...data }
  delete mttxData.__typename

  const columns = defineColumnKeys(mttxData)

  /* This function accomplishes the following:
  
    1:Iterates through the expected MTTx object from the ticket data to render input fields that are default disabled. 
    2:The defineColumnKeys function is used to create as close to even number of columns. 
    3:The makeMttxColumnLabels makes appropriate label case based on the label type (MTTx or customerTime etc..)
  */

  const MttxColumn = (columnIndex: number): JSX.Element => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexBasis: '50%',
          gap: '1rem',
        }}
      >
        {Object.entries(mttxData)
          .filter((key) => columns.at(columnIndex)!.includes(key[0]))
          .map(([key, value]) => {
            return (
              <Box key={key}>
                <Typography fontWeight={600} variant="body2">
                  {makeMttxColumnLabels(key)}
                </Typography>
                <Typography
                  key={key}
                  sx={(theme) => ({
                    color: theme.vars.palette.text.primary,
                    ...theme.applyStyles('dark', {
                      color: theme.vars.palette.text.secondary,
                    }),
                  })}
                  variant="body2"
                >
                  {value ? `${formatMttxValue(key as MttxKey, value)}` : '--'}
                </Typography>
              </Box>
            )
          })}
      </Box>
    )
  }

  return (
    <Box sx={{ display: 'flex', padding: '1rem 0' }}>
      {MttxColumn(0)}
      {MttxColumn(1)}
    </Box>
  )
}

export default MttxTab
