import { Input, Select } from '../../../../../design-system'
import { AdditionalDetailsRow } from '../../Types'
import { defineColumnKeys, setPTagsAndLabels } from '../../utils'
import {
  additionalFieldsUniqueLabelNames,
  selectFieldOptions,
} from './AdditionalDetailsUtils'

export const AdditionalDetailsTopRow = (
  data: AdditionalDetailsRow,
  isEditable: boolean,
): JSX.Element => {
  return (
    <>
      {Object.entries(data.data).map(([key, value]) => {
        switch (key) {
          case 'threatHuntOutcomes':
            return (
              <Select
                data-testid="additional-select"
                key={key}
                label={'Threat Hunt outcomes'}
                // eslint-disable-next-line security/detect-object-injection
                placeholder={'--'}
                options={selectFieldOptions(key)}
                border={true}
                labelStyleProps={{ fontSize: '14px', marginBottom: '6px' }}
                disabled={!isEditable}
                isEditable={isEditable}
              />
            )

          case 'ioc':
            return (
              <Input
                key={key}
                labelId={key}
                styles={{
                  marginBottom: '12px',
                  fontSize: '14px',
                }}
                label={'IOC'}
                placeholder={'Enter description here'}
                required={true}
                value={value || '--'}
                name={key}
                type="text"
                /* eslint-disable jsx-a11y/no-autofocus */
                autoFocus={false}
                disabled={true}
                isEditable={isEditable}
              />
            )
        }
      })}
    </>
  )
}

export const AdditionalDetailsColumn = (
  data: AdditionalDetailsRow,
  colIndex: number,
  isEditable: boolean,
): JSX.Element => {
  const twoColData = { ...data.data }
  delete twoColData.ioc
  delete twoColData.threatHuntOutcomes
  delete twoColData.__typename
  const columns = defineColumnKeys(twoColData)

  return (
    <>
      {Object.entries(twoColData)
        .filter((key) => columns[colIndex].includes(key[0]))
        .map(([key, value]) => {
          switch (key) {
            case 'cisControl':
            case 'incidentType':
              return (
                <div className="additional-details-select" key={key}>
                  <Select
                    data-testid="additional-select"
                    key={key}
                    label={additionalFieldsUniqueLabelNames(key)}
                    // eslint-disable-next-line security/detect-object-injection
                    placeholder={`${value}`}
                    options={selectFieldOptions(key)}
                    border={true}
                    labelStyleProps={{ fontSize: '14px', marginBottom: '6px' }}
                    disabled={true}
                    isEditable={isEditable}
                  />
                </div>
              )
            case 'splunkLink':
            case 'documentationLink':
            case 'soarLink':
              return (
                <Input
                  key={key}
                  labelId={key}
                  styles={{
                    marginBottom: '12px',
                    fontSize: '14px',
                  }}
                  label={setPTagsAndLabels(key)}
                  placeholder={value || '--'}
                  required={true}
                  value={value || '--'}
                  name={key}
                  type="text"
                  /* eslint-disable jsx-a11y/no-autofocus */
                  autoFocus={false}
                  disabled={true}
                  isEditable={isEditable}
                />
              )

            default:
              return (
                <Input
                  key={key}
                  labelId={key}
                  styles={{
                    marginBottom: '12px',
                    fontSize: '14px',
                  }}
                  label={setPTagsAndLabels(key)}
                  placeholder={value || '--'}
                  required={true}
                  value={value || '--'}
                  name={key}
                  type="text"
                  /* eslint-disable jsx-a11y/no-autofocus */
                  autoFocus={false}
                  disabled={true}
                  isEditable={isEditable}
                />
              )
          }
        })}
    </>
  )
}
