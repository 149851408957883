import { ResponsiveLine } from '@nivo/line'

import { FormattedMaturityScoreDataInterface } from './MaturityScore.models'
import { areaLayer, styledMaturityLines } from './MaturityScore.customLayers'
import { getTooltipTitle, formatDate, dedupeData } from './MaturityLineHelpers'
import ToolTip from './ToolTip'
import { Typography } from '../../../../../design-system'

interface MaturityLineProps {
  dataMaturityLength: number
  data: FormattedMaturityScoreDataInterface[]
  loading?: boolean
}

const MaturityLine = ({
  dataMaturityLength,
  data,
  loading,
}: MaturityLineProps): JSX.Element => {
  const maxValue =
    Math.max(
      ...data
        .map((item) => item.data)
        .flat()
        .map((item) => item.y),
    ) + 0.25

  const singleDate: string = formatDate(String(data[0].data[0].x))

  return (
    <div className="arealine">
      {!loading && (
        <div
          style={{
            display: 'flex',
            height: '100%',
            minHeight: 0,
            minWidth: 0,
            width: '99%',
          }}
        >
          <ResponsiveLine
            axisBottom={null}
            axisLeft={null}
            data={data}
            gridXValues={data[0].data.map(({ x }) => x)}
            margin={{ bottom: 0, left: 0, right: 0, top: 0 }}
            enablePoints={false}
            xScale={{ type: 'point' }}
            yScale={{
              type: 'linear',
              max: maxValue > 10 ? 10 : maxValue,
              min: 0,
            }}
            enableArea={true}
            enableCrosshair={false}
            enableGridX={false}
            enableGridY={false}
            enableSlices="x"
            curve={'linear'}
            isInteractive={true}
            layers={[
              'grid',
              'markers',
              'axes',
              areaLayer,
              'crosshair',
              'slices',
              'points',
              'mesh',
              'legends',
              styledMaturityLines,
            ]}
            sliceTooltip={({ slice }) => {
              const data = dedupeData(slice.points)
              return (
                <div className="tool-tip" data-testid="maturity-tool-tip">
                  <Typography
                    styles={{
                      paddingLeft: '1px',
                      color: '#EEF6F9',
                      borderBottom: '0.75px solid #455358',
                      paddingBottom: 4,
                      marginBottom: 4,
                    }}
                    size={12}
                    weight={400}
                  >
                    {dataMaturityLength === 1
                      ? singleDate
                      : formatDate(String(data[0].data.x))}
                  </Typography>
                  {data.map((point) => {
                    return (
                      <div
                        key={point.id}
                        style={{
                          color: '#A2BBC3',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'start',
                          alignItems: 'center',
                          gap: 4,
                        }}
                      >
                        <ToolTip name={point.serieId} />
                        <Typography size={12}>
                          {Number(point.data.yFormatted).toFixed(2)}{' '}
                          {getTooltipTitle(String(point.serieId))}
                        </Typography>
                      </div>
                    )
                  })}
                </div>
              )
            }}
          />
        </div>
      )}
    </div>
  )
}

export default MaturityLine
