import { Fragment } from 'react'
import { Box, Chip, Typography, useTheme } from '@mui/material'

import { ThreatIntelReportLabel } from '@models/ThreatIntel'
import {
  FilterOptions,
  FilterOptionsGenericObject,
  FilterOptionsThreatIntel,
  FilterOptionValues,
} from '@models/index'
import Icon from '@common/Icon'

import { convertKeywordSearchToArray, formatChipLabel } from '../Filters.utils'

export type FilterValue = string | ThreatIntelReportLabel

export interface FilterChipsHeaderProps<T> {
  selectedFilters: FilterOptions<T> | FilterOptionsThreatIntel<T>
  /** Optional mapping for human-readable filter value labels, with filter values as the key, and human-readable string as the value */
  filterValueLabelMap?: Record<string, string>
  deleteChip: (
    filterGroup: string,
    filterValue: string | ThreatIntelReportLabel,
  ) => void
  hasActiveFilters?: boolean
  operatorText?: string
}

const FilterChipsHeader = ({
  selectedFilters,
  filterValueLabelMap,
  deleteChip,
  hasActiveFilters = false,
  operatorText,
}: FilterChipsHeaderProps<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  FilterOptionValues | FilterOptionsGenericObject<any>
>): JSX.Element => {
  const convertedKeywordSearch = convertKeywordSearchToArray(
    selectedFilters.keywordSearch ?? '',
  )

  // flatten the object so it's easier to work with
  const combineKeywordSearchAndFilters = {
    ...selectedFilters?.filters,
    keywordSearch: convertedKeywordSearch,
  }
  const theme = useTheme()
  // TODO: refactor nested map loops: https://deepwatch.atlassian.net/browse/SON-4154
  return (
    <Box
      sx={{
        display: 'flex',
        gap: '0.5em',
        flexWrap: 'wrap',
        width: '100%',
        marginBottom: hasActiveFilters ? '2em' : '0',
      }}
      data-testid="filter-chips-header"
      id="filter-chips-header"
    >
      {Object.keys(combineKeywordSearchAndFilters).map(
        (filterGroup: string, groupIndex: number) => {
          return combineKeywordSearchAndFilters[`${filterGroup}`].map(
            (
              filterValue: string | ThreatIntelReportLabel,
              valueIndex: number,
            ) => {
              const value =
                typeof filterValue === 'string' ? filterValue : filterValue.name
              // eslint-disable-next-line security/detect-object-injection
              const displayValue = filterValueLabelMap?.[value] ?? value
              const isFirstFilter = groupIndex === 0 && valueIndex === 0

              return (
                <Fragment key={`${value}-${valueIndex}`}>
                  {operatorText && !isFirstFilter && (
                    <Typography
                      sx={(theme) => ({
                        paddingTop: '10px',
                        color: theme.vars.palette.text.primary,
                        ...theme.applyStyles('dark', {
                          color: theme.vars.palette.text.secondary,
                        }),
                      })}
                      variant="body2"
                    >
                      {operatorText}
                    </Typography>
                  )}
                  <Chip
                    sx={(theme) => ({
                      color: theme.vars.palette.text.primary,
                      ...theme.applyStyles('dark', {
                        color: theme.vars.palette.text.secondary,
                      }),
                    })}
                    data-testid="tag-new-no_text_supplied"
                    label={formatChipLabel(filterGroup, displayValue)}
                    onDelete={() => deleteChip(filterGroup, filterValue)}
                    deleteIcon={
                      <Box>
                        <Icon
                          variant="closeCircle"
                          size={20}
                          sx={{
                            color: theme.palette.secondary.dark,
                          }}
                        />
                      </Box>
                    }
                  />
                </Fragment>
              )
            },
          )
        },
      )}
    </Box>
  )
}
export default FilterChipsHeader
