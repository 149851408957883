import { ReactNode, useRef } from 'react'
import { CSSTransition } from 'react-transition-group'

import { Button } from '../../design-system'
import { handleSideSheetPosition } from '../../design-system/utils/SideSheetUtils'

import './SideSheet.scss'

export interface SideSheetProps {
  children: ReactNode
  open: boolean
  icon?: ReactNode
  title?: string
  closeSideSheet?: () => void
  footer?: ReactNode
}

const SideSheet = ({
  children,
  open,
  icon,
  title,
  closeSideSheet,
  footer,
}: SideSheetProps) => {
  const ref0 = useRef<HTMLElement>(null)

  const SideSheetComponent = () => {
    return (
      <CSSTransition
        nodeRef={ref0}
        in={open}
        timeout={500}
        classNames="appsidesheet"
        addEndListener={() => {
          if (open && ref0.current) {
            ref0.current.focus()
            handleSideSheetPosition('open')
          } else {
            handleSideSheetPosition('closed')
          }
        }}
      >
        <aside
          className="appsidesheet"
          ref={ref0}
          tabIndex={0}
          role={'contentinfo'}
        >
          {title && (
            <div className="header">
              <div className="header-content">
                <div className="title-content">
                  <div>{icon}</div>
                  <div>{title}</div>
                </div>
                <Button
                  variant="navigational-icon"
                  iconProps={{ variant: 'closeOutline' }}
                  onClick={closeSideSheet}
                />
              </div>
            </div>
          )}
          <div className={`content ${title ? 'default-style' : 'tab-style'}`}>
            {children}
          </div>
          {footer && <div className="footer">{footer}</div>}
        </aside>
      </CSSTransition>
    )
  }

  return <div>{SideSheetComponent()}</div>
}

export default SideSheet
