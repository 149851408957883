import { useContext } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useQuery } from '@apollo/client'
import { format, subDays } from 'date-fns'

import { Context } from '../../App'
import { ThreatProtectionSummary } from './charts/ThreatProtectionSummary'
import { CriticalHighOpenTickets } from './charts/CriticalHighOpenTickets'
import { TopLogSourcesByUsage } from './TopLogSourcesByUsage'
import { RecentReports } from './RecentReportsWidget'
import { MaturityScore } from './charts/MaturityScore'
import { CurrentTicketsWidget } from './CurrentTicketsWidget'
import DashboardBans from './Bans/DashboardBans'
import MyLinksWidget from './MyLinksWidget/MyLinksWidget'
import { shapeMyLinksData } from './MyLinksWidget/MyLinksHelpers'
import { renderErrorToast } from '../../../utils'
import { GET_DASHBOARD } from '../../../graphql/queries/dashboard'
import { GET_TICKET_METRICS } from '../../../graphql/queries/ticket'

import './Dashboard.scss'

interface DashboardProps {
  dateFilterableStyle?: boolean
  startDate?: Date
  endDate?: Date
}

const ticketDetailEmptyState = {
  openWaitingCustomerCount: 0,
  openHighCount: 0,
  openCriticalCount: 0,
  openWaitingDeepwatchCount: 0,
}
const emptyState = {
  highlightMetrics: [],
  links: {
    splunk: '',
    servicenow: '',
    threatLabs: '',
  },
  logSourceUsage: [],
  maturityTrend: {
    customer: [],
    deepwatch: [],
  },
  openTicketTrend: [],
  threatSummary: {
    loggedEventCount: 0,
    alertCount: 0,
    totalTicketCount: 0,
    openTicketCount: 0,
    closedTicketCount: 0,
    openCriticalTicketCount: 0,
    closedCriticalTicketCount: 0,
  },
  ticketDetail: ticketDetailEmptyState,
}

const Dashboard: React.FC<DashboardProps> = ({
  dateFilterableStyle = false,
  startDate = new Date(),
  endDate = subDays(new Date(), 1),
}) => {
  const {
    state: {
      user: { isDWEmployee },
      dwExpertsCustomer,
      customer,
    },
    dispatch,
  } = useContext(Context)

  const { securityIndex } = useFlags()

  const [searchParams] = useSearchParams()
  const selectedCustomer = searchParams.get('customer')

  const {
    data: { getDashboard: dashboardMetrics } = { getDashboard: emptyState },
    loading,
  } = useQuery(GET_DASHBOARD, {
    variables: {
      selectedCustomer,
      startDate: format(startDate, 'yyyy-MM-dd'),
      endDate: format(endDate, 'yyyy-MM-dd'),
    },
    onError: (error) => {
      renderErrorToast(error, dispatch)
    },
  })

  const {
    data: { getTicketMetrics: ticketMetrics } = ticketDetailEmptyState,
    loading: loadingTicketMetrics,
  } = useQuery(GET_TICKET_METRICS, {
    variables: {
      selectedCustomer,
      startDate: format(startDate, 'yyyy-MM-dd'),
      endDate: format(endDate, 'yyyy-MM-dd'),
    },
    onError: (error) => {
      renderErrorToast(error, dispatch)
    },
  })

  return (
    <article id="dashboard-page" data-testid="dashboard">
      <DashboardBans
        data={dashboardMetrics.highlightMetrics}
        loading={loading}
        dateFilterableStyle={dateFilterableStyle}
      />
      <div className="dashboard-page-columns">
        <section className="dashboard-page-content">
          <CriticalHighOpenTickets
            data={dashboardMetrics.openTicketTrend}
            loading={loading}
            dateFilterableStyle={dateFilterableStyle}
          />
          <ThreatProtectionSummary
            loading={loading}
            data={dashboardMetrics.threatSummary}
            dateFilterableStyle={dateFilterableStyle}
          />
          <TopLogSourcesByUsage
            loading={loading}
            data={dashboardMetrics.logSourceUsage}
            dateFilterableStyle={dateFilterableStyle}
          />
        </section>
        <aside className="dashboard-page-sidebar">
          {!securityIndex && (
            <MaturityScore
              loading={loading}
              data={dashboardMetrics.maturityTrend}
              dateFilterableStyle={dateFilterableStyle}
            />
          )}
          <CurrentTicketsWidget
            customer={isDWEmployee ? dwExpertsCustomer : customer}
            data={ticketMetrics}
            links={dashboardMetrics.links}
            loading={loadingTicketMetrics}
          />
          <RecentReports selectedCustomer={selectedCustomer} />
          <MyLinksWidget
            data={shapeMyLinksData(dashboardMetrics.links)}
            loading={loading}
          />
        </aside>
      </div>
    </article>
  )
}

export default Dashboard
