/**
 * Add an ellipsis to any text longer than `maxLength` characters
 * @param {string} text The text to truncate
 * @param {number} [maxLength] Maximum length of the text before truncation; default is 14
 * @returns {string} The truncated text
 */
export const truncateText = (text: string, maxLength = 14): string =>
  text.length > maxLength ? `${text.slice(0, maxLength)}...` : text

/**
 * Returns `value` if `value` is an integer, otherwise returns an empty string
 * @param {number} value The value to test
 * @returns {number | ''} The value if it is an integer, otherwise an empty string
 */
export const hideNonIntegers = (value: number): number | '' =>
  Math.floor(value) === value ? value : ''
