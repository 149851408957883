import { IconVariant } from '../../../design-system'
import { Paths } from '../../App/Types'

export enum NavMenuItemType {
  APP_ROUTE_CHANGE,
  MENU_SET_CHANGE,
}

export interface NavMenuSet {
  menuHeaderLabel: string
  parentSet?: NavMenuSet
  menuItems: IMenuItem[]
}

export interface IMenuItem {
  activeIcon: IconVariant
  id: string
  inactiveIcon: IconVariant
  label: string
  onClick?: () => void
  type: NavMenuItemType
}

export class AppRouteItem implements IMenuItem {
  activeIcon: IconVariant
  id: string
  inactiveIcon: IconVariant
  label: string
  targetPath: Paths
  onClick: (() => void) | undefined
  type: NavMenuItemType
  className?: string

  constructor(options: {
    activeIcon: IconVariant
    inactiveIcon: IconVariant
    id: string
    label: string
    targetPath: Paths
    className?: string
  }) {
    this.activeIcon = options.activeIcon
    this.id = options.id
    this.inactiveIcon = options.inactiveIcon
    this.label = options.label
    this.type = NavMenuItemType.APP_ROUTE_CHANGE
    this.targetPath = options.targetPath
    this.className = options.className
  }
}

export class MenuSetItem implements IMenuItem {
  activeIcon: IconVariant
  targetMenuSet: NavMenuSet
  id: string
  inactiveIcon: IconVariant
  label: string
  onClick?: (() => void) | undefined
  type: NavMenuItemType

  constructor(options: {
    activeIcon: IconVariant
    inactiveIcon: IconVariant
    id: string
    label: string
    targetMenuSet: NavMenuSet
  }) {
    this.activeIcon = options.activeIcon
    this.id = options.id
    this.inactiveIcon = options.inactiveIcon
    this.label = options.label
    this.type = NavMenuItemType.MENU_SET_CHANGE
    this.targetMenuSet = options.targetMenuSet
  }
}
