import { UserAvatar, UserInfo } from '../../../../common/UserAvatar'
import { ListItemSelectType } from '../../../../../design-system'

export const buildSelectOptions = (customers: UserInfo[]) => {
  const selectOptions: ListItemSelectType[] = [
    {
      id: '0',
      name: '--',
    },
  ]

  customers.forEach(({ id, firstName, lastName, type }) => {
    selectOptions.push({
      id,
      name: `${firstName} ${lastName}`,
      leftAlternateImage: (
        <UserAvatar
          size={24}
          userInfo={{
            id,
            firstName,
            lastName,
            type,
          }}
        />
      ),
    })
  })

  return selectOptions
}

export const mapSettingToLabel = {
  serviceOffering: 'Service Offering',
  assignmentGroup: 'Assignment Group',
  category: 'Category',
  subcategory: 'Subcategory',
  channel: 'Channel',
  caseType: 'Case type',
}
