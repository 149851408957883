import { useEffect, useState } from 'react'

import { Typography, Input, Button } from '../../../../design-system'
import { SideSheet } from '../../../SideSheet'
import { FilterOptionValues, FilterOptions } from '../../../../models'
import FilterChips from '../../../common/Filters/FilterChips'
import { DetectionCatalogFilterOptions } from './DetectionCatalogFiltersValues'
import { DetectionCatalogStatus } from '../../Coverage.utils'

interface DetectionCatalogFilterProps {
  isOpen: boolean
  setIsOpen: (boolean: boolean) => void
  filterOptionsResponse: string[]
  selectedFilters: FilterOptions<DetectionCatalogFilterOptions>
  clearAll: () => void
  applyFilter: (vars: FilterOptions<DetectionCatalogFilterOptions>) => void
}

const DetectionCatalogFilters = ({
  isOpen = true,
  setIsOpen,
  filterOptionsResponse, // currently only MITRE Tactics are returned in the response from BE
  selectedFilters,
  clearAll,
  applyFilter,
}: DetectionCatalogFilterProps): JSX.Element => {
  const filterOptions: FilterOptions<FilterOptionValues[]> = {
    keywordSearch: '',
    filters: [
      {
        label: 'MITRE Tactic',
        key: 'mitreTactics',
        values: filterOptionsResponse.map((tactic) => ({
          value: tactic,
          label: tactic,
          selected: false,
        })),
      },
      {
        label: 'Status',
        key: 'status',
        values: Object.values(DetectionCatalogStatus).map((status) => ({
          value: status,
          label: status,
          selected: false,
        })),
      },
    ],
  }
  const [selectedFiltersSideSheet, setSelectedFiltersSideSheet] =
    useState<FilterOptions<DetectionCatalogFilterOptions>>(selectedFilters)

  useEffect(() => {
    setSelectedFiltersSideSheet(selectedFilters)
  }, [selectedFilters])

  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      applyFilter(selectedFiltersSideSheet)
    }
  }

  return (
    <SideSheet
      open={isOpen}
      footer={
        <div
          className="dw-filters-buttons"
          data-testid="detection-catalog-filters-buttons"
          id="dw-filters-buttons"
        >
          <Button variant="secondary" onClick={() => clearAll()}>
            Clear all
          </Button>
          <Button onClick={() => applyFilter(selectedFiltersSideSheet)}>
            Apply
          </Button>
        </div>
      }
    >
      <article
        id="filters-sidesheet"
        className={`content dw-filters-sidesheet ${
          isOpen ? 'side-sheet-active' : ''
        }`} // for testing if the side sheet is open or closed. see comment in test file
        data-testid="detection-catalog-filters-sidesheet-test-id"
        style={{ padding: '0px' }}
      >
        <div
          className="header"
          data-testid="detection-catalog-filters-sidesheet-header"
        >
          <div className="header-content">
            <Typography>Filtering options</Typography>
            <Button
              variant="navigational-icon"
              iconProps={{ variant: 'closeOutline' }}
              onClick={() => setIsOpen(false)}
            />
          </div>
        </div>
        <div className="dw-filters-form" id="dw-filters">
          <Input
            leftIcon={'searchOutline'}
            placeholder="Search by detection name or technologies.."
            type="text"
            labelId="filterSearchText"
            name="ticket filter search text"
            onChange={({ target }) => {
              setSelectedFiltersSideSheet({
                ...selectedFiltersSideSheet,
                keywordSearch: target.value,
              })
            }}
            value={selectedFiltersSideSheet.keywordSearch}
            onKeyDown={handleOnKeyDown}
          />
          <>
            {filterOptions.filters.map(
              ({ label, key, values }): JSX.Element => {
                return (
                  <fieldset className="dw-filters-group" key={key}>
                    <Typography component={'legend'}>{label}</Typography>
                    <FilterChips
                      key={key}
                      keyValue={key}
                      filterGroup={label}
                      filterLabel={label}
                      filterValues={values}
                      selectedFilters={selectedFiltersSideSheet}
                      setSelectedFilters={
                        setSelectedFiltersSideSheet as VoidFunction
                      }
                    />
                  </fieldset>
                )
              },
            )}
          </>
        </div>
      </article>
    </SideSheet>
  )
}

export default DetectionCatalogFilters
