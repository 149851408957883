import { styled } from '@mui/material/styles'
import { SxProps, Theme } from '@mui/material'

const StyledSvg = styled('svg')()

interface NoDetectionsEmptyStateProps {
  sx?: SxProps<Theme>
}

const NoDetectionsEmptyState = ({ sx }: NoDetectionsEmptyStateProps) => {
  return (
    <StyledSvg viewBox="0 0 136 101" xmlns="http://www.w3.org/2000/svg" sx={sx}>
      <path
        d="M21.2915 39.5112L1.74773 29.2911L1.74773 21.1424L21.2915 31.3625L118.595 2.03252L118.595 9.39081L21.2915 39.5112Z"
        strokeWidth="1.60128"
      />
      <path d="M21.2915 39.5112L21.2915 31.3625" strokeWidth="1.60128" />
      <path
        d="M118.595 2.03252L118.503 9.39081L22.4505 79.7678L22.436 72.1896L118.595 2.03252Z"
        strokeWidth="1.60128"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M108.492 55.741L22.2381 80.3636L22.2381 72.6175L108.492 47.9465L108.492 55.741Z"
        strokeWidth="1.60128"
      />
      <path
        d="M90.8909 67.3684L108.478 48.8828L108.488 56.0324L90.9 74.4688L90.8909 67.3684Z"
        strokeWidth="1.60128"
      />
      <path
        d="M109.651 78.4792L106.13 84.1448L90.8905 75.9795L90.8905 68.4252L109.651 78.4792Z"
        strokeWidth="1.60128"
      />
      <path
        d="M105.326 81.552L105.326 90.7467C105.326 92.6722 106.469 94.4168 109.283 95.8882C114.68 98.7104 123.458 98.6957 128.889 95.8554C131.964 94.2473 132.999 92.8829 132.999 90.7467L132.999 86.1493L132.999 81.552"
        strokeWidth="2.56205"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M109.283 86.4601C114.679 89.2823 123.457 89.2676 128.889 86.4273C134.32 83.587 134.348 78.9967 128.952 76.1745C123.555 73.3523 114.777 73.367 109.345 76.2073C103.914 79.0475 103.886 83.6379 109.283 86.4601Z"
        strokeWidth="2.56205"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </StyledSvg>
  )
}

export default NoDetectionsEmptyState
