/* eslint import/namespace: ['error', { allowComputed: true }] */
import { IonIcon } from '@ionic/react'

//? we want to import icons individually rather than the entire library with *
//? refer to the ./Icons.ts file for icons we are exporting from the icon
//? library. Export any more icons in that file.
import * as icons from './Icons'
import colors from '../../theme/colors'
import { CSSProps, IconProps } from '../../interfaces'
import { useTheme } from '../../hooks'
import { getCustomVariant } from './CustomIcons'

const Icon = ({
  color,
  size = 24,
  style,
  variant,
  customVariant,
  title,
}: IconProps): JSX.Element => {
  const isDarkTheme = useTheme('dark')

  const iconColor = () => {
    if (color) {
      return color
    } else if (isDarkTheme) {
      return colors.util.navy[100]
    } else {
      return colors.util.one.main
    }
  }

  const elementStyle: CSSProps = {
    ...(iconColor && { color: iconColor() }),
    ...(size && { fontSize: size }),
    width: size,
    height: size,
    contain: 'size',
    pointerEvents: 'none',
    ...(style && style),
  } as CSSProps

  if (customVariant) {
    const content = getCustomVariant(customVariant, iconColor())
    return content
  }

  const getIcon = () => {
    if (variant) {
      return (
        <IonIcon
          title={title}
          icon={variant ? icons[String(variant)] : undefined}
          style={elementStyle}
          data-testid={variant ? `icon-${variant}` : ''}
        />
      )
    } else if (customVariant) {
      return getCustomVariant(customVariant, iconColor())
    } else {
      return null
    }
  }

  return <>{getIcon()}</>
}

export default Icon
