import './MaturityScore.scss'
const ToolTip = ({ name }) => {
  const toolTipClasses = () => {
    switch (name) {
      case 'customer':
        return <div className={'customer'}></div>
      case 'deepwatch':
        return <div className={'deepwatch'}></div>
      case 'customerTrend':
        return <div className={'customer'}></div>
      case 'deepwatchTrend':
        return <div className={'deepwatch'}></div>
    }
    return <div></div>
  }

  return toolTipClasses()
}

export default ToolTip
