import React, { createRef, useEffect, useRef, useState } from 'react'
import { CSSTransition } from 'react-transition-group'

import { colors } from '../../theme'
import { Icon } from '../Icon'
import { Button } from '../Button'
import { DatePickerProps } from '../../interfaces/DatePicker'
import SkeletonBlock from '../SkeletonBlock/SkeletonBlock'
import * as ButtonIcons from '../../assets/buttonIcons'
import Tooltip from '../Tooltip/Tooltip'
import { useOutsideClick } from '../../hooks'

import './DatePicker.scss'

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

//? all date objects within this component are time agnostic and liberally uses the Date.prototype.getTime() method for date comparisons
const DatePicker = ({
  startDate,
  endDate,
  onSubmit, //? a partial function executed when the "Apply" button is clicked
  onReset, //? a partial function executed when the "Reset" button is clicked
  onChange, //? a partial function exectued when any event happens that changes the value of the start or end dates (see updateAndEmitSelectedDates())
  disabledSubmit, //? a partial function whose returned value determines if the "Apply" button can be clicked and, consequently, if the onSubmit() callback can be executed
  disabledDate, //? a partial function executed to determine if any given date should be disabled
  popoverStyle, //? extra styles to add to the popover container
  defaultStartDate, //? default start date. Used as initial value for start date and when reset button is pressed
  defaultEndDate, //? default end date. Used as initial value for end date and when reset button is pressed
  loading, //? if the button to open the popover should be disabled and the date range undefined
  allowIconChange, //? boolean to allow alt icon behavior
  iconCallback, //? a partial function executed when the icon within the input is clicked or when the date change is submitted and the component allows alternate icon actions
  tooltipMessage, //? optional tooltip message to pass message for global date filtering click action on question icon
}: DatePickerProps): JSX.Element => {
  const startDateToUse = startDate || defaultStartDate
  const endDateToUse = endDate || defaultEndDate

  //? initialize startDate without time, if a Date is given
  const startDateWithoutTime = startDateToUse
    ? new Date(
        startDateToUse.getFullYear(),
        startDateToUse.getMonth(),
        startDateToUse.getDate(),
      )
    : undefined

  //? initialize endDate without time, if a Date is given
  const endDateWithoutTime = endDateToUse
    ? new Date(
        endDateToUse.getFullYear(),
        endDateToUse.getMonth(),
        endDateToUse.getDate(),
      )
    : undefined

  //? initialize defaultStartDate without time, if a Date is given
  const defaultStartDateWithoutTime = defaultStartDate
    ? new Date(
        defaultStartDate.getFullYear(),
        defaultStartDate.getMonth(),
        defaultStartDate.getDate(),
      )
    : undefined

  //? initialize defaultEndDate without time, if a Date is given
  const defaultEndDateWithoutTime = defaultEndDate
    ? new Date(
        defaultEndDate.getFullYear(),
        defaultEndDate.getMonth(),
        defaultEndDate.getDate(),
      )
    : undefined

  const [selectedDates, setSelectedDates] = useState<
    [Date | undefined, Date | undefined]
  >([startDateWithoutTime, endDateWithoutTime])

  const [hoveredDate, setHoveredDate] = useState<Date>()

  const [shouldShowDateLabel, setShouldShowDateLabel] = useState(!loading)

  //? used for handling state for outClick event

  const datePickerRef = useRef<HTMLDivElement>(null)

  useOutsideClick(
    () => {
      calendarDropdown.current?.classList.add('hidden')
      popoverButton.current?.classList.remove('focused')
      showCalendars.current = false
    },
    true,
    datePickerRef,
  )

  //? used for showing/hiding the popover
  const showCalendars = useRef<boolean>(false)

  //? ref used to change styles on the popover element
  const calendarDropdown = useRef<HTMLDivElement>(null)

  // ref used to animate the loading skeleton
  const loadingSkeleton = createRef<HTMLDivElement>()

  // ref used to change the border style of the popover button
  const popoverButton = useRef<HTMLButtonElement>(null)

  //? tracks user month pagination
  const [monthIncrement, setMonthIncrement] = useState<number>(0)

  //? handles the behavior of which date will be selected next (see UI/UX)
  const dateSelectionIndex = useRef<0 | 1>(startDate && !endDate ? 1 : 0)
  const [selectedStartDate, selectedEndDate] = selectedDates

  const calendarOutlineRef = useRef<HTMLDivElement>(null)
  const questionOutlineRef = useRef<HTMLImageElement>(null)
  const questionFilledRef = useRef<HTMLImageElement>(null)

  const questionFilledElement = tooltipMessage ? (
    <Tooltip
      target={
        <img
          id="questionFilled"
          src={ButtonIcons.CalendarQuestionFill}
          alt="calendar-question-filled"
        />
      }
    >
      {tooltipMessage}
    </Tooltip>
  ) : (
    <img
      id="questionFilled"
      src={ButtonIcons.CalendarQuestionFill}
      alt="calendar-question-filled"
    />
  )

  const handleIconEnter = (): void => {
    if (questionFilledRef.current) {
      if (questionFilledRef.current.style.display !== 'flex') {
        if (calendarOutlineRef.current) {
          calendarOutlineRef.current.style.display = 'none'
        }
        if (questionOutlineRef.current) {
          questionOutlineRef.current.style.display = 'flex'
        }
      }
    }
  }

  const handleIconLeave = (): void => {
    if (questionFilledRef.current) {
      if (
        questionFilledRef.current.style.display !== 'flex' &&
        (document.activeElement?.tagName === 'BUTTON' ||
          document.activeElement?.querySelector('img') !==
            questionOutlineRef.current)
      ) {
        if (questionOutlineRef.current) {
          questionOutlineRef.current.style.display = 'none'
        }
        if (calendarOutlineRef.current) {
          calendarOutlineRef.current.style.display = 'flex'
        }
      }
    }
  }

  const handleIconToggle = (
    reversionIconRef:
      | React.RefObject<HTMLImageElement>
      | React.RefObject<HTMLDivElement>,
  ): void => {
    if (questionFilledRef.current) {
      if (questionFilledRef.current.style.display === 'none') {
        questionFilledRef.current.style.display = 'flex'
        if (calendarOutlineRef.current) {
          calendarOutlineRef.current.style.display = 'none'
        }
        if (questionOutlineRef.current) {
          questionOutlineRef.current.style.display = 'none'
        }
      } else {
        questionFilledRef.current.style.display = 'none'
        if (reversionIconRef.current) {
          reversionIconRef.current.style.display = 'flex'
        }
      }
    }
  }

  const handleIconClick = (e: React.MouseEvent<HTMLDivElement>): void => {
    e.stopPropagation()
    iconCallback?.()
    handleIconToggle(questionOutlineRef)
  }

  const handleIconKeyDown = (e: React.KeyboardEvent<HTMLDivElement>): void => {
    if (['Enter', ' '].includes(e.key)) {
      e.preventDefault()
      iconCallback?.()
      handleIconToggle(calendarOutlineRef)
    }
  }

  useEffect(() => {
    showCalendars.current = false
    calendarDropdown.current?.classList.add('hidden')
  }, [loading])

  useEffect(() => {
    const newDefaultStartDateWithoutTime = defaultStartDate
      ? new Date(
          defaultStartDate.getFullYear(),
          defaultStartDate.getMonth(),
          defaultStartDate.getDate(),
        )
      : undefined

    const newDefaultEndDateWithoutTime = defaultEndDate
      ? new Date(
          defaultEndDate.getFullYear(),
          defaultEndDate.getMonth(),
          defaultEndDate.getDate(),
        )
      : undefined

    if (newDefaultStartDateWithoutTime && newDefaultEndDateWithoutTime) {
      setSelectedDates([
        newDefaultStartDateWithoutTime,
        newDefaultEndDateWithoutTime,
      ])
    }
  }, [defaultStartDate, defaultEndDate])

  const toggleDropdown = () => {
    showCalendars.current = !showCalendars.current
    if (showCalendars.current) {
      calendarDropdown.current?.classList.remove('hidden')
      popoverButton.current?.classList.add('focused')
    } else {
      calendarDropdown.current?.classList.add('hidden')
      popoverButton.current?.classList.remove('focused')
    }
  }

  const IconButton = (): JSX.Element => {
    if (allowIconChange === true) {
      return (
        <div
          data-testid="calendar-icon-container"
          role="button"
          tabIndex={0}
          className="questionIcon"
          onMouseEnter={handleIconEnter}
          onMouseLeave={handleIconLeave}
          onClick={handleIconClick}
          onKeyDown={handleIconKeyDown}
          onFocus={handleIconEnter}
          onBlur={handleIconLeave}
        >
          <div
            ref={calendarOutlineRef}
            style={{
              display: calendarOutlineRef.current
                ? calendarOutlineRef.current.style.display
                : 'flex',
            }}
          >
            <Icon variant="calendarOutline" color={colors.util.navy[50]} />
          </div>
          <img
            ref={questionOutlineRef}
            id="questionOutline"
            src={ButtonIcons.CalendarQuestionOutline}
            alt="calendar-question-outline"
            style={{
              display: questionOutlineRef.current
                ? questionOutlineRef.current.style.display
                : 'none',
            }}
          />
          <div
            ref={questionFilledRef}
            style={{
              display: questionFilledRef.current
                ? questionFilledRef.current.style.display
                : 'none',
            }}
          >
            {questionFilledElement}
          </div>
        </div>
      )
    } else {
      return <Icon variant="calendarOutline" color={colors.util.navy[50]} />
    }
  }

  const getDayClassName = (date: Date) => {
    const dateTime = date.getTime()
    const selectedStartDateTime = selectedStartDate?.getTime()
    const selectedEndDateTime = selectedEndDate?.getTime()
    let className = ''
    if (disabledDate?.(date)) {
      return 'disabled'
    }
    if (selectedStartDateTime) {
      if (dateTime === selectedStartDateTime) {
        className = 'selected start'
      }
      if (
        hoveredDate &&
        !selectedEndDate &&
        date.getTime() > selectedStartDateTime
      ) {
        if (date.getTime() <= hoveredDate.getTime()) {
          return 'selected middle'
        }
        return 'fill-with-button' //? fills button container space with the button area for smoother hover state transition
      }
      if (selectedEndDateTime) {
        if (
          dateTime >= selectedStartDateTime &&
          dateTime <= selectedEndDateTime
        ) {
          className = 'selected'
        }
        if (
          dateTime === selectedStartDateTime &&
          dateTime === selectedEndDateTime
        ) {
          return className
        }
        if (dateTime === selectedStartDateTime) {
          className += ' start'
        }
        if (dateTime === selectedEndDateTime) {
          className += ' end'
        }
        if (
          dateTime > selectedStartDateTime &&
          dateTime < selectedEndDateTime
        ) {
          className += ' middle'
        }
      }
    }
    return className
  }

  const handleDateSelect = (date: Date) => {
    const newSelection: typeof selectedDates = [
      selectedStartDate,
      selectedEndDate,
    ]
    const dateLessThanStartDate =
      dateSelectionIndex.current === 1 &&
      selectedStartDate &&
      date.getTime() < selectedStartDate.getTime()
    const dateGreaterThanEndDate =
      dateSelectionIndex.current === 0 &&
      selectedEndDate &&
      date.getTime() > selectedEndDate.getTime()
    if (dateLessThanStartDate || dateGreaterThanEndDate) {
      dateSelectionIndex.current = 1
      return updateAndEmitSelectedDates([date, undefined])
    }
    newSelection[dateSelectionIndex.current] = date
    updateAndEmitSelectedDates(newSelection)
    dateSelectionIndex.current = dateSelectionIndex.current === 0 ? 1 : 0
  }

  const updateAndEmitSelectedDates = (
    newDatesSelection: typeof selectedDates,
  ) => {
    setSelectedDates(newDatesSelection)
    onChange?.(...newDatesSelection)
  }

  //? strip time from today's date
  const todayWithTime = new Date()
  const today = new Date(
    todayWithTime.getFullYear(),
    todayWithTime.getMonth(),
    todayWithTime.getDate(),
  )

  //? start left calendar one month behind the right
  const leftCalendarStartDate = new Date(
    today.getFullYear(),
    today.getMonth() - 1 + monthIncrement,
  )
  const leftCalendarEndDate = new Date(
    today.getFullYear(),
    today.getMonth() + monthIncrement,
    0,
  )
  const rightCalendarStartDate = new Date(
    today.getFullYear(),
    today.getMonth() + monthIncrement,
  )
  const rightCalendarEndDate = new Date(
    today.getFullYear(),
    today.getMonth() + 1 + monthIncrement,
    0,
  )

  //? initialize calendar container used to build JSX
  const calendars: Array<null | Date>[][] = [[], []]

  //? build calendars
  calendars.forEach((calendar, index) => {
    let startDate = leftCalendarStartDate
    let calendarStartDateDayOfWeek = leftCalendarStartDate.getDay()
    let calendarEndDateDayOfMonth = leftCalendarEndDate.getDate()
    if (index === 1) {
      startDate = rightCalendarStartDate
      calendarStartDateDayOfWeek = rightCalendarStartDate.getDay()
      calendarEndDateDayOfMonth = rightCalendarEndDate.getDate()
    }
    //? fill first week with empty days until the start of the month
    let week: Array<null | Date> = Array(calendarStartDateDayOfWeek).fill(null)
    for (let i = 1; i <= calendarEndDateDayOfMonth; ++i) {
      week.push(new Date(startDate.getFullYear(), startDate.getMonth(), i))
      if (
        (i + calendarStartDateDayOfWeek) % 7 === 0 ||
        i === calendarEndDateDayOfMonth
      ) {
        calendar.push(week)
        week = []
      }
    }
  })

  return (
    <div className="dw-datepicker" ref={datePickerRef}>
      <button
        className="input"
        onClick={toggleDropdown}
        disabled={loading}
        ref={popoverButton}
      >
        <IconButton />
        <div className="label-container">
          <CSSTransition
            in={loading}
            appear={shouldShowDateLabel}
            nodeRef={loadingSkeleton}
            timeout={150}
            classNames="dw-datepicker-loading"
            onEnter={() => setShouldShowDateLabel(false)}
            onExited={() => setShouldShowDateLabel(true)}
            unmountOnExit
          >
            <div className="loading-container">
              <SkeletonBlock ref={loadingSkeleton} />
            </div>
          </CSSTransition>
          {shouldShowDateLabel && (
            <span className="dw-datepicker-label">
              {selectedStartDate
                ? selectedStartDate.toLocaleDateString()
                : 'mm/dd/yyyy'}
              {' - '}
              {selectedEndDate
                ? selectedEndDate.toLocaleDateString()
                : 'mm/dd/yyyy'}
            </span>
          )}
        </div>
      </button>
      <div
        ref={calendarDropdown}
        className="popover-container hidden"
        style={popoverStyle}
      >
        <div className="calendar-container">
          <button onClick={() => setMonthIncrement(monthIncrement - 1)}>
            <Icon
              variant="chevronBackCircleOutline"
              color={colors.util.navy[100]}
              size={28}
            />
          </button>
          {calendars.map((calendar, index) => (
            <div key={index} className="calendar">
              <div className="header">
                {index === 0
                  ? `${
                      months[leftCalendarStartDate.getMonth()]
                    } ${leftCalendarStartDate.getFullYear()}`
                  : `${
                      months[rightCalendarStartDate.getMonth()]
                    } ${rightCalendarStartDate.getFullYear()}`}
              </div>
              <div className="body-container">
                <div className="subheader">
                  <div>Su</div>
                  <div>Mo</div>
                  <div>Tu</div>
                  <div>We</div>
                  <div>Th</div>
                  <div>Fr</div>
                  <div>Sa</div>
                </div>
                <div className="body">
                  {calendar.map((week, index) => (
                    <div key={index} className="row">
                      {week.map((date, index) => {
                        if (!date) {
                          return <div key={index} />
                        }
                        return (
                          <div key={index} className={getDayClassName(date)}>
                            <button
                              disabled={
                                disabledDate &&
                                disabledDate(
                                  date,
                                  selectedStartDate,
                                  selectedEndDate,
                                )
                              }
                              onClick={() => {
                                handleDateSelect(date)
                              }}
                              onMouseEnter={() => {
                                //? only update hoveredDate when hovered state matters (optimization)
                                if (selectedStartDate && !selectedEndDate) {
                                  setHoveredDate(date)
                                }
                              }}
                              onMouseLeave={() => {
                                //? only update hoveredDate when hovered state matters (optimization)
                                if (selectedStartDate && !selectedEndDate) {
                                  setHoveredDate(undefined)
                                }
                              }}
                            >
                              {date.getTime() === today.getTime() &&
                              date.getTime() !== selectedStartDate?.getTime() &&
                              date.getTime() !== selectedEndDate?.getTime() ? (
                                <div className="today">{date.getDate()}</div>
                              ) : (
                                date.getDate()
                              )}
                            </button>
                          </div>
                        )
                      })}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
          <button onClick={() => setMonthIncrement(monthIncrement + 1)}>
            <Icon
              variant="chevronForwardCircleOutline"
              color={colors.util.navy[100]}
              size={28}
            />
          </button>
        </div>
        <div className="footer">
          <Button
            variant="tertiary"
            onClick={() => {
              const newDateSelection: typeof selectedDates = [
                defaultStartDateWithoutTime,
                defaultEndDateWithoutTime,
              ]
              onReset?.(selectedStartDate, selectedEndDate)
              setSelectedDates(newDateSelection)
              setMonthIncrement(0)
              dateSelectionIndex.current = 0
            }}
          >
            Reset
          </Button>
          {onSubmit && (
            <Button
              disabled={disabledSubmit?.(selectedStartDate, selectedEndDate)}
              onClick={() => {
                onSubmit(selectedStartDate, selectedEndDate)
                toggleDropdown()
                if (
                  questionFilledRef.current &&
                  questionFilledRef.current.style.display === 'flex'
                ) {
                  questionFilledRef.current.style.display = 'none'
                  if (calendarOutlineRef.current) {
                    calendarOutlineRef.current.style.display = 'flex'
                  }
                  iconCallback?.()
                }
              }}
            >
              Apply
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

export default DatePicker
