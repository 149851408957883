import React, { useContext } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@apollo/client'

import { DeepwatchExpertCustomerTable } from '../DeepwatchExpertCustomerTable'
import { Icon, Typography, colors } from '../../../design-system'
import {
  IconButtonProps,
  InlineMessageType,
} from '../../../design-system/interfaces'
import { Paths } from '../../App/Types'
import { deployToastMessage } from '../../../utils'
import { Context, setCustomerNames } from '../../App'
import { CustomerHighlight } from '../../../models'
import { TagNew } from '../../../design-system/components/TagNew'
import { GET_DW_EXPERTS_HOME_DATA } from '../../../graphql/queries/dwExperts'
import {
  ADD_BOOKMARKED_CUSTOMER,
  REMOVE_BOOKMARKED_CUSTOMER,
} from '../../../graphql/mutations/customer'
import { getCustomerString } from '../dwExperts.utils'

import './AllCustomers.scss'

interface AllCustomersProps {
  customerHighlights: CustomerHighlight[]
  loading: boolean
}

export const AllCustomers: React.FC<AllCustomersProps> = ({
  customerHighlights,
  loading,
}) => {
  const { dispatch } = useContext(Context)
  const { featureAssignedCustomers } = useFlags()
  const navigate = useNavigate()

  const [addBookmarkedCustomer] = useMutation(ADD_BOOKMARKED_CUSTOMER, {
    refetchQueries: [GET_DW_EXPERTS_HOME_DATA],
  })

  const [removeBookmarkedCustomer] = useMutation(REMOVE_BOOKMARKED_CUSTOMER, {
    refetchQueries: [GET_DW_EXPERTS_HOME_DATA],
  })

  const handleAddCustomerButtonClick = async (customer: CustomerHighlight) => {
    deployToastMessage(
      {
        id: crypto.randomUUID(),
        text: `Making request to add ${getCustomerString(
          customer,
        )} to "My Customers"`,
        messageType: InlineMessageType.Info,
        secondsToExpire: 3000,
        dismissible: false,
      },
      dispatch,
    )

    await addBookmarkedCustomer({
      variables: {
        customers: [customer.customerShortName],
      },
      onCompleted: () => {
        deployToastMessage(
          {
            id: crypto.randomUUID(),
            text: `${getCustomerString(customer)} added to "My Customers"`,
            messageType: InlineMessageType.SuccessInline,
            secondsToExpire: 3000,
            dismissible: false,
          },
          dispatch,
        )
      },
      onError: () => {
        deployToastMessage(
          {
            id: crypto.randomUUID(),
            text: `Could not add ${getCustomerString(
              customer,
            )} to "My Customers"`,
            messageType: InlineMessageType.ErrorInline,
            secondsToExpire: 3000,
            dismissible: false,
          },
          dispatch,
        )
      },
    })
  }

  const handleRemoveCustomerButtonClick = async (
    customer: CustomerHighlight,
  ) => {
    deployToastMessage(
      {
        id: crypto.randomUUID(),
        text: `Making request to remove ${getCustomerString(
          customer,
        )} from "My Customers"`,
        messageType: InlineMessageType.Info,
        secondsToExpire: 3000,
        dismissible: false,
      },
      dispatch,
    )

    await removeBookmarkedCustomer({
      variables: {
        customers: [customer.customerShortName],
      },
      onCompleted: () => {
        deployToastMessage(
          {
            id: crypto.randomUUID(),
            text: `${getCustomerString(customer)} removed from "My Customers"`,
            messageType: InlineMessageType.SuccessInline,
            secondsToExpire: 3000,
            dismissible: false,
          },
          dispatch,
        )
      },
      onError: () => {
        deployToastMessage(
          {
            id: crypto.randomUUID(),
            text: `Could not remove ${getCustomerString(
              customer,
            )} from "My Customers"`,
            messageType: InlineMessageType.ErrorInline,
            secondsToExpire: 3000,
            dismissible: false,
          },
          dispatch,
        )
      },
    })
  }

  const handleNavigateButtonClick = (customer: CustomerHighlight) => {
    let customerName = customer.name
    if (typeof customer.name === 'object' && customer.name.sortValue) {
      customerName = String(customer.name.sortValue)
    }
    dispatch(
      setCustomerNames({
        customerName: String(customerName),
        customerShortName: customer.customerShortName,
      }),
    )

    navigate(`${Paths.DASHBOARD}?customer=${customer.customerShortName}`)
  }

  const shapeHighlightData = (
    rawHighlight: CustomerHighlight[],
  ): CustomerHighlight[] => {
    return rawHighlight.map((highlight, index: number) => {
      if (highlight.usageGb === null) {
        highlight.usageGb = {
          sortValue: -1,
          displayValue: (
            <div style={{ color: colors.util.orange[100] }}>Error</div>
          ),
        }
      }

      if ((highlight.usageGb as number) > highlight.usageLicenseGb) {
        highlight.usageGb = {
          sortValue: highlight.usageGb as number,
          displayValue: (
            <div className="dw-expert-coverage">
              <Typography
                variant="text11semibold"
                color={colors.util.two.light}
              >
                {highlight.usageGb as number}
              </Typography>
              <Icon
                variant="alertCircle"
                color={colors.util.two.light}
                size={18}
              />
            </div>
          ),
        }
      }

      if (highlight.isBookmarkedCustomer) {
        if (typeof highlight.name === 'string') {
          highlight.name = {
            originalValue: highlight.name,
            displayValue: (
              <div className="customer-name-cell">
                {highlight.name}
                <TagNew
                  text="ADDED"
                  interactive={false}
                  severity="informational"
                />
              </div>
            ),
            sortValue: highlight.name,
          }
        }
      }

      const rowHoverElements: IconButtonProps[] = []

      if (featureAssignedCustomers) {
        if (!highlight.isBookmarkedCustomer) {
          rowHoverElements.push({
            variant: 'addCircleOutline',
            label: 'Add to "My Customers"',
            customOnClick: handleAddCustomerButtonClick,
          })
        } else {
          rowHoverElements.push({
            variant: 'removeCircleSharp',
            label: 'Remove from "My Customers"',
            customOnClick: handleRemoveCustomerButtonClick,
          })
        }

        rowHoverElements.push({
          variant: 'arrowForwardCircleOutline',
          label: 'Customer Dashboard Page',
          customOnClick: handleNavigateButtonClick,
        })
      }

      return {
        ...highlight,
        id: index,
        rowHoverElements,
      }
    })
  }

  return (
    <div
      data-testid="all-customers"
      id="all-customers"
      className="all-customers"
    >
      <DeepwatchExpertCustomerTable
        customerHighlights={shapeHighlightData(customerHighlights)}
        disableRowClick={featureAssignedCustomers}
        disableAddedTags={false}
        loading={loading}
      />
    </div>
  )
}

export default AllCustomers
