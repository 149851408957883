import './DateButton.scss'

interface DateButtonProps {
  label: string | number
  onClick?(): void
  isActive: boolean
  isDisabled?: boolean
}

const DateButton = ({
  label,
  onClick,
  isActive,
  isDisabled,
}: DateButtonProps) => {
  let buttonClass = 'selectable'

  if (isDisabled) {
    buttonClass = 'disabled'
  }
  if (isActive) {
    buttonClass = 'active'
  }

  return (
    <button
      className={`date-button ${buttonClass}`}
      onClick={onClick}
      disabled={isDisabled}
    >
      {label}
    </button>
  )
}

export default DateButton
