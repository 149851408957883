import { useContext, useEffect, useState } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useLocation, useNavigate } from 'react-router-dom'

import Overview from './Overview/Overview'
import { MitreOverviewContextProvider } from './Overview/context/MitreOverviewContext'
import DetectionCatalog from './DetectionCatalog/DetectionCatalog'
import { Tabs } from '../../design-system'
import { TaVersions } from './DetectionCatalog/TaVersions'
import { Paths } from '../App/Types'
import { Context } from '../App'
import { navigateUserType } from '../../utils'

import './Coverage.scss'

const Coverage = ({ activeTab = 0 }) => {
  const [selectedTab, setSelectedTab] = useState(activeTab)
  const { featureDetectionLibraryPage } = useFlags()
  const [selectedFilters, setFilters] = useState<Array<string>>([])
  const navigate = useNavigate()
  const { search } = useLocation()
  const tab = search.split('tab=')[1]

  const {
    state: {
      user: { isDWEmployee },
      dwExpertsCustomer: { customerShortName },
    },
  } = useContext(Context)

  useEffect(() => {
    if (tab) {
      setSelectedTab(parseInt(tab))
    }
  }, [tab])

  const tabData = [
    {
      id: 0,
      text: 'Overview',
      active: true,
      notification: false,
    },
    featureDetectionLibraryPage && {
      id: 1,
      text: 'Detection Catalog',
      active: false,
      notification: false,
    },
  ].filter(Boolean)

  const handleDetectionCatalogTab = (status: string): void => {
    setFilters([status])
    setSelectedTab(1)
  }

  const CoverageTabsContent: JSX.Element[] = [
    <MitreOverviewContextProvider key={0}>
      <Overview handleDetectionLibNav={handleDetectionCatalogTab} />
    </MitreOverviewContextProvider>,
    featureDetectionLibraryPage && (
      <DetectionCatalog key={1} selectedFilters={selectedFilters} />
    ),
  ]

  return (
    <article id="coverage-page" className="content" data-testid="coverage">
      <div id="coverage-page-tabs-container">
        <Tabs
          data={tabData}
          tabStyles={{ paddingLeft: '11px' }}
          activeTab={selectedTab}
          onTabClick={(id) => {
            setFilters([])
            setSelectedTab(id)
            if (id === 0) {
              navigateUserType(
                isDWEmployee,
                Paths.DETECTION_COVERAGE,
                navigate,
                customerShortName,
              )
            } else if (id === 1) {
              navigateUserType(
                isDWEmployee,
                Paths.DETECTION_CATALOG,
                navigate,
                customerShortName,
              )
            }
          }}
        ></Tabs>

        {selectedTab === 1 && <TaVersions />}
      </div>
      {CoverageTabsContent.at(selectedTab)}
    </article>
  )
}

export default Coverage
