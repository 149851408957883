import { useEffect, useState } from 'react'

export const usePageSize = () => {
  const calculatePageSize = (height: number) => {
    const rowHeight = 50
    const estimatedRows = Math.ceil(height / rowHeight)

    // Default to a maximum of 25 items fetched per paginated call
    return Math.max(25, estimatedRows)
  }

  const [pageSize, setPageSize] = useState(() =>
    calculatePageSize(window.innerHeight),
  )

  useEffect(() => {
    const observer = new ResizeObserver(([entry]) => {
      if (entry && entry.contentRect) {
        setPageSize(calculatePageSize(entry.contentRect.height))
      }
    })

    observer.observe(document.documentElement)

    return () => observer.disconnect()
  }, [])

  return pageSize
}
