import { format } from 'date-fns'
import { useSearchParams } from 'react-router-dom'

import { Table, Typography } from '../../../../../design-system/components'
import {
  ColumnHeader,
  TableLoadingState,
} from '../../../../../design-system/components/Table/Table.types'
import {
  ReportType,
  ThreatIntelReport,
} from '../../../../../models/ThreatIntel'
import { colors } from '../../../../../design-system'
import { TagNew } from '../../../../../design-system/components/TagNew'
import { ReportTypeLabel } from '../../../ThreatIntel.utils'
import { useThreatIntelReportsContext } from '../../context'
import NoResults from '../../../../common/NoResults/NoResults'

import './ThreatIntelReportsTable.scss'

interface Props {
  data: ThreatIntelReport[]
  isLoading: boolean
  onBottomReached: VoidFunction
}

const TABLE_HEADERS: ColumnHeader[] = [
  {
    name: 'id',
    visible: false,
  },
  {
    name: 'title',
    visible: true,
    sortable: true,
  },
  {
    name: 'type',
    visible: true,
    sortable: true,
  },
  {
    name: 'labels',
    visible: true,
  },
  {
    name: 'date',
    visible: true,
    sortable: true,
    columnWidth: '155px',
  },
]

const generateDataWithDisplayValues = (
  data: ThreatIntelReport[],
  onLabelClick: (label: string) => void,
  selectedLabels: string[],
) => {
  return data.map((report) => {
    const createdDate = new Date(report.createdDate)

    const result: Record<
      keyof Pick<
        ThreatIntelReport,
        'id' | 'title' | 'type' | 'labels' | 'createdDate'
      >,
      unknown
    > = {
      id: report.id,
      title: {
        displayValue: (
          <Typography
            variant="text11semibold"
            size={14}
            color={colors.util.navy[50]}
            weight={600}
          >
            {report.title}
          </Typography>
        ),
        sortValue: report.title,
      },
      type: {
        displayValue: (
          <Typography weight={400} size={14} color={colors.util.one.lighter}>
            {ReportTypeLabel[report.type as ReportType] || ''}
          </Typography>
        ),
        sortValue: report.type,
      },
      labels: {
        displayValue: (
          <div className="threat-intel-table-label-container">
            {report.labels.map((value) => (
              <TagNew
                key={value}
                text={value.toUpperCase()}
                interactive
                severity="none"
                onClickCallback={() => onLabelClick(value)}
                highlighted={selectedLabels.includes(value)}
              />
            ))}
          </div>
        ),
      },
      createdDate: {
        displayValue: (
          <Typography weight={400} size={14} color={colors.util.one.lighter}>
            {format(new Date(report.createdDate), 'MMM d, yyyy')}
          </Typography>
        ),
        // Makes sure items on the same day are sorted together
        sortValue: new Date(
          createdDate.getFullYear(),
          createdDate.getMonth(),
          createdDate.getDate(),
        ),
      },
    }

    return result
  })
}

function NoReports() {
  return (
    <div className="threat-intel-reports-table-empty">
      <NoResults
        textColor={colors.util.one.lighter}
        description={
          'There are no reports with these labels. Try removing a label.'
        }
        descriptionTextColor={colors.util.one.lighter}
      />
    </div>
  )
}

export default function ThreatIntelReportsTable({
  data,
  isLoading,
  onBottomReached,
}: Props) {
  const [searchParams, setSearchParams] = useSearchParams()
  const { openSideSheet } = useThreatIntelReportsContext()

  const onLabelClick = (label: string) =>
    setSearchParams((prevParams) => {
      if (!prevParams.has('label', label)) {
        prevParams.append('label', label)
      }

      return prevParams
    })
  const selectedLabels = searchParams.getAll('label')
  const tableDataWithDisplayValues = generateDataWithDisplayValues(
    data,
    onLabelClick,
    selectedLabels,
  )

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onRowClick = (rowId: string | number, rowData: ThreatIntelReport) => {
    openSideSheet(rowId.toString())
  }

  return (
    <Table
      data={tableDataWithDisplayValues}
      loading={isLoading}
      loadingRows={8}
      customHeaders={TABLE_HEADERS}
      sortOptions={{
        defaultSortColumn: 4,
        sortDirection: 'DESC',
        secondaryDefaultSortColumn: 1,
        secondarySortDirection: 'ASC',
      }}
      onClick={onRowClick}
      clickableTableRow
      customErrorComponent={<NoReports />}
      onBottomReached={onBottomReached}
      loadingRowState={
        data.length > 0 ? TableLoadingState.LOADING : TableLoadingState.IDLE
      }
    />
  )
}
