import { ReportType } from '../../../../../models/ThreatIntel'

/**
 * Note: Only the SCE and CA advisory cards use a custom icon.
 *       The CIB advisory card uses Ionicons.
 */
export const getAdvisoryCardIconVariant = (
  type?: ReportType,
  markAsRead?: boolean,
) => {
  switch (type) {
    case ReportType.CUSTOMER_ADVISORY_SVE: {
      return markAsRead
        ? 'significanCyberEventRead'
        : 'significanCyberEventUnread'
    }
    case ReportType.CUSTOMER_ADVISORY_AWARENESS: {
      return markAsRead ? 'customerAdvisoryRead' : 'customerAdvisoryUnread'
    }
    default: {
      throw new Error('Invalid report type')
    }
  }
}
