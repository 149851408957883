import { Ticket } from '../../../../models'
import { CommentProps } from './ActivityTab.utils'
import ActivityTabMessage from './ActivityTabMessage/ActivityTabMessage'

import './ActivityTab.scss'

interface TicketDetailsProps {
  focusedTicket: Ticket
}

const ActivityTab = ({ focusedTicket }: TicketDetailsProps): JSX.Element => {
  const mappedComments = (): CommentProps[] => {
    if (focusedTicket?.comments) {
      return focusedTicket?.comments?.map((comment): CommentProps => {
        return {
          ticketNumber: focusedTicket.ticketNumber,
          fullName: comment.user,
          initials:
            `${comment.user.split(' ')[0]?.charAt(0)}` +
            `${
              comment.user.split(' ')[1]?.charAt(0)
                ? comment.user.split(' ')[1]?.charAt(0)
                : ''
            }`,
          time: comment.time,
          type: comment.type,
          text: comment.text,
          emptyState: false,
        }
      })
    } else if (focusedTicket?.openedBy || focusedTicket?.createdDate) {
      return [
        {
          ticketNumber: focusedTicket.ticketNumber,
          fullName: '',
          initials: '',
          time: '',
          type: '',
          text: '',
          createdDate: focusedTicket?.createdDate,
          openedBy: focusedTicket?.openedBy,
          emptyState: false,
        },
      ]
    } else {
      return [
        {
          fullName: '',
          initials: '',
          time: '',
          type: '',
          text: '',
          createdDate: '',
          openedBy: '',
          emptyState: true,
        },
      ]
    }
  }

  return (
    <div id="activity-tab-details">
      {mappedComments()?.map((comment, index) => {
        return <ActivityTabMessage key={index} comment={comment} />
      })}
    </div>
  )
}

export default ActivityTab
