import { FilterOptions } from '../../../../models'
import { DetectionCatalogStatus } from '../../Coverage.utils'

export interface DetectionCatalogFilterOptions {
  mitreTactics: string[]
  status: string[]
}

export enum DetectionCatalogMitreTactics {
  Reconnaissance = 'Reconnaissance',
  ResourceDevelopment = 'Resource Development',
  InitialAccess = 'Initial Access',
  Execution = 'Execution',
  Persistence = 'Persistence',
  PrivilegeEscalation = 'Privilege Escalation',
  DefenseEvasion = 'Defense Evasion',
  CredentialAccess = 'Credential Access',
  Discovery = 'Discovery',
  LateralMovement = 'Lateral Movement',
  Collection = 'Collection',
  CommandAndControl = 'Command and Control',
  Exfiltration = 'Exfiltration',
  Impact = 'Impact',
}

export const detectionCatalogFilterOptionsState = {
  keywordSearch: '',
  filters: [
    {
      label: 'MITRE Tactic',
      key: 'mitreTactics',
      values: Object.values(DetectionCatalogMitreTactics).map((tactic) => ({
        value: tactic,
        label: tactic,
        selected: false,
      })),
    },
    // keep enabled for Enabled Detections BAN interactivity from Overview page
    {
      label: 'Status',
      key: 'status',
      values: [
        {
          value: DetectionCatalogStatus.Enabled,
          label: 'Enabled',
          selected: false,
        },
      ],
    },
  ],
}

export const detectionCatalogSelectedFiltersState: FilterOptions<DetectionCatalogFilterOptions> =
  {
    keywordSearch: '',
    filters: {
      mitreTactics: [],
      status: [],
    },
  }
