import { MouseEventHandler } from 'react'

export enum ProgressButtonState {
  DEFAULT = 'default',
  IN_PROGRESS = 'in-progress',
  ERROR = 'error',
  SUCCESS = 'success',
}

export interface ProgressButtonProps {
  defaultText: string
  state: ProgressButtonState
  disabled?: boolean
  progressText?: string
  errorText?: string
  successText?: string
  onClick: MouseEventHandler<HTMLButtonElement>
}
