import React, { useContext, useState } from 'react'
import { useMutation } from '@apollo/client'

import {
  Button,
  ChipInput,
  InlineMessageType,
  ListItemSelectType,
  ListItemType,
  Loader,
  Typography,
  colors,
} from '../../../design-system'
import { CustomerHighlight } from '../../../models'
import myCustomersEmptyState from '../../../assets/my-customers-empty-state.svg'
import { Context } from '../../App'
import { deployToastMessage } from '../../../utils'
import { GET_DW_EXPERTS_HOME_DATA } from '../../../graphql/queries/dwExperts'
import { ADD_BOOKMARKED_CUSTOMER } from '../../../graphql/mutations/customer'

import './MCEmptyState.scss'

interface MCEmptyStateProps {
  customerHighlights: CustomerHighlight[]
  loading: boolean
}

const MCEmptyState: React.FC<MCEmptyStateProps> = ({
  customerHighlights,
  loading,
}) => {
  const { dispatch } = useContext(Context)
  const [selectedCustomers, setSelectedCustomers] = useState<ListItemType[]>([])
  const [addBookmarkedCustomer] = useMutation(ADD_BOOKMARKED_CUSTOMER, {
    refetchQueries: [GET_DW_EXPERTS_HOME_DATA],
  })

  const handleAddCustomerButtonClick = async () => {
    if (selectedCustomers.length === 0) {
      return
    }

    const customerShortNames = selectedCustomers.map(
      (customer) => customer.uuid,
    )

    deployToastMessage(
      {
        id: crypto.randomUUID(),
        text: `Making request to add selected customers to My Customers`,
        messageType: InlineMessageType.Info,
        secondsToExpire: 3000,
        dismissible: false,
      },
      dispatch,
    )

    await addBookmarkedCustomer({
      variables: {
        customers: customerShortNames,
      },
      onCompleted: () => {
        deployToastMessage(
          {
            id: crypto.randomUUID(),
            text: 'Added to My Customers',
            messageType: InlineMessageType.SuccessInline,
            secondsToExpire: 3000,
            dismissible: false,
          },
          dispatch,
        )
      },
      onError: () => {
        deployToastMessage(
          {
            id: crypto.randomUUID(),
            text: `Could not add selected customers to My Customers`,
            messageType: InlineMessageType.ErrorInline,
            secondsToExpire: 3000,
            dismissible: false,
          },
          dispatch,
        )
      },
    })
  }

  const mappedCustomers = (
    customers: CustomerHighlight[],
  ): ListItemSelectType[] => {
    return customers?.map((customer) => ({
      id: customer.customerShortName,
      name: String(customer.name),
      uuid: customer.customerShortName,
    }))
  }

  const handleCustomerSelect = (options: ListItemType[]): void => {
    setSelectedCustomers(options)
  }

  return (
    <div className="mc-empty-state">
      {loading ? (
        <Loader size={80} strokeWidth={2} />
      ) : (
        <>
          <div className="empty-state-image">
            <img
              data-testid="report-empty-state"
              src={myCustomersEmptyState}
              alt="My Customers Empty State"
              className="image"
            />
          </div>
          <Typography variant="text8" color={colors.util.navy[50]}>
            No added customers yet
          </Typography>
          <Typography variant="text9" color={colors.util.navy[100]}>
            Customers you have added will appear here.
          </Typography>

          <ChipInput
            label="All customers"
            placeholder="Pick your customers here"
            options={mappedCustomers(customerHighlights)}
            selected={selectedCustomers.map((customer) => ({
              id: String(customer.id),
              name: String(customer.name),
              uuid: String(customer.uuid),
            }))}
            onSelect={handleCustomerSelect}
          />

          <div
            className="add-customers-button"
            data-testid="addCustomersButton"
          >
            <Button
              disabled={selectedCustomers.length === 0}
              variant="primary"
              label="Add to my customers"
              onClick={handleAddCustomerButtonClick}
            />
          </div>
        </>
      )}
    </div>
  )
}
export default MCEmptyState
