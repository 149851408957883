/* eslint-disable jsdoc/require-returns */
import { format, formatDistanceToNowStrict, parseISO } from 'date-fns'
import { ColumnFiltersState } from '@tanstack/table-core'
import cloneDeep from 'lodash/cloneDeep'

import {
  TicketPriorityEnum,
  TicketStateEnum,
  TicketTypeEnum,
} from '@models/Tickets'
import { IconVariant } from '@common/Icon/Icons'
import { safelyParseJSONSearchParam } from '@hooks/tableHooks/useSearchParamColumnFilters'

import type { FilterOptionValues, FilterOptions, Ticket } from '@models/index'

export interface TicketPriorityItem {
  text: string
  icon: IconVariant
  color: string
  order: string
}

export const formatDateAndTime = (date: string) => {
  const newDate = new Date(date)

  return (
    date &&
    `${format(newDate, 'MMM')}, ${format(newDate, 'd')} ${format(
      newDate,
      'yyyy',
    )} ${format(newDate, 'p')}`
  )
}

export const calculateLastUpdatedDate = (date: string): string => {
  const newDate = new Date(date)

  return (
    date &&
    `${format(newDate, 'MMM')} ${format(newDate, 'd')}, ${format(
      newDate,
      'yyyy',
    )}
      (${formatDistanceToNowStrict(newDate, {
        addSuffix: true,
      })})`
  )
}

export const formatCreatedDate = (date: string): string => {
  return (
    date &&
    `${format(parseISO(date), 'MMM')} ${format(parseISO(date), 'd')}, ${format(
      parseISO(date),
      'yyyy',
    )}`
  )
}

export const emptyTicket: Ticket = {
  ticketNumber: '',
  sysId: '',
  priority: TicketPriorityEnum.Informational,
  state: TicketStateEnum.Cancelled,
  type: TicketTypeEnum.Engineering,
  sysUpdatedOn: '',
  lastUpdateUser: '',
  createdDate: '',
  shortDescription: '',
  openedBy: '',
  assignedUser: '',
  assignedUserEmail: '',
  assignedToDeepwatch: false,
  assignmentGroup: '',
  module: '',
  serviceOffering: '',
  customerValidateState: '',
  category: '',
  subcategory: '',
  useCaseTitle: '',
  useCase: '',
  incidentType: '',
  cisControl: '',
  mitreTactics: '',
  mitreTechniques: '',
  splunkLink: '',
  splunkSearch: '',
  servicenowLink: '',
  comments: [],
  caseType: '',
  channel: '',
  impact: '',
  urgency: '',
  description: '',
  searchName: '',
  contactUser: '',
  resolutionNotes: null,
  resolutionBy: null,
  resolutionAt: null,
  resolutionCode: null,
  timeToCloseSec: null,
  timeToCustomerAcknowledgeSec: null,
  timeToCustomerAssignSec: null,
  timeToCustomerValidateSec: null,
  timeToDetectionSec: null,
  timeToDeclaredIncidentSec: null,
  timeToDetectResolveAlertSec: null,
  timeToInvestigateSec: null,
  timeToNotifySec: null,
  timeToRespondSec: null,
  timeToResolveTicketSec: null,
  timeToUpdateSec: null,
  totalTimeDeepwatchWaitCustomerSec: null,
  totalTimeDeepwatchWaitVendorSec: null,
  relatedCases: [],
  sysCreatedOn: '',
  closedAt: '',
  agentType: null,
}

/**
 * Rename filter labels using a `filterValueLabelMap`.
 *
 * If the label exists in the keys of `filterValueLabelMap`, the label will be replaced with the value of the key.
 * @param filterOptions
 * @param filterValueLabelMap
 */
export const transformFilterOptionLabels = (
  filterOptions: FilterOptions<FilterOptionValues[]>,
  filterValueLabelMap: Record<string, string>,
) => {
  return {
    ...filterOptions,
    filters: filterOptions.filters.map((filter) => ({
      ...filter,
      values: filter.values.map((value) => ({
        ...value,
        label: filterValueLabelMap?.[value.label] ?? value.label,
      })),
    })),
  }
}

/**
 * Construct an updated `ColumnFiltersState` with values from
 * the `selectedFilters` parameter in the supplied `URLSearchParams`.
 * @param defaultColumnState
 * @param searchParams
 */
export const deriveColumnFiltersStateFromSearchParams = (
  defaultColumnState: ColumnFiltersState,
  searchParams: URLSearchParams,
): ColumnFiltersState => {
  const selectedFilters = safelyParseJSONSearchParam<Record<string, unknown[]>>(
    searchParams.get('selectedFilters'),
  )

  if (selectedFilters) {
    const columnFiltersState = cloneDeep(defaultColumnState)

    for (const keyName of Object.keys(selectedFilters)) {
      const matchingIndex = defaultColumnState.findIndex(
        (col) => col.id === keyName,
      )
      if (matchingIndex >= 0) {
        // eslint-disable-next-line security/detect-object-injection
        columnFiltersState[matchingIndex].value =
          selectedFilters[keyName as keyof typeof selectedFilters]
      }
    }

    return columnFiltersState
  }

  return defaultColumnState
}

/**
 * If the value at the specified `key` is an array, removes the `valueToRemove` from the array; otherwise, deletes the key from the hash
 * @param hash
 * @param key
 * @param valueToRemove
 */
const removeValueAndMaybeDeleteKey = <
  T extends Record<string, unknown>,
  K extends keyof T,
>(
  hash: T,
  key: K,
  valueToRemove: unknown,
): T => {
  const valueAtKey = hash[key as K]
  // If the value at the key is not an array, remove the key entirely
  if (!Array.isArray(valueAtKey)) {
    const newHash = { ...hash }
    delete newHash[key as K]
    return newHash
  }
  // Otherwise, remove the value from the array
  const newValuesForKey = valueAtKey.filter((value) => value !== valueToRemove)
  const newHash = { ...hash, [key]: newValuesForKey }
  if (newValuesForKey.length === 0) {
    delete newHash[key as K]
  }
  return newHash
}

/**
 * Removes a value or key from a search parameter defined as a stringified hash
 *
 * ## Examples
 *
 * ### Removing a value from an array
 *
 * If the search params look like: `?selectedFilters={"useCase":["dwa_idsa_00015","dwa_idsa_00016"]}`
 * and you want to remove `dwa_idsa_00015` from the `useCase` array, you would call:
 *
 * ```ts
 * removeValueFromSearchParamHash(searchParams, 'selectedFilters', 'useCase', 'dwa_idsa_00015')
 * ```
 * which would return a `URLSearchParams` with the value of `?selectedFilters={"useCase":["dwa_idsa_00016"]}`
 *
 * ### Removing a key from a hash
 *
 * An example without an array specified at the key, for search params like: `?selectedFilters={"useCase":"dwa_idsa_00015"}&otherParam=foo`
 * where you want to remove the key `useCase`:
 *
 * ```ts
 * removeValueFromSearchParamHash(searchParams, 'selectedFilters', 'useCase')
 * ```
 * would return a `URLSearchParams` with the value of `?otherParam=foo`
 * @param searchParams
 * @param searchParameterKey
 * @param searchParameterInnerKey
 * @param valueToRemove
 */
export const removeValueFromSearchParamHash = (
  searchParams: URLSearchParams,
  /** Search param key at which the stringified hash is found */
  searchParameterKey: string,
  /** Key of the stringified hash which should be examined */
  searchParameterInnerKey: string,
  /** Value to remove from the array at the specified key. Can be omitted if the value at the key is not an array. */
  valueToRemove?: string,
): URLSearchParams => {
  const parsedSearchParam = safelyParseJSONSearchParam<Record<string, unknown>>(
    searchParams.get(searchParameterKey),
  )
  const hash =
    parsedSearchParam?.[
      searchParameterInnerKey as keyof typeof parsedSearchParam
    ]

  if (!hash || (Array.isArray(hash) && !hash.includes(valueToRemove))) {
    return searchParams
  }

  const newSearchParams = new URLSearchParams(searchParams)

  const newHash = removeValueAndMaybeDeleteKey(
    parsedSearchParam,
    searchParameterInnerKey,
    valueToRemove,
  )

  if (Object.keys(newHash).length > 0) {
    newSearchParams.set(searchParameterKey, JSON.stringify(newHash))
  } else {
    newSearchParams.delete(searchParameterKey)
  }

  return newSearchParams
}

export const isValidSIEMLink = (SIEMLink: string) =>
  // ensure link follows https or begins with www
  /^(https:\/\/|www\.).+/.test(SIEMLink)

export const isValidSIEMSearch = (SIEMSearch: string | null) => {
  const isInvalidSearch = !SIEMSearch || SIEMSearch.toLowerCase() === 'n/a'

  return isInvalidSearch ? false : true
}

/**
 * only include enabled modules in the filter side sheet
 * @param filterOptions FilterOptions<FilterOptionValues[]>
 * @param modules Record<string, boolean | undefined>
 * @returns FilterOptionValues[]
 */
export const includeEnabledModules = (
  filterOptions: FilterOptions<FilterOptionValues[]>,
  modules: Record<string, boolean | undefined>,
): FilterOptionValues[] => {
  return filterOptions.filters.map((filters) => {
    if (filters.key === 'module') {
      const filteredModules = filters.values.filter(({ value }) => {
        const transformedValue = value.includes('Threat Signal')
          ? value.replace(' ', '_')
          : value.replace('-', '_')

        if (modules[transformedValue]) {
          return value
        }
      })
      return {
        ...filters,
        values: filteredModules,
      }
    }
    return filters
  })
}
