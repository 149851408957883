import { useCallback, useContext, useMemo } from 'react'
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { useNavigate } from 'react-router-dom'
import { Typography } from '@mui/material'

import {
  buildGenericCell,
  buildGenericHeader,
} from '@common/Table/utils/table.utils'
import { RelatedTicket } from '@models/ThreatIntel'
import { TableCell, TableHeader } from '@common/Table'
import { handleKeyboardAction } from '@utils/handleKeyboardAction'
import { navigateUserType } from '@utils/navigateUserType'
import { Context } from '@components/App'

interface RelatedTicketsTableProps {
  data: Array<RelatedTicket>
}

const RelatedTicketsTable: React.FC<RelatedTicketsTableProps> = ({ data }) => {
  const {
    state: {
      user: { isDWEmployee },
      dwExpertsCustomer: { customerShortName },
    },
  } = useContext(Context)

  const navigate = useNavigate()

  const handleRelatedTicketClick = useCallback(
    (ticketId: string) => {
      navigateUserType(
        isDWEmployee,
        `/tickets/library/${ticketId}/edit`,
        navigate,
        customerShortName,
      )
    },
    [customerShortName, isDWEmployee, navigate],
  )

  const columnDef = useMemo(() => {
    const columnHelper = createColumnHelper<RelatedTicket>()

    return [
      {
        id: 'ticketNumber',
        accessorFn: (row: RelatedTicket) => ({
          sysId: row.sysId,
          ticketNumber: row.ticketNumber,
        }),
        cell: (props) => {
          const { sysId, ticketNumber } = props.getValue()

          return (
            <Typography
              fontWeight={600}
              variant="body2"
              {...{
                color: 'blue',
                onClick: () => handleRelatedTicketClick(sysId),
                onKeyDown: (e: React.KeyboardEvent<HTMLElement>) =>
                  handleKeyboardAction(e, () =>
                    handleRelatedTicketClick(sysId),
                  ),
                sx: {
                  cursor: 'pointer',
                },
                tabIndex: 0,
              }}
            >
              {ticketNumber}
            </Typography>
          )
        },
        header: () => buildGenericHeader('Ticket number'),
      },
      columnHelper.accessor('state', {
        cell: (props) => buildGenericCell(props),
        header: () => buildGenericHeader('Status'),
      }),
    ]
  }, [handleRelatedTicketClick])

  const table = useReactTable<RelatedTicket>({
    columns: columnDef,
    data,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <div
      className="common-table-container"
      data-testid="related-tickets-table-container"
    >
      <table cellSpacing={0} className="common-table">
        <thead data-testid="related-tickets-table-header">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} className="no-pointer-events">
              {headerGroup.headers.map((header) => (
                <TableHeader<RelatedTicket> key={header.id} header={header} />
              ))}
            </tr>
          ))}
        </thead>

        <tbody data-testid="related-tickets-body">
          {table.getRowModel().rows.map((row) => (
            <tr
              className="common-table-row"
              data-testid="related-tickets-table-row"
              key={row.id}
            >
              {row.getVisibleCells().map((cell) => (
                <TableCell key={cell.id} cell={cell} />
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default RelatedTicketsTable
